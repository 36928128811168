import {URL} from '../services/network'

export function imageURL(imageID) {
  if (!imageID || imageID.startsWith('data')) return imageID

  return `${URL}/v1/i/avatars/${imageID}.jpg`
}

export function avatarURL(avatarPath) {
  if (!avatarPath || avatarPath.startsWith('data')) return avatarPath
  return `${URL}/${avatarPath}`
}
