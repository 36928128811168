import {alpha, styled} from '@mui/material/styles'
import {Typography} from '@mui/material'

export const VoitingCard = styled('div')(({theme}) => ({
  minHeight: '178px',
  height: 'auto',
  position: 'relative',
  background: 'white',
  borderRadius: 12,
  padding: '16px 20px',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  // boxShadow: '0px 5px 5px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  border: '1px solid #EDEDFF',
  minWidth: '184px',
  // maxWidth: '314px',
  width: '100%',
  '.btn': {
    '&:hover': {
      background: 'transparent',
      boxShadow: 'none',
      fontSize: '16px'
    },
    '&:active': {
      background: 'transparent',
      boxShadow: 'none'
    }
  },
  '.title': {
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'pre-line',
    display: '-webkit-box'
  }
}))

// export const Wrapper = styled('div')(({theme}) => ({
//   background: alpha(theme.palette.primary.light, 0.12),
//   borderRadius: 8,
//   padding: '24px',
//   marginRight: 16
// }))

export const Text = styled(Typography)(({theme}) => ({
  color: theme.palette.primary.main,
  fontSize: 16,
  fontWeight: 700,
  marginBottom: '4px !important',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
}))
