import React, {useCallback, useState} from 'react'
import {useTheme} from '@mui/material/styles'
import {Dialog, DialogContent, Box, Button} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useDispatch, useSelector} from 'react-redux'

import {deleteCurrentUser} from '../../../../store/profileStore/profileStoreSliceEvents'
import {CheckIcon, CrossIcon} from '../../../../icons'
import {PhoneInput} from '../../../../components/Inputs/PhoneInput/PhoneInput'
import {PasswordInput} from '../../../../components/Inputs/PasswordInput/PasswordInput'
import {checkIfPhoneValid, getPasswordError} from '../../../../utils/validations'
import {SmsInput} from '../../../../components/Inputs/SmsInput/SmsInput'

import {profileSelectors} from '../../../../store/profileStore/profileStore'

const EntranceAndSafety = ({user, onSubmit}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const [userPhone, setUserPhone] = useState(user?.phone)

  const [password, setPassword] = useState('')
  const [passwordRepeated, setPasswordRepeated] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [repeatPasswordError, setRepeatPasswordError] = useState('')
  const [isPhoneError, setIsPhoneError] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [code, setCode] = useState(['', '', '', ''])

  const dispatch = useDispatch()

  const handleDataSubmit = (e) => {
    e.preventDefault()

    if (password) {
      const passwordError = getPasswordError(password)
      if (passwordError) {
        setPasswordError(passwordError)
        return
      }
    }
    if (password !== passwordRepeated) {
      setRepeatPasswordError('Пароли не совпадают')
      return
    }
    const data = {
      password
    }
    onSubmit(data)
  }

  const handleDeleteUser = useCallback(() => {
    dispatch(deleteCurrentUser.action(''))
  }, [])

  const onModalClose = () => {
    setIsModalOpen(false)
  }
  const getForbidLabel = () => {
    return (
      <span>
        телефон нельзя изменить в целях безопасности. Если нужно — обращайтесь в{' '}
        <a href="mailto:support@rodcom.ru">службу поддержки</a>
      </span>
    )
  }
  return (
    <Box
      sx={{
        display: 'flex',
        background: 'white',
        // boxShadow: '0px 12px 32px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
        border: '1px solid #EDEDFF',
        padding: '38px',
        borderRadius: '12px',
        maxWidth: '700px',
        width: {sm: '100%', lg: '70%'},
        boxSizing: 'border-box',
        input: {
          border: '2px solid rgba(0, 0, 0, 0.12) !important',
          padding: '11px !important',
          fontSize: '16px !important',
          '&:focus-visible': {
            border: 0
          }
        },
        fieldset: {
          borderColor: 'transparent !important'
        },
        '.settings': {
          fieldset: {
            border: '2px solid rgba(0, 0, 0, 0.12) !important'
          },
          input: {
            border: 'none !important'
          }
        }
        // padding: '48px',
        // borderRadius: '8px',
        // width: '800px'
      }}
    >
      <Box component="form" onSubmit={handleDataSubmit}>
        <PhoneInput
          isError={isPhoneError}
          phone={userPhone}
          phoneHandler={setUserPhone}
          clearPhoneError={setIsPhoneError}
          disabled
          secondLabelRaw={getForbidLabel()}
        />
        <PasswordInput
          id="change-pass"
          label="Новый пароль"
          className="settings"
          placeholder="Не менее 8 символов"
          password={password}
          passwordError={passwordError}
          passHandler={setPassword}
          clearPassError={setPasswordError}
          autocomplete="new-password"
        />
        <PasswordInput
          id="change-repeat-pass"
          label="Новый пароль ещё раз"
          withShowButton={false}
          password={passwordRepeated}
          passwordError={repeatPasswordError}
          passHandler={setPasswordRepeated}
          clearPassError={setRepeatPasswordError}
          placeholder="Для надёжности"
          autocomplete="new-password"
        />
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 3}}>
          <Button
            variant="contained"
            startIcon={<CheckIcon color="white" />}
            type="submit"
            sx={{width: {sm: '100%', lg: 'fit-content !important'}}}
          >
            Сохранить
          </Button>
          {/*<Button*/}
          {/*  variant="outlined"*/}
          {/*  startIcon={<CrossIcon color="red" />}*/}
          {/*  type="button"*/}
          {/*  className="delete-button"*/}
          {/*  onClick={handleDeleteUser}*/}
          {/*>*/}
          {/*  Удалить пользователя*/}
          {/*</Button>*/}
        </Box>
        <Dialog open={isModalOpen} onClose={onModalClose} fullScreen={fullScreen} fullWidth maxWidth="xs">
          <DialogContent>
            <SmsInput code={code} setCode={setCode} />
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  )
}

export default EntranceAndSafety
