import {styled} from '@mui/material/styles'
import {Box} from '@mui/material'

export const StyledAllOperationsCell = styled(Box)(() => ({
  boxSizing: 'border-box',

  '@media(max-width: 600px)': {
    span: {
      fontSize: '10px !important'
    }
  },

  '& .sortIconDesc': {
    transform: 'rotate(180deg)'
  },
  '&.td-1': {
    width: '20%'
  },
  '&.td-2': {
    width: '25%'
  },
  '&.td-3': {
    width: '25%'
  },
  '&.td-0': {
    width: '16%'
  },
  '&.td-4': {
    width: '14%'
  },
  textAlign: 'left',
  alignSelf: 'start',
  display: 'flex',
  alignItems: 'start',
  '&.td-2.history': {
    display: 'none'
  },
  '&.td-0.history': {
    width: '20%'
  },
  '&.td-1.history': {
    width: '25%'
  },
  '&.td-3.history': {
    width: '35%'
  },
  '&.td-4.history': {
    width: '20%'
  },

  '&.td-2.mobile:not(.history)': {
    display: 'none'
  },
  '&.td-0.mobile:not(.history)': {
    width: '20%'
  },
  '&.td-1.mobile:not(.history)': {
    display: 'none'
  },
  '&.td-3.mobile:not(.history)': {
    width: '65%'
  },
  '&.td-4.mobile:not(.history)': {
    width: '20%'
  },

  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)'
}))

export const StyledWrapper = styled(Box)(({theme}) => ({
  display: 'flex',
  width: '100%',
  gap: '16px',
  padding: '12px',
  // opacity: '0.75',
  boxSizing: 'border-box',
  borderTop: `1px solid #EDE3FF`,
  [theme.breakpoints.down(600)]: {
    padding: '2px 10px',
    '& .MuiTypography-cell': {
      lineHeight: '12px'
    }
  }
}))
