import {createSelectorsTree} from '../selectorsFactory'
import {generateReducersObject} from '../sliceMapping'
import {allInitAppEvents} from './creatingRkStoreSliceEvents'

const initialState = {
  isLoading: false,
  uuid: '',
  errors: {},
  myRodkoms: [],
  searchParentCommittees: [],
  tabsIsLoading: false
}

/** Reducers */
export const CREATING_RK_STORE = 'creatingRkStore'
export const creatingRkStore = generateReducersObject({
  allSliceEvents: allInitAppEvents,
  initialState,
  reducerName: CREATING_RK_STORE
})

/** Selectors */
export const creatingRkSelectors = createSelectorsTree(initialState, CREATING_RK_STORE)
