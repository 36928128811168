import initSlice from '../initSlice'

const {sliceEvent, allSliceEvents} = initSlice()

const PREFIX = 'USERS_'

export const verificationPhone = sliceEvent({
  type: `${PREFIX}_VERIFICATION_PHONE`
})

export const verificationPhoneResendCode = sliceEvent({
  type: `${PREFIX}_VERIFICATION_PHONE_RESEND_CODE`
})

export const allInitAppEvents = allSliceEvents
