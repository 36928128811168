import httpClient from './network'

export const getAuthorization = (loginPhone, password) => {
  return httpClient.post('/auth/login', {loginPhone, password}, {withCredentials: false})
}

export const refreshToken = (refreshToken) => {
  return httpClient.post('/auth/refresh-token', {refreshToken})
}

export const getSmsCodeForRegistration = (loginPhone) => {
  return httpClient.post('/auth/ask-to-send-registration-code', {loginPhone})
}
export const checkSmsCodeForRegistration = (loginPhone, code) => {
  return httpClient.post('/auth/check-registration-code', {loginPhone, verificationCode: code})
}

export const registration = (loginPhone, password, verificationCode) => {
  return httpClient.post('/auth/register', {loginPhone, password, verificationCode})
}

export const logout = () => {
  return httpClient.get('/auth/logout')
}

export const getUserInfo = () => {
  return httpClient.get('/users/me')
}

export const changePasswordRequest = (loginPhone) => {
  return httpClient.post('/auth/ask-to-send-reset-code', {loginPhone}, {withCredentials: false})
}

export const saveNewPasswordRequest = (loginPhone, password, verificationCode) => {
  return httpClient.post('/auth/save-new-password', {loginPhone, password, verificationCode}, {withCredentials: false})
}
