import {createSelectorsTree} from '../selectorsFactory'
import {generateReducersObject} from '../sliceMapping'
import {allInitAppEvents} from './inviteStoreSliceEvents'

const initialState = {
  isLoading: false,
  inviteRk: null
}

/** Reducers */
export const INVITE_STORE = 'inviteStore'
export const inviteStore = generateReducersObject({
  allSliceEvents: allInitAppEvents,
  initialState,
  reducerName: INVITE_STORE
})

/** Selectors */
export const inviteSelectors = createSelectorsTree(initialState, INVITE_STORE)
