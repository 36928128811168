import React from 'react'

import {IconButton, InputLabel} from '@mui/material'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import {styled} from '@mui/material/styles'

import {InputBaseFormControl} from '../InputsStyles'
import {FormHelperTextWrapper} from '../TextInput/TextInputStyle'

const Label = styled(InputLabel)(({theme}) => ({
  color: theme.palette.primary.main
}))

const StyledIconButton = styled(IconButton)(() => ({
  marginRight: 0
}))

export const FundraisingInput = (props) => {
  const {
    id,
    value,
    error,
    handleChange,
    clearError,
    className = '',
    label,
    placeholder = '',
    helperText = '',
    disabled = false
  } = props

  const onChange = (event) => {
    const {value: newValue} = event.target
    if (/^[0-9]*$/g.test(newValue)) {
      if (error) {
        clearError('')
      }
      handleChange(Number(newValue))
    }
  }

  const handleClickMinus = () => {
    const number = Number(value)
    if (number >= 100) {
      const newValue = number - 100
      handleChange(newValue)
    }
  }

  const handleClickPlus = () => {
    const number = Number(value)
    const newValue = number + 100
    handleChange(newValue)
  }

  const endAdornment = <InputAdornment position="end">₽</InputAdornment>

  return (
    <InputBaseFormControl classes={{root: className}} variant="standard" fullWidth={true}>
      <Label htmlFor="user-phone" shrink>
        {label}
      </Label>
      <TextField
        id={id}
        type="tel"
        value={value || ''}
        error={Boolean(error)}
        helperText={error}
        placeholder={placeholder}
        onChange={onChange}
        InputProps={{
          endAdornment
        }}
        disabled={disabled}
      />
      {helperText && <FormHelperTextWrapper>{helperText}</FormHelperTextWrapper>}
    </InputBaseFormControl>
  )
}
