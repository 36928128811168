import React, {useCallback, useRef, useState, useEffect} from 'react'
import {styled} from '@mui/material/styles'
import {Button} from '@mui/material'
// import Picker from 'emoji-picker-react'
import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data/sets/4/apple.json'
import {init} from 'emoji-mart'
import emojiLang from '@emoji-mart/data/i18n/ru.json'

init({data})

import {checkNoHtml} from '../../../../../../../../utils/validations'
import TextInput from '../../../../../../../../components/Inputs/TextInput/TextInput'
import {DeleteIcon, EmojiIcon} from '../../../../../../../../icons'
import useOutsideClick from '../../../../../../../../hooks/useOutsideClick'

export const StyledInputWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  gap: '6px',
  '& .MuiInputBase-root': {
    width: '100%'
  },
  '& .MuiButton-root': {
    padding: 0,
    width: '45px',
    minWidth: '45px',
    height: '38px'
  },
  '& .MuiInputBase-input': {
    height: '19px',
    paddingRight: '36px'
  },
  '& .inputWrapper': {
    position: 'relative',
    width: '100%'
  },
  '& .pickedEmoji': {
    position: 'absolute',
    right: '8px',
    zIndex: 1,
    top: 0,
    fontSize: '18px',
    cursor: 'pointer'
  },
  '& .buttonsWrapper': {
    position: 'relative',
    display: 'flex',
    gap: '6px'
  },
  '& .emojiBalloon': {
    position: 'absolute',
    zIndex: 1,
    right: 0,
    bottom: '107%'
  },
  '& .emoji-picker-react': {
    boxShadow: `0px 4px 8px ${theme.palette.grey[600]}`
  }
}))

const VoteOptionCreateItem = ({
  errorMsg,
  value,
  onChangeValue,
  optionNumber,
  removeOption,
  removeDisabled,
  disabled
}) => {
  const wrapperRef = useRef(null)
  const [isEmojiBalloonVisible, setEmojiBalloonVisible] = useState(false)
  const [emojiId, setEmojiId] = useState('')

  useEffect(() => {
    setEmojiId(value?.emoji?.id)
  }, [value?.emoji?.id])

  // const emoji = value.emoji ? String.fromCodePoint('0x' + value.emoji) : null

  const emojiBalloonClose = useCallback(() => {
    setEmojiBalloonVisible(false)
  }, [])
  useOutsideClick(wrapperRef, emojiBalloonClose)

  // const onEmojiClick = useCallback((e, emojiObject) => {
  //   const hexEmoji = emojiObject.emoji?.codePointAt(0).toString(16)
  //   onChangeValue('emoji', hexEmoji, optionNumber)
  //   setEmojiBalloonVisible(false)
  // }, [])

  const emojiButtonClickHandle = useCallback(() => {
    setEmojiBalloonVisible((prev) => !prev)
  }, [])

  const deleteButtonClickHandle = useCallback(() => {
    removeOption(optionNumber)
  }, [])

  return (
    <StyledInputWrapper>
      <div className="inputWrapper">
        <TextInput
          disabled={disabled}
          validator={checkNoHtml}
          errorMsg={errorMsg}
          label=""
          id={optionNumber.toString()}
          value={value.text}
          onChangeValue={(newText) => onChangeValue('text', newText, optionNumber)}
          className="input"
          placeholder="Название варианта"
        />

        {/* {emoji ? (
          <Button disabled={disabled} className="pickedEmoji" onClick={() => onChangeValue('emoji', '', optionNumber)}>
            {emoji}
          </Button>
        ) : null} */}
      </div>

      {!disabled ? (
        <div className="buttonsWrapper">
          {}
          {!emojiId ? (
            <Button variant="contained" type="button" onClick={emojiButtonClickHandle}>
              <EmojiIcon color="white" />
            </Button>
          ) : null}

          {emojiId ? (
            <em-emoji
              id={emojiId}
              set="apple"
              size="46px"
              onClick={emojiButtonClickHandle}
              style={{cursor: 'pointer'}}
            ></em-emoji>
          ) : null}

          <Button disabled={removeDisabled} variant="contained" type="button" onClick={deleteButtonClickHandle}>
            <DeleteIcon color="white" />
          </Button>

          {isEmojiBalloonVisible ? (
            <div className="emojiBalloon" ref={wrapperRef}>
              <Picker
                i18n={emojiLang}
                previewPosition="none"
                onEmojiSelect={(emojiData) => {
                  onChangeValue('emoji', emojiData, optionNumber)
                  setEmojiBalloonVisible(false)
                }}
                set="apple"
                skinTonePosition="none"
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </StyledInputWrapper>
  )
}

export default VoteOptionCreateItem
