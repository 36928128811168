import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import {Box, CircularProgress, Dialog, Typography, Button} from '@mui/material'
import {useNavigate, useParams} from 'react-router-dom'

import {
  initKindergartenState,
  initSchoolState,
  RK_TYPES
} from '../../../CreatingRodcom/constants/creatingRodcomConstants'
import {directoriesSelectors} from '../../../../store/directoriesStore/directoriesStore'
import {FormWrapper, Header, StyledButton, SettingBlock} from './SettingsPageStyle'
import CommitteeParametersForm from './components/CommitteeParametersForm/CommitteeParametersForm'

import {getSummaryEvent} from 'store/accrualsStore/accrualStoreSliceEvents'

import StyledAlert from '../../../../components/StyledAlert/StyledAlert'
import ChatInfoModal from './components/ChatInfoModal/ChatInfoModal'
import {deleteRkEvent} from '../../../../store/committeeSettingsStore/committeeSettingsStoreSliceEvents'
import {committeeSettingsSelectors} from '../../../../store/committeeSettingsStore/committeeSettingsStore'
import {format} from 'date-fns'
import {getRkMembersRequest, getRkProfileRequest} from '../../../../services/committeeSettings'
import {getRkProfileEventSuccess} from '../../../../store/committeeSettingsStore/committeeSettingsStoreSliceEvents'
import {accrualsSelectors} from '../../../../store/accrualsStore/accrualStore'

const SettingsPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const profileInfo = useSelector(committeeSettingsSelectors.profileInfo.selector)
  const uuid = useSelector(committeeSettingsSelectors.uuid.selector)
  const isLoading = useSelector(committeeSettingsSelectors.isLoading.selector)
  const members = useSelector(committeeSettingsSelectors.membersParentCommittee.selector)
  const {id} = useParams()

  const getData = useCallback(async () => {
    const x = await getRkProfileRequest(id).then((r) => dispatch(getRkProfileEventSuccess.action(r.data.payload, id)))
  }, [id])

  useEffect(() => {
    getData()
  }, [id])

  const [schoolErrors, setSchoolErrors] = useState({})
  const [schoolFieldValues, setSchoolFieldValues] = useState(initSchoolState)
  const [kindergartenErrors, setKindergartenErrors] = useState({})
  const [kindergartenFieldValues, setKindergartenFieldValues] = useState(initKindergartenState)
  const [chatInfoModalIsOpen, setChatInfoModalIsOpen] = useState(false)
  const [openConfirmDeleteParentCommittee, setOpenConfirmDeleteParentCommittee] = useState(false)
  const classes = useSelector(directoriesSelectors.class.selector)
  const classLetter = useSelector(directoriesSelectors.classLetter.selector)
  const summary = useSelector(accrualsSelectors.summary.selector)
  const [changeRk, setChangeRk] = useState()
  const [isZeroBalance, setIsZeroBalance] = useState(false)
  const [isNoDebt, setIsNoDebt] = useState(false)
  const [isShowAlertDeleteRk, setIsShowAlertDeleteRk] = useState(false)
  const [isShowAlertTransferRk, setIsShowAlertTransferRk] = useState(false)

  useEffect(() => {
    if (profileInfo?.institutionType === RK_TYPES.SCHOOL) {
      const {classUuid, classLetterUuid, educator, name, chatLink, uuid} = profileInfo
      const initInfo = {
        ...initSchoolState,
        uuid,
        yearOfAdmission: '',
        grade: classes?.[classUuid] || '',
        letter: classLetter?.[classLetterUuid] || '',
        educator: educator || '',
        name: name || '',
        chat: chatLink || ''
      }
      setSchoolFieldValues(initInfo)
    } else {
      const initInfo = {
        ...initKindergartenState,
        yearOfAdmission: profileInfo?.yearOfAdmission || '',
        uuid: profileInfo?.uuid,
        grade: classes?.[profileInfo?.classUuid] || '',
        letter: classLetter?.[profileInfo?.classLetterUuid] || '',
        educator: profileInfo?.educator || '',
        name: profileInfo?.name || '',
        chat: profileInfo?.chatLink || '',
        groupName: profileInfo?.groupName || '',
        groupNumber: profileInfo?.groupNumber || ''
      }
      setKindergartenFieldValues(initInfo)
    }
  }, [profileInfo, classes, classLetter])

  useEffect(() => {
    setChangeRk(members.filter((i) => i.userUuid === profileInfo?.chiefRoleTransferTo?.uuid)[0])
  }, [profileInfo, members])

  useEffect(() => {
    dispatch(getSummaryEvent.action(id))
    getRkMembersRequest(id)
  }, [])

  useEffect(() => {
    if (summary?.totalDebtByRk === 0) setIsNoDebt(true)
  }, [summary?.totalDebtByRk])

  useEffect(() => {
    if (summary?.rkBalance === 0) setIsZeroBalance(true)
  }, [summary?.rkBalance])

  const onOpenChatInfoModal = useCallback((e) => {
    e.preventDefault()
    setChatInfoModalIsOpen(true)
  }, [])

  const handleCloseChatInfoModal = useCallback(() => {
    setChatInfoModalIsOpen(false)
  }, [])

  const handleDeleteRk = useCallback((e) => {
    dispatch(deleteRkEvent.action(uuid))
  }, [])

  const handleOpenConfirmParentCommittee = () => {
    if (!isNoDebt || !isZeroBalance) {
      setIsShowAlertDeleteRk(true)
      return
    }
    setOpenConfirmDeleteParentCommittee(true)
  }

  const handleClickTransfer = () => {
    if (!isNoDebt) {
      setIsShowAlertTransferRk(true)
      return
    }
    navigate(`${window?.location?.pathname}/assignment`)
  }

  if (isLoading) {
    return <CircularProgress sx={{margin: '0 auto', padding: '120px', display: 'block'}} />
  }

  return (
    <>
      <Dialog
        open={openConfirmDeleteParentCommittee}
        onClose={() => setOpenConfirmDeleteParentCommittee(false)}
        fullWidth
        maxWidth="xs"
      >
        <Box sx={{padding: '38px', borderRadius: '12px', display: 'flex', flexDirection: 'column', gap: '19px'}}>
          <Typography sx={{fontSize: 16, fontWeight: 700, textAlign: 'center'}}>
            Вы действительно хотите удалить этот родительский коммитет?
          </Typography>
          <StyledButton variant="contained" onClick={handleDeleteRk} sx={{marginTop: '24px'}}>
            Удалить
          </StyledButton>
          <StyledButton variant="outlined" onClick={() => setOpenConfirmDeleteParentCommittee(false)}>
            Отмена
          </StyledButton>
        </Box>
      </Dialog>
      <SettingBlock>
        <FormWrapper className="left">
          <Box className="settingsPage">
            <Header>Параметры родкома</Header>
            <CommitteeParametersForm
              uuid={uuid}
              onOpenChatInfoModal={onOpenChatInfoModal}
              classes={classes}
              classLetter={classLetter}
              setSchoolFieldValues={setSchoolFieldValues}
              schoolErrors={schoolErrors}
              setSchoolErrors={setSchoolErrors}
              setKindergartenErrors={setKindergartenErrors}
              setKindergartenFieldValues={setKindergartenFieldValues}
              kindergartenErrors={kindergartenErrors}
              type={profileInfo?.institutionType}
              schoolFieldValues={schoolFieldValues}
              kindergartenFieldValues={kindergartenFieldValues}
              isFormDisabled={false}
            />
          </Box>
        </FormWrapper>
        <Box className="right">
          <FormWrapper sx={{marginTop: 0}}>
            <Header>Передача прав</Header>
            {profileInfo.chiefRoleTransferTo !== null ? (
              <StyledAlert withoutIconOnMobile title="" icon={false} sx={{fontSize: '13px !important'}}>
                Участник{' '}
                <b>
                  {changeRk?.fullName?.surname} {changeRk?.fullName?.firstName}
                </b>{' '}
                получил ваш запрос на передачу роли ГРК в{' '}
                <b>
                  {profileInfo?.chiefRoleTransferTo?.createdAt
                    ? format(new Date(profileInfo.chiefRoleTransferTo.createdAt), 'hh:mm dd.MM.yyyy')
                    : null}
                </b>
                . В течение <b>24-х часов</b> участник должен принять решение. В случае его отказа, вы сможете отправить
                заявку повторно.
              </StyledAlert>
            ) : (
              <StyledAlert
                isError={isShowAlertTransferRk}
                withoutIconOnMobile
                title=""
                icon={false}
                sx={{fontSize: '13px !important'}}
              >
                Для передачи роли ГРК вам необходимо отчитаться за выведенные средства.
              </StyledAlert>
            )}

            <Button
              variant="contained"
              color="info"
              onClick={handleClickTransfer}
              sx={{marginTop: '19px', boxShadow: 'none'}}
              disabled={profileInfo.chiefRoleTransferTo !== null}
              endIcon={<ArrowRightAltIcon className="arrowRight" />}
            >
              Выбрать участника
            </Button>
          </FormWrapper>
          <FormWrapper>
            <Header>Удалить родком</Header>
            <StyledAlert
              isError={isShowAlertDeleteRk}
              withoutIconOnMobile
              title=""
              icon={false}
              sx={{fontSize: '13px !important'}}
            >
              Чтобы удалить родком, необходимо вывести все деньги из проектов и отчитаться за них.
            </StyledAlert>
            <Button
              variant="contained"
              color="info"
              // onClick={() => setOpenConfirmDeleteParentCommittee(true)}
              onClick={handleOpenConfirmParentCommittee}
              sx={{marginTop: '19px'}}
              endIcon={<ArrowRightAltIcon className="arrowRight" />}
            >
              Продолжить
            </Button>
          </FormWrapper>
        </Box>
      </SettingBlock>
      <ChatInfoModal isOpen={chatInfoModalIsOpen} handleClose={handleCloseChatInfoModal} />
    </>
  )
}

export default SettingsPage
