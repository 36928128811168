import {styled} from '@mui/material/styles'
import AppBarMui from '@mui/material/AppBar'
import Menu from '@mui/material/Menu'
import OutlinedInput from '@mui/material/OutlinedInput'

export const AppBar = styled(AppBarMui)(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'white',
  boxShadow: '0px 12px 48px rgba(57, 33, 94, 0.06)',
  '.user_menu': {
    padding: 0,
    marginLeft: 12,
    svg: {
      width: 24,
      height: 24
    }
  },

  height: '48px',
  borderRadius: '0px !important',
  // boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.08)',
  [theme.breakpoints.up('xs')]: {
    marginBottom: 32,
    a: {
      order: 2
    },
    '& .mobile-navigation': {
      display: 'block',
      flexGrow: 0,
      order: 1
    },
    '& .header-logo-sm': {
      order: 2
    },
    '& .login-button-wrapper': {
      order: 3
    },
    '& .user-menu-wrapper': {
      order: 4
    }
  },
  [theme.breakpoints.up('md')]: {
    height: '48px',
    marginBottom: 32,
    '& .header-logo-lg': {
      img: {
        width: 135,
        height: 46
      }
    },
    '& .nav-list': {
      order: 2
    },
    '& .login-button-wrapper': {
      order: 3
    },
    '& .user-menu-wrapper': {
      order: 4
    },
    '& .container': {
      paddingLeft: '10px',
      paddingRight: '10px'
    }
  },
  [theme.breakpoints.down('lg')]: {
    '& .avatar': {
      width: '32px !important',
      height: '32px !important',
      marginLeft: '24px !important'
    }
  },
  [theme.breakpoints.up('lg')]: {
    boxShadow: '0px 12px 48px rgba(57, 33, 94, 0.06)',
    height: '64px',
    marginBottom: 32,
    '& .mobile-navigation': {
      display: 'none'
    },
    '.login-button-wrapper': {
      '.login-button': {
        padding: '7px 20px'
      }
    }
  },
  '& .icon-button': {
    borderRadius: '13px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  '& .menu-button': {
    '&:hover': {
      backgroundColor: 'none'
    },
    '&:focus': {
      backgroundColor: 'none'
    }
  },
  '& .nav-list': {
    margin: '0 auto',
    button: {
      fontSize: '14px',
      fontWeight: 600
    }
  },
  '& .logo': {
    width: '85px',
    height: '28px',
    [theme.breakpoints.up('lg')]: {
      width: '78px',
      height: '26px'
    }
  },
  '& .avatar': {
    boxSizing: 'border-box',
    width: '44px',
    height: '44px',
    marginLeft: '54px'
  },
  '& .user-name': {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.25px',
    marginRight: 4
  },
  '& .toolbar': {
    minHeight: '48px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  '& .container': {
    paddingLeft: '16px',
    paddingRight: '16px'
  },
  '& .MuiSelect': {
    select: {
      background: 'pink'
    }
  },
  '& .login-button': {
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '6px 12px',
    borderRadius: '12px',
    textDecoration: 'none',
    color: '#F7F2FF',
    background: 'linear-gradient(180deg, #794DBD 0%, #57338D 100%)',
    boxShadow:
      '0px 12px 20px rgba(87, 51, 141, 0.0628148), 0px 4.62222px 6.37037px rgba(87, 51, 141, 0.0971852), 0px 0.977778px 1.62963px rgba(87, 51, 141, 0.16)',
    border: 'none',
    // transition: '0.2s linear',
    '&:hover': {
      background: 'linear-gradient(180deg, #57338D 0%, #794DBD 100%)',
      border: 'none'
    },
    '&:focused': {
      background: 'linear-gradient(180deg, #57338D 0%, #794DBD 100%)',
      border: 'none'
    },
    '&:active': {
      background: 'linear-gradient(180deg, #553685 1000%, #553685 100%)'
    },
    svg: {
      path: {
        stroke: '#F7F2FF !important'
      }
    },
    [theme.breakpoints.down('md')]: {
      padding: '4px 19px',
      background: 'transparent',
      '& path': {
        stroke: '#39215E'
      },
      '&:hover, &:active': {
        textDecoration: 'none',
        background: 'transparent'
      }
    },
    p: {
      fontSize: 14,
      marginLeft: 16,
      fontWeight: 600
    }
  }
}))

export const StyledMenu = styled(Menu)(({theme}) => ({
  '& .MuiPaper-root': {
    top: '38px !important',
    borderRadius: 16
  },
  [theme.breakpoints.down('lg')]: {
    '& .MuiPaper-root': {
      top: '16px !important'
    }
  },
  '& .MuiMenuItem-root': {
    textAlign: 'center',
    maxWidth: '179px',
    fontSize: 14,
    fontWeight: 600
  },
  '& .MuiLink-root:hover, & .MuiLink-root:active': {
    textDecoration: 'none'
  }
}))

export const StyledInput = styled(OutlinedInput)(() => ({
  MuiOutlinedInput: {
    border: 'none'
  },
  border: 'none'
}))
