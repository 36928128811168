import React, {useEffect, useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'

import {BlocksListWrapper} from './ProjectsStyle'
import {PublishStatuses} from '../../../constants/parentCommitteeConstants'
import ActiveProject from './ActiveProject'
import DoneProject from './DoneProject'
import {Box} from '@mui/material'
import PayAllAtOnce from './PayAllAtOnce'
import {PATHS} from '../../../../../../routes/paths'
import {getProjectEvent} from 'store/projectsStore/projectsStoreSliceEvents'
import {accrualsSelectors} from '../../../../../../store/accrualsStore/accrualStore'
import {getMyAccrualsEvent} from '../../../../../../store/accrualsStore/accrualStoreSliceEvents'
import {Button} from '@mui/material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
const Projects = ({toPayByMe, projects, isChief, isMember}) => {
  const {id} = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const accruals = useSelector(accrualsSelectors.current.selector)
  const accrualsLoader = useSelector(accrualsSelectors.loading.selector)

  const navigateCart = useCallback(() => {
    navigate(`${PATHS.PARENT_COMMITTEE}/${id}${PATHS.PARENT_COMMITTEE_SUMMARY_CART}`)
  }, [id])

  useEffect(() => {
    dispatch(getMyAccrualsEvent.action(id))
  }, [])

  return (
    <>
      <BlocksListWrapper>
        {isMember && !!projects?.length && (
          <PayAllAtOnce
            totalToPay={toPayByMe}
            onClick={navigateCart}
            accruals={accruals}
            projects={projects}
            accrualsLoader={accrualsLoader}
          />
        )}
        {!!projects?.length &&
          projects.slice(0, 10).map((project, index) => {
            let percent = 0
            if (project.type === 'ONE_OFF') {
              percent =
                project.amountPaid * (100 / project.totalPerMember) >= 100
                  ? 100
                  : project.amountPaid * (100 / project.totalPerMember)
            } else {
              percent =
                project.amountPaid * (100 / project.targetMoney) >= 100
                  ? 100
                  : project.amountPaid * (100 / project.targetMoney)
            }

            // totalPerMember
            if (PublishStatuses[project.status] === PublishStatuses.COMPLETED && isChief) {
              return <DoneProject key={index.toString(36)} />
            }

            return (
              <ActiveProject
                onClick={() => {
                  dispatch(getProjectEvent.action(project.uuid))
                  navigate(`${PATHS.PARENT_COMMITTEE}/${id}${PATHS.PARENT_COMMITTEE_PROJECTS}/${project.uuid}`)
                }}
                key={index.toString(36)}
                project={project}
                percent={percent}
              />
            )
          })}
      </BlocksListWrapper>
    </>
  )
}

export default Projects
