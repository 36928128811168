import {styled} from '@mui/material/styles'
import {Typography} from '@mui/material'

export const StyledHeader = styled(Typography)(({theme}) => ({
  fontWeight: 400,
  fontSize: '32px',
  lineHeight: '38px',
  marginBottom: 19,
  [theme.breakpoints.down('md')]: {
    fontSize: '32px',
    lineHeight: '22px'
  }
}))
