import {styled} from '@mui/material/styles'
import {FormControl, FormHelperText, InputLabel, TextField} from '@mui/material'

export const InputLabelWrapper = styled(InputLabel)(({theme}) => ({
  color: theme.palette.primary.main,
  position: 'relative',
  marginBottom: 6
}))

export const FormHelperTextWrapper = styled(FormHelperText)(({theme}) => ({
  color: theme.palette.primary.main
}))

export const StyledTextField = styled(TextField)(({theme}) => ({
  background: theme.palette.background.paper,
  boxShadow: '0px 12px 32px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  '& input': {
    borderRadius: '6px',
    border: 'none',
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  '& .adornment-close': {
    marginLeft: '8px'
  }
}))

export const StyledFormControl = styled(FormControl)(() => ({
  margin: '0px !important',
  '& .MuiFormControl-root': {
    borderRadius: '6px'
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '6px'
  }
}))
