import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

import {HeaderLogin, Wrapper, FormWrapper} from './LogintPageStyle'
import {EVENT_STATUSES, TABS} from './constants'
import LoginForm from './components/LoginForm/LoginForm'
import RegistrationForm from './components/RegistrationForm/RegistrationForm'
import {LOGO_IMAGE_DATA} from '../../components/AppHeader/AppHeaderConstants'
import {initAppSelectors} from '../../store/initStore/initStore'
import {setRequestStatus} from '../../store/initStore/initStoreSliceEvents'
import Link from '@mui/material/Link'
import {PATHS} from '../../routes/paths'

const LoginPage = () => {
  const dispatch = useDispatch()

  const requestStatus = useSelector(initAppSelectors.requestStatus.selector)

  const [activeTab, setActiveTab] = useState(0)

  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')

  const [regLogin, setRegLogin] = useState('')
  const [regPassword, setRegPassword] = useState('')

  const tabSwitchHandler = (event, newValue) => {
    setActiveTab(newValue)
  }

  useEffect(() => {
    if (requestStatus === EVENT_STATUSES.REGISTER_SUCCESS) {
      setActiveTab(0)
      dispatch(setRequestStatus.action(''))
    }
  }, [requestStatus])

  return (
    <Wrapper>
      <HeaderLogin>
        <Link href={PATHS.LANDING}>
          <img src={LOGO_IMAGE_DATA.SRC} alt="" />
        </Link>
      </HeaderLogin>
      <FormWrapper>
        <Box
        // sx={{borderBottom: 1, borderColor: 'divider'}}
        >
          <Tabs
            value={activeTab}
            onChange={tabSwitchHandler}
            TabIndicatorProps={{style: {background: '#E1E1F2', height: '100%', borderRadius: 12}}}
          >
            <Tab
              label={TABS.ENTER}
              id={TABS.ENTER}
              className="tab"
              style={{zIndex: 1}}
              sx={{
                fontSize: '14px !important',
                fontWeight: 600,
                padding: '6px 12px',
                borderRadius: '12px !important'
              }}
            />
            <Tab
              label={TABS.REG}
              id={TABS.REG}
              className="tab"
              style={{zIndex: 1}}
              sx={{
                fontSize: '14px !important',
                fontWeight: 600,
                padding: '6px 12px',
                borderRadius: '12px !important'
              }}
            />
          </Tabs>
        </Box>

        {activeTab === 0 && (
          <LoginForm login={login} setLogin={setLogin} password={password} setPassword={setPassword} />
        )}
        {activeTab === 1 && (
          <RegistrationForm
            phone={regLogin}
            setPhone={setRegLogin}
            password={regPassword}
            setPassword={setRegPassword}
          />
        )}
      </FormWrapper>
    </Wrapper>
  )
}

export default LoginPage
