import {put} from 'redux-saga/effects'
import * as authyorizationEvents from '../store/initStore/initStoreSliceEvents'
import {getTransformedErrorsFromBack} from './getTransformedErrorsFromBack'

export function* withErrorLogging(effect, excludeErrorsArray = []) {
  try {
    return yield effect
  } catch (e) {
    const {response} = e

    if (!excludeErrorsArray.includes(response.data.event)) {
      const errorsMessages = getTransformedErrorsFromBack(e)
      const splitMessages = Object.values(errorsMessages).join('\n')
      const msg = response?.data?.payload?.message || 'Ошибка'
      const values = Object.values(errorsMessages).map((item, i) => {
        return i === Object.values(errorsMessages).length - 1 ? (
          item
        ) : (
          <>
            {item}
            <br />
          </>
        )
      })
      const res = splitMessages ? (
        <>
          {msg}
          <br />
          {values}
        </>
      ) : (
        msg
      )
      yield put(authyorizationEvents.setError.action(res))
      // console.error(e)
      throw e
    }
  }
}
