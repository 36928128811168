import React, {useState, useMemo, useCallback, useEffect} from 'react'
import {getFormattedDate} from 'utils/getFormattedDate'
import {Typography} from '@mui/material'

import {TotalBlock} from './FundraisingStyle'

const FundraisingInfoAlert = ({
  deadline,
  moneyPerMember,
  tariffs,
  tariff,
  membersAmount,
  periodsNumber,
  published,
  members,
  projectMembers
}) => {
  const [childrenCount, setChildrenCount] = useState(0)
  useEffect(() => {
    members &&
      setChildrenCount(
        members
          .filter((item) => [item]?.some((i) => projectMembers.includes(i.userUuid)))
          .reduce((a, b) => a + (b['children'].length || 0), 0)
      )
  }, [members, projectMembers])

  const resultLabel = useMemo(() => {
    const deadlineDate = new Date(deadline)
    const now = new Date(Date.now())
    if (!moneyPerMember || isNaN(moneyPerMember) || moneyPerMember <= 0 || !tariffs) return ''

    if (!deadlineDate || isNaN(deadlineDate) || (!published && deadlineDate < now)) {
      console.log('deadline error')
      return ''
    }
    // noinspection EqualityComparisonWithCoercionJS
    const curTariff = parseInt(tariffs.find((x) => x.id == tariff)?.label)
    const perMemberCommission = Math.ceil(Number(moneyPerMember) * (curTariff / 100))
    const perMemberWithTariff = Number(moneyPerMember) + perMemberCommission
    const targetMoney = Number(membersAmount) * perMemberWithTariff

    if (periodsNumber > 1) {
      const monthlyAmount = perMemberWithTariff
      const totalPerMonth = perMemberWithTariff * membersAmount
      const totalPerMember = perMemberWithTariff * periodsNumber
      const total = targetMoney * periodsNumber
      const monthWording = periodsNumber > 4 ? 'месяцев' : 'месяца'
      return (
        <span>
          Будем собирать <b>по {monthlyAmount}₽ в месяц на каждого ребенка</b>. Общая сумма в месяц
          <b>
            {' '}
            на {childrenCount} детей составит {monthlyAmount * childrenCount}₽
          </b>
          . Всего за {periodsNumber} {monthWording} соберем <b>{monthlyAmount * childrenCount * periodsNumber}₽</b>.
          Первый взнос нужно сделать <b>до {getFormattedDate(deadlineDate)}</b>.
          <b> Далее до {deadlineDate?.getDate() || '-'}-го числа каждого месяца.</b>
        </span>
      )
    }
    return (
      <span>
        Собираем по <b>{perMemberWithTariff}₽ на каждого ребенка</b>. Всего на {childrenCount} детей соберем{' '}
        <b>{perMemberWithTariff * childrenCount}₽</b>. Внести деньги нужно до{' '}
        <b>{deadline ? getFormattedDate(deadline) : '-'}</b>
      </span>
    )
  }, [membersAmount, moneyPerMember, periodsNumber, tariff, tariffs, deadline, childrenCount, projectMembers])
  return (
    resultLabel && (
      <TotalBlock>
        <Typography variant="h4">{resultLabel}</Typography>
      </TotalBlock>
    )
  )
}
export default FundraisingInfoAlert
