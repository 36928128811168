import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {Box, Button, CircularProgress, Dialog, FormControl, Typography} from '@mui/material'

import {FundraisingInput} from '../../../../../../../components/Inputs/FundraisingInput/FundraisingInput'
import TextInput from '../../../../../../../components/Inputs/TextInput/TextInput'
import {CheckIcon, PlusIcon} from '../../../../../../../icons'
import {AlertError} from '../../ProjectStyle'
import {notifyError} from '../../../../../../../utils/notify'

import {giveoutSelectors} from 'store/giveoutStore/giveoutStore'
import {sendExpenseReportEvent} from 'store/giveoutStore/giveoutStoreSliceEvents'

import {
  CloseIconWrapper,
  CloseImg,
  ImagesWrapper,
  Img,
  ImgWrapper,
  Input,
  Label,
  SelectImg
} from '../InfoProject/InfoProjectStyle'
import FormLabel from '@mui/material/FormLabel'
import {getFileType} from '../../../../../../../utils/getFileType'
import heic2any from 'heic2any'

import pdf from '../../../../../../../images/pdf.png'

const maxSizeFile = 5242880

const ModalContent = styled('div')(({theme}) => ({
  padding: '38px',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '19px',
  '.description, .fundraising-output-input': {
    marginBottom: 0
  },
  '& .MuiFormControl-root': {
    maxWidth: 'inherit'
  },
  '.selectImg, .img': {
    width: '96px',
    height: '96px'
  },
  '& label+.MuiInputBase-root': {
    marginTop: '17px'
  }
}))

const ReportBackModal = ({isOpen, projectId, handleClose, giveoutWarning}) => {
  const theme = useTheme()
  const [amount, setAmount] = useState(0)
  const [amountError, setAmountError] = useState('')
  const [description, setDescription] = useState('')
  const [images, setImages] = useState([])
  const [isLoadingFiles, setIsLoadingFile] = useState(false)
  const dispatch = useDispatch()
  const debtSummary = useSelector(giveoutSelectors.debtSummary.selector)

  const sendExpenseReport = useCallback(
    (e) => {
      if (!amount) {
        setAmountError('Сумма отчета должна быть больше 0')
        return
      }
      if (amount > debtSummary?.totalDebt) {
        setAmountError(`Сумма отчета не может быть больше ${debtSummary?.totalDebt} ₽`)
        return
      }
      e.preventDefault()
      e.nativeEvent.stopPropagation()

      dispatch(
        sendExpenseReportEvent.action({
          uuid: projectId,
          amount: amount,
          description: description,
          images: images
        })
      )
      handleClose()
    },
    [debtSummary, amount, description, images, projectId]
  )

  // const onSelectImage = useCallback(
  //   (event) => {
  //     const fileReader = new FileReader()
  //     fileReader.onload = function () {
  //       setImages([...images, fileReader.result])
  //     }
  //     fileReader.readAsDataURL(event.target.files?.[0])
  //   },
  //   [images]
  // )

  const onSelectImage = (event) => {
    for (let i = 0; i < event.target.files.length; i++) {
      if (event.target.files[i].size > maxSizeFile) {
        notifyError('Размер файла не должен превышать 5 Мб')
        continue
      }
      const fileType = getFileType(event.target.files[i].name)
      const fileReader = new FileReader()

      if (!event.target.files[i].type && fileType === 'heic') {
        setIsLoadingFile(true)

        heic2any({
          blob: event.target.files[i],
          toType: 'image/jpeg',
          quality: 0.5 // cuts the quality and size by half
        })
          .then((data) => {
            fileReader.readAsDataURL(data)

            fileReader.onload = function () {
              setImages((prev) => [...prev, fileReader.result])
              setIsLoadingFile(false)
            }

            fileReader.onerror = function () {
              notifyError('Не удалось загрузить файл')
            }
          })
          .catch(() => {
            notifyError('Не удалось загрузить файл')
            setIsLoadingFile(false)
          })
      } else {
        fileReader.readAsDataURL(event.target.files?.[i])

        fileReader.onload = function () {
          setImages((prev) => [...prev, fileReader.result])
        }

        fileReader.onerror = function () {
          notifyError('Не удалось загрузить файл')
        }
      }
    }
  }

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Dialog open={isOpen} onClose={handleClose} fullScreen={fullScreen} fullWidth maxWidth="xs">
      <ModalContent>
        <Typography variant="h4" sx={{lineHeight: '29px', fontSize: '19px'}}>
          Отчитаться за вывод
        </Typography>
        <AlertError>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '6x'}}>
            {/*<Typography variant="h3" sx={{fontWeight: 700}}></Typography>*/}
            <Typography variant="h3">{giveoutWarning}</Typography>
          </Box>
        </AlertError>
        <FundraisingInput
          className="fundraising-output-input"
          id="fundraising-output-input"
          value={amount}
          error={amountError}
          handleChange={setAmount}
          clearError={setAmountError}
          label="За сколько отчитываетесь?"
          placeholder="Сумма, ₽"
        />
        <TextInput
          className="description"
          label="Комментарий"
          id="description"
          minRows={4}
          multiline
          resize
          value={description}
          onChangeValue={setDescription}
          placeholder="Кратко опишите назначение"
        />
        <FormControl sx={{gap: '5px', marginBottom: 0}}>
          <Box
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 'bold',
              fontSize: '14px',
              lineHeight: '19px',
              color: '#39215E'
            }}
          >
            Загрузите чеки, если есть
          </Box>
          <ImagesWrapper>
            {images?.map((image, index) => (
              <ImgWrapper>
                <CloseIconWrapper
                  onClick={() => {
                    setImages(images.filter((_, idx) => idx !== index))
                  }}
                >
                  <CloseImg />
                </CloseIconWrapper>
                <Img
                  className="img"
                  src={image.startsWith('data:application/pdf') ? pdf : image}
                  key={index.toString(36)}
                />
              </ImgWrapper>
            ))}
            <SelectImg className="selectImg">
              <Label htmlFor="image-button-file">
                {isLoadingFiles ? (
                  <CircularProgress sx={{margin: '0 auto', padding: 19, display: 'block'}} />
                ) : (
                  <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '21px'}}>
                    <PlusIcon />
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px'}}>
                      <span>Загрузить</span>
                      <span>изображение</span>
                    </Box>
                  </Box>
                )}
              </Label>
              <Input
                accept=".gif, .jpg, .jpeg, .png, .pdf, .heic"
                id="image-button-file"
                type="file"
                multiple
                onChange={onSelectImage}
              />
            </SelectImg>
          </ImagesWrapper>
        </FormControl>

        <Button startIcon={<CheckIcon color="white" />} variant="contained" type="button" onClick={sendExpenseReport}>
          Отправить
        </Button>
        {fullScreen && (
          <Button variant="outlined" onClick={handleClose}>
            Отмена
          </Button>
        )}
      </ModalContent>
    </Dialog>
  )
}
export default ReportBackModal
