import React, {useState, useMemo, useCallback} from 'react'
import {useSelector} from 'react-redux'

import {TotalBlock, Wrapper} from './FundraisingStyle'
import {Header} from '../../../../Settings/SettingsPageStyle'
import StyledAlert from '../../../../../../../components/StyledAlert/StyledAlert'
import {AlertCircleIcon} from '../../../../../../../icons'

import AmountCalculator from './AmountCalculator'
import PeriodSlider from './PeriodSlider'
import DeadlineInput from './DeadlineInput'
import TariffInput from './TariffInput'
import {directoriesSelectors} from 'store/directoriesStore/directoriesStore'
import {styled} from '@mui/material/styles'
import {getOptionsFromDirectories} from '../../../../../../../utils/getOptionsFromDirectories'
import FundraisingInfoAlert from './FundraisingInfoAlert'
const StyledLabel = styled('div')(({theme}) => ({
  ...theme.components.MuiInputLabel.styleOverrides.root,
  transform: 'translate(0, -1.5px) scale(0.75)',
  transformOrigin: 'left'
}))

const Fundraising = ({
  membersAmount = 0,
  isPublished,

  setProjectValues,
  periodsNumber,
  firstDeadlineDate,
  tariff,
  moneyPerMember,
  setError,
  errors,
  members,
  projectMembers
}) => {
  const tariffSel = useSelector(directoriesSelectors.tariffs.selector)
  const payoutCommission = useSelector(directoriesSelectors.payoutCommission.selector)
  const tariffs = useMemo(() => getOptionsFromDirectories(tariffSel), [tariffSel])

  const setDeadline = (newValue) => {
    if (isNaN(newValue)) {
      setDeadlineError('неверная дата')
      return
    }

    if (newValue < new Date()) {
      if (periodsNumber > 1) setDeadlineError('дата первого взноса должна быть больше текущей')
      else setDeadlineError('дата окончания сбора должна быть больше текущей')
      return
    }

    setProjectValues((prev) => {
      // console.log(newValue.toLocaleDateString('en-CA'))
      return {
        ...prev,
        firstDeadlineDate: newValue.toLocaleDateString('en-CA')
        // firstDeadlineDate: newValue
      }
    })
  }
  const setPeriodsNumber = (newValue) => {
    setProjectValues((prev) => {
      return {
        ...prev,
        periodsNumber: newValue
      }
    })
  }
  const setTariff = (newValue) => {
    setProjectValues((prev) => {
      return {
        ...prev,
        tariffId: newValue
      }
    })
  }

  const onChangeAmount = (value) => {
    setProjectValues((prev) => {
      return {
        ...prev,
        moneyPerMember: value
      }
    })
  }
  const setDeadlineError = useCallback((e) => {
    setError('hasDeadline', e)
  })
  const setAmountError = useCallback((e) => {
    setError('moneyPerMember', e)
  })
  const alert = (
    <StyledAlert className="fundraising-alert" icon={false} sx={{fontSize: 16}}>
      После публикации нельзя менять сумму сбора.
    </StyledAlert>
  )
  const noMemberAlert = (
    <StyledAlert className="fundraising-alert" icon={false} sx={{fontSize: 16}}>
      Необходимо выбрать участников.
    </StyledAlert>
  )
  return (
    <Wrapper>
      <Header className="header">Сбор средств</Header>
      {isPublished ? (
        <div className="inner">
          {alert}
          <AmountCalculator
            amount={moneyPerMember}
            periodsNumber={periodsNumber}
            setAmount={onChangeAmount}
            memberCount={membersAmount}
            setError={setError}
            disabled
            members={members}
            projectMembers={projectMembers}
          />
          <TariffInput
            tariff={tariff}
            setTariff={setTariff}
            tariffs={tariffs}
            disabled={true}
            addedPercent={payoutCommission}
          />
          <PeriodSlider value={periodsNumber} setValue={setPeriodsNumber} disabled={true} />
          {firstDeadlineDate && (
            <>
              <DeadlineInput
                value={firstDeadlineDate}
                setValue={setDeadline}
                periods={periodsNumber}
                disabled
              ></DeadlineInput>
              <FundraisingInfoAlert
                deadline={firstDeadlineDate}
                moneyPerMember={moneyPerMember}
                tariffs={tariffs}
                tariff={tariff}
                membersAmount={membersAmount}
                periodsNumber={periodsNumber}
                published={true}
                members={members}
                projectMembers={projectMembers}
              />
            </>
          )}
        </div>
      ) : (
        <div className="inner">
          {!membersAmount && noMemberAlert}
          <AmountCalculator
            amount={moneyPerMember}
            periodsNumber={periodsNumber}
            setAmount={onChangeAmount}
            memberCount={membersAmount}
            setError={setAmountError}
            errors={errors}
            members={members}
            projectMembers={projectMembers}
          />
          <TariffInput
            tariff={tariff}
            setTariff={setTariff}
            tariffs={tariffs}
            disabled={!membersAmount}
            addedPercent={payoutCommission}
          />
          <PeriodSlider value={periodsNumber} onChange={setPeriodsNumber} disabled={!membersAmount} />
          <DeadlineInput
            value={firstDeadlineDate}
            onChange={setDeadline}
            periods={periodsNumber}
            setError={setDeadlineError}
            errorMsg={errors['hasDeadline']}
          />
          <FundraisingInfoAlert
            deadline={firstDeadlineDate}
            moneyPerMember={moneyPerMember}
            tariffs={tariffs}
            tariff={tariff}
            membersAmount={membersAmount}
            periodsNumber={periodsNumber}
            members={members}
            projectMembers={projectMembers}
          />
        </div>
      )}
    </Wrapper>
  )
}

export default Fundraising
