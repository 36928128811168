export const getTransformedErrorsFromBack = (e) => {
  const {response} = e

  const errors = response?.data?.payload?.errors
  if (errors) {
    const transformedErrs = Object.entries(errors).map(([key, value]) => {
      const errorMsg = value?.messages?.[0] || ''
      return [key, errorMsg]
    })
    return Object.fromEntries(transformedErrs)
  }

  return []
}
