import React from 'react'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'

import {FormWrapper, Header, StyledButton} from '../../SettingsPageStyle'
import telegram from '../../../../../../images/telegram.png'
import whatsapp1 from '../../../../../../images/whatsapp1.png'
import whatsapp2 from '../../../../../../images/whatsapp2.png'
import {ImagesWrapper} from './ChatInfoModalStyle'

const ChatInfoModal = ({isOpen, handleClose}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xl'))

  return (
    <Dialog open={isOpen} onClose={handleClose} fullScreen={fullScreen} fullWidth maxWidth="md">
      <FormWrapper sx={{marginBottom: 0}}>
        <Header>Где взять ссылку на чат</Header>
        <Typography gutterBottom variant="h3" sx={{fontWeight: 'bold', marginBottom: '19px'}}>
          Telegram
        </Typography>
        <Typography gutterBottom variant="h4" sx={{fontWeight: 300, marginBottom: '19px'}}>
          В настройках чата нажмите на «Тип канала» и скопируйте ссылку.
        </Typography>
        <ImagesWrapper>
          <img alt="telegram" src={telegram} />
        </ImagesWrapper>
        <Typography variant="h3" sx={{fontWeight: 'bold', marginBottom: '19px'}}>
          WhatsApp
        </Typography>
        <Typography gutterBottom variant="h4" sx={{fontWeight: 300, marginBottom: '19px'}}>
          В меню выберите «Данные группы», а затем на «Пригласить по ссылке». После чего, можно скопировать ссылку,
          нажав на одноимённую кнопку.
        </Typography>
        <ImagesWrapper>
          <img alt="whatsapp first image" src={whatsapp1} />
          <img alt="whatsapp second image" src={whatsapp2} />
        </ImagesWrapper>
        <StyledButton variant="outlined" onClick={handleClose}>
          Понятно
        </StyledButton>
      </FormWrapper>
    </Dialog>
  )
}

export default ChatInfoModal
