import React, {useMemo, useCallback, useState, useEffect, useLayoutEffect} from 'react'
import Box from '@mui/material/Box'
import {StyledLabel} from './FundraisingStyle'
import Slider from '@mui/material/Slider'
import {styled} from '@mui/material/styles'

const marks = Array.from({length: 12}, (_, i) => i + 1).map((e) => {
  return {value: e}
})

function valuetext(value) {
  return `${value}`
}

function valueLabelFormat(value) {
  return marks.findIndex((mark) => mark === value) + 1
}
const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)'

const StyledSlider = styled(Slider)(({theme}) => ({
  color: 'ffffff',
  height: 2,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#222222',
    boxShadow: iOSBoxShadow,
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow
      }
    }
  },
  '& .MuiSlider-valueLabel': {
    display: 'none'
  },
  '& .MuiSlider-track': {
    border: 'none',
    display: 'none',
    borderRadius: '0px 4px 4px 0px',
    background: 'rgba(0, 0, 0, 0.12)'
  },
  '& .MuiSlider-rail': {
    opacity: 1,
    height: 12,
    backgroundColor: 'rgba(0, 0, 0, 0.12)'
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#ffffff',
    height: 12,
    width: 4,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: '#ffffff'
    }
  }
}))
const StyledBox = styled(Box)(() => ({
  marginBottom: 31
}))

const PeriodSlider = ({value, onChange, disabled}) => {
  const [periods, setPeriods] = useState(value)

  const onSet = (e, newValue) => {
    if (newValue !== periods) {
      setPeriods(newValue)
      onChange(newValue)
    }
  }

  const label = useMemo(() => {
    const raz = value > 1 && value < 5 ? 'раза' : 'раз'
    return (
      <StyledLabel>
        Собрать за {value} {raz}
      </StyledLabel>
    )
  }, [value])
  return (
    <StyledBox>
      {label}
      <StyledSlider
        aria-label="кол-во месяцев"
        valueLabelFormat={valueLabelFormat}
        getAriaValueText={valuetext}
        step={1}
        valueLabelDisplay="auto"
        marks={marks}
        max={12}
        min={1}
        value={periods}
        onChange={onSet}
        disabled={disabled}
      />
    </StyledBox>
  )
}

export default PeriodSlider
