import {lighten, styled} from '@mui/material/styles'
import {Typography} from '@mui/material'

export const StyledPaid = styled(Typography)(({theme}) => ({
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '19px',
  marginTop: '26px',
  color: lighten(theme.palette.primary.main, 0.5),
  [theme.breakpoints.up('md')]: {
    fontWeight: 400,
    fontSize: '19px',
    lineHeight: '29px'
  }
}))
