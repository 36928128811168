import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter as Router} from 'react-router-dom'
import {Provider} from 'react-redux'
import Theme from './theme/Theme'

import store from './store'

import App from './App'
import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <Theme>
          <App />
        </Theme>
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
