import {createSelectorsTree} from '../selectorsFactory'
import {generateReducersObject} from '../sliceMapping'
import {allInitAppEvents} from './usersStoreSliceEvents'

const initialState = {}

/** Reducers */
export const USERS_STORE = 'usersStore'
export const usersStore = generateReducersObject({
  allSliceEvents: allInitAppEvents,
  initialState,
  reducerName: USERS_STORE
})

/** Selectors */
export const usersSelectors = createSelectorsTree(initialState, USERS_STORE)
