import initSlice from '../initSlice'

const {sliceEvent, allSliceEvents} = initSlice()

const PREFIX = 'CHILDREN_'

export const setIsLoading = sliceEvent({
  type: `${PREFIX}_SET_IS_LOADING`,
  action: (childrenLoading) => ({
    payload: {
      childrenLoading
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {childrenLoading} = action.payload
    return {
      ...state,
      childrenLoading
    }
  }
})

export const getChildren = sliceEvent({
  type: `${PREFIX}_GET_CHILDREN`,
  reducer: (state) => {
    return {
      ...state,
      childrenLoading: true
    }
  }
})

export const addChild = sliceEvent({
  type: `${PREFIX}_ADD_CHILD`,
  action: (child) => ({
    payload: {
      child
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      childrenLoading: true
    }
  }
})

export const updateChildEvent = sliceEvent({
  type: `${PREFIX}_UPDATE_CHILD_EVENT`,
  action: (child) => ({
    payload: {
      child
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      childrenLoading: true
    }
  }
})

export const getChildrenSuccess = sliceEvent({
  type: `${PREFIX}_GET_CHILDREN_SUCCESS`,
  action: (children) => ({
    payload: {
      children
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {children} = action.payload
    return {
      ...state,
      children,
      childrenLoading: false
    }
  }
})

export const addChildSuccess = sliceEvent({
  type: `${PREFIX}_ADD_CHILD_SUCCESS`,
  action: (child) => ({
    payload: {
      child
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {child} = action.payload
    const {children} = state
    return {
      ...state,
      children: [...children, child],
      childrenLoading: false
    }
  }
})

export const setErrors = sliceEvent({
  type: `${PREFIX}_SET_ERRORS`,
  action: (errors) => ({
    payload: {
      errors
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {errors} = action.payload
    return {
      ...state,
      errors
    }
  }
})

export const changeChildAvatarEvent = sliceEvent({
  type: `${PREFIX}_CHANGE_CHILD_AVATAR_EVENT`,
  action: (uuid, avatar) => ({
    payload: {
      uuid,
      avatar
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      childrenLoading: true
    }
  }
})

export const deleteChildEvent = sliceEvent({
  type: `${PREFIX}_DELETE_CHILD_EVENT`,
  action: (uuid) => ({
    payload: {
      uuid
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      childrenLoading: true
    }
  }
})

export const deleteChildAvatarEvent = sliceEvent({
  type: `${PREFIX}_DELETE_CHILD_AVATAR_EVENT`,
  action: (uuid) => ({
    payload: {
      uuid
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      childrenLoading: true
    }
  }
})

export const setChildNewAvatarUrlEvent = sliceEvent({
  type: `${PREFIX}_SET_CHILD_NEW_AVATAR_EVENT`,
  action: (avatarUrl, uuid) => ({
    payload: {
      avatarUrl,
      uuid
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {avatarUrl, uuid} = action.payload
    const foundChild = state.children.find(({id}) => uuid === id)
    const foundIndex = state.children.findIndex(({id}) => uuid === id)
    if (foundIndex === -1) {
      return {
        ...state
      }
    }
    const newDraft = [
      ...state.children.slice(0, foundIndex),
      {...foundChild, avatarUrl},
      ...state.children.slice(foundIndex + 1)
    ]
    return {
      ...state,
      children: newDraft
    }
  }
})

export const allInitAppEvents = allSliceEvents
