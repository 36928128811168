import initSlice from '../initSlice'

const {sliceEvent, allSliceEvents} = initSlice()

const PREFIX = 'AUTHORIZATION_'

export const sendAuthSmsCode = sliceEvent({
  type: `${PREFIX}_SEND_SMS_CODE`,
  action: (phone) => ({
    payload: {
      phone
    }
  })
})

export const checkAuthSmsCode = sliceEvent({
  type: `${PREFIX}_CHECK_SMS_CODE`,
  action: (phone, code) => ({
    payload: {
      phone,
      code
    }
  })
})

export const registration = sliceEvent({
  type: `${PREFIX}_REGISTRATION`,
  action: (phone, password, code) => ({
    payload: {
      phone,
      password,
      code
    }
  })
})

export const saveNewPassword = sliceEvent({
  type: `${PREFIX}_SAVE_NEW_PASSWORD`,
  action: (phone, password, code) => ({
    payload: {
      phone,
      password,
      code
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      loading: true
    }
  }
})

export const authorizationRequest = sliceEvent({
  type: `${PREFIX}_AUTHORIZATION_REQUEST`,
  action: (phone, password) => ({
    payload: {
      phone,
      password
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      loading: true
    }
  }
})

export const authorizationSuccess = sliceEvent({
  type: `${PREFIX}_AUTHORIZATION_SUCCESS`,
  reducer: (state) => {
    return {
      ...state,
      isAuth: true,
      loading: false,
      loaded: true
    }
  }
})

export const logoutUser = sliceEvent({
  type: `${PREFIX}_LOGOUT_USER`
})

export const getInitDataRequests = sliceEvent({
  type: `${PREFIX}_GET_INIT_DATA_REQUESTS`
})

export const logoutSuccess = sliceEvent({
  type: `${PREFIX}_LOGOUT_SUCCESS`,
  reducer: (state) => {
    return {
      ...state,
      isAuth: false
    }
  }
})

export const checkAuthorizationRequest = sliceEvent({
  type: `${PREFIX}_CHECK_AUTHORIZATION_REQUEST`
})

export const getUserInfoRequest = sliceEvent({
  type: `${PREFIX}_USER_INFO_REQUEST`
})

export const restoreAccess = sliceEvent({
  type: `${PREFIX}_RESTORE_ACCESS`,
  action: (phone) => ({
    payload: {
      phone
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      loading: true
    }
  }
})

export const getUserInfoSuccess = sliceEvent({
  type: `${PREFIX}_USER_INFO_SUCCESS`,
  reducer: (state) => {
    return {
      ...state,
      isAuth: true,
      loading: false,
      loaded: true
    }
  }
})

export const setError = sliceEvent({
  type: `${PREFIX}_SET_ERROR`,
  action: (error) => ({
    payload: {
      error
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {error} = action.payload
    return {
      ...state,
      loading: false,
      error
    }
  }
})

export const setSuccessMsg = sliceEvent({
  type: `${PREFIX}_SET_SUCCESS_MSG`,
  action: (msg) => ({
    payload: {
      msg
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {msg} = action.payload
    return {
      ...state,
      loading: false,
      successMsg: msg
    }
  }
})

export const setIsLoading = sliceEvent({
  type: `${PREFIX}_SET_IS_LOADING`,
  action: (isLoading) => ({
    payload: {
      isLoading
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {isLoading} = action.payload
    return {
      ...state,
      loading: isLoading
    }
  }
})

export const setRequestStatus = sliceEvent({
  type: `${PREFIX}_SET_REQUEST_STATUS`,
  action: (requestStatus) => ({
    payload: {
      requestStatus
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {requestStatus} = action.payload
    return {
      ...state,
      requestStatus
    }
  }
})

export const allInitAppEvents = allSliceEvents
