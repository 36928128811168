import React, {useMemo} from 'react'
import {Button} from '@mui/material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import {useSelector} from 'react-redux'

import {getFormattedDate} from '../../../utils/getFormattedDate'
import {LeftBlock, StyledBox, InfoLabel, Title, OrangeBlock, Content, FlexLineBreak} from './RodcomItemStyle'
import {STATUSES_IN} from '../constants/myRodcomesPageConstants'
import {RK_TYPES} from '../../CreatingRodcom/constants/creatingRodcomConstants'
import InfoBox from '../../../components/Layouts/InfoBox/InfoBox'
import {directoriesSelectors} from '../../../store/directoriesStore/directoriesStore'

const RodcomItem = ({
  institutionType,
  chiefFullName,
  classUuid,
  createdAt,
  educator,
  membersCount,
  groupName,
  name,
  statusIn,
  classLetterText,
  classesText,
  onLinkClick,
  onForgetRk,
  onJoinRk,
  sx
}) => {
  const classes = useSelector(directoriesSelectors.class.selector)
  // const titleText = useMemo(() => {
  //   return `${classLetterText || ''}${classesText || ''} ${name || ''} ${groupName || ''}`
  // }, [classLetterText, classesText, name, groupName])
  const classNumber = classUuid && String(classes?.[classUuid])

  const titleText = useMemo(() => {
    return `${name || ''} ${classNumber || ''}${groupName || ''}`
  }, [classLetterText, classesText, name, groupName, classNumber])

  const createdText = useMemo(() => `Создан: ${getFormattedDate(new Date(createdAt))}`, [createdAt])

  const handleForget = () => {
    onForgetRk()
  }
  const handleRepeat = () => {
    onJoinRk()
  }
  return (
    <InfoBox sx={sx}>
      <Content sx={{flexWrap: 'wrap'}} className="rodcom-item">
        <LeftBlock>
          <Title>{titleText}</Title>
          <StyledBox>
            <InfoLabel variant="h6">{createdText}</InfoLabel>
            <InfoLabel variant="h6">Глава: {chiefFullName}</InfoLabel>
            {educator && (
              <InfoLabel variant="h6" sx={{whiteSpace: 'break-spaces !important'}}>
                {`${institutionType === RK_TYPES.SCHOOL ? 'Классрук.: ' : 'Воспитатель: '}`}
                {educator.replace(/, /g, '\n')}
              </InfoLabel>
            )}
            <InfoLabel variant="h6">Участников: {membersCount}</InfoLabel>
          </StyledBox>
        </LeftBlock>
        {statusIn === STATUSES_IN.Expelled && <OrangeBlock>Вы исключены из родкома.</OrangeBlock>}
        {statusIn === STATUSES_IN.ApplicationForMembership && (
          <OrangeBlock>Глава родкома рассматривает заявку.</OrangeBlock>
        )}
        {statusIn === STATUSES_IN.ApplicationForMembershipRejected && (
          <>
            <OrangeBlock>
              Глава родкома отклонил заявку.
              <div className="btnWrap">
                <Button
                  variant="contained"
                  sx={{border: 'none', ml: 2}}
                  type="button"
                  onClick={handleRepeat}
                  endIcon={<ArrowRightAltIcon />}
                >
                  Повторить
                </Button>
                <Button
                  variant="outlined"
                  sx={{border: 'none', ml: 2}}
                  type="button"
                  onClick={handleForget}
                  endIcon={<ArrowRightAltIcon />}
                >
                  Отказаться от участия
                </Button>
              </div>
            </OrangeBlock>
          </>
        )}
        {statusIn !== STATUSES_IN.ApplicationForMembership &&
          statusIn !== STATUSES_IN.ApplicationForMembershipRejected &&
          statusIn !== STATUSES_IN.Expelled && (
            <Button
              variant="contained"
              sx={{border: 'none'}}
              type="button"
              onClick={onLinkClick}
              endIcon={<ArrowRightAltIcon />}
            >
              Перейти
            </Button>
          )}
      </Content>
    </InfoBox>
  )
}

export default RodcomItem
