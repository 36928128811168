import {styled} from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import {Typography} from '@mui/material'

export const WrapperInfoProject = styled('div')(({theme}) => ({
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  // maxWidth: '640px',
  boxSizing: 'border-box',
  borderRadius: '12px',
  [theme.breakpoints.up('md')]: {
    borderRadius: '12px'
  },
  [theme.breakpoints.down(1224)]: {
    // margin: '0 19px'
  },
  [theme.breakpoints.down('md')]: {
    margin: 0,
    padding: '24px'
  },
  boxShadow: '0px 12px 32px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)'
}))

export const WrapperFormInfoProject = styled('div')(({theme}) => ({
  display: 'flex',
  // gap: '19px',
  position: 'relative',
  flexDirection: 'column',
  [theme.breakpoints.down(1224)]: {
    padding: '0 19px'
  },
  '& .emojiBalloon': {
    position: 'absolute',
    zIndex: 1,
    left: '20%',
    bottom: '40%'
  }
}))

export const SelectIcon = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.grey[50],
  width: '128px',
  height: '128px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: '6px'
}))

export const SelectImg = styled(SelectIcon)(({theme}) => ({
  width: '115px',
  height: '115px',
  [theme.breakpoints.up('md')]: {
    width: '128px',
    height: '128px'
  }
}))

export const Img = styled('img')(({theme}) => ({
  width: '115px',
  height: '115px',
  borderRadius: '6px',
  objectFit: 'cover',
  [theme.breakpoints.up('md')]: {
    width: '128px',
    height: '128px'
  }
}))

export const ImagesWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  gap: '6px',
  flexWrap: 'wrap',
  [theme.breakpoints.up('md')]: {
    gap: '13px'
  }
}))

export const ReactQuillWrapper = styled('div')(({theme}) => ({
  borderRadius: '6px',
  border: `1px solid ${theme.palette.grey[100]}`,
  '& .ql-container': {
    minHeight: '230px',
    border: 'none !important',
    fontSize: theme.typography?.h4?.fontSize,
    fontWeight: theme.typography?.h4?.fontWeight,
    lineHeight: theme.typography?.h4?.lineHeight,
    letterSpacing: theme.typography?.h4?.letterSpacing,
    fontFamily: theme.typography?.fontFamily,
    textDecoration: 'none'
  },
  '& .ql-blank:before': {
    fontStyle: 'normal'
  },
  '& .ql-toolbar': {
    margin: '6px',
    border: 'none',
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '3px',
    backgroundColor: theme.palette.grey[50],
    '& .ql-formats:nth-child(1)': {
      borderRight: `1px solid ${theme.palette.grey[100]}`,
      paddingRight: '8px',
      margin: 0,
      button: {
        padding: '0 4px',
        svg: {
          height: '19px !important',
          width: '29px'
        }
      }
    },
    '& .ql-formats:nth-child(2)': {
      marginLeft: '8px'
    }
  }
}))

export const Label = styled('label')(({theme}) => ({
  display: 'block',
  position: 'relative',
  cursor: 'pointer',
  color: theme.palette.primary.main,
  fontSize: '10px'
}))

export const Input = styled('input')({
  display: 'none'
})

export const Icon = styled('img')({
  width: '64px',
  height: '64px',
  marginBottom: '16px',
  borderRadius: '34px',
  objectFit: 'cover'
})

export const ImgWrapper = styled('div')({
  position: 'relative'
})

export const CloseImg = styled(CloseIcon)(({theme}) => ({
  color: theme.palette.primary.main
}))

export const Text = styled(Typography)(({theme}) => ({
  color: theme.palette.primary.main
}))

export const CloseIconWrapper = styled('div')(({theme}) => ({
  borderRadius: '50%',
  backgroundColor: theme.palette.background.paper,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  right: '6px',
  top: '6px',
  cursor: 'pointer'
}))
