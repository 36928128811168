import {styled} from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import {Radio} from '@mui/material'

export const Wrapper = styled('div')(({theme}) => ({
  padding: '24px',
  borderRadius: '12px',
  marginBottom: '24px',
  // maxWidth: '640px',
  width: '100%',
  background: theme.palette.background.paper,
  // boxShadow: '0px 12px 12px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  border: '1px solid #EDEDFF',
  boxSizing: 'border-box',
  [theme.breakpoints.up('md')]: {
    padding: '24px',
    marginBottom: '24px'
  },
  '.MuiFormControl-root': {
    marginBottom: 0,
    // display: 'flex'
    gap: 24
  },
  '.MuiFormGroup-root': {
    justifyContent: 'space-between',
    flexDirection: 'row !important'
  },
  [theme.breakpoints.down(700)]: {
    '.MuiFormGroup-root': {
      justifyContent: 'space-between',
      flexDirection: 'column !important',
      gap: '12px'
    }
  }
}))

export const Title = styled(Typography)(({theme}) => ({
  marginBottom: '24px',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '28px',
  letterSpacing: 0,
  textAlign: 'left',
  color: theme.palette.primary.main,
  [theme.breakpoints.up('md')]: {
    fontSize: '24px',
    lineHeight: '36px'
  }
}))

export const Role = styled(Typography)(({theme}) => ({
  color: theme.palette.primary.main,
  fontWeight: 700,
  marginLeft: 12,
  fontSize: 16,
  [theme.breakpoints.down('md')]: {
    fontSize: 14
  }
}))

export const Description = styled('span')(({theme}) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  marginLeft: 12,
  lineHeight: '13px',
  letterSpacing: '0.25px',
  color: theme.palette.primary.main,
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
    lineHeight: '19px'
  }
}))

export const ParentRole = styled('div')`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 45%;
  border-radius: 12px;
  padding: 13px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: ${(props) => (props.selected ? '#F5F5F5' : '#FFFFFF')};
  @media (max-width: 700px) {
    width: 100%;
  }
`

export const EducationRole = styled(ParentRole)`
  /* margin-top: 6px; */
  /* width: 45%; */
`

export const Label = styled('div')`
  margin-left: 32px;
  /* margin-right: 32px; */
  display: flex;
  flex-direction: column;
  position: relative;
`

export const InputRadio = styled(Radio)`
  position: absolute;
  top: 8px;
`
