import {call, put, takeLatest, select} from 'redux-saga/effects'
import * as giveoutStoreSliceEvents from '../../store/giveoutStore/giveoutStoreSliceEvents'
import {getDebtSummaryEvent} from '../../store/giveoutStore/giveoutStoreSliceEvents'
import {withErrorLogging} from '../../utils/errorSaga'
import {getDebtSummaryRequest, sendReportRequest, takeGiveoutRequest, checkGiveoutRequest} from 'services/giveouts'
import * as authyorizationEvents from '../../store/initStore/initStoreSliceEvents'
import {EVENT_TRANSFER_STATUSES} from 'pages/ParentCommittee/subpages/Projects/Project/constants/constants'

export function* getProjectDebtSummary(action) {
  try {
    const {uuid} = action.payload

    yield put(giveoutStoreSliceEvents.setIsLoading.action(false))
    const response = yield withErrorLogging(call(getDebtSummaryRequest, uuid))
    yield put(giveoutStoreSliceEvents.getDebtSummarySuccessEvent.action(response.data?.payload))
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(giveoutStoreSliceEvents.setIsLoading.action(false))
  }
}

export function* requestMoneySaga(action) {
  try {
    const data = action.payload

    yield put(giveoutStoreSliceEvents.setIsLoading.action(true))
    let response = yield withErrorLogging(call(takeGiveoutRequest, data))
    if (response?.data?.event === EVENT_TRANSFER_STATUSES.PROCEED_WITH_CODE.STATUS) {
      yield put(giveoutStoreSliceEvents.requireConfirmEvent.action())
      return
    }
    yield put(giveoutStoreSliceEvents.setConfirmedEvent.action())
    yield put(getDebtSummaryEvent.action(data.uuid))
    // yield put(authyorizationEvents.setSuccessMsg.action(EVENT_TRANSFER_STATUSES.GIVEOUT_DONE.MESSAGE_TO_USER))
    yield put(authyorizationEvents.setSuccessMsg.action('Код верный. Ожидайте поступления в течение нескольких минут.'))
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(giveoutStoreSliceEvents.setIsLoading.action(false))
  }
}

export function* sendReportSaga(action) {
  try {
    const data = action.payload

    yield put(giveoutStoreSliceEvents.setIsLoading.action(false))
    const response = yield withErrorLogging(call(sendReportRequest, data))
    yield put(giveoutStoreSliceEvents.sendExpenseReportSuccessEvent.action(response.data?.payload))
    yield put(getDebtSummaryEvent.action(data.uuid))
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(giveoutStoreSliceEvents.setIsLoading.action(false))
  }
}

export function* giveoutSaga() {
  yield takeLatest(giveoutStoreSliceEvents.getDebtSummaryEvent.type, getProjectDebtSummary)
  yield takeLatest(giveoutStoreSliceEvents.requestMoneyEvent.type, requestMoneySaga)
  yield takeLatest(giveoutStoreSliceEvents.sendExpenseReportEvent.type, sendReportSaga)
}
