import {call, put, takeLatest, select} from 'redux-saga/effects'
import * as profileStoreSliceEvents from '../../store/profileStore/profileStoreSliceEvents'
import {
  setCurrentUserInfo,
  setCurrentUserAvatar,
  deleteCurrentUserAvatar,
  deleteCurrentUser,
  sendControlSumRequest,
  checkControlSumRequest
} from '../../services/profile'
import {getUserInfo} from '../../services/authorization'
import {withErrorLogging} from '../../utils/errorSaga'
import {profileSelectors} from '../../store/profileStore/profileStore'
import {getTransformedErrorsFromBack} from '../../utils/getTransformedErrorsFromBack'
import {logoutUser} from '../../store/initStore/initStoreSliceEvents'
import {EVENT_STATUSES} from '../../pages/LoginPage/constants'
import * as authyorizationEvents from '../../store/initStore/initStoreSliceEvents'

const FAILEDBILLING_DECLINED_MSG = 'У вас не подключен СБП в данном банке. Обратитесь в банк для подключения.'

export function* sendNewCurrentUserData(action) {
  const userData = yield select(profileSelectors.user.selector)
  try {
    const {user} = action.payload
    const payloadUser = {...user}
    if (user.phone.includes('+')) {
      payloadUser.phone = payloadUser.phone?.slice(1)
    }

    const response = yield withErrorLogging(call(setCurrentUserInfo, payloadUser))

    if (response?.data?.event === EVENT_STATUSES.USER_PROFILE_UPDATE) {
      yield put(authyorizationEvents.setSuccessMsg.action('Данные пользователя обновлены'))
    }
    yield put(profileStoreSliceEvents.getUserInfoSuccess.action({...userData, ...user}))
  } catch (e) {
    // console.error(e)
  }
}

export function* userInit() {
  try {
    const response = yield call(getUserInfo)

    yield put(profileStoreSliceEvents.getUserInfoSuccess.action(response.data.payload))
  } catch (e) {
    const errors = getTransformedErrorsFromBack(e)
    if (errors) {
      yield put(profileStoreSliceEvents.setErrors.action(errors))
    }
  }
}

export function* setNewCurrentUserAvatar(action) {
  try {
    const {userAvatar} = action.payload
    const response = yield call(setCurrentUserAvatar, userAvatar)
  } catch (e) {
    // console.error(e)
    const errors = getTransformedErrorsFromBack(e)
    if (errors) {
      yield put(profileStoreSliceEvents.setErrors.action(errors))
    }
  }
}

export function* sendControlSumSaga(action) {
  const {bank, sbpPhone, rkId} = action.payload
  try {
    const response = yield call(sendControlSumRequest, rkId, bank, sbpPhone)
    if (response.data?.event === 'PROCEED_WITH_CODE') {
      yield put(profileStoreSliceEvents.sendControlSumEventSuccess.action(bank))
    }
  } catch (e) {
    let msg = e.response?.data?.payload?.message
    if (msg) {
      yield put(
        authyorizationEvents.setError.action(msg === 'FAILEDBILLING_DECLINED' ? FAILEDBILLING_DECLINED_MSG : msg)
      )
      yield put(profileStoreSliceEvents.sendControlSumEventFail.action(e.response?.data?.event))
    }
  }
}

export function* checkControlSumSaga(action) {
  const {bank, phone, amount} = action.payload
  try {
    const response = yield call(checkControlSumRequest, bank, phone, amount)
    if (response.data?.event === 'SUCCESS') {
      const {beneficiaryBankName, phone} = response.data.payload
      yield put(profileStoreSliceEvents.checkControlSumSuccessEvent.action(beneficiaryBankName, phone))
    } else {
      yield put(profileStoreSliceEvents.checkControlSumFailEvent.action(bank))
    }
  } catch (e) {
    // console.error(e.response?.data?.payload)
    let msg = e.response?.data?.payload?.message
    if (msg) {
      yield put(authyorizationEvents.setError.action(msg))
      yield put(profileStoreSliceEvents.checkControlSumFailEvent.action(bank))
    }
  }
}

export function* deleteUserAvatarRequest() {
  try {
    const response = yield call(deleteCurrentUserAvatar)
  } catch (e) {
    // console.error(e)
    const errors = getTransformedErrorsFromBack(e)
    if (errors) {
      yield put(profileStoreSliceEvents.setErrors.action(errors))
    }
  }
}

export function* deleteCurrentUserRequest() {
  try {
    const userData = yield select(profileSelectors.user.selector)
    yield call(deleteCurrentUser, userData?.uuid)
    yield put(profileStoreSliceEvents.successDeleteCurrentUser)
    yield put(logoutUser)
  } catch (e) {
    const errors = getTransformedErrorsFromBack(e)
    if (errors) {
      yield put(profileStoreSliceEvents.setErrors.action(errors))
    }
  }
}

export function* profileSaga() {
  yield takeLatest(profileStoreSliceEvents.sendCurrentUserData.type, sendNewCurrentUserData)
  yield takeLatest(profileStoreSliceEvents.getUserInfoRequest.type, userInit)
  yield takeLatest(profileStoreSliceEvents.setUserAvatar.type, setNewCurrentUserAvatar)
  yield takeLatest(profileStoreSliceEvents.deleteUserAvatar.type, deleteUserAvatarRequest)
  yield takeLatest(profileStoreSliceEvents.deleteCurrentUser.type, deleteCurrentUserRequest)
  yield takeLatest(profileStoreSliceEvents.sendControlSumEvent.type, sendControlSumSaga)
  yield takeLatest(profileStoreSliceEvents.checkControlSumEvent.type, checkControlSumSaga)
}
