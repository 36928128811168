export const SLIDER_SETTINGS = {
  arrows: true,
  // centerPadding: '60px',
  slidesToShow: 1,
  swipeToSlide: true,
  infinite: false,
  dots: true
  // centerMode: true,
}

export const PROJECT_TYPES = {
  REGULAR: 'REGULAR',
  ONE_OFF: 'ONE_OFF'
}

export const DONATE_TEXT =
  'Отправьте ссылку кому угодно - по ней смогут внести деньги даже незарегистрированные пользователи. Деньги поступят в копилку.'

export const EVENT_PROJECT_STATUSES = {
  PROJECT_DATA_UPDATE: {STATUS: 'PROJECT_UPDATED', MESSAGE_TO_USER: 'Проект успешно отредактирован'},
  PROJECTS_LIST_GET: {STATUS: 'PROJECTS_LIST'},
  PROJECT_GET: {STATUS: 'PROJECTS_STATUS'},
  PROJECT_CREATED: {STATUS: 'PROJECT_ADDED', MESSAGE_TO_USER: 'Проект успешно создан'},
  PROJECT_DELETED: {STATUS: 'PROJECT_DELETED', MESSAGE_TO_USER: 'Проект успешно удален'},
  PROJECT_JOINED: {STATUS: 'USER_JOINED_TO_PROJECT', MESSAGE_TO_USER: 'Вы успешно добавлены в проект'},
  PROJECT_LEAVED: {STATUS: 'USER_LEAVED_PROJECT', MESSAGE_TO_USER: 'Вы успешно покинули проект'},
  PROJECT_IMAGES_ADD: {STATUS: 'PROJECT_UPDATE_IMAGE', MESSAGE_TO_USER: ''},
  PROJECT_IMAGES_REMOVE: {STATUS: 'PROJECT_DELETE_IMAGE', MESSAGE_TO_USER: ''},
  PROJECT_LOGO_UPDATE: {STATUS: 'PROJECT_UPDATE_LOGO', MESSAGE_TO_USER: ''},
  PROJECT_PUBLISH_UPDATE: {STATUS: 'PROJECT_ACTIVATED', MESSAGE_TO_USER: ''},
  PROJECT_USERS_UPDATE: {STATUS: 'PROJECT_USERS_LIST_CHANGED', MESSAGE_TO_USER: ''}
}

export const EVENT_TRANSFER_STATUSES = {
  TRANSFER_FAILED: {STATUS: 'TRANSFER_FAILED', MESSAGE_TO_USER: 'перевод не пройден'},
  TRANSFER_INFO: {STATUS: 'TRANSFER_INFO', MESSAGE_TO_USER: 'перевод прошел успешно'},
  GIVEOUT_REQUESTED: {STATUS: 'TRANSFER_REQUESTED', MESSAGE_TO_USER: 'вывод средств запрошен'},
  GIVEOUT_DONE: {STATUS: 'TRANSFER_DONE', MESSAGE_TO_USER: 'вывод средств выполнен'},
  GIVEOUT_FAILED: {STATUS: 'TRANSFER_FAILED', MESSAGE_TO_USER: 'вывод средств не выполнен'},
  PROCEED_WITH_CODE: {STATUS: 'PROCEED_WITH_CODE', MESSAGE_TO_USER: 'операция требует подтверждения'}
}

export const INIT_PROJECT_STATE = {
  firstDeadlineDate: null,
  nextDeadlineDate: '',
  title: '',
  description: '',
  targetFacilities: '',
  startDate: new Date(),
  type: PROJECT_TYPES.ONE_OFF,
  mainImage: '',
  galleryImages: [],
  visibleForEducator: true,
  rkId: '',
  targetMoney: 0,
  moneyPerMember: null,
  status: 'DRAFT',
  amountPaid: 0,
  chatLink: '',
  periodsNumber: 1,
  // tariffId: 5, //0%
  tariffId: 1,
  emoji: {}
}
