import {styled} from '@mui/material/styles'
import {Typography} from '@mui/material'

export const StyledTypography = styled(Typography)(({theme}) => ({
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '38px',
  textAlign: 'center',
  color: theme.palette.primary.main,
  marginBottom: 24,
  [theme.breakpoints.down(1224)]: {
    // padding: '0 19px'
  },
  [theme.breakpoints.down(600)]: {
    fontSize: '32px',
    lineHeight: '26px'
  }
}))

export const ItemsWrapper = styled('div')(({theme}) => ({
  '&.isLoading': {
    display: 'flex'
  },
  [theme.breakpoints.down(1224)]: {
    // padding: '0 19px'
  },
  [theme.breakpoints.down(600)]: {
    // padding: '0 19px'
  }
}))
