import {all} from 'redux-saga/effects'
import {authorizationSaga} from './authorization/authorizationSaga'
import {profileSaga} from './profile/profileSaga'
import {creatingRkSaga} from './creatingRk/creatingRkSaga'
import {usersSaga} from './users/usersSaga'
import {childrenSaga} from './child/childSagas'
import {directoriesSaga} from './directories/directoriesSaga'
import {committeeSettingsSaga} from './committeeSettings/committeeSettingsSaga'
import {votingSaga} from './voting/votingSaga'
import {projectsSaga} from './projects/projectSagas'
import {reportingSaga} from './reporting/reportingSagas'
import {inviteSaga} from './invite/inviteSagas'
import {accrualSaga} from './accrual/accrualSagas'
import {giveoutSaga} from './giveout/giveoutSaga'
function* rootSaga() {
  yield all([
    authorizationSaga(),
    profileSaga(),
    creatingRkSaga(),
    usersSaga(),
    childrenSaga(),
    directoriesSaga(),
    committeeSettingsSaga(),
    votingSaga(),
    projectsSaga(),
    reportingSaga(),
    inviteSaga(),
    accrualSaga(),
    giveoutSaga()
  ])
}

export default rootSaga
