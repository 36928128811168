import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import {Box, Typography} from '@mui/material'

import {Wrapper} from './RegistrationFormStyle'
import SmsForm from '../SmsForm/SmsForm'
import {registration, sendAuthSmsCode, setRequestStatus} from '../../../../store/initStore/initStoreSliceEvents'
import {PhoneInput} from '../../../../components/Inputs/PhoneInput/PhoneInput'
import {PasswordInput} from '../../../../components/Inputs/PasswordInput/PasswordInput'
import {checkIfPhoneValid, getPasswordError} from '../../../../utils/validations'
import {initAppSelectors} from '../../../../store/initStore/initStore'
import {EVENT_STATUSES, TIME} from '../../constants'
import {StyledLoadingButton} from '../../../../components/StyledLoadingButton/StyledLoadingButton'
import ButtonBack from '../../../../components/ButtonBack/ButtonBack'
import {authorizationRequest} from '../../../../store/initStore/initStoreSliceEvents'

const FORM_VARIANT = {
  REG: 'registration',
  SMS: 'sms'
}

const RegistrationForm = (props) => {
  const {phone, setPhone, password, setPassword} = props
  const loading = useSelector(initAppSelectors.loading.selector)
  const requestStatus = useSelector(initAppSelectors.requestStatus.selector)

  const [repeatPassword, setRepeatPassword] = useState('')
  const [verificationCode, setVerificationCode] = useState(['', '', '', ''])
  const [activeForm, setActiveForm] = useState(FORM_VARIANT.REG)
  const [isPhoneError, setIsPhoneError] = useState(false)
  const [passwordError, setPasswordError] = useState('')
  const [repeatPasswordError, setRepeatPasswordError] = useState('')
  const [seconds, setSeconds] = useState(TIME)

  const dispatch = useDispatch()

  const sendCode = () => {
    const changeLogin = phone.replace(/[^+\d]/g, '')
    dispatch(sendAuthSmsCode.action(changeLogin))
  }

  useEffect(() => {
    if (requestStatus === EVENT_STATUSES.REGISTRATION_SEND_SMS) {
      setActiveForm(FORM_VARIANT.SMS)
      dispatch(setRequestStatus.action(''))
    }
  }, [requestStatus])

  const sendSmsHandler = (event) => {
    event.preventDefault()
    const changeLogin = phone.replace(/[^+\d]/g, '')
    const isPhoneValid = checkIfPhoneValid(changeLogin)
    if (!isPhoneValid) {
      setIsPhoneError(true)
      return
    }
    const passwordError = getPasswordError(password)
    if (passwordError) {
      setPasswordError(passwordError)
      return
    }
    if (password !== repeatPassword) {
      setRepeatPasswordError('Пароли не совпадают')
      return
    }

    sendCode()
  }

  const registrationHandler = (event) => {
    const changeLogin = phone.replace(/[^+\d]/g, '')
    event.preventDefault()
    dispatch(registration.action(changeLogin, password, verificationCode))
  }

  const onBackClick = () => {
    setActiveForm(FORM_VARIANT.REG)
  }

  return (
    <Wrapper>
      {activeForm === FORM_VARIANT.REG && (
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            alignItems: 'center'
          }}
          onSubmit={sendSmsHandler}
        >
          <PhoneInput
            autoFocus
            phone={phone}
            phoneHandler={setPhone}
            isError={isPhoneError}
            clearPhoneError={setIsPhoneError}
            className="phoneField phone"
            withIcon
          />
          <PasswordInput
            id="registration-pass"
            label="Придумайте пароль"
            password={password}
            passwordError={passwordError}
            passHandler={setPassword}
            clearPassError={setPasswordError}
            className="passField"
            placeholder="Не менее 8 символов"
            autocomplete="new-password"
          />
          <PasswordInput
            id="registration-repeat-pass"
            label="Новый пароль ещё раз"
            withShowButton={false}
            password={repeatPassword}
            passwordError={repeatPasswordError}
            passHandler={setRepeatPassword}
            clearPassError={setRepeatPasswordError}
            className="passField passFieldRepeat"
            placeholder="Для надёжности"
            autocomplete="new-password"
          />
          <StyledLoadingButton
            loading={loading}
            loadingPosition="end"
            variant="contained"
            className="loadingButton"
            type="submit"
            endIcon={<ArrowRightAltIcon className="arrowRight" />}
          >
            Отправить SMS-код
          </StyledLoadingButton>
          <Typography
            variant="span"
            sx={{
              fontSize: '14px',
              alignItems: 'center',
              fontWeight: 500,
              opacity: 0.6,
              marginTop: '12px',
              textAlign: 'center'
            }}
          >
            При входе вы принимаете{' '}
            <a href="https://rodcom.ru/page25799288.html" target="_blank">
              правила сервиса
            </a>{' '}
            и соглашаетесь на обработку персональных данных согласно{' '}
            <a href="https://rodcom.ru/page25799585.html" target="_blank">
              политике конфиденциальности
            </a>
            .
          </Typography>
        </Box>
      )}
      {activeForm === FORM_VARIANT.SMS && (
        <>
          <SmsForm
            seconds={seconds}
            setSeconds={setSeconds}
            setCode={setVerificationCode}
            code={verificationCode}
            getRegistration={registrationHandler}
            btnText="Войти"
            onSendAgain={sendCode}
            loading={loading}
          />
          <ButtonBack onClick={onBackClick} text={'Назад'} className="back" />
        </>
      )}
    </Wrapper>
  )
}

export default RegistrationForm
