import React, {useCallback} from 'react'
import StyledAlert from '../../../../components/StyledAlert/StyledAlert'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import TextInput from '../../../../components/Inputs/TextInput/TextInput'
import {checkNoHtml} from '../../../../utils/validations'

const KindergartenForm = ({errors, fieldValues, onChangeValue, isFormDisabled = false, setKindergartenErrorField}) => {
  const onEducatorChange = (value) => {
    if (/^[а-яА-ЯЁё\s-,]*$/i.test(value)) {
      onChangeValue(value, 'educator')
    }
  }

  const onGroupNumberChange = (value) => {
    if (/^[0-9]*$/i.test(value)) {
      onChangeValue(value, 'groupNumber')
    }
  }

  return (
    <>
      <TextInput
        disabled={isFormDisabled}
        errorMsg={errors.educator}
        label="Воспитатели"
        id="educator"
        value={fieldValues.educator}
        onChangeValue={onEducatorChange}
        className="separateInput settingInp"
        placeholder="ФИО всех через запятую"
      />
      <TextInput
        disabled={isFormDisabled}
        validator={checkNoHtml}
        errorMsg={errors.groupName}
        label="Название группы"
        id="groupName"
        value={fieldValues.groupName}
        onChangeValue={(value) => onChangeValue(value, 'groupName')}
        className="separateInput settingInp"
        placeholder="Например «Медвежата»"
      />
      <TextInput
        disabled={isFormDisabled}
        errorMsg={errors.groupNumber}
        label="Номер группы"
        id="groupNumber"
        value={fieldValues.groupNumber}
        onChangeValue={onGroupNumberChange}
        className="separateInput settingInp"
        placeholder="Укажите, если есть"
      />
      {errors.copy && (
        <StyledAlert icon={false} className="error">
          <div>{errors.copy}</div>
        </StyledAlert>
      )}
      <TextInput
        disabled={isFormDisabled}
        placeholder="https://"
        errorMsg={errors.chat}
        label="Родительский чат"
        id="chat"
        value={fieldValues.chat}
        onChangeValue={(value) => onChangeValue(value, 'chat')}
        withNotRequiredText
        className="separateInput chat settingInp"
      />
    </>
  )
}

export default KindergartenForm
