import {call, put, takeLatest, select} from 'redux-saga/effects'
import * as inviteStoreSliceEvents from '../../store/inviteStore/inviteStoreSliceEvents'
import * as directoriesEvents from '../../store/directoriesStore/directoriesStoreSliceEvents'

import {getRkProfileRequest} from '../../services/committeeSettings'
import {withErrorLogging} from '../../utils/errorSaga'
import {EVENT_STATUSES} from '../../pages/LoginPage/constants'

export function* getProfileInfo(action) {
  try {
    const {uuid} = action.payload
    const res = yield withErrorLogging(call(getRkProfileRequest, uuid))
    if (res?.data?.event === EVENT_STATUSES.RK_PROFILE_GET) {
      yield put(directoriesEvents.getInitDirectories.action())
      yield put(inviteStoreSliceEvents.getProfileInfoSuccessEvent.action(res?.data?.payload))
    }
  } catch (e) {
    // console.error(e)
  }
}

export function* inviteSaga() {
  yield takeLatest(inviteStoreSliceEvents.getProfileInfoEvent.type, getProfileInfo)
}
