import React from 'react'

import {InputLabelWrapper, StyledFormControl, StyledTextField} from './SearchInputStyle'

const SearchInput = ({value = '', onChangeValue, id, label, className = '', ...props}) => {
  const onChange = (event) => {
    const {value} = event.target
    onChangeValue(value)
  }
  return (
    <StyledFormControl classes={{root: className}} variant="standard" fullWidth={true}>
      {label ? (
        <InputLabelWrapper htmlFor={id} shrink>
          {label}
        </InputLabelWrapper>
      ) : null}
      <StyledTextField
        inputProps={{
          'aria-label': id
        }}
        aria-describedby={id}
        id={id}
        type="text"
        value={value}
        onChange={onChange}
        {...props}
      />
    </StyledFormControl>
  )
}

export default SearchInput
