import {call, put, takeLatest} from 'redux-saga/effects'

import * as authyorizationEvents from '../../store/initStore/initStoreSliceEvents'
import * as directoriesEvents from '../../store/directoriesStore/directoriesStoreSliceEvents'
import * as creatingRkEvents from '../../store/creatingRkStore/creatingRkStoreSliceEvents'
import * as childEvents from '../../store/childStore/childStoreSliceEvents'
import {
  getSmsCodeForRegistration,
  checkSmsCodeForRegistration,
  getAuthorization,
  registration,
  changePasswordRequest,
  saveNewPasswordRequest,
  getUserInfo,
  logout
} from '../../services/authorization'
import {withErrorLogging} from '../../utils/errorSaga'
import * as profileEvents from '../../store/profileStore/profileStoreSliceEvents'
import {EVENT_STATUSES} from '../../pages/LoginPage/constants'

export function* sendSmsCodeForregistration(action) {
  try {
    yield put(authyorizationEvents.setIsLoading.action(true))
    const {phone} = action.payload
    phone.slice(1)
    const response = yield withErrorLogging(call(getSmsCodeForRegistration, phone.slice(1)))
    yield put(authyorizationEvents.setRequestStatus.action(response.data.event))
    yield put(authyorizationEvents.setIsLoading.action(false))
  } catch (e) {
    // console.error(e)
  }
}
export function* checkSmsCodeForregistration(action) {
  try {
    yield put(authyorizationEvents.setIsLoading.action(true))
    const {code, phone} = action.payload
    const response = yield withErrorLogging(call(checkSmsCodeForRegistration, phone.slice(1), code.join('')))
    yield put(authyorizationEvents.setRequestStatus.action(response.data.event))
    yield put(authyorizationEvents.setIsLoading.action(false))
  } catch (e) {
    // console.error(e)
  }
}

export function* getRegistration(action) {
  try {
    yield put(authyorizationEvents.setIsLoading.action(true))
    const {phone, password, code} = action.payload

    const res = yield withErrorLogging(call(registration, phone.slice(1), password, code.join('')))
    yield put(authyorizationEvents.setRequestStatus.action(res?.data?.event))
    yield put(authyorizationEvents.setIsLoading.action(false))
    yield authorization(action)
  } catch (e) {
    // console.error(e)
  }
}

export function* authorization(action) {
  try {
    const {phone, password} = action.payload

    const response = yield withErrorLogging(call(getAuthorization, phone.slice(1), password))
    if (response?.data?.event === EVENT_STATUSES.AUTHORIZED) {
      localStorage.setItem('token', response.data.payload.accessToken)
      localStorage.setItem('refresh', response.data.payload.refreshToken)

      yield put(authyorizationEvents.getUserInfoRequest.action())
      yield put(authyorizationEvents.authorizationSuccess.action())
      yield put(authyorizationEvents.getInitDataRequests.action())
    }
  } catch (e) {
    console.log(e)
  }
}

export function* logoutUser() {
  try {
    yield call(logout)
    localStorage.removeItem('token')
    localStorage.removeItem('refresh')
    localStorage.removeItem('city')
    localStorage.removeItem('results')

    yield put(authyorizationEvents.logoutSuccess.action())
  } catch (e) {
    // console.error(e)
  }
}

export function* userInit() {
  try {
    const response = yield withErrorLogging(call(getUserInfo))
    const phone = response?.data?.payload.phone
    if (phone) {
      response.data.payload.phone = `+${phone}`
    }
    yield put(authyorizationEvents.getUserInfoSuccess.action(response.data.payload))
    yield put(profileEvents.getUserInfoSuccess.action(response.data.payload))

    yield put(authyorizationEvents.getInitDataRequests.action())
  } catch (e) {
    // console.error(e)
  }
}

export function* getInitData() {
  try {
    yield put(directoriesEvents.getInitDirectories.action())
    yield put(creatingRkEvents.getMyRks.action())
    yield put(childEvents.getChildren.action())
  } catch (e) {
    // console.error(e)
  }
}

export function* changePassword(action) {
  try {
    const {phone} = action.payload
    const response = yield withErrorLogging(call(changePasswordRequest, phone.slice(1)))
    yield put(authyorizationEvents.setIsLoading.action(false))
    yield put(authyorizationEvents.setRequestStatus.action(response.data.event))
  } catch (e) {
    // console.error(e)
  }
}

export function* savePassword(action) {
  try {
    const {phone, password, code} = action.payload

    const res = yield withErrorLogging(call(saveNewPasswordRequest, phone.slice(1), password, code.join('')))
    yield put(authyorizationEvents.setIsLoading.action(false))
    yield put(authyorizationEvents.setRequestStatus.action(res.data.event))
  } catch (e) {
    // console.error(e)
  }
}

export function* authorizationSaga() {
  yield takeLatest(authyorizationEvents.sendAuthSmsCode.type, sendSmsCodeForregistration)
  yield takeLatest(authyorizationEvents.checkAuthSmsCode.type, checkSmsCodeForregistration)
  yield takeLatest(authyorizationEvents.registration.type, getRegistration)
  yield takeLatest(authyorizationEvents.authorizationRequest.type, authorization)
  yield takeLatest(authyorizationEvents.getUserInfoRequest.type, userInit)
  yield takeLatest(authyorizationEvents.restoreAccess.type, changePassword)
  yield takeLatest(authyorizationEvents.saveNewPassword.type, savePassword)
  yield takeLatest(authyorizationEvents.logoutUser.type, logoutUser)
  yield takeLatest(authyorizationEvents.getInitDataRequests.type, getInitData)
}
