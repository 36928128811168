import {createSelectorsTree} from '../selectorsFactory'
import {generateReducersObject} from '../sliceMapping'
import {allInitAppEvents} from './profileStoreSliceEvents'

const initialState = {
  user: null,
  errors: {},
  isLoading: false,
  check_sum_step: 'intro'
}

/** Reducers */
export const PROFILE_STORE = 'profileStore'
export const profileStore = generateReducersObject({
  allSliceEvents: allInitAppEvents,
  initialState,
  reducerName: PROFILE_STORE
})

/** Selectors */
export const profileSelectors = createSelectorsTree(initialState, PROFILE_STORE)
