import React, {useState, useMemo, useCallback} from 'react'

import {Typography, Box} from '@mui/material'
import DateInput from 'components/Inputs/DateInput/DateInput'
import {StyledLabel} from './FundraisingStyle'
import {styled} from '@mui/material/styles'
const FullwidthDateInput = styled(DateInput)(({theme}) => ({
  '& .MuiFormControl-root': {
    maxWidth: 'inherit'
  }
}))

const DeadlineInput = ({value, onChange, setError, periods, disabled, errorMsg}) => {
  const label = periods > 1 ? 'Первый взнос' : 'Собрать до'
  const date = new Date()
  const today = new Date(date)
  const tomorrow = today.setDate(today.getDate() + 1)

  return (
    <Box id="hasDeadline">
      <StyledLabel>{label}</StyledLabel>
      <FullwidthDateInput
        className="child-modal-date-input text"
        setError={setError}
        label=""
        id="child-birth-date"
        inputFormat="dd.MM.yyyy"
        mask="__.__.____"
        openTo="day"
        value={value}
        onChange={onChange}
        minDate={tomorrow}
        fullWidth
        disabled={disabled}
        errorMsg={errorMsg}
      />
    </Box>
  )
}

export default DeadlineInput
