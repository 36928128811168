import React from 'react'
import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

import IconButton from '@mui/material/IconButton'
import {CrossIcon} from '../../icons'
import {CircularProgress} from '@mui/material'

const ModalContent = styled(Box)(({theme}) => ({
  boxSizing: 'border-box',
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  backgroundColor: '#ffffff',
  borderRadius: '6px',
  padding: '38px',
  '& .button-modal-area': {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    flexWrap: 'wrap'
  },
  [theme.breakpoints.up('lg')]: {
    width: 624
  },
  [theme.breakpoints.up('md')]: {
    '& .button-modal-area': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      flexWrap: 'wrap'
    }
  },
  [theme.breakpoints.down('md')]: {
    padding: '22px'
  }
}))

export const CommonModal = ({children, isOpen, onClose, buttonsList, isLoading}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContent>
        {isLoading ? (
          <CircularProgress sx={{margin: '0 auto', padding: 19, display: 'block'}} />
        ) : (
          <>
            <IconButton
              sx={{
                position: 'absolute',
                top: '8px',
                left: 'auto',
                right: '15px'
              }}
              onClick={onClose}
            >
              <CrossIcon />
            </IconButton>
            {children}
            <Box className="button-modal-area">{buttonsList}</Box>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
