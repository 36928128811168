import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import ErrorOutline from '@mui/icons-material/ErrorOutline'

import {FormWrapper, StyledButton} from '../../SettingsPageStyle'
import ButtonBack from '../../../../../../components/ButtonBack/ButtonBack'
import {StyledHeader} from './AssignmentSubPageStyle'
import StyledAlert from '../../../../../../components/StyledAlert/StyledAlert'
import StyledSelect from '../../../../../../components/Inputs/StyledSelect/StyledSelect'
import {CheckIcon} from '../../../../../../icons'
import {committeeSettingsSelectors} from '../../../../../../store/committeeSettingsStore/committeeSettingsStore'
import {transferMembershipEvent} from '../../../../../../store/committeeSettingsStore/committeeSettingsStoreSliceEvents'
import {CircularProgress, Button} from '@mui/material'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {notifySuccess} from '../../../../../../utils/notify'

const AssignmentSubPage = () => {
  const dispatch = useDispatch()

  const [participant, setParticipant] = useState(null)
  const [options, setOptions] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const {memberId} = params

  const {chiefUuid} = useSelector(committeeSettingsSelectors.profileInfo.selector)
  const membersOptions = useSelector(committeeSettingsSelectors.membersOptions.selector)
  const isLoading = useSelector(committeeSettingsSelectors.isLoading.selector)

  const setInitOptions = useCallback(() => {
    setOptions(membersOptions.filter((member) => member.id !== chiefUuid))
  }, [membersOptions, setOptions])

  const onBackClick = () => {
    navigate(location?.pathname.replace('/assignment', ''))
  }

  useEffect(setInitOptions, [membersOptions])

  // const notify = () =>
  //   toast.success('Запрос на передачу роли ГРК отправлен.', {
  //     position: 'top-right',
  //     autoClose: 5000,
  //     hideProgressBar: true,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: 'light'
  //   })

  const handleTransferMember = useCallback(() => {
    dispatch(transferMembershipEvent.action(participant))
    notifySuccess('Запрос на передачу роли ГРК отправлен.')
  }, [dispatch, participant])

  if (isLoading) {
    return <CircularProgress sx={{margin: '0 auto', padding: 19, display: 'block'}} />
  }

  return (
    <FormWrapper sx={{width: '735px', marginTop: '32px'}}>
      <ButtonBack sx={{marginBottom: '40px', width: 'auto'}} onClick={onBackClick} text="Отмена" className="back" />
      <StyledHeader>Передача прав главы родкома</StyledHeader>
      <StyledAlert isBlue sx={{marginBottom: '22px', fontSize: 16}} withoutIconOnMobile title="" icon={false}>
        Выберите участника из списка и отправьте запрос. Претендент получит уведомление.
        <br />
        Он должен принять роль в течение 24-х часов, иначе она останется за вами.
      </StyledAlert>
      <StyledSelect
        id="participant"
        label="Новый глава"
        value={participant}
        onChangeValue={setParticipant}
        helpText="Выберите участника"
        options={options}
      />
      <Button
        variant="contained"
        // todo
        onClick={handleTransferMember}
        startIcon={<CheckIcon color="white" />}
        sx={{marginTop: '19px'}}
      >
        Отправить запрос
      </Button>
    </FormWrapper>
  )
}

export default AssignmentSubPage
