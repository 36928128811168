import React, {useMemo, useCallback, useState} from 'react'

import {Typography} from '@mui/material'

import {BoundedVotesWrapper, StyledVoteChip} from './BoundedVotesStyle'
import {CrossIcon, PlusIcon} from '../../../../../../../icons'
import {ButtonWrapper} from '../../../../Summary/components/projects/ProjectsStyle'
import {Header} from '../../../../Settings/SettingsPageStyle'
import VoteModal from '../VoteModal/VoteModal'
import {Button} from '@mui/material'

const BoundedVotes = ({votesList, selectedVotes, setSelectedVotes}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleDelete = useCallback(
    (value) => {
      setSelectedVotes((oldVotes) => oldVotes.filter(({uuid}) => uuid !== value))
    },
    [setSelectedVotes]
  )

  const onAddClick = () => {
    setIsModalOpen(true)
  }

  const onModalClose = () => {
    setIsModalOpen(false)
  }

  const votesChips = useMemo(() => {
    return selectedVotes.map(({title, uuid}) => {
      const onDelete = () => handleDelete(uuid)
      return (
        <StyledVoteChip
          key={uuid}
          label={<Typography variant="body1">{title}</Typography>}
          onDelete={onDelete}
          deleteIcon={<CrossIcon onClick={onDelete} />}
        />
      )
    })
  }, [handleDelete, selectedVotes])

  const addVote = (vote) => {
    setSelectedVotes((oldVotes) => [...oldVotes, vote])
  }

  const filteredVotesList = useMemo(() => {
    return votesList.filter(({uuid}) => {
      return !selectedVotes.find(({uuid: id}) => id === uuid)
    })
  }, [selectedVotes, votesList])

  return (
    <BoundedVotesWrapper className="piggy">
      <Header className="header">Связанные голосования</Header>
      {votesChips}
      <Button variant="contained" color="secondary" type="button" onClick={onAddClick} startIcon={<PlusIcon />}>
        Добавить
      </Button>
      <VoteModal votesList={filteredVotesList} isOpen={isModalOpen} onClose={onModalClose} addVote={addVote} />
    </BoundedVotesWrapper>
  )
}

export default BoundedVotes
