import {createSelectorsTree} from '../selectorsFactory'
import {generateReducersObject} from '../sliceMapping'
import {allInitAppEvents} from './giveoutStoreSliceEvents'

const initialState = {
  giveouts: [],
  expenses: [],
  debtSummary: null,
  isLoading: false,
  requireConfirm: false,
  isOpenModal: false
}

/** Reducers */
export const GIVEOUT_STORE = 'giveoutStore'
export const giveoutStore = generateReducersObject({
  allSliceEvents: allInitAppEvents,
  initialState,
  reducerName: GIVEOUT_STORE
})

/** Selectors */
export const giveoutSelectors = createSelectorsTree(initialState, GIVEOUT_STORE)
