import {styled} from '@mui/material/styles'
import {Box} from '@mui/material'

export const StyledLoadingWrapper = styled(Box)(({isLoading}) => ({
  position: 'relative',
  opacity: isLoading ? 0.3 : 1,
  ' .loading': {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
  // button: {
  //   fontSize: 16,
  //   fontWeight: 500,
  //   color: '#39215E',
  //   background: 'rgba(57, 33, 94, 0.12)',
  //   border: '1px solid rgba(255, 255, 255, 1e-05)',
  //   '&:hover': {
  //     background: 'rgba(57, 33, 94, 0.24)',
  //     border: '1px solid rgba(255, 255, 255, 1e-05)'
  //   },
  //   '&:focused': {
  //     border: '1px solid rgba(255, 255, 255, 1e-05)'
  //   },
  //   '&:active': {
  //     boxShadow:
  //       ' 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
  //   },
  //   svg: {
  //     path: {
  //       stroke: '#39215E'
  //     }
  //   },
  //   '&:disabled': {
  //     svg: {
  //       path: {
  //         stroke: 'rgba(0, 0, 0, 0.26)'
  //       }
  //     }
  //   }
  // }
}))
