import React from 'react'

import {styled} from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import CheckIcon from '@mui/icons-material/Check'

import {StyledLoadingButton, StyledNarrowButton} from '../../../../components/StyledLoadingButton/StyledLoadingButton'

export const StyledBlock = styled('div')(({theme}) => ({
  maxWidth: 320,
  background: '#F5F5FF',
  // boxShadow: '0px 12px 16px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  border: '1px solid #EDEDFF',
  borderRadius: 12,
  padding: 32,
  color: theme.palette.primary.main,
  '.info': {
    fontSize: 16,
    lineHeight: '28px',
    marginBottom: 16
  },
  '.download': {
    display: 'block',
    fontSize: 16,
    lineHeight: '28px',
    marginBottom: 16,
    color: theme.palette.primary.main
  },
  // '.button': {
  //   // maxWidth: '40px'
  //   width: '100%',
  //   fontSize: 16,
  //   color: '#39215E',
  //   fontWeight: 500,
  //   background: 'rgba(57, 33, 94, 0.12)',
  //   border: '1px solid rgba(255, 255, 255, 1e-05)',
  //   '&:hover': {
  //     background: 'rgba(57, 33, 94, 0.24) !important',
  //     border: '1px solid rgba(255, 255, 255, 1e-05)'
  //   },
  //   '&:focused': {
  //     border: '1px solid rgba(255, 255, 255, 1e-05)'
  //   },
  //   '&:active': {
  //     boxShadow:
  //       ' 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
  //   }
  // },
  '@media(max-width: 480px)': {
    borderRadius: 0,
    padding: 24
  },
  '@media(max-width: 1000px)': {
    maxWidth: 'none'
  }
}))

const SubmitBlock = ({isLoading}) => {
  return (
    <StyledBlock>
      <Typography className="info">
        Мы подготовили договор о совместной деятельности родителей в родкоме. Это правила, которые помогут решать задачи
        совместно. Прозрачно и без споров.
      </Typography>
      <a
        className="download"
        target="_blank"
        href="https://storage.yandexcloud.net/bucket576787/docs/%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80%20%D0%BE%20%D1%81%D0%BE%D0%B2%D0%BC%D0%B5%D1%81%D1%82%D0%BD%D0%BE%D0%B9%20%D0%B4%D0%B5%D1%8F%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8%20%D0%A0%D0%BE%D0%B4%D0%BA%D0%BE%D0%BC.pdf"
        download
      >
        Скачать договор о совместной деятельности
      </a>
      <StyledNarrowButton
        startIcon={<CheckIcon color="white" />}
        className="button"
        loading={isLoading}
        loadingPosition="start"
        variant="contained"
        type="submit"
      >
        Зарегистрировать родком
      </StyledNarrowButton>
    </StyledBlock>
  )
}

export default SubmitBlock
