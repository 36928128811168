import {styled} from '@mui/material/styles'
import {Box} from '@mui/material'
import Select from '@mui/material/Select'

export const ReportsPageBox = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  // boxShadow: '0px 12px 48px rgba(57, 33, 94, 0.06), 0px 2.4px 4px rgba(57, 33, 94, 0.04)',
  border: '1px solid #EDEDFF',
  padding: '32px',
  gap: '24px',
  background: '#FFFFFF',
  borderRadius: '12px',
  marginTop: 16
}))

export const ReportTableStyledSelect = styled(Select)(({theme}) => ({
  '& .MuiSelect-select': {
    background: 'transparent',
    fontWeight: 300,
    fontSize: '32px',
    lineHeight: '32px',
    color: '#000000',
    minHeight: '16px'
  },
  '& .MuiInputBase-input': {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '0 !important',
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: '16px',
    lineHeight: {xs: '16px', lg: '32px'}
  },
  '& fieldset': {
    border: 'none'
  },
  '& .select-icon': {
    top: 'calc(50% - 10px)'
  },
  [theme.breakpoints.down('md')]: {
    // maxWidth: '176px'
    // '& .MuiSelect-icon': {
    //   width: '13px',
    //   height: '13px',
    //   top: 'calc(50% - 5px)'
    // }
  },
  [theme.breakpoints.up('md')]: {
    '& .parent-committee-name': {
      fontSize: '13px',
      lineHeight: '19px'
    }
  }
}))
