export const PublishStatuses = {DRAFT: 'Черновик', PUBLISHED: 'Публикуется', COMPLETED: 'Закончено'}
export const PublishStatusesEnum = {DRAFT: 'DRAFT', PUBLISHED: 'PUBLISHED', COMPLETED: 'COMPLETED'}

export const TypesEnum = {
  ONE_OFF: 'ONE_OFF',
  REGULAR: 'REGULAR',
  TARGET: 'TARGET'
}

export const Types = {
  ONE_OFF: 'Разово',
  REGULAR: 'Ежемесячно',
  TARGET: 'Всего'
}

export const CHIP_TYPE = {
  RECORD: 'RECORD',
  APP: 'APP'
}

export const applicationOptions = ['Одобрить', 'Отклонить']
export const recordOptions = ['Редактировать', 'Удалить']

export const APP_STATES = {
  Approved: 'Approved',
  New: 'New'
}
