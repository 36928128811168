import React, {useCallback} from 'react'

import {imageURL} from 'utils/imageURL'

const Image = ({srcUuid, alt, className}) => {
  const hideImage = useCallback((e) => {
    e.target.style.visibility = 'hidden'
  }, [])

  if (!srcUuid) {
    return <div className={className} />
  }
  return <img className={className} src={imageURL(srcUuid)} alt={alt} onError={hideImage} />
}

export default Image
