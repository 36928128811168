import React from 'react'

import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'

const ParentCheckbox = ({checkedItems, members, handleChangeParent, childrenCount}) => {
  return (
    <FormControlLabel
      label={<Typography className="parentCheckbox" variant="h4" sx={{fontSize: 16}}>{`Выбрать всеx`}</Typography>}
      control={
        <Checkbox
          checked={checkedItems.length === members?.length}
          indeterminate={Boolean(checkedItems.length && checkedItems.length !== members?.length)}
          onChange={handleChangeParent}
          classes={{
            root: 'checkbox',
            checked: 'checkboxChecked'
          }}
        />
      }
    />
  )
}

export default ParentCheckbox
