import {styled} from '@mui/material/styles'
import {Chip, DialogContent} from '@mui/material'

export const BoundedVotesWrapper = styled('div')(({theme}) => ({
  padding: 19,
  display: 'flex',
  flexDirection: 'column',
  background: '#FFFFFF',
  width: '50%',
  borderRadius: 12,
  // maxWidth: '640px',
  boxSizing: 'border-box',
  justifyContent: 'space-between',
  boxShadow: '0px 12px 32px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  '.title': {
    marginBottom: 19
  },
  '.header': {
    marginBottom: '24px !important'
  },
  '.add-button': {
    border: 0,
    // maxWidth: '160px',
    margin: '13px auto 0',
    '&:hover': {
      background: 'rgba(57, 33, 94, 0.24)',
      border: '1px solid rgba(255, 255, 255, 1e-05)'
    }
  },
  [theme.breakpoints.down(1224)]: {
    // margin: '0 19px'
  },
  [theme.breakpoints.up('lg')]: {
    // maxWidth: 320
  },
  [theme.breakpoints.down('md')]: {
    margin: 0,
    borderRadius: 12
  },
  [theme.breakpoints.down(1000)]: {
    width: '100%'
  }
}))

export const StyledVoteChip = styled(Chip)(({theme}) => ({
  width: '100%',
  color: theme.palette.primary.main,
  background: theme.palette.background.default,
  padding: 13,
  height: 'auto',
  borderRadius: 6,
  alignItems: 'flex-start',
  boxShadow: '0px 6px 6px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  marginBottom: 6,
  '& span': {
    width: '100%',
    padding: '0 19px 0 0',
    whiteSpace: 'break-spaces'
  },
  '& svg': {
    width: 29,
    marginLeft: 13,
    margin: '3px -5px 0 0 !important'
  }
}))

export const ModalContent = styled(DialogContent)(({theme}) => ({
  '& .close-button': {
    // display: 'none',
    fontSize: 16,
    minWidth: '150px'
  },
  '& .add-button': {
    minWidth: '150px',
    marginLeft: 'auto',
    fontSize: 16,
    color: '#39215E',
    background: 'rgba(57, 33, 94, 0.12)',
    border: '1px solid rgba(255, 255, 255, 1e-05)',
    '&:hover': {
      background: 'rgba(57, 33, 94, 0.24)',
      border: '1px solid rgba(255, 255, 255, 1e-05)'
    },
    '&:focused': {
      border: '1px solid rgba(255, 255, 255, 1e-05)'
    },
    '&:active': {
      boxShadow:
        ' 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
    },
    svg: {
      path: {
        stroke: '#39215E'
      }
    },
    '&:disabled': {
      opacity: 0.5
    }
  },
  '& h4': {
    color: theme.palette.primary.main,
    marginBottom: 24
  },
  [theme.breakpoints.down('md')]: {
    '& .close-button': {
      display: 'block',
      marginRight: 'auto'
    },
    '& .add-button': {
      marginLeft: 0
    }
  }
}))

export const ButtonsBlock = styled(DialogContent)(() => ({
  display: 'flex',
  padding: 0
}))
