export const getOptionsFromDirectories = (directory) => {
  if (directory) {
    return Object.entries(directory).map(([key, val]) => ({label: val, id: key}))
  }
  return []
}

export const getOptionsFromDirectoriesWithOrder = (directory) => {
  if (directory && directory.length > 0) {
    return directory.map((x) => ({label: x.name, id: x.uuid}))
  }
  return []
}

export const findSelectedValueInDirectoriesWithOrder = (directory, value) => {
  if (directory) {
    return directory.find((x) => x.name === value)?.uuid || ''
  }
  return ''
}
export const findSelectedValueInDirectories = (directory, value) => {
  if (directory) {
    return Object.entries(directory).find(([, val]) => val === value)?.[0] || ''
  }
  return ''
}
