import {styled} from '@mui/material/styles'
import {Box, Tab} from '@mui/material'

export const TabsWrapper = styled(Box)(({theme}) => ({
  // marginTop: '38px',
  [theme.breakpoints.up('md')]: {
    // marginBottom: '64px'
  }
}))

export const TabWrapper = styled(Tab)(({theme, active, onClick}) => ({
  color: theme.palette.primary.main,
  fontSize: 14,
  fontWeight: 600,
  padding: '6px 12px',
  // opacity: active ? '1' : '0.68',
  onClick: onClick,
  '& .MuiTouchRipple-child': {
    backgroundColor: 'transparent !important'
  }
}))
