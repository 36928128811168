import {styled} from '@mui/material/styles'
import React from 'react'
import {Typography} from '@mui/material'

import VoteOptionCreateItem from '../VoteOptionCreateItem/VoteOptionCreateItem'
import {optionKey} from '../../VoteCreatePage'

export const StyledOptionList = styled('div')(({theme}) => ({
  '& .title': {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '19px',
    marginBottom: '19px',
    marginTop: '32px',
    [theme.breakpoints.up('md')]: {
      marginTop: '32px',
      fontSize: '16px',
      lineHeight: '29px',
      fontWeight: 700
    }
  }
}))

const VoteOptionCreateList = ({options, votingErrors, onChangeValue, removeOption, disabled}) => {
  const removeDisabled = options.length < 3

  return (
    <StyledOptionList>
      <Typography className="title">Варианты</Typography>
      {options.map((option) => {
        return (
          <VoteOptionCreateItem
            removeDisabled={removeDisabled}
            disabled={disabled}
            key={option.number}
            optionNumber={option.number}
            value={option}
            onChangeValue={onChangeValue}
            removeOption={removeOption}
            errorMsg={votingErrors[optionKey(option.number)]}
          />
        )
      })}
    </StyledOptionList>
  )
}

export default VoteOptionCreateList
