import React, {useMemo, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import InputAdornment from '@mui/material/InputAdornment'
import Popover from '@mui/material/Popover'

import {ButtonsWrapper, FiltersWrapper, StyledCalendar, StyledInputAdornment} from './CommonFilter/CommonFilterStyle'
import SearchInput from '../../../../../components/Inputs/SearchInput/SearchInput'
import {CalendarIcon, CrossIcon, LoupeIcon} from '../../../../../icons'
import AutocompleteInput from '../../../../../components/Inputs/AutocompleteInput/AutocompleteInput'
import AutocompleteWithCheckboxes from '../../../../../components/Inputs/AutocompleteWithCheckboxes/AutocompleteWithCheckboxes'
import {SecondButtonWrapper} from '../../Summary/components/projects/ProjectsStyle'
import {ButtonWrapper} from '../../Summary/components/Invite/InviteStyle'
import StyledSelect from '../../../../../components/Inputs/StyledSelect/StyledSelect'
import {PublishStatusesEnum} from '../../constants/parentCommitteeConstants'
import {RK_ROLE} from '../../../../JoiningRodcom/constants'

import {committeeSettingsSelectors} from 'store/committeeSettingsStore/committeeSettingsStore'
import {projectsSelectors} from 'store/projectsStore/projectsStore'
import {Button} from '@mui/material'

const prepare = (ma) =>
  ma?.map((e) => ({
    id: e.id,
    title: e.label
  }))

const FiltersComponent = ({
  purpose,
  setPurpose,
  isRangeSelected,
  onClearPeriodClick,
  handlePeriodClick,
  formattedPeriod,
  setPeriod,
  isPeriodPopoverOpen,
  periodAnchorEl,
  handleClosePeriod,
  onDateRangeChange,
  rangeValue,
  projects,
  handleChangeProject,
  members,
  handleChangeMember,
  handleApplyClick,
  handleCloseModal,
  handleReset
}) => {
  const memberOptions = prepare(useSelector(committeeSettingsSelectors.membersOptions.selector))

  // const [memberOptionState, setMemberOptionState] = useState(prepare(memberOptions))
  // useEffect(() => prepare(memberOptions), [memberOptions])
  // const onMemberChange = useCallback((e, newv) => {
  //   setMemberOptionState(newv)
  //   handleChangeMember(e, newv)
  // }, [])
  const roles = useSelector(committeeSettingsSelectors.roles.selector)
  const isEducator = useMemo(() => roles?.includes(RK_ROLE.EDUCATOR), [roles])

  const projectOptions = useSelector(projectsSelectors.projectsList.selector)
  let actualProjects = projectOptions
    ?.filter((project) => {
      const isVisibleForEducators = project.visibleForEducator || !isEducator
      return project.status === PublishStatusesEnum.PUBLISHED && isVisibleForEducators
    })
    .filter((date) => new Date(date.lastDeadlineDate) > new Date() || date.type === 'PIGGY')
    .sort((a, b) => new Date(a.lastDeadlineDate) - new Date(b.lastDeadlineDate))
  // .sort((date) => new Date(date.lastDeadlineDate) - new Date())
  actualProjects = [
    ...actualProjects,
    ...projectOptions
      .filter(
        (project) => project.status === PublishStatusesEnum.PUBLISHED && (project.visibleForEducator || !isEducator)
      )
      .filter((i) => new Date(i.lastDeadlineDate) < new Date() && i.type !== 'PIGGY')
  ]

  return (
    <FiltersWrapper>
      <SearchInput
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LoupeIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <StyledInputAdornment className="adornment-close" onClick={() => setPurpose('')} position="start">
              <CrossIcon />
            </StyledInputAdornment>
          )
        }}
        label="Назначение"
        id="child-firstName"
        value={purpose}
        className="naznach-input"
        onChangeValue={setPurpose}
        placeholder="Поиск по назначению"
      />
      <SearchInput
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CalendarIcon />
            </InputAdornment>
          ),
          endAdornment: isRangeSelected ? (
            <StyledInputAdornment className="adornment-close" onClick={onClearPeriodClick} position="start">
              <CrossIcon />
            </StyledInputAdornment>
          ) : null,
          readOnly: true
        }}
        label="Период"
        className="period-input"
        id="period-input"
        aria-describedby="period"
        onClick={handlePeriodClick}
        value={formattedPeriod}
        onChangeValue={setPeriod}
        placeholder="За всё время"
      />
      <Popover
        id="period"
        open={isPeriodPopoverOpen}
        anchorEl={periodAnchorEl}
        onClose={handleClosePeriod}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <StyledCalendar selectRange allowPartialRange onChange={onDateRangeChange} value={rangeValue} />
      </Popover>
      <AutocompleteInput
        label="Проект"
        value={projects}
        multiple={false}
        onChange={handleChangeProject}
        id="project"
        options={actualProjects}
        readOnly={true}
      />
      <AutocompleteInput
        label="Участники"
        id="membersAutocomplete"
        value={members}
        onChange={handleChangeMember}
        options={memberOptions}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id
        }}
        readOnly={true}
      />
      <ButtonsWrapper>
        <Button variant="outlined" className="clearButton" type="button" onClick={handleReset}>
          Сбросить
        </Button>
        <Button variant="contained" type="button" onClick={handleApplyClick}>
          Применить
        </Button>
      </ButtonsWrapper>
    </FiltersWrapper>
  )
}

export default FiltersComponent
