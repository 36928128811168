import initSlice from '../initSlice'

const {sliceEvent, allSliceEvents} = initSlice()

const PREFIX = 'GIVEOUTS_'

export const setIsLoading = sliceEvent({
  type: `${PREFIX}_SET_IS_LOADING`,
  action: (isLoading) => ({
    payload: {
      isLoading
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {isLoading} = action.payload
    return {
      ...state,
      isLoading
    }
  }
})

export const setIsOpenModal = sliceEvent({
  type: `${PREFIX}_SET_IS_OPEN_MODAL`,
  action: (isOpen, step) => ({
    payload: {
      isOpen,
      step
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {isOpen, step} = action.payload
    if (isOpen)
      return {
        ...state,
        isOpenModal: isOpen
      }
    else
      return {
        ...state,
        isOpenModal: isOpen,
        requireConfirm: false
      }
  }
})

export const requireConfirmEvent = sliceEvent({
  type: `${PREFIX}_REQUIRE_CONFIRM`,
  reducer: (state, action = {type: '', payload: {}}) => {
    return {
      ...state,
      requireConfirm: true
    }
  }
})

export const setConfirmedEvent = sliceEvent({
  type: `${PREFIX}_CONFIRMED`,
  reducer: (state, action = {type: '', payload: {}}) => {
    return {
      ...state,
      requireConfirm: false,
      isOpenModal: false
    }
  }
})

export const getDebtSummaryEvent = sliceEvent({
  type: `${PREFIX}_GET_SUMMARY_EVENT`,
  action: (uuid) => ({
    payload: {
      uuid
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const getDebtSummarySuccessEvent = sliceEvent({
  type: `${PREFIX}_GET_SUMMARY_SUCCESS_EVENT`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {data} = action.payload
    return {
      ...state,
      debtSummary: data,
      isLoading: false
    }
  }
})

export const requestMoneyEvent = sliceEvent({
  type: `${PREFIX}_REQUEST_MONEY_EVENT`,
  action: ({uuid, amount, description, code}) => ({
    payload: {
      uuid,
      amount,
      description,
      code
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const requestMoneySuccessEvent = sliceEvent({
  type: `${PREFIX}_REQUEST_MONEY_SUCCESS_EVENT`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state, action) => {
    const {data} = action.payload

    return {
      ...state,
      isLoading: false,
      requireConfirm: false
    }
  }
})

export const sendExpenseReportEvent = sliceEvent({
  type: `${PREFIX}_REPORT_EXPENSE_EVENT`,
  action: (report) => ({
    payload: report
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const sendExpenseReportSuccessEvent = sliceEvent({
  type: `${PREFIX}_REPORT_EXPENSE_SUCCESS_EVENT`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state, action) => {
    const {report} = action.payload
    return {
      ...state,
      isLoading: false
    }
  }
})

export const allInitAppEvents = allSliceEvents
