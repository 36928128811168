import React, {useCallback, useMemo, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Box, CircularProgress} from '@mui/material'
import {useTheme} from '@mui/material/styles'

import {projectsSelectors} from '../../../../store/projectsStore/projectsStore'
import ReportTable from './components/ReportTable'
import InfoBlock from './components/InfoBlock'
import FilterSelect from './components/FilterSelect'
import CommonFilter from './components/CommonFilter/CommonFilter'
import {tableOperationsFields} from './components/RowTableAllOperations/constants'
import {committeeSettingsSelectors} from '../../../../store/committeeSettingsStore/committeeSettingsStore'
import {childrenSelectors} from '../../../../store/childStore/childStore'
import {getParentCommitteeOperationsEvent} from '../../../../store/committeeSettingsStore/committeeSettingsStoreSliceEvents'
import SummaryBlock from './components/SummaryBlock'
import useErrorBoundary from 'use-error-boundary'
import InfoBox from '../../../../components/Layouts/InfoBox/InfoBox'
import {Typography} from '@mui/material'

import {All, Projects, Members} from './Transformers'
import {getHeadersByMode} from './headers'
import {map, sum} from 'ramda'
import {ReportsPageBox} from './ReportsPageStyle'

const available = (projectStat) => (x) =>
  x?.amount - x?.amountInProcessing - (x?.commission - x?.commissionInProcessing)

export const filterValues = {
  all: 'Все операции',
  members: 'По участникам',
  projects: 'По проектам'
}

// const OperationFilter = () => {
//   const dispatch = useDispatch()
//   const rkId = useSelector(committeeSettingsSelectors.uuid.selector)

//   const onApplyFilter = useCallback(
//     (value) => {
//       dispatch(getParentCommitteeOperationsEvent.action(rkId, value))
//     },
//     [rkId]
//   )
//   return <CommonFilter onApply={onApplyFilter} />
// }

const ReportsPage = () => {
  const {ErrorBoundary, didCatch, error: boundaryError} = useErrorBoundary()
  const dispatch = useDispatch()
  const theme = useTheme()
  const rkId = useSelector(committeeSettingsSelectors.uuid.selector)

  const [filterValue, setFilterValue] = useState(filterValues.all)
  const [commonFilterData, setCommonFilterData] = useState({})
  const projectsList = useSelector(projectsSelectors.projectsList.selector)

  const operations = useSelector(committeeSettingsSelectors.operations.selector)
  const memberOptions = useSelector(committeeSettingsSelectors.membersOptions.selector)
  const children = useSelector(childrenSelectors.children.selector)
  const isLoading = useSelector(committeeSettingsSelectors.isLoading.selector)

  const handleChangeFilterSelect = useCallback((value) => {
    setFilterValue(value)
  }, [])

  const onApplyFilter = useCallback(
    (value) => {
      setCommonFilterData(value)
      rkId && dispatch(getParentCommitteeOperationsEvent.action(rkId, {...value, description: ''}))
    },
    [rkId]
  )

  const [dataTable, setDataTable] = useState([])

  const projectsRows = useMemo(() => {
    return Projects(operations, projectsList, memberOptions)
  }, [projectsList, memberOptions, operations, filterValue])

  const membersRows = useMemo(() => {
    return Members(operations, projectsList, memberOptions)
  }, [operations, projectsList, memberOptions, filterValue])

  const operationRows = useMemo(() => {
    return All(operations, projectsList, memberOptions, children)
  }, [operations, projectsList, memberOptions, filterValue, children, commonFilterData])

  useEffect(() => {
    if (filterValue === 'Все операции') {
      setDataTable(operationRows)
    } else if (filterValue === 'По проектам') {
      setDataTable(projectsRows)
    } else if (filterValue === 'По участникам') {
      setDataTable(membersRows)
    }
  }, [filterValue, operationRows])

  const getHeadersByFilter = useMemo(() => {
    return getHeadersByMode(filterValue)
  }, [filterValue])

  const summary = useMemo(() => {
    if (!operations || !operations.operations)
      return {
        endBalance: 0,
        startBalance: 0,
        paid: 0,
        expenses: 0
      }

    const endBalance = sum(operations?.endBalances?.map((x) => x?.available))
    return {
      endBalance,
      startBalance: operations?.startBalance,
      paid: operations?.paid,
      expenses: operations?.expenses
    }
  }, [operations])

  useEffect(() => {
    if (commonFilterData.description) {
      setDataTable(
        operationRows.filter((data) =>
          data.paymentDescription.toUpperCase().includes(commonFilterData?.description.toUpperCase())
        )
      )
    }
  }, [commonFilterData, operationRows])

  const getProjectTitle = (project) => {
    return project?.title ?? ''
  }
  const getProjectName = (id, projectsList) => getProjectTitle(projectsList?.find((m) => m.uuid === id))
  const projectName = getProjectName(commonFilterData.projects?.[0], projectsList)

  return (
    <>
      {didCatch ? (
        <p>Ошибка</p>
      ) : (
        <ErrorBoundary>
          <Box
            sx={{
              marginTop: '24px',
              marginLeft: 0,
              // marginTop: 0,
              fontFamily: theme.typography.fontFamily
            }}
          >
            {/* <OperationFilter /> */}
            <CommonFilter onApply={onApplyFilter} />
            <ReportsPageBox>
              {/* <FilterSelect
                filterValue={filterValue}
                setFilterValue={handleChangeFilterSelect}
                filterValues={filterValues}
              /> */}
              <Typography
                variant="h4"
                sx={{fontSize: '24px', alignItems: 'center', fontWeight: 700, lineHeight: '32px'}}
              >
                {filterValue}
              </Typography>
              {isLoading ? (
                <CircularProgress sx={{margin: '0 auto', padding: 24, display: 'block'}} />
              ) : !commonFilterData?.description && !commonFilterData?.members?.length ? (
                <SummaryBlock
                  startBalance={summary.startBalance}
                  endBalance={summary.endBalance}
                  paid={summary.paid}
                  summary={operations}
                  expenses={summary.expenses}
                  filterProject={commonFilterData?.projects?.length}
                  isPiggy={projectName === 'Копилка'}
                  isSelectPeriod={!!commonFilterData?.period}
                />
              ) : null}

              {!isLoading &&
                (dataTable ? (
                  <ReportTable
                    tableKind={filterValue}
                    headers={getHeadersByFilter}
                    data={dataTable}
                    projectFilter={commonFilterData?.projects?.length}
                  />
                ) : (
                  <Typography variant="h4" sx={{fontSize: '16px', alignItems: 'center', fontWeight: 700}}>
                    Не найдено результатов. Измените условия фильтрации.
                  </Typography>
                ))}

              {/*{dataTable ? (*/}
              {/*  <ReportTable*/}
              {/*    tableKind={filterValue}*/}
              {/*    headers={getHeadersByFilter}*/}
              {/*    data={dataTable}*/}
              {/*    projectFilter={commonFilterData?.projects?.length}*/}
              {/*  />*/}
              {/*) : (*/}
              {/*  <Typography variant="h4" sx={{fontSize: '16px', alignItems: 'center', fontWeight: 700}}>*/}
              {/*    Не найдено результатов. Измените условия фильтрации.*/}
              {/*  </Typography>*/}
              {/*)}*/}
            </ReportsPageBox>
          </Box>
        </ErrorBoundary>
      )}
    </>
  )
}

export default ReportsPage
