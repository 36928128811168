import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import {refreshToken} from './authorization'
import {logoutSuccess} from '../store/initStore/initStoreSliceEvents'
import store from '../store/index'

export const URL = process.env.REACT_APP_API_URL || 'https://staging.rodcom.su'

export const BASE_URL = `${URL}/api/v1`

const defaultConfig = {
  withCredentials: false,
  baseURL: BASE_URL
}

const httpClient = axios.create(defaultConfig)

const refreshAuth = () => {
  const refresh = localStorage.getItem('refresh') || ''
  return refreshToken(refresh)
    .then((response) => {
      // noinspection JSUnresolvedVariable
      localStorage.setItem('token', response.data.payload.accessToken)
      localStorage.setItem('refresh', response.data.payload.refreshToken)
      return Promise.resolve()
    })
    .catch(() => {
      localStorage.removeItem('token')
      localStorage.removeItem('refresh')
      store.dispatch(logoutSuccess.action())
      // console.error('Пользователь не авторизован')
    })
}

export const configureApiInstance = (instance) => {
  httpClient.interceptors.request.use(async (config) => {
    config.headers = {
      ...config.headers
    }

    const token = localStorage.getItem('token')
    config.headers.Authorization = `Bearer ${token}`
    return config
  })

  createAuthRefreshInterceptor(instance, refreshAuth)
}

configureApiInstance(httpClient)

export default httpClient
