import React, {useCallback, useEffect, useMemo} from 'react'
import {format, intervalToDuration} from 'date-fns'
import {Box, Button} from '@mui/material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import Avatar from '@mui/material/Avatar'
import AvatarGroup from '@mui/material/AvatarGroup'
import {avatarURL, imageURL} from '../../../../../../utils/imageURL'
import {
  ActiveProjectWrapper,
  ButtonWrapper,
  DateBlock,
  Loader,
  PaymentBlock,
  WrapperIconDatePayment,
  WrapperImg,
  WrapperLoader,
  WrapperText
} from './ProjectsStyle'
import {Types} from '../../../constants/parentCommitteeConstants'
import {estimatedTime} from '../../../../../../utils/EstimatedTime'
import ProgressBar from '../../../Votes/components/ProgressBar/ProgressBar'

import {Emoji} from 'emoji-picker-react'
import data from '@emoji-mart/data/sets/4/apple.json'
import {init} from 'emoji-mart'

init({data})

const ActiveProject = ({project, percent, onClick, isDraft, isAltProgress = false}) => {
  const isPiggy = project.type === 'PIGGY'
  const payment = () => (
    <span style={{fontSize: 14, fontWeight: 700, color: '#39215E'}}>
      {Types[project.type]} по<strong>&nbsp; {project.totalPerMember} ₽</strong>
    </span>
  )

  // useEffect(() => {
  //   console.log(project?.emoji?.id)
  // }, [project?.emoji])

  const avatars = useMemo(() => {
    return project?.photos?.guids.map((url) => {
      return <Avatar sx={{width: '32px', height: '32px'}} key={url} alt={`${url}`} src={imageURL(url)} />
    })
  }, [])

  const dateTime = () => {
    if (project?.type !== 'PIGGY') {
      const estimated = estimatedTime(project?.lastDeadlineDate)
      switch (true) {
        case project?.status === 'COMPLETED':
          return (
            <DateBlock>
              Проект завершен
              {/*<br />*/}
              {/*<span style={{fontSize: 16, fontWeight: 600}}>*/}
              {/*  {project?.lastDeadlineDate ? format(new Date(project?.lastDeadlineDate), 'dd.MM.yyyy') : ''}*/}
              {/*</span>*/}
            </DateBlock>
          )

        case project?.amountPaid === project?.targetWithCommission:
          return (
            <DateBlock>
              Ура! Мы собрали всю сумму
              {/*<br />*/}
              {/*<span style={{fontSize: 16, fontWeight: 600}}>*/}
              {/*  {project?.lastDeadlineDate ? format(new Date(project?.lastDeadlineDate), 'dd.MM.yyyy') : ''}*/}
              {/*</span>*/}
            </DateBlock>
          )

        case project?.amountPaid !== project?.targetWithCommission && !estimated:
          return (
            <DateBlock>
              Поторопитесь, еще можно сдать деньги!
              {/*<br />*/}
              {/*<span style={{fontSize: 16, fontWeight: 600}}>*/}
              {/*  {project?.lastDeadlineDate ? format(new Date(project?.lastDeadlineDate), 'dd.MM.yyyy') : ''}*/}
              {/*</span>*/}
            </DateBlock>
          )

        default:
          return (
            <DateBlock>
              Собираем еще:
              <br /> <span style={{fontSize: 16, fontWeight: 600}}>{estimated}</span>
            </DateBlock>
          )
      }
      //
      // if (!estimated) {
      //   return (
      //     <DateBlock>
      //       Сбор денег завершен
      //       <br />
      //       <span style={{fontSize: 16, fontWeight: 600}}>
      //         {project?.lastDeadlineDate ? format(new Date(project?.lastDeadlineDate), 'dd.MM.yyyy') : ''}
      //       </span>
      //     </DateBlock>
      //   )
      // } else {
      //   return (
      //     <DateBlock>
      //       Собираем еще:
      //       <br /> <span style={{fontSize: 16, fontWeight: 600}}>{estimated}</span>
      //     </DateBlock>
      //   )
      // }
    } else {
      return (
        <DateBlock>
          Баланс копилки
          <br />{' '}
          <span style={{fontSize: 48, fontWeight: 700, lineHeight: '56px'}}>
            {project.available ? `${project.available} ₽` : `0 ₽`}
          </span>
        </DateBlock>
      )
    }

    // return ''
  }

  if (project.type === 'REGULAR') {
    percent =
      project.amountPaid * (100 / (project.totalPerMember * project.members)) >= 100
        ? 100
        : project.amountPaid * (100 / (project.totalPerMember * project.members))
  } else {
    percent =
      project.amountPaid * (100 / (project.totalPerMember * project.members)) >= 100
        ? 100
        : project.amountPaid * (100 / (project.totalPerMember * project.members))
  }

  return (
    <ActiveProjectWrapper className="project-card" onClick={onClick}>
      <WrapperIconDatePayment>
        {project?.emoji ? (
          // <em-emoji id={project?.emoji?.id} set="apple" size="120px"></em-emoji>
          <span style={{fontSize: '60px'}}>{project?.emoji?.native}</span>
        ) : project.title === 'Копилка' ? (
          // <em-emoji id="moneybag" set="apple" size="120px"></em-emoji>
          <span style={{fontSize: '60px'}}>💰</span>
        ) : (
          <div />
        )}
        {/* {project?.emoji ? <Emoji unified={project?.emoji?.unified} size="60" /> : <div />} */}

        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'self-end'}}>
          {project?.status !== 'DRAFT' && dateTime()}
          {/* {project.totalPerMember && !isPiggy ? <PaymentBlock>{payment()}</PaymentBlock> : null} */}
          {/* {isPiggy ? <PaymentBlock sx={{fontWeight: 700}}>{project.amountPaid} ₽</PaymentBlock> : null} */}
        </Box>
      </WrapperIconDatePayment>
      {!isPiggy ? (
        <WrapperText variant="h2" sx={{fontSize: '18px !important'}}>
          {project.title}
        </WrapperText>
      ) : null}
      {project.totalPerMember && !isPiggy ? <div style={{width: '100%', marginTop: '4px'}}>{payment()}</div> : null}
      {!isPiggy ? (
        isAltProgress ? (
          <ProgressBar value={`${percent}%`} sx={{marginTop: '24px'}} />
        ) : (
          <>
            <WrapperLoader>
              {/* <div className="text">
              <span>1</span>
              <span>2</span>
            </div> */}
              <Loader sx={{left: `${percent >= 100 ? 0 : -100 + percent}%`}} />
              {/* <div className="text">
              <span>0</span>
              <span>{project.type === 'REGULAR' ? project.totalPerMember : project.targetMoney}</span>
            </div> */}
            </WrapperLoader>
            <Box className="text" sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
              <div style={{fontSize: '14px', fontWeight: 700, color: '#39215E', width: 'fit-content'}}>0 ₽</div>
              <div style={{fontSize: '14px', fontWeight: 700, color: '#39215E', width: 'fit-content'}}>
                {project.type === 'REGULAR'
                  ? project.totalPerMember * project.members
                  : project.totalPerMember * project.members}{' '}
                ₽
              </div>
            </Box>
            <AvatarGroup
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                height: '34px',
                width: '100%',
                marginTop: '12px',
                '& .MuiAvatarGroup-avatar': {
                  width: 32,
                  height: 32,
                  fontSize: 15,
                  fontWeight: 700,
                  background: 'rgba(84, 39, 143, 0.8)',
                  border: '2px solid #EDE3FF !important'
                }
              }}
              max={4}
            >
              {project?.photos?.guids.map((url) => (
                <Avatar sx={{width: '32px', height: '32px'}} key={url} alt={``} src={imageURL(url)} />
              ))}
            </AvatarGroup>
          </>
        )
      ) : (
        <p style={{fontSize: '18px', fontWeight: 700, color: '#39215E'}}>
          Самый быстрый способ внести деньги даже незарегистрированным.
        </p>
      )}

      {/* <Button
        variant="contained"
        type="button"
        onClick={onClick}
        endIcon={<ArrowRightAltIcon />}
        sx={{marginTop: '24px', width: '100%'}}
      >
        {isDraft ? 'Редактировать' : 'О проекте'}
      </Button> */}
    </ActiveProjectWrapper>
  )
}

export default ActiveProject
