import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'

export const SearchWrapper = styled(Box)(({theme}) => ({
  transform: 'scale(1) !important',
  width: '100% !important',
  maxWidth: '800px',

  '@media(max-width: 976px)': {
    marginTop: '48px !important',
    marginBottom: '20px !important'
  },

  '.widgetHeader': {
    marginBottom: '24px',
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '56px',
    color: theme.palette.primary.main,
    textTransform: 'none',
    '@media(max-width: 976px)': {
      marginBottom: '24px',
      fontSize: 32,
      lineHeight: '32px'
    }
  }
}))
