import React from 'react'

import Switch from '@mui/material/Switch'
import {styled} from '@mui/material/styles'

import logo from '../../../images/check.svg'

import {FormControlLabelWrapper} from '../../UserAgreement/UserAgreement'

const StyledSwitch = styled(Switch)(({theme}) => ({
  padding: 6,
  marginLeft: 3,
  '& .MuiSwitch-thumb': {
    width: 13,
    height: 13,
    backgroundColor: theme.palette.grey[500]
  },
  '& .MuiSwitch-track': {
    backgroundColor: 'transparent',
    border: `2px solid ${theme.palette.grey[200]}`,
    width: 32,
    height: 18,
    borderRadius: '640px',
    boxSizing: 'border-box'
  },
  '& .Mui-checked + .MuiSwitch-track': {
    backgroundColor: 'transparent !important',
    border: `2px solid ${theme.palette.primary.main}`
  },
  '& .Mui-checked .MuiSwitch-thumb': {
    backgroundColor: theme.palette.primary.main,
    '&:before': {
      color: '#fff',
      content: "''",
      position: 'absolute',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url(${logo})`,
      width: '10px',
      height: '10px',
      backgroundSize: '10px',
      left: 9,
      top: 9,
      boxSizing: 'border-box'
    }
  },
  '& .Mui-checked': {
    transform: 'translateX(14px) !important'
  },
  '& .MuiSwitch-switchBase': {
    top: '2px',
    left: '2px',
    padding: '6px'
  }
}))

const Switcher = ({label, checked, onChange}) => {
  const handleChange = (event) => {
    onChange(event.target.checked)
  }

  return <FormControlLabelWrapper control={<Switch checked={checked} onChange={handleChange} />} label={label} />
}

export default Switcher
