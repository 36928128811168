import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {useParams, useNavigate, useLocation} from 'react-router-dom'
import VotingBlock from '../../../../Summary/components/Votings/VotingBlock'
import ParagraphWrapper from '../../../../components/ParagraphWrapper/ParagraphWrapper'
import {votingSelectors} from '../../../../../../../store/votingStore/votingStore'
import {PublishStatusesEnum} from '../../../../../subpages/constants/parentCommitteeConstants'

const ProjectVotesBlock = ({isChief, status}) => {
  const {projectId} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const votesListTotal = useSelector(votingSelectors.votesList.selector)
  const votesListProject = votesListTotal.filter((vote) => vote.projectUuid === projectId)

  const handleClickVotesBtn = () => {
    projectId && localStorage.setItem('projId', JSON.stringify(projectId))
    // navigate(location?.pathname.replace(`/projects/${projectId}`, '/votes/vote-create'))
    window.open(location?.pathname.replace(`/projects/${projectId}`, '/votes/vote-create'), '_blank')
  }

  return (
    <>
      {isChief ? (
        <ParagraphWrapper
          withSlider={Boolean(votesListProject?.length)}
          title={`Голосования (${votesListProject.length})`}
          handleClickButton={isChief && status !== PublishStatusesEnum.COMPLETED ? handleClickVotesBtn : null}
          proj={true}
        />
      ) : votesListProject.length ? (
        <ParagraphWrapper
          withSlider={Boolean(votesListProject?.length)}
          title={`Голосования (${votesListProject.length})`}
        />
      ) : null}
      {votesListProject.length ? <VotingBlock votesList={votesListProject} /> : null}
    </>
  )
}

export default ProjectVotesBlock
