import {createSelectorsTree} from '../selectorsFactory'
import {generateReducersObject} from '../sliceMapping'
import {allInitAppEvents} from './projectsStoreSliceEvents'

const initialState = {
  projectsList: [],
  isLoading: false,
  isLoadingExpenses: false,
  projectsUsers: [],
  confirmTransfer: false,
  payUrl: null
}

/** Reducers */
export const PROJECTS_STORE = 'projectsStore'
export const projectsStore = generateReducersObject({
  allSliceEvents: allInitAppEvents,
  initialState,
  reducerName: PROJECTS_STORE
})

/** Selectors */
export const projectsSelectors = createSelectorsTree(initialState, PROJECTS_STORE)
