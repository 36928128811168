import React from 'react'

export const LoginIcon = ({className, color = '#39215E'}) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={color}
        d="M14 8.001V6.001C14 5.47057 13.7893 4.96186 13.4142 4.58679C13.0391 4.21171 12.5304 4.001 12 4.001H5C4.46957 4.001 3.96086 4.21171 3.58579 4.58679C3.21071 4.96186 3 5.47057 3 6.001V18.001C3 18.5314 3.21071 19.0401 3.58579 19.4152C3.96086 19.7903 4.46957 20.001 5 20.001H12C12.5304 20.001 13.0391 19.7903 13.4142 19.4152C13.7893 19.0401 14 18.5314 14 18.001V16.001M20 12.001H7M7 12.001L10 9.001M7 12.001L10 15.001"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
