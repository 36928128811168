import {DoneProjectWrapper} from './ProjectsStyle'
import {Typography} from '@mui/material'
import React from 'react'

const DoneProject = () => (
  <DoneProjectWrapper>
    <Typography variant="h1" sx={{fontWeight: 'bold'}}>
      Отлично!
    </Typography>
    <Typography>Вы оплатили все взносы.</Typography>
  </DoneProjectWrapper>
)

export default DoneProject
