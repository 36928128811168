import {createSelectorsTree} from '../selectorsFactory'
import {generateReducersObject} from '../sliceMapping'
import {allInitAppEvents} from './directoriesStoreSliceEvents'

const initialState = {
  gender: {},
  kinship: {},
  class: {},
  classLetter: {},
  isLoading: false,
  genderUuid: '',
  tariffs: {},
  payoutCommission: null
}

/** Reducers */
export const DIRECTORIES_STORE = 'directoriesStore'
export const directoriesStore = generateReducersObject({
  allSliceEvents: allInitAppEvents,
  initialState,
  reducerName: DIRECTORIES_STORE
})

/** Selectors */
export const directoriesSelectors = createSelectorsTree(initialState, DIRECTORIES_STORE)
