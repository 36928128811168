import initSlice from '../initSlice'

const {sliceEvent, allSliceEvents} = initSlice()

const PREFIX = 'CREATING_RK_'

export const createNewRk = sliceEvent({
  type: `${PREFIX}_CREATE_NEW_RK`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const joiningRK = sliceEvent({
  type: `${PREFIX}_JOINING_RK`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const forgetRK = sliceEvent({
  type: `${PREFIX}_FORGET_RK`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const refChildEvent = sliceEvent({
  type: `${PREFIX}_REF_CHILD`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: () => {
    return {
      isLoading: true
    }
  }
})

export const updateChildRecordEvent = sliceEvent({
  type: `${PREFIX}_UPDATE_CHILD_RECORD`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: () => {
    return {
      isLoading: true
    }
  }
})

export const deleteChildRecordEvent = sliceEvent({
  type: `${PREFIX}_DELETE_CHILD_RECORD_EVENT`,
  action: (ids, data) => {
    return {
      payload: {
        ids,
        data
      }
    }
  },
  reducer: () => {
    return {
      isLoading: true
    }
  }
})

export const setIsLoading = sliceEvent({
  type: `${PREFIX}_SET_IS_LOADING`,
  action: (isLoading) => ({
    payload: {
      isLoading
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {isLoading} = action.payload
    return {
      ...state,
      isLoading
    }
  }
})

export const setErrors = sliceEvent({
  type: `${PREFIX}_SET_ERRORS`,
  action: (errors) => ({
    payload: {
      errors
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {errors} = action.payload
    return {
      ...state,
      errors
    }
  }
})

export const setCreatedRkUuid = sliceEvent({
  type: `${PREFIX}_SET_CREATED_RK_UUID`,
  action: (uuid) => ({
    payload: {
      uuid
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {uuid} = action.payload
    return {
      ...state,
      uuid
    }
  }
})

export const joinChildrenToRk = sliceEvent({
  type: `${PREFIX}_SET_ADDITIONAL_INFO`,
  action: (checkedKids, uuid) => ({
    payload: {
      checkedKids,
      uuid
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const getMyRks = sliceEvent({
  type: `${PREFIX}_GET_MY_RODCOMS`,
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})
export const clearMyRks = sliceEvent({
  type: `${PREFIX}_CLEAR_MY_RODCOMS`,
  reducer: (state) => {
    return {
      ...state,
      myRodkoms: [],
      isLoading: true
    }
  }
})

export const getMyRksSuccess = sliceEvent({
  type: `${PREFIX}_GET_MY_RODCOMS_SUCCESS`,
  action: (myRodkoms) => ({
    payload: {
      myRodkoms
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {myRodkoms} = action.payload
    return {
      ...state,
      isLoading: false,
      myRodkoms
    }
  }
})

export const searchParentCommittees = sliceEvent({
  type: 'SEARCH_PARENT_COMMITTEES',
  action: (data) => ({
    payload: {
      data
    }
  })
})

export const setParentCommittees = sliceEvent({
  type: 'SET_PARENT_COMMITTEES',
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {data} = action.payload
    return {
      ...state,
      isLoading: false,
      searchParentCommittees: data
    }
  }
})

export const allInitAppEvents = allSliceEvents
