import React, {useState, useMemo, useEffect, useCallback} from 'react'

import 'react-calendar/dist/Calendar.css'

import {FilterIcon} from '../../../../../../icons'
import {getFormattedDate} from '../../../../../../utils/getFormattedDate'
import {FiltersButton, Text} from './CommonFilterStyle'
import FiltersComponent from '../FiltersComponent'
import {Box, Dialog} from '@mui/material'
import {useSelector} from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import {projectsSelectors} from 'store/projectsStore/projectsStore'

// const mockedOptions = [
//   {value: 'val', label: 'lab', title: 'title'},
//   {value: '2', label: '2', title: '2'},
//   {value: '3', label: '3', title: '3'}
// ]

const mockedGroupedOptions = [
  {value: 'val', label: 'lab', title: 'title', group: 'test1'},
  {value: '2', label: '2', title: '2', group: 'test2'},
  {value: '3', label: '3', title: '3', group: 'test3333'}
]

const CommonFilter = ({onApply}) => {
  const [purpose, setPurpose] = useState('')
  const [period, setPeriod] = useState('')
  const [members, setMembers] = useState([])
  const [projects, setProjects] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [periodAnchorEl, setPeriodAnchorEl] = React.useState(null)
  const projectOptions = useSelector(projectsSelectors.projectsList.selector)

  const theme = useTheme()
  const isRenderModal = !!useMediaQuery(theme.breakpoints.down('1024'))
  const fullScreenModal = useMediaQuery(theme.breakpoints.down('500'))

  const handlePeriodClick = (event, v) => {
    setPeriodAnchorEl(event.currentTarget)
  }

  const handleChangeMember = (event, newValue) => {
    setMembers(newValue)
  }

  const handleChangeProject = (event, newValue) => {
    setProjects(newValue)
  }

  const handleClosePeriod = () => {
    setPeriodAnchorEl(null)
  }

  const handleApplyClick = (val) => {
    onApply({
      projects: projects.length !== 0 ? [projects].map((p) => p.uuid) : [],
      members: members.map((m) => m.id),
      description: purpose,
      period: rangeValue
    })
    handleCloseModal()
  }

  useEffect(() => {
    if (localStorage.getItem('projId')) {
      const data = projectOptions.filter((el) => el.uuid === JSON.parse(localStorage.getItem('projId')).uuid)
      setProjects(data[0])
      localStorage.removeItem('projId')
      onApply({
        projects: data.map((p) => p.uuid),
        members: members.map((m) => m.id),
        description: purpose,
        period: rangeValue
      })
    }
  }, [localStorage])

  useEffect(() => {
    return () => {
      onApply({
        projects: [],
        members: [],
        description: '',
        period: ''
      })
    }
  }, [])

  // useEffect(() => {
  //   if (!localStorage.getItem('projId')) {
  //     onApply({
  //       projects: projects.map((p) => p.uuid),
  //       members: members.map((m) => m.id),
  //       description: purpose,
  //       period: rangeValue
  //     })
  //   }
  // }, [localStorage])

  const isPeriodPopoverOpen = Boolean(periodAnchorEl)

  const [rangeValue, onChange] = useState(null)

  const isRangeSelected = useMemo(() => Array.isArray(rangeValue) && rangeValue.length === 2, [rangeValue])

  const formattedPeriod = useMemo(() => {
    if (isRangeSelected) {
      return `${getFormattedDate(rangeValue[0])}-${getFormattedDate(rangeValue[1])}`
    }
    return 'За все время'
  }, [rangeValue, isRangeSelected])

  const onDateRangeChange = (value) => {
    const isRangeSelected = Array.isArray(value) && value.length === 2
    if (isRangeSelected) {
      onChange(value)
      handleClosePeriod()
    }
  }

  const onClearPeriodClick = (e) => {
    e.stopPropagation()
    onChange(null)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleReset = () => {
    setProjects([])
    setMembers([])
    onChange(null)
    setPurpose('')
    // handleCloseModal()
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const filters = useMemo(() => {
    let num = 0
    if (formattedPeriod !== 'За все время') {
      num = 1
    }
    if (members.length) {
      num = num + 1
    }
    if (!(projects instanceof Array)) {
      num = num + 1
    }
    if (purpose) {
      num = num + 1
    }
    return num
  }, [purpose, projects, members, period, formattedPeriod])

  const renderFilter = () => (
    <FiltersComponent
      purpose={purpose}
      setPurpose={setPurpose}
      isRangeSelected={isRangeSelected}
      onClearPeriodClick={onClearPeriodClick}
      handlePeriodClick={handlePeriodClick}
      formattedPeriod={formattedPeriod}
      setPeriod={setPeriod}
      isPeriodPopoverOpen={isPeriodPopoverOpen}
      periodAnchorEl={periodAnchorEl}
      handleClosePeriod={handleClosePeriod}
      onDateRangeChange={onDateRangeChange}
      rangeValue={rangeValue}
      projects={projects}
      handleChangeProject={handleChangeProject}
      members={members}
      handleChangeMember={handleChangeMember}
      handleApplyClick={handleApplyClick}
      handleCloseModal={handleCloseModal}
      handleReset={handleReset}
    />
  )

  return (
    <>
      {isRenderModal && (
        <>
          <FiltersButton onClick={handleOpenModal}>
            <FilterIcon />
            <Text variant="subtitle1">{`Фильтры ${filters ? `(${filters})` : ''}`}</Text>
          </FiltersButton>
          <Dialog
            open={isModalOpen}
            onClose={handleCloseModal}
            fullScreen={fullScreenModal}
            fullWidth
            maxWidth="xs"
            sx={{margin: '12px'}}
          >
            <Box sx={{padding: '22px'}}>{renderFilter()}</Box>
          </Dialog>
        </>
      )}
      {!isRenderModal && renderFilter()}
    </>
  )
}

export default CommonFilter
