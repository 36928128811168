import {styled} from '@mui/material/styles'
import {StyledAutocomplete} from '../AutocompleteInput/AutocompleteInputStyle'

export const Tags = styled('div')(() => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
}))

export const StyledAutocompleteWithCheckboxes = styled(StyledAutocomplete)(() => ({
  '& .MuiAutocomplete-inputRoot': {
    flexWrap: 'nowrap'
  }
}))
