import {styled} from '@mui/material/styles'

export const StyledItem = styled('div')(({theme}) => ({
  color: theme.palette.primary.main,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  // boxShadow: '0px 12px 32px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  border: '1px solid #EDEDFF',
  background: theme.palette.background.paper,
  padding: 24,
  borderRadius: 12,
  marginBottom: '24px !important',
  '& p, & h6': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row'
  },
  [theme.breakpoints.up(600)]: {
    borderRadius: '12px',
    padding: '26px'
  }
}))
