import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import {Box} from '@mui/material'
import {StyledLoadingButton} from '../../../../components/StyledLoadingButton/StyledLoadingButton'
import {Wrapper} from './LoginFormStyle'
import {authorizationRequest} from '../../../../store/initStore/initStoreSliceEvents'
import {PhoneInput} from '../../../../components/Inputs/PhoneInput/PhoneInput'
import {PasswordInput} from '../../../../components/Inputs/PasswordInput/PasswordInput'
import {checkIfPhoneValid, getPasswordError} from '../../../../utils/validations'
import {PATHS} from '../../../../routes/paths'
import {initAppSelectors} from '../../../../store/initStore/initStore'

const LoginForm = (props) => {
  const dispatch = useDispatch()
  const {login, setLogin, password, setPassword} = props
  const loading = useSelector(initAppSelectors.loading.selector)

  const [isLoginError, setIsPhoneError] = useState(false)
  const [passwordError, setPasswordError] = useState('')

  const getAuthorization = (event) => {
    event.preventDefault()
    const changeLogin = login.replace(/[^+\d]/g, '')
    const isPhoneValid = checkIfPhoneValid(changeLogin)
    if (!isPhoneValid) {
      setIsPhoneError(true)
      return
    }
    const passwordError = getPasswordError(password)
    if (passwordError) {
      setPasswordError(passwordError)
      return
    }

    dispatch(authorizationRequest.action(changeLogin, password))
  }

  return (
    <Wrapper>
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          alignItems: 'center'
        }}
        onSubmit={getAuthorization}
      >
        <PhoneInput
          autoFocus
          phone={login}
          phoneHandler={setLogin}
          isError={isLoginError}
          clearPhoneError={setIsPhoneError}
          className="phoneField phone"
          withIcon
          id="login-phone"
        />
        <PasswordInput
          label="Пароль"
          id="login-pass"
          password={password}
          passwordError={passwordError}
          passHandler={setPassword}
          clearPassError={setPasswordError}
          className="passField password"
          placeholder="Введите пароль"
          autocomplete="current-password"
        />
        <StyledLoadingButton
          className="button"
          id="login-submit"
          loading={loading}
          loadingPosition="end"
          variant="contained"
          type="submit"
          endIcon={<ArrowRightAltIcon className="arrowRight" />}
        >
          Войти
        </StyledLoadingButton>
        <Link to={PATHS.RESTORING_ACCESS} className="forgotPassword">
          Забыли пароль?
        </Link>
      </Box>
    </Wrapper>
  )
}

export default LoginForm
