import React, {useCallback, useEffect, useState} from 'react'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import {useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'

import {CheckIcon, ChevronDownIcon} from '../../icons'
import {PATHS} from '../../routes/paths'
import {
  StyledSelect,
  ParentCommitteeDropdownWrapper,
  ParentCommitteeDropdownItem,
  NewParentCommitteeDropdownWrapper
} from './ParentCommetteeDropdown.styles'
import {profileSelectors} from '../../store/profileStore/profileStore'
import {getRkMembersRequest} from '../../services/committeeSettings'
import {STATUSES_IN} from '../../pages/MyRodcomesPage/constants/myRodcomesPageConstants'
import {directoriesSelectors} from '../../store/directoriesStore/directoriesStore'

export const ParentCommitteeDropdown = ({parentCommittees}) => {
  const user = useSelector(profileSelectors.user.selector)

  const navigator = useNavigate()

  const {id: uuidParentCommittee} = useParams()
  const classes = useSelector(directoriesSelectors.class.selector)
  const classesLetter = useSelector(directoriesSelectors.classLetter.selector)
  const [data, setData] = useState([])

  const handleChangeCurrentParentCommittee = useCallback((parentCommitteeId) => {
    navigator(`${PATHS.PARENT_COMMITTEE}/${parentCommitteeId}`)
  }, [])

  const getData = useCallback(() => {
    if (parentCommittees?.length) {
      let newData = []
      parentCommittees.forEach(async (committee) => {
        if (
          committee?.statusIn === STATUSES_IN.Expelled ||
          committee?.statusIn === STATUSES_IN.ApplicationForMembership ||
          committee?.statusIn === STATUSES_IN.ApplicationForMembershipRejected
        ) {
          return
        }
        await getRkMembersRequest(committee.uuid).then(({data: {payload: members}}) => {
          if (members?.length && user) {
            const findUser = members.find((member) => member.userUuid === user.uuid)
            let className = `${
              committee.classUuid && classes[committee.classUuid] ? classes[committee.classUuid] : ''
            }${
              committee.classLetterUuid && classesLetter[committee.classLetterUuid]
                ? classesLetter[committee.classLetterUuid]
                : ''
            }`

            newData = [
              ...newData,
              {
                id: committee.uuid,
                label:
                  committee.institutionType === 'School'
                    ? `${committee.name || ''} ${className || ''}`
                    : `${committee.groupName || ''}`,
                children: findUser?.children
              }
            ]
          }
          setData([...newData])
        })
      })
    }
  }, [parentCommittees, uuidParentCommittee])

  useEffect(() => {
    getData()
  }, [parentCommittees, user?.uuid])

  const renderParentCommitteeValue = useCallback(() => {
    return (
      <Typography className="parent-committee-name" textAlign="center" variant="button">
        {uuidParentCommittee ? data?.find((elem) => elem.id === uuidParentCommittee)?.label : 'РК не выбран'}
      </Typography>
    )
  }, [uuidParentCommittee, data])

  const renderParentCommitteeItems = () => {
    return data.map((parentCommittee) => {
      return (
        <ParentCommitteeDropdownItem
          key={parentCommittee.id}
          onClick={() => handleChangeCurrentParentCommittee(parentCommittee.id)}
          sx={{whiteSpace: 'normal'}}
        >
          {parentCommittee.id === uuidParentCommittee && <CheckIcon className="check-icon" />}
          <div>
            <Typography textAlign="left" variant="button" sx={{display: 'block', fontSize: 14, fontWeight: 600}}>
              {parentCommittee.label}
            </Typography>
            {!!parentCommittee?.children?.length &&
              parentCommittee?.children?.map((child, index) => {
                return (
                  <Typography
                    key={index.toString(36)}
                    textAlign="left"
                    variant="caption"
                    mt={1}
                    sx={{display: 'block', letterSpacing: '0.5px', fontSize: 11}}
                  >
                    {`${child?.fullName?.surname} ${child?.fullName?.firstName}`}
                  </Typography>
                )
              })}
          </div>
        </ParentCommitteeDropdownItem>
      )
    })
  }

  return (
    <StyledSelect
      displayEmpty
      value={uuidParentCommittee}
      renderValue={(parentCommittee) => renderParentCommitteeValue(parentCommittee)}
      IconComponent={(props) => <ChevronDownIcon {...props} />}
      autoWidth={true}
      style={{borderRadius: '50px'}}
    >
      {!!data?.length && (
        <ParentCommitteeDropdownWrapper>{renderParentCommitteeItems()}</ParentCommitteeDropdownWrapper>
      )}
      <NewParentCommitteeDropdownWrapper>
        <MenuItem sx={{justifyContent: 'center', padding: '6px 10px 6px'}}>
          <Link href={PATHS.SEARCH_PAGE}>
            <Typography
              variant="caption"
              sx={{textAlign: 'center', textDecoration: 'underline', fontSize: 12, fontWeight: 600}}
            >
              Найти или создать родком
            </Typography>
          </Link>
        </MenuItem>
      </NewParentCommitteeDropdownWrapper>
    </StyledSelect>
  )
}
