import LoadingButton from '@mui/lab/LoadingButton'
import {alpha, styled} from '@mui/material/styles'

export const StyledLoadingButton = styled(LoadingButton)(({theme}) => ({
  maxWidth: 'inherit',
  width: '100%',
  // '&.MuiButton-root': {
  //   width: '100%',
  //   height: '48px',
  //   maxWidth: 'inherit',
  //   borderRadius: '12px',
  //   background: 'rgba(57, 33, 94, 0.12)',
  //   border: '1px solid rgba(255, 255, 255, 1e-05)',
  //   color: '#39215E',
  //   '&:hover': {
  //     background: 'rgba(57, 33, 94, 0.24)',
  //     border: '1px solid rgba(255, 255, 255, 1e-05)'
  //   },
  //   '&:focused': {
  //     border: '1px solid rgba(255, 255, 255, 1e-05)'
  //   },
  //   '&:active': {
  //     boxShadow:
  //       ' 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
  //   },
  //   svg: {
  //     path: {
  //       stroke: '#39215E'
  //     }
  //   }
  // },
  '&.MuiLoadingButton-loading': {
    background: 'linear-gradient(180deg, #553685 1000%, #553685 100%)',
    color: '#F7F2FF',
    border: 'none'
  }
}))

export const StyledNarrowButton = styled(LoadingButton)(({theme}) => ({
  // '&.MuiButton-root': {
  //   height: '38px',
  //   maxWidth: 'inherit',
  //   borderRadius: '13px',
  //   '&:hover': {
  //     background: alpha(theme.palette.primary.main, 0.6)
  //   }
  // },
  '&.MuiLoadingButton-loading': {
    opacity: 0.6
  },
  '@media(min-width: 1222px)': {
    width: '100%'
  }
}))
