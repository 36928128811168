import React, {useCallback, useMemo} from 'react'
import {useSelector} from 'react-redux'
import {Button, Typography, Box, Tooltip} from '@mui/material'
import {useLocation, useNavigate, useParams} from 'react-router-dom'

import ButtonBack from '../../../../../../components/ButtonBack/ButtonBack'
import {Text} from '../../../components/ParagraphWrapper/ParagraphWrapperStyle'
import {votingSelectors} from '../../../../../../store/votingStore/votingStore'
import {PencilIcon, CheckIcon} from '../../../../../../icons'
import {estimatedTime} from '../../../../../../utils/EstimatedTime'
import {projectsSelectors} from '../../../../../../store/projectsStore/projectsStore'
import WrappedText from '../../../../../../components/WrappedText/WrappedText'
import {generateThemeBase} from '../../../../../../theme/base'
import VoteOptionsList from './components/VoteOptionsList/VoteOptionsList'
import {StyledSpecInfo} from './VoteItemPageStyle'
import LinkBlock from '../../../../../../components/LinkBlock/LinkBlock'
import ParentChatLinkBlock from '../../../../../../components/ParentChatLinkBlock/ParentChatLinkBlock'
import {
  StyledLeftContent,
  StyledMainContent,
  StyledRightContent,
  StyledWrapper
} from '../../../../../../components/Layouts/TabContentLayout/TabContentLayout'
import {PATHS} from '../../../../../../routes/paths'
import {committeeSettingsSelectors} from '../../../../../../store/committeeSettingsStore/committeeSettingsStore'
import {STATUSES_IN} from '../../../../../MyRodcomesPage/constants/myRodcomesPageConstants'
import ActiveProject from '../../../Summary/components/projects/ActiveProject'
import {PublishStatusesEnum} from '../../../constants/parentCommitteeConstants'

const VoteItemPage = () => {
  const link = window?.location?.href //useLocation() не возвращает домен
  const votesList = useSelector(votingSelectors.votesList.selector)
  const roles = useSelector(committeeSettingsSelectors.roles.selector)
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const {voteId} = params
  const isChief = useMemo(() => roles?.includes(STATUSES_IN.Chief), [roles])

  const voteData = votesList.find((vote) => vote.uuid === voteId)

  const projectsList = useSelector(projectsSelectors.projectsList.selector)

  let currentProject
  if (voteData?.projectUuid && projectsList.length) {
    currentProject = projectsList.find((project) => project.uuid === voteData.projectUuid)
  }

  const onBackClick = useCallback(() => {
    navigate(location?.pathname.replace(`/${voteId}`, ''))
  }, [voteId])

  const onProjectClick = useCallback(() => {
    const projectPath = location?.pathname.replace(
      `${PATHS.PARENT_COMMITTEE_VOTES}/${voteId}`,
      `${PATHS.PARENT_COMMITTEE_PROJECTS}/${currentProject.uuid}`
    )
    navigate(projectPath)
  }, [voteId, currentProject])

  const onEditClick = useCallback(() => {
    navigate(`${location?.pathname}${PATHS.PARENT_COMMITTEE_VOTE_EDIT}`)
  }, [])

  const estimated = estimatedTime(voteData?.deadlineAt)
  const estimatedText = estimated === 0 ? 'Голосование закончено' : `До окончания ${estimated}`

  if (!voteData) return null
  return (
    <StyledWrapper>
      <StyledLeftContent>
        <StyledMainContent>
          {/* <Box className="action"> */}
          {/* <ButtonBack sx={{width: 'auto'}} onClick={onBackClick} text="Голосования" className="back" /> */}
          {/* </Box> */}
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Text sx={{marginRight: '12px'}}>{voteData.title}</Text>
            {isChief && !voteData.isArchived ? (
              // <Button
              //   className="change"
              //   // sx={{marginTop: '19px'}}
              //   onClick={onEditClick}
              //   variant="contained"
              //   color="info"
              //   sx={{marginTop: '32px'}}
              //   startIcon={<PencilIcon className="pencilIcon" />}
              // >
              //   Редактировать
              // </Button>
              <Tooltip title="Редактировать" placement="top">
                <Box sx={{cursor: 'pointer'}} onClick={onEditClick}>
                  <PencilIcon className="pencilIcon" />
                </Box>
              </Tooltip>
            ) : null}
          </Box>
          <Typography sx={{marginTop: '32px', fontSize: 16, wordBreak: 'break-word'}} variant="h4">
            {voteData.question}
          </Typography>

          {/* <StyledSpecInfo className="specialInfoOne">
            <WrappedText
              wrapperClassname="privateVote"
              text={voteData.isPrivate ? 'Закрытое голосование' : 'Открытое голосование'}
            />
          </StyledSpecInfo> */}

          <StyledSpecInfo className="specialInfoTwo" sx={{marginTop: '24px'}}>
            <Typography variant="h6" sx={{fontSize: 12}}>
              {voteData.isPrivate ? 'Закрытое голосование' : 'Открытое голосование'}
            </Typography>
            <Typography variant="h6" sx={{fontSize: 12}}>
              {estimatedText}
            </Typography>
            <Typography variant="h6" sx={{fontSize: 12}}>
              Проголосовало: {voteData.answersTotal} / {voteData.expectedAnswersTotal}
            </Typography>
            {voteData.haveIAnswered ? (
              <WrappedText
                wrapperClassname="youVoted"
                icon={<CheckIcon color={generateThemeBase.palette.info.contrastText} className="checkIcon" />}
                text="Вы проголосовали"
              />
            ) : null}
          </StyledSpecInfo>

          <VoteOptionsList voteData={voteData} />
        </StyledMainContent>
      </StyledLeftContent>

      <StyledRightContent>
        {currentProject?.chatLink ? (
          <ParentChatLinkBlock title="Родительский чат" link={currentProject?.chatLink} />
        ) : null}

        <LinkBlock title="Ссылка на это голосование" link={link} />

        {currentProject ? (
          <>
            <ActiveProject
              project={currentProject}
              percent={(currentProject.amountPaid / currentProject.targetMoney) * 100}
              onClick={onProjectClick}
              isDraft={currentProject?.status === PublishStatusesEnum.DRAFT}
              // isAltProgress
            />
          </>
        ) : null}
      </StyledRightContent>
    </StyledWrapper>
  )
}

export default VoteItemPage
