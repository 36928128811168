import initSlice from '../initSlice'

const {sliceEvent, allSliceEvents} = initSlice()

const PREFIX = 'DIRECTORIES_'

export const setIsLoading = sliceEvent({
  type: `${PREFIX}_SET_IS_LOADING`,
  action: (isLoading) => ({
    payload: {
      isLoading
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {isLoading} = action.payload
    return {
      ...state,
      isLoading
    }
  }
})

export const getInitDirectories = sliceEvent({
  type: `${PREFIX}_GET_INIT_DIRECTORIES`,
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const getInitDirectoriesSuccess = sliceEvent({
  type: `${PREFIX}_GET_INIT_DIRECTORIES_SUCCESS`,
  action: (directories) => ({
    payload: {
      directories
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {directories} = action.payload
    const genderUuid = Object.keys(directories.gender ?? {})?.[0] || ''
    return {
      ...state,
      ...directories,
      genderUuid,
      isLoading: false
    }
  }
})

export const allInitAppEvents = allSliceEvents
