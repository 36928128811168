import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Box, CircularProgress} from '@mui/material'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

import InfoProject from '../components/InfoProject/InfoProject'
import ProjectMembersBlock from '../components/ProjectMembersBlock/ProjectMembersBlock'
import Fundraising from '../components/Fundraising/Fundraising'
import {EditProjectWrapper} from './EditProjectStyle'
import FinalBlock from '../components/FinalBlock/FinalBlock'
import BoundedVotes from '../components/BoundedVotes/BoundedVotes'
import {committeeSettingsSelectors} from '../../../../../../store/committeeSettingsStore/committeeSettingsStore'
import {RK_ROLE} from '../../../../../JoiningRodcom/constants'
import {
  archivateProjectEvent,
  createProjectEvent,
  getProjectUsersEvent,
  getProjectEvent,
  updateProjectEvent
} from '../../../../../../store/projectsStore/projectsStoreSliceEvents'
import {projectsSelectors} from '../../../../../../store/projectsStore/projectsStore'
import {PublishStatusesEnum} from '../../../constants/parentCommitteeConstants'
import {getVotesListEvent} from '../../../../../../store/votingStore/votingStoreSliceEvents'
import {votingSelectors} from '../../../../../../store/votingStore/votingStore'
import {PATHS} from '../../../../../../routes/paths'
import {INIT_PROJECT_STATE} from '../constants/constants'
import {initAppSelectors} from '../../../../../../store/initStore/initStore'
import {EVENT_STATUSES} from '../../../../../LoginPage/constants'
import {setRequestStatus} from '../../../../../../store/initStore/initStoreSliceEvents'
import {STATUSES_IN} from '../../../../../MyRodcomesPage/constants/myRodcomesPageConstants'

const EditProject = ({isEdit}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const {projectId, id} = useParams()

  const members = useSelector(committeeSettingsSelectors.membersParentCommittee.selector)
  const isLoading = useSelector(projectsSelectors.isLoading.selector)
  const projectsList = useSelector(projectsSelectors.projectsList.selector)
  const projectsUsers = useSelector(projectsSelectors.projectsUsers.selector)
  const votesListAll = useSelector(votingSelectors.votesList.selector)
  const requestStatus = useSelector(initAppSelectors.requestStatus.selector)
  const roles = useSelector(committeeSettingsSelectors.roles.selector)

  let projectVoteList = []

  const isChief = roles?.includes(STATUSES_IN.Chief)

  const getInitData = useCallback(() => {
    //
    if (isEdit) {
      dispatch(getProjectEvent.action(projectId))
      dispatch(getProjectUsersEvent.action(projectId))
    }

    const requestData = {
      offset: 0,
      limit: 20,
      rkUuids: [id],
      projectUuids: projectId ? [projectId] : [],
      statuses: ['PUBLISHED', 'COMPLETED'],
      sort: {
        sortType: 'Asc'
      }
    }
    dispatch(getVotesListEvent.action(requestData))
  }, [dispatch, id, votesListAll, projectId, isEdit])

  useEffect(() => {
    if (roles.length) {
      if (!isChief) {
        if (projectId) {
          navigate(`${PATHS.PARENT_COMMITTEE}/${id}${PATHS.PARENT_COMMITTEE_PROJECTS}/${projectId}`)
        } else {
          navigate(`${PATHS.PARENT_COMMITTEE}/${id}${PATHS.PARENT_COMMITTEE_PROJECTS}`)
        }
      }
    }

    getInitData()
  }, [roles])

  const [projectValues, setProjectValues] = useState(INIT_PROJECT_STATE)

  useEffect(() => {
    if (projectId && projectsList) {
      const projectData = projectsList.find((proj) => proj.uuid === projectId)
      setProjectValues(projectData)
    }
  }, [projectsList, projectId])

  const [projectErrors, setProjectErrors] = useState({})
  const [selectedVotes, setSelectedVotes] = useState([])
  const [projectMembers, setProjectMembers] = useState(members?.map(({userUuid}) => userUuid) || [])
  const [logo, setLogo] = useState()
  const [images, setImages] = useState([])
  const [removedImagesFromBackEnd, setRemovedImagesFromBackEnd] = useState([])

  //const [isCommonAmount, setIsCommonAmount] = useState(true)

  // useEffect(() => {
  //   if (projectValuesInit) {
  //     if (projectValuesInit.moneyPerMember && !projectValuesInit.targetMoney) {
  //       setIsCommonAmount(false)
  //     } else if (!projectValuesInit.moneyPerMember && projectValuesInit.targetMoney) {
  //       setIsCommonAmount(true)
  //     }
  //   }
  // }, [projectValuesInit])

  useEffect(() => {
    if (members?.length && !isEdit) {
      setProjectMembers(members.map((user) => user.userUuid))
    } else {
      setProjectMembers(projectsUsers.map((user) => user.uuid))
    }
  }, [members, projectsUsers])

  useEffect(() => {
    if (votesListAll?.length) {
      projectVoteList = votesListAll.filter((vote) => vote.projectUuid === projectId)
      if (projectVoteList.length) setSelectedVotes(projectVoteList)
    }
  }, [votesListAll])

  useEffect(() => {
    dispatch(setRequestStatus.action(null))
  }, [])

  useEffect(() => {
    if (
      requestStatus === EVENT_STATUSES.PROJECT_ACTIVATED ||
      requestStatus === EVENT_STATUSES.PROJECT_CLOSED ||
      requestStatus === EVENT_STATUSES.PROJECT_DELETED ||
      requestStatus?.includes(EVENT_STATUSES.PROJECT_ADDED)
    ) {
      const path = `${PATHS.PARENT_COMMITTEE}/${id}${PATHS.PARENT_COMMITTEE_PROJECTS}`
      navigate(path)
    }
    return function cleanup() {
      dispatch(setRequestStatus.action(null))
    }
  }, [requestStatus, location, dispatch, id])

  const isDraft = useMemo(() => {
    if (projectValues?.status) {
      return projectValues?.status === PublishStatusesEnum.DRAFT
    }
    return true
  }, [projectValues?.status])

  const filteredVotesList = useMemo(
    () => votesListAll.filter(({projectUuid}) => !projectUuid || projectUuid === projectId),
    [projectId, votesListAll]
  )

  const isPublished = useMemo(() => {
    if (projectValues?.status) {
      return projectValues?.status === PublishStatusesEnum.PUBLISHED
    }
    return false
  }, [projectValues?.status])

  const isCompleted = useMemo(() => {
    if (projectValues?.status) {
      return projectValues?.status === PublishStatusesEnum.COMPLETED
    }
    return false
  }, [projectValues?.status])

  const membersOfCommittee = useMemo(() => {
    if (!members) {
      return []
    }
    return members.filter((item) => !item?.membershipRoles?.find((role) => role.name === RK_ROLE.EDUCATOR))
  }, [members])

  const setValue = useCallback(
    (name, value) => {
      setProjectValues((oldValues) => ({...oldValues, [name]: value}))
    },
    [projectValues]
  )

  const setError = useCallback(
    (name, value) => {
      setProjectErrors((old) => ({...old, [name]: value}))
    },
    [projectErrors]
  )

  const onUpdate = (e, withPublish) => {
    const selectedVotesId = selectedVotes.map((vote) => vote.uuid)
    const data = {...projectValues, votes: selectedVotesId}
    if (isDraft) {
      data.startDate = new Date()
      data.title = data.title ? data.title : 'Без названия'
    }
    if (!data.mainImage) {
      data.mainImage = undefined
    }
    if (!data.description) {
      data.description = '<p></p>'
    }
    if (!withPublish) {
      data.startDate = null
    }
    data.deadlineDate = data.firstDeadlineDate
    if (!data.deadlineDate) {
      data.deadlineDate = null
    }

    if (!data.targetMoney) {
      data.targetMoney = null
    }

    if (!data?.emoji?.id) {
      data.emoji = {
        id: 'slightly_smiling_face',
        name: 'Slightly Smiling Face',
        native: '🙂',
        unified: '1f642',
        keywords: ['smile'],
        shortcodes: ':slightly_smiling_face:',
        emoticons: [':)', '(:', ':-)']
      }
    }

    if (isEdit) {
      data.deadlineDate = isDraft ? data.deadlineDate : null
      data.status = withPublish ? PublishStatusesEnum.PUBLISHED : data.status
      dispatch(
        updateProjectEvent.action({
          data,
          projectMembers,
          projectId,
          logo,
          images,
          removedImagesFromBackEnd,
          withPublish: withPublish
        })
      )
      navigate(-1)
    } else {
      data.rkId = id
      data.targetFacilities = 'targetFacilities'
      dispatch(createProjectEvent.action({data, projectMembers, logo, withPublish, images}))
      navigate(-1)
    }
  }

  const handlePublish = useCallback(
    (e) => {
      const isTitle = !!projectValues?.title
      !isTitle && setError('title', 'Обязательное поле')
      // const isEmoji = !!projectValues?.emoji?.native
      // !isEmoji && setError('emoji', 'Обязательное поле')
      const areMembers = !!projectMembers?.length
      !areMembers && setError('members', 'Необходимо выбрать хотя бы одного участника')
      const isMoneyPerMember = !!projectValues.moneyPerMember
      !isMoneyPerMember && setError('moneyPerMember', 'Обязательное поле')
      // const hasAmount = isEdit ? true : !!projectValues?.moneyPerMember
      // const hasPeriod = !!projectValues?.periodsNumber
      const hasDeadline = !!projectValues?.firstDeadlineDate
      !hasDeadline && setError('hasDeadline', 'Обязательное поле')
      // !isNaN(new Date(projectValues.firstDeadlineDate))

      isTitle && areMembers && isMoneyPerMember && hasDeadline
        ? onUpdate(e, true)
        : document
            .getElementById(
              Object.entries(projectErrors)
                .filter((i) => i[1] != null)
                .map((i) => i[0])[0]
            )
            ?.scrollIntoView()
    },
    [onUpdate, setError]
  )

  const handleArchive = useCallback(() => {
    dispatch(archivateProjectEvent.action(projectId))
  }, [dispatch, projectId])

  const handleDraft = useCallback(
    (e) => {
      const isTitle = !!projectValues?.title
      !isTitle && setError('title', 'Обязательное поле')
      isTitle
        ? onUpdate(e, false)
        : window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
    },
    [onUpdate]
  )

  const isAlertRequiredFields = useMemo(() => {
    const isTitle = !!projectValues?.title
    const areMembers = !!projectMembers?.length
    const isDescription = !!projectValues?.description
    const hasAmount = isEdit ? true : !!projectValues?.moneyPerMember
    const hasPeriod = !!projectValues?.periodsNumber
    const hasDeadline = !!projectValues?.firstDeadlineDate && !isNaN(new Date(projectValues.firstDeadlineDate))
    return !(isTitle && areMembers && isDescription && hasAmount && hasPeriod && hasDeadline)
  }, [
    projectValues?.title,
    projectValues?.mainImage,
    projectMembers,
    projectValues?.moneyPerMember,
    projectValues?.firstDeadlineDate,
    projectValues?.tariffId,
    projectValues?.periodsNumber,
    projectValues?.description
  ])

  if (isLoading) return <CircularProgress sx={{margin: '0 auto', padding: 19, display: 'block'}} />

  return (
    <EditProjectWrapper>
      <Box className="form">
        <Box sx={{display: 'flex', flexDirection: 'column'}} className="left">
          <InfoProject
            removedImagesFromBackEnd={removedImagesFromBackEnd}
            setRemovedImagesFromBackEnd={setRemovedImagesFromBackEnd}
            images={images}
            setImages={setImages}
            projectValues={projectValues}
            setValue={setValue}
            setLogo={setLogo}
            logo={logo}
            projectErrors={projectErrors}
            setError={setError}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
          className="right"
        >
          {projectValues?.type === 'PIGGY' ? (
            <>
              <BoundedVotes
                votesList={filteredVotesList}
                selectedVotes={selectedVotes}
                setSelectedVotes={setSelectedVotes}
              />
              <FinalBlock
                isAlertRequiredFields={isAlertRequiredFields}
                isDraft={isDraft}
                isCompleted={isCompleted}
                isPublished={isPublished}
                projectValues={projectValues}
                setValue={setValue}
                projectErrors={projectErrors}
                setError={setError}
                onUpdate={onUpdate}
                handleDraft={handleDraft}
                isUpdateButtonDisabled={isAlertRequiredFields}
                handlePublish={handlePublish}
                handleArchive={handleArchive}
              />
            </>
          ) : (
            <>
              <ProjectMembersBlock
                members={membersOfCommittee}
                projectMembers={projectMembers}
                setProjectMembers={setProjectMembers}
                projectErrors={projectErrors}
                setError={setError}
                editable
                className="members"
              />
              {projectValues ? (
                <Fundraising
                  isPublished={isPublished}
                  moneyPerMember={projectValues.moneyPerMember}
                  nextDeadlineDate={projectValues.nextDeadlineDate}
                  firstDeadlineDate={projectValues.firstDeadlineDate}
                  periodsNumber={projectValues.periodsNumber}
                  tariff={projectValues.tariffId}
                  setProjectValues={setProjectValues}
                  membersAmount={projectMembers.length}
                  setError={setError}
                  hasErrors={!!projectErrors}
                  errors={projectErrors}
                  members={membersOfCommittee}
                  projectMembers={projectMembers}
                />
              ) : null}
            </>
          )}
        </Box>
      </Box>
      {projectValues?.type !== 'PIGGY' ? (
        <Box className="finaly">
          <BoundedVotes
            votesList={filteredVotesList}
            selectedVotes={selectedVotes}
            setSelectedVotes={setSelectedVotes}
          />
          <FinalBlock
            isAlertRequiredFields={isAlertRequiredFields}
            isDraft={isDraft}
            isCompleted={isCompleted}
            isPublished={isPublished}
            projectValues={projectValues}
            setValue={setValue}
            projectErrors={projectErrors}
            setError={setError}
            onUpdate={onUpdate}
            handleDraft={handleDraft}
            isUpdateButtonDisabled={isAlertRequiredFields}
            handlePublish={handlePublish}
            handleArchive={handleArchive}
          />
        </Box>
      ) : null}
    </EditProjectWrapper>
  )
}

export default EditProject
