export const sliceEventsToReducersMap = (sliceEvents) => {
  const reducersMapping = {}
  if (sliceEvents) {
    sliceEvents.forEach((event) => {
      reducersMapping[event.type] = event.reducer
    })
  }
  return reducersMapping
}

export const applyReducerFromMap = (state, action, reducersMapping) => {
  if (action.type && reducersMapping[action.type]) {
    return reducersMapping[action.type](state, action)
  }
  return state
}

export const generateReducersObject = ({allSliceEvents, initialState, reducerName}) => {
  const reducers = sliceEventsToReducersMap(allSliceEvents)

  const applyReducer = function headerReducer(state = initialState, action) {
    return applyReducerFromMap(state, action, reducers)
  }
  return {
    [reducerName]: applyReducer
  }
}
