import React, {useMemo, useEffect, useRef} from 'react'

import InputAdornment from '@mui/material/InputAdornment'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import InputMask from 'react-input-mask'
import {IconButton, TextField} from '@mui/material'

import {InputBaseFormControl} from '../InputsStyles'
import {InputLabelWrapper} from '../TextInput/TextInputStyle'
import {Text} from './PhoneInputStyle'

export const PhoneInput = ({
  phone,
  phoneHandler,
  isError,
  secondLabel,
  secondLabelRaw,
  clearPhoneError,
  id,
  className = '',
  required = false,
  withIcon = false,
  autoFocus = false,
  withNotRequiredText = false,
  disabled = false
}) => {
  const onChange = (event) => {
    let {value} = event.target
    value = value.slice(0, 50)
    if (/^\+7/g.test(value)) {
      if (isError && clearPhoneError) {
        clearPhoneError(false)
      }
      // phoneHandler(value)
    }
  }

  const endAdornment = (
    <InputAdornment position="end">
      <IconButton
        sx={{
          cursor: 'default'
        }}
        // aria-label="Переключение режима видимости пароля"
        // onClick={handleClickShowPassword}
        // onMouseDown={handleMouseDownPassword}
        edge="end"
      >
        <LocalPhoneOutlinedIcon />
      </IconButton>
    </InputAdornment>
  )

  // const currentValue = useMemo(() => {
  //   if (phone?.startsWith('7')) {
  //     return `+${phone}`
  //   }
  //   if (!phone) {
  //     return '+7'
  //   }
  //   return phone
  // }, [phone])
  // const htmlRef = useRef()

  return (
    <InputBaseFormControl classes={{root: className}} variant="standard" fullWidth={true} required={required}>
      <InputLabelWrapper shrink>
        Телефон {withNotRequiredText && <span className="notRequiredText">(не обязательно)</span>}
      </InputLabelWrapper>
      <InputMask
        mask="+7 999 999-99-99"
        value={phone}
        disabled={disabled}
        alwaysShowMask={true}
        onChange={(e) => {
          phoneHandler(e.target.value)
          onChange(e)
        }}
      >
        {() => (
          <TextField
            type="tel"
            name="tel"
            id={id}
            inputProps={{
              inputMode: 'numeric',
              autoComplete: 'tel',
              autoFocus
            }}
            value={phone}
            error={isError}
            helperText={isError ? 'Неверный номер' : secondLabelRaw ? secondLabelRaw : <Text>{secondLabel}</Text>}
            InputProps={{
              endAdornment: withIcon ? endAdornment : null
            }}
            disabled={disabled}
          />
        )}
      </InputMask>
    </InputBaseFormControl>
  )
}
