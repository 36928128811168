import React, {useCallback, useEffect} from 'react'
import {cleanBillEvent, finishBillEvent} from 'store/accrualsStore/accrualStoreSliceEvents'
import {useDispatch, useSelector} from 'react-redux'
import {setError} from '../../store/initStore/initStoreSliceEvents'
import {accrualsSelectors} from '../../store/accrualsStore/accrualStore'
import {notifySuccess} from '../../utils/notify'

const PayPopup = ({onSuccess}) => {
  const dispatch = useDispatch()
  const confirmationToken = useSelector(accrualsSelectors.confirmationToken.selector)
  console.log(confirmationToken)

  const onPaySuccess = useCallback(() => {
    notifySuccess('Оплата прошла успешно')
    dispatch(finishBillEvent.action())
    dispatch(cleanBillEvent.action())

    onSuccess()
  }, [])

  const onFail = useCallback(() => {
    dispatch(setError.action('Ошибка оплаты'))
    dispatch(finishBillEvent.action())
    dispatch(cleanBillEvent.action())
  }, [])

  const onClose = useCallback((error) => {
    dispatch(cleanBillEvent.action())
  }, [])

  useEffect(() => {
    if (!confirmationToken) return
    const checkout = new window.YooMoneyCheckoutWidget({
      confirmation_token: confirmationToken,
      customization: {
        modal: true
      },
      error_callback: function (error) {
        console.log(error)
      }
    })

    checkout.render()

    checkout.on('success', () => {
      onPaySuccess()
      checkout.destroy()
    })

    checkout.on('fail', () => {
      onFail()
      checkout.destroy()
    })
  }, [confirmationToken])
  return <></>
}

export default PayPopup
