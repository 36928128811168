import React from 'react'
import {Route, Routes, useLocation, Navigate} from 'react-router-dom'
import ContentFallback from './ContentFallback'
import AppHeader from '../AppHeader/AppHeader'
import AppFooter from '../AppFooter/AppFooter'
import {PATHS} from '../../routes/paths'
import Profile from '../../pages/Profile/Profile'
import CreatingRodcom from '../../pages/CreatingRodcom/CreatingRodcom'
import JoiningRodcom from '../../pages/JoiningRodcom/JoiningRodcom'
import MyRodcomesPage from '../../pages/MyRodcomesPage/MyRodcomesPage'
import EducationalInstitution from '../../pages/EducationalInstitutionPage/EducationalInstitution'
import ParentCommitteePage from '../../pages/ParentCommittee/ParentCommitteePage'
import SummaryPage from '../../pages/ParentCommittee/subpages/Summary/SummaryPage'
import ProjectsPage from '../../pages/ParentCommittee/subpages/Projects/ProjectsPage'
import ReportsPage from '../../pages/ParentCommittee/subpages/Reports/ReportsPage'
import VotesPage from '../../pages/ParentCommittee/subpages/Votes/VotesPage'
import MembersPage from '../../pages/ParentCommittee/subpages/Members/MembersPage'
import DocumentsPage from '../../pages/ParentCommittee/subpages/Documents/DocumentsPage'
import SettingsPage from '../../pages/ParentCommittee/subpages/Settings/SettingsPage'
import AssignmentSubPage from '../../pages/ParentCommittee/subpages/Settings/components/AssignmentSubPage/AssignmentSubPage'
import MemberRecordPage from '../../pages/ParentCommittee/subpages/Members/components/MemberRecordPage/MemberRecordPage'
import Project from '../../pages/ParentCommittee/subpages/Projects/Project/Project'
import EditProject from '../../pages/ParentCommittee/subpages/Projects/Project/editProject/EditProject'
import VoteCreatePage from '../../pages/ParentCommittee/subpages/Votes/components/VoteCreatePage/VoteCreatePage'
import VoteItemPage from '../../pages/ParentCommittee/subpages/Votes/components/VoteItemPage/VoteItemPage'
import SearchPage from '../../pages/SearchPage/SearchPage'
import CartPage from '../../pages/ParentCommittee/subpages/Summary/components/CartPage/CartPage'
import {PageWrapper} from '../../App'
import RkDonatePage from 'pages/Direct/RkDonatePage'
import CheckPaymentPage from 'pages/Payments/CheckPaymentPage'

const renderFallback = (customFallback) => {
  return customFallback || <ContentFallback />
}

function RequireAuth({children}) {
  const location = useLocation()
  if (!localStorage.getItem('token')) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={PATHS.LOGIN} state={{from: location}} replace />
  }

  return (
    <>
      <AppHeader />
      <PageWrapper>{children}</PageWrapper>
      <AppFooter />
    </>
  )
}

export const PageRoutes = (props) => {
  const {routesDescription} = props
  // todo исправить это, почему-то не работает Navigate на summmary как ':id/summary'
  const id = window?.location?.pathname?.replace(`/${PATHS.PARENT_COMMITTEE}`, '')
  return (
    <>
      <Routes>
        <Route
          path={PATHS.PROFILE}
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        />
        <Route
          path={PATHS.CREATING_PARENT_COMMITTEE}
          element={
            <RequireAuth>
              <CreatingRodcom />
            </RequireAuth>
          }
        />
        <Route
          path={PATHS.JOINING_PARENT_COMMITTEE + '/:id'}
          element={
            <RequireAuth>
              <JoiningRodcom withBank={false} />
            </RequireAuth>
          }
        />
        <Route
          path="/"
          element={
            <RequireAuth>
              <MyRodcomesPage />
            </RequireAuth>
          }
        />
        <Route
          path={PATHS.MY_PARENT_COMMITTEES}
          element={
            <RequireAuth>
              <MyRodcomesPage />
            </RequireAuth>
          }
        />

        <Route
          path={PATHS.EDUCATIONAL_INSTITUTION}
          element={
            <RequireAuth>
              <EducationalInstitution isLogged />
            </RequireAuth>
          }
        />
        <Route
          path={PATHS.SEARCH_PAGE}
          element={
            <RequireAuth>
              <SearchPage isLogged />
            </RequireAuth>
          }
        />
        <Route path="/rk/:id/donate" element={<RkDonatePage />} />
        <Route path="/payment/:id/success" element={<CheckPaymentPage />} />
        <Route
          path={PATHS.PARENT_COMMITTEE}
          element={
            <RequireAuth>
              <ParentCommitteePage />
            </RequireAuth>
          }
        >
          <Route path={':id'} element={<Navigate replace to={`${id}${PATHS.PARENT_COMMITTEE_SUMMARY}`} />} />
          <Route path={':id' + PATHS.PARENT_COMMITTEE_SUMMARY} element={<SummaryPage />} />
          <Route path={':id' + PATHS.PARENT_COMMITTEE_SUMMARY_CART} element={<CartPage />} />
          <Route path={':id' + PATHS.PARENT_COMMITTEE_PROJECTS} element={<ProjectsPage />} />
          <Route path={':id' + PATHS.PARENT_COMMITTEE_PROJECTS + '/:projectId'} element={<Project />} />
          <Route
            path={':id' + PATHS.PARENT_COMMITTEE_PROJECTS + PATHS.PARENT_COMMITTEE_CREATE_PROJECT}
            element={<EditProject />}
          />
          <Route
            path={':id' + PATHS.PARENT_COMMITTEE_PROJECTS + '/:projectId' + PATHS.PARENT_COMMITTEE_EDIT_PROJECT}
            element={<EditProject isEdit />}
          />
          <Route path={':id' + PATHS.PARENT_COMMITTEE_DOCUMENTS} element={<DocumentsPage />} />
          <Route path={':id' + PATHS.PARENT_COMMITTEE_SETTINGS} element={<SettingsPage />} />
          <Route path={':id' + PATHS.PARENT_COMMITTEE_REPORTS} element={<ReportsPage />} />
          <Route path={':id' + PATHS.PARENT_COMMITTEE_VOTES} element={<VotesPage />} />
          <Route path={':id' + PATHS.PARENT_COMMITTEE_VOTES + '/:voteId'} element={<VoteItemPage />} />
          <Route
            path={':id' + PATHS.PARENT_COMMITTEE_VOTES + PATHS.PARENT_COMMITTEE_VOTE_CREATE}
            element={<VoteCreatePage />}
          />
          <Route
            path={':id' + PATHS.PARENT_COMMITTEE_VOTES + '/:voteId' + PATHS.PARENT_COMMITTEE_VOTE_EDIT}
            element={<VoteCreatePage isEdit />}
          />
          <Route path={':id' + PATHS.PARENT_COMMITTEE_MEMBERS} element={<MembersPage />} />
          <Route path={':id' + PATHS.PARENT_COMMITTEE_SETTINGS_ASSIGNMENT} element={<AssignmentSubPage />} />
          <Route path={':id' + PATHS.PARENT_COMMITTEE_MEMBER_RECORD + '/:memberId'} element={<MemberRecordPage />} />
          <Route path={':id' + PATHS.PARENT_COMMITTEE_MEMBER_RECORD} element={<MemberRecordPage />} />
        </Route>
        <Route
          path="*"
          element={
            <RequireAuth>
              <Navigate replace to={PATHS.MY_PARENT_COMMITTEES} />
            </RequireAuth>
          }
        />
      </Routes>
    </>
  )
}
