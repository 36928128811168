import React, {useState} from 'react'
import {FormWrapper, DocumentText, DocumentWrapper, Text, DateButtonBlock} from './DocumentPageStyle'
import Box from '@mui/material/Box'
import {Button} from '@mui/material'
import {DownloadIcon} from '../../../../icons'
import {DOCUMENTS} from '../../../../mockedData/mockedDocuments'

// todo remove mocked data
const DocumentsPage = () => {
  const [documents, setDocuments] = useState(DOCUMENTS)

  return (
    <FormWrapper>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Text variant="h2">Документы</Text>
      </Box>
      {documents.map((doc) => {
        return (
          <DocumentWrapper key={doc.name}>
            <DocumentText>{doc.name}</DocumentText>
            <Button component={'a'} download href={doc.link} className="downloadWrapperMobile" target="_blank" />
            <DateButtonBlock>
              <div className="descr" target="_blank">
                <DocumentText>{doc.date}</DocumentText>
                {/* <DocumentText>{doc.format}</DocumentText> */}
              </div>
              <Button
                variant="contained"
                color="secondary"
                component={'a'}
                download
                href={doc.link}
                target="_blank"
                sx={{boxShadow: 'none'}}
                // className="downloadButton"
                startIcon={<DownloadIcon className="downloadIcon" />}
              >
                Скачать
              </Button>
            </DateButtonBlock>
          </DocumentWrapper>
        )
      })}
    </FormWrapper>
  )
}

export default DocumentsPage
