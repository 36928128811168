import {styled} from '@mui/material/styles'

export const ProfileSection = styled('section')(({theme}) => ({
  '& .main-info': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '32px'
  },

  '& .avatar': {
    order: 2,
    width: '128px',
    height: '128px'
  },
  '& .text': {
    color: theme.palette.primary.main
  },
  '& .delete-button': {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  },
  '& input::placeholder': {
    color: theme.palette.primary.main
  },
  '& .profile-info': {
    order: 1,
    paddingLeft: 6,
    textAlign: 'center',
    marginTop: 8
  },
  '& .profile-name': {
    fontSize: '19px',
    lineHeight: '26px',
    margin: '6px 0 19px'
  },
  '& .profile-tab': {
    padding: 0,
    marginRight: '19px',
    fontWeight: '400',
    minWidth: 'auto',
    color: theme.palette.primary.main,
    '&.Mui-selected': {
      color: theme.palette.primary.main,
      padding: '0px'
    },
    '& .MuiTouchRipple-child': {
      backgroundColor: 'transparent !important'
    }
  },
  '& .profile-content': {
    margin: '32px 0 0 0',
    padding: '20px',
    textAlign: 'left',
    background: '#ffffff',
    // boxShadow: '0px 12px 32px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
    border: '1px solid #EDEDFF',
    borderRadius: '12px'
  },
  '& .profile-content.safetytab': {
    background: 'none',
    boxShadow: 'none',
    border: 'none',
    maxWidth: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
    '& img': {
      width: '173px'
    },
    h4: {
      margin: '24px 0',
      fontSize: '14px',
      textAlign: 'center'
    },
    button: {
      width: '100%'
    }
  },
  '& .profile-button': {
    width: '100%',
    flexShrink: 0
  },
  '& .not-required-text': {
    fontWeight: '400',
    letterSpacing: '0.25px',
    marginLeft: '6px'
  },
  '& .profile-button-icon': {
    fill: '#ffffff'
  },
  [theme.breakpoints.up('md')]: {
    '& .main-info': {
      flexDirection: 'row',
      marginBottom: '32px'
    },
    '& .avatar': {
      order: 1,
      marginRight: '16px'
    },
    '& .profile-info': {
      order: 2,
      textAlign: 'left'
    },
    '& .profile-name': {
      fontSize: '38px',
      lineHeight: '45px',
      margin: '0'
    },
    '& .profile-content': {
      margin: '38px 0 134px',
      padding: '38px',
      background: '#ffffff',
      border: '1px solid #EDEDFF',
      // boxShadow: '0px 12px 32px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
      borderRadius: '12px'
    },
    '& .profile-button': {
      width: 'auto'
    }
  },
  [theme.breakpoints.up('lg')]: {
    '& .profile-content': {
      maxWidth: '700px'
    }
  }
}))
