import {lighten, styled} from '@mui/material/styles'

export const StyledProjectOptionsList = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '29px',
  ml: 2,
  position: 'relative',
  gap: '19px',
  '& .MuiFormControlLabel-label': {
    width: '100%'
  },
  '& .MuiFormControlLabel-root': {
    margin: 0
  }
}))

export const StyledProjectOption = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'flex-start',
  backgroundColor: lighten(theme.palette.primary.light, 0.96),
  borderRadius: '16px',
  padding: '13px 13px 13px 3px',
  // boxShadow: '0px 12px 12px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  border: '1px solid #EDEDFF',
  [theme.breakpoints.up('md')]: {
    padding: '19px 19px 19px 13px',
    alignItems: 'center'
  },
  '.MuiCheckbox-root': {
    '&:hover': {
      backgroundColor: 'transparent !important'
    },
    '&:active': {
      backgroundColor: 'transparent !important'
    }
  },
  '&:hover': {
    boxShadow: '0px 12px 12px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)'
  },
  '& .projectImgWrapper': {
    width: '38px',
    marginRight: '6px',
    flexShrink: 0,
    flexGrow: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      marginRight: '13px'
    }
  },
  '& .projectImg': {
    objectFit: 'contain',
    width: '100%',
    height: '100%'
  },
  '& .projectText': {
    marginBottom: '3px',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '19px',
    [theme.breakpoints.up('md')]: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '19px'
    }
  }
}))
