import React from 'react'
import {AccordionDetails, AccordionSummary, Typography} from '@mui/material'
import {ArrowForward} from '@mui/icons-material'

import {Question, QuestionsWrapper, WrapperChevronDownIcon} from './QuestionsStyle'
import Button from '@mui/material/Button'

const Questions = ({handleClick}) => {
  return (
    <QuestionsWrapper>
      <Question>
        <AccordionSummary expandIcon={<WrapperChevronDownIcon />}>
          <Typography className="title">Что такое родком?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="description">
            Создавая родком, вы получаете виртуальное объединение родителей, где все вопросы решаются прозрачно и по
            правилам. В родкоме вы сможете создавать проекты и копить деньги на общие цели. Принимать решения
            коллективно через голосования. Контролировать все траты через удобные отчеты.
          </Typography>
        </AccordionDetails>
      </Question>
      <Question>
        <AccordionSummary expandIcon={<WrapperChevronDownIcon />}>
          <Typography className="title">Как найти мой родительский комитет?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="description">
            Выберите город и введите название учебного заведения, например, “школа 130”. В результатах поиска найдите
            своё учебное заведение и перейдите к списку родкомов.
          </Typography>
        </AccordionDetails>
      </Question>
      <Question>
        <AccordionSummary expandIcon={<WrapperChevronDownIcon />}>
          <Typography className="title">Как вступить в комитет?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="description">
            На странице учебного заведения отображается список всех зарегистрированных родительских комитетов. Напротив
            нужного родкома справа нажмите “Вступить”. Глава родительского комитета получит вашу заявку, и после
            одобрения заявки вы получите доступ к родительскому комитету.
          </Typography>
        </AccordionDetails>
      </Question>
      <Question>
        <AccordionSummary expandIcon={<WrapperChevronDownIcon />}>
          <Typography className="title">Я могу создать родительский комитет?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="description">
            Да, вы можете создать родительский комитет. Для этого необходимо выбрать ваше учебное заведение, заполнить
            свой профиль и добавить основную информацию о детях.
          </Typography>
        </AccordionDetails>
      </Question>
      <Question>
        <AccordionSummary expandIcon={<WrapperChevronDownIcon />}>
          <Typography className="title">Как пригласить родителей в комитет?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="description">
            После создания родкома вы получите постоянную ссылку, которую можете отправить в родительский чатик. Новые
            заявки на вступление будет проверять глава родительского комитета.
          </Typography>
        </AccordionDetails>
      </Question>

      {/*<Button*/}
      {/*  variant="contained"*/}
      {/*  color="info"*/}
      {/*  onClick={handleClick}*/}
      {/*  endIcon={<ArrowForward color="white" style={{marginLeft: 16}} />}*/}
      {/*  sx={{margin: '3px 0'}}*/}
      {/*>*/}
      {/*  База знаний*/}
      {/*</Button>*/}
    </QuestionsWrapper>
  )
}

export default Questions
