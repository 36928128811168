import React, {useCallback, useMemo, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'

import {CircularProgress, Box} from '@mui/material'

import {ItemsWrapper, StyledTypography} from './MyRodcomesPageStyle'
import {creatingRkSelectors} from '../../store/creatingRkStore/creatingRkStore'
import RodcomItem from './RodcomItem/RodcomItem'
import {directoriesSelectors} from '../../store/directoriesStore/directoriesStore'
import SearchRodcom from './SearchRodkom/SearchRodcom'
import {PATHS} from '../../routes/paths'
import {getMyRks, forgetRK, joiningRK} from '../../store/creatingRkStore/creatingRkStoreSliceEvents'
import {getRkMembersRequest} from '../../services/committeeSettings'

const MyRodcomesPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const myRodkoms = useSelector(creatingRkSelectors.myRodkoms.selector)
  const isLoading = useSelector(creatingRkSelectors.isLoading.selector)
  const classes = useSelector(directoriesSelectors.class.selector)
  const classLetter = useSelector(directoriesSelectors.classLetter.selector)

  useEffect(() => {
    dispatch(getMyRks.action())
  }, [])

  const onLinkClick = useCallback(
    (id) => {
      navigate(`${PATHS.PARENT_COMMITTEE}/${id}${PATHS.PARENT_COMMITTEE_SUMMARY}`)
      getRkMembersRequest(id)
    },
    [navigate]
  )
  const joinToRk = useCallback((id) => {
    navigate(`${PATHS.JOINING_PARENT_COMMITTEE}/${id}`)
  }, [])

  const ForgetRk = useCallback(
    (id) => {
      dispatch(forgetRK.action({uuid: id}))
    },
    [navigate]
  )

  const items = useMemo(() => {
    return myRodkoms.map((item, index) => {
      const {classLetterUuid, classUuid} = item
      const classLetterText = classLetter?.[classLetterUuid] || ''
      const classesText = classes?.[classUuid] || ''
      return (
        <RodcomItem
          onLinkClick={() => onLinkClick(item.uuid)}
          key={index.toString(36)}
          classLetterText={classLetterText}
          classesText={classesText}
          {...item}
          sx={{marginBottom: index === myRodkoms.length - 1 ? '64px' : '19px'}}
          onJoinRk={() => joinToRk(item.uuid)}
          onForgetRk={() => ForgetRk(item.uuid)}
          classUuid={classUuid}
        />
      )
    })
  }, [myRodkoms])

  return (
    <>
      <StyledTypography>Мои родкомы</StyledTypography>
      <ItemsWrapper className={isLoading ? 'isLoading' : ''}>
        {isLoading ? <CircularProgress sx={{margin: '0 auto', padding: 19}} /> : items}
      </ItemsWrapper>
      <Box sx={{display: 'flex', justifyContent: 'center'}}>
        <SearchRodcom />
      </Box>
    </>
  )
}

export default MyRodcomesPage
