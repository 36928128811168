import React, {useCallback} from 'react'

import {styled} from '@mui/material/styles'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'

import {FormWrapper, StyledButton} from '../../../Settings/SettingsPageStyle'
import {StyledHeader} from '../../../Settings/components/AssignmentSubPage/AssignmentSubPageStyle'
import StyledAlert from '../../../../../../components/StyledAlert/StyledAlert'
import {CrossIcon, InfoIcon} from '../../../../../../icons'
import {useDispatch} from 'react-redux'
import {Button} from '@mui/material'
import {notifySuccess} from '../../../../../../utils/notify'
import {
  transferMembershipApproveEvent,
  transferMembershipRejectEvent
} from '../../../../../../store/committeeSettingsStore/committeeSettingsStoreSliceEvents'

export const ButtonsWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  '& button': {
    marginTop: 0
  }
}))

const AcceptingTheRoleBlock = ({name}) => {
  const dispatch = useDispatch()

  // const notify = () =>
  //   toast.success('Поздравляем! Теперь вы глава родкома.', {
  //     position: 'top-right',
  //     autoClose: 5000,
  //     hideProgressBar: true,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: 'light'
  //   })

  // todo добавить ссылку правами и обязанностями главы родкома

  const handleRefuse = useCallback(() => {
    dispatch(transferMembershipRejectEvent.action())
  }, [dispatch])

  const handleAccess = useCallback(() => {
    dispatch(transferMembershipApproveEvent.action())
    notifySuccess('Поздравляем! Теперь вы глава родкома.')
  }, [dispatch])

  return (
    <FormWrapper>
      <StyledHeader>Приём роли главы родкома</StyledHeader>
      <StyledAlert sx={{marginBottom: '22px'}} isBlue title="" icon={<InfoIcon />}>
        Глава родкома <b>{name}</b> отправил запрос на передачу вам роли главы родкома. Принимая запрос, вы соглашаетесь
        с{' '}
        <a
          href="https://storage.yandexcloud.net/bucket576787/docs/%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80%20%D0%BE%20%D1%81%D0%BE%D0%B2%D0%BC%D0%B5%D1%81%D1%82%D0%BD%D0%BE%D0%B9%20%D0%B4%D0%B5%D1%8F%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8%20%D0%A0%D0%BE%D0%B4%D0%BA%D0%BE%D0%BC.pdf"
          target="_blanc"
        >
          правами и обязанностями главы родкома
        </a>
        .
      </StyledAlert>
      <ButtonsWrapper>
        <Button
          variant="outlined"
          type="button"
          sx={{width: 'fit-content'}}
          onClick={handleRefuse}
          startIcon={<CrossIcon />}
        >
          Отказаться
        </Button>
        <Button
          variant="contained"
          type="button"
          sx={{width: 'fit-content'}}
          onClick={handleAccess}
          endIcon={<ArrowRightAltIcon color="white" />}
        >
          Принять
        </Button>
      </ButtonsWrapper>
    </FormWrapper>
  )
}

export default AcceptingTheRoleBlock
