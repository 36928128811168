import {createSelectorsTree} from '../selectorsFactory'
import {generateReducersObject} from '../sliceMapping'
import {allInitAppEvents} from './reportingStoreSliceEvents'

const initialState = {
  isLoading: false,
  reports: false
}

/** Reducers */
export const COMMITTEE_REPORTING_STORE = 'committeeReportingStore'
export const reportingStore = generateReducersObject({
  allSliceEvents: allInitAppEvents,
  initialState,
  reducerName: COMMITTEE_REPORTING_STORE
})

/** Selectors */
export const reportingSelectors = createSelectorsTree(initialState, COMMITTEE_REPORTING_STORE)
