import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {votingSelectors} from '../../../../store/votingStore/votingStore'
import ParagraphWrapper from '../components/ParagraphWrapper/ParagraphWrapper'
import VotingBlock from '../Summary/components/Votings/VotingBlock'
import InfoBox from '../../../../components/Layouts/InfoBox/InfoBox'
import {Typography} from '@mui/material'
import {committeeSettingsSelectors} from '../../../../store/committeeSettingsStore/committeeSettingsStore'
import {useLocation, useNavigate} from 'react-router-dom'
import {STATUSES_IN} from '../../../MyRodcomesPage/constants/myRodcomesPageConstants'

const VotesPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const votesList = useSelector(votingSelectors.votesList.selector)
  const x = votesList
    .filter((voting) => !voting.isArchived)
    .filter((i) => new Date(i.deadlineAt) < new Date())
    .sort((a, b) => new Date(b.deadlineAt) - new Date(a.deadlineAt))
  let votesListNotArchived = votesList
    .filter((voting) => !voting.isArchived)
    .filter((i) => new Date(i.deadlineAt) > new Date())
    .sort((a, b) => new Date(a.deadlineAt) - new Date(b.deadlineAt))
  votesListNotArchived.push(...x)
  const votesListArchived = votesList.filter((voting) => !!voting.isArchived)

  const roles = useSelector(committeeSettingsSelectors.roles.selector)
  const isChief = useMemo(() => roles.includes(STATUSES_IN.Chief), [roles])

  const votingCreate = () => {
    navigate(`${location.pathname}/vote-create`)
  }

  return (
    <div>
      <ParagraphWrapper
        title={`Актуальные (${votesListNotArchived.length ?? 0})`}
        handleClickButton={isChief ? () => votingCreate() : undefined}
      />

      {votesListNotArchived.length ? (
        <VotingBlock votesList={votesListNotArchived} isSlider={false} />
      ) : (
        <InfoBox sx={{marginBottom: '19px'}}>
          <Typography variant="h4" sx={{fontSize: '16px'}}>
            Здесь пока ничего нет
          </Typography>
        </InfoBox>
      )}

      {votesListArchived.length ? (
        <>
          <ParagraphWrapper title={`В архиве (${votesListArchived.length ?? 0})`} />
          <VotingBlock votesList={votesListArchived} isSlider={false} />
        </>
      ) : null}
    </div>
  )
}

export default VotesPage
