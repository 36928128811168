import React, {useEffect, useMemo} from 'react'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import Typography from '@mui/material/Typography'

import {WrapperForm} from './SmsFormStyle'
import {SmsInput} from '../../../../components/Inputs/SmsInput/SmsInput'
import {TIME} from '../../constants'
import {StyledLoadingButton} from '../../../../components/StyledLoadingButton/StyledLoadingButton'

const SmsForm = (props) => {
  const {code, getRegistration, setCode, btnText, onSendAgain, loading, seconds, setSeconds, prevent} = props

  const registrationDisabled = code.some((item) => !Number.isInteger(parseInt(item)))

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        clearInterval(myInterval)
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  })

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      getRegistration(e)
    }
  }

  const onClickSendAgain = () => {
    if (!seconds) {
      onSendAgain()
      setSeconds(TIME)
    }
  }

  const sendAgainBtn = useMemo(() => {
    const className = seconds ? 'sendAgain' : 'sendAgain sendAgainActive'
    const text = seconds ? `Отправить ещё раз (${seconds} сек.)` : 'Отправить ещё раз'
    return (
      <Typography onClick={onClickSendAgain} className={className} textAlign="center">
        {text}
      </Typography>
    )
  }, [seconds])

  return (
    <WrapperForm onKeyDown={onKeyDown} onSubmit={getRegistration}>
      <SmsInput code={code} setCode={setCode} />
      <StyledLoadingButton
        className="button"
        loading={loading}
        loadingPosition="end"
        variant="contained"
        type={prevent ? 'button' : 'submit'}
        disabled={registrationDisabled}
        onClick={prevent ? getRegistration : null}
        endIcon={<ArrowRightAltIcon className="arrowRight" />}
      >
        {btnText}
      </StyledLoadingButton>
      {sendAgainBtn}
    </WrapperForm>
  )
}

export default SmsForm
