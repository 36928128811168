import React, {useState, useEffect, useCallback, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {profileSelectors} from '../../store/profileStore/profileStore'
import {childrenSelectors} from '../../store/childStore/childStore'
import {checkBillEvent} from '../../store/accrualsStore/accrualStoreSliceEvents'
import {useLocation, useParams} from 'react-router-dom'

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <div className="profile-content" role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} {...other}>
      {value === index && children}
    </div>
  )
}

const CheckPaymentPage = () => {
  const dispatch = useDispatch()

  const {id} = useParams()
  const {search} = useLocation()
  const params = new URLSearchParams(search)
  const rkId = params.get('rkId') || ''
  const projectId = params.get('projectId') || ''

  const user = useSelector(profileSelectors.user.selector)
  const children = useSelector(childrenSelectors.children.selector)
  const isLoading = useSelector(childrenSelectors.childrenLoading.selector)

  useEffect(() => {
    dispatch(checkBillEvent.action(id, rkId, projectId))
  }, [])
  return <div>Проверка оплаты...</div>
}

export default CheckPaymentPage
