import {CLASSES} from '../constants/creatingRodcomConstants'

export const createYearArray = () => {
  const currentYear = new Date().getFullYear()
  return Array(CLASSES)
    .fill(currentYear)
    .map((year, i) => {
      const value = i ? year - i : year
      return {
        label: String(value),
        id: value
      }
    })
}

const LETTERS_ARRAY = [
  'А',
  'Б',
  'В',
  'Г',
  'Д',
  'Е',
  'Ё',
  'Ж',
  'З',
  'И',
  'Й',
  'К',
  'Л',
  'М',
  'Н',
  'О',
  'П',
  'Р',
  'С',
  'Т',
  'У',
  'Ф',
  'Х',
  'Ц',
  'Ч',
  'Ш',
  'Щ',
  'Ъ',
  'Ы',
  'Ь',
  'Э',
  'Ю',
  'Я'
]

export const getGradeOptions = () => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((el) => ({label: `${el}`, id: `${el}`}))

export const getLetterOptions = () => LETTERS_ARRAY.map((el) => ({label: el, id: el}))
