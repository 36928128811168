export default () => {
  const allSliceEvents = []

  const actionWrapper = (type, action) => {
    return (...params) => {
      const actionObj = action ? {...action(...params)} : {}
      return {
        ...actionObj,
        type
      }
    }
  }

  const sliceEvent = (eventTemplate) => {
    const {type} = eventTemplate
    const event = {
      ...eventTemplate,
      action: actionWrapper(type, eventTemplate.action)
    }

    allSliceEvents.push(event)

    return event
  }

  return {
    sliceEvent,

    allSliceEvents
  }
}
