import {styled} from '@mui/material/styles'
import Slider from 'react-slick'

export const StyledSlider = styled(Slider)(({theme}) => ({
  position: 'relative',
  // margin: '0 0 32px 0',
  '& .slick-track': {
    display: 'flex'
  },
  '&.noCenter .slick-track': {
    margin: 0
  },
  '& .slick-slide': {
    minHeight: '100%',
    height: 'auto',
    '& > div': {
      minHeight: '100%',
      height: '100%',
      marginRight: '16px'
    }
  },
  [theme.breakpoints.down(1224)]: {
    margin: '0 0 32px 0'
  }
}))
