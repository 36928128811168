import {styled} from '@mui/material/styles'
import FormControl from '@mui/material/FormControl'
import {FormHelperText, InputBase, InputLabel} from '@mui/material'
import {fontSize} from '@mui/system'

export const StyledFormControl = styled(FormControl)(({theme}) => ({
  position: 'relative',
  '.notRequiredText': {
    fontWeight: 400,
    color: theme.palette.primary.main
  },
  '.error': {
    border: `1px solid ${theme.palette.error.main}`
  },
  '.resize': {
    resize: 'vertical'
  },
  '& .lockIcon': {
    position: 'absolute',
    top: '21px',
    right: '4px'
  }
}))

export const StyledInputBase = styled(InputBase)(({theme}) => ({
  borderWidth: '1px',
  borderRadius: '3px',
  color: theme.palette.primary.main,
  '&.Mui-disabled': {
    '& input': {
      borderStyle: 'dashed'
    }
  }
}))

export const InputLabelWrapper = styled(InputLabel)(({theme}) => ({
  color: theme.palette.primary.main,
  fontSize: 16,
  fontWeight: 700
}))

export const FormHelperTextWrapper = styled(FormHelperText)(({theme}) => ({
  color: theme.palette.primary.main
}))
