import {toast} from 'react-toastify'

//TODO удалить notify по проекту
export const notifyError = (error) =>
  toast.error(`${error}`, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light'
  })

export const notifySuccess = (msg) =>
  toast.success(`${msg}`, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light'
  })
