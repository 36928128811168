export const initYourRodcomDataState = {
  firstName: '',
  lastName: '',
  middleName: '',
  email: '',
  phone: '+7',
  bank: ''
}

export const initYourRodcomDataErrors = {
  firstName: '',
  lastName: '',
  middleName: '',
  email: '',
  phone: '',
  bank: ''
}
