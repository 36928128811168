import {styled} from '@mui/material/styles'
import {Button, Typography} from '@mui/material'
import Image from 'components/Media/Image'
export const DoneProjectWrapper = styled('div')(({theme}) => ({
  background: '#ffffff',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: '0px 12px 32px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  alignItems: 'center',
  padding: '13px',
  width: '100%',
  border: `2px dashed ${theme.palette.primary.main}`,
  boxSizing: 'border-box',
  borderRadius: '16px',
  gap: '19px',
  [theme.breakpoints.up('md')]: {
    padding: '19px',
    width: '30%'
  },
  [theme.breakpoints.between('md', 1224)]: {
    width: '30%'
  }
}))

export const ActiveProjectWrapper = styled('div')(({theme}) => ({
  background: '#ffffff',
  display: 'flex',
  cursor: 'pointer',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxShadow: '0px 12px 48px rgba(57, 33, 94, 0.06)',
  border: '1px solid #EDEDFF',
  alignItems: 'center',
  padding: '20px',
  width: '100%',
  boxSizing: 'border-box',
  borderRadius: '16px',
  '&:hover': {
    boxShadow: 'none'
  },
  '&.payAll': {
    boxShadow: 'none',
    cursor: 'default'
  },
  [theme.breakpoints.up('md')]: {
    padding: '20px'
    // width: '30%'
  }
}))

export const ButtonWrapper = styled(Button)(({theme}) => ({
  color: '#39215E',
  height: '38px !important',
  fontSize: 16,
  width: '100%',
  boxSizing: 'border-box',
  marginTop: '13px',
  borderRadius: '13px',
  flexGrow: '0',
  padding: '13px 19px',
  whiteSpace: 'nowrap',
  background: 'rgba(57, 33, 94, 0.12)',
  border: '1px solid rgba(255, 255, 255, 1e-05)',
  '&:hover': {
    background: 'rgba(57, 33, 94, 0.24)',
    border: '1px solid rgba(255, 255, 255, 1e-05) !important'
  },
  '&:focused': {
    border: '1px solid rgba(255, 255, 255, 1e-05)'
  },
  '&:active': {
    boxShadow: ' 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
  },
  [theme.breakpoints.up('md')]: {
    marginTop: '19px'
  }
}))

export const SecondButtonWrapper = styled(Button)(({theme}) => ({
  // height: '50px !important',
  boxShadow: '0px 12px 32px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  width: '100%',
  boxSizing: 'border-box',
  borderRadius: '13px',
  color: '#39215E',
  // marginTop: '13px',
  fontSize: 16,
  flexGrow: '0',
  padding: '13px 19px',
  whiteSpace: 'nowrap',
  background: 'rgba(57, 33, 94, 0.12)',
  border: '1px solid rgba(255, 255, 255, 1e-05)',
  '&:hover': {
    background: 'rgba(57, 33, 94, 0.24)',
    border: '1px solid rgba(255, 255, 255, 1e-05)'
  },
  '&:focused': {
    border: '1px solid rgba(255, 255, 255, 1e-05)'
  },
  '&:active': {
    boxShadow: ' 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
  },
  [theme.breakpoints.up('md')]: {
    // marginTop: '19px'
  }
}))

export const WrapperLoader = styled('div')(({theme}) => ({
  position: 'relative',
  width: '100%',
  height: '8px',
  borderRadius: '6px',
  marginTop: '12px',
  overflow: 'hidden',
  background: '#EBEBF2',
  // border: '1px solid #5FA8D3',
  [theme.breakpoints.up('md')]: {
    marginTop: '12px'
  },
  '.text': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 5px',
    position: 'inherit',
    fontSize: 12,
    color: '#39215E',
    fontWeight: 600
  }
}))

export const Loader = styled('div')(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  // background: 'linear-gradient(45deg, rgb(251, 207, 1), rgb(253, 87, 12))'
  background: '#794DBD'
}))

export const PaymentBlock = styled('div')(({theme}) => ({
  color: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  padding: '3px 10px',
  width: 'min-content',
  height: '22px',
  whiteSpace: 'nowrap',
  background: theme.palette.primary.contrastText,
  fontSize: '14px',
  lineHeight: '13px',
  borderRadius: '6px',
  [theme.breakpoints.up('md')]: {
    margin: '6px 0px',
    fontSize: '16px',
    lineHeight: '22px',
    height: '29px'
  }
}))

export const DateBlock = styled('div')(({theme}) => ({
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '20px',
  color: theme.palette.primary.main,
  textAlign: 'right',
  marginBottom: '6px',
  [theme.breakpoints.up('md')]: {
    fontSize: '13px',
    lineHeight: '24px',
    height: '29px'
  }
}))

export const WrapperIconDatePayment = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: '13px',
  height: '70px',
  [theme.breakpoints.up('md')]: {
    marginBottom: '19px'
  }
}))

export const BlockTitle = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  fontSize: '28px',
  width: '100%',
  marginBottom: '13px',
  margin: 'auto',
  color: '#333',
  flexDirection: 'column'
}))

export const BlockDescription = styled('div')(({theme}) => ({
  fontSize: '16px',
  marginTop: '24px'
}))

export const WrapperText = styled(Typography)(({theme}) => ({
  overflowWrap: 'break-word',
  color: theme.palette.primary.main,
  flexGrow: '2',
  textAlign: 'left',
  width: '100%',
  fontSize: '18px !important',
  lineHeight: '19px !important',
  fontWeight: 700,
  [theme.breakpoints.up('md')]: {
    fontSize: '18px !important',
    lineHeight: '26px !important'
  }
}))

export const WrapperImg = styled(Image)(({theme}) => ({
  height: '38px',
  width: '38px',
  [theme.breakpoints.up('md')]: {
    height: '64px',
    width: '64px'
  }
}))

export const BlocksListWrapper = styled('div')(({theme}) => ({
  // display: 'flex',
  // justifyContent: 'space-between',
  marginBottom: 32,
  // gap: 24,
  // flexWrap: 'wrap',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateRows: 'auto',
  gridColumnGap: '20px',
  gridRowGap: '20px',
  '.activePay': {
    gridRowEnd: 3,
    gridColumnEnd: 2,
    gridRowStart: 1,
    gridColumnStart: 1
  },
  [theme.breakpoints.down(1000)]: {
    gridTemplateColumns: 'repeat(2, 1fr)'
  },
  [theme.breakpoints.down(740)]: {
    gridColumnGap: '18px',
    gridRowGap: '18px',
    '.activePay': {
      gridRowEnd: 1,
      gridColumnEnd: 1,
      gridRowStart: 1,
      gridColumnStart: 1
    }
  },
  [theme.breakpoints.down(640)]: {
    gridTemplateColumns: 'repeat(1, 1fr)'
  }
}))

export const VotesListWrapper = styled('div')(({theme}) => ({
  // display: 'flex',
  // justifyContent: 'space-between',
  marginBottom: 32,
  // gap: 24,
  // flexWrap: 'wrap',
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridTemplateRows: 'auto',
  gridColumnGap: '20px',
  gridRowGap: '20px',
  '.activePay': {
    gridRowEnd: 3,
    gridColumnEnd: 2,
    gridRowStart: 1,
    gridColumnStart: 1
  },
  [theme.breakpoints.down(1000)]: {
    gridTemplateColumns: 'repeat(2, 1fr)'
  },
  [theme.breakpoints.down(740)]: {
    gridColumnGap: '18px',
    gridRowGap: '18px',
    '.activePay': {
      gridRowEnd: 1,
      gridColumnEnd: 1,
      gridRowStart: 1,
      gridColumnStart: 1
    }
  },
  [theme.breakpoints.down(640)]: {
    gridTemplateColumns: 'repeat(1, 1fr)'
  }
}))
