export const restApiMapper = {
  toClient: async (restPromise, transformer) => {
    const response = await restPromise
    const serverData = response?.data
    if (transformer) {
      return transformer(serverData)
    }
    return serverData
  }
}

const matchResponseToClient = (scheme) => (field) => scheme[field]

export const getModifiedSchemeToClient = (data, matchPropsFn) =>
  Object.keys(data).reduce((modifiedData, key) => ({...modifiedData, [matchPropsFn(key)]: data[key]}), {})

export const getClientData = (serverData, scheme) =>
  getModifiedSchemeToClient(serverData, matchResponseToClient(scheme))
