import React, {useState} from 'react'

import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import {Typography} from '@mui/material'

import {GroupItem, OptionItem, StyledAutocomplete, StyledInputAdornment, StyledPopper} from './AutocompleteInputStyle'
import {CrossIcon} from '../../../icons'
import {InputLabelWrapper} from '../TextInput/TextInputStyle'
import {StyledChip} from '../../../pages/ParentCommittee/subpages/Projects/Project/components/Fundraising/FundraisingStyle'
import {StyledFormControl} from '../StyledSelect/StyledSelectStyle'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const AutocompleteInput = ({options, label, id, value, onChange, isOptionEqualToValue, multiple = true, readOnly}) => {
  const [isOpen, setIsOpen] = useState(true)
  return (
    <StyledFormControl variant="standard" fullWidth={true}>
      {label ? (
        <InputLabelWrapper htmlFor={id} shrink>
          {label}
        </InputLabelWrapper>
      ) : null}
      <StyledAutocomplete
        className={isOpen ? 'opened' : ''}
        multiple={multiple}
        value={value}
        onChange={onChange}
        // blurOnSelect="mouse"
        // ListboxProps={{style: {position: 'absolute', backgroundColor: '#fafafa'}}}
        // defaultValue={value[0]}
        defaultValue={value}
        id={`${id}-grouped-autocomplete`}
        options={options}
        classes={{popper: 'groupedAutocomplete'}}
        getOptionLabel={(option) => (option.title ? option.title : value)}
        renderInput={(params) => {
          params.InputProps.endAdornment = value ? (
            <StyledInputAdornment onClick={(e) => onChange(e, [])} position="end">
              <CrossIcon />
            </StyledInputAdornment>
          ) : null
          return (
            <TextField
              {...params}
              label="With categories"
              inputProps={{...params.inputProps, readOnly: readOnly ? true : false}}
            />
          )
        }}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        disableCloseOnSelect
        renderTags={(values) => (
          <StyledChip label={<Typography variant="subtitle1">Выбрано: {values.length}</Typography>} />
        )}
        // renderGroup={(params) => {
        //   console.log(params)
        //   return (
        //     <>
        //       <GroupItem variant="h4">{params.group}</GroupItem>
        //       {params.children}
        //     </>
        //   )
        // }}
        renderOption={(props, option, {selected}) => {
          return (
            <OptionItem {...props}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{marginRight: 8}} checked={selected} />
              {option.title}
            </OptionItem>
          )
        }}
        isOptionEqualToValue={isOptionEqualToValue}
        limitTags={1}
        noOptionsText="Нет результатов"
        // groupBy={(option) => option.group}
        // groupBy={(option) => {
        //   console.log(option)
        // }}
        PopperComponent={StyledPopper}
      />
    </StyledFormControl>
  )
}

export default AutocompleteInput
