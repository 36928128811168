import React, {useState, useEffect} from 'react'
import {Box, Typography} from '@mui/material'
import {styled, useTheme} from '@mui/material/styles'

import {getValuePlaceholder} from '../../../../../utils/getValuePlaceholder'

const StyledBillContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  cursor: 'pointer',
  textDecoration: 'none'
}))

const StyledBillLink = styled(Typography)(({theme}) => ({
  textDecoration: 'underline',
  color: theme.palette.primary.main
}))

const BillContainer = ({billLink, amountPaid, type, changeColor = false, projectFilter, cash, isDebtOperation}) => {
  const theme = useTheme()

  // const [cash, setCash] = useState('')
  //
  // useEffect(() => {
  //   if (projectFilter) {
  //     setCash(
  //       type === 'GiveOutSuccess'
  //         ? Math.abs(amountPaid)
  //         : type === 'ExpertReportConfirmed'
  //         ? `-${amountPaid}`
  //         : // : `${amountPaid > 0 ? '+' : ''}${amountPaid}`
  //           `${amountPaid > 0 ? '+' : ''}${getValuePlaceholder(amountPaid)}`
  //     )
  //   } else {
  //     setCash(
  //       type === 'Transfer' || type === 'GiveOutSuccess'
  //         ? Math.abs(amountPaid)
  //         : type === 'ExpertReportConfirmed'
  //         ? `-${amountPaid}`
  //         : // : `${amountPaid > 0 ? '+' : ''}${amountPaid}`
  //           `${amountPaid > 0 ? '+' : ''}${getValuePlaceholder(amountPaid)}`
  //     )
  //   }
  // }, [projectFilter, amountPaid, type])

  const renderOperationCost = () => {
    const costColor = isDebtOperation ? '#790050' : '#39215E'
    // amountPaid > 0 && billLink && type !== 'ExpertReportConfirmed'
    //   ? '#1B5500'
    //   : (billLink && amountPaid < 0) || (amountPaid < 0 && type === 'Transfer') || type === 'ExpertReportConfirmed'
    //   ? '#790050'
    //   : '#39215E'

    return (
      <Typography
        variant="cell"
        sx={{
          color: changeColor ? costColor : null,
          fontWeight: 600,
          minWidth: '25px',
          textAlign: 'right',
          padding: '4px 14px',
          whiteSpace: 'nowrap',
          background: isDebtOperation ? '#FFDDF3' : '#F5F5FF',

          // background:
          //   amountPaid > 0 && billLink && type !== 'ExpertReportConfirmed'
          //     ? '#D4F3C6'
          //     : (amountPaid < 0 && billLink) ||
          //       (amountPaid < 0 && type === 'Transfer') ||
          //       type === 'ExpertReportConfirmed'
          //     ? '#FFDDF3'
          //     : amountPaid === undefined
          //     ? null
          //     : '#F5F5FF',
          borderRadius: 800,
          [theme.breakpoints.down(600)]: {
            padding: '4px 4px'
          }
        }}
      >
        {cash}
      </Typography>
    )
  }
  return (
    <>
      {/* {billLink ? (
        <StyledBillContainer component={'a'} download href={billLink}>
          {renderOperationCost()}
          <StyledBillLink variant="caption">Скачать чек</StyledBillLink>
        </StyledBillContainer>
      ) : ( */}
      {renderOperationCost()}
      {/* )} */}
    </>
  )
}

export default BillContainer
