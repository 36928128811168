import React, {useMemo} from 'react'

import {Alert, AlertTitle} from '@mui/material'
import {styled} from '@mui/material/styles'
import WarningAmber from '@mui/icons-material/WarningAmber'

export const AlertWithStyles = styled(Alert)(({theme}) => ({
  '&': {
    borderRadius: 12,
    background: '#FFF3EA',
    color: theme.palette.warningLight.contrastText,
    padding: 13,
    alignItems: 'center'
  },
  '& .MuiSvgIcon-root': {
    alignSelf: 'center'
  },
  '& .MuiAlert-icon': {
    fontSize: '29px',
    marginRight: 18
  },
  '& .MuiAlertTitle-root': {
    fontWeight: 700
  },
  '&.error': {
    background: '#FFEEEE',
    color: '#CD0000'
  },
  '& .withoutChildren': {
    margin: 0
  },
  '&.blue': {
    background: theme.palette.blue.main,
    color: theme.palette.blue.contrastText,
    '& a': {
      color: theme.palette.blue.contrastText
    }
  },
  '&.success': {
    border: `1px solid ${theme.palette.success.main}`,
    background: theme.palette.success.contrastText,
    color: theme.palette.success.main,
    '& a': {
      color: theme.palette.success.main
    }
  },
  [theme.breakpoints.down('md')]: {
    '&.withoutIconOnMobile': {
      '& .MuiAlert-icon': {
        display: 'none'
      }
    }
  }
}))

const StyledAlert = React.forwardRef(
  (
    {
      withoutIconOnMobile = false,
      title,
      children,
      className = '',
      icon,
      isWarning = false,
      isError = false,
      isBlue,
      isSuccess,
      ...props
    },
    ref
  ) => {
    const classes = useMemo(() => {
      const withoutIconClass = withoutIconOnMobile ? 'withoutIconOnMobile' : ''
      const errorClass = isError ? 'error' : ''
      const colorClass = isBlue ? 'blue' : ''
      const successClass = isSuccess ? 'success' : ''
      return `${className} ${errorClass} ${withoutIconClass} ${colorClass} ${successClass}`
    }, [withoutIconOnMobile, isError])

    const currentIcon = isError && !icon ? <WarningAmber fontSize="inherit" /> : icon

    return (
      <AlertWithStyles ref={ref} classes={{root: classes}} icon={currentIcon} variant="filled" {...props}>
        {title && <AlertTitle className={children ? '' : 'withoutChildren'}>{title}</AlertTitle>}
        {children}
      </AlertWithStyles>
    )
  }
)
export default StyledAlert
