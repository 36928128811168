import React, {useState, useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {styled} from '@mui/material/styles'

import SelectChildForm from '../../../../components/SelectChildForm/SelectChildForm'
import {childrenSelectors} from '../../../../store/childStore/childStore'
import {profileSelectors} from '../../../../store/profileStore/profileStore'
import AccessRulesAndSaveBlock from '../AccessRulesAndSaveBlock/AccessRulesAndSaveBlock'
import YourRodcomDataForm from '../../../../components/YourRodcomDataForm/YourRodcomDataForm'
import {
  initYourRodcomDataErrors,
  initYourRodcomDataState
} from '../../../../components/YourRodcomDataForm/constants/YourRodcomDataFormConstants'
import {checkEmail, checkIfPhoneValid, getNameError} from '../../../../utils/validations'
import {creatingRkSelectors} from '../../../../store/creatingRkStore/creatingRkStore'
import {createNewRk, joinChildrenToRk} from '../../../../store/creatingRkStore/creatingRkStoreSliceEvents'

const Wrapper = styled('div')(() => ({
  display: 'flex',
  gap: 32,
  flexDirection: 'column',
  alignItems: 'center',
  '@media(max-width: 900px)': {
    display: 'block',
    margin: 0
  }
}))

const LeftBlock = styled('div')(() => ({
  width: '100%',
  gap: 32,
  display: 'flex',
  flexDirection: 'row',
  '@media(max-width: 1100px)': {
    maxWidth: '100%',
    flexDirection: 'column'
  }
}))

const HeadOfRodkomWrapper = ({newRKData}) => {
  const dispatch = useDispatch()

  const children = useSelector(childrenSelectors.children.selector)
  const user = useSelector(profileSelectors.user.selector)
  const loading = useSelector(creatingRkSelectors.isLoading.selector)

  const [yourRodcomErrors, setYourRodcomErrors] = useState(initYourRodcomDataErrors)
  const [yourRodcomFieldValues, setYourRodcomFieldValues] = useState(initYourRodcomDataState)
  const [checkedKids, setCheckedKids] = useState([])
  const [userAgreement, setUserAgreement] = useState(true)
  const [isUserAgreementErr, setIsUserAgreementErr] = useState(false)
  const [showAlertErr, setShowAlertErr] = useState(false)

  const setInitData = useCallback(() => {
    const modifiedPhone = user?.phone && !user.phone.includes('+') ? `+${user.phone}` : user?.phone
    const data = {
      firstName: user?.fullName?.firstName || '',
      lastName: user?.fullName?.surname || '',
      middleName: user?.fullName?.patronymic || '',
      email: user?.email || '',
      phone: modifiedPhone || '+7'
    }
    setYourRodcomFieldValues(data)
  }, [user])

  useEffect(setInitData, [user])

  const setErrorField = (field, isError) => {
    setYourRodcomErrors((errs) => ({...errs, [field]: isError}))
  }

  const clearAlertError = useCallback(() => {
    setShowAlertErr(false)
  }, [])

  const clearPhoneError = useCallback(() => {
    setErrorField('phone', false)
  }, [])

  const setFieldValue = ({value, field}) => {
    setYourRodcomFieldValues((values) => ({...values, [field]: value}))
  }

  const handleUserAgreement = useCallback(() => {
    if (isUserAgreementErr) {
      setIsUserAgreementErr(false)
    }
    setUserAgreement(!userAgreement)
  }, [userAgreement, isUserAgreementErr])

  const handleDataSubmit = () => {
    const newErrors = {}
    const {firstName, lastName, middleName, email, phone, bank} = yourRodcomFieldValues
    newErrors.firstName = getNameError(firstName, 'Введите имя')
    newErrors.lastName = getNameError(lastName, 'Введите фамилию')
    newErrors.email = checkEmail(email)
    newErrors.phone = checkIfPhoneValid(phone) ? '' : 'Введите номер телефона'
    setYourRodcomErrors(newErrors)
    const hasErrors = Object.values(newErrors).some((val) => Boolean(val))
    const isChildSelected = checkedKids.length
    if (!isChildSelected) {
      setShowAlertErr(true)
    }
    if (!userAgreement) {
      setIsUserAgreementErr(true)
    }
    const isNotValid = hasErrors || !isChildSelected || !userAgreement
    if (isNotValid) {
      return
    }
    const userData = {
      uuid: user?.uuid,
      fullName: {
        firstName: firstName,
        surname: lastName,
        patronymic: middleName
      },
      email: email,
      phone: phone
    }

    const fullNewRkData = {
      newRKData,
      userData: {...user, ...userData},
      checkedKids
    }

    dispatch(createNewRk.action(fullNewRkData))
  }

  const onChangeRodcomDataValue = useCallback(
    (value, field) => {
      const isError = yourRodcomErrors[field]
      if (isError) {
        setErrorField(field, false)
      }
      setFieldValue({value: value, field: field})
    },
    [yourRodcomErrors]
  )

  return (
    <Wrapper>
      <LeftBlock>
        <SelectChildForm
          clearError={clearAlertError}
          showErr={showAlertErr}
          kids={children}
          checkedKids={checkedKids}
          setCheckedKids={setCheckedKids}
        />
        <YourRodcomDataForm
          clearPhoneError={clearPhoneError}
          errors={yourRodcomErrors}
          fieldValues={yourRodcomFieldValues}
          onChangeValue={onChangeRodcomDataValue}
        />
      </LeftBlock>
      <AccessRulesAndSaveBlock
        userAgreement={userAgreement}
        handleUserAgreement={handleUserAgreement}
        loading={loading}
        handleDataSubmit={handleDataSubmit}
        isUserAgreementErr={isUserAgreementErr}
      />
    </Wrapper>
  )
}

export default HeadOfRodkomWrapper
