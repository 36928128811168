import React, {useEffect, useState, useMemo} from 'react'

import Box from '@mui/material/Box'
import {Dialog, CircularProgress} from '@mui/material'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'

import {CrossIcon, CheckIcon} from '../../icons'
import AvatarUploader from '../AvatarUploader/AvatarUploader'
import {
  checkOnlyLettersAndHyphen,
  getDateError,
  getDateErrorByReason,
  getEmptyError,
  getNameError
} from '../../utils/validations'
import TextInput from '../Inputs/TextInput/TextInput'
import DateInput, {DATE_ERRORS} from '../Inputs/DateInput/DateInput'
import StyledSelect from '../Inputs/StyledSelect/StyledSelect'
import {ModalContent} from './ChildModalStyle'
import * as childStoreSliceEvents from '../../store/childStore/childStoreSliceEvents'
import {useDispatch, useSelector} from 'react-redux'
import {directoriesSelectors} from '../../store/directoriesStore/directoriesStore'
import {
  findSelectedValueInDirectories,
  findSelectedValueInDirectoriesWithOrder,
  getOptionsFromDirectories,
  getOptionsFromDirectoriesWithOrder
} from '../../utils/getOptionsFromDirectories'
import {changeChildAvatarEvent, deleteChildAvatarEvent} from '../../store/childStore/childStoreSliceEvents'
import {getFormattedDate} from '../../utils/getFormattedDate'

const MIN_CHILD_DATE = new Date('1900-01-01')
const MAX_CHILD_DATE = new Date('2020-09-17')
export const getBirthdayError = (value, maxDate, minDate) => {
  if (!value || value?.toString() === 'Invalid Date') {
    return 'Введите валидную дату'
  }
  // if (maxDate && value > maxDate) {
  //   return `Дата рождения должна быть меньше ${getFormattedDate(maxDate)}`
  // }
  if (minDate && value < minDate) {
    return `Введите дату больше ${getFormattedDate(minDate)}`
  }
  return ''
}
export const ChildModal = ({
  child,
  isOpen,
  onClose,
  onSave,
  onDelete,
  errorsFromBack,
  childrenLoading,
  handleAvatarSave,
  handleAvatarDelete
}) => {
  const dispatch = useDispatch()

  const [firstName, setFirstName] = useState(child?.firstName)
  const [lastName, setLastName] = useState(child?.lastName)
  const [birthday, setBirthday] = useState(null)
  const [attitudeWithChild, setAttitudeWithChild] = useState(child?.attitudeWithChild)
  const [gender, setGender] = useState(child?.gender)
  const [avatarUrl, setAvatarUrl] = useState(child?.avatarUrl)
  const [avatarSrc, setAvatarSrc] = useState(null)
  const [errors, setErrors] = useState({})
  const [openConfirm, setOpenConfirm] = useState(false)

  const kinship = useSelector(directoriesSelectors.kinship.selector)
  const genderSelector = useSelector(directoriesSelectors.gender.selector)
  const kinshipOptions = useMemo(() => getOptionsFromDirectoriesWithOrder(kinship), [kinship])
  const genderOptions = useMemo(() => getOptionsFromDirectories(genderSelector).reverse(), [genderSelector])
  useEffect(() => {
    setAvatarUrl(child?.avatarUrl)
  }, [child])

  useEffect(() => {
    if (errorsFromBack) {
      const hasErrors = Object.values(errorsFromBack).some((val) => Boolean(val))
      if (hasErrors) {
        setErrors(errorsFromBack)
        dispatch(childStoreSliceEvents.setErrors.action({}))
      }
    }
  }, [dispatch, errorsFromBack])

  const onDeleteClick = () => {
    if (child) {
      // setErrors({})
      // dispatch(childStoreSliceEvents.setErrors.action({}))
      // onDelete(child?.id)
      // onClose()
      setOpenConfirm(true)
    } else {
      onClose()
    }
  }

  const deleteChildren = () => {
    setErrors({})
    dispatch(childStoreSliceEvents.setErrors.action({}))
    onDelete(child?.id)
    onClose()
  }

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const setErrorField = (field, val = '') => {
    setErrors((oldErrs) => ({...oldErrs, [field]: val}))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const newErrors = {}
    newErrors.firstName = getNameError(firstName, 'Заполните имя')
    newErrors.lastName = getNameError(lastName, 'Заполните фамилию')
    newErrors.birthday = getBirthdayError(birthday, MAX_CHILD_DATE, MIN_CHILD_DATE)
    newErrors.attitudeWithChild = getEmptyError(attitudeWithChild)
    newErrors.gender = getEmptyError(gender)
    const hasErrors = Object.values(newErrors).some((val) => Boolean(val))
    if (hasErrors) {
      setErrors(newErrors)
      return
    }
    const newChild = {
      firstName,
      lastName,
      birthday,
      attitudeWithChild: findSelectedValueInDirectoriesWithOrder(kinship, attitudeWithChild),
      gender: findSelectedValueInDirectories(genderSelector, gender),
      avatarUrl,
      avatarSrc
    }
    if (!child?.id) {
      newChild.avatarSrc = avatarSrc
    }
    onSave(newChild, child?.id)
    onClose()
  }

  const prepareChildData = () => {
    const date = child?.birthday
    const firstName = child?.firstName || ''
    const lastName = child?.lastName || ''
    const attitudeWithChild = child?.attitudeWithChild || ''
    const gender = child?.gender || ''
    const preparedDate = date ? new Date(date) : null

    setFirstName(firstName)
    setLastName(lastName)
    setBirthday(preparedDate)
    setAttitudeWithChild(attitudeWithChild)
    setGender(gender)
  }

  useEffect(prepareChildData, [child])

  const onDateChange = (newValue) => {
    setBirthday(newValue)
  }

  const getDateErrorForBirthday = ({reason, minDate}) => {
    const minDatePrepared = new Date(minDate)
    switch (reason) {
      case DATE_ERRORS.maxDate: {
        return `Дата рождения должна быть меньше текущей даты`
      }
      case DATE_ERRORS.minDate: {
        if (!minDate) {
          return 'Введите валидную дату'
        }
        const formattedDate = getFormattedDate(minDatePrepared)
        return `Введите дату не меньше ${formattedDate}`
      }
      case DATE_ERRORS.invalidDate:
        return 'Введите валидную дату'
      default:
        return ''
    }
  }

  const onDateError = (reason) => {
    const error = getDateErrorForBirthday({reason, minDate: MIN_CHILD_DATE})
    setErrorField('birthday', error)
  }

  const onAvatarSave = (src) => {
    setAvatarUrl(src)
    if (!child?.id) {
      setAvatarSrc(src)
    } else {
      dispatch(changeChildAvatarEvent.action(child.id, src))
      onClose()
    }
    // if (handleAvatarSave) {
    //   handleAvatarSave(src, child?.id)
    // }
  }

  const onAvatarDelete = () => {
    setAvatarUrl('')
    if (!child?.id) {
      setAvatarSrc(null)
    } else {
      dispatch(deleteChildAvatarEvent.action(child.id))
    }
    if (handleAvatarDelete) {
      handleAvatarDelete(child?.id)
    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen={fullScreen} fullWidth maxWidth="xs">
      <ModalContent classes={{root: childrenLoading ? 'childrenLoading' : ''}}>
        {childrenLoading ? (
          <CircularProgress sx={{margin: '0 auto'}} />
        ) : (
          <>
            <IconButton
              sx={{
                position: 'absolute',
                top: '8px',
                left: 'auto',
                right: '12px'
              }}
              onClick={onClose}
            >
              <CrossIcon />
            </IconButton>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                maxWidth: '480px',
                alignItems: 'center',
                margin: '0 auto'
              }}
            >
              <Dialog
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                fullScreen={fullScreen}
                fullWidth
                maxWidth="xs"
              >
                <Box sx={{padding: '20px', display: 'flex', flexDirection: 'column', gap: '24px'}}>
                  <span style={{alignSelf: 'center', fontSize: '16px', fontWeight: 700, color: '#39215E'}}>
                    Вы уверены что хотите удалить ребенка ?
                  </span>
                  <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button
                      variant="contained"
                      type="button"
                      color="info"
                      onClick={() => setOpenConfirm(false)}
                      startIcon={<CrossIcon color="#CB0843" />}
                    >
                      Нет
                    </Button>
                    <Button
                      variant="contained"
                      type="button"
                      startIcon={<CheckIcon color="white" />}
                      onClick={deleteChildren}
                    >
                      Да
                    </Button>
                  </Box>
                </Box>
              </Dialog>
              <AvatarUploader avatarUrl={avatarUrl} onSave={onAvatarSave} onDelete={onAvatarDelete} />
              <TextInput
                clearError={() => setErrorField('lastName')}
                errorMsg={errors.lastName}
                label="Фамилия"
                id="child-lastName"
                validator={checkOnlyLettersAndHyphen}
                value={lastName}
                onChangeValue={setLastName}
                placeholder="Фамилия ребёнка"
              />
              <TextInput
                clearError={() => setErrorField('firstName')}
                errorMsg={errors.firstName}
                label="Имя"
                id="child-firstName"
                value={firstName}
                validator={checkOnlyLettersAndHyphen}
                onChangeValue={setFirstName}
                placeholder="Имя ребёнка"
              />
              <DateInput
                className="child-modal-date-input text"
                setError={(val) => setErrorField('birthday', val)}
                errorMsg={errors.birthday}
                label="Дата рождения"
                id="child-birth-date"
                inputFormat="dd/MM/yyyy"
                mask="__/__/____"
                openTo="year"
                value={birthday}
                onChange={onDateChange}
                onError={onDateError}
                maxDate={new Date()}
                minDate={MIN_CHILD_DATE}
              />
              <StyledSelect
                clearError={() => setErrorField('gender')}
                error={Boolean(errors.gender)}
                errorMsg={errors.gender}
                id="child-attitude"
                label="Пол ребёнка"
                value={gender}
                onChangeValue={setGender}
                helpText="Выберите вариант ответа"
                className="childGender"
                options={genderOptions}
              />
              <StyledSelect
                clearError={() => setErrorField('attitudeWithChild')}
                error={Boolean(errors.attitudeWithChild)}
                errorMsg={errors.attitudeWithChild}
                id="child-attitude"
                label="Кто вы ребенку"
                value={attitudeWithChild}
                onChangeValue={setAttitudeWithChild}
                helpText="Выберите вариант ответа"
                options={kinshipOptions}
              />
              <Box
                sx={{display: 'flex', justifyContent: 'space-between', width: '100%', gap: '6px', marginTop: '24px'}}
              >
                <Button
                  variant="contained"
                  type="button"
                  color="info"
                  onClick={onDeleteClick}
                  startIcon={<CrossIcon color="#CB0843" />}
                  // className="delBtn"
                >
                  {child ? 'Удалить' : 'Отмена'}
                </Button>
                <Button variant="contained" type="submit" startIcon={<CheckIcon color="white" />}>
                  Сохранить
                </Button>
              </Box>
            </Box>
          </>
        )}
      </ModalContent>
    </Dialog>
  )
}
