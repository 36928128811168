import React, {useState, useRef, useEffect} from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import {styled} from '@mui/material/styles'
import {avatarURL} from '../../utils/imageURL'

const CropWrapper = styled('div')(({theme}) => ({
  width: '80%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginBottom: '24px',
  '& .ReactCrop': {
    flex: '0 1 45%'
  },
  '& .ReactCrop__crop-selection': {
    borderRadius: '50%'
  },
  '& .crop-preview': {
    flex: '0 1 200px',
    height: '200px',
    borderRadius: '50%'
  },
  [theme.breakpoints.down(600)]: {
    '& .ReactCrop': {
      flex: '0 1 100%'
    },
    '& .crop-preview': {
      display: 'none'
    }
  }
}))

const ImageCrop = ({src, updateImageUrl}) => {
  const [crop, setCrop] = useState({
    unit: '%',
    width: 24,
    aspect: 1
  })

  const [croppedImageUrl, setCroppedImageUrl] = useState(null)

  const imageRef = useRef()

  // If you setState the crop in here you should return false.
  const onImageLoaded = (image) => {
    imageRef.current = image
  }

  useEffect(() => {
    updateImageUrl(croppedImageUrl)
  }, [croppedImageUrl, updateImageUrl])

  const onCropComplete = (crop) => {
    // noinspection JSIgnoredPromiseFromCall
    makeClientCrop(crop)
  }

  const onCropChange = (crop) => {
    setCrop(crop)
  }

  const makeClientCrop = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(imageRef.current, crop)
      setCroppedImageUrl(croppedImageUrl)
    }
  }

  const getCroppedImg = (image, crop) => {
    image.setAttribute('crossorigin', 'anonymous')
    const canvas = document.createElement('canvas')
    const pixelRatio = window.devicePixelRatio
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')

    canvas.width = crop.width * pixelRatio * scaleX
    canvas.height = crop.height * pixelRatio * scaleY

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)

    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    )

    return new Promise((resolve) => {
      const jpegUrl = canvas.toDataURL('image/jpeg')
      resolve(jpegUrl)
    })
  }

  return (
    <CropWrapper>
      {src && (
        <ReactCrop
          src={avatarURL(src)}
          crop={crop}
          ruleOfThirds
          onImageLoaded={onImageLoaded}
          onComplete={onCropComplete}
          onChange={onCropChange}
        />
      )}
      {croppedImageUrl && <img alt="Crop" className="crop-preview" src={croppedImageUrl} />}
    </CropWrapper>
  )
}

export default ImageCrop
