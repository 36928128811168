import {styled} from '@mui/material/styles'

export const Wrapper = styled('div')(({theme}) => ({
  '.header': {
    padding: '0 19px'
  },
  '.back': {
    marginTop: 30,
    marginBottom: 11
  },
  '.title': {
    fontSize: '19px',
    marginBottom: 6
  },
  '.parentCommitteeName': {
    color: theme.palette.primary.main,
    marginBottom: 19
  },
  '.formInner': {
    padding: 24,
    background: '#FFFFFF',
    // boxShadow: '0px 12px 16px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
    border: '1px solid #EDEDFF',
    borderRadius: 12,
    '.creacteForm': {
      marginTop: 24
    }
    // marginBottom: 24
  },
  '.params': {
    fontSize: '18px',
    lineHeight: '28px',
    color: theme.palette.primary.main,
    marginBottom: 28,
    fontWeight: 700
  },
  '.select': {
    marginBottom: 24,
    '& em': {
      color: theme.palette.primary.main
    }
  },
  '.separateInput': {
    marginBottom: 24
  },
  '.chat': {
    marginBottom: 24
  },
  '.warning': {
    fontSize: 16,
    lineHeight: '24px',
    color: '#39215E',
    background: '#FFF3EA',
    padding: 8,
    borderRadius: 12,
    maxWidth: 'auto',
    svg: {
      width: 28,
      height: 28
    }
  },
  '.form': {
    display: 'block'
  },
  '@media(max-width: 1000px)': {
    '.formInner': {
      marginBottom: 32
    }
  },
  '@media(min-width: 1000px)': {
    '.form': {
      display: 'flex',
      alignItems: 'flex-start',
      gap: 32,
      padding: 0
    },
    '.warning': {
      // maxWidth: 800
      fontSize: 16,
      lineHeight: '24px',
      color: '#39215E',
      background: '#FFF3EA',
      padding: 8,
      borderRadius: 12,
      svg: {
        width: 28,
        height: 28
      }
    }
  },
  '.institutionInfo': {
    display: 'flex',
    flexDirection: 'column',
    gap: 13
  },
  '@media(min-width: 750px)': {
    '.institutionInfo': {
      display: 'flex',
      gap: 13
    }
  },
  '@media(min-width: 600px)': {
    '.title': {
      fontSize: '38px',
      lineHeight: '45px'
    },
    '.parentCommitteeName': {
      marginBottom: 38
    },
    '.formInner': {
      padding: 32,
      borderRadius: 12
    },
    '.params': {
      fontSize: '24px',
      lineHeight: '36px'
    }
  },
  '@media(max-width: 480px)': {
    '.formInner': {
      borderRadius: 12
    },
    '.form': {
      padding: 0
    }
  }
}))
