import React from 'react'
import {Typography} from '@mui/material'

import {PlusIcon} from '../../../../icons'
import {WrapperButton, EmptyListWrapper} from './EmptyListStyle'
import Button from '@mui/material/Button'

const EmptyList = ({length, handleClick}) => {
  return (
    <EmptyListWrapper>
      <Typography variant="h2">
        {length ? 'Нет вашего родкома? Создайте его! Это бесплатно.' : 'Здесь пока нет родкомов. Создайте первый!'}
      </Typography>
      <WrapperButton>
        <Button
          variant="contained"
          type="button"
          color="secondary"
          sx={{boxShadow: 'none'}}
          onClick={handleClick}
          endIcon={<PlusIcon color="white" sx={{marginLeft: 12}} />}
        >
          Зарегистрировать родком
        </Button>
      </WrapperButton>
    </EmptyListWrapper>
  )
}

export default EmptyList
