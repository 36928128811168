import {call, put, takeLatest, select} from 'redux-saga/effects'
import * as childStoreSliceEvents from '../../store/childStore/childStoreSliceEvents'
import {
  addNewChild,
  changeChildAvatar,
  deleteChildAvatar,
  deleteChildReq,
  getChildren,
  updateChild
} from '../../services/child'
import {withErrorLogging} from '../../utils/errorSaga'
import {getTransformedErrorsFromBack} from '../../utils/getTransformedErrorsFromBack'
import {getClientData} from '../../utils/restApiMapper'
import {ERRORS_FROM_BACK_FIELDS} from '../../pages/CreatingRodcom/constants/creatingRodcomConstants'
import * as authyorizationEvents from '../../store/initStore/initStoreSliceEvents'
import {directoriesSelectors} from '../../store/directoriesStore/directoriesStore'
import {getDirectories} from '../../services/directories'
import {EVENT_STATUSES} from '../../pages/LoginPage/constants'
import * as childEvents from '../../store/childStore/childStoreSliceEvents'

export function* getChildrenRequest() {
  try {
    const response = yield withErrorLogging(call(getChildren))

    let kinship = yield select(directoriesSelectors.kinship.selector)
    let genders = yield select(directoriesSelectors.gender.selector)
    const isKinshipEmpty = !kinship || !Object.keys(kinship).length
    if (isKinshipEmpty) {
      const dirs = yield withErrorLogging(call(getDirectories))
      kinship = dirs?.data?.payload?.kinship
    }
    const isGendersEmpty = !genders || !Object.keys(genders).length
    if (isGendersEmpty) {
      const dirs = yield withErrorLogging(call(getDirectories))
      genders = dirs?.data?.payload?.gender
    }
    const childrenArr = response.payload?.map((item) => ({
      ...item,
      attitudeWithChild: kinship.find((x) => x.uuid === item?.attitudeWithChild)?.name,
      gender: genders?.[item?.gender]
    }))
    yield put(childStoreSliceEvents.getChildrenSuccess.action(childrenArr))
    yield put(authyorizationEvents.setRequestStatus.action(response.event))
  } catch (e) {
    yield put(childStoreSliceEvents.setIsLoading.action(false))
    // console.error(e)
  }
}

export function* addChildRequest(action) {
  try {
    const {child} = action.payload
    // console.log(child)
    const response = yield withErrorLogging(call(addNewChild, child))
    if (child?.avatarSrc) {
      yield put(childStoreSliceEvents.changeChildAvatarEvent.action(response?.payload?.id, child?.avatarSrc))
    }
    yield put(authyorizationEvents.setRequestStatus.action(response.event))
    yield put(childStoreSliceEvents.getChildren.action())
  } catch (e) {
    const errors = getTransformedErrorsFromBack(e)
    if (errors) {
      const modifiedErrors = getClientData(errors, ERRORS_FROM_BACK_FIELDS)
      yield put(childStoreSliceEvents.setErrors.action(modifiedErrors))
    }
    yield put(childStoreSliceEvents.setIsLoading.action(false))
    // console.error(e)
  }
}

export function* updateChildRequest(action) {
  try {
    const {child} = action.payload
    yield withErrorLogging(call(updateChild, child))
    yield put(childStoreSliceEvents.getChildren.action())
  } catch (e) {
    const errors = getTransformedErrorsFromBack(e)
    if (errors) {
      const modifiedErrors = getClientData(errors, ERRORS_FROM_BACK_FIELDS)
      yield put(childStoreSliceEvents.setErrors.action(modifiedErrors))
    }
    yield put(childStoreSliceEvents.setIsLoading.action(false))
    // console.error(e)
  }
}

export function* deleteChild(action) {
  try {
    const {uuid} = action.payload
    yield withErrorLogging(call(deleteChildReq, uuid))
    yield put(childStoreSliceEvents.getChildren.action())
  } catch (e) {
    const errors = getTransformedErrorsFromBack(e)
    if (errors) {
      const modifiedErrors = getClientData(errors, ERRORS_FROM_BACK_FIELDS)
      yield put(childStoreSliceEvents.setErrors.action(modifiedErrors))
    }
    yield put(childStoreSliceEvents.setIsLoading.action(false))
    // console.error(e)
  }
}

export function* changeChildAvatarRequest(action) {
  const {uuid, avatar} = action.payload
  try {
    const res = yield withErrorLogging(call(changeChildAvatar, uuid, avatar))
    if (res?.data?.event === EVENT_STATUSES.USER_AVATAR_UPDATE) {
      yield put(childEvents.getChildren.action())
    }
  } catch (e) {
    // console.error(e)
  }
  yield put(childStoreSliceEvents.setIsLoading.action(false))
}

export function* deleteChildAvatarRequest(action) {
  const {uuid} = action.payload
  try {
    yield withErrorLogging(call(deleteChildAvatar, uuid))
  } catch (e) {
    // console.error(e)
  }
  yield put(childStoreSliceEvents.setIsLoading.action(false))
}

export function* childrenSaga() {
  yield takeLatest(childStoreSliceEvents.getChildren.type, getChildrenRequest)
  yield takeLatest(childStoreSliceEvents.addChild.type, addChildRequest)
  yield takeLatest(childStoreSliceEvents.updateChildEvent.type, updateChildRequest)
  yield takeLatest(childStoreSliceEvents.changeChildAvatarEvent.type, changeChildAvatarRequest)
  yield takeLatest(childStoreSliceEvents.deleteChildAvatarEvent.type, deleteChildAvatarRequest)
  yield takeLatest(childStoreSliceEvents.deleteChildEvent.type, deleteChild)
}
