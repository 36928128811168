import {call, put, takeLatest} from 'redux-saga/effects'
import * as votingSliceEvents from '../../store/votingStore/votingStoreSliceEvents'
import {withErrorLogging} from '../../utils/errorSaga'
import {
  createVoteRequest,
  getVotesListRequest,
  getVoteRequest,
  toVoteRequest,
  updateVoteRequest,
  archiveVoteRequest
} from '../../services/voting'
import * as authyorizationEvents from '../../store/initStore/initStoreSliceEvents'
import {EVENT_VOTE_STATUSES} from '../../pages/ParentCommittee/subpages/Votes/components/VoteCreatePage/constants/creatingVotingConstants'

export function* getVotesList(action) {
  try {
    const response = yield withErrorLogging(call(getVotesListRequest, action.payload.data))
    const responseData = response.data.payload
    if (response?.data?.event === EVENT_VOTE_STATUSES.VOTE_INFO_LIST_GET.STATUS) {
      yield put(votingSliceEvents.getVotesListEventSuccess.action(responseData))
    }
  } catch (e) {
    // console.error(e)
  }
  yield put(votingSliceEvents.setIsLoading.action(false))
}

export function* getVotesListWithRewrite(action) {
  try {
    const response = yield withErrorLogging(call(getVotesListRequest, action.payload.data))
    const responseData = response.data.payload
    if (response?.data?.event === EVENT_VOTE_STATUSES.VOTE_INFO_LIST_GET.STATUS) {
      yield put(votingSliceEvents.getVotesListWithRewriteEventSuccess.action(responseData))
    }
  } catch (e) {
    // console.error(e)
  }
  yield put(votingSliceEvents.setIsLoading.action(false))
}

export function* getVote(action) {
  try {
    const response = yield withErrorLogging(call(getVoteRequest, action.payload.data))
    const responseData = response.data.payload
    if (responseData) {
      yield put(votingSliceEvents.getVoteEventSuccess.action(responseData))
    }
  } catch (e) {
    // console.error(e)
  }
  yield put(votingSliceEvents.setIsLoading.action(false))
}

export function* createVote(action) {
  try {
    const {
      data: {myData}
    } = action.payload
    const response = yield withErrorLogging(call(createVoteRequest, myData))

    if (response?.data?.event === EVENT_VOTE_STATUSES.VOTE_CREATED.STATUS && response?.data?.payload) {
      yield put(votingSliceEvents.getVoteEvent.action(response.data.payload))
      yield put(votingSliceEvents.setRequestPayload.action(response.data.payload))
      yield put(authyorizationEvents.setSuccessMsg.action(EVENT_VOTE_STATUSES.VOTE_CREATED.MESSAGE_TO_USER))
    }
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(votingSliceEvents.setIsLoading.action(false))
  }
}

export function* updateVote(action) {
  try {
    const {
      data: {voteId, myData}
    } = action.payload
    const response = yield withErrorLogging(call(updateVoteRequest, voteId, myData))

    if (response?.data?.event === EVENT_VOTE_STATUSES.VOTE_UPDATED.STATUS && response?.data?.payload) {
      yield put(votingSliceEvents.getVoteEvent.action(voteId))
      yield put(votingSliceEvents.setRequestPayload.action(voteId))
      yield put(authyorizationEvents.setSuccessMsg.action(EVENT_VOTE_STATUSES.VOTE_UPDATED.MESSAGE_TO_USER))
    }
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(votingSliceEvents.setIsLoading.action(false))
  }
}

export function* toVote(action) {
  try {
    const {data} = action.payload
    const response = yield withErrorLogging(call(toVoteRequest, data))

    if (response?.data?.event === EVENT_VOTE_STATUSES.VOTE_RESULT_SUCCESS.STATUS && response?.data?.payload) {
      yield put(votingSliceEvents.getVoteEvent.action(data.voteUuid))
      yield put(authyorizationEvents.setSuccessMsg.action(EVENT_VOTE_STATUSES.VOTE_RESULT_SUCCESS.MESSAGE_TO_USER))
    }
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(votingSliceEvents.setIsLoading.action(false))
  }
}

export function* archiveVote(action) {
  try {
    const {data} = action.payload
    const response = yield withErrorLogging(call(archiveVoteRequest, data))

    if (response?.data?.event === EVENT_VOTE_STATUSES.VOTE_ARCHIVED_SUCCESS.STATUS && response?.data?.payload) {
      yield put(votingSliceEvents.getVoteEvent.action(data))
      yield put(votingSliceEvents.setRequestPayload.action(response.data.payload))
      yield put(authyorizationEvents.setSuccessMsg.action(EVENT_VOTE_STATUSES.VOTE_ARCHIVED_SUCCESS.MESSAGE_TO_USER))
    }
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(votingSliceEvents.setIsLoading.action(false))
  }
}

export function* votingSaga() {
  yield takeLatest(votingSliceEvents.getVotesListEvent.type, getVotesList)
  yield takeLatest(votingSliceEvents.getVotesListWithRewriteEvent.type, getVotesListWithRewrite)
  yield takeLatest(votingSliceEvents.createVoteEvent.type, createVote)
  yield takeLatest(votingSliceEvents.updateVoteEvent.type, updateVote)
  yield takeLatest(votingSliceEvents.getVoteEvent.type, getVote)
  yield takeLatest(votingSliceEvents.toVoteEvent.type, toVote)
  yield takeLatest(votingSliceEvents.archiveVoteEvent.type, archiveVote)
}
