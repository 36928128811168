import React, {useState, useMemo, useEffect, useCallback} from 'react'

import Typography from '@mui/material/Typography'
import {styled, useTheme} from '@mui/material/styles'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import {Button} from '@mui/material'

import KidsCheckbox from '../KidsCheckbox/KidsCheckbox'
import StyledAlert from '../StyledAlert/StyledAlert'
import {PlusIcon} from '../../icons'
import {ChildModal} from '../ChildModal/ChildModal'
import {useDispatch, useSelector} from 'react-redux'
import {
  addChild,
  deleteChildEvent,
  updateChildEvent,
  setChildNewAvatarUrlEvent
} from '../../store/childStore/childStoreSliceEvents'
import {childrenSelectors} from '../../store/childStore/childStore'
import {initAppSelectors} from '../../store/initStore/initStore'
import {EVENT_STATUSES} from '../../pages/LoginPage/constants'
import {setRequestStatus} from '../../store/initStore/initStoreSliceEvents'
import {imageURL, avatarURL} from '../../utils/imageURL'

const StyledBlock = styled('div')(() => ({
  backgroundColor: '#ffffff',
  // boxShadow: '0px 12px 12px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  border: '1px solid #EDEDFF',
  padding: 32,
  borderRadius: '12px',
  boxSizing: 'border-box',
  width: '100%',
  '.whoStudyWarning': {
    marginBottom: '20px',
    fontSize: 12,
    lineHeight: '24px',
    color: '#39215E',
    background: '#FFF3EA',
    padding: 8,
    borderRadius: 12,
    maxWidth: 'auto',
    svg: {
      width: 28,
      height: 28
    }
  },
  '.kidsCheckbox': {
    marginBottom: 8,
    '&:last-of-type': {
      marginBottom: 32
    }
  },
  '.whoStudyButton': {
    width: '100%',
    padding: '31px 0',
    marginBottom: '15px',
    borderRadius: '8px',
    // fontSize: 16,
    color: '#39215E',
    background: '#ffffff',
    border: '1px dashed rgba(0, 0, 0, 0.12)',
    boxShadow: 'none',
    '&:hover': {
      background: '#ffffff',
      border: '1px dashed rgba(0, 0, 0, 0.12)'
    },
    // '&:focused': {
    //   border: '1px solid rgba(255, 255, 255, 1e-05)'
    // },
    // '&:active': {
    //   boxShadow:
    //     ' 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
    // },
    svg: {
      path: {
        stroke: '#39215E !important'
      }
    }
  }
}))

const StyledTypography = styled(Typography)(({theme}) => ({
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '36px',
  color: theme.palette.primary.main,
  marginBottom: '24px',
  '@media (max-width: 600px)': {
    fontSize: '24px',
    lineHeight: '28px'
  }
}))

const SelectChildForm = ({kids = [], checkedKids, setCheckedKids, clearError, showErr}) => {
  const dispatch = useDispatch()
  const theme = useTheme()

  const errors = useSelector(childrenSelectors.errors.selector)
  const childrenLoading = useSelector(childrenSelectors.childrenLoading.selector)
  const requestStatus = useSelector(initAppSelectors.requestStatus.selector)

  const [isCreateKidModalOpen, setIsCreateKidModalOpen] = useState(false)
  const [activeChildData, setActiveChildData] = useState(null)

  useEffect(() => {
    if (requestStatus === EVENT_STATUSES.CHILD_ADDED) {
      setIsCreateKidModalOpen(false)
      dispatch(setRequestStatus.action(''))
    }
  }, [requestStatus])

  const onCloseModal = () => {
    setIsCreateKidModalOpen(false)
    setActiveChildData(null)
  }

  const setChildNewAvatarUrl = useCallback(
    (avatarUrl, childUuid) => {
      dispatch(setChildNewAvatarUrlEvent.action(avatarUrl, childUuid))
      onCloseModal()
    },
    [dispatch]
  )

  const handleDeleteChild = useCallback((uuid) => {
    dispatch(deleteChildEvent.action(uuid))
  }, [])

  useEffect(() => {
    const kidsId = kids.map((child) => child.id)
    setCheckedKids(kidsId)
  }, [kids])

  const onOpenModal = (childId) => {
    if (childId) {
      const selectedChild = kids.find(({id}) => id === childId)
      setActiveChildData(selectedChild)
      setIsCreateKidModalOpen(true)
    } else {
      setIsCreateKidModalOpen(true)
      if (showErr && clearError) {
        clearError()
      }
    }
  }

  const onKidCheck = (checked, id) => {
    if (checkedKids.includes(id)) {
      setCheckedKids([...checkedKids.filter((val) => val !== id)])
    } else {
      setCheckedKids([...checkedKids, id])
      if (showErr && clearError) {
        clearError()
      }
    }
  }

  const onSaveChild = (child, id) => {
    if (id) {
      dispatch(updateChildEvent.action({...child, id}))
    } else {
      dispatch(addChild.action(child))
    }
  }

  const mappedKids = useMemo(() => {
    return kids.map(({id, firstName, avatarUrl, birthday}) => {
      return (
        <KidsCheckbox
          key={id}
          className="kidsCheckbox"
          checked={checkedKids.includes(id)}
          setChecked={(val) => onKidCheck(val, id)}
          avatarUrl={imageURL(
            avatarUrl
              ?.split('/')
              ?.find((elem) => elem.includes('.jpg'))
              ?.replace('.jpg', '') || null
          )}
          name={firstName}
          birthday={birthday}
          onOpenModal={onOpenModal}
          id={id}
        />
      )
    })
  }, [kids, checkedKids, showErr])

  return (
    <StyledBlock>
      <StyledTypography>Кто из ваших детей здесь обучается?</StyledTypography>
      {showErr && (
        <StyledAlert icon={false} className="whoStudyWarning">
          Поставьте галочку напротив ребёнка или добавьте новую запись.
        </StyledAlert>
      )}
      <Button
        variant="contained"
        startIcon={<PlusIcon color={theme.palette.primary.contrastText} />}
        classes={{root: 'whoStudyButton'}}
        type="button"
        onClick={onOpenModal}
      >
        Добавить ребенка
      </Button>
      {mappedKids}
      {isCreateKidModalOpen && (
        <ChildModal
          errorsFromBack={errors}
          onSave={onSaveChild}
          child={activeChildData}
          isOpen={isCreateKidModalOpen}
          onClose={onCloseModal}
          onDelete={handleDeleteChild}
          childrenLoading={childrenLoading}
          handleAvatarSave={setChildNewAvatarUrl}
        />
      )}
    </StyledBlock>
  )
}

export default SelectChildForm
