import initSlice from '../initSlice'

const {sliceEvent, allSliceEvents} = initSlice()

const PREFIX = 'REPORTING_'

export const setIsLoading = sliceEvent({
  type: `${PREFIX}_LOADING`,
  action: (isLoading) => ({
    payload: {
      isLoading
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {isLoading} = action.payload
    return {
      ...state,
      isLoading
    }
  }
})

export const makeRkMembersEvent = sliceEvent({
  type: `${PREFIX}_MAKE_RK_USERS_REPORT`,
  action: (uuid) => ({
    payload: {
      uuid
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const allInitAppEvents = allSliceEvents
