import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import ParagraphWrapper from '../components/ParagraphWrapper/ParagraphWrapper'
import {committeeSettingsSelectors} from '../../../../store/committeeSettingsStore/committeeSettingsStore'
import {STATUSES_IN} from '../../../MyRodcomesPage/constants/myRodcomesPageConstants'
import {projectsSelectors} from '../../../../store/projectsStore/projectsStore'
import {PublishStatusesEnum} from '../constants/parentCommitteeConstants'
import {BlocksListWrapper} from '../Summary/components/projects/ProjectsStyle'
import ActiveProject from '../Summary/components/projects/ActiveProject'
import {PATHS} from '../../../../routes/paths'
import {RK_ROLE} from '../../../JoiningRodcom/constants'
import InfoBox from '../../../../components/Layouts/InfoBox/InfoBox'
import {Typography} from '@mui/material'

const ProjectsPage = () => {
  const {id} = useParams()
  const roles = useSelector(committeeSettingsSelectors.roles.selector)
  const navigate = useNavigate()
  const projectsList = useSelector(projectsSelectors.projectsList.selector)
  const isChief = useMemo(() => roles?.includes(STATUSES_IN.Chief), [roles])
  const isEducator = useMemo(() => roles?.includes(RK_ROLE.EDUCATOR), [roles])

  const getPercent = (moneyPerMember, amountPaid, targetMoney, totalPerMember, type) => {
    let percent = 0
    if (type == 'REGULAR') {
      percent = moneyPerMember * (100 / totalPerMember) >= 100 ? 100 : moneyPerMember * (100 / totalPerMember)
    } else {
      percent = amountPaid * (100 / targetMoney) >= 100 ? 100 : amountPaid * (100 / targetMoney)
    }
    return percent
  }

  let actualProjects = projectsList
    ?.filter((project) => {
      const isVisibleForEducators = project.visibleForEducator || !isEducator
      return project.status === PublishStatusesEnum.PUBLISHED && isVisibleForEducators
    })
    .filter((date) => new Date(date.lastDeadlineDate) > new Date() || date.type === 'PIGGY')
    .sort((a, b) => new Date(a.lastDeadlineDate) - new Date(b.lastDeadlineDate))
  // .sort((date) => new Date(date.lastDeadlineDate) - new Date())
  actualProjects = [
    ...actualProjects,
    ...projectsList
      .filter(
        (project) => project.status === PublishStatusesEnum.PUBLISHED && (project.visibleForEducator || !isEducator)
      )
      .filter((i) => new Date(i.lastDeadlineDate) < new Date() && i.type !== 'PIGGY')
  ]

  const draftProjects = projectsList
    ?.filter((project) => {
      const isVisibleForEducators = project.visibleForEducator || !isEducator
      return project.status === PublishStatusesEnum.DRAFT && isVisibleForEducators
    })
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

  const completedProjects = projectsList?.filter((project) => {
    const isVisibleForEducators = project.visibleForEducator || !isEducator
    return project.status === PublishStatusesEnum.COMPLETED && isVisibleForEducators
  })

  return (
    <>
      {actualProjects.length ? (
        <ParagraphWrapper
          title={`Актуальные (${actualProjects.length ?? 0})`}
          handleClickButton={
            isChief
              ? () =>
                  navigate(
                    `${PATHS.PARENT_COMMITTEE}/${id}${PATHS.PARENT_COMMITTEE_PROJECTS}${PATHS.PARENT_COMMITTEE_CREATE_PROJECT}`
                  )
              : undefined
          }
        />
      ) : (
        <InfoBox sx={{marginTop: '24px'}}>
          <Typography variant="h4" sx={{fontSize: '16px'}}>
            Здесь пока ничего нет
          </Typography>
        </InfoBox>
      )}
      <BlocksListWrapper>
        {actualProjects?.map((project, index) => (
          <ActiveProject
            onClick={() =>
              navigate(`${PATHS.PARENT_COMMITTEE}/${id}${PATHS.PARENT_COMMITTEE_PROJECTS}/${project.uuid}`)
            }
            project={project}
            percent={getPercent(project.moneyPerMember, project.amountPaid, project.targetMoney)}
            key={index.toString(36)}
          />
        ))}
      </BlocksListWrapper>

      {draftProjects.length ? <ParagraphWrapper title={`Черновики (${draftProjects.length ?? 0})`} /> : null}
      <BlocksListWrapper>
        {draftProjects?.map((project, index) => {
          let percent = 0
          if (project.type === 'REGULAR') {
            percent =
              project.amountPaid * (100 / project.totalPerMember) >= 100
                ? 100
                : project.amountPaid * (100 / project.totalPerMember)
          } else {
            percent =
              project.amountPaid * (100 / project.targetMoney) >= 100
                ? 100
                : project.amountPaid * (100 / project.targetMoney)
          }
          return (
            <ActiveProject
              isDraft
              onClick={() =>
                navigate(
                  `${PATHS.PARENT_COMMITTEE}/${id}${PATHS.PARENT_COMMITTEE_PROJECTS}/${project.uuid}${PATHS.PARENT_COMMITTEE_EDIT_PROJECT}`
                )
              }
              project={project}
              // percent={percent}
              key={index.toString(36)}
            />
          )
        })}
      </BlocksListWrapper>

      {completedProjects.length ? <ParagraphWrapper title={`Завершенные (${completedProjects.length ?? 0})`} /> : null}
      <BlocksListWrapper>
        {completedProjects?.map((project, index) => (
          <ActiveProject
            onClick={() =>
              navigate(`${PATHS.PARENT_COMMITTEE}/${id}${PATHS.PARENT_COMMITTEE_PROJECTS}/${project.uuid}`)
            }
            project={project}
            percent={100}
            key={index.toString(36)}
          />
        ))}
      </BlocksListWrapper>
    </>
  )
}

export default ProjectsPage
