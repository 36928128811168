import {call, put, select, takeLatest, delay} from 'redux-saga/effects'
import {
  activateProjectEvent,
  createProjectEvent,
  createProjectEventSuccess,
  deleteProjectEvent,
  getProjectsListEvent,
  getProjectsListEventSuccess,
  setLogoProjectEvent,
  getProjectUsersEvent,
  getProjectUsersEventSuccess,
  setIsLoading,
  setProjectUsersEvent,
  updateProjectEvent,
  archivateProjectEvent,
  getProjectEvent,
  getProjectEventSuccess,
  getProjectStatusEvent,
  getProjectStatusEventSuccess,
  joinToProjectEvent,
  leaveProjectEvent,
  requestTransferToProjectEvent,
  requestTransferToPiggyEvent,
  requestTransferToCardEvent,
  setConfirmEvent
} from '../../store/projectsStore/projectsStoreSliceEvents'
import {withErrorLogging} from '../../utils/errorSaga'
import {
  activateProject,
  createProject,
  deleteProject,
  getProjectsByRK,
  setLogoProject,
  getProjectUsers,
  setProjectUsers,
  updateProject,
  archivateProject,
  addImageToProject,
  removeImageFromProject,
  getProject,
  joinProject,
  leaveProject,
  getProjectStatus,
  transferToProject,
  transferToPiggy,
  transferToCard
} from '../../services/project'
import {committeeSettingsSelectors} from '../../store/committeeSettingsStore/committeeSettingsStore'
import {EVENT_STATUSES} from '../../pages/LoginPage/constants'
import * as authyorizationEvents from '../../store/initStore/initStoreSliceEvents'
import * as directoriesEvents from 'store/directoriesStore/directoriesStoreSliceEvents'
import {
  EVENT_PROJECT_STATUSES,
  EVENT_TRANSFER_STATUSES
} from '../../pages/ParentCommittee/subpages/Projects/Project/constants/constants'
import {getVotesListEvent} from '../../store/votingStore/votingStoreSliceEvents'
import {getDebtSummaryEvent} from '../../store/giveoutStore/giveoutStoreSliceEvents'

export function* getProjectsRequest(action) {
  try {
    const {uuid} = action.payload
    const response = yield withErrorLogging(call(getProjectsByRK, uuid))
    if (response?.data?.event === EVENT_PROJECT_STATUSES.PROJECTS_LIST_GET.STATUS) {
      yield put(getProjectsListEventSuccess.action(response.data.payload))
    }
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(setIsLoading.action(false))
  }
}

export function* getProjectSaga(action) {
  try {
    const {uuid} = action.payload
    const response = yield withErrorLogging(call(getProject, uuid))
    if (response?.data?.event === EVENT_PROJECT_STATUSES.PROJECTS_GET.STATUS) {
      yield put(directoriesEvents.getInitDirectories.action())
      yield put(getProjectEventSuccess.action(response.data.payload))
    }
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(setIsLoading.action(false))
  }
}

export function* getProjectStatusRequest(action) {
  try {
    const {uuid} = action.payload
    const response = yield withErrorLogging(call(getProjectStatus, uuid))
    if (response?.data?.event === EVENT_PROJECT_STATUSES.PROJECTS_GET.STATUS) {
      yield put(getProjectStatusEventSuccess.action(response.data.payload))
    }
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(setIsLoading.action(false))
  }
}

export function* getProjectRequest(action) {
  try {
    const {uuid} = action.payload
    const response = yield withErrorLogging(call(getProject, uuid))

    if (response.data.payload) {
      yield put(getProjectEventSuccess.action(response.data.payload))
    }
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(setIsLoading.action(false))
  }
}

export function* activateProjectRequest(action) {
  try {
    const {uuid} = action.payload
    const res = yield withErrorLogging(call(activateProject, uuid))
    if (res?.data?.event === EVENT_STATUSES.PROJECT_ACTIVATED) {
      const uuidCurrentParentCommittee = yield select(committeeSettingsSelectors.uuid.selector)
      yield put(getProjectsListEvent.action(uuidCurrentParentCommittee))
      yield put(authyorizationEvents.setRequestStatus.action(EVENT_STATUSES.PROJECT_ACTIVATED))
    }
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(setIsLoading.action(false))
  }
}

export function* setLogoProjectRequest(action) {
  try {
    const {logo, uuid} = action.payload
    return yield withErrorLogging(call(setLogoProject, uuid, logo))
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(setIsLoading.action(false))
  }
}

export function* getProjectUsersRequest(action) {
  try {
    const {uuid} = action.payload
    const response = yield withErrorLogging(call(getProjectUsers, uuid))
    yield put(getProjectUsersEventSuccess.action(response.data?.payload))
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(setIsLoading.action(false))
  }
}

export function* setProjectUsersRequest(action) {
  try {
    const {uuid, data} = action.payload
    const membersRequestData = data.map((uuid) => {
      return {
        uuid,
        guestCount: 0,
        endNotify: true
      }
    })

    return yield withErrorLogging(call(setProjectUsers, uuid, membersRequestData))
  } catch (e) {
    // console.error(e)
  }
}

export function* deleteProjectRequest(action) {
  try {
    const {projectId} = action.payload
    const res = yield withErrorLogging(call(deleteProject, projectId))
    const uuidCurrentParentCommittee = yield select(committeeSettingsSelectors.uuid.selector)
    if (res?.data?.event === EVENT_PROJECT_STATUSES.PROJECT_DELETED.STATUS) {
      yield put(getProjectsListEvent.action(uuidCurrentParentCommittee))
      yield put(authyorizationEvents.setRequestStatus.action(EVENT_STATUSES.PROJECT_DELETED))
      yield put(authyorizationEvents.setSuccessMsg.action(EVENT_PROJECT_STATUSES.PROJECT_DELETED.MESSAGE_TO_USER))
    }
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(setIsLoading.action(false))
  }
}

export function* archivateProjectRequest(action) {
  try {
    const {projectId} = action.payload
    const res = yield withErrorLogging(call(archivateProject, projectId))
    const uuidCurrentParentCommittee = yield select(committeeSettingsSelectors.uuid.selector)
    if (res?.data?.event === EVENT_STATUSES.PROJECT_CLOSED) {
      yield put(getProjectsListEvent.action(uuidCurrentParentCommittee))
      yield put(authyorizationEvents.setRequestStatus.action(EVENT_STATUSES.PROJECT_CLOSED))
    }
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(setIsLoading.action(false))
  }
}

export function* joinToProjectRequest(action) {
  try {
    const {uuid} = action.payload
    const res = yield withErrorLogging(call(joinProject, uuid))
    if (res?.data?.event === EVENT_PROJECT_STATUSES.PROJECT_JOINED.STATUS) {
      yield put(getProjectEvent.action(uuid))
      yield put(getProjectUsersEvent.action(uuid))
      yield put(authyorizationEvents.setSuccessMsg.action(EVENT_PROJECT_STATUSES.PROJECT_JOINED.MESSAGE_TO_USER))
    }
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(setIsLoading.action(false))
  }
}

export function* leaveProjectRequest(action) {
  try {
    const {uuid} = action.payload
    const res = yield withErrorLogging(call(leaveProject, uuid))
    if (res?.data?.event === EVENT_PROJECT_STATUSES.PROJECT_LEAVED.STATUS) {
      yield put(getProjectEvent.action(uuid))
      yield put(getProjectUsersEvent.action(uuid))
      yield put(authyorizationEvents.setSuccessMsg.action(EVENT_PROJECT_STATUSES.PROJECT_LEAVED.MESSAGE_TO_USER))
    }
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(setIsLoading.action(false))
  }
}

export function* transferToCardRequest(action) {
  try {
    const {data} = action.payload
    const res = yield withErrorLogging(call(transferToCard, data))
    if (res?.data?.event === EVENT_TRANSFER_STATUSES.TRANSFER_INFO.STATUS) {
      yield put(authyorizationEvents.setSuccessMsg.action(EVENT_TRANSFER_STATUSES.TRANSFER_INFO.MESSAGE_TO_USER))
      yield put(setConfirmEvent.action(false))
      yield put(getProjectEvent.action(data.projectId))
      yield put(getDebtSummaryEvent.action(data.projectId))
    }
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(setIsLoading.action(false))
  }
}

export function* transferToProjectRequest(action) {
  try {
    const {data} = action.payload
    const res = yield withErrorLogging(call(transferToProject, data))
    if (res?.data?.event === EVENT_TRANSFER_STATUSES.TRANSFER_INFO.STATUS) {
      yield put(authyorizationEvents.setSuccessMsg.action(EVENT_TRANSFER_STATUSES.TRANSFER_INFO.MESSAGE_TO_USER))
      yield put(getProjectEvent.action(data.piggyUuid))
      yield put(getDebtSummaryEvent.action(data.piggyUuid))
    }
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(setIsLoading.action(false))
  }
}

export function* transferToPiggyRequest(action) {
  try {
    const {data} = action.payload
    if (!data.projectId) console.trace('no project id')
    const res = yield withErrorLogging(call(transferToPiggy, data))
    if (res?.data?.event === EVENT_TRANSFER_STATUSES.TRANSFER_INFO.STATUS) {
      yield put(authyorizationEvents.setSuccessMsg.action(EVENT_TRANSFER_STATUSES.TRANSFER_INFO.MESSAGE_TO_USER))
      yield put(getProjectEvent.action(data.projectId))
      yield put(getDebtSummaryEvent.action(data.projectId))
    }
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(setIsLoading.action(false))
  }
}

export function* createProjectRequest(action) {
  try {
    const {data, projectMembers, logo, withPublish, images} = action.payload
    const response = yield withErrorLogging(call(createProject, data))
    const {uuid} = response.data.payload
    if (logo) yield put(setLogoProjectEvent.action(logo, uuid))

    if (images?.length) {
      for (const image of images) {
        yield withErrorLogging(call(addImageToProject, uuid, image))
      }
    }

    try {
      yield put(setProjectUsersEvent.action(uuid, projectMembers))
    } catch (e) {
      // console.error(e)
    }

    if (withPublish) {
      yield delay(1500)
      yield withErrorLogging(call(activateProject, uuid))
    }

    if (response?.data?.event === EVENT_STATUSES.PROJECT_ADDED) {
      yield put(getProjectEvent.action(uuid))

      const requestVotesData = {
        offset: 0,
        limit: 100,
        rkUuids: [],
        projectUuids: [uuid],
        statuses: ['PUBLISHED'],
        sort: {
          sortType: 'Asc'
        }
      }
      yield put(getVotesListEvent.action(requestVotesData))

      yield put(authyorizationEvents.setRequestStatus.action(`${EVENT_STATUSES.PROJECT_ADDED}${uuid}`))
      yield put(authyorizationEvents.setSuccessMsg.action(EVENT_PROJECT_STATUSES.PROJECT_CREATED.MESSAGE_TO_USER))
      const rkUuid = yield select(committeeSettingsSelectors.uuid.selector)
      //window.location.reload()
    }
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(setIsLoading.action(false))
  }
}

export function* updateProjectRequest(action) {
  try {
    yield put(setIsLoading.action(true))
    let IS_LOGO_CHANGE_SUCCESS = true
    let IS_IMAGES_ADD_SUCCESS = true
    let IS_IMAGES_REMOVE_SUCCESS = true
    let IS_DATA_CHANGE_SUCCESS = true
    let IS_MEMBERS_CHANGE_SUCCESS = true
    let IS_PUBLISH_CHANGE_SUCCESS = true
    const {data, projectMembers, projectId, logo, images, removedImagesFromBackEnd, withPublish} = action.payload

    const responseDataChange = yield withErrorLogging(call(updateProject, projectId, data))
    IS_DATA_CHANGE_SUCCESS = responseDataChange?.data?.event === EVENT_PROJECT_STATUSES.PROJECT_DATA_UPDATE.STATUS

    if (logo) {
      const action = {
        payload: {logo: logo, uuid: projectId}
      }
      const responseChangeLogo = yield call(setLogoProjectRequest, action)
      IS_LOGO_CHANGE_SUCCESS = responseChangeLogo?.data?.event === EVENT_PROJECT_STATUSES.PROJECT_LOGO_UPDATE.STATUS
    }

    if (images?.length) {
      for (const image of images) {
        const responseAddImage = yield withErrorLogging(call(addImageToProject, projectId, image))
        IS_IMAGES_ADD_SUCCESS = responseAddImage?.data?.event === EVENT_PROJECT_STATUSES.PROJECT_IMAGES_ADD.STATUS
      }
    }

    if (removedImagesFromBackEnd?.length) {
      for (const image of removedImagesFromBackEnd) {
        const responseRemoveImage = yield withErrorLogging(call(removeImageFromProject, projectId, image))
        IS_IMAGES_REMOVE_SUCCESS =
          responseRemoveImage?.data?.event === EVENT_PROJECT_STATUSES.PROJECT_IMAGES_REMOVE.STATUS
      }
    }

    if (projectMembers.length) {
      const action = {
        payload: {uuid: projectId, data: projectMembers}
      }
      const responseMembersChange = yield call(setProjectUsersRequest, action)
      IS_MEMBERS_CHANGE_SUCCESS =
        responseMembersChange?.data?.event === EVENT_PROJECT_STATUSES.PROJECT_USERS_UPDATE.STATUS
    }

    if (withPublish) {
      yield delay(1500)
      const responsePublishChange = yield put(activateProjectEvent.action(projectId))
      IS_PUBLISH_CHANGE_SUCCESS =
        responsePublishChange?.data?.event === EVENT_PROJECT_STATUSES.PROJECT_PUBLISH_UPDATE.STATUS
    }

    yield put(getProjectEvent.action(projectId))

    const rkUuid = yield select(committeeSettingsSelectors.uuid.selector)
    const requestVotesData = {
      offset: 0,
      limit: 100,
      rkUuids: [rkUuid],
      projectUuids: [],
      statuses: ['PUBLISHED'],
      sort: {
        sortType: 'Asc'
      }
    }
    yield put(getVotesListEvent.action(requestVotesData))

    const isSuccess =
      IS_LOGO_CHANGE_SUCCESS &&
      IS_IMAGES_ADD_SUCCESS &&
      IS_IMAGES_REMOVE_SUCCESS &&
      IS_DATA_CHANGE_SUCCESS &&
      IS_MEMBERS_CHANGE_SUCCESS &&
      IS_PUBLISH_CHANGE_SUCCESS

    if (isSuccess)
      yield put(authyorizationEvents.setSuccessMsg.action(EVENT_PROJECT_STATUSES.PROJECT_DATA_UPDATE.MESSAGE_TO_USER))
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(setIsLoading.action(false))
  }
}

export function* projectsSaga() {
  yield takeLatest(deleteProjectEvent.type, deleteProjectRequest)
  yield takeLatest(setLogoProjectEvent.type, setLogoProjectRequest)
  yield takeLatest(activateProjectEvent.type, activateProjectRequest)
  yield takeLatest(getProjectsListEvent.type, getProjectsRequest)
  yield takeLatest(createProjectEvent.type, createProjectRequest)
  yield takeLatest(getProjectStatusEvent.type, getProjectStatusRequest)
  yield takeLatest(getProjectUsersEvent.type, getProjectUsersRequest)
  yield takeLatest(setProjectUsersEvent.type, setProjectUsersRequest)
  yield takeLatest(updateProjectEvent.type, updateProjectRequest)
  yield takeLatest(archivateProjectEvent.type, archivateProjectRequest)
  yield takeLatest(getProjectEvent.type, getProjectRequest)
  yield takeLatest(joinToProjectEvent.type, joinToProjectRequest)
  yield takeLatest(leaveProjectEvent.type, leaveProjectRequest)
  yield takeLatest(requestTransferToProjectEvent.type, transferToProjectRequest)
  yield takeLatest(requestTransferToPiggyEvent.type, transferToPiggyRequest)
  yield takeLatest(requestTransferToCardEvent.type, transferToCardRequest)
}
