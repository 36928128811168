import React, {useEffect} from 'react'

import {ContentCopy} from '@mui/icons-material'
import {Box, Typography, Button} from '@mui/material'
import {useSelector} from 'react-redux'
import {committeeSettingsSelectors} from '../../../../../../store/committeeSettingsStore/committeeSettingsStore'

import {LinkPC, ButtonWrapper} from './InviteStyle'
import {PATHS} from '../../../../../../routes/paths'
import InfoBox from '../../../../../../components/Layouts/InfoBox/InfoBox'
import {copyToClipboard} from '../../../../../../utils/copyToClipBoard'
import {inviteText} from '../../constants/summaryConstants'
import {useParams} from 'react-router-dom'
import {notifySuccess} from '../../../../../../utils/notify'
import 'react-toastify/dist/ReactToastify.css'

const Invite = ({url, text = inviteText, className}) => {
  const {id} = useParams()
  const profile = useSelector(committeeSettingsSelectors.profileInfo.selector)
  const link = url || `${window?.location?.origin}${PATHS.JOINING_PARENT_COMMITTEE}/${id}`
  // const notify = () =>
  //   toast.success('Ссылка скопирована !', {
  //     position: 'top-right',
  //     autoClose: 5000,
  //     hideProgressBar: true,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: 'light'
  //   })

  useEffect(() => {
    if (profile.name) {
      // const meta = document.createElement('meta')
      // meta.property = 'og:title'
      // meta.content = `Вас пригласили присоединится к ${profile?.name}`
      // document.getElementsByTagName('head')[0].appendChild(meta)
      document.querySelector('meta[property="og:title"]').setAttribute('content', profile?.name)
      // document.querySelector('meta[property="og:title"]').attr('content', profile?.name)
    }
    // console.log(profile)
  }, [profile])

  return (
    <InfoBox className={className}>
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <Typography sx={{maxWidth: '775px', fontSize: '16px', wordBreak: 'break-word'}} variant="h4">
          Пригласите других родителей в этот комитет
        </Typography>
        <LinkPC href={link}>{link}</LinkPC>
        <Typography sx={{maxWidth: '775px', fontSize: '16px', wordBreak: 'break-word'}} variant="h4">
          {text}
        </Typography>
      </Box>
      <Box
        sx={{width: {sm: '100%', md: 'auto'}, marginLeft: {sm: '0', md: '19px'}, marginTop: {sm: '12px', lg: '0px'}}}
      >
        <Button
          variant="contained"
          startIcon={<ContentCopy color="white" />}
          type="button"
          color="secondary"
          sx={{boxShadow: 'none'}}
          onClick={() => {
            window.ym(process.env.REACT_APP_YA_METRIKA_ID, 'reachGoal', 'CopyLink')
            copyToClipboard(link)
            notifySuccess('Ссылка скопирована !')
          }}
        >
          Скопировать
        </Button>
      </Box>
      {/* <ToastContainer /> */}
    </InfoBox>
  )
}

export default Invite
