import React, {useCallback} from 'react'
import {StyledInfoBox} from '../Layouts/TabContentLayout/TabContentLayout'
import {copyToClipboard} from '../../utils/copyToClipBoard'
import {ContentCopy} from '@mui/icons-material'
import {lighten, styled} from '@mui/material/styles'
import {Button} from '@mui/material'
import {ButtonWrapper} from '../../pages/ParentCommittee/subpages/Summary/components/Invite/InviteStyle'
import 'react-toastify/dist/ReactToastify.css'
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'
import {Box} from '@mui/material'
import {notifySuccess} from '../../utils/notify'

export const StyledLinkTitle = styled('a')(({theme}) => ({
  fontSize: '16px',
  lineHeight: '20px',
  color: '#39215E',
  wordBreak: 'break-word',
  // textOverflow: 'ellipsis',
  maxWidth: '-webkit-fill-available',
  // whiteSpace: 'nowrap',
  // overflow: 'hidden',
  textDecoration: 'none'
}))

export const StyledLink = styled('div')(({theme}) => ({
  fontWeight: 500,
  maxWidth: '-webkit-fill-available',
  fontSize: '16px',
  textOverflow: 'ellipsis',
  textDecoration: 'underline',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  lineHeight: '20px',
  [theme.breakpoints.up('sm')]: {
    fontSize: '16px',
    lineHeight: '19px'
  }
}))

const LinkBlock = ({title, link, isPiggy, className}) => {
  const linkDefault = window?.location?.href
  if (!link) link = linkDefault

  const handleCopyLink = useCallback(() => {
    copyToClipboard(link)
  }, [link])

  // const notify = () =>
  //   toast.success('Ссылка скопирована !', {
  //     position: 'top-right',
  //     autoClose: 5000,
  //     hideProgressBar: true,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: 'light'
  //   })

  return (
    <StyledInfoBox className={className} sx={{width: isPiggy && '100%'}}>
      <StyledLinkTitle sx={{fontSize: 13, color: '#39215E', fontWeight: 500, opacity: 0.8}}>{title}</StyledLinkTitle>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: '-webkit-fill-available',
          marginTop: '12px'
        }}
      >
        <LinkOutlinedIcon sx={{rotate: '315deg', color: '#4F75FF', marginRight: '12px'}} />
        <StyledLink sx={{color: '#39215E'}}>{link}</StyledLink>
      </Box>

      <Button
        sx={{marginTop: '12px'}}
        variant="contained"
        color="info"
        // startIcon={<ContentCopy />}
        type="button"
        onClick={() => {
          window.ym(process.env.REACT_APP_YA_METRIKA_ID, 'reachGoal', 'CopyLink')
          handleCopyLink()
          notifySuccess('Ссылка скопирована !')
        }}
      >
        Скопировать
      </Button>
      {/* <ToastContainer /> */}
    </StyledInfoBox>
  )
}

export default LinkBlock
