import React from 'react'
import {ReportsWrapper, Report, Img, Text, Sum} from './ReportsStyle'
// import report1 from '../../../../../../images/report1.svg'
// import report2 from '../../../../../../images/report2.svg'
// import report3 from '../../../../../../images/report3.svg'
// import report4 from '../../../../../../images/report4.svg'

const Reports = ({reports}) => {
  return (
    <ReportsWrapper>
      {reports.map((report, index) => (
        <Report key={index.toString(36)}>
          {/* <Img src={report.icon} alt="report" /> */}
          {/* <span style={{fontSize: '60px'}}>{report.icon}</span> */}
          {report.icon}
          <Text variant="h4">{report.text}</Text>
          <Sum variant="h1">{report.sum}</Sum>
        </Report>
      ))}
    </ReportsWrapper>
  )
}

export default Reports
