import React, {useCallback, useState, useEffect, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import 'react-quill/dist/quill.snow.css' // ES6
import ReactQuill from 'react-quill'
import {Box, CircularProgress, Typography} from '@mui/material'

import {HeaderWithBackButton} from '../../../../../../../components/HeaderWithBackButton/HeaderWithBackButton'
import TextInput from '../../../../../../../components/Inputs/TextInput/TextInput'
import StyledAlert from '../../../../../../../components/StyledAlert/StyledAlert'
import EmojiPicker from 'emoji-picker-react'
import {Emoji} from 'emoji-picker-react'
import {imageURL} from '../../../../../../../utils/imageURL'
import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data/sets/4/apple.json'
import {init} from 'emoji-mart'
import emojiLang from '@emoji-mart/data/i18n/ru.json'
import {Button} from '@mui/material'
import useOutsideClick from '../../../../../../../hooks/useOutsideClick'
import {notifyError} from '../../../../../../../utils/notify'
import pdf from '../../../../../../../images/pdf.png'

init({data})

import {InfoIcon, ChevronDownIcon, PlusIcon, EmojiIcon} from '../../../../../../../icons'
import {
  ImagesWrapper,
  Icon,
  Img,
  Input,
  Label,
  ReactQuillWrapper,
  SelectIcon,
  WrapperFormInfoProject,
  WrapperInfoProject,
  SelectImg,
  ImgWrapper,
  CloseIconWrapper,
  CloseImg,
  Text
} from './InfoProjectStyle'
import {getFileType} from '../../../../../../../utils/getFileType'
import heic2any from 'heic2any'

const modules = {
  toolbar: [[{list: 'ordered'}, {list: 'bullet'}], ['link']]
}

const maxSizeFile = 5242880

const formats = ['list', 'link']

const InfoProject = ({
  projectValues,
  setValue,
  projectErrors,
  setError,
  setLogo,
  logo,
  setImages,
  images,
  setRemovedImagesFromBackEnd,
  removedImagesFromBackEnd
}) => {
  const navigate = useNavigate()
  const [emojiId, setEmojiId] = useState('')
  //=================
  const wrapperRef = useRef(null)
  const [isEmojiBalloonVisible, setEmojiBalloonVisible] = useState(false)
  const [isLoadingFiles, setIsLoadingFile] = useState(false)
  const emojiBalloonClose = useCallback(() => {
    setEmojiBalloonVisible(false)
  }, [])
  useOutsideClick(wrapperRef, emojiBalloonClose)

  const emojiButtonClickHandle = useCallback(() => {
    setEmojiBalloonVisible((prev) => !prev)
  }, [])
  //=================

  useEffect(() => {
    setEmojiId(projectValues?.emoji?.native)
    setError('emoji', null)
  }, [projectValues?.emoji?.id])

  const onSelectImage = (event) => {
    for (let i = 0; i < event.target.files.length; i++) {
      if (event.target.files[i].size > maxSizeFile) {
        notifyError('Размер файла не должен превышать 5 Мб')
        continue
      }
      const fileType = getFileType(event.target.files[i].name)
      const fileReader = new FileReader()

      if (!event.target.files[i].type && fileType === 'heic') {
        setIsLoadingFile(true)
        heic2any({
          blob: event.target.files[i],
          toType: 'image/jpeg',
          quality: 0.5
        })
          .then((data) => {
            fileReader.readAsDataURL(data)

            fileReader.onload = function () {
              setImages((prev) => [...prev, fileReader.result])
              setIsLoadingFile(false)
            }

            fileReader.onerror = function () {
              notifyError('Не удалось загрузить файл')
            }
          })
          .catch(() => {
            notifyError('Не удалось загрузить файл')
            setIsLoadingFile(false)
          })
      } else {
        fileReader.readAsDataURL(event.target.files?.[i])

        fileReader.onload = function () {
          setImages((prev) => [...prev, fileReader.result])
        }

        fileReader.onerror = function () {
          notifyError('Не удалось загрузить файл')
        }
      }
    }
  }

  const onSelectFile = useCallback((event) => {
    const fileReader = new FileReader()

    fileReader.onload = function () {
      setLogo(fileReader.result)
    }
    fileReader.readAsDataURL(event.target.files[0])
  }, [])

  const handleQuillChange = (value) => {
    setValue('description', value === '<p><br></p>' ? null : value)
  }

  return (
    <WrapperInfoProject>
      {/* <HeaderWithBackButton
        title="Описание проекта"
        description={null}
        // linkText="Отмена"
        mediumText
        onClick={() => navigate(-1)}
      /> */}
      <Text variant="h4" sx={{fontWeight: 700, marginBottom: '24px', fontSize: '18px'}}>
        Описание проекта
      </Text>
      <WrapperFormInfoProject>
        <Box sx={{display: 'flex', alignItems: 'center', gap: '12px'}}>
          <TextInput
            clearError={() => setError('title', null)}
            errorMsg={projectErrors?.title}
            label="Тема"
            id="title"
            value={projectValues?.title}
            onChangeValue={(value) => setValue('title', value)}
            placeholder="Название проекта"
            autoFocus
            sx={{marginBottom: 0}}
            inputProps={{maxLength: 95}}
          />
          {!emojiId ? (
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <Button
                variant="contained"
                id="emoji"
                sx={{
                  zIndex: 2,
                  padding: '19px 10px',
                  svg: {height: 20, width: 20},
                  borderColor: projectErrors?.emoji ? 'red' : null,
                  '&:hover': {
                    borderColor: projectErrors?.emoji ? 'red' : null
                  },
                  minWidth: '45px',
                  marginBottom: '6px',
                  width: 'fit-content'
                }}
                type="button"
                color="secondary"
                onClick={emojiButtonClickHandle}
              >
                <EmojiIcon />
              </Button>
              {projectErrors?.emoji && (
                <span
                  style={{
                    color: '#E41D3D',
                    fontSize: '10px',
                    lineHeight: '13px',
                    marginBottom: '8px',
                    width: 'max-content'
                  }}
                >
                  Выберите иконку
                </span>
              )}
            </Box>
          ) : null}
          {isEmojiBalloonVisible ? (
            <div className="emojiBalloon" ref={wrapperRef}>
              <Picker
                // data={data}
                i18n={emojiLang}
                previewPosition="none"
                onEmojiSelect={(emojiData) => {
                  setValue('emoji', emojiData)
                  setEmojiBalloonVisible(false)
                }}
                // set="apple"
                skinTonePosition="none"
              />
            </div>
          ) : null}
          {emojiId ? (
            // <em-emoji
            //   id={emojiId}
            //   set="apple"
            //   size="45px"
            //   onClick={emojiButtonClickHandle}
            //   style={{cursor: 'pointer'}}
            // ></em-emoji>
            <span style={{fontSize: '45px', cursor: 'pointer'}} onClick={emojiButtonClickHandle}>
              {emojiId}
            </span>
          ) : null}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 12,
            marginBottom: '30px'
          }}
        >
          {/* {!emojiId ? (
            <Button
              variant="contained"
              sx={{zIndex: 2, padding: '31px 17px', svg: {height: 30, width: 30}}}
              type="button"
              color="secondary"
              onClick={emojiButtonClickHandle}
            >
              <EmojiIcon />
            </Button>
          ) : null}
          {isEmojiBalloonVisible ? (
            <div className="emojiBalloon" ref={wrapperRef}>
              <Picker
                // data={data}
                i18n={emojiLang}
                previewPosition="none"
                onEmojiSelect={(emojiData) => {
                  setValue('emoji', emojiData)
                  setEmojiBalloonVisible(false)
                }}
                set="apple"
                skinTonePosition="none"
              />
            </div>
          ) : null}
          {emojiId ? (
            <em-emoji
              id={emojiId}
              set="apple"
              size="120px"
              onClick={emojiButtonClickHandle}
              style={{cursor: 'pointer'}}
            ></em-emoji>
          ) : null} */}
          <ImagesWrapper sx={{marginBottom: '30px'}}>
            {projectValues?.galleryImages
              ?.filter((image) => !removedImagesFromBackEnd?.find((removedImage) => removedImage === image))
              ?.map((image, index) => (
                <ImgWrapper key={index.toString(36)}>
                  <CloseIconWrapper
                    onClick={() => {
                      setRemovedImagesFromBackEnd([...removedImagesFromBackEnd, image])
                    }}
                  >
                    <CloseImg />
                  </CloseIconWrapper>
                  <Img src={imageURL(image)} />
                </ImgWrapper>
              ))}
            {images?.map((image, index) => (
              <ImgWrapper key={index.toString(36)}>
                <CloseIconWrapper
                  onClick={() => {
                    setImages(images.filter((_, idx) => idx !== index))
                  }}
                >
                  <CloseImg />
                </CloseIconWrapper>
                <Img src={image} />
              </ImgWrapper>
            ))}
            <SelectImg>
              <Label htmlFor="image-button-file">
                {isLoadingFiles ? (
                  <CircularProgress sx={{margin: '0 auto', padding: 19, display: 'block'}} />
                ) : (
                  <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '17px'}}>
                    <PlusIcon />
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '4px'}}>
                      <span>Загрузить</span>
                      <span>изображение</span>
                    </Box>
                  </Box>
                )}
              </Label>
              <Input
                accept=".gif, .jpg, .jpeg, .png, .heic"
                id="image-button-file"
                type="file"
                multiple
                onChange={onSelectImage}
              />
            </SelectImg>
          </ImagesWrapper>
        </Box>
        {/* <ImagesWrapper sx={{marginBottom: '30px'}}>
          {projectValues?.galleryImages
            ?.filter((image) => !removedImagesFromBackEnd?.find((removedImage) => removedImage === image))
            ?.map((image, index) => (
              <ImgWrapper key={index.toString(36)}>
                <CloseIconWrapper
                  onClick={() => {
                    setRemovedImagesFromBackEnd([...removedImagesFromBackEnd, image])
                  }}
                >
                  <CloseImg />
                </CloseIconWrapper>
                <Img src={imageURL(image)} />
              </ImgWrapper>
            ))}
          {images?.map((image, index) => (
            <ImgWrapper key={index.toString(36)}>
              <CloseIconWrapper
                onClick={() => {
                  setImages(images.filter((_, idx) => idx !== index))
                }}
              >
                <CloseImg />
              </CloseIconWrapper>
              <Img src={image} />
            </ImgWrapper>
          ))}
          <SelectImg>
            <Label htmlFor="image-button-file">
              <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '17px'}}>
                <PlusIcon />
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '4px'}}>
                  <span>Загрузить</span>
                  <span>изображение</span>
                </Box>
              </Box>
            </Label>
            <Input accept="image/*" id="image-button-file" type="file" onChange={onSelectImage} />
          </SelectImg>
        </ImagesWrapper> */}
        {/* <SelectIcon sx={{marginBottom: '30px'}}>
          {logo && <Icon src={logo} alt="logo" />}
          {projectValues?.mainImage && !logo && <Icon src={imageURL(projectValues?.mainImage)} alt="projectImage" />}
          <Label htmlFor="icon-button-file">
            <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
              <span style={{fontSize: 12}}>Выбрать иконку</span> <ChevronDownIcon />
            </Box>
          </Label>
          <Input accept="image/*" id="icon-button-file" type="file" onChange={onSelectFile} />
        </SelectIcon> */}
        <Box sx={{marginBottom: '30px'}}>
          <Text variant="h4" sx={{fontWeight: 700, marginBottom: '3px', fontSize: 15}}>
            Описание
          </Text>
          <ReactQuillWrapper>
            <ReactQuill
              value={projectValues?.description || ''}
              onChange={handleQuillChange}
              placeholder="Опишите проект, составьте список планируемых покупок"
              formats={formats}
              modules={modules}
              id="reactTextDescription"
            />
          </ReactQuillWrapper>
        </Box>
        <StyledAlert isBlue title="" icon={false} sx={{borderRadius: 16}}>
          <Typography variant="h4" sx={{fontSize: 16}}>
            <b>Совет:</b> добавьте в описание мотивацию, чтобы участники ощутили пользу от этой инициативы. Например:
            «Подарим детям настоящий праздник, чтобы они учились лучше!»
          </Typography>
        </StyledAlert>
        {/* <ImagesWrapper sx={{marginTop: '30px'}}>
          {projectValues?.galleryImages
            ?.filter((image) => !removedImagesFromBackEnd?.find((removedImage) => removedImage === image))
            ?.map((image, index) => (
              <ImgWrapper key={index.toString(36)}>
                <CloseIconWrapper
                  onClick={() => {
                    setRemovedImagesFromBackEnd([...removedImagesFromBackEnd, image])
                  }}
                >
                  <CloseImg />
                </CloseIconWrapper>
                <Img src={imageURL(image)} />
              </ImgWrapper>
            ))}
          {images?.map((image, index) => (
            <ImgWrapper key={index.toString(36)}>
              <CloseIconWrapper
                onClick={() => {
                  setImages(images.filter((_, idx) => idx !== index))
                }}
              >
                <CloseImg />
              </CloseIconWrapper>
              <Img src={image} />
            </ImgWrapper>
          ))}
          <SelectImg>
            <Label htmlFor="image-button-file">
              <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '17px'}}>
                <PlusIcon />
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '4px'}}>
                  <span>Загрузить</span>
                  <span>изображение</span>
                </Box>
              </Box>
            </Label>
            <Input accept="image/*" id="image-button-file" type="file" onChange={onSelectImage} />
          </SelectImg>
        </ImagesWrapper> */}
      </WrapperFormInfoProject>
    </WrapperInfoProject>
  )
}

export default InfoProject
