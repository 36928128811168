import React, {useCallback, useMemo, useState} from 'react'
import {Button, CircularProgress, Typography} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'

import VoteOption from '../VoteOption/VoteOption'
import {CheckIcon} from '../../../../../../../../icons'
import {FormHelperTextWrapper} from '../../../../../../../../components/Inputs/TextInput/TextInputStyle'
import {toVoteEvent} from '../../../../../../../../store/votingStore/votingStoreSliceEvents'
import {votingSelectors} from '../../../../../../../../store/votingStore/votingStore'
import {StyledFormControl} from './VoteOptionsListStyle'
import {StyledLoadingWrapper} from '../../../../../../../../components/StyledLoadingWrapper/StyledLoadingWrapper'
import {VOTE_STATUSES} from '../../../VoteCreatePage/constants/creatingVotingConstants'
import {committeeSettingsSelectors} from '../../../../../../../../store/committeeSettingsStore/committeeSettingsStore'
import {RK_ROLE} from '../../../../../../../JoiningRodcom/constants'

const VoteOptionsList = ({voteData}) => {
  const isLoading = useSelector(votingSelectors.isLoading.selector)
  const [selectedChoices, setSelectedChoices] = useState([])
  const [errorMsg, setErrorMsg] = useState(null)
  const params = useParams()
  const dispatch = useDispatch()
  const roles = useSelector(committeeSettingsSelectors.roles.selector)

  const isEducator = useMemo(() => roles?.includes(RK_ROLE.EDUCATOR), [roles])

  const isVotable = isEducator
    ? false
    : !voteData.haveIAnswered && !voteData.isArchived && voteData.status !== VOTE_STATUSES.COMPLETED

  const optionClicked = useCallback((optionNumber) => {
    if (!isVotable) return

    setErrorMsg(null)

    if (voteData.choiceType === 'ALLOW_MANY') {
      setSelectedChoices((prev) => {
        if (prev.includes(optionNumber)) {
          return prev.filter((choice) => choice !== optionNumber)
        } else {
          return [...prev, optionNumber]
        }
      })
    } else {
      setSelectedChoices([optionNumber])
    }
  }, [])

  const choiceOptions = voteData.choiceOptions

  const handleDataSubmit = () => {
    let newErrorMsg = 'Выберите вариант ответа'

    for (let i = 0; i < choiceOptions.length; i++) {
      if (selectedChoices.includes(choiceOptions[i].number)) {
        newErrorMsg = null
        break
      }
    }

    setErrorMsg(newErrorMsg)
    if (newErrorMsg) return

    const myVoteData = {
      chosenOptionNumbers: selectedChoices,
      voteUuid: params.voteId
    }

    dispatch(toVoteEvent.action(myVoteData))
  }

  return (
    <StyledLoadingWrapper isLoading={isLoading}>
      <StyledFormControl error={Boolean(errorMsg)} style={{marginTop: '32px', marginBottom: 0}}>
        {choiceOptions.map((choice) => {
          return (
            <VoteOption
              key={choice.number}
              onClick={() => optionClicked(choice.number)}
              choice={choice}
              voteData={voteData}
              isSelected={selectedChoices.includes(choice.number)}
              isVotable={isVotable}
            />
          )
        })}
        {errorMsg ? <FormHelperTextWrapper className="errorText">{errorMsg}</FormHelperTextWrapper> : null}
      </StyledFormControl>
      {isVotable ? (
        <>
          <Button
            sx={{marginBottom: '12px', marginTop: '24px'}}
            variant="contained"
            startIcon={<CheckIcon color="white" />}
            onClick={handleDataSubmit}
            disabled={!selectedChoices.length}
          >
            Проголосовать
          </Button>
          <Typography variant={'body2'} sx={{fontSize: 14}}>
            Вы увидите результаты, после того, как проголосуете.
          </Typography>
        </>
      ) : null}

      {isLoading ? (
        <div className="loading">
          <CircularProgress sx={{margin: '0 auto', padding: 19, display: 'block'}} />
        </div>
      ) : null}
    </StyledLoadingWrapper>
  )
}

export default VoteOptionsList
//
