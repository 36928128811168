import httpClient from './network'

export const setCurrentUserInfo = (data) => {
  return httpClient.put('/users', data)
}

export const setCurrentUserAvatar = (avatar) => {
  const data = {
    name: 'user.jpg',
    contentType: 'image/jpeg',
    body: avatar.replace(/^data:image\/[a-z]+;base64,/, '')
  }
  return httpClient.post('/users/avatar', data)
}

export const sendControlSumRequest = (rkId, bank, phone) => {
  return httpClient.post('/sbp_setup/send', {
    rkId,
    bankName: bank,
    phone
  })
}

export const checkControlSumRequest = (bank, phone, code) => {
  return httpClient.post('/sbp_setup/verify', {
    bank,
    phone,
    amount: code
  })
}

export const getSbpParticipants = () => {
  return httpClient.get('/sbp_setup/banks-participants')
}

export const deleteCurrentUserAvatar = () => {
  return httpClient.delete(`/users/avatar`)
}

export const deleteCurrentUser = (userId) => {
  return httpClient.delete(`/user-manage/${userId}`)
}
