import React from 'react'
import {alpha, styled} from '@mui/material/styles'
import {keyframes} from '@mui/system'

const move = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 16px 20px;
  }
`

const StyledWrapper = styled('div')(({theme}) => ({
  position: 'relative',
  height: '8px',
  overflow: 'hidden',
  width: '100%',
  backgroundColor: '#FFFFFF',
  border: 'npne',
  borderRadius: '6px',
  '& .progressText': {
    color: '#39215E',
    textAlign: 'center',
    zIndex: 100,
    position: 'inherit',
    height: '100%',
    fontWeight: 600
  },
  '& .progressFill': {
    backgroundColor: 'transparent',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    '& .progressFillDecor': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 1,
      backgroundSize: '16px 16px',
      // animation: `${move} 2s linear infinite`,
      background: '#794DBD'
    }
  }
}))

const ProgressBar = ({value, text, ...props}) => {
  return (
    <StyledWrapper {...props}>
      {text && <div className="progressText">{text}</div>}

      <div style={{width: value}} className="progressFill">
        <div className="progressFillDecor"></div>
      </div>
    </StyledWrapper>
  )
}

export default ProgressBar
