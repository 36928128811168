import initSlice from '../initSlice'

const {sliceEvent, allSliceEvents} = initSlice()

const PREFIX = 'VOTING_'

export const setIsLoading = sliceEvent({
  type: `${PREFIX}_SET_IS_LOADING`,
  action: (isLoading) => ({
    payload: {
      isLoading
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {isLoading} = action.payload
    return {
      ...state,
      isLoading
    }
  }
})
export const setRequestPayload = sliceEvent({
  type: `${PREFIX}_SET_REQUEST_PAYLOAD`,
  action: (requestPayload) => ({
    payload: {
      requestPayload
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {requestPayload} = action.payload
    return {
      ...state,
      requestPayload
    }
  }
})

export const getVotesListEvent = sliceEvent({
  type: `${PREFIX}_GET_VOTES_LIST_EVENT`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})
export const getVotesListEventSuccess = sliceEvent({
  type: `${PREFIX}_GET_VOTES_LIST_EVENT_SUCCESS`,
  action: (votesList) => ({
    payload: {
      votesList
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {votesList} = action.payload
    const votesListIds = votesList.map((voteItem) => voteItem.uuid)
    const prevVotesList = state.votesList.filter((voteListItem) => !votesListIds.includes(voteListItem.uuid))
    const newVotesList = prevVotesList.concat(votesList)

    return {
      ...state,
      votesList: newVotesList,
      isLoading: false
    }
  }
})

export const getVotesListWithRewriteEvent = sliceEvent({
  type: `${PREFIX}_GET_VOTES_LIST_WITH_REWRITE_EVENT`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})
export const getVotesListWithRewriteEventSuccess = sliceEvent({
  type: `${PREFIX}_GET_VOTES_LIST_WITH_REWRITE_EVENT_SUCCESS`,
  action: (votesList) => ({
    payload: {
      votesList
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {votesList} = action.payload

    return {
      ...state,
      votesList,
      isLoading: false
    }
  }
})

export const getVoteEvent = sliceEvent({
  type: `${PREFIX}_GET_VOTE_EVENT`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})
export const getVoteEventSuccess = sliceEvent({
  type: `${PREFIX}_GET_VOTE_EVENT_SUCCESS`,
  action: (vote) => ({
    payload: {
      vote
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {vote} = action.payload
    const newVotesList = state.votesList.filter((voteListItem) => voteListItem.uuid !== vote.uuid)
    newVotesList.push(vote)
    return {
      ...state,
      votesList: newVotesList,
      isLoading: false
    }
  }
})

export const createVoteEvent = sliceEvent({
  type: `${PREFIX}_CREATE_VOTE_EVENT`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const updateVoteEvent = sliceEvent({
  type: `${PREFIX}_UPDATE_VOTE_EVENT`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const toVoteEvent = sliceEvent({
  type: `${PREFIX}_TO_VOTE_EVENT`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const archiveVoteEvent = sliceEvent({
  type: `${PREFIX}_ARCHIVE_VOTE_EVENT`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const allInitAppEvents = allSliceEvents
