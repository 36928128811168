import httpClient from './network'

export const verificationPhoneRequest = (phoneNumber) => {
  return httpClient.post('/users/verification-phone', {phoneNumber})
}

export const verifyPhoneRequest = (data) => {
  return httpClient.post('/users/verify-phone', data)
}

export const verificationPhoneResendCodeRequest = (phoneNumber) => {
  return httpClient.post('/users/verification-phone-resend-code', {phoneNumber})
}
