import {styled} from '@mui/material/styles'
import {Button} from '@mui/material'

export const LinkPC = styled('a')(({theme}) => ({
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 400,
  textDecoration: 'underline',
  color: '#39215E',
  // marginBottom: '8px',
  [theme.breakpoints.up('sm')]: {
    fontSize: '16px',
    lineHeight: '26px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
    lineHeight: '29px'
  }
}))

export const ButtonWrapper = styled(Button)(({theme}) => ({
  // height: '38px !important',
  width: '100%',
  boxSizing: 'border-box',
  boxShadow: '0px 12px 32px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  borderRadius: '13px',
  fontSize: 16,
  // marginTop: '16px',
  flexGrow: '0',
  padding: '13px 19px',
  whiteSpace: 'nowrap',
  background: 'rgba(57, 33, 94, 0.12)',
  border: 'none',
  '&:hover': {
    // background: 'rgba(57, 33, 94, 0.24)',
    border: 'none'
  },
  // '&:focused': {
  //   border: '1px solid rgba(255, 255, 255, 1e-05)'
  // },
  // '&:active': {
  //   boxShadow: ' 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
  // },
  [theme.breakpoints.up('md')]: {
    // marginTop: '19px',
    width: 'min-content'
  }
}))
