import {darken, lighten, styled} from '@mui/material/styles'

export const StyledTabContentLayout = styled('div')(({theme}) => ({
  // paddingBottom: '77px'
  // 'div:last-child': {
  //   marginTop: 24,
  //   marginButtom: '0px !important'
  // }
  '.MuiButton-contained': {
    boxShadow: 'none'
  }
}))

export const StyledWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  color: theme.palette.primary.main,
  marginTop: 32,
  gap: '19px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  },
  '.notRequiredText': {
    fontWeight: 400,
    color: theme.palette.primary.main
  },
  // '.MuiFormControl-root': {
  //   width: '100%'
  // },
  '.child-modal-date-input .MuiInputLabel-root': {
    top: 0,
    transformOrigin: 'left',
    transform: 'translate(0px, 0px) scale(0.75)',
    position: 'relative'
  },
  '.project-members': {
    marginLeft: 0,
    marginRight: 0
  }
}))

export const StyledLeftContent = styled('div')(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    width: '67%',
    flex: '1 1 67%'
  },
  '.projTitle': {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '40px'
  },
  [theme.breakpoints.down(600)]: {
    '.projTitle': {
      fontSize: 32,
      lineHeight: '40px'
    }
  },
  '.payment-history': {
    padding: 32,
    '@media(max-width: 600px)': {
      padding: 24
    },
    border: '1px solid #EDEDFF',
    background: 'white',
    borderRadius: 12,
    marginTop: 24,
    textAlign: 'center',
    '.info-block': {
      marginBottom: '32px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down(700)]: {
        flexDirection: 'column',
        gap: 12,
        alignItems: 'flex-start'
      }
    },
    '.showMoreButton': {
      border: 0,
      '&:hover': {
        border: 0
      }
    }
  }
}))

export const StyledMainContent = styled('div')(({theme}) => ({
  padding: '32px',
  background: theme.palette.background.paper,
  borderRadius: 12,
  // boxShadow: '0px 12px 48px rgba(57, 33, 94, 0.06), 0px 2.4px 4px rgba(57, 33, 94, 0.04)',
  border: '1px solid #EDEDFF',
  [theme.breakpoints.up('md')]: {
    borderRadius: 12
  },
  [theme.breakpoints.up('lg')]: {
    padding: '40px 64px'
  },
  '& .pencilIcon': {
    fill: theme.palette.primary.contrastText,
    width: '24px',
    height: '24px'
  },
  span: {
    fontSize: 16
  },
  '.change': {
    svg: {
      fill: 'transparent'
    }
  },
  '.action': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 16,
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      gap: 16
    }
  },
  '.img-list': {
    '.img-icon': {
      display: 'none',
      cursor: 'pointer'
    },
    '&:hover': {
      '.img-icon': {
        display: 'block'
      },
      '.proj-img': {
        filter: 'blur(2px)'
      }
    }
  },
  '.proj-img': {
    cursor: 'pointer',
    borderRadius: '12px',
    '&:hover': {
      filter: 'blur(2px)'
    }
  }
}))

export const StyledRightContent = styled('div')(({theme}) => ({
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '19px',
  // marginLeft: '19px',
  // marginRight: '19px',
  [theme.breakpoints.up('md')]: {
    padding: 0,
    flex: '1 1 33%',
    maxWidth: '400px',
    marginLeft: 0,
    marginRight: 0
  },
  div: {
    // width: '100%'
  }
}))

export const StyledSpecInfo = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  '&.specialInfoOne': {
    gap: '6px',
    margin: '19px 0'
  },
  '&.specialInfoTwo': {
    gap: '6px',
    [theme.breakpoints.up('md')]: {
      gap: '19px'
    }
  },
  '& .privateVote, .youVoted': {
    width: '128px'
  },
  '& .privateVote': {
    justifyContent: 'center',
    backgroundColor: lighten(theme.palette.warning.main, 0.8),
    color: darken(theme.palette.error.main, 0.3)
  },
  '& .youVoted': {
    justifyContent: 'space-between',
    backgroundColor: darken(theme.palette.primary.light, 0.25),
    color: theme.palette.info.contrastText
  }
}))

export const StyledInfoBox = styled('div')(({theme}) => ({
  boxSizing: 'border-box',
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  background: theme.palette.background.paper,
  // boxShadow: '0px 12px 48px rgba(57, 33, 94, 0.06), 0px 2.4px 4px rgba(57, 33, 94, 0.04)',
  border: '1px solid #EDEDFF',
  padding: '13px',
  borderRadius: '12px',
  [theme.breakpoints.up('md')]: {
    padding: '19px'
  },
  [theme.breakpoints.down(1224)]: {
    width: '100%'
  },
  '&.withoutPadding': {
    marginLeft: 0,
    marginRight: 0,
    borderRadius: 0,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      borderRadius: '6px'
    }
  },
  '& .MuiInputAdornment-root': {
    justifyContent: 'flex-end'
  },
  '& .voteLink': {
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '16px',
    marginTop: '3px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
      lineHeight: '19px'
    }
  },
  '& .projectHeader': {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%'
  },
  '& .projectImg': {
    width: '64px',
    height: '64px',
    marginRight: '8px'
  },
  '& .projectCost': {
    backgroundColor: lighten(theme.palette.primary.main, 0.92),
    justifyContent: 'center',
    marginTop: '6px'
  },
  '& .projectName': {
    marginTop: '19px',
    fontSize: '14px',
    lineHeight: '19px',
    [theme.breakpoints.up('md')]: {
      fontSize: '19px',
      lineHeight: '29px'
    }
  },

  '& .paymentText': {
    margin: '0 auto',
    fontSize: '19px',
    lineHeight: '29px'
  },

  '&.payLink': {
    [theme.breakpoints.up('sm')]: {
      marginTop: '19px'
    }
  }
}))

export const StyledDateWarningBlock = styled('div')(({theme}) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.grey[200],
  padding: '13px',
  borderRadius: '6px',
  [theme.breakpoints.up('md')]: {
    padding: '19px'
  },
  '& .finishDateTitle': {
    fontSize: '10px',
    lineHeight: '21px'
  },
  '& .finishDate': {
    fontWeight: 500,
    fontSize: '19px',
    lineHeight: '100%'
  }
}))
