import React, {useCallback, useMemo} from 'react'
import {Box, Button, Divider, Typography} from '@mui/material'
import {useParams} from 'react-router-dom'
import {useDispatch} from 'react-redux'

import {ArchiveIconWrapper, FinalBlockWrapper, InfoIconWarning, CloseIconWrapper} from './FinalBlockStyle'
import Switcher from '../../../../../../../components/Inputs/Switcher/Switcher'
import StyledAlert from '../../../../../../../components/StyledAlert/StyledAlert'
import {deleteProjectEvent} from '../../../../../../../store/projectsStore/projectsStoreSliceEvents'
import {MIN_DATE} from '../../../../../../../components/Inputs/DateInput/constants'

const FinalBlock = ({
  projectValues,
  setValue,
  projectErrors,
  setError,
  isDraft,
  isCompleted,
  isPublished,
  isUpdateButtonDisabled,
  onUpdate,
  handlePublish,
  handleArchive,
  isAlertRequiredFields,
  handleDraft
}) => {
  const dispatch = useDispatch()
  const {projectId, id: uuidParentCommittee} = useParams()

  const isAllPaid = useMemo(() => {
    // todo
    return false
  }, [])

  const onDateError = useCallback(
    (error) => {
      setError('deadlineDate', error)
    },
    [MIN_DATE]
  )

  return (
    <FinalBlockWrapper className="piggy">
      <Switcher
        label="Показывать педагогам"
        checked={projectValues?.visibleForEducator}
        onChange={(value) => setValue('visibleForEducator', value)}
      />
      {/* {isDraft && (
        <StyledAlert title="Это черновик." icon={false}>
          <Typography variant="h4">
            Опубликуйте проект, чтобы он стал доступен участникам. Сбор средств начнётся сразу.
          </Typography>
        </StyledAlert>
      )} */}
      {isDraft && (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
          {projectId ? (
            <Button variant="outlined" type="button" onClick={() => dispatch(deleteProjectEvent.action(projectId))}>
              Удалить черновик
            </Button>
          ) : null}
          <Button variant="contained" type="button" onClick={handleDraft}>
            Сохранить черновик
          </Button>
        </Box>
      )}
      {/* {isDraft && <Divider />} */}
      {/* {isAlertRequiredFields && (
        <StyledAlert title="Не всё заполнено." icon={false}>
          <Typography variant="h4">
            Пока нельзя опубликовать, потому что не заполнены все необходимые данные.
          </Typography>
        </StyledAlert>
      )} */}
      {isDraft && (
        <Button variant="contained" type="button" disabled={false} onClick={handlePublish}>
          Опубликовать
        </Button>
      )}
      {isCompleted && !isAllPaid && (
        <StyledAlert icon={false}>
          <Typography variant="h4">Перевести в архив можно, когда Вы отчитаетесь за все выводы средств.</Typography>
        </StyledAlert>
      )}
      {isCompleted && (
        <Button disabled={isUpdateButtonDisabled} variant="contained" type="button" onClick={handlePublish}>
          Переопубликовать
        </Button>
      )}
      {isCompleted && (
        <Button variant="contained" type="button" color="secondary" disabled={!isAllPaid} onClick={handleArchive}>
          <ArchiveIconWrapper />В архив
        </Button>
      )}
      {isPublished && (
        <Button disabled={false} variant="contained" type="button" onClick={handlePublish}>
          Сохранить изменения
        </Button>
      )}
    </FinalBlockWrapper>
  )
}

export default FinalBlock
