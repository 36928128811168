import {styled} from '@mui/material/styles'
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from '@mui/material'

export const SelectWrapper = styled(Select)(({theme}) => ({
  '&.Mui-disabled': {
    '& fieldset': {
      borderStyle: 'dashed'
    }
  },
  color: theme.palette.primary.main,
  '.notRequiredText': {
    fontWeight: 400,
    color: theme.palette.primary.main
  }
}))

export const MenuItemWrapper = styled(MenuItem)(({theme}) => ({
  color: theme.palette.primary.main
}))

export const StyledFormControl = styled(FormControl)(({theme}) => ({
  margin: '0px !important',
  position: 'relative',
  '& .lockIcon': {
    position: 'absolute',
    top: '20px',
    right: '4px'
  }
}))

export const InputLabelWrapper = styled(InputLabel)(({theme}) => ({
  color: theme.palette.primary.main
}))

export const FormHelperTextWrapper = styled(FormHelperText)(({theme}) => ({
  color: theme.palette.primary.main
}))
