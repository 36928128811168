import {styled} from '@mui/material/styles'
import {Chip} from '@mui/material'
import {CHIP_TYPE} from '../../../constants/parentCommitteeConstants'

export const StyledChip = styled(Chip)(({theme}) => ({
  background: '#E8DAFF',
  color: theme.palette.primary.main,
  borderRadius: '6px',
  fontSize: 14,
  fontWeight: 700,
  padding: '12px 14px !important',
  // height: '19px',
  [`&.${CHIP_TYPE.APP}`]: {
    background: theme.palette.primary.main,
    color: '#FFFFFF'
  },
  [`&.${CHIP_TYPE.RECORD}`]: {
    background: 'rgba(57, 33, 94, 0.12)',
    color: theme.palette.primary.main
  }
}))
