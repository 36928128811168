import {call, put, takeLatest, select, take} from 'redux-saga/effects'
import * as committeeSettingsStoreSliceEvents from '../../store/committeeSettingsStore/committeeSettingsStoreSliceEvents'
import {withErrorLogging} from '../../utils/errorSaga'
import {
  approveMembershipRequest,
  deleteRkRequest,
  excludeMemberRequest,
  getAppMembershipListRequest,
  getAppMembershipRequest,
  getRkMembersRequest,
  getRkProfileRequest,
  rejectMembershipRequest,
  transferMembershipApproveRequest,
  transferMembershipLastRequest,
  transferMembershipMeRequest,
  transferMembershipRejectRequest,
  transferMembershipRequest,
  updateRkProfileRequest,
  getOperationHistoryRequest
} from '../../services/committeeSettings'
import {EVENT_STATUSES} from '../../pages/LoginPage/constants'
import * as authyorizationEvents from '../../store/initStore/initStoreSliceEvents'
import {committeeSettingsSelectors} from '../../store/committeeSettingsStore/committeeSettingsStore'
import {profileSelectors} from '../../store/profileStore/profileStore'
import * as votingSliceEvents from '../../store/votingStore/votingStoreSliceEvents'
import {getUserInfo} from '../../services/authorization'
import * as profileEvents from '../../store/profileStore/profileStoreSliceEvents'
import {STATUSES_IN} from '../../pages/MyRodcomesPage/constants/myRodcomesPageConstants'
import * as creatingRkStoreSliceEvents from '../../store/creatingRkStore/creatingRkStoreSliceEvents'

export function* deleteRk(action) {
  try {
    const {uuid} = action.payload
    const res = yield withErrorLogging(call(deleteRkRequest, uuid))
    if (res?.data?.event === EVENT_STATUSES.RK_DELETED) {
      yield put(authyorizationEvents.setRequestStatus.action(res.data.event))
    }
  } catch (e) {
    // console.error(e)
  }
  yield put(committeeSettingsStoreSliceEvents.setIsLoading.action(false))
}

export function* getInitRkData(action) {
  try {
    const {uuid} = action.payload
    yield put(authyorizationEvents.getUserInfoRequest.action(uuid))
    yield put(committeeSettingsStoreSliceEvents.getRkMembersEvent.action(uuid))
    yield put(committeeSettingsStoreSliceEvents.getRkProfileEvent.action(uuid))
    const votingRequestData = {
      rkUuids: [uuid],
      projectUuids: [],
      statuses: ['PUBLISHED', 'COMPLETED'],
      limit: 100,
      offset: 0,
      sort: {
        sortType: 'Asc'
      }
    }
    yield put(votingSliceEvents.getVotesListWithRewriteEvent.action(votingRequestData))
  } catch (e) {
    // console.error(e)
  }
}

export function* getRkProfile(action) {
  try {
    const {uuid} = action.payload
    const res = yield withErrorLogging(call(getRkProfileRequest, uuid))
    if (res?.data?.event === EVENT_STATUSES.RK_PROFILE_GET) {
      const profileInfo = res.data.payload
      const filteredChildren = profileInfo?.children?.filter(({isReference}) => isReference)
      const parents = filteredChildren?.reduce((acc, next) => {
        const {fullNameParent = {}, uuid, phoneNumber, emailParent, fullNameChild} = next
        const {firstName, surname, patronymic} = fullNameParent
        const {firstName: firstNameChild, surname: surnameChild} = fullNameChild
        const parentName = `${firstName} ${surname} ${patronymic || ''}`.trim()
        const kidName = `${surnameChild} ${firstNameChild}`
        const switchedKidName = `${surnameChild} ${firstNameChild}`
        acc[parentName] = acc[parentName]
          ? {
              ...acc[parentName],
              ids: [...acc[parentName].ids, uuid],
              kids: `${acc[parentName].kids}, ${kidName}`,
              switchedKids: `${acc[parentName].switchedKids}, ${switchedKidName}`
            }
          : {
              ...next,
              record: true,
              memberName: `${surname || ''} ${firstName || ''}`.trim(),
              switchedMemberName: `${surname || ''} ${firstName || ''}`.trim(),
              verifiedPhone: phoneNumber,
              email: emailParent || '',
              ids: [uuid],
              kids: kidName,
              switchedKids: switchedKidName,
              userUuid: uuid
            }
        return acc
      }, {})
      const recordsMembers = Object.values(parents)
      yield put(
        committeeSettingsStoreSliceEvents.getRkProfileEventSuccess.action(res.data.payload, uuid, recordsMembers)
      )
    }
  } catch (e) {
    if (e?.response?.data?.event === EVENT_STATUSES.RK_FAIL_NOT_FOUND) {
      yield put(authyorizationEvents.setRequestStatus.action(e.response.data.event))
    }
    // console.error(e)
  }
  yield put(committeeSettingsStoreSliceEvents.setIsLoading.action(false))
  yield put(creatingRkStoreSliceEvents.setIsLoading.action(false))
}

export function* getOperationHistory(action) {
  try {
    const {uuid, filter} = action.payload

    const res = yield withErrorLogging(call(getOperationHistoryRequest, uuid, filter))
    yield put(committeeSettingsStoreSliceEvents.getParentCommitteeOperationsEventSuccess.action(res.data.payload))
  } catch (e) {
    // console.error(e)
  }
}

export function* updateRkProfile(action) {
  try {
    const {uuid, data} = action.payload
    const res = yield withErrorLogging(call(updateRkProfileRequest, uuid, data))
    if (res?.data?.event === EVENT_STATUSES.RK_PROFILE_UPDATED.STATUS) {
      yield put(committeeSettingsStoreSliceEvents.getRkProfileEventSuccess.action(data))
      yield put(authyorizationEvents.setSuccessMsg.action(EVENT_STATUSES.RK_PROFILE_UPDATED.MESSAGE_TO_USER))
    }
  } catch (e) {
    // console.error(e)
  }
  yield put(committeeSettingsStoreSliceEvents.setIsLoading.action(false))
}

export function* getRkMembers(action) {
  const {uuid, fromMembersTab} = action.payload
  yield put(committeeSettingsStoreSliceEvents.setMembersIsLoadingEvent.action(true))
  if (!fromMembersTab) {
    yield put(committeeSettingsStoreSliceEvents.setTabsIsLoading.action(true))
    yield put(committeeSettingsStoreSliceEvents.setIsLoading.action(true))
  }
  try {
    const response = yield withErrorLogging(call(getRkMembersRequest, uuid))
    const membersOptions = response.data.payload?.map((item) => {
      const {fullName, userUuid} = item
      return {
        id: userUuid,
        label: `${fullName?.surname || ''} ${fullName?.firstName || ''}`
      }
    })
    let userData
    userData = yield select(profileSelectors.user.selector)
    if (!userData) {
      const response = yield withErrorLogging(call(getUserInfo))
      userData = response?.data?.payload
      const phone = userData?.phone
      if (phone) {
        userData.phone = `+${phone}`
      }
      yield put(profileEvents.getUserInfoSuccess.action(userData))
    }
    const currentUserMember = response.data.payload.find(({userUuid}) => {
      return userUuid === userData?.uuid
    })
    const roles = currentUserMember?.membershipRoles?.map(({name}) => name)
    const isChief = roles?.includes(STATUSES_IN.Chief)
    let appMembership = []
    if (isChief) {
      const res = yield call(getAppMembershipListRequest, uuid)
      appMembership = res?.data?.payload || []
    }
    yield put(
      committeeSettingsStoreSliceEvents.setMembersParentCommittee.action(
        response.data.payload,
        membersOptions,
        roles,
        appMembership
      )
    )
  } catch (e) {
    if (e?.response?.data?.event === EVENT_STATUSES.RK_FAIL_NOT_FOUND_MEMBER || e?.response?.status === 404) {
      yield put(authyorizationEvents.setRequestStatus.action(EVENT_STATUSES.RK_FAIL_NOT_FOUND_MEMBER))
      yield put(committeeSettingsStoreSliceEvents.setMembersParentCommittee.action([], [], [], []))
    }
    // console.error(e)
  }
  if (!fromMembersTab) {
    yield put(committeeSettingsStoreSliceEvents.setTabsIsLoading.action(false))
    yield put(committeeSettingsStoreSliceEvents.setIsLoading.action(false))
  }
  yield put(committeeSettingsStoreSliceEvents.setMembersIsLoadingEvent.action(false))
}

export function* checkIfUserIsMember(action) {
  try {
    const {uuid} = action.payload
    const res = yield call(getRkMembersRequest, uuid)
    if (res?.data?.event === EVENT_STATUSES.RK_MEMBERS_LIST) {
      yield put(authyorizationEvents.setRequestStatus.action(res.data.event))
    }
  } catch (e) {
    if (e?.response?.data?.event === EVENT_STATUSES.RK_FAIL_NOT_FOUND_MEMBER || e?.response?.status === 404) {
      yield put(authyorizationEvents.setRequestStatus.action(EVENT_STATUSES.RK_FAIL_NOT_FOUND_MEMBER))
    }
    // console.error(e)
  }
  yield put(committeeSettingsStoreSliceEvents.setIsLoading.action(false))
}

export function* transferMembership(action) {
  try {
    const {participant} = action.payload
    const membersOptions = yield select(committeeSettingsSelectors.membersOptions.selector)
    const uuid = yield select(committeeSettingsSelectors.uuid.selector)
    const foundMember = membersOptions.find((item) => item.label === participant)
    const res = yield withErrorLogging(call(transferMembershipRequest, uuid, foundMember.id))
    if (res?.data?.event === EVENT_STATUSES.RK_TRANSFER_CHIEF_MEMBERSHIP) {
      yield put(authyorizationEvents.setRequestStatus.action(res.data.event))
    }
  } catch (e) {
    // console.error(e)
  }
  yield put(committeeSettingsStoreSliceEvents.setIsLoading.action(false))
}

export function* transferMembershipApprove() {
  try {
    const uuid = yield select(committeeSettingsSelectors.uuid.selector)
    yield withErrorLogging(call(transferMembershipApproveRequest, uuid))
    yield put(committeeSettingsStoreSliceEvents.transferMembershipMeEvent.action(uuid))
    yield put(committeeSettingsStoreSliceEvents.setIsLoading.action(false))
    window.location.reload()
  } catch (e) {
    yield put(committeeSettingsStoreSliceEvents.setIsLoading.action(false))
    // console.error(e)
  }
}

export function* transferMembershipReject() {
  try {
    const uuid = yield select(committeeSettingsSelectors.uuid.selector)
    yield withErrorLogging(call(transferMembershipRejectRequest, uuid))
    yield put(committeeSettingsStoreSliceEvents.transferMembershipRejectSuccessEvent.action(uuid))
  } catch (e) {
    // console.error(e)
  }
  yield put(committeeSettingsStoreSliceEvents.setIsLoading.action(false))
}

export function* transferMembershipLast(action) {
  try {
    const {uuid} = action.payload
    const res = yield withErrorLogging(call(transferMembershipLastRequest, uuid))
    // todo
  } catch (e) {
    // console.error(e)
  }
  yield put(committeeSettingsStoreSliceEvents.setIsLoading.action(false))
}

export function* transferMembershipMe(action) {
  try {
    const {uuid} = action.payload
    const res = yield withErrorLogging(call(transferMembershipMeRequest, uuid))
    if (res?.data?.event === EVENT_STATUSES.RK_TRANSFER_CHIEF_MEMBERSHIP_APP) {
      yield put(committeeSettingsStoreSliceEvents.transferMembershipMeEventSuccess.action(res.data.payload))
    }
    if (res?.data?.event === EVENT_STATUSES.RK_TRANSFER_CHIEF_MEMBERSHIP_NOT_LAUNCHED) {
      return
    }
  } catch (e) {
    // console.error(e)
  }
  yield put(committeeSettingsStoreSliceEvents.setIsLoading.action(false))
}

export function* approveMembership(action) {
  try {
    const {appUuid} = action.payload
    const uuid = yield select(committeeSettingsSelectors.uuid.selector)
    const res = yield withErrorLogging(call(approveMembershipRequest, appUuid))
    if (res?.data?.event === EVENT_STATUSES.RK_APPLICATION_MEMBERSHIP_APPROVED) {
      yield put(committeeSettingsStoreSliceEvents.getRkMembersEvent.action(uuid, true))
    }
  } catch (e) {
    // console.error(e)
    yield put(committeeSettingsStoreSliceEvents.setMembersIsLoadingEvent.action(false))
  }
}

export function* rejectMembership(action) {
  try {
    const {appUuid} = action.payload
    const uuid = yield select(committeeSettingsSelectors.uuid.selector)
    const res = yield withErrorLogging(call(rejectMembershipRequest, appUuid))
    if (res?.data?.event === EVENT_STATUSES.RK_APPLICATION_MEMBERSHIP_REJECTED) {
      yield put(committeeSettingsStoreSliceEvents.getRkMembersEvent.action(uuid, true))
    }
  } catch (e) {
    yield put(committeeSettingsStoreSliceEvents.setMembersIsLoadingEvent.action(false))
    // console.error(e)
  }
}

export function* getAppMembership(action) {
  try {
    const {appUuid} = action.payload
    const response = yield withErrorLogging(call(getAppMembershipRequest, appUuid))
    // todo
  } catch (e) {
    // console.error(e)
  }
  yield put(committeeSettingsStoreSliceEvents.setIsLoading.action(false))
}

export function* excludeMember(action) {
  try {
    const {userUuid} = action.payload
    const uuid = yield select(committeeSettingsSelectors.uuid.selector)
    const res = yield withErrorLogging(call(excludeMemberRequest, uuid, userUuid))
    if (res?.data?.event === EVENT_STATUSES.RK_USER_LEFT) {
      yield put(committeeSettingsStoreSliceEvents.getRkMembersEvent.action(uuid, true))
    }
  } catch (e) {
    yield put(committeeSettingsStoreSliceEvents.setMembersIsLoadingEvent.action(false))
    // console.error(e)
  }
}

export function* committeeSettingsSaga() {
  yield takeLatest(committeeSettingsStoreSliceEvents.deleteRkEvent.type, deleteRk)
  yield takeLatest(committeeSettingsStoreSliceEvents.getRkProfileEvent.type, getRkProfile)
  yield takeLatest(committeeSettingsStoreSliceEvents.updateRkProfileEvent.type, updateRkProfile)
  yield takeLatest(committeeSettingsStoreSliceEvents.getRkMembersEvent.type, getRkMembers)
  yield takeLatest(committeeSettingsStoreSliceEvents.checkIfUserIsMemberEvent.type, checkIfUserIsMember)
  yield takeLatest(committeeSettingsStoreSliceEvents.transferMembershipEvent.type, transferMembership)
  yield takeLatest(committeeSettingsStoreSliceEvents.transferMembershipApproveEvent.type, transferMembershipApprove)
  yield takeLatest(committeeSettingsStoreSliceEvents.transferMembershipRejectEvent.type, transferMembershipReject)
  yield takeLatest(committeeSettingsStoreSliceEvents.transferMembershipLastEvent.type, transferMembershipLast)
  yield takeLatest(committeeSettingsStoreSliceEvents.transferMembershipMeEvent.type, transferMembershipMe)
  yield takeLatest(committeeSettingsStoreSliceEvents.getInitParentCommitteeData.type, getInitRkData)
  yield takeLatest(committeeSettingsStoreSliceEvents.approveMembershipEvent.type, approveMembership)
  yield takeLatest(committeeSettingsStoreSliceEvents.rejectMembershipEvent.type, rejectMembership)
  yield takeLatest(committeeSettingsStoreSliceEvents.getAppMembershipEvent.type, getAppMembership)
  yield takeLatest(committeeSettingsStoreSliceEvents.excludeMemberEvent.type, excludeMember)
  yield takeLatest(committeeSettingsStoreSliceEvents.getParentCommitteeOperationsEvent.type, getOperationHistory)
}
