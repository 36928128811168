import initSlice from '../initSlice'

const {sliceEvent, allSliceEvents} = initSlice()

const PREFIX = 'PROJECTS_'

export const getProjectsListEvent = sliceEvent({
  type: `${PREFIX}_GET_PROJECTS_LIST_EVENT`,
  action: (uuid) => ({
    payload: {
      uuid
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const activateProjectEvent = sliceEvent({
  type: `${PREFIX}_ACTIVATE_PROJECT_EVENT`,
  action: (uuid) => ({
    payload: {
      uuid
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const getProjectsListEventSuccess = sliceEvent({
  type: `${PREFIX}_GET_PROJECTS_LIST_EVENT_SUCCESS`,
  action: (projectsList) => ({
    payload: {
      projectsList
    }
  }),
  reducer: (state, action) => {
    const {projectsList} = action.payload

    // projectsList.forEach((data) => {
    //   if (data.type == 'REGULAR' && data.moneyPerMember && data.periodsNumber) {
    //     data.moneyPerMember = data.moneyPerMember * data.periodsNumber
    //   }
    // })

    let piggy = projectsList.filter((p) => p.type === 'PIGGY')
    let other = projectsList.filter((p) => p.type !== 'PIGGY')

    return {
      ...state,
      projectsList: piggy ? piggy.concat(other) : other,
      isLoading: false
    }
  }
})
export const requestTransferToCardEvent = sliceEvent({
  type: `${PREFIX}_TRANSFER_TO_CARD_EVENT`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      confirmTransfer: true
    }
  }
})

export const requestTransferToPiggyEvent = sliceEvent({
  type: `${PREFIX}_TRANSFER_TO_PIGGY_EVENT`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const requestTransferToPiggyEventSuccess = sliceEvent({
  type: `${PREFIX}_TRANSFER_TO_PIGGY_EVENT_SUCCESS`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state, action) => {
    return {
      ...state,
      isLoading: false
    }
  }
})

export const requestTransferToProjectEvent = sliceEvent({
  type: `${PREFIX}_TRANSFER_TO_PROJECT_EVENT`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const requestTransferToProjectEventSuccess = sliceEvent({
  type: `${PREFIX}_TRANSFER_TO_PROJECT_EVENT_SUCCESS`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state, action) => {
    return {
      ...state,
      isLoading: false
    }
  }
})

export const getProjectEvent = sliceEvent({
  type: `${PREFIX}_GET_PROJECT_EVENT`,
  action: (uuid) => ({
    payload: {
      uuid
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const getProjectStatusEvent = sliceEvent({
  type: `${PREFIX}_GET_PROJECT_STATUS_EVENT`,
  action: (uuid) => ({
    payload: {
      uuid
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const getProjectEventSuccess = sliceEvent({
  type: `${PREFIX}_GET_PROJECT_EVENT_SUCCESS`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state, action) => {
    const {data} = action.payload
    // if (data.type == 'REGULAR' && data.moneyPerMember && data.periodsNumber) {
    //   data.moneyPerMember = data.moneyPerMember// * data.periodsNumber
    // }
    const idx = state.projectsList.findIndex((project) => project.uuid === data.uuid)
    const newList = [...state.projectsList]
    if (idx >= 0) {
      newList[idx] = data
    } else newList[newList.length] = data
    return {
      ...state,
      projectsList: newList,
      isLoading: false
    }
  }
})

export const getProjectStatusEventSuccess = sliceEvent({
  type: `${PREFIX}_GET_PROJECT_STATUS_EVENT_SUCCESS`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state, action) => {
    const {data} = action.payload
    let toUpdate = state.projectsList.filter((project) => project.uuid === data.uuid)
    if (toUpdate) {
      toUpdate = {...toUpdate, data}
    }
    const newList = state.projectsList.filter((project) => project.uuid !== data.uuid)
    newList.push(data)
    return {
      ...state,
      projectsList: newList,
      isLoading: false
    }
  }
})

export const createProjectEvent = sliceEvent({
  type: `${PREFIX}_CREATE_PROJECT_EVENT`,
  action: ({data, projectMembers, logo, withPublish, images}) => {
    if (data.periodsNumber > 1) {
      data.type = 'REGULAR'
    }
    // if (data.type == 'REGULAR' && data.moneyPerMember && data.periodsNumber) {
    //   data.moneyPerMember = Math.ceil(data.moneyPerMember / data.periodsNumber)
    // }
    return {
      payload: {
        data,
        projectMembers,
        logo,
        images,
        withPublish
      }
    }
  },
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const archivateProjectEvent = sliceEvent({
  type: `${PREFIX}_ARCHIVATE_PROJECT_EVENT`,
  action: (projectId) => ({
    payload: {
      projectId
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const getProjectUsersEvent = sliceEvent({
  type: `${PREFIX}_GET_PROJECT_USERS_EVENT`,
  action: (uuid) => ({
    payload: {
      uuid
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const setProjectUsersEvent = sliceEvent({
  type: `${PREFIX}_SET_PROJECT_USERS_EVENT`,
  action: (uuid, data) => ({
    payload: {
      uuid,
      data
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const getProjectUsersEventSuccess = sliceEvent({
  type: `${PREFIX}_GET_PROJECT_USERS_EVENT_SUCCESS`,
  action: (users) => ({
    payload: {
      users
    }
  }),
  reducer: (state, action) => {
    const {users} = action.payload
    return {
      ...state,
      projectsUsers: users,
      isLoading: false
    }
  }
})

export const joinToProjectEvent = sliceEvent({
  type: `${PREFIX}_JOIN_TO_PROJECT_EVENT`,
  action: (uuid, data) => ({
    payload: {
      uuid
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const leaveProjectEvent = sliceEvent({
  type: `${PREFIX}_LEAVE_PROJECT_EVENT`,
  action: (uuid, data) => ({
    payload: {
      uuid
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const setLogoProjectEvent = sliceEvent({
  type: `${PREFIX}_SET_LOGO_PROJECT`,
  action: (logo, uuid) => ({
    payload: {
      logo,
      uuid
    }
  }),
  reducer: (state) => {
    return {
      ...state
    }
  }
})

export const updateProjectEvent = sliceEvent({
  type: `${PREFIX}_UPDATE_PROJECT_EVENT`,
  action: ({data, projectMembers, projectId, logo, images, removedImagesFromBackEnd, withPublish}) => {
    if (data.periodsNumber > 1) {
      data.type = 'REGULAR'
    }
    // if (data.type == 'REGULAR' && data.moneyPerMember && data.periodsNumber) {
    //   data.moneyPerMember = Math.ceil(data.moneyPerMember / data.periodsNumber)
    // }
    return {
      payload: {
        data,
        projectMembers,
        projectId,
        logo,
        images,
        removedImagesFromBackEnd,
        withPublish
      }
    }
  }
})

export const deleteProjectEvent = sliceEvent({
  type: `${PREFIX}_DELETE_PROJECT`,
  action: (projectId) => ({
    payload: {
      projectId
    }
  }),
  reducer: (state) => {
    return {
      ...state
    }
  }
})

export const setConfirmEvent = sliceEvent({
  type: `${PREFIX}_SET_CONFIRM`,
  action: (confirm) => ({
    payload: {
      confirm
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {confirm} = action.payload
    return {
      ...state,
      confirmTransfer: confirm
    }
  }
})

export const setIsLoading = sliceEvent({
  type: `${PREFIX}_SET_IS_LOADING`,
  action: (isLoading) => ({
    payload: {
      isLoading
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {isLoading} = action.payload
    return {
      ...state,
      isLoading
    }
  }
})

export const setIsLoadingExpenses = sliceEvent({
  type: `${PREFIX}_SET_IS_LOADING_EXPENSES`,
  action: (isLoadingExpenses) => ({
    payload: {
      isLoadingExpenses
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {isLoadingExpenses} = action.payload
    return {
      ...state,
      isLoadingExpenses
    }
  }
})

export const allInitAppEvents = allSliceEvents
