import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTheme} from '@emotion/react'

const InfoBlock = ({title, sum, styles, subTitle, projectPage}) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        height: '100px',
        flexGrow: '1',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '3px 0 0 3px',
        backgroundColor: '#F5F5FF',
        boxSizing: 'border-box',
        // padding: '24px',
        gap: projectPage ? '4px' : '8px',
        ...styles
      }}
    >
      <Typography variant="caption2" sx={{fontSize: projectPage ? '12px' : '14px', color: 'rgba(57, 33, 94, 0.68)'}}>
        {title}
      </Typography>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: projectPage ? '16px' : '24px',
          lineHeight: '100%',
          letterSpacing: '-0.04em',
          fontFeatureSettings: "'tnum' on, 'lnum' on",
          color: '#39215E'
        }}
      >
        {sum} ₽
      </Typography>
      {subTitle ? (
        <Typography
          variant="caption2"
          sx={{fontSize: projectPage ? '12px' : '14px', color: 'rgba(57, 33, 94, 0.68)', textAlign: 'center'}}
        >
          {subTitle}
        </Typography>
      ) : null}
    </Box>
  )
}

export default InfoBlock
