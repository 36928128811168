import React from 'react'

export const ArchiveIcon = ({className, color = '#39215E'}) => {
  return (
    <svg
      className={className}
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 5C18.6046 5 19.5 4.10457 19.5 3C19.5 1.89543 18.6046 1 17.5 1H3.5C2.39543 1 1.5 1.89543 1.5 3C1.5 4.10457 2.39543 5 3.5 5M17.5 5H3.5M17.5 5V15C17.5 15.5304 17.2893 16.0391 16.9142 16.4142C16.5391 16.7893 16.0304 17 15.5 17H5.5C4.96957 17 4.46086 16.7893 4.08579 16.4142C3.71071 16.0391 3.5 15.5304 3.5 15V5M8.5 9H12.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
