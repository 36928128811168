import {createSelectorsTree} from '../selectorsFactory'
import {generateReducersObject} from '../sliceMapping'
import {allInitAppEvents} from './votingStoreSliceEvents'

const initialState = {
  isLoading: false,
  votesList: [],
  requestPayload: ''
}

/** Reducers */
export const VOTING_STORE = 'votingStore'
export const votingStore = generateReducersObject({
  allSliceEvents: allInitAppEvents,
  initialState,
  reducerName: VOTING_STORE
})

/** Selectors */
export const votingSelectors = createSelectorsTree(initialState, VOTING_STORE)
