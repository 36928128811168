import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import parse from 'html-react-parser'
import {format} from 'date-fns'
import {CircularProgress, Typography, Box, Button, Dialog, Tooltip, List, ListItem, ListItemText} from '@mui/material'
import {redirect} from 'react-router-dom'
import {
  StyledSlider,
  AlertError,
  PayAlert,
  DaysLeftBlock,
  PaymentBlock,
  PiggyBlock,
  StyledAddContent,
  WithdrawingFunds,
  StyledButtonsWrapper,
  StyledJoinBlock,
  StyledLogo,
  StyledMenuButton,
  StyledTitle
} from './ProjectStyle'
import {map, sum} from 'ramda'
import ButtonBack from '../../../../../components/ButtonBack/ButtonBack'
import {Text} from '../../components/ParagraphWrapper/ParagraphWrapperStyle'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
// import ProgressBar from '../../../../../components/ProgressBar/ProgressBar'
// import ProgressBar from '../../../Votes/components/ProgressBar/ProgressBar'
import ProgressBar from '../../Votes/components/ProgressBar/ProgressBar'
import {NextArrow, PrevArrow} from '../../components/SliderArrows/SliderArrows'
import {DONATE_TEXT, SLIDER_SETTINGS} from './constants/constants'
import {useDispatch, useSelector} from 'react-redux'
import {projectsSelectors} from '../../../../../store/projectsStore/projectsStore'
import ProjectVotesBlock from './components/ProjectVotesBlock/ProjectVotesBlock'
import Invite from '../../Summary/components/Invite/Invite'
import ProjectMembersBlock from './components/ProjectMembersBlock/ProjectMembersBlock'
import {
  StyledTabContentLayout,
  StyledLeftContent,
  StyledMainContent,
  StyledWrapper
} from '../../../../../components/Layouts/TabContentLayout/TabContentLayout'
import {PATHS} from '../../../../../routes/paths'
import {PublishStatusesEnum} from '../../constants/parentCommitteeConstants'
import SummaryBlock from '../../../subpages/Reports/components/SummaryBlock'

import {Emoji} from 'emoji-picker-react'
import {
  setIsLoading,
  getProjectUsersEvent,
  getProjectEvent,
  joinToProjectEvent,
  leaveProjectEvent,
  archivateProjectEvent,
  setConfirmEvent
} from '../../../../../store/projectsStore/projectsStoreSliceEvents'
import {committeeSettingsSelectors} from '../../../../../store/committeeSettingsStore/committeeSettingsStore'
import {CheckIcon, PencilIcon} from '../../../../../icons'
import {STATUSES_IN} from '../../../../MyRodcomesPage/constants/myRodcomesPageConstants'
import StyledAlert from '../../../../../components/StyledAlert/StyledAlert'
import {InfoIconWarning} from './components/FinalBlock/FinalBlockStyle'
import ReportingOutputModal from './components/ReportingOutputModal/ReportingOutputModal'
import ReportBackModal from './components/ReportBackModal/ReportBackModal'
import ParentChatLinkBlock from '../../../../../components/ParentChatLinkBlock/ParentChatLinkBlock'
import LinkBlock from '../../../../../components/LinkBlock/LinkBlock'
import {estimatedTime} from '../../../../../utils/EstimatedTime'
import {imageURL} from '../../../../../utils/imageURL'
import {generateThemeBase} from '../../../../../theme/base'
import {profileSelectors} from '../../../../../store/profileStore/profileStore'
import {childrenSelectors} from '../../../../../store/childStore/childStore'
import {RK_ROLE} from '../../../../JoiningRodcom/constants'
import {createProjectBillEvent, finishBillEvent} from 'store/accrualsStore/accrualStoreSliceEvents'
import {getDebtSummaryEvent, setConfirmedEvent, setIsOpenModal} from 'store/giveoutStore/giveoutStoreSliceEvents'
import {giveoutSelectors} from 'store/giveoutStore/giveoutStore'
import {
  TransferToProjectModal,
  TransferToPiggyOrCardModal,
  TransferToPiggyModal
} from './components/TransferModals/TransferModals'
import FillBankModal from '../../../../../components/Payments/FillBankModal'

import {getOptionsFromDirectories} from 'utils/getOptionsFromDirectories'
import {directoriesSelectors} from 'store/directoriesStore/directoriesStore'
import FundraisingInfoAlert from './components/Fundraising/FundraisingInfoAlert'
import PayPopup from '../../../../../components/Payments/PayPopup'
import {setError} from 'store/initStore/initStoreSliceEvents'
import {accrualsSelectors} from 'store/accrualsStore/accrualStore'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import useScript from 'utils/useScript'
import {getFormattedDate} from 'utils/getFormattedDate'
import data from '@emoji-mart/data/sets/4/apple.json'
import {init} from 'emoji-mart'
import ReportTable from '../../../subpages/Reports/components/ReportTable'
import {All} from '../../../subpages/Reports/Transformers'
import {getHeadersByMode} from '../../../subpages/Reports/headers'
import {getParentCommitteeOperationsEvent} from '../../../../../store/committeeSettingsStore/committeeSettingsStoreSliceEvents'
import {ChevronDownIcon} from '../../../../../icons'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined'
import {notifyError} from '../../../../../utils/notify'
import {StyledLabel} from './components/Fundraising/FundraisingStyle'
import {HtmlTooltip} from '../../../../../components/Tooltip/Tooltips'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import {fontSize} from '@mui/system'

init({data})

export const ALERT_IMAGE_DATA = {
  SRC: '/images/svg/alert.svg',
  ALT: 'предупреждение'
}

const Project = () => {
  const roles = useSelector(committeeSettingsSelectors.roles.selector)
  const rkMembers = useSelector(committeeSettingsSelectors.membersParentCommittee.selector)
  const rkUuid = useSelector(committeeSettingsSelectors.uuid.selector)
  const myUuid = useSelector(profileSelectors.user.selector)?.uuid
  const bank = useSelector(profileSelectors.user.selector)?.beneficiaryBankName
  const sbpPhone = useSelector(profileSelectors.user.selector)?.sbpPhone
  const isChief = useMemo(() => roles?.includes(STATUSES_IN.Chief), [roles])
  const isEducator = useMemo(() => roles?.includes(RK_ROLE.EDUCATOR), [roles])
  const tariffSel = useSelector(directoriesSelectors.tariffs.selector)
  const tariffs = useMemo(() => getOptionsFromDirectories(tariffSel), [tariffSel])
  const [openModal, setOpenModal] = useState(false)
  const isLoading = useSelector(projectsSelectors.isLoading.selector)
  const projectsList = useSelector(projectsSelectors.projectsList.selector)
  const projectsUsers = useSelector(projectsSelectors.projectsUsers.selector)
  const myProjectUser = projectsUsers?.find((user) => user.uuid === myUuid)
  const amIInProject = !!myProjectUser
  const myChildrenCount = myProjectUser?.children?.length ?? 0
  const expensesSummary = useSelector(giveoutSelectors.debtSummary.selector)
  const expensesLoading = useSelector(giveoutSelectors.isLoading.selector)
  const openReportingOutputModal = useSelector(giveoutSelectors.isOpenModal.selector)
  const navigate = useNavigate()
  const location = useLocation()
  const {projectId} = useParams()
  const dispatch = useDispatch()
  // eslint-disable-next-line no-undef
  const donateLink = rkUuid && `${window?.location?.origin}/rk/${rkUuid}/donate`
  const projectData = projectId && projectsList?.find((project) => project.uuid === projectId)
  const isPiggy = projectData?.type === 'PIGGY'
  const unpaidAccruals = projectData?.unpaidAccruals
  const hasToPay = unpaidAccruals?.length
  const [openFillBankModal, setOpenFillBankModal] = useState(false)
  const [openReportBackModal, setOpenReportBackModal] = useState(false)
  const [openTransferModal, setOpenTransferModal] = useState(false)
  const [openTransferToPiggyModal, setOpenTransferToPiggyModal] = useState(false)
  const payInProgress = useSelector(accrualsSelectors.payInProgress.selector)
  const payoutCommission = useSelector(directoriesSelectors.payoutCommission.selector)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const deadlineDate = new Date(projectData?.lastDeadlineDate)
  const firstDeadlineDate = new Date(projectData?.firstDeadlineDate)
  //=================================
  const [isOpenImg, setOpenImgModal] = useState(false)
  const [modalImg, setModalImg] = useState('')
  //===============================
  const [dataTable, setDataTable] = useState([])
  const [filterValue, setFilterValue] = useState('Все операции')
  const [allHistory, setAllHistory] = useState(false)
  const operations = useSelector(committeeSettingsSelectors.operations.selector)
  const memberOptions = useSelector(committeeSettingsSelectors.membersOptions.selector)
  const children = useSelector(childrenSelectors.children.selector)
  const [isRegularPay, setRegularPay] = useState([])
  const [isOneOffPay, setOneOffPay] = useState([])

  const endBalance = operations?.endBalances ? sum(operations?.endBalances?.map((x) => x?.available)) : 0

  const operationRows = useMemo(() => {
    return All(operations, projectsList, memberOptions, children)
  }, [operations, projectsList, memberOptions, children])

  useEffect(() => {
    if (operationRows) {
      if (allHistory) {
        setDataTable(operationRows)
      } else {
        setDataTable(operationRows.slice(0, 6))
      }
    }
  }, [operations, operationRows, allHistory])

  const getHeadersByFilter = useMemo(() => {
    return getHeadersByMode(filterValue)
  }, [filterValue])

  useEffect(() => {
    if (projectData?.type === 'REGULAR' && operations) {
      // console.log(new Date().toLocaleDateString().slice(3, 10))
      const regularPayment = operations?.operations
        ?.filter(
          (oper) =>
            new Date(oper.date).toLocaleDateString().slice(3, 10) === new Date().toLocaleDateString().slice(3, 10)
        )
        .filter((i) => i.operationType === 'PaymentSuccess')
      const checkRegularPayment = regularPayment.filter((i) => i.userUuid === myUuid)
      setRegularPay(checkRegularPayment)
    } else if (projectData?.type === 'ONE_OFF' && operations) {
      const oneOffPayment = operations?.operations.filter((i) => i.operationType === 'PaymentSuccess')
      const checkOneOffPayment = oneOffPayment.filter((i) => i.userUuid === myUuid)
      setOneOffPay(checkOneOffPayment)
    }
  }, [projectData, operations])

  // const onApplyFilter = useCallback(
  //   (value) => {
  //     console.log(value)
  //     dispatch(getParentCommitteeOperationsEvent.action(rkId, value))
  //   },
  //   [rkId]
  // )

  useEffect(() => {
    rkUuid &&
      projectData &&
      dispatch(
        getParentCommitteeOperationsEvent.action(rkUuid, {
          description: '',
          members: [],
          period: '',
          projects: [projectData?.uuid]
        })
      )
  }, [projectData, rkUuid])
  //=================================

  // useScript('https://oplata.qiwi.com/popup/v1.js')

  const daysLeftBeforeAccrual = estimatedTime(projectData?.previousDeadlineDate, false, true, true)
  const daysLeftBeforeEnd = estimatedTime(
    projectData?.lastDeadlineDate ?? projectData?.firstDeadlineDate,
    false,
    true,
    true
  )
  const paymentText = useMemo(() => {
    if (!projectData) return ''
    if (myChildrenCount === 0) {
      return `${projectData.totalPerMember} ₽ за ребенка`
    }

    const moneyToPay = projectData.unpaidAccruals?.map((x) => x.amount).reduce((acc, v) => acc + v, 0) ?? 0

    return `${moneyToPay} ₽`
  }, [projectData])

  const getInitData = useCallback(() => {
    dispatch(getProjectEvent.action(projectId))
    dispatch(getProjectUsersEvent.action(projectId))
    if (isChief) dispatch(getDebtSummaryEvent.action(projectId))
  }, [])

  const handleCloseOutputModal = useCallback(() => {
    dispatch(setIsOpenModal.action(false))
  }, [])
  useEffect(getInitData, [])

  const settings = useMemo(
    () => ({
      ...SLIDER_SETTINGS,
      nextArrow: <NextArrow myClassName="centerSpaceBetween" />,
      prevArrow: <PrevArrow myClassName="centerSpaceBetween" />
    }),
    []
  )

  const onBackClick = () => {
    navigate(location?.pathname.replace(`/${projectId}`, ''))
  }

  const onEditProject = () => {
    navigate(`${location?.pathname}${PATHS.PARENT_COMMITTEE_EDIT_PROJECT}`)
  }

  const payProject = useCallback(() => {
    // dispatch(createProjectBillEvent.action(projectId))
    localStorage.setItem('projId', projectId)
    navigate(location?.pathname.replace(`/projects/${projectData?.uuid}`, '/summary/cart'))
  }, [])

  const parsedAboutProject = useMemo(() => {
    if (!projectData) return null

    return parse(projectData.description)
  }, [projectData?.uuid])

  var links = document.getElementsByTagName('a')
  for (var i = 0, _i = links.length; i < _i; i++) {
    if (links[i].innerHTML.includes('https')) {
      links[i].innerHTML = links[i].hostname + (links[i].pathname ? `${links[i].pathname.substring(0, 5)}...` : '')
      break
    }
  }

  const handleClickBtn = () => {
    localStorage.setItem('projId', JSON.stringify(projectData))
    navigate(location?.pathname.replace(`/projects/${projectData?.uuid}`, '/reports'))
  }

  const handleJoinProject = useCallback(() => {
    dispatch(joinToProjectEvent.action(projectId))
  }, [projectId])

  const handleLeaveProject = useCallback(() => {
    dispatch(leaveProjectEvent.action(projectId))
    setOpenModal(false)
  }, [projectId])

  const closeProject = useCallback(() => {
    if (expensesSummary.totalDebt !== 0 || expensesSummary.available !== 0) {
      notifyError('Чтобы завершить проект, необходимо вывести все деньги и отчитаться за них.')
      return
    }
    dispatch(archivateProjectEvent.action(projectId))
  })

  // bank fill modal logic
  const closeFillBankModal = useCallback(() => {
    setOpenFillBankModal(false)
  }, [bank])

  const prepareTransferModal = useCallback(() => {
    dispatch(setConfirmEvent.action(false))
    setOpenTransferModal(true)
  })
  const closeTransferConfirm = useCallback(() => {
    dispatch(setConfirmedEvent.action(false))
  })
  useEffect(() => {
    if (bank && sbpPhone && openFillBankModal) {
      setOpenFillBankModal(false)
      dispatch(setIsOpenModal.action(true))
    }
  }, [bank, openFillBankModal, sbpPhone])

  const checkBank = useCallback(() => {
    if (bank && sbpPhone) dispatch(setIsOpenModal.action(true))
    else setOpenFillBankModal(true)
  }, [bank, sbpPhone])
  // end
  const showTransferToPiggyModal = useCallback(() => {
    setOpenTransferToPiggyModal(true)
  }, [bank, sbpPhone])

  const giveoutWarning = `Вам нужно отчитаться за ${expensesSummary?.deadlineDebt} ₽, за ${
    expensesSummary?.totalDebt
  } ₽ из которых ${
    expensesSummary?.blocked
      ? 'прямо сейчас. До этих пор вывод будет невозможен.'
      : ' в течение ' + estimatedTime(expensesSummary?.debtOperationDeadLine) + ', иначе вывод будет приостановлен.'
  }`

  const onPaySuccess = useCallback(
    (data) => {
      dispatch(setIsLoading.action(true))
      setTimeout(() => {
        dispatch(getProjectEvent.action(projectId))
      }, 1000)
    },
    [projectId]
  )

  if (isLoading) return <CircularProgress sx={{margin: '0 auto', padding: 19, display: 'block'}} />
  if (!projectData) return null

  let projectProgress = 0

  if (projectData.type === 'REGULAR') {
    projectProgress =
      projectData.amountPaid * (100 / (projectData.totalPerMember * projectData?.members)) >= 100
        ? 100
        : projectData.amountPaid * (100 / (projectData.totalPerMember * projectData?.members))
  } else {
    projectProgress =
      projectData.amountPaid * (100 / (projectData.totalPerMember * projectData?.members)) >= 100
        ? 100
        : projectData.amountPaid * (100 / (projectData.totalPerMember * projectData?.members))
  }

  return (
    <StyledTabContentLayout>
      <Dialog open={openModal} onClose={() => setOpenModal(false)} fullScreen={fullScreen} fullWidth maxWidth="xs">
        <Box sx={{padding: '24px'}}>
          <Typography variant="h1" sx={{fontWeight: '700', textAlign: 'center'}}>
            Вы уверены что хотите покинуть этот проект?
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: '32px'}}>
            <Button className="close-button" variant="outlined" onClick={() => setOpenModal(false)}>
              Нет
            </Button>
            <Button
              className="add-button"
              onClick={() => handleLeaveProject(false)}
              variant="contained"
              startIcon={<CheckIcon color="white" />}
            >
              Да
            </Button>
          </Box>
        </Box>
      </Dialog>
      <Dialog
        open={isOpenImg}
        onClose={() => {
          setOpenImgModal(false)
          setModalImg('')
        }}
        // fullScreen={fullScreen}
        // fullWidth
        maxWidth="500px"
        maxHeight="500px"
      >
        <Box sx={{display: 'flex'}}>
          {modalImg ? (
            <img
              src={`${imageURL(modalImg)}?w=164&h=164&fit=crop&auto=format`}
              // srcSet={`${imageURL(modalImg)}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              width="500px"
              height="500px"
              alt="project_img"
              // loading="lazy"
            />
          ) : null}
        </Box>
      </Dialog>
      {isChief && (
        <ReportingOutputModal
          projectId={projectData?.uuid}
          isOpen={openReportingOutputModal}
          available={projectData?.available}
          handleClose={handleCloseOutputModal}
        />
      )}
      <FillBankModal
        rkId={rkUuid}
        projectId={projectData?.uuid}
        isOpen={openFillBankModal}
        handleClose={closeFillBankModal}
      />
      <ReportBackModal
        projectId={projectData?.uuid}
        isOpen={openReportBackModal}
        handleClose={() => setOpenReportBackModal(false)}
        giveoutWarning={giveoutWarning}
      />
      {isPiggy && openTransferModal && (
        <TransferToProjectModal
          piggyUuid={projectData?.uuid}
          isOpen={openTransferModal}
          available={projectData?.available}
          handleClose={() => setOpenTransferModal(false)}
        />
      )}
      {!isPiggy && openTransferModal && (
        <TransferToPiggyOrCardModal
          projectId={projectData?.uuid}
          isOpen={openTransferModal}
          handleClose={() => {
            setOpenTransferModal(false)
            closeTransferConfirm()
          }}
        />
      )}
      {!isPiggy && openTransferToPiggyModal && (
        <TransferToPiggyModal
          projectId={projectData?.uuid}
          available={projectData?.available}
          isOpen={openTransferToPiggyModal}
          handleClose={() => {
            setOpenTransferToPiggyModal(false)
            closeTransferConfirm()
          }}
        />
      )}

      {/*<PayPopup onSuccess={onPaySuccess} />*/}
      <StyledWrapper>
        <StyledLeftContent>
          <StyledMainContent>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', flexWrap: 'wrap'}}>
              {projectData?.emoji ? (
                // <em-emoji id={projectData?.emoji?.id} set="apple" size="32px"></em-emoji>
                <span style={{fontSize: '60px'}}>{projectData?.emoji?.native}</span>
              ) : projectData?.title === 'Копилка' ? (
                // <em-emoji id="moneybag" set="apple" size="32px"></em-emoji>
                <span style={{fontSize: '60px'}}>💰</span>
              ) : null}
              <Text sx={{marginLeft: '12px', marginRight: '12px'}} className="projTitle">
                {projectData.title}
              </Text>
              {isChief && projectData?.status !== PublishStatusesEnum.COMPLETED && !isPiggy ? (
                <Tooltip title="Редактировать" placement="top">
                  <Box sx={{cursor: 'pointer'}} onClick={onEditProject}>
                    <PencilIcon className="pencilIcon" />
                  </Box>
                </Tooltip>
              ) : null}
            </Box>
            {parsedAboutProject ? <div style={{overflowWrap: 'break-word'}}>{parsedAboutProject}</div> : null}
            {isPiggy ? (
              <Box>
                <Text
                  sx={{
                    fontSize: '16px !important',
                    lineHeight: '24px !important',
                    fontWeight: '500 !important',
                    letterSpacing: '0 !important'
                  }}
                >
                  При создании родительского комитета автоматически создается копилка комитета.
                </Text>
                <ul>
                  <li style={{color: '#FF770C'}}>
                    <span style={{color: '#39215E'}}>
                      Незарегистрированные могут пополнять копилку по прямой ссылке
                    </span>
                  </li>
                  <li style={{color: '#FF770C'}}>
                    <span style={{color: '#39215E'}}>Глава может переводить в копилку остаток средств от проектов</span>
                  </li>
                  <li style={{color: '#FF770C'}}>
                    <span style={{color: '#39215E'}}>
                      Глава может переводить из копилки в проект доступные средства
                    </span>
                  </li>
                  <li style={{color: '#FF770C'}}>
                    <span style={{color: '#39215E'}}>Глава может вывести из проекта нужную сумму под отчет</span>
                  </li>
                </ul>
              </Box>
            ) : null}
            {projectData.galleryImages.length ? (
              <ImageList sx={{width: '100%'}} cols={4} rowHeight={170}>
                {projectData.galleryImages.map((image, index) => (
                  <ImageListItem key={index} className="img-list" sx={{minWidth: '150px'}}>
                    <img
                      src={`${imageURL(image)}?w=164&h=164&fit=crop&auto=format`}
                      srcSet={`${imageURL(image)}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      alt="project_img"
                      // loading="lazy"
                      className="proj-img"
                      onClick={() => {
                        setModalImg(image), setOpenImgModal(true)
                      }}
                    />
                    <ZoomInOutlinedIcon
                      className="img-icon"
                      sx={{
                        position: 'absolute',
                        width: '50px',
                        height: '50px',
                        color: 'white',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)'
                      }}
                      onClick={() => {
                        setModalImg(image), setOpenImgModal(true)
                      }}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            ) : null}
          </StyledMainContent>

          {/*{!daysLeftBeforeEnd && projectData?.title !== 'Копилка' && fullScreen ? (*/}
          {projectData?.status === 'COMPLETED' && projectData?.title !== 'Копилка' && fullScreen ? (
            <PaymentBlock sx={{marginTop: '19px'}}>
              <Typography variant="h4" sx={{fontWeight: '700', fontSize: '18px'}}>
                Сбор денег завершен{' '}
                {projectData?.lastDeadlineDate ? format(new Date(projectData?.lastDeadlineDate), 'dd.MM.yyyy') : ''}
              </Typography>
            </PaymentBlock>
          ) : projectData?.type === 'PIGGY' && fullScreen ? null : (
            <>
              {paymentText && !isPiggy && fullScreen && (
                <PaymentBlock sx={{marginTop: '19px'}}>
                  {projectData?.type === 'REGULAR' ? (
                    <Typography sx={{fontSize: '16px', fontWeight: '500', textAlign: 'left'}}>
                      Будем собирать <b>по {projectData.totalPerMember}₽ в месяц на каждого ребенка.</b> Общая сумма в
                      месяц{' '}
                      <b>
                        на {projectData?.members} детей составит {projectData.totalPerMember * projectData?.members}₽.{' '}
                      </b>
                      Всего за {projectData.periodsNumber} {projectData.periodsNumber > 4 ? 'месяцев' : 'месяца'}{' '}
                      соберем<b> {projectData.totalPerMember * projectData?.members * projectData.periodsNumber}₽.</b>{' '}
                      {projectData?.currentAccrualNumber > 1 ? 'Следующий' : 'Первый'} взнос нужно сделать{' '}
                      <b>
                        до {getFormattedDate(firstDeadlineDate)}. Далее до {deadlineDate?.getDate() || '-'}-го числа{' '}
                        каждого месяца.
                      </b>
                    </Typography>
                  ) : (
                    <Typography sx={{fontSize: '16px', fontWeight: '500', textAlign: 'left'}}>
                      Cобираем по {projectData.totalPerMember}₽ на каждого ребенка. Всего на {projectData?.members}{' '}
                      детей соберем {projectData.totalPerMember * projectData?.members}₽. Внести деньги нужно до{' '}
                      {getFormattedDate(deadlineDate)}
                    </Typography>
                  )}
                  <Box sx={{width: '100%'}}>
                    <ProgressBar
                      // text={
                      //   projectData.type === 'REGULAR'
                      //     ? `${projectData?.moneyPerMember} из ${projectData?.totalPerMember}`
                      //     : `${projectData?.amountPaid} из ${projectData?.targetMoney}`
                      // }
                      value={`${projectProgress}%`}
                      sx={{height: '8', borderRadius: 16, width: '100%'}}
                    />
                    <Typography sx={{fontSize: '12px', fontWeight: '500', textAlign: 'center'}}>
                      {projectData.type === 'REGULAR'
                        ? `${projectData?.amountPaid} ₽ из ${projectData?.totalPerMember * projectData?.members} ₽`
                        : `${projectData?.amountPaid} ₽ из ${projectData?.totalPerMember * projectData?.members} ₽`}
                    </Typography>
                  </Box>
                  {/* {daysLeftBeforeAccrual && myChildrenCount > 0 && hasToPay ? (
                <Typography variant="caption" sx={{fontSize: '16px', opacity: 0.7}}>
                  Оплатить в течение {daysLeftBeforeAccrual}
                </Typography>
              ) : null} */}
                  {isEducator ? (
                    <StyledAlert
                      title=""
                      icon={false}
                      sx={{width: '100%', borderRadius: '8px !important', background: '#F3F3FF !important'}}
                    >
                      <Typography variant="h4" sx={{fontWeight: '700'}}>
                        Ваша роль в родкоме не предполагает взносы.
                      </Typography>
                    </StyledAlert>
                  ) : (
                    <>
                      <Box
                        sx={{
                          width: '100%',
                          borderRadius: '8px',
                          background: '#F3F3FF !important',
                          padding: '12px 16px !important',
                          boxSizing: 'border-box'
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            fontSize: '15px',
                            fontWeight: '500',
                            padding: '0 !important',
                            color: '#39215E',
                            marginBottom: '4px'
                          }}
                        >
                          Собираем по: <b>{projectData.totalPerMember} ₽</b>
                        </Typography>
                        <Typography
                          variant="h4"
                          sx={{fontSize: '15px', fontWeight: '500', padding: '0 !important', color: '#B52B00'}}
                        >
                          Ваш долг: <b>{unpaidAccruals?.reduce((a, b) => a + b.amount, 0)} ₽</b>
                        </Typography>
                      </Box>
                      {myChildrenCount > 0 && hasToPay ? (
                        <Button
                          disabled={payInProgress}
                          sx={{width: '100% !important'}}
                          variant="contained"
                          type="button"
                          onClick={payProject}
                          endIcon={<ArrowRightAltIcon color="white" />}
                        >
                          К оплате
                        </Button>
                      ) : null}
                    </>
                  )}
                </PaymentBlock>
              )}
            </>
          )}

          {isPiggy && fullScreen ? (
            <>
              <PiggyBlock sx={{marginTop: '19px'}}>
                <Box
                  sx={{
                    fontSize: '15px',
                    fontWeight: 500,
                    padding: '12px 16px',
                    background: '#F3F3FF',
                    borderRadius: '8px',
                    width: '100%',
                    boxSizing: 'border-box'
                  }}
                >
                  Баланс копилки: <b>{endBalance ? `${endBalance} ₽` : `0 ₽`}</b>
                  <br />
                  Из них на карте у главы родкома: <b>{operations?.totalDebt ? `${operations.totalDebt} ₽` : `0 ₽`}</b>
                </Box>
                <Button
                  // disabled={payInProgress}
                  sx={{width: '100% !important'}}
                  variant="contained"
                  type="button"
                  onClick={() => window.location.assign(`${window?.location.origin}/rk/${projectData.rkId}/donate`)}
                  endIcon={<ArrowRightAltIcon color="white" />}
                >
                  Внести сумму
                </Button>
              </PiggyBlock>
              <LinkBlock
                className="payLink"
                title="Незарегистрированные родители могут сдать деньги в копилку по этой ссылке. Просто отправьте её им."
                isPiggy={isPiggy}
                link={`${window?.location.origin}/rk/${projectData.rkId}/donate`}
              />
            </>
          ) : null}

          {/*{isChief &&*/}
          {/*projectData &&*/}
          {/*fullScreen &&*/}
          {/*// !(isPiggy && !projectData.available) &&*/}
          {/*(projectData?.available > 0 ||*/}
          {/*  // expensesSummary?.totalDebt > 0 ||*/}
          {/*  expensesSummary?.totalDebt > 0 ||*/}
          {/*  (hasTransferButton() && projectData?.available > 0) ||*/}
          {/*  hasArchiveButton()) ? (*/}
          {/*  <Box sx={{marginTop: '19px'}}>{chiefActionsBlock()}</Box>*/}
          {/*) : null}*/}

          {isChief && projectData && fullScreen ? <Box sx={{marginTop: '19px'}}>{chiefActionsBlock()}</Box> : null}

          <ProjectVotesBlock isChief={isChief} status={projectData?.status} />

          {/* тут блок с оплатами */}
          <Box className="payment-history">
            <Box className="info-block">
              <Box sx={{textAlign: 'left'}}>
                <Typography variant="h4" sx={{fontWeight: '700', fontSize: '26px'}}>
                  Финансы
                </Typography>
                {/* <Typography variant="h4" sx={{fontWeight: '500', fontSize: '14px', marginTop: '10px'}}>
                  Баланс проекта: {projectData?.available}₽
                </Typography>
                <Typography variant="h4" sx={{fontWeight: '500', fontSize: '14px', marginTop: '8px'}}>
                  Всего поступило: {operations?.operations.reduce((a, b) => a + (b['amountPaid'] || 0), 0)}₽ из них
                  взносов{' '}
                  {operations?.operations
                    .filter((operation) => operation.operationType === 'PaymentSuccess')
                    .reduce((a, b) => a + (b['amountPaid'] || 0), 0)}
                  ₽
                </Typography>
                <Typography variant="h4" sx={{fontWeight: '500', fontSize: '14px', marginTop: '8px'}}>
                  Потратили: {operations?.expenses}₽
                </Typography> */}
              </Box>
              <Button
                className="btn"
                variant="contained"
                type="button"
                color="secondary"
                onClick={handleClickBtn}
                endIcon={<ArrowRightAltIcon />}
              >
                Подробный отчет
              </Button>
            </Box>
            <SummaryBlock
              startBalance={operations?.startBalance ? operations?.startBalance : 0}
              endBalance={operations?.endBalances ? sum(operations?.endBalances?.map((x) => x?.available)) : 0}
              paid={operations?.paid ? operations?.paid : 0}
              expenses={operations?.expenses ? operations?.expenses : 0}
              filterProject={true}
              summary={operations}
              projectPage={true}
              isPiggy={isPiggy}
            />
            <ReportTable
              tableKind={filterValue}
              headers={getHeadersByFilter}
              data={dataTable}
              history={true}
              projectFilter={true}
            />
            {operationRows?.length > 6 && !allHistory ? (
              <Button
                className="showMoreButton"
                sx={{marginTop: '24px'}}
                variant="outlined"
                type="button"
                onClick={() => setAllHistory(true)}
                endIcon={<ChevronDownIcon />}
              >
                Показать еще
              </Button>
            ) : null}
          </Box>
          {!fullScreen ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: {lg: 'row', sm: 'column'},
                marginTop: '24px',
                gap: '20px',
                boxSizing: 'border-box'
              }}
            >
              {projectData?.chatLink ? (
                <ParentChatLinkBlock title="Родительский чат" link={projectData.chatLink} />
              ) : null}
              <LinkBlock title="Ссылка на этот проект" />
            </Box>
          ) : null}
        </StyledLeftContent>

        <StyledAddContent>
          {projectData?.status === 'COMPLETED' && projectData?.title !== 'Копилка' && !fullScreen ? (
            <PaymentBlock>
              <Typography variant="h4" sx={{fontWeight: '700', fontSize: '18px'}}>
                Сбор денег завершен{' '}
                {projectData?.lastDeadlineDate ? format(new Date(projectData?.lastDeadlineDate), 'dd.MM.yyyy') : ''}
              </Typography>
            </PaymentBlock>
          ) : projectData?.type === 'PIGGY' && !fullScreen ? null : (
            <>
              {paymentText && !isPiggy && !fullScreen && (
                <PaymentBlock>
                  {projectData?.type === 'REGULAR' ? (
                    <Typography sx={{fontSize: '16px', fontWeight: '500', textAlign: 'left'}}>
                      Будем собирать <b>по {projectData.totalPerMember}₽ в месяц на каждого ребенка.</b> Общая сумма в
                      месяц{' '}
                      <b>
                        на {projectData?.members} детей составит {projectData.totalPerMember * projectData?.members}₽.{' '}
                      </b>
                      Всего за {projectData.periodsNumber} {projectData.periodsNumber > 4 ? 'месяцев' : 'месяца'}{' '}
                      соберем <b>{projectData.totalPerMember * projectData?.members * projectData.periodsNumber}₽.</b>{' '}
                      {projectData?.currentAccrualNumber > 1 ? 'Следующий' : 'Первый'} взнос нужно сделать{' '}
                      <b>
                        до {getFormattedDate(firstDeadlineDate)}.{' '}
                        {projectData?.currentAccrualNumber > 1
                          ? ''
                          : `Далее до ${deadlineDate?.getDate() || '-'}-го числа каждого месяца.`}
                      </b>
                    </Typography>
                  ) : (
                    <Typography sx={{fontSize: '16px', fontWeight: '500', textAlign: 'left'}}>
                      Cобираем <b>по {projectData.totalPerMember}₽ на каждого ребенка.</b> Всего{' '}
                      <b>
                        на {projectData?.members} детей соберем {projectData.totalPerMember * projectData?.members}₽.{' '}
                      </b>
                      Внести деньги нужно <b>до {getFormattedDate(deadlineDate)}</b>
                    </Typography>
                  )}
                  <Box sx={{width: '100%'}}>
                    <ProgressBar
                      // text={
                      //   projectData.type === 'REGULAR'
                      //     ? `${projectData?.moneyPerMember} из ${projectData?.totalPerMember}`
                      //     : `${projectData?.amountPaid} из ${projectData?.targetMoney}`
                      // }
                      value={`${projectProgress}%`}
                      sx={{height: '8', borderRadius: 16, width: '100%'}}
                    />
                    <Typography sx={{fontSize: '12px', fontWeight: '500', textAlign: 'center'}}>
                      {projectData.type === 'REGULAR'
                        ? `${projectData?.amountPaid} ₽ из ${projectData?.totalPerMember * projectData?.members} ₽`
                        : `${projectData?.amountPaid} ₽ из ${projectData?.totalPerMember * projectData?.members} ₽`}
                    </Typography>
                  </Box>
                  {/* {daysLeftBeforeAccrual && myChildrenCount > 0 && hasToPay ? (
                <Typography variant="caption" sx={{fontSize: '16px', opacity: 0.7}}>
                  Оплатить в течение {daysLeftBeforeAccrual}
                </Typography>
              ) : null} */}
                  {isEducator ? (
                    <StyledAlert
                      title=""
                      icon={false}
                      sx={{width: '100%', borderRadius: '8px !important', background: '#F3F3FF !important'}}
                    >
                      <Typography variant="h4" sx={{fontWeight: '700'}}>
                        Ваша роль в родкоме не предполагает взносы.
                      </Typography>
                    </StyledAlert>
                  ) : (
                    <>
                      <Box
                        sx={{
                          width: '100%',
                          borderRadius: '8px',
                          background: '#F3F3FF !important',
                          padding: '12px 16px !important',
                          boxSizing: 'border-box'
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            fontSize: '15px',
                            fontWeight: '500',
                            padding: '0 !important',
                            color: '#39215E',
                            marginBottom: '4px'
                          }}
                        >
                          Собираем по: <b>{projectData.totalPerMember} ₽</b>
                        </Typography>
                        <Typography
                          variant="h4"
                          sx={{fontSize: '15px', fontWeight: '500', padding: '0 !important', color: '#B52B00'}}
                        >
                          Ваш долг: <b>{unpaidAccruals?.reduce((a, b) => a + b.amount, 0)} ₽</b>
                        </Typography>
                      </Box>
                      {myChildrenCount > 0 && hasToPay ? (
                        <Button
                          disabled={payInProgress}
                          sx={{width: '100% !important'}}
                          variant="contained"
                          type="button"
                          onClick={payProject}
                          endIcon={<ArrowRightAltIcon color="white" />}
                        >
                          К оплате
                        </Button>
                      ) : null}
                    </>
                  )}
                </PaymentBlock>
              )}
            </>
          )}
          {isPiggy && !fullScreen ? (
            <>
              <PiggyBlock>
                <Box
                  sx={{
                    fontSize: '15px',
                    fontWeight: 500,
                    padding: '12px 16px',
                    background: '#F3F3FF',
                    borderRadius: '8px',
                    width: '100%',
                    boxSizing: 'border-box'
                  }}
                >
                  Баланс копилки: <b>{endBalance ? `${endBalance} ₽` : `0 ₽`}</b>
                  <br />
                  Из них на карте у главы родкома: <b>{operations?.totalDebt ? `${operations.totalDebt} ₽` : `0 ₽`}</b>
                </Box>
                <Button
                  // disabled={payInProgress}
                  sx={{width: '100% !important'}}
                  variant="contained"
                  type="button"
                  onClick={() => window.location.assign(`${window?.location.origin}/rk/${projectData.rkId}/donate`)}
                  endIcon={<ArrowRightAltIcon color="white" />}
                >
                  Внести сумму
                </Button>
              </PiggyBlock>
              <LinkBlock
                title="Незарегистрированные родители могут сдать деньги в копилку по этой ссылке. Просто отправьте её им."
                isPiggy={isPiggy}
                link={`${window?.location.origin}/rk/${projectData.rkId}/donate`}
              />
            </>
          ) : null}
          {/*{isChief &&*/}
          {/*projectData &&*/}
          {/*!fullScreen &&*/}
          {/*// !(isPiggy && !projectData.available) &&*/}
          {/*(projectData?.available > 0 ||*/}
          {/*  // expensesSummary?.totalDebt > 0 ||*/}
          {/*  expensesSummary?.totalDebt > 0 ||*/}
          {/*  (hasTransferButton() && projectData?.available > 0) ||*/}
          {/*  hasArchiveButton())*/}
          {/*  ? chiefActionsBlock()*/}
          {/*  : null}*/}

          {isChief && projectData && !fullScreen
            ? // !(isPiggy && !projectData.available) &&
              chiefActionsBlock()
            : null}

          <Box sx={{borderRadius: '12px', border: '1px solid #EDEDFF', padding: '20px', background: 'white'}}>
            <ProjectMembersBlock
              className="project-members"
              members={isPiggy ? rkMembers : projectsUsers}
              editable={false}
            />
            {amIInProject && daysLeftBeforeEnd && projectData?.status !== PublishStatusesEnum.COMPLETED ? (
              <Button
                sx={{width: '100%', marginTop: '20px'}}
                variant="contained"
                startIcon={<CheckIcon color={generateThemeBase.palette.primary.contrastText} />}
                type="button"
                color="secondary"
                onClick={() => setOpenModal(true)}
                disabled={isRegularPay.length || isOneOffPay.length}
              >
                Покинуть проект
              </Button>
            ) : null}
            {isRegularPay.length ? (
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  textAlign: 'center',
                  opacity: 0.6,
                  marginTop: '12px',
                  lineHeight: '15px'
                }}
              >
                Нельзя покинуть проект в оплаченном месяце. Дождитесь следующего.
              </Typography>
            ) : isOneOffPay.length ? (
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  textAlign: 'center',
                  opacity: 0.6,
                  marginTop: '12px',
                  lineHeight: '15px'
                }}
              >
                После оплаты нельзя выйти из проекта.
              </Typography>
            ) : null}
            {!isEducator && !isPiggy && !amIInProject && daysLeftBeforeEnd ? (
              <Button
                sx={{width: '100%', marginTop: '20px'}}
                variant="contained"
                startIcon={<CheckIcon color={generateThemeBase.palette.primary.contrastText} />}
                type="button"
                onClick={() => handleJoinProject(false)}
              >
                Вступить в проект
              </Button>
            ) : null}
          </Box>
        </StyledAddContent>
        {fullScreen ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: {lg: 'row', sm: 'column'},
              // marginTop: '24px',
              gap: '20px',
              boxSizing: 'border-box'
            }}
          >
            {projectData?.chatLink ? (
              <ParentChatLinkBlock title="Родительский чат" link={projectData.chatLink} />
            ) : null}
            <LinkBlock title="Ссылка на этот проект" />
          </Box>
        ) : null}
      </StyledWrapper>
      {/* {isPiggy && (
        <Box sx={{marginTop: '32px'}}>
          <Invite url={donateLink} text={DONATE_TEXT} />
        </Box>
      )} */}
    </StyledTabContentLayout>
  )

  function chiefActionsBlock() {
    return (
      <WithdrawingFunds>
        <Box sx={{fontSize: 18, fontWeight: 700, color: '#39215E'}}>Управление финансами</Box>

        {giveoutAndReportBlock()}

        {/*{hasTransferButton() && projectData?.available > 0 && isPiggy && (*/}
        {hasTransferButton() && isPiggy && (
          <Button
            variant="contained"
            type="button"
            color="secondary"
            sx={{width: '100%'}}
            onClick={prepareTransferModal}
          >
            Перевести
          </Button>
        )}
        {hasArchiveButton() && (
          <Button variant="contained" type="button" color="secondary" sx={{width: '100%'}} onClick={closeProject}>
            Завершить проект
          </Button>
        )}
        <StyledLabel
          sx={{
            marginTop: '10px',
            width: '100%',
            alignSelf: 'center',
            display: 'flex',
            gap: '7px',
            alignItems: 'center',
            flexWrap: 'wrap',
            transformOrigin: 'center'
          }}
        >
          <HtmlTooltip
            leaveTouchDelay={20000}
            enterTouchDelay={1}
            disableFocusListener
            sx={{color: 'black'}}
            title={
              <>
                <Typography color="inherit" paragraph="true" sx={{margin: '0'}}>
                  <List
                    sx={{
                      listStyleType: 'disc',
                      pl: 2,
                      '& .MuiListItem-root': {
                        display: 'list-item',
                        fontSize: '14px',
                        padding: '0px'
                      },

                      listStylePosition: 'inside'
                    }}
                  >
                    <ListItem>пользование сервисом - бесплатно;</ListItem>
                    <ListItem>пополнение копилки/проектов - бесплатно;</ListItem>
                    <ListItem>вывод средств на карту главы комитета - {payoutCommission}%.</ListItem>
                  </List>
                </Typography>
              </>
            }
          >
            <Typography
              variant="caption"
              sx={{display: 'flex', margin: 'auto', justifyContent: 'center', alignItems: 'center'}}
            >
              <ErrorOutlineIcon />
              <Typography
                variant="caption"
                sx={{fontSize: '19px', fontWeight: '700', display: 'inline', marginLeft: '5px'}}
              >
                Тариф
              </Typography>
            </Typography>
          </HtmlTooltip>
        </StyledLabel>
      </WithdrawingFunds>
    )
  }

  function giveoutAndReportBlock() {
    return (
      <>
        {/*{projectData?.available > 0 && (*/}
        {
          <>
            {!isPiggy ? (
              <Button
                variant="contained"
                type="button"
                color="secondary"
                sx={{width: '100%'}}
                onClick={showTransferToPiggyModal}
              >
                Перевод в копилку
              </Button>
            ) : null}
            <Button variant="contained" type="button" color="secondary" sx={{width: '100%'}} onClick={checkBank}>
              Вывод под отчёт
            </Button>
          </>
        }
        {expensesSummary?.totalDebt > 0 ? (
          <AlertError>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '6px'}}>
              {expensesSummary?.blocked && <Typography variant="h3" sx={{fontWeight: 700}}></Typography>}

              <Typography variant="h3">{giveoutWarning}</Typography>
              <Button
                variant="contained"
                type="button"
                onClick={() => setOpenReportBackModal(true)}
                color="secondary"
                sx={{width: '100%', background: '#FFEBB7', ':hover': {background: '#fcde91'}}}
              >
                Отчитаться за вывод
              </Button>
            </Box>
          </AlertError>
        ) : (
          expensesSummary?.totalDebt > 0 && (
            <Button
              variant="contained"
              type="button"
              color="secondary"
              sx={{width: '100%'}}
              onClick={() => setOpenReportBackModal(true)}
            >
              Отчитаться за вывод
            </Button>
          )
        )}
      </>
    )
  }

  function hasArchiveButton() {
    return (
      !daysLeftBeforeEnd &&
      !expensesSummary?.totalDebt &&
      !isPiggy &&
      projectData?.status !== PublishStatusesEnum.COMPLETED
    )
  }

  function hasTransferButton() {
    return isPiggy || (projectData?.status === PublishStatusesEnum.PUBLISHED && !daysLeftBeforeEnd)
  }
}

export default Project
