import initSlice from '../initSlice'

const {sliceEvent, allSliceEvents} = initSlice()

const PREFIX = 'INVITE_'

export const getProfileInfoEvent = sliceEvent({
  type: `${PREFIX}_INVITE_INFO_EVENT`,
  action: (uuid) => {
    return {
      payload: {
        uuid
      }
    }
  },
  reducer: (state) => {
    return {
      ...state,
      inviteIsLoading: true
    }
  }
})

export const getProfileInfoSuccessEvent = sliceEvent({
  type: `${PREFIX}_INFO_SUCCESS`,
  action: (payload) => {
    return {payload}
  },
  reducer: (state, action = {type: '', payload: {}}) => {
    const {institutionAddress, institutionName, institutionType, groupName, groupNumber, classUuid, classLetterUuid} =
      action.payload
    return {
      ...state,
      inviteRk: {
        institutionAddress: institutionAddress.fullAddress,
        institutionName: institutionName,
        institutionType: institutionType,
        groupName,
        groupNumber: groupNumber,
        classUuid: classUuid,
        classLetterUuid: classLetterUuid
      }
    }
  }
})

export const allInitAppEvents = allSliceEvents
