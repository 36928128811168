import React, {useState, useMemo, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {Box, Typography} from '@mui/material'
import ErrorOutline from '@mui/icons-material/ErrorOutline'

import StyledAlert from '../../../../components/StyledAlert/StyledAlert'
import SubmitBlock from '../SubmitBlock/SubmitBlock'
import {searchParentCommittees} from '../../../../store/creatingRkStore/creatingRkStoreSliceEvents'
import SchoolForm from '../SchoolForm/SchoolForm'
import KindergartenForm from '../KindergartenForm/KindergartenForm'
import {getEmptyError, getNameError, getUrlError} from '../../../../utils/validations'
import {creatingRkSelectors} from '../../../../store/creatingRkStore/creatingRkStore'
import {initKindergartenState, initSchoolState, RK_TYPES} from '../../constants/creatingRodcomConstants'
import * as creatingRkStoreSliceEvents from '../../../../store/creatingRkStore/creatingRkStoreSliceEvents'
import {directoriesSelectors} from '../../../../store/directoriesStore/directoriesStore'
import {findSelectedValueInDirectories} from '../../../../utils/getOptionsFromDirectories'
import {useLocation} from 'react-router-dom'
import {STEPS} from '../../CreatingRodcom'
const useCheckSchoolRkExists = (fieldValues, classes, city, companyName, type) => {
  const [list, setList] = useState([])
  const [hasCombination, setHasCombination] = useState(false)
  const resultSearchParentCommittees = useSelector(creatingRkSelectors.searchParentCommittees.selector)

  useEffect(() => {
    setList(resultSearchParentCommittees)

    const res = list.find(
      (v) =>
        v.yearOfAdmission == fieldValues.yearOfAdmission &&
        v.groupName == fieldValues.letter &&
        classes[v.classUuid] == fieldValues.grade
    )
    setHasCombination(res)
  }, [resultSearchParentCommittees, classes, fieldValues])

  const dispatch = useDispatch()
  useEffect(() => {
    if (type !== RK_TYPES.SCHOOL) return

    dispatch(
      searchParentCommittees.action({
        City: city,
        InstitutionName: companyName,
        InstitutionType: RK_TYPES.SCHOOL
      })
    )
  }, [])

  return hasCombination
}

const useCheckKindergartenRkExists = (fieldValues, classes, city, companyName, type) => {
  const [list, setList] = useState([])
  const [hasCombination, setHasCombination] = useState(false)
  const resultSearchParentCommittees = useSelector(creatingRkSelectors.searchParentCommittees.selector)

  useEffect(() => {
    setList(resultSearchParentCommittees)

    const res = list.find(
      (v) => v.groupName == fieldValues.groupName && (v.groupNumber ?? '') == fieldValues.groupNumber
    )
    setHasCombination(res)
  }, [resultSearchParentCommittees, classes, fieldValues])

  const dispatch = useDispatch()
  useEffect(() => {
    if (type !== RK_TYPES.KINDERGARTEN) return

    dispatch(
      searchParentCommittees.action({
        City: city,
        InstitutionName: companyName,
        InstitutionType: RK_TYPES.KINDERGARTEN
      })
    )
  }, [])

  return hasCombination
}

const CreatingRodcomForm = ({type, setNewRKData, setStep}) => {
  const dispatch = useDispatch()
  const {search} = useLocation()

  const params = new URLSearchParams(search)
  const companyName = params.get('name') || ''
  const companyDesc = params.get('desc') || ''
  const companyCity = params.get('city') || ''
  const companyExternalSystem = params.get('externalSystem') || ''

  const isLoading = useSelector(creatingRkSelectors.isLoading.selector)
  const errors = useSelector(creatingRkSelectors.errors.selector)
  const classes = useSelector(directoriesSelectors.class.selector)
  const classLetter = useSelector(directoriesSelectors.classLetter.selector)

  const foundRks = useSelector(creatingRkSelectors.searchParentCommittees.selector)

  const [schoolFieldValues, setSchoolFieldValues] = useState(initSchoolState)
  const [kindergartenFieldValues, setKindergartenFieldValues] = useState(initKindergartenState)
  const [schoolErrors, setSchoolErrors] = useState({})
  const [kindergartenErrors, setKindergartenErrors] = useState({})

  const kindergartenRkExists = useCheckKindergartenRkExists(
    kindergartenFieldValues,
    classes,
    companyCity,
    companyName,
    type
  )

  const schoolRkExists = useCheckSchoolRkExists(schoolFieldValues, classes, companyCity, companyName, type)

  useEffect(() => {
    const newErrors = {}
    newErrors.copy = getCopyClassError(schoolRkExists)
    setSchoolErrors(newErrors)
  }, [schoolRkExists])

  useEffect(() => {
    const newErrors = {}
    newErrors.copy = getCopyClassError(kindergartenRkExists)
    setKindergartenErrors(newErrors)
  }, [kindergartenRkExists])

  useEffect(() => {
    const hasErrors = Object.values(errors).some((val) => Boolean(val))
    if (hasErrors) {
      if (type === RK_TYPES.SCHOOL) {
        setSchoolErrors(errors)
      } else {
        setKindergartenErrors(errors)
      }
      dispatch(creatingRkStoreSliceEvents.setErrors.action({}))
    }
  }, [errors])

  const setSchoolFieldValue = (value, field) => {
    setSchoolFieldValues((values) => ({...values, [field]: value}))
  }

  const setKindergartenFieldValue = (value, field) => {
    setKindergartenFieldValues((values) => ({...values, [field]: value}))
  }

  const setSchoolErrorField = (field, isError) => {
    setSchoolErrors((errs) => ({...errs, [field]: isError}))
  }

  const setKindergartenErrorField = (field, isError) => {
    setKindergartenErrors((errs) => ({...errs, [field]: isError}))
  }

  const onSchoolChangeValue = (value, field) => {
    const isError = schoolErrors[field]
    if (isError) {
      setSchoolErrorField(field, false)
    }
    setSchoolFieldValue(value, field)
  }

  const onKindergartenChangeValue = (value, field) => {
    const isError = kindergartenErrors[field]
    if (isError) {
      setKindergartenErrorField(field, false)
    }
    if (field === 'groupName' && kindergartenErrors.groupNumber) {
      setKindergartenErrorField('groupNumber', false)
    }
    if (field === 'groupNumber' && kindergartenErrors.groupName) {
      setKindergartenErrorField('groupName', false)
    }
    setKindergartenFieldValue(value, field)
  }

  function getCopyClassError(err) {
    const url =
      '${method}?city=${$city}&name=${companyProperties.name}&desc=${companyProperties.description}&geo=${geometry.coordinates}&institutionType=${institutionType}'
    return err ? (
      <span>
        Такой родительский комитет уже существует. Вступите в него или напишите на&nbsp;
        <a href="mailto:support@rodcom.ru">support@rodcom.ru</a>
      </span>
    ) : null
  }
  function getCopyGroupError(err) {
    return err ? 'Такой родительский комитет уже существует. Вступите в него или напишите на support@rodcom.ru' : ''
  }

  const onSchoolSubmit = () => {
    const newErrors = {}
    const {yearOfAdmission, grade, letter, educator, name, chat} = schoolFieldValues
    newErrors.yearOfAdmission = getEmptyError(yearOfAdmission)
    newErrors.grade = getEmptyError(grade)
    newErrors.letter = getEmptyError(letter)
    newErrors.chat = getUrlError(chat)
    newErrors.copy = getCopyClassError(schoolRkExists)

    setSchoolErrors(newErrors)
    const hasErrors = Object.values(newErrors).some((el) => el)
    if (hasErrors) {
      return
    }
    const myData = {
      groupName: letter,
      institutionType: type,
      classUuid: findSelectedValueInDirectories(classes, grade),
      classLetterUuid: findSelectedValueInDirectories(classLetter, letter),
      yearOfAdmission: Number(yearOfAdmission),
      chatLink: chat,
      city: companyCity,
      institutionAddress: {
        fullAddress: companyDesc
      },
      institution: {
        name: companyName,
        externalSystem: companyExternalSystem
      }
    }
    if (name.trim()) {
      myData.name = name
    }
    if (educator.trim()) {
      myData.educator = educator
    }

    setStep(STEPS.ADDING_INFO)
    setNewRKData(myData)
  }

  const onKindergartenSubmit = () => {
    const newErrors = {}
    const {educator, groupName, groupNumber, chat} = kindergartenFieldValues
    newErrors.educator = getNameError(educator)
    const groupNameError = getEmptyError(groupName)
    const groupNumberError = getEmptyError(groupNumber)
    if (groupNumber || groupName) {
      newErrors.groupName = false
      newErrors.groupNumber = false
    } else {
      newErrors.groupName = groupNameError
      newErrors.groupNumber = groupNumberError
    }
    newErrors.chat = getUrlError(chat)
    newErrors.copy = getCopyClassError(kindergartenRkExists)
    setKindergartenErrors(newErrors)
    const hasErrors = Object.values(newErrors).some((el) => el)
    if (hasErrors) {
      return
    }
    const requestData = {
      institutionType: type,
      educator,
      chatLink: chat,
      city: companyCity,
      institutionAddress: {
        fullAddress: companyDesc
      },
      institution: {
        name: companyName,
        externalSystem: companyExternalSystem
      }
    }
    if (groupName.trim()) {
      requestData.groupName = groupName
    }
    if (groupNumber.trim()) {
      requestData.groupNumber = groupNumber
    }

    setStep(STEPS.ADDING_INFO)
    setNewRKData(requestData)
  }

  const onSubmit = (event) => {
    event.preventDefault()
    if (type === RK_TYPES.SCHOOL) {
      onSchoolSubmit()
    } else {
      onKindergartenSubmit()
    }
  }

  const form = useMemo(
    () =>
      type === RK_TYPES.SCHOOL ? (
        <SchoolForm
          errors={schoolErrors}
          fieldValues={schoolFieldValues}
          onChangeValue={onSchoolChangeValue}
          classes={classes}
          classLetter={classLetter}
        />
      ) : (
        <KindergartenForm
          errors={kindergartenErrors}
          fieldValues={kindergartenFieldValues}
          onChangeValue={onKindergartenChangeValue}
        />
      ),
    [
      type,
      schoolErrors,
      schoolFieldValues,
      kindergartenErrors,
      kindergartenFieldValues,
      onKindergartenChangeValue,
      onSchoolChangeValue
    ]
  )

  return (
    <Box className="form" component="form" onSubmit={onSubmit}>
      <Box className="formInner" component="div">
        <Typography className="params">Параметры родкома</Typography>
        {form}
        <StyledAlert icon={false} className="warning" sx={{fontSize: '16px', maxWidth: '500px'}}>
          Регистрируя родком, вы становитесь его главой. Деятельность родкома регламентируется “Договором о совместной
          деятельности”.
        </StyledAlert>
      </Box>
      <SubmitBlock isLoading={isLoading} />
    </Box>
  )
}

export default CreatingRodcomForm
