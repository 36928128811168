import {getFormattedDateWithHyphen} from '../../utils/getFormattedDate'

const getChildForClient = (child) => {
  const {uuid, fullName, birthday, avatarUrl, kinshipUuid, genderUuid} = child
  return {
    avatarUrl,
    birthday: getFormattedDateWithHyphen(new Date(birthday)),
    id: uuid,
    firstName: fullName?.firstName || '',
    lastName: fullName?.surname || '',
    attitudeWithChild: kinshipUuid,
    gender: genderUuid
  }
}

export const getChildrenMapper = {
  toClient: (response) => {
    const {event, payload} = response
    const transformedChildren = payload?.map((item) => getChildForClient(item)) || []
    return {
      event,
      payload: transformedChildren
    }
  }
}

export const childMapper = {
  toClient: (response) => {
    const {event, payload} = response
    return {
      event,
      payload: getChildForClient(payload)
    }
  },
  toServer: (data) => {
    const transformedData = {}
    const {id, firstName, lastName, birthday, attitudeWithChild, avatarUrl, gender} = data
    transformedData.uuid = id
    transformedData.fullName = {
      firstName: firstName.trim(),
      surname: lastName.trim(),
      patronymic: ''
    }
    if (avatarUrl && !avatarUrl.startsWith('data')) {
      transformedData.photoId = avatarUrl.replace('v1/i/avatars/', '').replace('.jpg', '')
    }
    transformedData.birthday = getFormattedDateWithHyphen(new Date(birthday))
    transformedData.kinshipUuid = attitudeWithChild
    transformedData.genderUuid = gender
    return transformedData
  }
}
