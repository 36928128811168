import {styled} from '@mui/material/styles'

export const EmptyListWrapper = styled('div')(({theme}) => ({
  background: 'white',
  border: '1px solid #EDEDFF',
  color: theme.palette.primary.main,
  height: '150px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  gap: '24px',
  padding: '19px',
  borderRadius: 12,
  h2: {
    fontSize: 18,
    lineHeight: '28px',
    fontWeight: 700
  },
  [theme.breakpoints.up('md')]: {
    gap: '24px',
    height: '200px',
    borderRadius: 12,
    h2: {
      fontSize: 24,
      lineHeight: '100%'
    }
  }
}))

export const WrapperButton = styled('div')(({theme}) => ({
  width: '100%'
  // button: {
  //   fontSize: 16,
  //   color: '#39215E',
  //   background: 'rgba(57, 33, 94, 0.12)',
  //   border: '1px solid rgba(255, 255, 255, 1e-05)',
  //   svg: {
  //     path: {
  //       stroke: '#39215E'
  //     }
  //   },
  //   '&:hover': {
  //     background: 'rgba(57, 33, 94, 0.24)',
  //     border: '1px solid rgba(255, 255, 255, 1e-05)'
  //   },
  //   '&:focused': {
  //     border: '1px solid rgba(255, 255, 255, 1e-05)'
  //   },
  //   '&:active': {
  //     boxShadow:
  //       ' 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
  //   }
  // }
}))
