import {styled} from '@mui/material/styles'

export const Wrapper = styled('div')(({theme}) => ({
  // padding: 24,
  // marginTop: 32,
  background: '#FFFFFF',
  borderRadius: 12,
  // boxShadow: '0px 12px 48px rgba(57, 33, 94, 0.06), 0px 2.4px 4px rgba(57, 33, 94, 0.04)',
  // border: '1px solid #EDEDFF',
  display: 'flex',
  flexDirection: 'column',
  // maxWidth: '640px',
  boxSizing: 'border-box',
  '.checkbox': {
    color: 'rgba(0, 0, 0, 0.12);'
  },
  '& > div .checkbox': {
    marginLeft: '-9px'
  },
  '.checkboxChecked': {
    color: theme.palette.primary.main
  },
  '& svg': {
    fontSize: '22px'
  },
  '& .chipClass': {
    marginLeft: 'auto'
  },
  '& .parentCheckbox': {
    paddingLeft: '6px',
    color: theme.palette.primary.main
  },
  '& .editableTitle': {
    marginBottom: 48
  },
  '& .title': {
    marginBottom: 38
  },
  '& .showMoreButton': {
    marginTop: '20px',
    width: 'fit-content',
    alignSelf: 'center',
    border: 0,
    fontSize: 14,
    fontWeight: 700,
    svg: {
      width: 20,
      height: 20
    },
    '&:hover': {
      border: 0
    }
  },
  [theme.breakpoints.down(1224)]: {
    // margin: '38px 19px 0'
  },
  [theme.breakpoints.down('md')]: {
    margin: '19px 0 0',
    padding: 19,
    borderRadius: 0,
    '& .title, & .editableTitle': {
      marginBottom: 13
    }
  }
}))
