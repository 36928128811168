import {styled} from '@mui/material/styles'

export const WrapperForm = styled('form')({
  width: '100%',
  margin: 0,
  '.MuiOutlinedInput-notchedOutline': {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '6px'
  },
  h4: {
    fontSize: 16,
    lineHeight: '24px',
    marginBottom: 4
  },
  '.fieldsWrapper': {
    display: 'flex',
    justifyContent: 'space-between'
  },
  '.MuiOutlinedInput-root, .MuiOutlinedInput-input': {
    textAlign: 'center',
    letterSpacing: 0
  },
  '.button': {
    marginTop: '32px',
    fontSize: 16
  },
  '.arrowRight': {
    marginLeft: '14px'
  },
  '.sendAgain': {
    fontSize: 16,
    lineHeight: '16px',
    fontWeight: 500,
    color: '#0000008F',
    marginTop: 24
  },
  '.sendAgainActive': {
    cursor: 'pointer'
  }
})
