import React from 'react'

import {styled} from '@mui/material/styles'
import {IconButton} from '@mui/material'

import {ChevronLeftIcon, ChevronRightIcon} from '../../../../../icons'

export const StyledArrowButton = styled(IconButton)(({theme}) => ({
  position: 'absolute',
  zIndex: 1,
  width: 29,
  height: 29,
  borderRadius: 6,
  background: '#DDDDDD',
  top: '-56px',
  '&.centerSpaceBetween': {
    top: '50%',
    transform: 'translateY(-50%)'
  },
  '&:hover': {
    opacity: '0.7'
  },
  '&.Mui-disabled': {
    background: '#DDDDDD',
    opacity: '0.4'
  }
  // [theme.breakpoints.down(480)]: {
  //   display: 'none'
  // }
}))

export const StyledRightArrowButton = styled(StyledArrowButton)(() => ({
  right: '0',
  '&.centerSpaceBetween': {
    right: '16px'
  }
}))

export const StyledLeftArrowButton = styled(StyledArrowButton)(() => ({
  right: '35px',
  '&.centerSpaceBetween': {
    right: 'auto',
    left: '16px'
  }
}))

export const NextArrow = ({onClick, className, myClassName}) => (
  <StyledRightArrowButton
    disabled={className?.includes('slick-disabled')}
    className={myClassName}
    onClick={onClick}
    variant="outlined"
  >
    <ChevronRightIcon />
  </StyledRightArrowButton>
)

export const PrevArrow = ({onClick, className, myClassName}) => (
  <StyledLeftArrowButton
    disabled={className?.includes('slick-disabled')}
    className={myClassName}
    onClick={onClick}
    variant="outlined"
  >
    <ChevronLeftIcon />
  </StyledLeftArrowButton>
)
