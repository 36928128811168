import {styled} from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import {width} from '@mui/system'

export const Wrapper = styled('div')(({theme}) => ({
  padding: '32px',
  // boxShadow: '0px 12px 12px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  border: '1px solid #EDEDFF',
  borderRadius: 12,

  [theme.breakpoints.up('md')]: {
    width: '100%',
    padding: '32px',
    // boxShadow: '0px 12px 12px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
    borderRadius: 12
  },
  '.MuiFormControl-root': {
    marginBottom: 22
  },
  '& .joining-phone .MuiInputBase-root': {
    border: '2px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '6px'
  },
  '& .joining-phone  fieldset': {
    border: 'none'
  },
  '& .joining-phone': {
    maxWidth: '500px'
  },
  '& .joining-phone  input': {
    padding: '11px'
  }
}))

export const Title = styled(Typography)(({theme}) => ({
  marginBottom: '24px',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '28px',
  letterSpacing: 0,
  textAlign: 'left',
  color: theme.palette.primary.main,
  '@media (min-width: 600px)': {
    fontSize: '24px',
    lineHeight: '36px'
  }
}))
