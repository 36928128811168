import React, {useState, useEffect} from 'react'

import SmsForm from 'pages/LoginPage/components/SmsForm/SmsForm'
import {useDispatch, useSelector} from 'react-redux'

const TIME = 59

/**
 * @param {*} children
 * @param {*} load_selector -- for loading indicator
 * @param {*} event -- redux event for sendCode
 * @returns форма контейнер с подтверждением по смс
 */
const ConfirmForm = ({children, loading, event, data, showConfirm, handleClose}) => {
  const [verificationCode, setVerificationCode] = useState(['', '', '', ''])
  const [seconds, setSeconds] = useState(TIME)
  const dispatch = useDispatch()

  const sendCode = () => {
    dispatch(event.action(data))
  }

  const onSmsNumberSubmit = () => {
    // handleClose()
    dispatch(event.action({...data, code: verificationCode.join('')}))
  }

  const onBackClick = () => {
    if (showConfirm) {
      /* empty */
    }
  }

  return (
    <>
      {showConfirm ? (
        <SmsForm
          seconds={seconds}
          setSeconds={setSeconds}
          setCode={setVerificationCode}
          code={verificationCode}
          getRegistration={onSmsNumberSubmit}
          btnText="Продолжить"
          loading={loading}
          onSendAgain={sendCode}
          prevent={true}
        />
      ) : (
        children
      )}
    </>
  )
}
export default ConfirmForm
