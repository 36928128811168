import {styled} from '@mui/material/styles'

export const EditProjectWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 32,
  // gap: '24px',
  '.left': {
    width: '50%'
  },
  '.right': {
    width: '50%',
    gap: '24px',
    '.piggy': {
      width: '100%'
    }
  },
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'column'
  },
  '.members': {
    marginTop: 0,
    padding: 19,
    border: '1px solid #EDEDFF'
  },
  '.form': {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px'
  },
  '.finaly': {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyConenten: 'space-between',
    gap: 24
  },
  [theme.breakpoints.down(1000)]: {
    '.form': {
      flexDirection: 'column'
    },
    '.left': {
      width: '100%'
    },
    '.right': {
      width: '100%',
      gap: '24px'
    },
    '.finaly': {
      flexDirection: 'column'
    }
  }
}))
