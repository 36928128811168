import httpClient from './network'

const ctrlName = 'giveouts'

export const getDebtSummaryRequest = (uuid) => {
  return httpClient.get(`${ctrlName}/summary_for/${uuid}`)
}

export const takeGiveoutRequest = ({uuid, amount, description, code}) => {
  return httpClient.post(`${ctrlName}`, {projectUuid: uuid, amount, target: description, code})
}

export const checkGiveoutRequest = (uuid) => {
  return httpClient.get(`${ctrlName}/${uuid}`)
}

export const sendReportRequest = ({uuid, images, amount, description}) => {
  return httpClient.post(`expenses/${uuid}`, {imageUuids: images, amount, comment: description})
}
