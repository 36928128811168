import httpClient from './network'

export const getProjectsByRK = (uuid) => {
  return httpClient.get(`projects/find-by-rk/${uuid}`)
}

export const createProject = (data) => {
  return httpClient.post(`projects`, data)
}

export const activateProject = (uuid) => {
  return httpClient.put(`projects/${uuid}/activate`)
}

export const getProject = (uuid) => {
  return httpClient.get(`projects/${uuid}`)
}

export const getProjectStatus = (uuid) => {
  return httpClient.get(`projects/${uuid}/status`)
}

export const updateProject = (uuid, data) => {
  return httpClient.put(`projects/${uuid}`, data)
}

export const joinProject = (uuid) => {
  return httpClient.put(`projects/${uuid}/join`)
}

export const leaveProject = (uuid) => {
  return httpClient.put(`projects/${uuid}/leave`)
}

export const deleteProject = (uuid) => {
  return httpClient.delete(`projects/${uuid}`)
}

export const archivateProject = (uuid) => {
  return httpClient.put(`projects/${uuid}/close`)
}

export const setLogoProject = (uuid, avatar) => {
  const data = {
    name: 'logo.jpg',
    contentType: 'image/jpeg',
    body: avatar.replace(/^data:image\/[a-z]+;base64,/, '')
  }
  return httpClient.post(`projects/${uuid}/logo`, data)
}

export const addImageToProject = (uuid, avatar) => {
  const data = {
    name: 'image.jpg',
    contentType: 'image/jpeg',
    body: avatar.replace(/^data:image\/[a-z]+;base64,/, '')
  }
  return httpClient.post(`projects/${uuid}/photo`, data)
}

export const removeImageFromProject = (uuid, uuidImage) => {
  return httpClient.delete(`projects/${uuid}/photo?photoUuid=${uuidImage}`)
}

export const getProjectUsers = (uuid) => {
  return httpClient.get(`projects/${uuid}/users`)
}

export const setProjectUsers = (uuid, data) => {
  return httpClient.put(`projects/${uuid}/users`, data)
}

export const transferToProject = (data) => {
  return httpClient.post(`transfers/toProject`, data)
}

export const transferToPiggy = (data) => {
  return httpClient.post(`transfers/toPiggy`, data)
}

export const transferToCard = (data) => {
  return httpClient.post(`transfers/toCard`, data)
}
