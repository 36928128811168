import React, {useState, useMemo, useEffect, useCallback} from 'react'
import {styled} from '@mui/material/styles'
import {debounce} from 'lodash'
import {Box} from '@mui/material'

import {FundraisingInput} from '../../../../../../../components/Inputs/FundraisingInput/FundraisingInput'

const CalculatorLine = styled(Box)(({theme}) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 6
}))

const OrDivider = styled(Box)(({theme}) => ({
  marginLeft: 12,
  marginRight: 12,
  marginTop: 60,
  fontSize: 16
}))

const AmountCalculator = ({
  amount,
  periodsNumber,
  setAmount,
  memberCount,
  disabled,
  errors,
  setError,
  members,
  projectMembers
}) => {
  const [perMemberError, setPerMemberError] = useState('')
  const [totalError, setTotalError] = useState('')
  const [childrenCount, setChildrenCount] = useState(0)

  useEffect(() => {
    members &&
      setChildrenCount(
        members
          .filter((item) => [item]?.some((i) => projectMembers.includes(i.userUuid)))
          .reduce((a, b) => a + (b['children'].length || 0), 0)
      )
  }, [members, projectMembers])

  const [total, setTotal] = useState('')

  const handlePerMember = useCallback(
    (v) => {
      setAmount(v)
      setTotal(Number(v) * childrenCount)
    },
    [childrenCount]
  )

  const handleTotal = (v) => {
    setAmount(Math.ceil(Number(v) / Number(childrenCount)))
  }

  const debouncedChangeHandler = useCallback(debounce(handleTotal, 600), [childrenCount])

  useEffect(() => {
    setTotal(amount * childrenCount)
  }, [childrenCount, amount])

  // useEffect(() => {
  //   if (total === Infinity || amount === Infinity) {
  //     // setTotal('')
  //     setAmount('')
  //   }
  // }, [total, amount])

  return (
    <CalculatorLine id="moneyPerMember">
      <FundraisingInput
        className="fundraising-input"
        id="per-member-input"
        value={amount}
        error={errors?.moneyPerMember}
        handleChange={handlePerMember}
        clearError={() => setError(null)}
        label={periodsNumber > 1 ? 'С каждого в месяц' : 'С каждого'}
        placeholder="Сумма"
        disabled={!childrenCount || disabled}
      />
      <OrDivider>или</OrDivider>
      <FundraisingInput
        className="fundraising-input"
        id="total-input"
        value={total}
        error={errors?.moneyPerMember}
        handleChange={(e) => {
          debouncedChangeHandler(e), setTotal(e)
        }}
        clearError={setTotalError}
        label={periodsNumber > 1 ? 'Всего в месяц' : 'Всего'}
        placeholder="Сумма"
        disabled={!childrenCount || disabled}
      />
    </CalculatorLine>
  )
}

export default AmountCalculator
