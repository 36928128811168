import React from 'react'

import ArrowBack from '@mui/icons-material/ArrowBack'
import {styled} from '@mui/material/styles'
import {Typography} from '@mui/material'

export const Title = styled(Typography)(({theme}) => ({
  fontSize: '32px',
  lineHeight: '32px',
  fontStyle: 'normal',
  fontWeight: 700,
  width: '100%',
  color: theme.palette.primary.main,
  [theme.breakpoints.up('md')]: {
    fontSize: '32px',
    lineHeight: '56px',
    fontWeight: 700
  }
}))

export const MediumTitle = styled(Typography)(({theme}) => ({
  fontSize: '32px',
  lineHeight: '32px',
  fontStyle: 'normal',
  fontWeight: 400,
  color: theme.palette.primary.main,
  [theme.breakpoints.up('md')]: {
    fontSize: '48px',
    lineHeight: '56px',
    fontWeight: 400
  }
}))

export const GoBackWrapper = styled('div')`
  margin: 0 0 12px 0;
  display: flex;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  cursor: pointer;
  align-items: center;
  @media (max-width: 900px) {
    margin: 0px 0 12px 0;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }
`

export const GoBackText = styled('div')(({theme}) => ({
  textDecoration: 'auto',
  alignSelf: 'center',
  fontSize: '18px',
  lineHeight: '24px',
  color: theme.palette.primary.main,
  margin: '0 6px',
  [theme.breakpoints.down('md')]: {
    fontSize: '18px'
  }
}))

export const DescriptionText = styled(Typography)(({theme}) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.25px',
  color: theme.palette.primary.main,
  margin: '8px 0 0 0',
  [theme.breakpoints.down('md')]: {
    fontSize: '14px'
  }
}))

export const Wrapper = styled('div')(({theme}) => ({
  marginBottom: '24px',
  // maxWidth: '640px',
  [theme.breakpoints.up('md')]: {
    marginBottom: '24px'
  }
}))

export const Icon = styled(ArrowBack)(({theme}) => ({
  color: theme.palette.primary.main,
  fontSize: '17px'
}))

export const HeaderWithBackButton = ({onClick, linkText, title, description, descriptionEl, mediumText}) => (
  <Wrapper>
    {linkText ? (
      <GoBackWrapper onClick={onClick}>
        <Icon />
        <GoBackText>{linkText}</GoBackText>
      </GoBackWrapper>
    ) : null}
    {mediumText ? <MediumTitle>{title}</MediumTitle> : <Title>{title}</Title>}
    {description && <DescriptionText>{description}</DescriptionText>}
    {descriptionEl}
  </Wrapper>
)
