import React from 'react'

import {Checkbox, FormControlLabel, FormGroup, FormHelperText} from '@mui/material'
import {styled} from '@mui/material/styles'

const Underline = styled('a')(({theme}) => ({
  textDecoration: 'underline',
  color: theme.palette.primary.main
}))

export const FormControlLabelWrapper = styled(FormControlLabel)(({theme}) => ({
  alignItems: 'center !important',
  color: theme.palette.primary.main
}))

const ErrorText = styled(FormHelperText)`
  margin-left: 24px;
`
// todo ссылку на пользовательское соглашение
const UserAgreement = ({userAgreement, handleUserAgreement, isError, className = ''}) => (
  <FormGroup className={className}>
    <FormControlLabelWrapper
      control={<Checkbox checked={userAgreement} onChange={handleUserAgreement} />}
      label={
        <div>
          <span style={{fontSize: 16}}>Принимаю условия </span>
          <Underline
            style={{fontSize: 16}}
            href="https://storage.yandexcloud.net/bucket576787/docs/01%20%D0%9F%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D0%BA%D0%BE%D0%B5%20%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%A0%D0%BE%D0%B4%D0%BA%D0%BE%D0%BC.docx%20(1).pdf"
            target="_blank"
          >
            Пользовательского соглашения
          </Underline>
        </div>
      }
    />
    {isError && <ErrorText error>Обязательное поле</ErrorText>}
  </FormGroup>
)

export default UserAgreement
