import httpClient from './network'

export const createRkRequest = (data) => {
  return httpClient.post('/rk', data)
}

export const joinChildrenToRkRequest = (rkId, children) => {
  return httpClient.post(`/rk/${rkId}/join-children`, children)
}

export const joiningToRk = (uuid, role, children) => {
  return httpClient.post(`/rk/${uuid}/join/${role}`, children)
}

export const forgettingRk = (uuid) => {
  return httpClient.post(`/rk/${uuid}/forget`)
}

export const refChildReq = (rkId, child) => {
  return httpClient.post(`/rk/${rkId}/ref-child`, child)
}

export const updateChildRecordReq = (child) => {
  return httpClient.put(`/rk/ref-child`, child)
}

export const deleteChildRecordReq = (childUuid) => {
  return httpClient.delete(`/rk/ref-child/${childUuid}`)
}

export const getMyRodcomsRequest = () => {
  return httpClient.get('/rk/my')
}

export const getParentCommitteesRequest = (data) => {
  const {City, ClassUuid, GroupName, YearOfAdmission, ClassLetterUuid, InstitutionType, InstitutionName} = data
  let params = ''
  if (City) {
    params += `City=${City}&`
  }
  if (ClassUuid) {
    params += `ClassUuid=${ClassUuid}&`
  }
  if (GroupName) {
    params += `GroupName=${GroupName}&`
  }
  if (InstitutionType) {
    params += `InstitutionType=${InstitutionType}&`
  }
  if (InstitutionName) {
    params += `InstitutionName=${InstitutionName}&`
  }
  if (ClassLetterUuid) {
    params += `ClassLetterUuid=${ClassLetterUuid}&`
  }
  if (YearOfAdmission) {
    params += `YearOfAdmission=${YearOfAdmission}&`
  }
  return httpClient.get(`/rk/find${params ? `?${params}` : ''}`)
}
