import React from 'react'
import {styled} from '@mui/material/styles'
import {Typography} from '@mui/material'

export const StyledWrappedText = styled('div')(({theme}) => ({
  display: 'inline-flex',
  alignItems: 'center',
  padding: '0 8px',
  borderRadius: '6px',
  // height: '19px',
  userSelect: 'none'
}))

const WrappedText = ({icon, text, wrapperStyles, wrapperClassname}) => {
  return (
    <StyledWrappedText className={wrapperClassname} sx={wrapperStyles}>
      {icon}
      <Typography
        variant="h6"
        sx={{
          fontSize: 14
        }}
      >
        {text}
      </Typography>
    </StyledWrappedText>
  )
}

export default WrappedText
