import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {CircularProgress} from '@mui/material'

import {SLIDER_SETTINGS} from '../../constants/summaryConstants'
import {votingSelectors} from '../../../../../../store/votingStore/votingStore'
import VotingItem from '../VotingItem/VotingItem'
import {NextArrow, PrevArrow} from '../../../components/SliderArrows/SliderArrows'
import {StyledSlider} from '../../../components/StyledSlider/StyledSlider'
import {BlocksListWrapper, VotesListWrapper} from '../projects/ProjectsStyle'

const VotingBlock = ({votesList, isSlider = true, sliderSettings}) => {
  const isLoading = useSelector(votingSelectors.isLoading.selector)

  const defaultSettings = useMemo(
    () => ({
      ...SLIDER_SETTINGS,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />
    }),
    []
  )

  const settings = sliderSettings ? sliderSettings : defaultSettings

  const votesListItem = useMemo(() => {
    return votesList.map(({uuid, title, voteAnswers, deadlineAt}) => {
      return <VotingItem key={uuid} uuid={uuid} question={title} voteAnswers={voteAnswers} deadlineAt={deadlineAt} />
    })
  }, [votesList])

  if (isLoading) {
    return <CircularProgress sx={{margin: '0 auto', padding: '120px', display: 'block'}} />
  }

  if (isSlider) {
    return (
      <StyledSlider className="noCenter" {...settings} sx={{paddingButtom: 10}}>
        {votesListItem}
      </StyledSlider>
    )
  }

  return <VotesListWrapper>{votesListItem}</VotesListWrapper>
}

export default VotingBlock
