import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'

import {REDUCERS_NAME} from './constants'

import {initStore} from './initStore/initStore'
import {profileStore} from './profileStore/profileStore'
import {creatingRkStore} from './creatingRkStore/creatingRkStore'
import {usersStore} from './usersStore/usersStore'
import {childrenStore} from './childStore/childStore'
import {directoriesStore} from './directoriesStore/directoriesStore'
import {committeeSettingsStore} from './committeeSettingsStore/committeeSettingsStore'
import {votingStore} from './votingStore/votingStore'
import {projectsStore} from './projectsStore/projectsStore'
import {reportingStore} from './reportingStore/reportingStore'
import {inviteStore} from './inviteStore/inviteStore'
import {accrualStore} from './accrualsStore/accrualStore'
import {giveoutStore} from './giveoutStore/giveoutStore'
const {ROUTER} = REDUCERS_NAME

const createRootStore = (history) =>
  combineReducers({
    [ROUTER]: connectRouter(history),
    ...initStore,
    ...profileStore,
    ...creatingRkStore,
    ...usersStore,
    ...childrenStore,
    ...directoriesStore,
    ...committeeSettingsStore,
    ...votingStore,
    ...projectsStore,
    ...reportingStore,
    ...inviteStore,
    ...accrualStore,
    ...giveoutStore
  })

export default createRootStore
