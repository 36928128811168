import React, {useMemo, useEffect, useState} from 'react'
import StyledAlert from '../../../../components/StyledAlert/StyledAlert'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import StyledSelect from '../../../../components/Inputs/StyledSelect/StyledSelect'
import TextInput from '../../../../components/Inputs/TextInput/TextInput'
import {createYearArray} from '../../utils/creatingRodcomUtils'
import {checkNoHtml} from '../../../../utils/validations'
import {getOptionsFromDirectories} from '../../../../utils/getOptionsFromDirectories'
import {Avatar, Checkbox, FormLabel, Box} from '@mui/material'
import {styled} from '@mui/material/styles'

const FormLabelWrapper = styled(FormLabel)(({theme}) => ({
  color: theme.palette.primary.main
}))
const GradeCheckbox = ({checked, setChecked, name, className}) => {
  const onClick = () => {
    setChecked(!checked)
  }

  return (
    <Box className={className} onClick={onClick}>
      <Checkbox
        checked={checked}
        classes={{
          root: 'checkbox',
          checked: 'checkboxChecked'
        }}
      />
      <FormLabelWrapper classes={{root: 'label'}}>{name}</FormLabelWrapper>
    </Box>
  )
}

const SchoolForm = ({
  errors,
  fieldValues,
  onChangeValue,
  classes,
  classLetter,
  withYearOfAdmission = true,
  chatSecondLabel = '',
  isFormDisabled = false,
  setSchoolErrorField
}) => {
  const yearOptions = useMemo(() => createYearArray(), [])
  const gradeOptions = useMemo(() => getOptionsFromDirectories(classes), [classes])
  const letterOptions = useMemo(() => getOptionsFromDirectories(classLetter), [classLetter])
  const filteredGradeOptions = useMemo(() => {
    return getGradeOptions(new Date(), fieldValues.yearOfAdmission, classes)
  }, [fieldValues.yearOfAdmission, classes])
  const onEducatorChange = (value) => {
    if (/^[а-яА-ЯЁё\s-]*$/i.test(value)) {
      onChangeValue(value, 'educator')
    }
  }

  function isDateAfterClassChange(currentDate) {
    let oneJune = new Date(currentDate.getFullYear(), 8, 15)
    return currentDate > oneJune
  }

  function getGradeOptions(currentDate, yearOfAdmission, options) {
    if (!withYearOfAdmission) {
      return gradeOptions
    }
    if (!options || !yearOfAdmission) {
      return []
    }
    let currentYear = currentDate.getFullYear()
    let classNumber, classNumbers
    // if (isDateAfterClassChange(currentDate)) {
    //   classNumber = currentYear - yearOfAdmission + 1
    // } else {
    //   classNumber = currentYear - yearOfAdmission + 1
    // }
    classNumber = currentYear - yearOfAdmission + 1

    let o = gradeOptions.find((x) => x.label == classNumber)
    let o2 = gradeOptions.find((x) => x.label == classNumber + 1)
    classNumbers = classNumber > 3 && classNumber < 12 ? [o, o2] : [o]

    return classNumbers
  }

  return (
    <>
      <div className="institutionInfo">
        {withYearOfAdmission && (
          <StyledSelect
            disabled={isFormDisabled}
            error={Boolean(errors.yearOfAdmission)}
            errorMsg={errors.yearOfAdmission}
            id="yearOfAdmission"
            label="Год поступления"
            value={fieldValues.yearOfAdmission}
            onChangeValue={(value) => onChangeValue(value, 'yearOfAdmission')}
            helpText="Выберите"
            options={yearOptions}
            className="select"
          />
        )}
        <StyledSelect
          disabled={isFormDisabled || !fieldValues.yearOfAdmission}
          error={Boolean(errors.grade)}
          errorMsg={errors.grade}
          id="grade"
          label="Класс"
          value={fieldValues.grade}
          onChangeValue={(value) => onChangeValue(value, 'grade')}
          helpText="Выберите"
          options={filteredGradeOptions}
          className="select settingInp"
        />
        <StyledSelect
          disabled={isFormDisabled}
          error={Boolean(errors.letter)}
          errorMsg={errors.grade}
          id="letter"
          label="Буква"
          value={fieldValues.letter}
          onChangeValue={(value) => onChangeValue(value, 'letter')}
          helpText="Выберите"
          options={letterOptions}
          className="select settingInp"
        />
      </div>
      {errors.copy && (
        <StyledAlert icon={false} className="error" sx={{marginTop: '24px'}}>
          <div>{errors.copy}</div>
        </StyledAlert>
      )}
      <TextInput
        disabled={isFormDisabled}
        errorMsg={errors.educator}
        label="Классный руководитель"
        id="educator"
        value={fieldValues.educator}
        onChangeValue={onEducatorChange}
        withNotRequiredText
        className="separateInput settingInp creacteForm"
        placeholder="ФИО"
      />
      <TextInput
        disabled={isFormDisabled}
        validator={checkNoHtml}
        errorMsg={errors.name}
        label="Название родкома"
        id="name"
        value={fieldValues.name}
        onChangeValue={(value) => onChangeValue(value, 'name')}
        className="separateInput settingInp"
        withNotRequiredText
        placeholder="Например «Физмат»"
      />
      <TextInput
        disabled={isFormDisabled}
        placeholder="https://"
        errorMsg={errors.chat}
        label="Родительский чат"
        id="chat"
        value={fieldValues.chat}
        onChangeValue={(value) => onChangeValue(value, 'chat')}
        withNotRequiredText
        className="separateInput chat settingInp"
        secondLabel={chatSecondLabel}
      />
    </>
  )
}

export default SchoolForm
