import {alpha} from '@mui/material/styles'

export const generateThemeBase = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 340,
      md: 768,
      lg: 1224
    }
  },
  palette: {
    grey: {
      50: '#F4F4F4',
      f8: '#f8f8f8',
      f0: '#f0f0f0',
      ee: '#eeeeee',
      100: '#E0E0E0',
      200: '#C6C6C6',
      300: '#A8A8A8',
      400: '#8D8D8D',
      500: '#6F6F6F',
      600: '#525252',
      700: '#393939',
      800: '#262626',
      900: '#161616'
    },
    primary: {
      light: '#783CB9',
      main: '#39215E',
      dark: '#18161D',
      action: '#54278F',
      contrastText: '#F7F2FF'
    },
    secondary: {
      light: '#FFF5C2',
      main: '#ffdd44',
      dark: '#DDAA01',
      contrastText: '#1A1614'
    },
    error: {
      main: '#E41D3D',
      secondary: '#FFECEC',
      contrastText: '#fff'
    },
    info: {
      main: '#0288D1',
      contrastText: '#fff'
    },
    success: {
      main: '#4b894e',
      secondary: '#CEFAD5',
      contrastText: '#fff'
    },
    warning: {
      main: '#FF580A',
      contrastText: '#fff'
    },
    warningLight: {
      main: '#FAEBCE',
      contrastText: '#442D0C'
    },
    background: {
      default: '#f5f5f5',
      paper: '#fff'
    },
    blue: {
      main: '#DFF6FD',
      contrastText: '#1E4569'
    }
  },
  spacing: 4,
  page: {
    width: 1224
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    h1: {
      fontSize: '16px',
      fontWeight: 400,
      letterSpacing: 'normal',
      lineHeight: '22px',
      textTransform: 'uppercase'
    },
    modal_header: {
      fontSize: '16px',
      letterSpacing: 'normal',
      lineHeight: '24px',
      textAlign: 'center'
    },
    tooltip: {
      fontSize: '16px',
      letterSpacing: 'normal',
      lineHeight: '22px',
      textAlign: 'left'
    },
    bolded: {
      fontSize: '16px',
      fontWeight: 700,
      letterSpacing: 'normal',
      lineHeight: '24px'
    },
    h2: {
      fontSize: '16px',
      fontWeight: 500,
      letterSpacing: '0.25px',
      lineHeight: '19px'
    },
    h3: {
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.25px',
      lineHeight: '19px'
    },
    h4: {
      fontSize: '13px',
      fontWeight: 400,
      letterSpacing: '0.15px',
      lineHeight: '19px'
    },
    h5: {
      fontSize: '11px',
      fontWeight: 500,
      letterSpacing: '0.1px',
      lineHeight: '19px'
    },
    h6: {
      fontSize: '11px',
      fontWeight: 400,
      letterSpacing: 'normal',
      lineHeight: '16px'
    },
    subtitle1: {
      fontSize: '13px',
      fontWeight: 500,
      letterSpacing: '0.00938em',
      lineHeight: '16px'
    },
    subtitle2: {
      fontSize: '11px',
      fontWeight: 500,
      letterSpacing: '0.00714em',
      lineHeight: '13px'
    },
    body1: {
      fontSize: '13px',
      fontWeight: 400,
      letterSpacing: '0.5px',
      lineHeight: '22px'
    },
    body2: {
      fontSize: '11px',
      fontWeight: 400,
      letterSpacing: '0.25px',
      lineHeight: '16px'
    },
    button: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '19px',
      letterSpacing: '0.25px',
      textTransform: 'none'
    },
    caption: {
      fontSize: '10px',
      fontWeight: 400,
      letterSpacing: '0.25px',
      lineHeight: '13px'
    },
    caption2: {
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: '0.25px',
      lineHeight: '16px',
      color: '#222222'
    },

    overline: {
      fontSize: '10px',
      fontWeight: 500,
      letterSpacing: '1px',
      lineHeight: '13px'
    },
    cell: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '24px',
      fontFeatureSettings: "'tnum' on, 'lnum' on",
      color: '#160038'
    },
    table_header: {
      lineHeight: '16px',
      // letterSpacing: '0.5px',
      // textTransform: 'uppercase',
      fontSize: '13px !important',
      color: '#39215E important',
      fontWeight: 500
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          padding: '10px 19px',
          borderRadius: '13px',
          textTransform: 'none',
          fontSize: '16px',
          lineHeight: '19px',
          letterSpacing: '0.25px',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
            background: 'inherit'
          },
          '& .MuiButton-startIcon': {
            marginLeft: '-6px',
            marginRight: '10px'
          }
        },
        text: {
          padding: '6px 13px',
          fontSize: '13px',
          lineHeight: '19px',
          letterSpacing: '0.25px',
          '&:hover': {
            textDecoration: 'underline',
            background: 'none'
          }
        },
        // @ts-ignore
        containedPrimary: ({theme}) => ({
          fontSize: 14,
          fontWeight: 700,
          minHeight: 46,
          maxHeight: 46,
          borderRadius: 14,
          padding: '10px 19px',
          color: '#F7F2FF',
          background: 'linear-gradient(180deg, #794DBD 0%, #57338D 100%)',
          boxShadow:
            '0px 12px 20px rgba(87, 51, 141, 0.0628148), 0px 4.62222px 6.37037px rgba(87, 51, 141, 0.0971852), 0px 0.977778px 1.62963px rgba(87, 51, 141, 0.16)',
          border: 'none',
          // transition: '0.2s linear',
          '&:hover': {
            background: 'linear-gradient(180deg, #57338D 0%, #794DBD 100%)',
            border: 'none'
          },
          '&:focused': {
            background: 'linear-gradient(180deg, #57338D 0%, #794DBD 100%)',
            border: 'none'
          },
          '&:active': {
            background: 'linear-gradient(180deg, #553685 1000%, #553685 100%)'
          },
          svg: {
            path: {
              stroke: '#F7F2FF !important'
            }
          },
          '&:disabled': {
            background: '#E0E0E0',
            color: 'black',
            svg: {
              path: {
                stroke: 'black !important'
              }
            }
          },
          '&.MuiLoadingButton-loading': {
            background: 'linear-gradient(180deg, #553685 1000%, #553685 100%)',
            color: '#F7F2FF',
            border: 'none',
            svg: {
              circle: {
                stroke: '#F7F2FF !important'
              }
            }
          }
        }),
        // @ts-ignore
        containedSecondary: ({theme}) => ({
          fontSize: 14,
          fontWeight: 700,
          minHeight: 46,
          maxHeight: 46,
          borderRadius: 14,
          padding: '10px 19px',
          color: '#39215E',
          background: '#E5E5FF',
          boxShadow:
            '0px 12px 20px rgba(87, 51, 141, 0.0628148), 0px 4.62222px 6.37037px rgba(87, 51, 141, 0.0971852), 0px 0.977778px 1.62963px rgba(87, 51, 141, 0.16)',
          border: '1px solid rgba(255, 255, 255, 1e-05)',
          // transition: '0.2s linear',
          '&:hover': {
            background: '#D0D0F8',
            border: '1px solid rgba(255, 255, 255, 1e-05)'
          },
          '&:focused': {
            background: '#B3B3ED',
            border: 'none'
          },
          '&:active': {
            background: '#B3B3ED'
          },
          svg: {
            path: {
              stroke: '#39215E !important'
            }
          },
          '&:disabled': {
            background: '#E0E0E0',
            color: 'black',
            svg: {
              path: {
                stroke: 'black !important'
              }
            }
          }
        }),
        containedInfo: ({theme}) => ({
          fontSize: 14,
          fontWeight: 700,
          minHeight: 46,
          maxHeight: 46,
          borderRadius: 14,
          padding: '10px 19px',
          color: '#39215E',
          background: 'transparent',
          // boxShadow:
          //   '0px 12px 20px rgba(87, 51, 141, 0.0628148), 0px 4.62222px 6.37037px rgba(87, 51, 141, 0.0971852), 0px 0.977778px 1.62963px rgba(87, 51, 141, 0.16)',
          border: '2px solid #E6E6FF',
          // transition: '0.2s linear',
          '&:hover': {
            // background: '#D0D0F8',
            border: '2px solid #D0D0F8'
          },
          '&:focused': {
            // background: '#B3B3ED',
            border: '2px solid #B3B3ED'
          },
          '&:active': {
            // background: '#B3B3ED'
            border: '2px solid #B3B3ED'
          },
          svg: {
            path: {
              stroke: '#39215E !important'
            }
          },
          '&:disabled': {
            background: '#E0E0E0',
            color: 'black',
            svg: {
              path: {
                stroke: 'black !important'
              }
            }
          }
        }),
        startIcon: {
          marginRight: '11px',
          marginLeft: '-6px'
        },
        endIcon: {
          marginLeft: '3px'
        }
      }
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          'label + &': {
            marginTop: '26px'
          }
        },
        input: {
          borderRadius: '6px',
          border: '2px solid rgba(0, 0, 0, 0.12)',
          fontSize: 16,
          lineHeight: '19px',
          height: '19px',
          padding: '11px',
          letterSpacing: '0.25px',
          background: '#ffffff'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          border: 'none'
        },
        icon: {
          top: 'calc(50% - 10px)'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        // @ts-ignore
        root: ({theme}) => ({
          color: theme.palette.primary.main,
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline'
          }
        })
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: '700',
          fontSize: '19px !important',
          lineHeight: '24px',
          left: '2px',
          top: '4px'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          // maxWidth: '307px',
          display: 'flex',
          marginBottom: '30px'
        }
      }
    },
    MuiFormControlFullWidth: {
      styleOverrides: {
        root: {
          maxWidth: '600'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: '3px',
          letterSpacing: '0.5px'
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          // @ts-ignore
          containedSecondary: ({theme}) => ({
            color: theme.palette.primary.main,
            background: alpha(theme.palette.primary.main, 0.12),
            '&:hover': {
              background: alpha(theme.palette.primary.main, 0.2)
            }
          })
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: ({theme}) => ({
          backgroundColor: theme.palette.success.secondary,
          '& .MuiAlertTitle-root': {
            fontWeight: 700
          }
        })
      }
    }
  }
}
