import {styled} from '@mui/material/styles'
import FormControl from '@mui/material/FormControl'

export const StyledFormControl = styled(FormControl)(() => ({
  position: 'relative',
  maxWidth: '100%',
  ' .errorText': {
    position: 'absolute',
    bottom: '-20px'
  }
}))
