import {lighten, styled} from '@mui/material/styles'
import {Typography} from '@mui/material'

export const StyledVoteOptionWrapper = styled('div')(({theme}) => ({
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: '6px',
  overflow: 'hidden',
  cursor: 'pointer',
  '& .optionMainContent': {
    height: '51px',
    padding: '0 13px',
    transition: '0.3s all ease',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      height: '64px',
      padding: '0 19px'
    },
    '& .optionFill': {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      backgroundColor: theme.palette.primary.contrastText
    },
    '& .optionData': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      position: 'relative',
      zIndex: 1
    },
    '& .optionEmoji': {
      fontSize: '19px',
      [theme.breakpoints.up('md')]: {
        fontSize: '19px'
      }
    },
    '& .optionDataText': {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      flexShrink: 1,
      gap: '19px',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 0
      }
    },
    '& .selectIcon': {
      borderRadius: '50%',
      border: `2px solid ${theme.palette.grey[100]}`,
      width: '19px',
      height: '19px',
      margin: '0 13px 0 0',
      flexGrow: 0,
      flexShrink: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: '0.3s all ease',
      '&:before': {
        content: '""',
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: theme.palette.grey[900],
        transition: '0.3s all ease',
        transform: 'scale(0)'
      }
    }
  },
  '&:not(:first-of-type)': {
    marginTop: '6px'
  },
  '&._answeredByMe': {
    borderColor: theme.palette.primary.main,
    borderWidth: '2px'
  },
  '&._selected': {
    cursor: 'default',
    '& .optionMainContent': {
      backgroundColor: theme.palette.primary.contrastText,
      '& .selectIcon': {
        borderColor: theme.palette.grey[900],
        '&:before': {
          transform: 'scale(1)'
        }
      }
    }
  },
  '&._archived': {
    cursor: 'default'
  }
}))

export const StyledOptionTitle = styled(Typography)(({theme}) => ({
  fontSize: '13px',
  lineHeight: '19px',
  fontStyle: 'normal',
  fontWeight: 320,
  flexGrow: 1,
  flexShrink: 1,
  [theme.breakpoints.up('md')]: {
    fontSize: '19px',
    lineHeight: '26px'
  }
}))

export const StyledSelectedCount = styled(Typography)(({theme}) => ({
  fontSize: '11px',
  lineHeight: '13px',
  fontWeight: 400,
  marginRight: '8px',
  flexGrow: 0,
  flexShrink: 0,
  color: lighten(theme.palette.primary.main, 0.4),
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    lineHeight: '22px'
  }
}))

export const StyledVoterName = styled(Typography)(({theme}) => ({
  fontSize: '11px',
  lineHeight: '13px',
  marginLeft: '10px',
  [theme.breakpoints.up('md')]: {
    fontSize: '13px',
    lineHeight: '19px'
  }
}))

export const StyledVotedList = styled('div')(({theme}) => ({
  padding: '10px',
  [theme.breakpoints.up('md')]: {
    padding: '19px'
  },
  '& .votedListItem': {
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: '6px'
    }
  },
  '& .userImg': {
    width: '29px',
    height: '29px',
    borderRadius: '50%'
  }
}))
