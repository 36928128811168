import {styled} from '@mui/material/styles'

export const Wrapper = styled('div')`
  max-width: 450px;
  margin: 0 auto;
  @media (max-width: 500px) {
    max-width: 100%;
  }
`

export const HeaderLogin = styled('div')`
  width: 100%;
  margin-top: 48px;
  text-align: center;
  a {
    img {
      //height: 80px;
      //width: 245px;
      width: 260px;
    }
  }
  @media (max-width: 500px) {
    a {
      img {
        //height: 48px;
        //width: 145px;
        width: 160px;
      }
    }
  }
`

export const FormWrapper = styled('div')({
  width: '100%',
  padding: '32px',
  marginTop: '32px',
  borderRadius: '16px',
  backgroundColor: '#ffffff',
  boxShadow: '0px 12px 32px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  boxSizing: 'border-box',
  '& .tab': {
    fontSize: 16,
    textTransform: 'none',
    '& .MuiTouchRipple-child': {
      backgroundColor: 'transparent !important'
    }
  },

  '@media(max-width: 500px)': {
    padding: '24px'
  }
})
