import React, {useState, useEffect, useCallback, useMemo} from 'react'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import {Button, CircularProgress} from '@mui/material'
import Typography from '@mui/material/Typography'
import {useDispatch, useSelector} from 'react-redux'
import {format} from 'date-fns'
import {useTheme} from '@mui/material/styles'

import {PlusIcon} from '../../icons'
import {MyData} from './components/MyData/MyData'
import {MyChildren} from './components/MyChildren/MyChildren'
import AvatarUploader from '../../components/AvatarUploader/AvatarUploader'
import {ChildModal} from '../../components/ChildModal/ChildModal'
import EntranceAndSafety from './components/EntranceAndSafety/EntranceAndSafety'
import {
  deleteUserAvatar,
  sendCurrentUserData,
  setStepEvent,
  setUserAvatar
} from '../../store/profileStore/profileStoreSliceEvents'
import {ProfileSection} from './Profile.styles'
import {profileSelectors} from '../../store/profileStore/profileStore'
import {childrenSelectors} from '../../store/childStore/childStore'
import {
  addChild,
  deleteChildEvent,
  setChildNewAvatarUrlEvent,
  updateChildEvent
} from '../../store/childStore/childStoreSliceEvents'
import FillBankModal, {IntroStep, SuccessStep} from '../../components/Payments/FillBankModal'

function TabPanel(props) {
  const {children, value, index, tabClass, ...other} = props

  return (
    <Box
      className={'profile-content ' + tabClass ?? ''}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  )
}

const Profile = () => {
  const dispatch = useDispatch()
  const theme = useTheme()

  const [tabValue, setTabValue] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [userData, setUserData] = useState(null)
  const [openFillBankModal, setOpenFillBankModal] = useState(false)
  const user = useSelector(profileSelectors.user.selector)
  const children = useSelector(childrenSelectors.children.selector)
  const isLoading = useSelector(childrenSelectors.childrenLoading.selector)
  const userBank = useSelector(profileSelectors.user.selector)?.beneficiaryBankName
  const sbpPhone = useSelector(profileSelectors.user.selector)?.sbpPhone
  const hasGrkRole = useSelector(profileSelectors.user.selector)?.hasGrkRole

  const setChildNewAvatarUrl = useCallback(
    (avatarUrl, childUuid) => {
      dispatch(setChildNewAvatarUrlEvent.action(avatarUrl, childUuid))
    },
    [dispatch]
  )

  const createdAccountDate = useMemo(() => {
    if (user?.accountCreateDate) {
      return format(new Date(user.accountCreateDate), 'dd.MM.yyyy')
    }

    return ''
  }, [user])

  useEffect(() => {
    setUserData(user)
  }, [user])

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const handleSubmit = (data) => {
    dispatch(sendCurrentUserData.action({...userData, ...data}))
  }

  const handleSaveAvatar = (avatar) => {
    dispatch(setUserAvatar.action(avatar))
  }

  const handleDeleteAvatar = () => {
    dispatch(deleteUserAvatar.action())
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleSaveChild = useCallback((child, id) => {
    setIsModalOpen(false)
    if (!id) {
      dispatch(addChild.action(child))
    } else {
      dispatch(updateChildEvent.action({...child, id}))
    }
  }, [])

  const handleDeleteChild = useCallback((uuid) => {
    dispatch(deleteChildEvent.action(uuid))
  }, [])

  const setStep = useCallback((step) => {
    dispatch(setStepEvent.action(step))
  })

  const openSbpSetupModal = useCallback(() => {
    setStep('input')
    setOpenFillBankModal(true)
  }, [])
  const closeFillBankModal = useCallback(() => {
    setOpenFillBankModal(false)
  }, [])

  return (
    <>
      <FillBankModal rkId={null} isOpen={openFillBankModal} handleClose={closeFillBankModal} />

      <ProfileSection>
        <Box className="main-info">
          <AvatarUploader avatarUrl={userData?.avatarUrl} onSave={handleSaveAvatar} onDelete={handleDeleteAvatar} />
          <Box className="profile-info">
            {createdAccountDate && (
              <Typography className="create-date text" variant="button">
                Аккаунт создан: {createdAccountDate}
              </Typography>
            )}
            <Typography className="profile-name text">
              {userData?.fullName.firstName} {userData?.fullName.surname}
            </Typography>
          </Box>
        </Box>
        <Box
        // sx={{borderBottom: 1, borderColor: 'divider'}}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            allowScrollButtonsMobile={true}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{style: {background: '#E1E1F2', height: '100%', borderRadius: 12}}}
          >
            <Tab
              className="profile-tab"
              label="Мои дети"
              disableRipple={true}
              style={{zIndex: 1}}
              sx={{
                fontSize: '14px !important',
                fontWeight: '600 !important',
                padding: '6px 12px !important',
                borderRadius: '12px !important'
              }}
            />
            <Tab
              className="profile-tab"
              label="Мои данные"
              disableRipple={true}
              style={{zIndex: 1}}
              sx={{
                fontSize: '14px !important',
                fontWeight: '600 !important',
                padding: '6px 12px!important',
                borderRadius: '12px !important'
              }}
            />
            <Tab
              className="profile-tab"
              label="Вход и безопасность"
              disableRipple={true}
              style={{zIndex: 1}}
              sx={{
                fontSize: '14px !important',
                fontWeight: '600 !important',
                padding: '6px 12px !important',
                borderRadius: '12px !important'
              }}
            />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          {isLoading ? (
            <CircularProgress sx={{margin: '0 auto', padding: 24, display: 'block'}} />
          ) : (
            <MyChildren
              children={children}
              setChildNewAvatarUrl={setChildNewAvatarUrl}
              handleSaveChild={handleSaveChild}
              handleDeleteChild={handleDeleteChild}
            />
          )}
          <Button
            classes={{root: 'profile-button', startIcon: 'profile-button-icon'}}
            variant="contained"
            startIcon={<PlusIcon color={theme.palette.primary.contrastText} />}
            onClick={handleOpenModal}
            sx={{marginTop: '24px'}}
          >
            Добавить ребенка
          </Button>
          {isModalOpen && (
            <ChildModal
              onSave={handleSaveChild}
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              onDelete={handleDeleteChild}
            />
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <MyData user={userData} onSubmit={handleSubmit} />
        </TabPanel>
        <TabPanel value={tabValue} index={2} sx={{background: 'none'}} tabClass="safetytab">
          <Box
            sx={{
              display: 'flex',
              flexDirection: {sm: 'column', lg: 'row'},
              alignItems: 'flex-start',
              gap: '24px !important'
            }}
          >
            <EntranceAndSafety user={userData} onSubmit={handleSubmit} openSbpSetupModal={openSbpSetupModal} />
            {hasGrkRole && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  background: 'white',
                  // marginLeft: '24px',
                  // padding: '24px',
                  // borderRadius: '8px',
                  // width: '400px',
                  width: {sm: '100%', lg: '30%'},
                  boxSizing: 'border-box',
                  alignItems: 'center',
                  gap: '24px !important',
                  maxWidth: '700px !important',
                  // boxShadow: '0px 12px 32px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
                  border: '1px solid #EDEDFF',
                  padding: '38px',
                  borderRadius: '12px'
                }}
              >
                {!(sbpPhone && userBank) ? (
                  <IntroStep setStep={openSbpSetupModal} />
                ) : (
                  <SuccessStep phone={sbpPhone} bank={userBank} />
                )}
              </Box>
            )}
          </Box>
        </TabPanel>
      </ProfileSection>
    </>
  )
}

export default Profile
