import React, {useEffect, useRef, useState} from 'react'
import Typography from '@mui/material/Typography'
import {SearchWrapper} from './SearchPage.styles'

const SearchPage = ({isLogged}) => {
  const widgetWrapper = useRef()

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const script = document.createElement('script')

    script.src = process.env.REACT_APP_WIDGET_JS_URL
    script.async = true
    script.type = 'module'
    if (widgetWrapper && widgetWrapper.current) {
      // noinspection JSUnresolvedFunction
      widgetWrapper.current.appendChild(script)
    }

    return () => {
      // noinspection JSUnresolvedFunction
      widgetWrapper.current?.removeChild(script)
    }
  }, [])

  return (
    <SearchWrapper className="widgetWrapper">
      <Typography textAlign="center" className="widgetHeader" variant="h1">
        Найдите родительский комитет
      </Typography>
      <div ref={widgetWrapper}>
        <div id="search_with_res"></div>
        <link href={`${process.env.REACT_APP_WIDGET_CSS_URL}`} rel="stylesheet" />
      </div>
    </SearchWrapper>
  )
}

export default SearchPage
