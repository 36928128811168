import React, {useCallback, useMemo} from 'react'

import Checkbox from '@mui/material/Checkbox'

import MemberItem from '../../../../Summary/components/MemberItem'
import ChildrenItem from '../../../../Summary/components/ChildrenItem'
import StatusChip from '../../../../Members/components/StatusChip/StatusChip'
import {RK_ROLE} from '../../../../../../JoiningRodcom/constants'
import {CHIP_TYPE} from '../../../../constants/parentCommitteeConstants'
import {ItemWrapper} from './SelectableMemberItemStyle'

const SelectableMemberItem = ({editable, checkedItems, onCheckBoxClick, member, withoutBorder}) => {
  const handleCheckBoxClick = useCallback(() => {
    if (editable) {
      onCheckBoxClick(member.userUuid)
    }
  }, [onCheckBoxClick, member])

  const isChecked = checkedItems.includes(member.userUuid)

  const isChief = useMemo(() => member?.membershipRoles?.some((role) => role.name === RK_ROLE.CHIEF), [member])

  const chipLabel = useMemo(() => (isChief ? 'Глава' : 'Участник'), [isChief])

  const chipType = useMemo(() => (isChief ? `${CHIP_TYPE.APP} chipClass` : 'chipClass'), [isChief])

  const className = useMemo(
    () => `${!editable ? 'notSelectable' : ''} ${withoutBorder ? 'withoutBorder' : ''}`,
    [editable, withoutBorder]
  )

  return (
    <ItemWrapper className={className} onClick={handleCheckBoxClick} key={member.userUuid}>
      {editable && (
        <Checkbox
          checked={isChecked}
          classes={{
            root: 'checkbox',
            checked: 'checkboxChecked'
          }}
        />
      )}
      <MemberItem normalWidth={false} isMembers member={member} isChild key={member.userUuid} isMembersPage={false} />
      {/* <StatusChip label={chipLabel} className={chipType} /> */}
    </ItemWrapper>
  )
}

export default SelectableMemberItem
