import {getFormattedDate} from './getFormattedDate'
import {DATE_ERRORS} from '../components/Inputs/DateInput/DateInput'
//import sanitizeHtml from 'sanitize-html'
export const checkIfPhoneValid = (phone) => phone !== undefined && /^\+\d{11}$/g.test(phone)

export const getPasswordError = (password) => {
  if (!password) {
    return 'Введите пароль'
  }
  if (password.length < 8) {
    return 'Пароль должен быть не менее 8 символов'
  }
  return ''
}

export const getUrlError = (value) => {
  if (!value.trim()) {
    return ''
  }
  const reg =
    /^(ftp|http|https):\/\/((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,}\.?((xn--)?([a-z0-9\-.]{1,61}|[a-z0-9-]{1,30})\.?[a-z]{2,})$/i
  const isVal = reg.test(value)

  const t_link = /^(https?:\/\/)?(www[.])?(telegram|t)\.me\/(\+[a-zA-Z0-9_-]*)\/?$/i
  const isTLinkVal = t_link.test(value)

  const w_link = /^(https?:\/\/)?chat\.whatsapp\.com\/(?:invite\/)?([a-zA-Z0-9_-]{22})$/i
  const isWLinkVal = w_link.test(value)

  if (!isVal && !isTLinkVal && !isWLinkVal) {
    return 'Введите валидную ссылку'
  }
  let url
  try {
    url = new URL(value)
  } catch (_) {
    return 'Введите валидную ссылку'
  }
  const isValid = url.protocol === 'http:' || url.protocol === 'https:'
  return isValid ? '' : 'Введите валидную ссылку'
}

export const getEmptyError = (value) => {
  if (value === 'Invalid Date') {
    return 'Введите валидную дату'
  }
  const isFilled = value && value?.toString()?.trim()
  return isFilled ? '' : 'Заполните поле'
}

export const getLastDate = (value) => {
  if (value === 'Invalid Date') {
    return 'Введите валидную дату'
  }
  const isFilled = new Date(value) > new Date()
  return isFilled ? '' : 'Дата должна быть от от сегодняшнего дня'
}

export const getLengthError = (value, reqLength) => {
  const isLength = value.length >= reqLength && value?.toString()?.trim().length >= reqLength
  return isLength ? null : 'Длина поля должна содержать минимум 5 символов'
}

export const getNameError = (value, errMsg = 'Введите ФИО') => {
  const isFilled = value && value?.trim()?.length > 1
  if (!isFilled) {
    return 'Заполните поле'
  }
  return value.match(/[а-яА-ЯЁё]/) ? '' : errMsg
}

// TODO: NODE verion issue check at home
export const checkNoHtml = (value) => {
  return true
  // return (
  //   value ===
  //   sanitizeHtml(value, {
  //     allowedTags: [],
  //     allowedAttributes: []
  //   })
  // )
}

export const checkLettersNumbersHyphenAndSpace = (value) => /^[а-яА-ЯЁё0-9\s-]*$/i.test(value.trim())
export const checkOnlyLettersAndHyphen = (value) => /^[а-яА-ЯЁё-]*$/i.test(value)
export const checkLettersSpaceAndHyphen = (value) => /^[а-яА-ЯЁё\s-]*$/i.test(value.trim())
export const checkLettersSpaceCommaAndHyphen = (value) => /^[а-яА-ЯЁё,\s-]*$/i.test(value.trim())
export const checkLettersAndOneSpace = (value) => /^([а-яА-ЯЁё-]*)\s?([а-яА-ЯЁё-]*)$/.test(value)
export const checkKidsNames = (value) =>
  /^(\s?([а-яА-ЯЁё-]*)\s?([а-яА-ЯЁё-]*)\s?)([,]\s?([а-яА-ЯЁё-]*)\s?([а-яА-ЯЁё-]*)\s?)*$/i.test(value)

export const checkEmail = (value, errMsg = 'Введите валидный email', isImportant = true) => {
  if (!isImportant && !value.trim()) {
    return ''
  }
  if (
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i.test(
      value.trim()
    )
  ) {
    return ''
  }

  return errMsg
}

export const getDateError = (value, maxDate, minDate) => {
  if (!value || value?.toString() === 'Invalid Date') {
    return 'Введите валидную дату'
  }
  if (maxDate && value > maxDate) {
    return `Введите дату меньше ${getFormattedDate(maxDate)}`
  }
  if (minDate && value < minDate) {
    return `Введите дату больше ${getFormattedDate(minDate)}`
  }
  return ''
}

export const getDateErrorByReason = ({reason, maxDate, minDate}) => {
  const maxDatePrepared = new Date(maxDate)
  const minDatePrepared = new Date(minDate)

  switch (reason) {
    case DATE_ERRORS.maxDate: {
      if (!maxDate) {
        return 'Введите валидную дату'
      }
      const formattedDate = getFormattedDate(maxDatePrepared)
      return `Введите дату меньше ${formattedDate}`
    }
    case DATE_ERRORS.minDate: {
      if (!minDate) {
        return 'Введите валидную дату'
      }
      const formattedDate = getFormattedDate(minDatePrepared)
      return `Введите дату не меньше ${formattedDate}`
    }
    case DATE_ERRORS.invalidDate:
      return 'Введите валидную дату'
    default:
      return ''
  }
}
