import {styled} from '@mui/material/styles'
import {Box, Button, Typography} from '@mui/material'

export const Content = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: '#FFFFFF',
  width: '100%',
  '& p, & h6': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  [theme.breakpoints.down(700)]: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  [theme.breakpoints.down(700)]: {
    '& button': {
      width: '100%',
      marginTop: 12
    }
  }
  // button: {
  //   color: '#39215E',
  //   fontSize: 16,
  //   background: 'rgba(57, 33, 94, 0.12)',
  //   border: '1px solid rgba(255, 255, 255, 1e-05)',
  //   '&:hover': {
  //     background: 'rgba(57, 33, 94, 0.24)',
  //     border: '1px solid rgba(255, 255, 255, 1e-05)'
  //   },
  //   '&:focused': {
  //     border: '1px solid rgba(255, 255, 255, 1e-05)'
  //   },
  //   '&:active': {
  //     boxShadow:
  //       ' 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
  //   }
  // }
}))

export const LeftBlock = styled('div')(({theme}) => ({
  // width: 'calc(100% - 340px)',
  [theme.breakpoints.down(700)]: {
    width: '100%'
  }
}))
export const FlexLineBreak = styled('div')(({theme}) => ({
  flexBasis: '100%',
  height: 0
}))

export const Title = styled(Typography)(({theme}) => ({
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: '29px',
  marginBottom: 6,
  color: theme.palette.primary.main,
  [theme.breakpoints.down(600)]: {
    fontSize: '16px',
    lineHeight: '22px',
    whiteSpace: 'normal'
  }
}))

export const StyledBox = styled(Box)(({theme}) => ({
  display: 'flex',
  h6: {
    fontSize: 16
  },
  [theme.breakpoints.down(1224)]: {
    flexDirection: 'column',
    h6: {
      fontSize: 16
    }
  },
  [theme.breakpoints.down(900)]: {
    h6: {
      fontSize: 16
    }
  }
}))

export const InfoLabel = styled(Typography)(({theme}) => ({
  color: theme.palette.primary.main,
  marginRight: 19,
  [theme.breakpoints.down(1000)]: {
    marginBottom: 6
  }
}))

export const OrangeBlock = styled('div')(({theme}) => ({
  background: '#FFE2D1',
  width: 'fit-content',
  color: '#39215E',
  boxSizing: 'border-box',
  textAlign: 'center',
  borderRadius: '6px',
  padding: '6px 10px',
  marginTop: 12,
  button: {
    margin: '12px 0 0 0 !important',
    padding: '8px 10px'
  },
  'button:first-child': {
    marginRight: '4px !important'
  },
  [theme.breakpoints.down(700)]: {
    width: '100%',
    marginTop: 12,
    'button:first-child': {
      marginRight: '0px !important'
    }
  }
}))
