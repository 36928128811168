import React from 'react'

import {styled} from '@mui/material/styles'
import Button from '@mui/material/Button'
import ArrowBack from '@mui/icons-material/ArrowBack'

const StyledButton = styled(Button)(({theme}) => ({
  color: theme.palette.primary.main,
  marginRight: 'auto',
  width: '100%',
  background: 'transparent !important',
  // padding: '13px 13px',
  fontSize: '16px !important',
  '&:hover': {
    textDecoration: 'none'
  },
  '@media(max-width: 600px)': {
    // padding: '8px',
    lineHeight: '13px',
    '&, .MuiSvgIcon-root': {
      fontSize: '16px'
    }
  }
}))

const ButtonBack = ({className, text = '', onClick, ...props}) => (
  <StyledButton
    onClick={onClick}
    className={className}
    size="small"
    aria-label={text}
    startIcon={<ArrowBack />}
    {...props}
  >
    {text}
  </StyledButton>
)

export default ButtonBack
