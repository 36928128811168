import {styled} from '@mui/material/styles'
import {TableCell, TableContainer} from '@mui/material'

export const HeaderCell = styled(TableCell)(({theme}) => ({
  ...theme.typography.overline,
  letterSpacing: '0.5px',
  // textTransform: 'uppercase',
  // color: theme.palette.primary.main,
  fontSize: '14px !important',
  color: '#39215E important',
  fontWeight: 600
}))

export const TableContainerWrapper = styled(TableContainer)(() => ({
  marginTop: 19,
  '@media(max-width: 600px)': {
    overflowX: 'inherit'
  }
}))
