import httpClient from './network'

export const deleteRkRequest = (uuid) => {
  return httpClient.delete(`/rk/${uuid}`)
}

export const getRkProfileRequest = (uuid) => {
  return httpClient.get(`/rk/${uuid}/profile`)
}

export const updateRkProfileRequest = (uuid, data) => {
  return httpClient.put(`/rk/${uuid}/profile`, data)
}

export const getOperationHistoryRequest = (uuid, data) => {
  const req = {description: data.description}
  if (data.members?.length > 0) {
    req.members = data.members
  }
  if (data.projects?.length > 0) {
    req.projects = data.projects
  }
  if (data.period?.length > 1) {
    data.start = data.period[0]
    data.end = data.period[1]
  }
  return httpClient.post(`/rk/${uuid}/operations`, data)
}

export const getRkMembersRequest = (uuid) => {
  return httpClient.get(`/rk/${uuid}/members`)
}

export const transferMembershipRequest = (uuid, userUuid) => {
  return httpClient.post(`/rk/${uuid}/transfer-membership/${userUuid}`)
}

export const transferMembershipApproveRequest = (uuid) => {
  return httpClient.put(`/rk/${uuid}/transfer-membership-approve`)
}

export const transferMembershipRejectRequest = (uuid) => {
  return httpClient.put(`/rk/${uuid}/transfer-membership-reject`)
}

export const transferMembershipLastRequest = (uuid) => {
  return httpClient.get(`/rk/${uuid}/transfer-membership/last`)
}

export const transferMembershipMeRequest = (uuid) => {
  return httpClient.get(`/rk/${uuid}/transfer-membership/me`)
}

export const approveMembershipRequest = (appUuid) => {
  return httpClient.put(`/rk/app-membership/${appUuid}/approve`)
}

export const rejectMembershipRequest = (appUuid) => {
  return httpClient.put(`/rk/app-membership/${appUuid}/reject`)
}

export const getAppMembershipListRequest = (uuid) => {
  return httpClient.get(`/rk/${uuid}/app-membership`)
}

export const getAppMembershipRequest = (appUuid) => {
  return httpClient.get(`/rk/app-membership/${appUuid}`)
}

export const excludeMemberRequest = (uuid, userUuid) => {
  return httpClient.post(`/rk/${uuid}/exclude/${userUuid}`)
}
