import {darken, lighten, styled} from '@mui/material/styles'
import Slider from 'react-slick'
import Image from 'components/Media/Image'
import {Button} from '@mui/material'

export const StyledWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  color: theme.palette.primary.main,
  marginTop: 22,
  marginBottom: 77,
  gap: '19px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  },
  '.notRequiredText': {
    fontWeight: 400,
    color: theme.palette.primary.main
  },
  '.MuiFormControl-root': {
    width: '100%'
  },
  '.child-modal-date-input .MuiInputLabel-root': {
    top: 0,
    transformOrigin: 'left',
    transform: 'translate(0px, 0px) scale(0.75)',
    position: 'relative'
  }
}))

export const StyledLogo = styled(Image)(({theme}) => ({
  width: '20px',
  height: '20px',
  display: 'block',
  marginRight: 12
}))

export const StyledMainContent = styled('div')(({theme}) => ({
  padding: '38px 19px',
  background: theme.palette.background.paper,
  borderRadius: 0,

  [theme.breakpoints.up('md')]: {
    borderRadius: 8,
    width: '67%',
    flex: '1 1 67%'
  },
  [theme.breakpoints.up('lg')]: {
    padding: 38
  },
  '& .pencilIcon': {
    fill: theme.palette.primary.contrastText
  }
}))

export const StyledAddContent = styled('div')(({theme}) => ({
  // width: '100%',
  maxWidth: '100%',
  gap: '19px',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '0 !important',
  // boxShadow: '0px 12px 32px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  height: 'fit-content',
  borderRadius: 12,
  padding: '0',
  [theme.breakpoints.up('md')]: {
    flex: '1 1 33%',
    maxWidth: '32%'
  },
  '.invite': {
    borderRadius: '6px',
    flexDirection: 'column',
    '& div:nth-of-type(1)': {
      flexDirection: 'column-reverse',
      a: {
        overflowWrap: 'anywhere'
      }
    },
    '& div:nth-of-type(2)': {
      width: '100%',
      marginLeft: 0,
      button: {
        width: '100%'
      }
    }
  }
}))

export const StyledSpecInfo = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  '&.specialInfoOne': {
    gap: '6px',
    margin: '19px 0'
  },
  '&.specialInfoTwo': {
    gap: '6px',
    [theme.breakpoints.up('md')]: {
      gap: '19px'
    }
  },
  '& .privateVote, .youVoted': {
    width: '128px'
  },
  '& .privateVote': {
    justifyContent: 'center',
    backgroundColor: lighten(theme.palette.warning.main, 0.8),
    color: darken(theme.palette.error.main, 0.3)
  },
  '& .youVoted': {
    justifyContent: 'space-between',
    backgroundColor: darken(theme.palette.primary.light, 0.25),
    color: theme.palette.info.contrastText
  }
}))

export const StyledInfoBox = styled('div')(({theme}) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  background: theme.palette.background.paper,
  padding: '13px',
  borderRadius: '6px',
  [theme.breakpoints.up('md')]: {
    padding: '19px'
  },
  '& .chatLink, .voteLinkTitle': {
    fontSize: '11px',
    lineHeight: '16px',
    color: lighten(theme.palette.primary.main, 0.35),
    wordBreak: 'break-word'
  },
  '& .voteLink': {
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '16px',
    marginTop: '34px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
      lineHeight: '19px'
    }
  },
  '& .projectHeader': {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%'
  },
  '& .projectImg': {
    width: '64px',
    height: '64px',
    marginRight: '8px'
  },
  '& .projectCost': {
    backgroundColor: lighten(theme.palette.primary.main, 0.92),
    justifyContent: 'center',
    marginTop: '6px'
  },
  '& .projectName': {
    marginTop: '19px',
    fontSize: '14px',
    lineHeight: '19px',
    [theme.breakpoints.up('md')]: {
      fontSize: '19px',
      lineHeight: '29px'
    }
  },
  ':not(:last-child)': {
    marginBottom: '19px'
  }
}))

export const StyledButtonsWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-between',
  margin: '19px 0',
  gap: '13px',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row'
  },
  '& .btn': {
    maxWidth: '234px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto'
    }
  }
}))

export const StyledTitle = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 32,
  '.edit': {
    marginLeft: 12,
    svg: {
      fill: 'transparent'
    }
  },
  [theme.breakpoints.down(1000)]: {
    flexDirection: 'column',
    '.btn': {
      marginTop: 16
    },
    '.edit': {
      marginLeft: 0,
      marginTop: 16
    }
  }
}))

export const StyledMenuButton = styled(Button)(({theme}) => ({
  width: '100%'
}))

export const StyledSlider = styled(Slider)(({theme}) => ({
  marginTop: '22px',
  '& .slick-track': {
    display: 'flex'
  },

  '& .slick-dots': {
    backgroundColor: theme.palette.background.paper,
    width: 'auto',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '2px',
    borderRadius: '6px 6px 0px 0px'
  },
  '& .slick-dots li': {
    margin: '0 2px'
  },
  '& .slick-dots li button:before': {
    fontSize: '8px'
  },
  '& .slick-slide': {
    minHeight: '100%',
    height: 'auto',
    '& > div': {
      minHeight: '100%',
      height: '100%',
      padding: '0 2px',
      [theme.breakpoints.up('md')]: {
        padding: '0 8px'
      }
    }
  },

  '& .slideImgContainer': {
    paddingBottom: '47%',
    position: 'relative'
  },
  '& .slideImgContainer img': {
    position: 'absolute',
    height: '100%',
    width: '100%',
    left: 0,
    top: 0
  }
}))

export const DaysLeftBlock = styled('div')(({theme}) => ({
  maxWidth: '100%',
  padding: '19px',
  display: 'flex',
  gap: '6px',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '12px',
  boxShadow: '0px 12px 48px rgba(57, 33, 94, 0.06), 0px 2.4px 4px rgba(57, 33, 94, 0.04)',
  backgroundColor: '#E0E0E0',
  [theme.breakpoints.up('md')]: {
    borderRadius: '12px'
  }
}))

export const PaymentBlock = styled('div')(({theme}) => ({
  maxWidth: '100%',
  padding: '20px',
  display: 'flex',
  gap: '12px',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '12px',
  // boxShadow: '0px 12px 48px rgba(57, 33, 94, 0.06), 0px 2.4px 4px rgba(57, 33, 94, 0.04)',
  border: '1px solid #BCBCEB',
  backgroundColor: '#E8E8F8',
  [theme.breakpoints.up('md')]: {
    borderRadius: '12px'
  }
  // button: {
  //   fontSize: 16,
  //   fontWeight: 500,
  //   background: 'rgba(57, 33, 94, 0.12)',
  //   color: '#39215E',
  //   border: '1px solid rgba(255, 255, 255, 1e-05)',
  //   '&:hover': {
  //     background: 'rgba(57, 33, 94, 0.24)',
  //     border: '1px solid rgba(255, 255, 255, 1e-05)'
  //   },
  //   '&:focused': {
  //     border: '1px solid rgba(255, 255, 255, 1e-05)'
  //   },
  //   '&:active': {
  //     boxShadow:
  //       ' 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
  //   }
  // }
}))

export const PiggyBlock = styled('div')(({theme}) => ({
  maxWidth: '100%',
  padding: '20px',
  display: 'flex',
  boxSizing: 'border-box',
  gap: '12px',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '12px',
  // boxShadow: '0px 12px 48px rgba(57, 33, 94, 0.06), 0px 2.4px 4px rgba(57, 33, 94, 0.04)',
  border: '1px solid #BCBCEB',
  backgroundColor: '#E8E8F8',
  [theme.breakpoints.up('md')]: {
    borderRadius: '12px'
  }
}))

export const WithdrawingFunds = styled('div')(({theme}) => ({
  maxWidth: '100%',
  padding: '21px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  borderRadius: '12px',
  alignItems: 'start',
  backgroundColor: theme.palette.background.paper,
  border: '1px solid #EDEDFF',
  [theme.breakpoints.up('md')]: {
    borderRadius: '12px'
  },
  '.caption': {
    color: theme.palette.grey[400]
  }
}))

export const StyledJoinBlock = styled('div')(({theme}) => ({
  maxWidth: '100%',
  padding: '24px 24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '19px',
  alignItems: 'start',
  boxShadow: '0px 12px 48px rgba(57, 33, 94, 0.06), 0px 2.4px 4px rgba(57, 33, 94, 0.04)',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('md')]: {
    borderRadius: '12px'
  },
  '.caption': {
    color: theme.palette.grey[400]
  },
  ' .header': {
    fontSize: '19px',
    lineHeight: '29px',
    color: theme.palette.primary.main,
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      lineHeight: '22px'
    }
  }
  // button: {
  //   fontSize: 16,
  //   fontWeight: 500,
  //   color: '#39215E',
  //   background: 'rgba(57, 33, 94, 0.12)',
  //   border: '1px solid rgba(255, 255, 255, 1e-05)',
  //   '&:hover': {
  //     background: 'rgba(57, 33, 94, 0.24)',
  //     border: '1px solid rgba(255, 255, 255, 1e-05)'
  //   },
  //   '&:focused': {
  //     border: '1px solid rgba(255, 255, 255, 1e-05)'
  //   },
  //   '&:active': {
  //     boxShadow:
  //       ' 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
  //   },
  //   svg: {
  //     path: {
  //       stroke: '#39215E'
  //     }
  //   }
  // }
}))

export const AlertError = styled('div')(({theme}) => ({
  maxWidth: '100%',
  padding: '13px',
  display: 'flex',
  flexDirection: 'column',
  gap: '19px',
  alignItems: 'start',
  borderRadius: '6px',
  backgroundColor: theme.palette.error.secondary,
  color: theme.palette.error.main
}))
export const PayAlert = styled('div')(({theme, severity}) => ({
  width: '100%',
  padding: '13px',
  display: 'flex',
  flexDirection: 'column',
  gap: '19px',
  alignItems: 'start',
  borderRadius: '6px',
  // backgroundColor: severity === 'error' ? theme.palette.error.secondary : theme.palette.warning.secondary,
  backgroundColor: '#FFECEC',
  // color: severity === 'error' ? theme.palette.error.main : theme.palette.warning.main
  color: '#E41D3D'
}))
