import React, {useCallback, useMemo} from 'react'
import {Typography} from '@mui/material'
import Button from '@mui/material/Button'

import {StyledWrapper} from './CartTotalAndPayStyle'
import {ArrowIcon} from '../../../../../../../../icons'
import {useTheme} from '@mui/material/styles'
import pay from '../../../../../../../../images/pay.png'

const CartTotalAndPay = ({items, active, createBill}) => {
  const theme = useTheme()

  const totalPayment = useMemo(() => {
    return (
      items &&
      items.reduce((prev, current) => {
        if (current.checked) {
          return prev + current.amount
        } else {
          return prev
        }
      }, 0)
    )
  }, [items])

  return (
    <StyledWrapper>
      <Typography variant={'h4'} sx={{fontSize: 16}}>
        Итого
      </Typography>
      <Typography className="totalPayment">{totalPayment} ₽</Typography>

      <Button
        disabled={Boolean(!totalPayment) || !active}
        sx={{width: '100%'}}
        variant="contained"
        type="button"
        onClick={createBill}
        endIcon={totalPayment ? <ArrowIcon color={theme.palette.primary.contrastText} /> : null}
      >
        <span style={{marginRight: '4px'}}>К оплате</span>
      </Button>
      <img alt="pay" src={pay} style={{width: 'inherit', height: '200px'}} />
    </StyledWrapper>
  )
}

export default CartTotalAndPay
