import React, {useCallback} from 'react'

import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import {InputLabelWrapper, StyledFormControl} from '../TextInput/TextInputStyle'
import {ChevronDownIcon} from '../../../icons'
import {StyledAutocompleteWithCheckboxes, Tags} from './AutocompleteIWithCheckoxesStyle'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const AutocompleteWithCheckboxes = ({options, label, id, onChange, value}) => {
  const handleChange = useCallback(
    (ev) => {
      onChange(ev)
    },
    [options]
  )
  return (
    <StyledFormControl variant="standard" fullWidth={true}>
      {label ? (
        <InputLabelWrapper htmlFor={id} shrink>
          {label}
        </InputLabelWrapper>
      ) : null}
      <StyledAutocompleteWithCheckboxes
        multiple
        value={value}
        onChange={onChange}
        id={`${id}-Autocomplete`}
        options={options}
        popupIcon={<ChevronDownIcon />}
        disableCloseOnSelect
        getOptionLabel={(option) => option.title}
        clearIcon={null}
        renderOption={(props, option, {selected}) => {
          return (
            <li {...props}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{marginRight: 8}} checked={selected} />
              {option.title}
            </li>
          )
        }}
        renderTags={(values) => {
          if (values.length === options.length) {
            return <Tags>Все ({values.length})</Tags>
          }
          return <Tags>{values.map(({title}) => title).join(', ')}</Tags>
        }}
        renderInput={(params) => <TextField {...params} label="Checkboxes" placeholder="" />}
        noOptionsText="Нет результатов"
      />
    </StyledFormControl>
  )
}

export default AutocompleteWithCheckboxes
