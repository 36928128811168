export const CLASSES = 11

export const FROM_BTN = 'FROM_BTN'

export const initSchoolState = {
  yearOfAdmission: '',
  grade: '',
  letter: '',
  educator: '',
  name: '',
  chat: '',
  phone: '+7',
  bank: ''
}

export const initKindergartenState = {
  groupName: '',
  groupNumber: '',
  educator: '',
  chat: '',
  name: '',
  phone: '+7',
  bank: ''
}

export const ERRORS_FROM_BACK_FIELDS = {
  'fullName.firstName': 'firstName',
  'fullName.surname': 'lastName',
  birthday: 'birthday',
  avatarUrl: 'avatarUrl'
}

export const RK_TYPES = {
  SCHOOL: 'School',
  KINDERGARTEN: 'Kindergarten',
  MUGS_SECTIONS_OTHERS: 'MugsSectionsOthers'
}

export const RK_TYPES_RU = {
  SCHOOL: 'Школа',
  KINDERGARTEN: 'Детский сад'
}
