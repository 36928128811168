import React, {useState, useMemo, useEffect, useCallback} from 'react'
import {styled} from '@mui/material/styles'
import {Box, Typography, RadioGroup, Radio, FormControlLabel} from '@mui/material'
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip'
import {StyledLabel} from './FundraisingStyle'
import {HtmlTooltip} from '../../../../../../../components/Tooltip/Tooltips'
import {InfoIcon} from '../../../../../../../icons'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

const StyledBox = styled(Box)(() => ({
  marginBottom: 31
}))

const TariffInput = ({amount, setAmount, addedPercent, tariff, setTariff, memberCount, tariffs, disabled}) => {
  let [withTariff, setWithTariff] = useState(0)

  const handleTariffChange = useCallback((e) => {
    setTariff(e.target.value)
  })

  return (
    <StyledBox component="div">
      <StyledLabel>
        <div
          style={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            flexWrap: 'wrap'
          }}
        >
          <HtmlTooltip
            leaveTouchDelay={20000}
            enterTouchDelay={1}
            disableFocusListener
            title={
              <>
                <Typography color="inherit" paragraph="true">
                  К указанной сумме мы добавляем минимум {addedPercent}%, которые будут списаны в момент вывода денег.
                  Эта сумма является платой за пользование системой.
                </Typography>
                <Typography color="inherit" paragraph="true">
                  Вы можете увеличить процент самостоятельно для перестраховки от тех, кто не сдаст деньги. Сумма
                  перестраховки остается на счете родительского комитета.
                </Typography>
              </>
            }
          >
            <Typography variant="caption" sx={{display: 'flex', alignItems: 'center'}}>
              <ErrorOutlineIcon />
              <Typography
                variant="caption"
                sx={{fontSize: '19px', fontWeight: '700', display: 'inline', marginLeft: '5px'}}
              >
                Тариф и перестраховка
              </Typography>
            </Typography>
          </HtmlTooltip>
          {/*Тариф и перестраховка*/}
        </div>
      </StyledLabel>
      <RadioGroup
        aria-labelledby="tariff-radio-buttons-group-label"
        name="Тарифы"
        row
        value={tariff}
        onChange={handleTariffChange}
      >
        {tariffs.map((e) => {
          return <FormControlLabel value={e.id} control={<Radio />} label={e.label} key={e.id} disabled={disabled} />
        })}
      </RadioGroup>
    </StyledBox>
  )
}

export default TariffInput
