import {darken, lighten, styled} from '@mui/material/styles'

export const StyledSpecInfo = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  '&.specialInfoOne': {
    gap: '6px',
    margin: '19px 0'
  },
  '&.specialInfoTwo': {
    gap: '6px',
    [theme.breakpoints.up('md')]: {
      gap: '19px'
    }
  },
  '& .privateVote, .youVoted': {
    width: 'fit-content'
  },
  '& .privateVote': {
    justifyContent: 'center',
    backgroundColor: lighten(theme.palette.warning.main, 0.8),
    color: darken(theme.palette.error.main, 0.3),
    width: 'fit-content',
    padding: 6
  },
  '& .youVoted': {
    justifyContent: 'space-between',
    backgroundColor: darken(theme.palette.primary.light, 0.25),
    color: theme.palette.info.contrastText
  }
}))
