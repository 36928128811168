import React from 'react'
import {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Wrapper} from './ParentCommitteesStyle'
import ParentCommittee from './ParentCommittee'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import {directoriesSelectors} from '../../../../store/directoriesStore/directoriesStore'

const ParentCommittees = ({list, handleClickJoin}) => {
  const classes = useSelector(directoriesSelectors.class.selector)
  const [activeTab, setActiveTab] = useState(0)
  const [filterList, setFilterList] = useState(list)
  const [school, setSchool] = useState(false)

  const tabSwitchHandler = (event, newValue) => {
    setActiveTab(newValue)
  }

  const classesNum = Array.from(new Set(list.map((item) => String(classes[item.classUuid]))))

  useEffect(() => {
    if (activeTab === 0) {
      setFilterList(list)
    } else {
      setFilterList(list.filter((item) => String(classes[item.classUuid]) === String(activeTab)))
    }
  }, [activeTab])

  useEffect(() => {
    if (list.find((item) => item.institutionType === 'School')) {
      setSchool(true)
    } else {
      setSchool(false)
    }
  }, [list])

  return (
    <Wrapper>
      {school && (
        <Box
          // sx={{borderBottom: 1, borderColor: 'divider'}}
          className="tab_box"
        >
          <Tabs
            value={activeTab}
            onChange={tabSwitchHandler}
            allowScrollButtonsMobile={true}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
            TabIndicatorProps={{style: {background: '#E1E1F2', height: '100%', borderRadius: 12}}}
          >
            <Tab
              label={'Все'}
              id={'All'}
              className="tab"
              style={{zIndex: 1}}
              sx={{fontSize: '14px !important', fontWeight: 600, padding: '6px 12px'}}
            />
            {classesNum.map((item) => (
              <Tab
                label={`${item}`}
                id={`${item}`}
                className="tab"
                value={item}
                style={{zIndex: 1}}
                sx={{fontSize: '14px !important', fontWeight: 600, padding: '6px 12px'}}
              />
            ))}
          </Tabs>
        </Box>
      )}
      {filterList.map((elem, index) => (
        <ParentCommittee
          key={index.toString(36)}
          {...elem}
          isSchool={!!elem.classUuid && !!elem.classLetterUuid}
          onLinkClick={handleClickJoin}
        />
      ))}
    </Wrapper>
  )
}

export default ParentCommittees
