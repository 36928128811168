import {createSelectorsTree} from '../selectorsFactory'
import {generateReducersObject} from '../sliceMapping'
import {allInitAppEvents} from './childStoreSliceEvents'

const initialState = {
  children: [],
  childrenLoading: false,
  errors: {}
}

/** Reducers */
export const CHILDREN_STORE = 'childrenStore'
export const childrenStore = generateReducersObject({
  allSliceEvents: allInitAppEvents,
  initialState,
  reducerName: CHILDREN_STORE
})

/** Selectors */
export const childrenSelectors = createSelectorsTree(initialState, CHILDREN_STORE)
