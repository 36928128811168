import React, {useState} from 'react'

import {IconButton, InputLabel} from '@mui/material'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import {InputBaseFormControl} from '../InputsStyles'
import {styled} from '@mui/material/styles'

const Label = styled(InputLabel)(({theme}) => ({
  color: theme.palette.primary.main
}))

export const PasswordInput = (props) => {
  const {
    id,
    password,
    passwordError,
    passHandler,
    clearPassError,
    className = '',
    withShowButton = true,
    label,
    placeholder = '',
    required = false,
    autoFocus = false,
    autocomplete = null
  } = props
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const onChange = (event) => {
    if (passwordError) {
      clearPassError('')
    }
    const {value} = event.target
    passHandler(value.substring(0, 50).replace(/[а-яА-ЯёЁ]/gi, ''))
  }

  const endAdornment = (
    <InputAdornment position="end">
      <IconButton
        aria-label="Переключение режима видимости пароля"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge="end"
      >
        {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
      </IconButton>
    </InputAdornment>
  )

  return (
    <InputBaseFormControl classes={{root: className}} variant="standard" fullWidth={true} required={required}>
      <Label htmlFor="user-phone" shrink>
        {label}
      </Label>
      <TextField
        autoFocus={autoFocus}
        id={id}
        type={showPassword ? 'text' : 'password'}
        value={password}
        error={Boolean(passwordError)}
        helperText={passwordError}
        placeholder={placeholder}
        onChange={onChange}
        inputProps={{
          autoComplete: autocomplete
        }}
        InputProps={{
          endAdornment: withShowButton ? endAdornment : null
        }}
      />
    </InputBaseFormControl>
  )
}
