import {alpha, styled} from '@mui/material/styles'
import {Typography} from '@mui/material'

export const ReportsWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  gap: '8px',
  flexDirection: 'column',
  // margin: '0 0 32px',
  [theme.breakpoints.up('md')]: {
    // margin: '0 0 32px',
    flexDirection: 'row',
    justifyContent: 'space-between'
    // flexWrap: 'wrap'
  },
  [theme.breakpoints.between('md', 1224)]: {
    // padding: '0 19px'
  }
}))

export const Report = styled('div')(({theme}) => ({
  width: '100%',
  height: 'fit-content',
  background: 'white',
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  alignItems: 'center',
  justifyContent: 'space-between',
  // boxShadow: '0px 9px 12px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  border: '1px solid #EDEDFF',
  padding: '13px',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    padding: '19px',
    // width: '100%',
    height: '176px'
  },
  [theme.breakpoints.between('md', 1224)]: {
    width: 'calc(50% - 6px)'
  }
}))

export const Img = styled('img')(({theme}) => ({
  width: '38px',
  height: '38px',
  [theme.breakpoints.up('md')]: {
    width: '64px',
    height: '64px'
  }
}))

export const Text = styled(Typography)(({theme}) => ({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '13px',
  textAlign: 'center',
  color: theme.palette.primary.main,
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    lineHeight: '16px'
  }
}))

export const Sum = styled(Typography)(({theme}) => ({
  letterSpacing: '-0.04em',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '29px',
  textAlign: 'center',
  color: theme.palette.primary.main,
  width: 'inherit',
  [theme.breakpoints.up('md')]: {
    fontSize: '24px',
    lineHeight: '38px'
  }
}))
