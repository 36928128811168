import {styled} from '@mui/material/styles'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

export const StyledSelect = styled(Select)(({theme}) => ({
  svg: {
    width: 24,
    height: 24,
    top: 'calc(50% - 12px)'
  },
  '& .MuiMenu-paper': {
    borderRadius: 12
  },
  '.MuiSelect-icon': {},
  '& .MuiSelect-select': {
    background: 'transparent'
  },
  '& fieldset': {
    border: 'none'
  },
  '& .select-icon': {
    top: 'calc(50% - 10px)'
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '176px',
    '& .MuiSelect-icon': {
      width: '13px',
      height: '13px',
      top: 'calc(50% - 5px)'
    }
  },
  '& .parent-committee-name': {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 600,
    marginRight: 8,
    color: theme.palette.primary.main
  },
  [theme.breakpoints.up('md')]: {
    '& .parent-committee-name': {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 600,
      marginRight: 8
    }
  }
}))

export const ParentCommitteeDropdownWrapper = styled('div')(({theme}) => ({
  paddingBottom: '6px',
  color: theme.palette.primary.main
}))

export const ParentCommitteeDropdownItem = styled(MenuItem)(() => ({
  position: 'relative',
  whiteSpace: 'normal',
  maxWidth: '224px',
  fontSize: 14,
  padding: '10px 13px 10px 38px',
  '& .check-icon': {
    position: 'absolute',
    left: '10px',
    top: '10px'
  }
}))

export const NewParentCommitteeDropdownWrapper = styled('div')(() => ({
  '&:not(:only-child)': {
    paddingTop: '6px',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)'
  }
}))
