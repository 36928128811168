import React, {useCallback, useState, useMemo} from 'react'

import {ListItemIcon, ListItemText} from '@mui/material'

import {CheckIcon, CrossIcon, VerticalDotsIcon} from '../../../../../../icons'
import {StyledMenu, StyledMenuItem} from '../../MembersPageStyle'
import {applicationOptions, recordOptions} from '../../../constants/parentCommitteeConstants'
import {ButtonWrapper} from '../../../Summary/components/Invite/InviteStyle'
import {useConfirm} from 'material-ui-confirm'
// @ts-ignore

const ContextMenu = ({
  openMyProfile,
  fromApplication,
  record,
  isMember,
  isCurrentUser,
  handleRemoveFromCommittee,
  handleApproveMembership,
  handleRejectMembership,
  userUuid,
  userName,
  ids,
  handleDeleteRecord,
  handleEditRecord,
  handleModal
}) => {
  const confirm = useConfirm()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const onRemoveFromCommittee = useCallback(() => {
    setAnchorEl(null)
    confirm({
      title: 'Подтверждение',
      description: `Удалить ${userName} из родительского комитета?`,
      confirmationText: 'Подтвердить',
      cancellationText: 'Отмена',
      titleProps: {sx: {lineHeight: '29px', fontSize: '19px'}},
      confirmationButtonProps: {variant: 'contained'},
      cancellationButtonProps: {variant: 'outlined'}
    })
      .then(() => {
        handleRemoveFromCommittee(userUuid)
      })
      .catch(() => {
        /* ... */
      })
  }, [handleRemoveFromCommittee, userUuid, userName])

  const onApproveMembership = useCallback(() => {
    setAnchorEl(null)
    handleApproveMembership(userUuid)
  }, [handleApproveMembership, userUuid])

  const onRejectMembership = useCallback(() => {
    setAnchorEl(null)
    handleRejectMembership(userUuid)
  }, [handleRejectMembership, userUuid])

  const onDeleteRecord = useCallback(() => {
    setAnchorEl(null)
    handleDeleteRecord(ids)
  }, [handleDeleteRecord, ids])

  const onEditRecord = useCallback(() => {
    setAnchorEl(null)
    handleModal(userUuid, true)
  }, [handleModal, userUuid])

  const applicationItems = useMemo(() => {
    return applicationOptions.map((label, i) => {
      const onClick = i ? onRejectMembership : onApproveMembership
      const icon = i ? <CrossIcon className="cross-icon" /> : <CheckIcon />
      return (
        <StyledMenuItem key={label} onClick={onClick}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{label}</ListItemText>
        </StyledMenuItem>
      )
    })
  }, [onRejectMembership, onApproveMembership])

  const recordItems = useMemo(() => {
    return recordOptions.map((label, i) => {
      const onClick = i ? onDeleteRecord : onEditRecord
      const sx = i ? {color: '#CD0000'} : {}
      return (
        <StyledMenuItem key={label} onClick={onClick} sx={sx}>
          {label}
        </StyledMenuItem>
      )
    })
  }, [onDeleteRecord, handleModal])

  return (
    <>
      <ButtonWrapper
        aria-controls={anchorEl ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : undefined}
        aria-label="open-context-menu"
        className="context-menu-btn row-btn"
        variant="outlined"
        type="button"
        onClick={handleClick}
        startIcon={<VerticalDotsIcon />}
      />
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {isCurrentUser && <StyledMenuItem onClick={openMyProfile}>Мой профиль</StyledMenuItem>}
        {fromApplication && applicationItems}
        {isMember && !isCurrentUser && (
          <StyledMenuItem sx={{color: '#CD0000'}} onClick={onRemoveFromCommittee}>
            Исключить из родкома
          </StyledMenuItem>
        )}
        {record && recordItems}
      </StyledMenu>
    </>
  )
}

export default ContextMenu
