import {styled} from '@mui/material/styles'

export const StyledBox = styled('div')(({theme}) => ({
  width: '100%',
  marginBottom: 19,
  '& label': {
    marginBottom: '6px',
    color: theme.palette.primary.main
  },
  '& .outer-input-wrapper': {
    marginBottom: 6
  },
  '& .outer-input-wrapper input ': {
    color: theme.palette.primary.main
  },
  '& .outer-input-wrapper .MuiOutlinedInput-input': {
    border: 'none'
  },
  '& .outer-input-wrapper .MuiInputLabel-root': {
    transform: 'translate(0, -1.5px) scale(0.75)'
  },
  '& input::placeholder': {
    color: theme.palette.primary.main
  }
}))
