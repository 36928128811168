import {useDispatch, useSelector} from 'react-redux'
import {applyMiddleware, createStore} from 'redux'
import createSagaMiddleware from 'redux-saga'
import {composeWithDevTools} from '@redux-devtools/extension'
import {createBrowserHistory} from 'history'
import {routerMiddleware} from 'connected-react-router'

import createRootStore from './rootStore'
import rootSaga from '../sagas/rootSaga'

const sagaMiddleware = createSagaMiddleware()

export const browserHistory = createBrowserHistory()

const store = createStore(
  createRootStore(browserHistory),
  composeWithDevTools({name: 'rodcom', trace: true, traceLimit: 25})(
    applyMiddleware(routerMiddleware(browserHistory), sagaMiddleware)
  )
)

sagaMiddleware.run(rootSaga)

export default store

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch()
export const useAppSelector = useSelector
