import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {Box} from '@mui/material'

import {MapWrapper, SchoolsWrapper} from './EducationalInstitutionStyle'
import {HeaderWithBackButton} from '../../components/HeaderWithBackButton/HeaderWithBackButton'
import EmptyList from './components/EmptyList/EmptyList'
import Questions from './components/Questions/Questions'
import ParentCommittees from './components/ParentCommittees/ParentCommittees'
import {useDispatch, useSelector} from 'react-redux'
import {searchParentCommittees} from '../../store/creatingRkStore/creatingRkStoreSliceEvents'
import {creatingRkSelectors} from '../../store/creatingRkStore/creatingRkStore'
import {directoriesSelectors} from '../../store/directoriesStore/directoriesStore'
import {PATHS} from '../../routes/paths'
import placemarkYmaps from '../../../src/images/placemarkYmaps.svg'
import {FROM_BTN} from '../CreatingRodcom/constants/creatingRodcomConstants'
import * as directoriesEvents from '../../store/directoriesStore/directoriesStoreSliceEvents'

const EducationalInstitution = ({isLogged}) => {
  const {search} = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const params = new URLSearchParams(search)
  const geometry = params.get('geo') || ''
  const companyName = params.get('name') || ''
  const companyDesc = params.get('desc') || ''
  const companyCity = params.get('city') || ''
  const institutionType = params.get('institutionType') || ''
  const companyExternalSystem = params.get('externalSystem') || ''
  const longitude = geometry?.split(',')?.[1] || ''
  const latitude = geometry?.split(',')?.[0] || ''

  const classes = useSelector(directoriesSelectors.class.selector)
  const resultSearchParentCommittees = useSelector(creatingRkSelectors.searchParentCommittees.selector)
  const [list, setList] = useState([])
  const [initMap, setInitMap] = useState(false)

  useEffect(() => {
    setList(resultSearchParentCommittees)
  }, [resultSearchParentCommittees, classes])

  useEffect(() => {
    dispatch(
      searchParentCommittees.action({
        City: params.get('city'),
        InstitutionName: companyName,
        InstitutionType: institutionType
      })
    )
    dispatch(directoriesEvents.getInitDirectories.action())
  }, [])

  useLayoutEffect(() => {
    if (!longitude || !latitude) return
    if (window.ymaps && !initMap) {
      window.ymaps?.ready(init)
      setInitMap(true)
    }

    function init() {
      const myMap = new window.ymaps.Map('map', {
        controls: [],
        center: [longitude, latitude],
        zoom: 15
      })
      // noinspection JSUnresolvedVariable,JSUnresolvedFunction
      myMap.geoObjects.add(
        new window.ymaps.Placemark(
          [longitude, latitude],
          {},
          {
            // iconLayout: 'default#image',
            iconColor: '#39215E'
            // iconImageHref: placemarkYmaps,
            // iconImageSize: [24, 24]
          }
        )
      )
    }
  }, [])

  const handleClickCreate = useCallback(() => {
    const currentLink = `${PATHS.EDUCATIONAL_INSTITUTION}${search}`
    navigate(
      `${PATHS.CREATING_PARENT_COMMITTEE}?name=${companyName}&desc=${companyDesc}&city=${companyCity}&externalSystem=${companyExternalSystem}&institutionType=${institutionType}`,
      {state: {from: FROM_BTN, prevLink: currentLink}}
    )
  }, [companyName, companyDesc])

  const handleClickMore = useCallback(() => {
    // todo
  }, [])

  const handleClickJoin = useCallback(
    (id) => {
      navigate(`${PATHS.JOINING_PARENT_COMMITTEE}/${id}?name=${companyName}&desc=${companyDesc}`)
    },
    [companyName, companyDesc]
  )

  return (
    <Box>
      <HeaderWithBackButton
        onClick={() => navigate(PATHS.SEARCH_PAGE)}
        linkText="К результатам"
        description={companyDesc}
        title={companyName}
      />
      {longitude && latitude && <MapWrapper id="map" />}
      {!!list.length && <ParentCommittees handleClickJoin={handleClickJoin} list={list} />}
      <EmptyList length={!!list.length} handleClick={handleClickCreate} />
      <Questions handleClick={handleClickMore} />
    </Box>
  )
}

export default EducationalInstitution
