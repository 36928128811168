export const PATHS = {
  LANDING: 'https://rodcom.ru/',
  HOME: '/',
  LOGIN: '/login',
  PROFILE: '/profile',
  RESTORING_ACCESS: '/restoring-access',
  CREATING_PARENT_COMMITTEE: '/creating-parent-committee',
  JOINING_PARENT_COMMITTEE: '/joining',
  MY_PARENT_COMMITTEES: '/my-parent-committees',
  EDUCATIONAL_INSTITUTION: '/educational-institution',
  PARENT_COMMITTEE: '/parent-committee',
  PARENT_COMMITTEE_SUMMARY: '/summary',
  PARENT_COMMITTEE_SUMMARY_CART: '/summary/cart',
  PARENT_COMMITTEE_PROJECTS: '/projects',
  PARENT_COMMITTEE_EDIT_PROJECT: '/edit',
  PARENT_COMMITTEE_CREATE_PROJECT: '/create',
  PARENT_COMMITTEE_REPORTS: '/reports',
  PARENT_COMMITTEE_VOTES: '/votes',
  PARENT_COMMITTEE_VOTE_CREATE: '/vote-create',
  PARENT_COMMITTEE_VOTE_EDIT: '/vote-edit',
  PARENT_COMMITTEE_MEMBERS: '/members',
  PARENT_COMMITTEE_DOCUMENTS: '/documents',
  PARENT_COMMITTEE_SETTINGS: '/settings',
  PARENT_COMMITTEE_SETTINGS_ASSIGNMENT: '/settings/assignment',
  PARENT_COMMITTEE_MEMBER_RECORD: '/members/member-record',
  DIRECT_LINK: '/rk',
  DIRECT_LINK_DONATE: '/donate',
  SEARCH_PAGE: '/search',
  PAYMENT: '/payment'
}
