import React, {useCallback, useState, useMemo, useEffect} from 'react'
import {useTheme} from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import {useDispatch, useSelector} from 'react-redux'
import Link from '@mui/material/Link'

import {ChevronDownIcon, LoginIcon, MenuIcon} from '../../icons'
import {ParentCommitteeDropdown} from '../ParentCommitteeDropdown/ParentCommitteeDropdown'
import {LOGO_IMAGE_DATA, pages} from './AppHeaderConstants'
import {AppBar, StyledMenu} from './AppHeaderStyles'
import {logoutUser} from '../../store/initStore/initStoreSliceEvents'
import {PATHS} from '../../routes/paths'
import {initAppSelectors} from '../../store/initStore/initStore'
import {profileSelectors} from '../../store/profileStore/profileStore'
import {creatingRkSelectors} from '../../store/creatingRkStore/creatingRkStore'
import {avatarURL} from '../../utils/imageURL'
import {clearMyRks} from '../../store/creatingRkStore/creatingRkStoreSliceEvents'

const AppHeader = () => {
  const dispatch = useDispatch()
  const [anchorElNav, setAnchorElNav] = useState(null)
  const [anchorElUser, setAnchorElUser] = useState(null)

  const token = localStorage.getItem('token')
  const theme = useTheme()
  const isAuth = useSelector(initAppSelectors.isAuth.selector)
  const profile = useSelector(profileSelectors.user.selector)
  const myParentCommittees = useSelector(creatingRkSelectors.myRodkoms.selector)

  const handleLogoutButtonClick = useCallback(
    (e) => {
      e.preventDefault()
      handleCloseUserMenu()

      dispatch(logoutUser.action())
    },
    [dispatch]
  )

  useEffect(() => {
    return () => dispatch(clearMyRks.action())
  }, [])

  const handleOpenNavMenu = useCallback((event) => {
    setAnchorElNav(event.currentTarget)
  }, [])

  const handleOpenUserMenu = useCallback((event) => {
    setAnchorElUser(event.currentTarget)
  }, [])

  const handleCloseNavMenu = useCallback(() => {
    setAnchorElNav(null)
  }, [])

  const handleCloseUserMenu = useCallback(() => {
    setAnchorElUser(null)
  }, [])

  const UserSettings = [
    {
      label: 'Настройки',
      onClick: handleCloseUserMenu,
      href: '/profile'
    },
    {
      label: 'Выйти',
      onClick: handleLogoutButtonClick,
      href: '/login'
    }
  ]

  const renderDesktopNavigation = (
    <>
      <Box component="div" className="header-logo-lg" sx={{display: {xs: 'none', lg: 'flex'}}}>
        <Link underline="none" href={PATHS.MY_PARENT_COMMITTEES}>
          <img className="logo" src={LOGO_IMAGE_DATA.SRC} alt={LOGO_IMAGE_DATA.ALT} />
        </Link>
      </Box>
      {/*<Box className="nav-list" sx={{display: {xs: 'none', lg: isAuth ? 'none' : 'flex'}}}>*/}
      {/*  {pages.map((page) => (*/}
      {/*    <Button*/}
      {/*      className="nav-button"*/}
      {/*      variant="text"*/}
      {/*      key={page}*/}
      {/*      onClick={handleCloseNavMenu}*/}
      {/*      color="primary"*/}
      {/*      sx={{my: 2, display: 'block'}}*/}
      {/*    >*/}
      {/*      {page}*/}
      {/*    </Button>*/}
      {/*  ))}*/}
      {/*</Box>*/}
    </>
  )

  const renderMobileNavigation = (
    <>
      {/* {!isAuth && ( */}
      <Link href={PATHS.MY_PARENT_COMMITTEES}>
        <Box component="div" className="header-logo-sm" sx={{flexGrow: 1, display: {xs: 'flex', lg: 'none'}}}>
          <img className="logo" src={'/images/svg/MobileIcon.svg'} alt={"Логотип 'Родком'"} style={{width: 'auto'}} />
        </Box>
      </Link>
      {/* )} */}
      {/* <Box className="mobile-navigation" sx={{flexGrow: 1, display: {xs: 'flex', lg: 'none'}}}>
        <IconButton className="menu-button" size="large" onClick={handleOpenNavMenu} color="inherit">
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: {xs: 'block', lg: 'none'}
          }}
        >
          {pages.map((page) => (
            <MenuItem key={page} onClick={handleCloseNavMenu}>
              <Typography textAlign="center" variant="button">
                {page}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box> */}
    </>
  )

  const userMenu = (
    <StyledMenu
      className="user-menu"
      sx={{mt: '36px'}}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
      {UserSettings.map(({label, onClick, href}) => (
        <MenuItem key={label} onClick={onClick}>
          <Link textAlign="center" href={href} underline="none">
            {label}
          </Link>
        </MenuItem>
      ))}
    </StyledMenu>
  )

  return (
    <AppBar position="static" color="default">
      <Container className="container" maxWidth="lg">
        <Toolbar className="toolbar" disableGutters sx={{minHeight: 48}}>
          {renderMobileNavigation}
          {renderDesktopNavigation}
          {profile?.uuid ? (
            <Box className="user-menu-wrapper" sx={{ml: 'auto', flexGrow: 0, display: {xs: token ? 'flex' : 'none'}}}>
              <ParentCommitteeDropdown parentCommittees={myParentCommittees} />
              <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                <Avatar
                  className="avatar"
                  alt={`${profile?.fullName.firstName} ${profile?.fullName.surname}`}
                  src={avatarURL(profile?.avatarUrl)}
                />
              </IconButton>
              <Button
                endIcon={<ChevronDownIcon />}
                variant="text"
                sx={{display: {xs: 'none', lg: 'inline-flex'}}}
                onClick={handleOpenUserMenu}
                className="user_menu"
              >
                <Typography variant="button" className="user-name">
                  {profile?.fullName.firstName}
                </Typography>
              </Button>
              {userMenu}
            </Box>
          ) : null}

          <Box className="login-button-wrapper" sx={{flexGrow: 0, display: {xs: token ? 'none' : 'block'}}}>
            <Link
              href={'/login'}
              className="login-button"
              sx={{display: {sx: 'inline-flex', sm: 'inline-flex', md: 'none', lg: 'none'}}}
              underline="none"
            >
              <LoginIcon color="white" />
            </Link>
            <Link
              className="login-button"
              href={'/login'}
              sx={{display: {xs: 'none', sm: 'none', md: 'inline-flex', lg: 'inline-flex'}}}
              underline="none"
            >
              <LoginIcon color="white" />
              <Typography>Войти</Typography>
            </Link>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default AppHeader
