import React, {useMemo} from 'react'
import StyledSelect from '../../../../../../../../components/Inputs/StyledSelect/StyledSelect'
import {useSelector} from 'react-redux'
import {projectsSelectors} from '../../../../../../../../store/projectsStore/projectsStore'
import {PublishStatusesEnum} from '../../../../../constants/parentCommitteeConstants'

const VoteProjectsSelect = ({handleProjectChange, selectedProjectId}) => {
  const projects = useSelector(projectsSelectors.projectsList.selector)

  const projectsList = useMemo(() => {
    return projects
      .filter((project) => {
        const isProjectUuid = !!project.uuid
        const isPublished = project.status === PublishStatusesEnum.PUBLISHED
        return isProjectUuid && isPublished
      })
      .map((project) => {
        return {
          label: project.title,
          id: project.uuid
        }
      })
  }, [projects])

  const selectedProjectName = useMemo(
    () => projectsList.find((project) => project.id === selectedProjectId)?.id,
    [selectedProjectId]
  )

  if (!projects.length) return null

  return (
    <StyledSelect
      label="Для проекта"
      value={selectedProjectName}
      withNotRequiredText
      onChangeValue={handleProjectChange}
      options={projectsList}
      withClearOption
      selectById={true}
      clearOption="Не назначено"
    />
  )
}

export default VoteProjectsSelect
