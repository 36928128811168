import React, {useCallback, useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import {CircularProgress} from '@mui/material'

import {Wrapper} from './CreatingRodcomPageStyle'
import {FROM_BTN, RK_TYPES, RK_TYPES_RU} from './constants/creatingRodcomConstants'
import HeadOfRodkomWrapper from './components/HeadOfRodkomWrapper/HeadOfRodkomWrapper'
import CreatingRodcomForm from './components/CreatingRodcomForm/CreatingRodcomForm'
import {EVENT_STATUSES} from '../LoginPage/constants'
import {setRequestStatus} from '../../store/initStore/initStoreSliceEvents'
import {useDispatch, useSelector} from 'react-redux'
import {initAppSelectors} from '../../store/initStore/initStore'
import {PATHS} from '../../routes/paths'
import {HeaderWithBackButton} from '../../components/HeaderWithBackButton/HeaderWithBackButton'

export const STEPS = {
  CREATING: 'CREATING',
  ADDING_INFO: 'ADDING_INFO'
}

const CreatingRodcom = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const {search} = location

  const params = new URLSearchParams(search)
  const companyName = params.get('name') || ''
  const companyDesc = params.get('desc') || ''
  const institutionType = params.get('institutionType') || ''

  const requestStatus = useSelector(initAppSelectors.requestStatus.selector)

  const [newRKData, setNewRKData] = useState(null)
  const [step, setStep] = useState(STEPS.CREATING)
  const [type, setType] = useState(RK_TYPES.KINDERGARTEN)
  const [loading, setLoading] = useState(true)

  const tabSwitchHandler = () => {
    setType(type === RK_TYPES.SCHOOL ? RK_TYPES.KINDERGARTEN : RK_TYPES.SCHOOL)
  }

  useEffect(() => {
    if (requestStatus === EVENT_STATUSES.RK_CREATE_FAIL_EXISTS) {
      setStep(STEPS.CREATING)
      dispatch(setRequestStatus.action(''))
    }
    if (requestStatus?.includes(EVENT_STATUSES.RK_JOIN_CHILDREN)) {
      const id = requestStatus.replace(EVENT_STATUSES.RK_JOIN_CHILDREN, '')
      const link = id ? `${PATHS.PARENT_COMMITTEE}/${id}/summary` : PATHS.MY_PARENT_COMMITTEES
      navigate(link)
      dispatch(setRequestStatus.action(''))
    }
  }, [requestStatus])

  useEffect(() => {
    if (location.state.from !== FROM_BTN) {
      navigate(location.state.prevLink)
    } else {
      setLoading(false)
    }
  }, [])

  const onBackClick = useCallback(() => {
    navigate(location.state.prevLink)
  }, [])

  if (loading) {
    return <CircularProgress sx={{margin: '0 auto', padding: '120px', display: 'block'}} />
  }

  return (
    <Wrapper>
      <HeaderWithBackButton
        title={step === STEPS.CREATING ? 'Новый комитет' : 'Данные главы родкома'}
        description={companyName || companyDesc ? `${companyName}, ${companyDesc}` : ''}
        linkText="К учебному заведению"
        onClick={onBackClick}
      />
      {/*{step === STEPS.CREATING && (*/}
      {/*  <Tabs*/}
      {/*    sx={{marginLeft: {xs: '24px', lg: '0'}, marginBottom: {xs: '24px', lg: '48px'}}}*/}
      {/*    value={type}*/}
      {/*    onChange={tabSwitchHandler}*/}
      {/*  >*/}
      {/*    <Tab*/}
      {/*      active={type === RK_TYPES.KINDERGARTEN ? 1 : 0}*/}
      {/*      label={RK_TYPES_RU.KINDERGARTEN}*/}
      {/*      value={RK_TYPES.KINDERGARTEN}*/}
      {/*      id={RK_TYPES.KINDERGARTEN}*/}
      {/*    />*/}
      {/*    <Tab*/}
      {/*      active={type === RK_TYPES.SCHOOL ? 1 : 0}*/}
      {/*      label={RK_TYPES_RU.SCHOOL}*/}
      {/*      value={RK_TYPES.SCHOOL}*/}
      {/*      id={RK_TYPES.SCHOOL}*/}
      {/*    />*/}
      {/*  </Tabs>*/}
      {/*)}*/}
      {step === STEPS.CREATING && (
        <CreatingRodcomForm type={institutionType} setNewRKData={setNewRKData} setStep={setStep} />
      )}
      {step === STEPS.ADDING_INFO && <HeadOfRodkomWrapper newRKData={newRKData} />}
    </Wrapper>
  )
}

export default CreatingRodcom
