import React from 'react'
import {MobileDatePicker, DesktopDatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {Box, FormHelperText, InputLabel} from '@mui/material'
import TextField from '@mui/material/TextField'
import ruLocale from 'date-fns/locale/ru'

import {StyledBox} from './DateInputStyles'
import {MAX_DATE, MIN_DATE} from './constants'
import {estimatedTime} from '../../../utils/EstimatedTime'
import {getDateErrorByReason} from '../../../utils/validations'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import useCheckMobileScreen from '../../../utils/useCheckMobileScreen'
export const DATE_ERRORS = {
  maxDate: 'maxDate',
  minDate: 'minDate',
  shouldDisableDate: 'shouldDisableDate',
  disablePast: 'disablePast',
  disableFuture: 'disableFuture',
  invalidDate: 'invalidDate'
}

const DateInput = ({
  value,
  label,
  withEstimatedText = false,
  onChange,
  id,
  required,
  maxDate = MAX_DATE,
  minDate = MIN_DATE,
  openTo,
  errorMsg,
  setError,
  onError,
  inputFormat,
  className = '',
  mask,
  disabled,
  fullWidth = false
}) => {
  const onChangeDate = (date) => {
    if (errorMsg && setError) {
      setError('')
    }
    if (date) {
      onChange && onChange(date)
    }
  }

  const onDateError = (reason) => {
    const error = getDateErrorByReason({reason, minDate, maxDate})
    if (onError) onError(error)
  }

  const estimatedText = () => {
    const estimatedInfo = value ? estimatedTime(value, false, false) : null
    if (estimatedInfo) return `Это через ${estimatedInfo}`
  }

  const isMobile = useCheckMobileScreen()
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ruLocale}
      localeText={{
        cancelButtonLabel: 'Отмена',
        okButtonLabel: 'Выбрать',
        clearButtonLabel: 'Очистить',
        todayButtonLabel: 'Сегодня'
      }}
    >
      {isMobile ? (
        <MobileDatePicker
          onError={onDateError}
          value={value || null}
          onChange={onChangeDate}
          inputFormat={inputFormat}
          mask={mask}
          // views={['day']}
          desktopModeMediaQuery={'@media screen'}
          renderInput={(params) => {
            const isError = params.error && Boolean(errorMsg)
            params.inputProps.placeholder = 'дд.мм.гггг'
            return (
              <StyledBox className={className}>
                {
                  <InputLabel error={isError} htmlFor={id} shrink required={required}>
                    {label}
                  </InputLabel>
                }
                <TextField
                  {...params}
                  error={isError}
                  className="outer-input-wrapper"
                  placeholder="дд.мм.гггг"
                  id={id}
                  fullWidth
                  sx={fullWidth ? {width: '100%'} : {}}
                />
                {errorMsg && <FormHelperText error>{errorMsg}</FormHelperText>}
                {withEstimatedText && value ? (
                  <FormHelperText sx={{fontSize: '14px'}}>{estimatedText()}</FormHelperText>
                ) : null}
              </StyledBox>
            )
          }}
          maxDate={maxDate}
          minDate={minDate}
          openTo={openTo}
          OpenPickerButtonProps={{
            'aria-label': 'изменить дату'
          }}
          toolbarTitle="дата"
          disabled={disabled}
        />
      ) : (
        <DesktopDatePicker
          sx={{display: {xs: 'none', lg: 'block', ' legend': {display: 'none'}}}}
          onError={onDateError}
          value={value || null}
          onChange={onChangeDate}
          inputFormat={inputFormat}
          mask={mask}
          // views={['day']}
          desktopModeMediaQuery={'@media screen'}
          renderInput={(params) => {
            const isError = params.error && Boolean(errorMsg)
            params.inputProps.placeholder = 'дд.мм.гггг'
            return (
              <StyledBox className={className}>
                {
                  <InputLabel error={isError} htmlFor={id} shrink required={required}>
                    {label}
                  </InputLabel>
                }
                <TextField
                  {...params}
                  error={isError}
                  className="outer-input-wrapper"
                  placeholder="дд.мм.гггг"
                  id={id}
                  fullWidth
                  sx={fullWidth ? {width: '100%', '&.MuiInputAdornment-root': {justifyContent: 'flex-end'}} : {}}
                />
                {errorMsg && <FormHelperText error>{errorMsg}</FormHelperText>}
                {withEstimatedText && value ? <FormHelperText>{estimatedText()}</FormHelperText> : null}
              </StyledBox>
            )
          }}
          maxDate={maxDate}
          minDate={minDate}
          openTo={openTo}
          OpenPickerButtonProps={{
            'aria-label': 'изменить дату'
          }}
          toolbarTitle="дата"
          disabled={disabled}
        />
      )}
    </LocalizationProvider>
  )
}

export default DateInput
