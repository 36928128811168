import React from 'react'

export const SearchIcon = ({className, color = '#39215E'}) => {
  return (
    <svg
      className={className}
      width="36"
      height="32"
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 26L35 31M1 1H33M1 13H9M1 25H9M31 19C31 23.4183 27.4183 27 23 27C18.5817 27 15 23.4183 15 19C15 14.5817 18.5817 11 23 11C27.4183 11 31 14.5817 31 19Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
