export const checker = {
  isEmpty: (valueArg, allowEmptyString = false) => {
    let value = valueArg
    if (typeof value === 'undefined' || value === null) return true
    if (checker.isPlainObject(value)) return Object.keys(value).length === 0
    if (typeof value === 'string') value = value.trim()
    return (!allowEmptyString ? value === '' : false) || (Array.isArray(value) && value.length === 0)
  },

  isPlainObject: (obj) => {
    if (typeof obj === 'object' && obj !== null) {
      // if (Object.getPrototypeOf === '') {
      //   const proto = Object.getPrototypeOf(obj)

      //   return proto === Object.prototype || proto === null
      // }

      return Object.prototype.toString.call(obj) === '[object Object]'
    }

    return false
  }
}
