export const CHOICE_TYPE = {
  ONLY_ONE: 'ONLY_ONE',
  ALLOW_MANY: 'ALLOW_MANY'
}

export const VOTE_STATUSES = {
  COMPLETED: 'COMPLETED',
  PUBLISHED: 'PUBLISHED'
}

export const EVENT_VOTE_STATUSES = {
  VOTE_CREATED: {STATUS: 'VOTE_CREATED', MESSAGE_TO_USER: 'Голосование успешно создано'},
  VOTE_UPDATED: {STATUS: 'VOTE_UPDATED', MESSAGE_TO_USER: 'Голосование успешно отредактировано'},
  VOTE_INFO_LIST_GET: {STATUS: 'VOTE_INFO_LIST_GET', MESSAGE_TO_USER: ''},
  VOTE_RESULT_SUCCESS: {STATUS: 'VOTE_RESULT_SUCCESS', MESSAGE_TO_USER: 'Вы успешно проголосовали'},
  USER_NOT_PARENT_CHIEF: {STATUS: 'USER_NOT_PARENT_CHIEF', MESSAGE_TO_USER: ''},
  VOTE_FAIL_NOT_FOUND: {STATUS: 'VOTE_FAIL_NOT_FOUND', MESSAGE_TO_USER: ''},
  VOTE_ARCHIVED_SUCCESS: {STATUS: 'VOTE_ARCHIVED', MESSAGE_TO_USER: 'Голосование отправлено в архив'}
}

export const INIT_VOTING_STATE = {
  title: '',
  question: '',
  description: '',
  isPrivate: false,
  choiceType: CHOICE_TYPE.ONLY_ONE,
  projectUuid: '',
  deadlineAt: ''
}

export const INIT_VOTING_OPTIONS_STATE = [
  {
    number: 0,
    text: '',
    emoji: {}
  },
  {
    number: 1,
    text: '',
    emoji: {}
  }
]
