import React from 'react'

export const CalendarIcon = ({className, color = '#39215E'}) => {
  return (
    <svg
      className={className}
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.75 7.75H8.75C8.33579 7.75 8 8.08579 8 8.5V23.5C8 23.9142 8.33579 24.25 8.75 24.25H23.75C24.1642 24.25 24.5 23.9142 24.5 23.5V8.5C24.5 8.08579 24.1642 7.75 23.75 7.75Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M20.75 6.25V9.25" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.75 6.25V9.25" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 12.25H24.5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
