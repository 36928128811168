import React, {useCallback, useMemo} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import Avatar from '@mui/material/Avatar'
import AvatarGroup from '@mui/material/AvatarGroup'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'

import {VoitingCard, Text} from './VoitingItemStyle'
import {ButtonWrapper, SecondButtonWrapper} from '../projects/ProjectsStyle'
import {Button, Box} from '@mui/material'
import {estimatedTime} from '../../../../../../utils/EstimatedTime'
import {PATHS} from '../../../../../../routes/paths'
import {avatarURL} from '../../../../../../utils/imageURL'

const VotingItem = ({uuid, question, voteAnswers, deadlineAt}) => {
  const navigate = useNavigate()
  const params = useParams()
  const handleClickBtn = useCallback(() => {
    navigate(`${PATHS.PARENT_COMMITTEE}/${params.id}${PATHS.PARENT_COMMITTEE_VOTES}/${uuid}`)
  }, [])

  const estimated = estimatedTime(deadlineAt)

  const button = useMemo(() => {
    const estimated = estimatedTime(deadlineAt)
    if (!estimated) {
      return null
    }

    return (
      <Button
        variant="contained"
        type="button"
        className="btn"
        onClick={handleClickBtn}
        endIcon={<ArrowRightAltIcon />}
        sx={{
          marginTop: '12px',
          background: 'transparent',
          color: '#39215E',
          boxShadow: 'none',
          // borderBottom: '1px solid #39215E',
          // display: 'inline',
          borderRadius: 0,
          width: 'fit-content',
          paddingBottom: '0px',
          alignSelf: 'center'
        }}
      >
        Голосовать
      </Button>
    )
  }, [deadlineAt])

  const avatars = useMemo(() => {
    return voteAnswers.map(({userFullname, userAvatarUrl, userUuid}) => {
      return (
        <Avatar
          sx={{width: '32px', height: '32px'}}
          key={userUuid}
          alt={`${userFullname?.surname} ${userFullname?.firstName}`}
          src={avatarURL(userAvatarUrl)}
        />
      )
    })
  }, [])

  return (
    <VoitingCard onClick={() => handleClickBtn()} sx={{cursor: 'pointer'}}>
      <Box>
        {estimated ? (
          <Text variant="body1" sx={{marginBottom: '4px !important', fontSize: 12, fontWeight: 600}}>
            Eще {estimated}
          </Text>
        ) : (
          <Text variant="body1" sx={{marginBottom: '4px !important', fontSize: 12, fontWeight: 600}}>
            Завершено
          </Text>
        )}
        <Text variant="body1" className="title" sx={{marginBottom: '6px !important'}}>
          {question}
        </Text>
        <AvatarGroup sx={{justifyContent: 'flex-end', height: '34px'}} max={4}>
          {avatars}
        </AvatarGroup>
      </Box>
      {button}
    </VoitingCard>
  )
}

export default VotingItem
