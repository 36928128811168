import {styled} from '@mui/material/styles'
import {Box, Typography} from '@mui/material'

export const Wrapper = styled('div')(({theme}) => ({
  margin: '0 0 24px 0',
  [theme.breakpoints.up('md')]: {
    marginBottom: '64px'
  },
  [theme.breakpoints.up(1224)]: {
    margin: '0 0 24px 0'
  },
  '.tab': {
    fontSize: 14,
    '& .MuiTouchRipple-child': {
      backgroundColor: 'transparent !important'
    }
  },
  '.tab_box': {
    marginBottom: '24px',
    [theme.breakpoints.down(600)]: {
      marginBottom: '24px'
    }
  }
}))

export const StyledItem = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: theme.palette.background.paper,
  borderRadius: 12,
  boxShadow: '0px 12px 12px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  padding: 24,
  marginBottom: 16,
  // '& p, & h6': {
  //   whiteSpace: 'nowrap',
  //   overflow: 'hidden',
  //   textOverflow: 'ellipsis'
  // },
  [theme.breakpoints.down(900)]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& button': {
      width: '100%',
      marginTop: 24
    }
  },
  [theme.breakpoints.down(600)]: {
    padding: 16,
    '& button': {
      width: '100%',
      marginTop: 24
    }
  }
  // button: {
  //   fontSize: 16,
  //   color: '#39215E',
  //   background: 'rgba(57, 33, 94, 0.12)',
  //   border: '1px solid rgba(255, 255, 255, 1e-05)',
  //   svg: {
  //     path: {
  //       stroke: '#39215E'
  //     }
  //   },
  //   '&:hover': {
  //     background: 'rgba(57, 33, 94, 0.24)',
  //     border: '1px solid rgba(255, 255, 255, 1e-05)'
  //   },
  //   '&:focused': {
  //     border: '1px solid rgba(255, 255, 255, 1e-05)'
  //   },
  //   '&:active': {
  //     boxShadow:
  //       ' 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
  //   }
  // }
}))

export const LeftBlock = styled('div')(({theme}) => ({
  width: 'calc(100% - 275px)',
  marginLeft: 24,
  [theme.breakpoints.down(900)]: {
    width: '100%',
    marginLeft: 0
  }
}))

export const ClassWrapper = styled('div')(({theme}) => ({
  width: '115px',
  boxSizing: 'border-box',
  borderRight: 'none',
  textAlign: 'left',
  [theme.breakpoints.up(900)]: {
    borderRight: `1px solid ${theme.palette.grey[100]}`
  }
}))

export const Class = styled(Typography)(({theme}) => ({
  fontSize: '32px',
  fontWeight: 700,
  lineHeight: '44px',
  letterSpacing: '0px',
  marginBottom: 24,
  [theme.breakpoints.up('md')]: {
    fontSize: '48px',
    fontWeight: 500,
    lineHeight: '56px',
    marginBottom: 0
  }
}))

export const Title = styled(Typography)(({theme}) => ({
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '28x',
  marginBottom: 8,
  color: theme.palette.primary.main,
  [theme.breakpoints.down(900)]: {
    fontSize: '16px',
    lineHeight: '28px',
    whiteSpace: 'normal',
    marginBottom: 24
  }
}))

export const StyledBox = styled(Box)(({theme}) => ({
  display: 'flex',
  [theme.breakpoints.down(1000)]: {
    flexDirection: 'column'
  },
  [theme.breakpoints.down(900)]: {
    marginBottom: 6
  }
}))

export const InfoLabel = styled(Typography)(({theme}) => ({
  color: theme.palette.grey[500],
  marginRight: 24,
  fontSize: 16,
  [theme.breakpoints.down(1000)]: {
    marginBottom: 6
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 14
  }
}))
