import {styled} from '@mui/material/styles'
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip'
import React from 'react'

export const HtmlTooltip = styled(({className, ...props}) => <Tooltip classes={{popper: className}} {...props} />)(
  ({theme}) => ({
    [`.${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.background,
      color: theme.palette.primary,
      maxWidth: 400,
      fontSize: theme.typography.fontSize
    }
  })
)
