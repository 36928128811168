import React, {useRef, useMemo, useEffect} from 'react'

import TextField from '@mui/material/TextField'
import {styled} from '@mui/material/styles'
import {Typography, FormControl} from '@mui/material'

export const SmsLabel = styled(Typography)({
  // paddingLeft: 4,
  fontWeight: 'bold',
  marginBottom: 8
})

export const SmsField = styled(TextField)({
  width: '22%',
  marginBottom: 0
})

export const SmsInput = ({code, setCode}) => {
  const firstNumber = useRef(null)
  const secondNumber = useRef(null)
  const thirdNumber = useRef(null)
  const fourthNumber = useRef(null)

  const refs = useMemo(() => [firstNumber, secondNumber, thirdNumber, fourthNumber], [])

  useEffect(() => {
    const isLastFilled = code[code.length - 1]
    if (isLastFilled && fourthNumber?.current) {
      fourthNumber.current.focus()
    }
  }, [])

  const setPasteCode = (value) => {
    const test = value.split('')
    const newCode = [...code]
    test.forEach((item, i) => {
      newCode[i] = item
    })
    const refIndex = code.length <= value.length ? value.length - 1 : value.length
    const nextRef = refs[refIndex]
    if (nextRef?.current) {
      nextRef.current.focus()
    }
    setCode(newCode)
  }

  const setNewCode = (value, index) => {
    const newCode = [...code]
    newCode[index] = value
    setCode(newCode)
  }

  const onChange = (event, index) => {
    const {value} = event.target
    const isPasteCode = !index && /^[0-9]+$/g.test(value) && value.length > 1
    if (isPasteCode) {
      setPasteCode(value)
      return
    }
    if (/^[0-9]?$/g.test(value)) {
      setNewCode(value, index)
      if (value) {
        const nextIndex = index + 1
        const lastIndex = code.length - 1
        if (nextIndex <= lastIndex) {
          const nextRef = refs[nextIndex]
          if (nextRef?.current) {
            nextRef.current.focus()
          }
        }
      }
    }
  }

  const onKeyUp = (e) => {
    if (e.key === 'Shift' || e.key === 'Tab' || e.key === 'Alt' || e.key === 'Control') {
      return
    }
    const {name} = e.target
    const index = Number(name)
    if (e.key === 'Backspace' || e.key === 'ArrowLeft') {
      const prevIndex = index - 1
      if (prevIndex >= 0) {
        const prevRef = refs[prevIndex]
        if (prevRef?.current) {
          prevRef.current.focus()
        }
      }
    }
  }

  return (
    <FormControl variant="standard" fullWidth={true} required={true} sx={{margin: 0}}>
      <SmsLabel variant="h4">Код из СМС</SmsLabel>
      <div className="fieldsWrapper">
        {code.map((item, index) => (
          <SmsField
            type="tel"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              autoComplete: 'one-time-code'
            }}
            autoFocus={!index}
            key={index}
            onKeyUp={onKeyUp}
            inputRef={refs[index]}
            value={item}
            name={String(index)}
            onChange={(event) => onChange(event, index)}
            autocomplete
          />
        ))}
      </div>
    </FormControl>
  )
}
