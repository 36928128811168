import React from 'react'

export const LockIcon = ({className, color = '#39215E'}) => {
  return (
    <svg
      className={className}
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={color}
        d="M8.75 3.75H7.0625V2.4375C7.0625 1.89049 6.8452 1.36589 6.45841 0.979092C6.07161 0.592299 5.54701 0.375 5 0.375C4.45299 0.375 3.92839 0.592299 3.54159 0.979092C3.1548 1.36589 2.9375 1.89049 2.9375 2.4375V3.75H1.25C1.05116 3.75023 0.860524 3.82932 0.719921 3.96992C0.579318 4.11052 0.500227 4.30116 0.5 4.5V9.75C0.500227 9.94884 0.579318 10.1395 0.719921 10.2801C0.860524 10.4207 1.05116 10.4998 1.25 10.5H8.75C8.94884 10.4998 9.13948 10.4207 9.28008 10.2801C9.42068 10.1395 9.49977 9.94884 9.5 9.75V4.5C9.49977 4.30116 9.42068 4.11052 9.28008 3.96992C9.13948 3.82932 8.94884 3.75023 8.75 3.75ZM3.6875 2.4375C3.6875 2.0894 3.82578 1.75556 4.07192 1.50942C4.31806 1.26328 4.6519 1.125 5 1.125C5.3481 1.125 5.68194 1.26328 5.92808 1.50942C6.17422 1.75556 6.3125 2.0894 6.3125 2.4375V3.75H3.6875V2.4375Z"
        fill="#A2A9B0"
      />
    </svg>
  )
}
