import React, {useState, useMemo} from 'react'

import {Dialog, Typography} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import Button from '@mui/material/Button'

import StyledSelect from '../../../../../../../components/Inputs/StyledSelect/StyledSelect'
import {CheckIcon} from '../../../../../../../icons'
import {ButtonsBlock, ModalContent} from '../BoundedVotes/BoundedVotesStyle'

const VoteModal = ({votesList, isOpen, onClose, addVote}) => {
  const [vote, setVote] = useState(null)
  // todo set votes list
  const theme = useTheme()

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const options = useMemo(() => votesList.map(({title, uuid}) => ({id: uuid, label: title})), [votesList])

  const handleClose = () => {
    onClose()
    setVote(null)
  }

  const onAdd = () => {
    addVote(votesList.find(({uuid}) => uuid === vote))
    handleClose()
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} fullScreen={fullScreen} fullWidth maxWidth="xs">
      <ModalContent>
        {!options.length ? (
          <Typography variant="h4" sx={{fontSize: 24, marginBottom: '24px'}}>
            Нет доступных голосований
          </Typography>
        ) : (
          <StyledSelect
            id="vote"
            label="Голосование"
            value={vote}
            onChangeValue={setVote}
            options={options}
            secondLabel="Выберите голосование"
            selectById
          />
        )}
        <ButtonsBlock>
          <Button className="close-button" variant="outlined" onClick={handleClose}>
            Отмена
          </Button>
          <Button
            disabled={!vote}
            className="add-button"
            onClick={onAdd}
            variant="contained"
            startIcon={<CheckIcon color="white" />}
          >
            Привязать
          </Button>
        </ButtonsBlock>
      </ModalContent>
    </Dialog>
  )
}

export default VoteModal
