import React from 'react'
import {List, ListItem, Typography, useTheme} from '@mui/material'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import {FOOTER_PAGES} from './AppFooterConstants'
import {styled} from '@mui/material/styles'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import {HtmlTooltip} from '../Tooltip/Tooltips'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import {StyledLabel} from '../../pages/ParentCommittee/subpages/Projects/Project/components/Fundraising/FundraisingStyle'
import {useSelector} from 'react-redux'
import {directoriesSelectors} from '../../store/directoriesStore/directoriesStore'

const Footer = styled('footer')(({theme}) => ({
  background: 'white',
  // height: 100,
  marginTop: 64,
  display: 'flex',
  flexDirection: 'row',
  padding: '30px 0',
  alignItems: 'center',
  '& .container': {
    padding: '0 16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  '& .wrapper': {
    padding: 0
  },
  '& .line': {
    display: 'none'
  },
  [theme.breakpoints.down('lg')]: {
    padding: '18px 0px',
    '& .container': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center'
    },
    '& .wrepper': {
      marginLeft: 0
    },
    '& .line': {
      display: 'block',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      width: '100%',
      margin: '24px 0'
    }
  }
}))

export const FooterLink = styled(Link)(({theme}) => ({
  display: 'block',
  marginBottom: '18px',
  fontWeight: 400,
  fontSize: 16,
  [theme.breakpoints.up('lg')]: {
    display: 'inline',
    margin: '0 19px 0 0',
    '&:last-child': {
      marginRight: 0
    }
  },
  '&:last-child': {
    marginBottom: 0
  }
}))

const AppFooter = () => {
  const theme = useTheme()
  const payoutCommission = useSelector(directoriesSelectors.payoutCommission.selector)
  return (
    <Footer>
      <Container className="container" maxWidth="lg">
        <Box
          className="wrapper"
          sx={{
            typography: 'button',
            padding: '38px 0'
          }}
          // onClick={(event) => event.preventDefault()}
        >
          {/*{FOOTER_PAGES.map((page) => {*/}
          {/*  return (*/}
          {/*    <FooterLink key={page} href="#">*/}
          {/*      {page}*/}
          {/*    </FooterLink>*/}
          {/*  )*/}
          {/*})}*/}
          <FooterLink target="_blank" href="https://rodcom.ru/#blog">
            Блог
          </FooterLink>
          <FooterLink target="_blank" href="mailto:support@rodcom.ru">
            Помощь
          </FooterLink>

          <StyledLabel
            sx={{
              display: 'inline'
            }}
          >
            <HtmlTooltip
              leaveTouchDelay={20000}
              enterTouchDelay={1}
              disableFocusListener
              sx={{color: 'black'}}
              title={
                <>
                  <Typography color="inherit" paragraph="true" sx={{margin: '0'}}>
                    <List
                      sx={{
                        listStyleType: 'disc',
                        pl: 2,
                        '& .MuiListItem-root': {
                          display: 'list-item',
                          fontSize: '14px',
                          padding: '0px'
                        },

                        listStylePosition: 'inside'
                      }}
                    >
                      <ListItem>пользование сервисом - бесплатно;</ListItem>
                      <ListItem>пополнение копилки/проектов - бесплатно;</ListItem>
                      <ListItem>вывод средств на карту главы комитета - {payoutCommission}%.</ListItem>
                    </List>
                  </Typography>
                </>
              }
            >
              <FooterLink
                underline="none"
                sx={{'&:hover': {textDecoration: 'none !important'}}}
                // target="_blank"
                href="#"
                onClick={(e) => e.preventDefault()}
              >
                Тариф
              </FooterLink>
            </HtmlTooltip>
          </StyledLabel>
        </Box>
        <Divider className="line" />
        <Box
          className="wrapper"
          sx={{
            typography: 'button',
            padding: '38px 0'
          }}
          // onClick={(event) => event.preventDefault()}
        >
          <FooterLink target="_blank" href="https://rodcom.ru/info">
            Правовые документы
          </FooterLink>
        </Box>
      </Container>
    </Footer>
  )
}
export default AppFooter
