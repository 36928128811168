import React from 'react'

import {Box, Button, IconButton} from '@mui/material'

import {Text, Wrapper, Arrow, ButtonsWrapper} from './ParagraphWrapperStyle'
import {PlusIcon} from '../../../../../icons'

const ParagraphWrapper = ({title, handleClickButton, handleClickTitle, withSlider, proj}) => {
  return (
    <Wrapper>
      <Box sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={handleClickTitle}>
        <Text variant="h2">{title}</Text>
        {handleClickTitle && <Arrow />}
      </Box>
      {handleClickButton && (
        <>
          <Button
            variant="contained"
            sx={{marginLeft: 'auto'}}
            startIcon={<PlusIcon color="white" />}
            color={proj ? 'info' : 'primary'}
            type="button"
            onClick={handleClickButton}
            className={`desktop-add-button pro ${proj ? 'proj' : ''}`}
          >
            Добавить
          </Button>
          <IconButton
            variant="contained"
            sx={{marginLeft: 'auto'}}
            type="button"
            onClick={handleClickButton}
            className="mobile-add-button"
          >
            <PlusIcon color="black" />
          </IconButton>
        </>
      )}
      {withSlider && <ButtonsWrapper />}
    </Wrapper>
  )
}

export default ParagraphWrapper
