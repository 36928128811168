import {styled} from '@mui/material/styles'
import {Typography, Button} from '@mui/material'

export const FormWrapper = styled('div')(({theme}) => ({
  padding: 32,
  background: '#ffffff',
  borderRadius: 12,
  // boxShadow: '0px 12px 32px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  border: '1px solid #EDEDFF',
  marginTop: 24,
  [theme.breakpoints.between('md', 1224)]: {
    // margin: '0 19px 38px'
  },
  [theme.breakpoints.down('md')]: {
    // padding: 10
    // margin: '0 0 19px'
  },
  '.settingsPage': {
    marginTop: 0,
    '.settingInp': {
      marginBottom: '20px !important'
    }
  },
  [theme.breakpoints.down(1000)]: {
    width: 'auto'
  },
  '.modal': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      '.close': {
        marginTop: 30
      }
    }
  }
}))

export const SettingBlock = styled('div')(({theme}) => ({
  marginTop: 32,
  display: 'flex',
  flexDirection: 'row',
  gap: 19,
  width: '100%',
  '.left': {
    width: '67%',
    flex: '1 1 67%',
    marginTop: 0
  },
  '.right': {
    maxWidth: 400,
    flex: '1 1 33%',
    marginTop: 0
  },
  [theme.breakpoints.down(1000)]: {
    flexDirection: 'column',
    '.left': {
      width: 'auto'
    },
    '.right': {
      maxWidth: '100%'
    }
  }
}))

export const Header = styled(Typography)(({theme}) => ({
  fontSize: '18px',
  lineHeight: '29px',
  fontWeight: 700,
  color: theme.palette.primary.main,
  marginBottom: '24px !important',
  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
    lineHeight: '22px'
  }
}))

export const StyledButton = styled(Button)(({theme}) => ({
  svg: {
    path: {
      stroke: '#39215E !important'
    }
  },
  [theme.breakpoints.down(480)]: {
    width: '100%'
  }
}))
