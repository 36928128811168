import React, {useCallback, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import ErrorOutline from '@mui/icons-material/ErrorOutline'
import Button from '@mui/material/Button'

import StyledAlert from '../../../../../../components/StyledAlert/StyledAlert'
import {RK_TYPES} from '../../../../../CreatingRodcom/constants/creatingRodcomConstants'
import SchoolForm from '../../../../../CreatingRodcom/components/SchoolForm/SchoolForm'
import KindergartenForm from '../../../../../CreatingRodcom/components/KindergartenForm/KindergartenForm'
import {CheckIcon} from '../../../../../../icons'
import {getEmptyError, getNameError, getUrlError} from '../../../../../../utils/validations'
import {findSelectedValueInDirectories} from '../../../../../../utils/getOptionsFromDirectories'
import {updateRkProfileEvent} from '../../../../../../store/committeeSettingsStore/committeeSettingsStoreSliceEvents'
import {committeeSettingsSelectors} from '../../../../../../store/committeeSettingsStore/committeeSettingsStore'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import {useNavigate} from 'react-router-dom'
import {Box, CircularProgress, Dialog, Typography} from '@mui/material'
import {deleteRkEvent} from '../../../../../../store/committeeSettingsStore/committeeSettingsStoreSliceEvents'

const CommitteeParametersForm = (props) => {
  const {
    uuid: uuidFromProps,
    onOpenChatInfoModal,
    classes,
    classLetter,
    setSchoolFieldValues,
    schoolErrors,
    setSchoolErrors,
    setKindergartenErrors,
    setKindergartenFieldValues,
    kindergartenErrors,
    type,
    schoolFieldValues,
    kindergartenFieldValues,
    isFormDisabled
  } = props

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const address = useSelector(committeeSettingsSelectors.address.selector)
  const profile = useSelector(committeeSettingsSelectors.profileInfo.selector)
  const [openConfirmDeleteParentCommittee, setOpenConfirmDeleteParentCommittee] = useState(false)
  const setSchoolFieldValue = useCallback(
    (value, field) => {
      setSchoolFieldValues((values) => ({...values, [field]: value}))
    },
    [setSchoolFieldValues]
  )

  const setSchoolErrorField = useCallback(
    (field, isError) => {
      setSchoolErrors((errs) => ({...errs, [field]: isError}))
    },
    [setSchoolErrors]
  )

  const onSchoolChangeValue = useCallback(
    (value, field) => {
      const isError = schoolErrors[field]
      if (isError) {
        setSchoolErrorField(field, false)
      }
      setSchoolFieldValue(value, field)
    },
    [schoolErrors, setSchoolErrorField, setSchoolFieldValue]
  )

  const setKindergartenErrorField = useCallback(
    (field, isError) => {
      setKindergartenErrors((errs) => ({...errs, [field]: isError}))
    },
    [setKindergartenErrors]
  )

  const setKindergartenFieldValue = useCallback(
    (value, field) => {
      setKindergartenFieldValues((values) => ({...values, [field]: value}))
    },
    [setKindergartenFieldValues]
  )

  const onKindergartenChangeValue = useCallback(
    (value, field) => {
      const isError = kindergartenErrors[field]
      if (isError) {
        setKindergartenErrorField(field, false)
      }
      if (field === 'groupName' && kindergartenErrors.groupNumber) {
        setKindergartenErrorField('groupNumber', false)
      }
      if (field === 'groupNumber' && kindergartenErrors.groupName) {
        setKindergartenErrorField('groupName', false)
      }
      setKindergartenFieldValue(value, field)
    },
    [kindergartenErrors, setKindergartenErrorField, setKindergartenFieldValue]
  )

  const chatSecondLabel = useMemo(() => {
    return (
      <span style={{fontSize: 12}}>
        Ссылка на чат в Telegram или WhatsApp.{' '}
        <a href="#" onClick={onOpenChatInfoModal}>
          Где её взять?
        </a>
      </span>
    )
  }, [onOpenChatInfoModal])

  const addressInfo = useMemo(() => {
    return (
      <div style={{marginBottom: '24px'}}>
        <div>{profile.institutionName}</div>
        <div>Адрес: {address.fullAddress}</div>
      </div>
    )
  }, [address])

  const onSchoolSubmit = () => {
    const newErrors = {}
    const {grade, letter, educator, name, chat, uuid} = schoolFieldValues
    newErrors.grade = getEmptyError(grade)
    newErrors.letter = getEmptyError(letter)
    newErrors.chat = getUrlError(chat)
    setSchoolErrors(newErrors)
    const hasErrors = Object.values(newErrors).some((el) => el)
    if (hasErrors) {
      return
    }
    const myData = {
      uuid,
      groupName: letter,
      institutionType: type,
      classUuid: findSelectedValueInDirectories(classes, grade),
      classLetterUuid: findSelectedValueInDirectories(classLetter, letter),
      chatLink: chat
    }
    if (name.trim()) {
      myData.name = name
    }
    if (educator.trim()) {
      myData.educator = educator
    }

    dispatch(updateRkProfileEvent.action(uuidFromProps, myData))
  }

  const onKindergartenSubmit = () => {
    const newErrors = {}
    const {educator, groupName, groupNumber, chat, name, uuid, yearOfAdmission} = kindergartenFieldValues
    newErrors.educator = getNameError(educator)
    const groupNameError = getEmptyError(groupName)
    const groupNumberError = getEmptyError(groupNumber)
    if (groupNumber || groupName) {
      newErrors.groupName = false
      newErrors.groupNumber = false
    } else {
      newErrors.groupName = groupNameError
      newErrors.groupNumber = groupNumberError
    }
    newErrors.chat = getUrlError(chat)
    setKindergartenErrors(newErrors)
    const hasErrors = Object.values(newErrors).some((el) => el)
    if (hasErrors) {
      return
    }
    const requestData = {
      uuid,
      yearOfAdmission,
      institutionType: type,
      name,
      educator,
      chatLink: chat
    }
    if (groupName.trim()) {
      requestData.groupName = groupName
    }
    if (groupNumber.trim()) {
      requestData.groupNumber = groupNumber
    }

    dispatch(updateRkProfileEvent.action(uuidFromProps, requestData))
  }

  const handleClickTransfer = useCallback(() => {
    navigate(`${window?.location?.pathname}/assignment`)
  }, [])

  const form = useMemo(
    () =>
      type === RK_TYPES.SCHOOL ? (
        <SchoolForm
          isFormDisabled={isFormDisabled}
          errors={schoolErrors}
          fieldValues={schoolFieldValues}
          onChangeValue={onSchoolChangeValue}
          classes={classes}
          setSchoolErrorField={setSchoolErrorField}
          classLetter={classLetter}
          withYearOfAdmission={false}
          chatSecondLabel={chatSecondLabel}
        />
      ) : (
        <KindergartenForm
          isFormDisabled={isFormDisabled}
          errors={kindergartenErrors}
          fieldValues={kindergartenFieldValues}
          onChangeValue={onKindergartenChangeValue}
          setKindergartenErrorField={setKindergartenErrorField}
        />
      ),
    [
      type,
      schoolErrors,
      schoolFieldValues,
      kindergartenErrors,
      kindergartenFieldValues,
      onKindergartenChangeValue,
      onSchoolChangeValue,
      classes,
      classLetter,
      chatSecondLabel,
      isFormDisabled,
      setSchoolErrorField,
      setKindergartenErrorField
    ]
  )

  const handleSaveClick = (e) => {
    e.preventDefault()
    if (type === RK_TYPES.SCHOOL) {
      onSchoolSubmit()
    } else {
      onKindergartenSubmit()
    }
  }
  const handleDeleteRk = useCallback(() => {
    dispatch(deleteRkEvent.action(uuidFromProps))
  }, [])

  return (
    <>
      {/* <Dialog
        open={openConfirmDeleteParentCommittee}
        onClose={() => setOpenConfirmDeleteParentCommittee(false)}
        fullWidth
        maxWidth="xs"
      >
        <Box sx={{padding: '38px', borderRadius: '24px', display: 'flex', flexDirection: 'column', gap: '19px'}}>
          <Typography sx={{fontSize: 16, fontWeight: 700, textAlign: 'center'}}>
            Вы действительно хотите удалить этот родительский коммитет?
          </Typography>
          <Button variant="contained" onClick={handleDeleteRk} sx={{marginTop: '24px'}}>
            Удалить
          </Button>
          <Button variant="outlined" onClick={() => setOpenConfirmDeleteParentCommittee(false)}>
            Отмена
          </Button>
        </Box>
      </Dialog> */}
      {addressInfo}
      {form}
      <StyledAlert
        withoutIconOnMobile
        title="Менять параметры можно не чаще 1 раза в месяц."
        icon={false}
        sx={{maxWidth: '500px'}}
      >
        {isFormDisabled && 'Последнее сохранение было 04.05.2021, поэтому изменения можно внести только через 21 день.'}
      </StyledAlert>
      {!isFormDisabled && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: {xs: 'column', lg: 'row'},
            marginTop: '32px'
          }}
        >
          <Button
            variant="contained"
            onClick={handleSaveClick}
            startIcon={<CheckIcon color="white" />}
            sx={{width: 'fit-content', maxWidth: '500px'}}
          >
            Сохранить
          </Button>
          {/* <Button
            variant="contained"
            onClick={handleClickTransfer}
            sx={{height: 46, width: {xs: '100%', lg: '200px'}}}
            endIcon={<ArrowRightAltIcon className="arrowRight" />}
          >
            Передача прав
          </Button>
          <Button
            variant="contained"
            onClick={() => setOpenConfirmDeleteParentCommittee(true)}
            sx={{width: {xs: '100%', lg: '200px'}}}
          >
            Удалить
          </Button> */}
        </Box>
      )}
    </>
  )
}

export default CommitteeParametersForm
