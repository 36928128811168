import React, {useCallback, useEffect, useMemo} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {CircularProgress, Typography} from '@mui/material'

import ParagraphWrapper from '../components/ParagraphWrapper/ParagraphWrapper'
import Projects from './components/projects/Projects'
import Invite from './components/Invite/Invite'
import {PATHS} from '../../../../routes/paths'
import Members from './components/members/Members'
import AcceptingTheRoleBlock from './components/AcceptingTheRoleBlock/AcceptingTheRoleBlock'
import {committeeSettingsSelectors} from '../../../../store/committeeSettingsStore/committeeSettingsStore'
import {accrualsSelectors} from '../../../../store/accrualsStore/accrualStore'
import {votingSelectors} from '../../../../store/votingStore/votingStore'
import Reports from './components/reports/Reports'
import {STATUSES_IN} from '../../../MyRodcomesPage/constants/myRodcomesPageConstants'
import {projectsSelectors} from '../../../../store/projectsStore/projectsStore'
import {PublishStatusesEnum} from '../constants/parentCommitteeConstants'
import InfoBox from '../../../../components/Layouts/InfoBox/InfoBox'
import {
  transferMembershipMeEvent,
  transferMembershipMeEventSuccess
} from '../../../../store/committeeSettingsStore/committeeSettingsStoreSliceEvents'
import Votings from './components/Votings/Votings'
import report1 from '../../../../images/report1.svg'
import report2 from '../../../../images/report2.svg'
import report3 from '../../../../images/report3.svg'
import report4 from '../../../../images/report4.svg'

import {getSummaryEvent} from 'store/accrualsStore/accrualStoreSliceEvents'

import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined'
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined'
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined'
import {getRkMembersRequest} from '../../../../services/committeeSettings'

function humanize(x) {
  return x.toFixed(6).replace(/\.?0*$/, '')
}
function roundAmount(x) {
  if (typeof x !== 'number') return x
  else return roundAmount(x.toFixed(0))
}

const SummaryPage = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const projectsList = useSelector(projectsSelectors.projectsList.selector)
  const roles = useSelector(committeeSettingsSelectors.roles.selector)
  const transferMemberApplication = useSelector(committeeSettingsSelectors.transferMemberApplication.selector)
  const votesList = useSelector(votingSelectors.votesList.selector)
  const members = useSelector(committeeSettingsSelectors.membersParentCommittee.selector)
  const isLoading = useSelector(committeeSettingsSelectors.isLoading.selector)
  const accrualsLoading = useSelector(accrualsSelectors.loading.selector)
  const summary = useSelector(accrualsSelectors.summary.selector)

  const isMember = true

  useEffect(() => {
    dispatch(getSummaryEvent.action(id))
    getRkMembersRequest(id)
  }, [])

  // const actualProjects = useMemo(
  //  () => projectsList?.filter((project) => project.status === PublishStatusesEnum.PUBLISHED),
  //   [projectsList]
  // )
  let actualProjects = projectsList
    ?.filter((project) => project.status === PublishStatusesEnum.PUBLISHED)
    .filter((date) => new Date(date.lastDeadlineDate) > new Date() || date.type === 'PIGGY')
    .sort((a, b) => new Date(a.lastDeadlineDate) - new Date(b.lastDeadlineDate))

  actualProjects = [
    ...actualProjects,
    ...projectsList
      .filter((project) => project.status === PublishStatusesEnum.PUBLISHED)
      .filter((i) => new Date(i.lastDeadlineDate) < new Date() && i.type !== 'PIGGY')
  ]

  const x = votesList
    .filter((voting) => !voting.isArchived)
    .filter((i) => new Date(i.deadlineAt) < new Date())
    .sort((a, b) => new Date(b.deadlineAt) - new Date(a.deadlineAt))
  let votesListNotArchived = votesList
    .filter((voting) => !voting.isArchived)
    .filter((i) => new Date(i.deadlineAt) > new Date())
    .sort((a, b) => new Date(a.deadlineAt) - new Date(b.deadlineAt))
  votesListNotArchived.push(...x)

  const isChief = useMemo(() => roles?.includes(STATUSES_IN.Chief), [roles])

  const onAddMemberClick = () => {
    navigate(`${location.pathname.replace(PATHS.PARENT_COMMITTEE_SUMMARY, '')}${PATHS.PARENT_COMMITTEE_MEMBER_RECORD}`)
  }

  const getApplication = useCallback(() => {
    if (!isChief) {
      dispatch(transferMembershipMeEvent.action(id))
    }
  }, [dispatch, id])

  const reports = useMemo(() => {
    if (summary) {
      return [
        {
          icon: <SavingsOutlinedIcon sx={{width: '70px', height: '70px', color: '#39215E'}} />,
          text: 'Баланс комитета',

          //TODO выровнить summary.rkBalance.Должен учитываться вместе с выведенными средствами
          sum: `${roundAmount(summary.rkBalance + summary.totalDebtByRk)} ₽`
        },
        {
          icon: <CreditScoreOutlinedIcon sx={{width: '70px', height: '70px', color: '#39215E'}} />,
          text: 'Собрали за прошлый месяц',
          sum: `${roundAmount(summary.paidByRk)} ₽`
        },
        {
          icon: <AccountBalanceWalletOutlinedIcon sx={{width: '70px', height: '70px', color: '#39215E'}} />,
          text: 'Мои взносы за прошлый месяц',
          sum: `${roundAmount(summary.paidByMe)} ₽`
        },
        {
          icon: <AddCardOutlinedIcon sx={{width: '70px', height: '70px', color: '#39215E'}} />,
          text: 'Мне нужно внести',
          sum: `${roundAmount(summary.toPayByMe)} ₽`
        }
      ]
    }
    return []
  }, [summary])
  useEffect(getApplication, [])

  const chiefName = useMemo(() => {
    const foundMember = members?.find(({userUuid}) => userUuid === transferMemberApplication?.fromUserUuid)
    if (!foundMember) {
      return null
    }
    return `${foundMember?.fullName?.surname || ''} ${foundMember?.fullName?.firstName || ''}`
  }, [transferMemberApplication, members])

  if (isLoading) {
    return <CircularProgress sx={{margin: '0 auto', padding: '120px', display: 'block'}} />
  }

  return (
    <>
      {Boolean(transferMemberApplication?.fromUserUuid) && <AcceptingTheRoleBlock name={chiefName} />}
      <ParagraphWrapper
        handleClickTitle={() => navigate(`${PATHS.PARENT_COMMITTEE}/${id}${PATHS.PARENT_COMMITTEE_PROJECTS}`)}
        title="Проекты"
        handleClickButton={
          isChief
            ? () =>
                navigate(
                  `${PATHS.PARENT_COMMITTEE}/${id}${PATHS.PARENT_COMMITTEE_PROJECTS}${PATHS.PARENT_COMMITTEE_CREATE_PROJECT}`
                )
            : undefined
        }
      />
      {actualProjects.length ? (
        <Projects toPayByMe={summary?.toPayByMe} isChief={isChief} isMember={isMember} projects={actualProjects} />
      ) : (
        <InfoBox sx={{marginBottom: '32px', fontSize: '16px'}}>
          <Typography variant="h4" sx={{fontSize: '16px', fontWeight: 600}}>
            Здесь пока ничего нет
          </Typography>
        </InfoBox>
      )}

      <Invite />

      <Votings votesList={votesListNotArchived} isChief={isChief} isMember={isMember} />

      <ParagraphWrapper
        handleClickTitle={() => navigate(`${PATHS.PARENT_COMMITTEE}/${id}${PATHS.PARENT_COMMITTEE_REPORTS}`)}
        title="Отчёты"
      />
      <Reports reports={reports} />
      {/* <ParagraphWrapper
        handleClickTitle={() => navigate(`${PATHS.PARENT_COMMITTEE}/${id}${PATHS.PARENT_COMMITTEE_MEMBERS}`)}
        title="Участники"
        handleClickButton={isChief ? onAddMemberClick : undefined}
      />
      <Members /> */}
    </>
  )
}

export default SummaryPage
