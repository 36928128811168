import {useMemo} from 'react'
import {tableOperationsFields} from './components/RowTableAllOperations/constants'
import {filterValues} from './ReportsPage'

export const getHeadersByMode = (filterValue) => {
  switch (filterValue) {
    case filterValues.all:
      return [
        {fieldName: tableOperationsFields.date, headerName: 'Дата'},
        {fieldName: tableOperationsFields.fullNameParent, headerName: 'Счет отправителя'},
        {fieldName: tableOperationsFields.projectName, headerName: 'Счет получателя'},
        {fieldName: tableOperationsFields.paymentDescription, headerName: 'Назначение'},
        {fieldName: tableOperationsFields.amountPaid, headerName: 'Сумма'}
      ]
    case filterValues.projects:
      return ['', 'Проект', 'На начало периода', 'Внесено', 'Списано', 'На конец периода']
    case filterValues.members:
      return ['', 'Участник', 'На начало периода', 'Внесено', 'Списано', 'На конец периода']
  }
}
