import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {Button, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup, Typography} from '@mui/material'

import {StyledLabel, StyledVoteCreateBody} from './VoteCreatePageStyle'
import TextInput from '../../../../../../components/Inputs/TextInput/TextInput'
import {checkNoHtml, getEmptyError, getLengthError, getLastDate} from '../../../../../../utils/validations'
import ButtonBack from '../../../../../../components/ButtonBack/ButtonBack'
import {ArchiveIcon, CheckIcon, PlusIcon} from '../../../../../../icons'
import VoteOptionCreateList from './components/VioteOptionCreateList/VoteOptionCreateList'
import {CHOICE_TYPE, INIT_VOTING_OPTIONS_STATE, INIT_VOTING_STATE} from './constants/creatingVotingConstants'
import DateInput from '../../../../../../components/Inputs/DateInput/DateInput'
import {generateThemeBase} from '../../../../../../theme/base'
import {Box} from '@mui/material'
import {
  StyledInfoBox,
  StyledLeftContent,
  StyledMainContent,
  StyledRightContent,
  StyledWrapper
} from '../../../../../../components/Layouts/TabContentLayout/TabContentLayout'
import {
  archiveVoteEvent,
  createVoteEvent,
  updateVoteEvent
} from '../../../../../../store/votingStore/votingStoreSliceEvents'
import {votingSelectors} from '../../../../../../store/votingStore/votingStore'
import VoteProjectsSelect from './components/VoteProjectsSelect/VoteProjectsSelect'
import {StyledLoadingWrapper} from '../../../../../../components/StyledLoadingWrapper/StyledLoadingWrapper'
import * as votingSliceEvents from '../../../../../../store/votingStore/votingStoreSliceEvents'
import {PATHS} from '../../../../../../routes/paths'
import {STATUSES_IN} from '../../../../../MyRodcomesPage/constants/myRodcomesPageConstants'
import {committeeSettingsSelectors} from '../../../../../../store/committeeSettingsStore/committeeSettingsStore'

export const optionKey = (optionNumber) => {
  return `option${optionNumber}`
}

const VoteCreatePage = ({isEdit}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  const {id, voteId} = params
  const dispatch = useDispatch()
  const isLoading = useSelector(votingSelectors.isLoading.selector)
  const requestVoteId = useSelector(votingSelectors.requestPayload.selector)
  const votesList = useSelector(votingSelectors.votesList.selector)

  const roles = useSelector(committeeSettingsSelectors.roles.selector)
  const isChief = useMemo(() => roles?.includes(STATUSES_IN.Chief), [roles])

  const onBackClick = useCallback(() => {
    if (isEdit) {
      navigate(-1)
    } else {
      navigate(location?.pathname.replace('/vote-create', ''))
    }
  }, [])

  let votingValuesInit = INIT_VOTING_STATE
  let voteOptionsInit = INIT_VOTING_OPTIONS_STATE

  if (isEdit && voteId) {
    const voteToEdit = votesList.find((vote) => vote.uuid === voteId)

    if (voteToEdit) {
      votingValuesInit = voteToEdit
      voteOptionsInit = votingValuesInit.choiceOptions
    }
  }

  const [votingErrors, setVotingErrors] = useState({})
  const [votingValues, setVotingValues] = useState(votingValuesInit)
  const [options, setOptions] = useState(voteOptionsInit)

  useEffect(() => {
    setVotingValues(votingValuesInit)
  }, [votingValuesInit])

  useEffect(() => {
    if (localStorage.getItem('projId') && !votingValues.projectUuid) {
      handleProjectChange(JSON.parse(localStorage.getItem('projId')))
    } else if (votingValues.projectUuid) {
      localStorage.removeItem('projId')
    }
  }, [localStorage, votingValues.projectUuid])

  useEffect(() => {
    setOptions(voteOptionsInit)
  }, [voteOptionsInit])

  useEffect(() => {
    if (requestVoteId) {
      if (isEdit) {
        navigate(location?.pathname.replace(PATHS.PARENT_COMMITTEE_VOTE_EDIT, ''))
      } else {
        navigate(location?.pathname.replace(PATHS.PARENT_COMMITTEE_VOTE_CREATE, `/${requestVoteId}`))
      }

      dispatch(votingSliceEvents.setRequestPayload.action(null))
    }
  }, [requestVoteId])

  useEffect(() => {
    if (roles.length) {
      if (!isChief) {
        if (voteId) {
          navigate(`${PATHS.PARENT_COMMITTEE}/${id}${PATHS.PARENT_COMMITTEE_VOTES}/${voteId}`)
        } else {
          navigate(`${PATHS.PARENT_COMMITTEE}/${id}${PATHS.PARENT_COMMITTEE_VOTES}`)
        }
      }
    }
  }, [roles])

  const onChangeGetOffError = (field) => {
    const isError = votingErrors[field]
    if (isError) {
      setVotingErrors((prev) => {
        return {...prev, [field]: false}
      })
    }
  }

  const setVotingValue = (value, field) => {
    setVotingValues((prev) => ({...prev, [field]: value}))
    onChangeGetOffError(field)
  }

  const removeOption = useCallback((optionNumberToDelete) => {
    setOptions((prev) => {
      const filteredPrev = prev.filter((option) => option.number !== optionNumberToDelete)
      return filteredPrev.map((option, index) => {
        return {...option, number: index}
      })
    })
  }, [])

  const addOption = useCallback(() => {
    setOptions((prev) => {
      const currentLength = prev.length
      return [
        ...prev,
        {
          number: currentLength,
          text: '',
          emoji: {}
        }
      ]
    })
  }, [])

  const onChangeOptionValue = (field, value, optionNumber) => {
    onChangeGetOffError(optionKey(optionNumber))

    setOptions((prev) => {
      return prev.map((option) => {
        if (option.number === optionNumber) {
          return {
            ...option,
            [field]: value
          }
        } else {
          return option
        }
      })
    })
  }

  const handlePrivateChange = (value) => {
    setVotingValue(JSON.parse(value), 'isPrivate')
  }
  const handleChoiceTypeChange = (value) => {
    setVotingValue(value, 'choiceType')
  }
  const handleProjectChange = (value) => {
    setVotingValue(value, 'projectUuid')
  }
  const handleDateChange = (value) => {
    setVotingValue(value, 'deadlineAt')
  }

  const handleDataSubmit = (isArchived) => {
    const newErrors = {}
    const {choiceType, description, deadlineAt, isPrivate, title, projectUuid, question} = votingValues

    options.map((option) => {
      newErrors[optionKey(option.number)] = getEmptyError(option.text)
    })

    newErrors.choiceType = getEmptyError(choiceType)
    newErrors.deadlineAt = getEmptyError(deadlineAt) || getLastDate(deadlineAt)
    newErrors.title = getLengthError(title, 5)
    setVotingErrors(newErrors)
    const hasErrors = Object.values(newErrors).some((el) => el)
    if (hasErrors) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      return
    }

    const myData = {
      rkUuid: id,
      title: title,
      choiceType: choiceType,
      choiceOptions: options,
      deadlineAt: deadlineAt,
      isResultVisibleUntilEnd: true,
      isArchived: isArchived,
      isPrivate: isPrivate
    }

    if (question?.trim()) {
      myData.question = question
    }
    if (description?.trim()) {
      myData.description = description
    }
    if (projectUuid?.trim()) {
      myData.projectUuid = projectUuid
    }

    if (isEdit) {
      dispatch(updateVoteEvent.action({voteId, myData}))
    } else {
      dispatch(createVoteEvent.action({myData}))
    }
  }

  const handleArchive = () => {
    dispatch(archiveVoteEvent.action(voteId))
  }

  return (
    <StyledLoadingWrapper component="form" onSubmit={handleDataSubmit} isLoading={isLoading}>
      <StyledWrapper>
        <StyledLeftContent>
          <StyledMainContent>
            {/* <ButtonBack
              sx={{marginBottom: '19px', width: 'auto'}}
              onClick={onBackClick}
              text={isEdit ? 'Отмена' : 'Голосования'}
              className="back"
            /> */}

            <StyledVoteCreateBody>
              <TextInput
                errorMsg={votingErrors.title}
                label="Тема"
                id="title"
                disabled={votingValuesInit?.voteAnswers?.length ? votingValuesInit?.voteAnswers?.length : null}
                value={votingValues['title']}
                inputProps={{maxLength: 40}}
                onChangeValue={(value) => setVotingValue(value, 'title')}
                className="input"
                placeholder="Опишите тему голосования"
              />
              <TextInput
                errorMsg={votingErrors.question}
                label="Опишите тему голосования"
                disabled={votingValuesInit?.voteAnswers?.length ? votingValuesInit?.voteAnswers?.length : null}
                id="question"
                inputProps={{maxLength: 500}}
                value={votingValues.question || ''}
                onChangeValue={(value) => setVotingValue(value, 'question')}
                className="input"
                withNotRequiredText
                placeholder='Опишите коротко решение, которое нужно принять родителям. И предоставьте, информацию необходимую для его принятия. Например: "Выбираем новогодний спектакль. Планируем повести детей 20го декабря, в этот день есть 3 варианта. Выберете ваш"'
                multiline={true}
                maxRows={7}
                minRows={7}
              />

              <VoteOptionCreateList
                disabled={isEdit}
                votingErrors={votingErrors}
                options={options}
                onChangeValue={onChangeOptionValue}
                removeOption={removeOption}
              />

              {!isEdit ? (
                <Button
                  variant="contained"
                  type="button"
                  onClick={addOption}
                  startIcon={<PlusIcon />}
                  // sx={{width: '100%'}}
                >
                  Добавить
                </Button>
              ) : null}
            </StyledVoteCreateBody>
          </StyledMainContent>
        </StyledLeftContent>

        <StyledRightContent>
          <StyledInfoBox className="withoutPadding">
            <FormControl>
              <StyledLabel>Тип голосования</StyledLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="choiceType"
                row
                value={votingValues.isPrivate}
                onChange={(event) => {
                  handlePrivateChange(event.target.value)
                }}
              >
                <FormControlLabel value={false} control={<Radio />} label="Открытое" />
                <FormControlLabel value={true} control={<Radio />} label="Закрытое" />
              </RadioGroup>
              <Typography variant={'caption'} sx={{fontSize: 12, opacity: 0.6}}>
                При открытом типе все будут видеть, кто как голосовал. При закрытом — только проценты.
              </Typography>
            </FormControl>

            <FormControl disabled={isEdit}>
              <StyledLabel>Можно выбрать</StyledLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                row
                value={votingValues.choiceType}
                onChange={(event) => {
                  handleChoiceTypeChange(event.target.value)
                }}
              >
                <FormControlLabel value={CHOICE_TYPE.ONLY_ONE} control={<Radio />} label="Один вариант" />
                <FormControlLabel value={CHOICE_TYPE.ALLOW_MANY} control={<Radio />} label="Несколько" />
              </RadioGroup>
            </FormControl>

            <Box sx={{width: '100%', marginBottom: '24px'}}>
              <VoteProjectsSelect
                handleProjectChange={handleProjectChange}
                selectedProjectId={votingValues.projectUuid}
              />
            </Box>
            <FormControl sx={{width: '100%'}}>
              <DateInput
                className="child-modal-date-input text"
                errorMsg={votingErrors.deadlineAt}
                label="Дата завершения"
                id="deadlineAt"
                inputFormat="dd.MM.yyyy"
                mask="__.__.____"
                openTo="day"
                value={votingValues.deadlineAt}
                onChange={handleDateChange}
                withEstimatedText
              />
            </FormControl>

            <Button
              sx={{width: '100%'}}
              variant="contained"
              startIcon={<CheckIcon color={generateThemeBase.palette.primary.contrastText} />}
              type="button"
              onClick={() => handleDataSubmit(false)}
            >
              Сохранить
            </Button>

            {isEdit ? (
              <Button
                sx={{width: '100%', height: '38px !important', marginTop: '13px'}}
                variant="contained"
                color="secondary"
                startIcon={<ArchiveIcon />}
                type="button"
                onClick={handleArchive}
              >
                В архив
              </Button>
            ) : null}
          </StyledInfoBox>
        </StyledRightContent>
      </StyledWrapper>
      {isLoading ? (
        <div className="loading">
          <CircularProgress sx={{margin: '0 auto', padding: 19, display: 'block'}} />
        </div>
      ) : null}
    </StyledLoadingWrapper>
  )
}

export default VoteCreatePage
