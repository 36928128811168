import {call, takeLatest, select} from 'redux-saga/effects'
import * as usersStoreSliceEvents from '../../store/usersStore/usersStoreSliceEvents'
import {profileSelectors} from '../../store/profileStore/profileStore'
import {verificationPhoneRequest, verificationPhoneResendCodeRequest} from '../../services/users'
import {withErrorLogging} from '../../utils/errorSaga'

export function* sendVerificationSms() {
  const user = yield select(profileSelectors.user.selector)
  const phone = user.phone
  try {
    yield withErrorLogging(call(verificationPhoneRequest, phone))
    // todo finish
  } catch (e) {
    // console.error(e)
  }
}

export function* sendSmsCodeAgain() {
  const user = yield select(profileSelectors.user.selector)
  const phone = user.phone
  try {
    yield withErrorLogging(call(verificationPhoneResendCodeRequest, phone))
    // todo finish
  } catch (e) {
    // console.error(e)
  }
}

export function* usersSaga() {
  yield takeLatest(usersStoreSliceEvents.verificationPhone.type, sendVerificationSms)
  yield takeLatest(usersStoreSliceEvents.verificationPhoneResendCode.type, sendSmsCodeAgain)
}
