import React, {useState, useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {Dialog, Typography, Button, CircularProgress, Alert, AlertTitle, Box} from '@mui/material'

import {FundraisingInput} from '../../../../../../../components/Inputs/FundraisingInput/FundraisingInput'
import TextInput from '../../../../../../../components/Inputs/TextInput/TextInput'
import {CheckIcon} from '../../../../../../../icons'
import {
  requestTransferToProjectEvent,
  requestTransferToPiggyEvent,
  requestTransferToCardEvent
} from 'store/projectsStore/projectsStoreSliceEvents'

import {projectsSelectors} from 'store/projectsStore/projectsStore'

import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import ConfirmForm from '../ReportingOutputModal/ConfirmDialog'

function DestionationRadioGroup({value, handleChange}) {
  return (
    <FormControl>
      <FormLabel id="destinations-group-label">Куда:</FormLabel>
      <RadioGroup
        row
        aria-labelledby="destination-group-label"
        name="destination-buttons-group"
        value={value}
        onChange={(event, newValue) => {
          handleChange(newValue)
        }}
      >
        <FormControlLabel value="piggy" control={<Radio />} label="в копилку" />
        <FormControlLabel value="card" control={<Radio />} label="на карту" />
      </RadioGroup>
    </FormControl>
  )
}

const ModalContent = styled('div')(({theme}) => ({
  padding: '38px',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '19px',
  '& .MuiFormControl-root': {
    maxWidth: 'inherit'
  },
  '.description, .fundraising-output-input': {
    marginBottom: 0
  }
}))

const ProjectInput = ({projects, value, setValue}) => {
  return (
    <FormControl sx={{gap: '10px'}}>
      {/*  Выберите проект*/}
      {/*</Box>*/}
      <Autocomplete
        id="combo-box-demo"
        options={projects}
        autoHighlight
        value={value}
        getOptionLabel={(option) => {
          let s = `${option?.title}`
          if (option?.status !== 'DRAFT') s += ` собрано ${option?.amountPaid} P`
          return s
        }}
        onChange={(event, newValue) => {
          setValue(newValue)
        }}
        getOptionDisabled={(option) => option?.status !== 'PUBLISHED'}
        renderInput={(params) => <TextField {...params} placeholder="Проект" />}
        sx={{
          '& input': {border: 'none'}
        }}
      />
    </FormControl>
  )
}

const ModalHeading = ({caption}) => {
  return (
    <Typography variant="h4" sx={{lineHeight: '29px', fontSize: '19px'}}>
      {caption}
    </Typography>
  )
}

const ModalButtons = ({fullScreen, text, action, handleClose, disabled}) => {
  return (
    <>
      <Button
        disabled={disabled}
        onClick={action}
        startIcon={<CheckIcon color="white" />}
        variant="contained"
        type="button"
      >
        {text}
      </Button>
      {fullScreen && (
        <Button variant="outlined" onClick={handleClose}>
          Отмена
        </Button>
      )}
    </>
  )
}

export const TransferToProjectModal = ({isOpen, piggyUuid, rkId, available, handleClose}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useDispatch()

  const [amount, setAmount] = useState(0)
  const [amountError, setAmountError] = useState('')
  const [description, setDescription] = useState('')
  const [project, setProject] = useState(null)
  const [filterProjects, setFilterProjects] = useState([])

  const projects = useSelector(projectsSelectors.projectsList.selector)

  useEffect(() => {
    setFilterProjects(projects.filter((e) => e.status !== 'DRAFT'))
  }, [projects])

  const requestTransfer = useCallback(() => {
    dispatch(
      requestTransferToProjectEvent.action({
        rkId: rkId,
        piggyUuid: piggyUuid,
        projectId: project.uuid,
        amount,
        description
      })
    )
    handleClose()
  }, [project, amount, description, piggyUuid])

  return (
    <Dialog open={isOpen} onClose={handleClose} fullScreen={fullScreen} fullWidth maxWidth="xs">
      <ModalContent>
        <ModalHeading caption="Перевести в проект" />
        <Alert icon={false} severity="success">
          <AlertTitle>Можно перевести: {available} ₽</AlertTitle>
        </Alert>
        {amountInput()}
        <ProjectInput projects={filterProjects} value={project} setValue={setProject} />
        {/*{descriptionInput()}*/}
        <ModalButtons
          fullScreen={fullScreen}
          text="Перевести"
          action={requestTransfer}
          handleClose={handleClose}
          disabled={!(available > 0)}
        />
      </ModalContent>
    </Dialog>
  )

  function amountInput() {
    return (
      <FundraisingInput
        className="fundraising-output-input"
        id="fundraising-output-input"
        value={amount}
        error={amountError}
        handleChange={setAmount}
        clearError={setAmountError}
        //label="Сколько вывести"
        placeholder="Сумма, ₽"
      />
    )
  }

  function descriptionInput() {
    return (
      <TextInput
        className="description"
        label="На что пойдут средства"
        id="description"
        minRows={4}
        multiline
        resize
        value={description}
        onChangeValue={setDescription}
        placeholder="Кратко опишите назначение"
      />
    )
  }
}

export const TransferToPiggyOrCardModal = ({isOpen, projectId, handleClose}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useDispatch()

  const loading = useSelector(projectsSelectors.isLoading.selector)
  const [amount, setAmount] = useState(0)
  const [amountError, setAmountError] = useState('')
  const [description, setDescription] = useState('')
  const [destination, setDestination] = useState('piggy')
  const showConfirm = useSelector(projectsSelectors.confirmTransfer.selector)

  const requestTransfer = useCallback(() => {
    if (destination === 'piggy') {
      dispatch(requestTransferToPiggyEvent.action({projectId: projectId, amount, description}))
      handleClose()
    } else {
      dispatch(requestTransferToCardEvent.action({projectId: projectId, amount, description}))
    }
  }, [amount, description, destination])

  return (
    <Dialog open={isOpen} onClose={handleClose} fullScreen={fullScreen} fullWidth maxWidth="xs">
      <ModalContent>
        <ConfirmForm
          loading={loading}
          event={requestTransferToCardEvent}
          data={{projectId: projectId, amount, description}}
          showConfirm={showConfirm}
          handleClose={handleClose}
        >
          <ModalHeading caption="Перевести" />
          <DestionationRadioGroup value={destination} handleChange={setDestination} />
          {amountInput()}
          {descriptionInput()}
          <ModalButtons text="перевести" action={requestTransfer} handleClose={handleClose} />
        </ConfirmForm>
      </ModalContent>
    </Dialog>
  )

  function amountInput() {
    return (
      <FundraisingInput
        className="fundraising-output-input"
        id="fundraising-output-input"
        value={amount}
        error={amountError}
        handleChange={setAmount}
        clearError={setAmountError}
        label="Сколько вывести"
        placeholder="Сумма, ₽"
      />
    )
  }

  function descriptionInput() {
    return (
      <TextInput
        className="description"
        label="На что пойдут средства"
        id="description"
        minRows={4}
        multiline
        resize
        value={description}
        onChangeValue={setDescription}
        placeholder="Кратко опишите назначение"
      />
    )
  }
}

export const TransferToPiggyModal = ({isOpen, projectId, available, handleClose}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useDispatch()

  const loading = useSelector(projectsSelectors.isLoading.selector)
  const [amount, setAmount] = useState(0)
  const [amountError, setAmountError] = useState('')

  const requestTransfer = useCallback(() => {
    dispatch(requestTransferToPiggyEvent.action({projectId, amount}))
    handleClose()
  }, [projectId, amount])

  return (
    <Dialog open={isOpen} onClose={handleClose} fullScreen={fullScreen} fullWidth maxWidth="xs">
      <ModalContent>
        {loading && <CircularProgress />}
        <ModalHeading caption="Перевод в копилку" />
        <Alert icon={false} severity="success">
          <AlertTitle>Можно перевести: {available} ₽</AlertTitle>
        </Alert>

        <FundraisingInput
          className="fundraising-output-input"
          id="fundraising-output-input"
          value={amount}
          error={amountError}
          handleChange={setAmount}
          clearError={setAmountError}
          placeholder="Введите сумму"
        />
        <ModalButtons
          disabled={!(available > 0)}
          text="Подтвердить"
          action={requestTransfer}
          handleClose={handleClose}
        />
      </ModalContent>
    </Dialog>
  )
}
