import {styled} from '@mui/material/styles'

export const Wrapper = styled('div')({
  width: '100%',
  '.loadingButton': {
    marginBottom: '18px'
  },
  '.arrowRight': {
    marginLeft: '14px'
  },
  '.forgotPassword': {
    fontSize: '12px',
    color: 'rgba(119, 119, 119, 1)'
  },
  '.header': {
    fontSize: '19px'
  },
  '.phoneField': {
    marginBottom: 38
  },
  '.link': {
    marginTop: 24,
    display: 'block',
    fontSize: '14px',
    color: 'rgba(119, 119, 119, 1)',
    textAlign: 'center'
  },
  '.repeatPassField': {
    marginBottom: 0
  },
  '.pass': {
    marginBottom: 24
  },
  '.back': {
    color: 'rgba(0, 0, 0, 0.6)'
  },
  '.phone': {
    margin: 0,
    input: {
      fontSize: '16px',
      lineHeight: '24px'
    }
  },
  '.smsBtn': {
    fontSize: '16px',
    margin: '32px 0 0 0'
  },
  '@media(max-width: 500px)': {
    '.header': {
      marginBottom: 32
    },
    '.link': {
      marginTop: 24
    }
  }
})
