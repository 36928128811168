import {call, put, takeLatest} from 'redux-saga/effects'
import * as childStoreSliceEvents from '../../store/childStore/childStoreSliceEvents'
import {getMyAccruals, getRkAccruals, getPaidAccruals, getPrAccruals, getMySummary} from '../../services/accrual'
import {createBill, createProjectBill, checkBill} from '../../services/payments'
import {withErrorLogging} from '../../utils/errorSaga'
import * as accrualEvents from '../../store/accrualsStore/accrualStoreSliceEvents'
import {PATHS} from '../../routes/paths'

export function* getSummarySaga(action) {
  try {
    const {rkId} = action.payload
    const response = yield withErrorLogging(call(getMySummary, rkId))
    yield put(accrualEvents.getSummarySuccessEvent.action(response.payload))
  } catch (e) {
    yield put(childStoreSliceEvents.setIsLoading.action(false))
    // console.error(e)
  }
}

export function* getMyAccrualsSaga(action) {
  try {
    const {rkId, unselect} = action.payload
    const response = yield withErrorLogging(call(getMyAccruals, rkId))
    yield put(accrualEvents.getMyAccrualsSuccessEvent.action(response.payload, unselect))
  } catch (e) {
    yield put(childStoreSliceEvents.setIsLoading.action(false))
    // console.error(e)
  }
}

export function* getMyPaidSaga(action) {
  try {
    const {rkId} = action.payload
    const response = yield withErrorLogging(call(getPaidAccruals, rkId))
    yield put(accrualEvents.getMyPaidSuccessEvent.action(response.payload))
  } catch (e) {
    yield put(childStoreSliceEvents.setIsLoading.action(false))
    // console.error(e)
  }
}

export function* getRkAccrualsSaga(action) {
  try {
    const {rkId} = action.payload
    const response = yield withErrorLogging(call(getRkAccruals, rkId))
    yield put(accrualEvents.getRkAccrualsSuccessEvent.action(response.payload))
  } catch (e) {
    yield put(childStoreSliceEvents.setIsLoading.action(false))
    // console.error(e)
  }
}

export function* getPrAccrualsSaga(action) {
  try {
    const {data} = action.payload
    const {rkId, prId} = data
    const response = yield withErrorLogging(call(getPrAccruals, rkId, prId))
    yield put(accrualEvents.getPrAccrualsSuccessEvent.action(response.payload))
  } catch (e) {
    yield put(childStoreSliceEvents.setIsLoading.action(false))
    // console.error(e)
  }
}

export function* createBillSaga(action) {
  try {
    const {items} = action.payload
    const response = yield withErrorLogging(call(createBill, items))
    yield put(accrualEvents.createBillSuccessEvent.action(response.payload))
  } catch (e) {
    yield put(childStoreSliceEvents.setIsLoading.action(false))
    // console.error(e)
  }
}

export function* createProjectBillSaga(action) {
  try {
    const {items} = action.payload
    const response = yield withErrorLogging(call(createProjectBill, items))
    yield put(accrualEvents.createBillSuccessEvent.action(response.payload))
  } catch (e) {
    yield put(childStoreSliceEvents.setIsLoading.action(false))
    // console.error(e)
  }
}

export function* checkBillSaga(action) {
  try {
    const {uuid, rkId, projectId} = action.payload
    //проверка не нужна, просто отправить пользователя на страницу проекта
    //const response = yield withErrorLogging(call(checkBill, uuid))
    window.location = `https://staging.rodcom.su/${PATHS.MY_PARENT_COMMITTEES}/${rkId}/${PATHS.PARENT_COMMITTEE_PROJECTS}/${projectId}`
  } catch (e) {
    yield put(childStoreSliceEvents.setIsLoading.action(false))
    // console.error(e)
  }
}

export function* accrualSaga() {
  yield takeLatest(accrualEvents.getSummaryEvent.type, getSummarySaga)
  yield takeLatest(accrualEvents.getRkAccrualsEvent.type, getRkAccrualsSaga)
  yield takeLatest(accrualEvents.getPrAccrualsEvent.type, getPrAccrualsSaga)
  yield takeLatest(accrualEvents.getMyAccrualsEvent.type, getMyAccrualsSaga)
  yield takeLatest(accrualEvents.getMyPaidEvent.type, getMyPaidSaga)
  yield takeLatest(accrualEvents.createBillEvent.type, createBillSaga)
  yield takeLatest(accrualEvents.createProjectBillEvent.type, createProjectBillSaga)
  yield takeLatest(accrualEvents.checkBillEvent.type, checkBillSaga)
}
