import React from 'react'

export const FilterIcon = ({className, color = '#39215E'}) => {
  return (
    <svg
      className={className}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.00108 1H15.0011C15.1451 1.05051 15.2756 1.13331 15.3827 1.24206C15.4898 1.35082 15.5706 1.48264 15.6188 1.62742C15.6671 1.77221 15.6816 1.92612 15.6612 2.07737C15.6407 2.22861 15.586 2.37318 15.5011 2.5L10.5011 8V15L6.50108 12V8L1.50108 2.5C1.41617 2.37318 1.36142 2.22861 1.34101 2.07737C1.3206 1.92612 1.33509 1.77221 1.38335 1.62742C1.43161 1.48264 1.51237 1.35082 1.61945 1.24206C1.72652 1.13331 1.85707 1.05051 2.00108 1Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
