import {call, put, takeLatest} from 'redux-saga/effects'
import {withErrorLogging} from '../../utils/errorSaga'
import {getDirectories} from '../../services/directories'
import * as directoriesStoreSliceEvents from '../../store/directoriesStore/directoriesStoreSliceEvents'

export function* getDirectoriesRequest() {
  try {
    const response = yield withErrorLogging(call(getDirectories))
    yield put(directoriesStoreSliceEvents.getInitDirectoriesSuccess.action(response.data.payload))
  } catch (e) {
    // console.error(e)
  }
}

export function* directoriesSaga() {
  yield takeLatest(directoriesStoreSliceEvents.getInitDirectories.type, getDirectoriesRequest)
}
