import React, {useCallback, useMemo} from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import {Typography} from '@mui/material'

import {StyledText} from '../../../../../../../../components/StyledTexts/StyledTexts'
import {estimatedTime} from '../../../../../../../../utils/EstimatedTime'
import {getMonth} from 'utils/getFormattedDate'
import {StyledProjectOptionsList, StyledProjectOption} from './CartProjectsListStyle'
import Image from 'components/Media/Image'
const CartProjectList = ({items, setChecked}) => {
  const now = new Date()
  return (
    <StyledProjectOptionsList>
      {items &&
        items.map((accrualItem) => {
          return (
            <FormControlLabel
              key={accrualItem.key}
              onChange={(e) => setChecked(accrualItem.key, e.target.checked)}
              label={
                <StyledProjectOption>
                  <Checkbox
                    checked={accrualItem.checked}
                    onChange={(e) => setChecked(accrualItem.key, e.target.checked)}
                  />

                  <div className="projectImgWrapper">
                    <Image className="projectImg" src={accrualItem.projectLogo} alt="project_img" />
                  </div>
                  <div>
                    <Typography className="projectText">
                      {accrualItem.projectName} - {accrualItem.amount} ₽
                    </Typography>
                    {accrualItem.deadline && accrualItem.deadline >= now && (
                      <StyledText>Оплатить в течение {estimatedTime(accrualItem.deadline)}</StyledText>
                    )}
                    {accrualItem.deadline && accrualItem.deadline < now && (
                      <StyledText>Долг за {getMonth(accrualItem.deadline)}</StyledText>
                    )}
                  </div>
                </StyledProjectOption>
              }
              control={<Checkbox sx={{display: 'none'}} checked={accrualItem.checked} />}
            />
          )
        })}
    </StyledProjectOptionsList>
  )
}

export default CartProjectList
