import React from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {Typography} from '@mui/material'

import {SLIDER_SETTINGS} from '../../constants/summaryConstants'
import ParagraphWrapper from '../../../components/ParagraphWrapper/ParagraphWrapper'
import {PATHS} from '../../../../../../routes/paths'
import VotingBlock from './VotingBlock'
import InfoBox from '../../../../../../components/Layouts/InfoBox/InfoBox'
import useWindowDimensions from '../../../../../../hooks/useWindowDimensions'

const Votings = ({votesList, isChief, isMember}) => {
  const {width} = useWindowDimensions()
  const {id} = useParams()
  const navigate = useNavigate()
  let isVotingSlider = true
  let isVotingSliderButtons = isVotingSlider

  switch (true) {
    case width < SLIDER_SETTINGS.responsive[2].breakpoint:
      isVotingSlider = votesList.length > SLIDER_SETTINGS.responsive[2].settings.slidesToShow
      isVotingSliderButtons = isVotingSlider && SLIDER_SETTINGS.responsive[2].settings.arrows
      break

    case width < SLIDER_SETTINGS.responsive[1].breakpoint:
      isVotingSlider = votesList.length > SLIDER_SETTINGS.responsive[1].settings.slidesToShow
      isVotingSliderButtons = isVotingSlider && SLIDER_SETTINGS.responsive[1].settings.arrows
      break

    case width <= SLIDER_SETTINGS.responsive[0].settings.slidesToShow:
      isVotingSlider = votesList.length > SLIDER_SETTINGS.responsive[0].settings.slidesToShow
      isVotingSliderButtons = isVotingSlider && SLIDER_SETTINGS.responsive[0].settings.arrows
      break

    default:
      isVotingSlider = votesList.length > SLIDER_SETTINGS.slidesToShow
      isVotingSliderButtons = isVotingSlider && SLIDER_SETTINGS.arrows
      break
  }

  return (
    <>
      <ParagraphWrapper
        withSlider={isVotingSliderButtons}
        handleClickTitle={() => navigate(`${PATHS.PARENT_COMMITTEE}/${id}${PATHS.PARENT_COMMITTEE_VOTES}`)}
        title="Голосования"
        handleClickButton={
          isChief
            ? () => {
                navigate(
                  `${PATHS.PARENT_COMMITTEE}/${id}${PATHS.PARENT_COMMITTEE_VOTES}${PATHS.PARENT_COMMITTEE_VOTE_CREATE}`
                )
              }
            : undefined
        }
      />
      {votesList.length ? (
        <VotingBlock isChief={isChief} isMember={isMember} votesList={votesList} />
      ) : (
        <InfoBox sx={{marginBottom: '32px'}}>
          <Typography variant="h4" sx={{fontSize: '16px', fontWeight: 600}}>
            Здесь пока ничего нет
          </Typography>
        </InfoBox>
      )}
    </>
  )
}

export default Votings
