import {createSelector} from 'reselect'
import {clone, path} from 'ramda'

import {checker} from '../utils/checker'

const SELECTOR_FIELD_NAME = 'selector'

const fillSelectors = (object, ...pathParams) => {
  if (object) {
    if (!object.selector) {
      object.selector = createSelector(path(pathParams), (value) => value)
    }
    Object.keys(object)
      .filter((key) => key !== SELECTOR_FIELD_NAME)
      .forEach((key) => {
        if (!checker.isPlainObject(object[key])) {
          object[key] = {}
        }
        fillSelectors(object[key], ...[...pathParams], key)
      })
  }
  return object
}

export const createSelectorsTree = (initialState, ...pathParams) => {
  const stateDeepCopy = clone(initialState)
  return fillSelectors(stateDeepCopy, ...[...pathParams])
}
