import {styled} from '@mui/material/styles'

export const StyledWrapper = styled('div')(({theme}) => ({
  color: theme.palette.primary.main,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // boxShadow: '0px 12px 48px rgba(57, 33, 94, 0.06), 0px 2.4px 4px rgba(57, 33, 94, 0.04)',
  border: '1px solid #EDEDFF',
  justifyContent: 'space-between',
  background: theme.palette.background.paper,
  padding: 19,
  borderRadius: 12,
  [theme.breakpoints.up('md')]: {
    borderRadius: '12px'
  },
  '& .totalPayment': {
    fontWeight: 700,
    fontSize: '26px',
    lineHeight: '32px',
    marginBottom: '19px'
  }
  // button: {
  //   fontSize: 16,
  //   fontWeight: 500,
  //   color: '#39215E',
  //   background: 'rgba(57, 33, 94, 0.12)',
  //   border: '1px solid rgba(255, 255, 255, 1e-05)',
  //   '&:hover': {
  //     background: 'rgba(57, 33, 94, 0.24)',
  //     border: '1px solid rgba(255, 255, 255, 1e-05)'
  //   },
  //   '&:focused': {
  //     border: '1px solid rgba(255, 255, 255, 1e-05)'
  //   },
  //   '&:active': {
  //     boxShadow:
  //       ' 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
  //   },
  //   svg: {
  //     path: {
  //       stroke: '#39215E'
  //     }
  //   }
  // }
}))
