import React, {useCallback, useMemo, useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {CircularProgress} from '@mui/material'
import {StyledPaid} from './CartPageStyle'
import {useNavigate, useParams} from 'react-router-dom'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import {chain as flatMap, prop, filter} from 'ramda'

import {
  StyledLeftContent,
  StyledMainContent,
  StyledTabContentLayout,
  StyledWrapper
} from '../../../../../../components/Layouts/TabContentLayout/TabContentLayout'
import {Text} from '../../../components/ParagraphWrapper/ParagraphWrapperStyle'
import {StyledAddContent} from '../../../Projects/Project/ProjectStyle'
import {committeeSettingsSelectors} from '../../../../../../store/committeeSettingsStore/committeeSettingsStore'
import {accrualsSelectors} from '../../../../../../store/accrualsStore/accrualStore'
import CartProjectList from './components/CartProjectsList/CartProjectList'
import CartTotalAndPay from './components/CartTotalAndPay/CartTotalAndPay'
import {getMyAccrualsEvent, createBillEvent} from '../../../../../../store/accrualsStore/accrualStoreSliceEvents'
import PayPopup from '../../../../../../components/Payments/PayPopup'
import useScript from 'utils/useScript'

const CartPage = () => {
  const dispatch = useDispatch()
  const {id} = useParams()
  const accruals = useSelector(accrualsSelectors.current.selector)
  const loading = useSelector(accrualsSelectors.loading.selector)
  const payInProgress = useSelector(accrualsSelectors.payInProgress.selector)
  useScript('https://yookassa.ru/checkout-widget/v1/checkout-widget.js')
  useEffect(() => {
    dispatch(getMyAccrualsEvent.action(id))
  }, [])

  const RKName = useSelector(committeeSettingsSelectors.profileInfo.selector).name
  const [key, setKey] = useState(0)
  const navigate = useNavigate()
  const onBackClick = useCallback(() => {
    navigate(location?.pathname.replace('/cart', ''))
  }, [])

  const [count, setCount] = useState()

  const [accrualsInfo, setAccrualsInfo] = useState(accruals)

  useEffect(() => {
    if (accruals && accruals.length > 0) {
      if (localStorage.getItem('projId')) {
        setAccrualsInfo(
          accruals.map((x) =>
            x.projectUuid === localStorage.getItem('projId') ? {...x, checked: true} : {...x, checked: false}
          )
        )
      } else {
        setAccrualsInfo(accruals.map((x) => ({...x, checked: true})))
      }
      setCount(accruals.length)
    } else {
      setAccrualsInfo([])
      setCount(0)
    }
  }, [accruals])

  useEffect(() => {
    return () => {
      localStorage.removeItem('projId')
    }
  }, [])

  const setChecked = useCallback((projectId, value, all = false) => {
    setAccrualsInfo((prev) => {
      const newAccrualsInfo = prev.map((projectInfo) => {
        if (projectInfo.key === projectId || all) {
          return {
            ...projectInfo,
            checked: value
          }
        } else {
          return projectInfo
        }
      })

      return newAccrualsInfo
    })
    setKey(key + 1)
  }, [])

  const onPaySuccess = useCallback(() => {
    dispatch(getMyAccrualsEvent.action(id, true))
  }, [])

  const handleChangeAll = useCallback((event) => {
    setChecked(null, event.target.checked, true)
  }, [])

  const createBill = useCallback(() => {
    // useScript('https://oplata.qiwi.com/popup/v1.js')
    const extractIds = flatMap(prop('ids'))
    const filterChecked = filter(prop('checked'))
    dispatch(createBillEvent.action(extractIds(filterChecked(accrualsInfo))))
  }, [accrualsInfo])

  const isAllChecked = useMemo(() => {
    return accrualsInfo.every((x) => x.checked)
  }, [accrualsInfo])

  const isSomeChecked = useMemo(() => {
    return accrualsInfo.some((x) => x.checked)
  }, [accrualsInfo])

  console.log(loading)

  if (loading) return <CircularProgress sx={{margin: '0 auto', padding: 19, display: 'block'}} />
  return (
    <StyledTabContentLayout>
      <PayPopup onSuccess={onPaySuccess} />
      <StyledWrapper>
        <StyledLeftContent>
          <StyledMainContent>
            {/* <ButtonBack
              sx={{marginBottom: '19px', width: 'auto'}}
              onClick={onBackClick}
              text="Назад"
              className="back"
            /> */}
            {/* <Typography sx={{marginBottom: '6px', fontSize: 16}}>{RKName}</Typography> */}
            <Text sx={{fontSize: 32}}>Оплатить взносы</Text>

            {!loading &&
              !payInProgress &&
              // <AccrualSelectionList
              //   count={count}
              //   isAllChecked={isAllChecked}
              //   isSomeChecked={isSomeChecked}
              //   handleChangeAll={handleChangeAll}
              //   accrualsInfo={accrualsInfo}
              //   setChecked={setChecked}
              // />
              (count > 0 ? (
                <div
                  style={{
                    marginTop: '24px'
                  }}
                >
                  <FormControlLabel
                    label={`Выбрать все (${count})`}
                    control={
                      <Checkbox
                        checked={isAllChecked}
                        indeterminate={isSomeChecked && !isAllChecked}
                        onChange={handleChangeAll}
                      />
                    }
                  />
                  <CartProjectList items={accrualsInfo} setChecked={setChecked} />
                </div>
              ) : (
                <StyledPaid sx={{fontSize: 16}}>Отлично! Вы уже оплатили все взносы.</StyledPaid>
              ))}
          </StyledMainContent>
        </StyledLeftContent>

        {count ? (
          <StyledAddContent>
            <CartTotalAndPay items={accrualsInfo} active={!payInProgress} createBill={createBill} />
          </StyledAddContent>
        ) : null}
      </StyledWrapper>
    </StyledTabContentLayout>
  )
}

export default CartPage
