import React, {useState, useCallback, useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {Dialog, Typography, Button, CircularProgress, Box, Autocomplete, InputLabel} from '@mui/material'
import {IMaskInput} from 'react-imask'
import TextInput from '../Inputs/TextInput/TextInput'
import {CheckIcon} from '../../icons'
import {checkControlSumEvent, sendControlSumEvent, setStepEvent} from 'store/profileStore/profileStoreSliceEvents'
import StyledSelect from 'components/Inputs/StyledSelect/StyledSelect'
import {PhoneInput} from 'components/Inputs/PhoneInput/PhoneInput'
import {profileSelectors} from 'store/profileStore/profileStore'
import InputMask from 'react-input-mask'
import TextField from '@mui/material/TextField'

import {getSbpParticipants} from 'services/profile'
import LoadingButton from '@mui/lab/LoadingButton'

const SBP_LOGO = {
  SRC: '/images/svg/logosbp.svg',
  ALT: 'Логотип СБП'
}

const ModalContent = styled('div')(({theme}) => ({
  padding: '38px',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '19px',
  '.description, .fundraising-output-input': {
    marginBottom: 0
  }
}))

export const IntroStep = ({setStep}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        alignItems: 'center'
      }}
    >
      <img src={SBP_LOGO.SRC} alt={SBP_LOGO.ALT} width="173px" />
      <Typography variant="modal_header">Для вывода денег, подключите СБП по своему номеру телефона.</Typography>
      <Button
        onClick={() => setStep('input')}
        startIcon={<CheckIcon color="white" />}
        variant="contained"
        type="button"
        sx={{width: '100%'}}
      >
        <Typography variant="button">Подключить СБП</Typography>
      </Button>
    </Box>
  )
}

export const SuccessStep = ({bank, phone, handleClose = null}) => {
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '19px'}}>
      <Box sx={{}}>
        <img src={SBP_LOGO.SRC} alt={SBP_LOGO.ALT} width="173px" />
      </Box>
      <Box sx={{}}>
        <Typography variant="modal_header">Вы авторизованы в СБП</Typography>
      </Box>

      <Typography variant="bolded">{bank}</Typography>
      <Box sx={{margin: '-10px'}}>
        <Typography variant="bolded">{phone}</Typography>
      </Box>

      {handleClose ? (
        <Button onClick={handleClose} startIcon={<CheckIcon color="white" />} variant="contained" type="button">
          Закрыть
        </Button>
      ) : (
        <Box sx={{color: '#545454'}}>
          Смена банка или номера телефона возможна через{' '}
          <a style={{color: '#545454'}} href="mailto:support@rodcom.ru">
            службу поддержки
          </a>{' '}
        </Box>
      )}
    </Box>
  )
}
const FillBankModal = ({rkId, isOpen, handleClose}) => {
  const theme = useTheme()
  const [userBank, setUserBank] = useState('')
  const [sbpPhone, setSbpPhone] = useState('')
  const [code, setCode] = useState('  ')
  const dispatch = useDispatch()

  const step = useSelector(profileSelectors.check_sum_step.selector)
  const isLoading = useSelector(profileSelectors.isLoading.selector)

  const savedBankName = useSelector(profileSelectors.user.selector)?.beneficiaryBankName
  const savedPhone = useSelector(profileSelectors.user.selector)?.sbpPhone

  const [banks, setBanks] = useState([])

  useEffect(() => {
    setSbpPhone(sbpPhone.replace(/[^+\d]/g, ''))
  }, [sbpPhone])

  useEffect(() => {
    getSbpParticipants().then((res) => {
      if (res.data.payload.length) setBanks(res.data.payload.map((x) => ({id: x.order, label: x.memberNameRus})))
    })
  }, [])

  const sendControlSum = useCallback(
    (e) => {
      e.preventDefault()
      setSeconds(60)
      e.nativeEvent.stopPropagation()
      dispatch(sendControlSumEvent.action(rkId, userBank.label, sbpPhone))
      //handleClose()
    },
    [rkId, userBank, sbpPhone]
  )

  const setStep = (step) => {
    dispatch(setStepEvent.action(step))
  }

  const checkControlSum = useCallback(
    (e) => {
      e.preventDefault()
      e.nativeEvent.stopPropagation()
      dispatch(checkControlSumEvent.action(rkId, userBank, sbpPhone, code))
      //handleClose()
    },
    [rkId, userBank, sbpPhone, code]
  )

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  // const errorStep = ({props}) => {
  //   return (
  //     <>
  //       <Typography variant="h4" sx={{lineHeight: '29px', fontSize: '19px'}}>
  //         Ошибка
  //       </Typography>
  //       <Typography variant="h4" sx={{lineHeight: '29px', fontSize: '19px'}}>
  //         Указанный номер телефона не зарегистрирован на ваше имя. Пожалуйста, проверьте данные.
  //       </Typography>
  //       <Button onClick={setStep('input')} startIcon={<CheckIcon color="white" />} variant="contained" type="button">
  //         <Typography variant="button">Назад</Typography>
  //       </Button>
  //     </>
  //   )
  // }

  // const badPhoneStep = ({props}) => {
  //   return (
  //     <>
  //       <img src={SBP_LOGO.SRC} alt={SBP_LOGO.ALT} />
  //       <Typography variant="h4" sx={{lineHeight: '29px', fontSize: '19px'}}>
  //         Ошибка
  //       </Typography>
  //       <Typography variant="h4" sx={{lineHeight: '29px', fontSize: '19px'}}>
  //         Указанный номер телефона не зарегистрирован на ваше имя. Пожалуйста, проверьте данные.
  //       </Typography>
  //       <Button onClick={saveBank} startIcon={<CheckIcon color="white" />} variant="contained" type="button">
  //         <Typography variant="button">Назад</Typography>
  //       </Button>
  //     </>
  //   )
  // }

  const RetryStep = () => {
    return (
      <>
        <img src={SBP_LOGO.SRC} alt={SBP_LOGO.ALT} />
        <Typography variant="h4" sx={{lineHeight: '29px', fontSize: '19px'}}>
          Ошибка
        </Typography>
        <Typography>Указана неверная контрольная сумма.</Typography>
        <Typography sx={{lineHeight: '29px', fontSize: '19px'}}>У вас есть 2 попытки.</Typography>
        <Button
          onClick={() => setStep('check')}
          startIcon={<CheckIcon color="white" />}
          variant="contained"
          type="button"
        >
          Назад
        </Button>
      </>
    )
  }

  const NoRetryStep = () => {
    return (
      <>
        <img src={SBP_LOGO.SRC} alt={SBP_LOGO.ALT} />
        <Typography variant="h4" sx={{lineHeight: '29px', fontSize: '19px'}}>
          Ошибка
        </Typography>
        <Typography>Лимит попыток исчерпан.</Typography>
        <Typography sx={{lineHeight: '29px', fontSize: '19px'}}>Повторить можно через 60 сек.</Typography>
        <Button onClick={() => handleClose()} startIcon={<CheckIcon color="white" />} variant="contained" type="button">
          Назад
        </Button>
      </>
    )
  }

  const handleChangeCode = ({target}) => {
    let {value} = target

    value = value.replace(/_+/, '')
    setCode(value)
  }

  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        clearInterval(myInterval)
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  })

  const sendAgainBtn = useMemo(() => {
    // const className = seconds ? 'sendAgain' : 'sendAgain sendAgainActive'
    const text = `Отправить ещё раз можно через ${seconds} сек.`
    return (
      <Typography style={{display: seconds === 0 && 'none'}} textAlign="center">
        {text}
      </Typography>
    )
  }, [seconds])

  const InputStep = () => {
    return (
      <>
        <Typography variant="h4" sx={{lineHeight: '29px', fontSize: '24px', color: '#39215E', fontWeight: 700}}>
          Данные для вывода средств
        </Typography>
        {/*<AmountInput codeS={code} setCodeS={setCode} />*/}
        <Box
          sx={{
            '& .MuiFormControl-root': {
              marginBottom: 0
            }
          }}
        >
          <PhoneInput
            secondLabel={'Используется для вывода средств, если вы — глава родкома'}
            phone={sbpPhone}
            phoneHandler={setSbpPhone}
          />
        </Box>

        <InputLabel
          sx={{
            fontSize: '15px !important',
            fontWeight: '700',
            color: '#39215E',
            marginBottom: '-6px'
          }}
        >
          Банк для вывода средств
        </InputLabel>

        <Autocomplete
          id="user-bank"
          options={banks}
          sx={{
            '& .MuiOutlinedInput-root input': {
              border: 'none'
            }
          }}
          onChange={(event, newValue) => {
            setUserBank(newValue)
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        <LoadingButton
          disabled={!userBank || !sbpPhone || sbpPhone.length !== 12 || seconds !== 0}
          onClick={sendControlSum}
          startIcon={<CheckIcon color="white" />}
          variant="contained"
          type="button"
        >
          Подтвердить
        </LoadingButton>

        {sendAgainBtn}
        {fullScreen && (
          <Button variant="outlined" onClick={sendControlSum}>
            Отмена
          </Button>
        )}
      </>
    )
  }

  const CheckStep = () => {
    return (
      <>
        <Typography variant="h4" sx={{lineHeight: '29px', fontSize: '19px'}}>
          На ваш счет будет зачислена небольшая сумма, для проверки принадлежности счета. Введите ее для подтверждения.
        </Typography>
        <InputMask mask="9.99" value={code} onChange={handleChangeCode}>
          {(inputProps) => <TextField {...inputProps} type="tel" className="" disableUnderline />}
        </InputMask>

        <Button onClick={checkControlSum} startIcon={<CheckIcon color="white" />} variant="contained" type="button">
          Подтвердить
        </Button>
        {fullScreen && (
          <Button variant="outlined" onClick={handleClose}>
            Отмена
          </Button>
        )}
      </>
    )
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} fullScreen={fullScreen} fullWidth maxWidth="xs">
      <ModalContent>
        {isLoading ? (
          <Box sx={{overflow: 'hidden'}}>
            <CircularProgress sx={{margin: '0 auto', padding: 19, display: 'block'}} />
          </Box>
        ) : (
          <>
            {step === 'intro' && <IntroStep setStep={setStep} />}
            {step === 'input' && InputStep()}
            {step === 'check' && CheckStep()}
            {step === 'no_retry' && NoRetryStep()}
            {step === 'retry' && RetryStep()}
            {step === 'success' && <SuccessStep phone={savedPhone} bank={savedBankName} handleClose={handleClose} />}
          </>
        )}
      </ModalContent>
    </Dialog>
  )
}
export default FillBankModal
