import {styled} from '@mui/material/styles'
import {Typography} from '@mui/material'

export const MembersWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  margin: '0 19px',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  [theme.breakpoints.up(900)]: {
    margin: '0',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  [theme.breakpoints.between('md', 1224)]: {
    // padding: '0 19px'
  }
}))

export const Member = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  gap: '13px',
  padding: '13px',
  // minHeight: '77px',
  background: '#FFFFFF',
  borderRadius: '12px',
  boxSizing: 'border-box',
  // boxShadow: '0px 12px 32px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  // border: '1px solid #EDEDFF',
  // [theme.breakpoints.between('md', 1224)]: {
  //   width: 'calc(50% - 6px)'
  // },
  // [theme.breakpoints.up('lg')]: {
  //   maxWidth: '322px'
  // }
  [theme.breakpoints.down(900)]: {
    width: '100%'
  }
}))

export const Avatar = styled('img')(() => ({
  width: '64px',
  height: '64px',
  borderRadius: '100%',
  marginRight: '13px'
}))

export const Text = styled(Typography)(({theme}) => ({
  color: theme.palette.primary.main,
  whiteSpace: 'nowrap',
  // overflow: 'hidden',
  textOverflow: 'ellipsis',
  // height: '15px',
  marginBottom: 5
}))
