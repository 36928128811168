import React from 'react'

import {styled} from '@mui/material/styles'

import {StyledLoadingButton} from '../../../../components/StyledLoadingButton/StyledLoadingButton'
import {CheckIcon} from '../../../../icons'
import UserAgreement from '../../../../components/UserAgreement/UserAgreement'

const Wrapper = styled('div')(() => ({
  background: '#F5F5FF',
  height: 'min-content',
  // boxShadow: '0px 12px 12px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  border: '1px solid #EDEDFF',
  borderRadius: '12px',
  padding: '24px',
  width: '515px',
  boxSizing: 'border-box',
  button: {
    fontSize: 16
  },
  '.userAgreement': {
    marginBottom: 22
  },
  '@media(max-width: 900px)': {
    padding: '24px',
    marginTop: 24,
    marginBottom: 24
  },
  '@media(max-width: 600px)': {
    padding: '24px'
  },
  '@media(max-width: 1100px)': {
    // maxWidth: '100%',
    width: '100%'
  }
}))

const AccessRulesAndSaveBlock = ({
  userAgreement,
  handleUserAgreement,
  loading,
  handleDataSubmit,
  isUserAgreementErr
}) => {
  // todo добавить ссылку на пользовальского соглашения
  return (
    <Wrapper>
      <UserAgreement
        className="userAgreement"
        isError={isUserAgreementErr}
        userAgreement={userAgreement}
        handleUserAgreement={handleUserAgreement}
      />
      <StyledLoadingButton
        onClick={handleDataSubmit}
        loading={loading}
        loadingPosition="start"
        variant="contained"
        color="primary"
        type="submit"
        startIcon={<CheckIcon color="white" />}
      >
        Сохранить и войти
      </StyledLoadingButton>
    </Wrapper>
  )
}

export default AccessRulesAndSaveBlock
