import {styled} from '@mui/material/styles'

export const Wrapper = styled('div')({
  width: '100%',
  marginTop: '32px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '.button': {
    margin: '32px 0 0 0',
    fontSize: '16px'
  },
  '.arrowRight': {
    marginLeft: '14px'
  },
  '.forgotPassword': {
    color: 'rgba(119, 119, 119, 1)',
    marginTop: '24px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px'
  },
  '.password': {
    marginBottom: 0
  },
  ' .phone': {
    marginBottom: 21
  },
  input: {
    fontSize: '16px',
    lineHeight: '24px'
  },
  '@media(max-width: 500px)': {
    marginTop: '28px',
    '.forgotPassword': {
      marginTop: '24px'
    }
  }
})
