import React from 'react'

import {FormWrapper, HeaderLogin, Wrapper} from '../LoginPage/LogintPageStyle'
import {LOGO_IMAGE_DATA} from '../../components/AppHeader/AppHeaderConstants'
import RestoringAccessForm from './components/RestoringAccessForm/RestoringAccessForm'
import Link from '@mui/material/Link'
import {PATHS} from '../../routes/paths'
const RestoringAccessPage = () => {
  return (
    <Wrapper>
      <HeaderLogin>
        <Link href={PATHS.HOME}>
          <img src={LOGO_IMAGE_DATA.SRC} alt="" />
        </Link>
      </HeaderLogin>
      <FormWrapper>
        <RestoringAccessForm />
      </FormWrapper>
    </Wrapper>
  )
}

export default RestoringAccessPage
