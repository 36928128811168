import {styled} from '@mui/material/styles'

export const StyledVoteCreateBody = styled('div')(({theme}) => ({
  '& .MuiFormControl-root': {
    maxWidth: '100%'
  },
  '& .MuiInputAdornment-positionEnd': {
    justifyContent: 'flex-end'
  }
}))

export const StyledLabel = styled('div')(({theme}) => ({
  ...theme.components.MuiInputLabel.styleOverrides.root,
  transform: 'translate(0, -1.5px) scale(0.75)',
  fontSize: 22,
  transformOrigin: 'left'
}))
