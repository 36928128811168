import React, {useCallback, useState, useEffect} from 'react'

import Typography from '@mui/material/Typography'

import {Text} from '../../../components/ParagraphWrapper/ParagraphWrapperStyle'
import {ButtonWrapper, SecondButtonWrapper} from '../../../Summary/components/projects/ProjectsStyle'
import {DownloadIcon, PlusIcon, VerticalDotsIcon} from '../../../../../../icons'
import {MembersHeader, StyledMenu, StyledMenuItem} from '../../MembersPageStyle'
import {Button, Dialog, Box} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux'
import {committeeSettingsSelectors} from '../../../../../../store/committeeSettingsStore/committeeSettingsStore'
import {makeRkMembersEvent} from '../../../../../../store/reportingStore/reportingStoreSliceEvents'
import {reportingSelectors} from '../../../../../../store/reportingStore/reportingStore'
import LoadingButton from '@mui/lab/LoadingButton'
// import MemberRecordPage from '../../pages/ParentCommittee/subpages/Members/components/MemberRecordPage/MemberRecordPage'

const MembersHeaderComponent = ({membLength, referenceRecords, handleAddRecordClick, isChief, handleModal}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useDispatch()
  const rkUuid = useSelector(committeeSettingsSelectors.uuid.selector)
  const isLoading = useSelector(reportingSelectors.isLoading.selector)
  const [loading, setLoading] = useState(false)

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleDownloadClick = () => {
    dispatch(makeRkMembersEvent.action(rkUuid))
  }

  useEffect(() => {
    if (isLoading) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [isLoading])

  return (
    <MembersHeader>
      <div className="members-amount">
        <Text className="title">В родкоме: {membLength}</Text>
        <Typography className="subTitle" variant="caption" sx={{color: '#666666'}}>
          Всего записей: {referenceRecords || 0}
        </Typography>
      </div>
      {isChief && (
        <Button
          className="add-record-btn"
          variant="contained"
          color="secondary"
          type="button"
          onClick={() => handleModal('', true)}
          sx={{boxShadow: 'none'}}
          startIcon={<PlusIcon className="addRecordIcon" />}
        >
          Добавить справочную запись
        </Button>
      )}
      {isChief && (
        <LoadingButton
          className="download-btn"
          variant="contained"
          type="button"
          color="info"
          loading={loading}
          loadingPosition="start"
          onClick={handleDownloadClick}
          startIcon={<DownloadIcon />}
        >
          Скачать в PDF
        </LoadingButton>
      )}
      {isChief && (
        <ButtonWrapper
          aria-controls={anchorEl ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={anchorEl ? 'true' : undefined}
          aria-label="open-context-menu"
          className="context-menu-btn header-btn"
          variant="outlined"
          type="button"
          onClick={handleClick}
          startIcon={<VerticalDotsIcon color="#39215E" />}
        />
      )}
      {isChief && (
        <StyledMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          keepMounted
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          <StyledMenuItem onClick={handleDownloadClick}>Скачать в PDF</StyledMenuItem>
          <StyledMenuItem onClick={handleAddRecordClick}>Добавить справочную запись</StyledMenuItem>
        </StyledMenu>
      )}
    </MembersHeader>
  )
}

export default MembersHeaderComponent
