import {Box} from '@mui/material'
import InfoBlock from './InfoBlock'
import {roundDecimals} from '../../../../../utils/math'
import React from 'react'

const SummaryBlock = ({
  startBalance,
  endBalance,
  paid,
  expenses,
  filterProject,
  projectPage,
  summary,
  isPiggy,
  isSelectPeriod
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {sm: 'column', lg: 'row'},
        gap: {sm: '16px', lg: '2px'},
        margin: '0 auto',
        // marginBottom: '19px',
        // marginLeft: {sm: '19px', lg: 'auto'},
        borderRadius: '8px',
        width: '100%'
      }}
    >
      <InfoBlock
        title="Баланс на начало периода"
        sum={startBalance}
        styles={{borderRadius: '3px 0 0 3px', width: '100%'}}
        projectPage={projectPage}
      />
      <InfoBlock
        title="Поступило"
        sum={filterProject ? paid : summary?.payments?.[0]?.paid}
        styles={{width: '100%'}}
        subTitle={
          filterProject
            ? `От взносов: ${summary?.payments?.[0]?.paid}₽, из ${isPiggy ? 'проектов' : 'Копилки'}: ${
                summary?.transfers?.[0]?.paid
              }₽`
            : null
        }
        projectPage={projectPage}
      />
      <InfoBlock
        title="Потратили"
        sum={filterProject ? expenses : summary?.payments?.[0]?.expense}
        styles={{width: '100%'}}
        subTitle={
          filterProject
            ? `На покупки:  ${summary?.payments?.[0]?.expense}₽, перевели в ${isPiggy ? 'проекты' : 'Копилку'}: ${
                summary?.transfers?.[0]?.expense
              }₽`
            : null
        }
        projectPage={projectPage}
      />
      <InfoBlock
        title="Баланс на конец периода"
        sum={endBalance}
        styles={{borderRadius: '0 3px 3px 0', width: '100%'}}
        subTitle={
          summary?.totalDebt !== 0 && !isSelectPeriod
            ? `${summary?.totalDebt}₽ из которых на карте у главы родкома`
            : null
        }
        projectPage={projectPage}
      />
    </Box>
  )
}
export default SummaryBlock
