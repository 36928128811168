import React from 'react'

import {LockIcon} from '../../../icons'
import {
  FormHelperTextWrapper,
  InputLabelWrapper,
  MenuItemWrapper,
  SelectWrapper,
  StyledFormControl
} from './StyledSelectStyle'

const StyledSelect = ({
  id,
  label = '',
  className = '',
  value,
  secondLabel,
  onChangeValue,
  helpText,
  options = [],
  error = false,
  errorMsg = '',
  clearError,
  disabled,
  selectById = false,
  withNotRequiredText,
  withClearOption = false,
  clearOption = 'Ничего'
}) => {
  const onChange = (evt) => {
    if (errorMsg && clearError) {
      clearError()
    }
    const {value} = evt.target
    if (value !== clearOption) {
      onChangeValue(value)
    } else {
      onChangeValue('')
    }
  }

  return (
    <StyledFormControl
      disabled={disabled}
      variant="standard"
      fullWidth={true}
      className={className}
      error={error}
      sx={{maxWidth: '500px'}}
    >
      {label ? (
        <InputLabelWrapper htmlFor={id} shrink>
          {label} {withNotRequiredText && <span className="notRequiredText">(не обязательно)</span>}
        </InputLabelWrapper>
      ) : null}
      <SelectWrapper id={id} value={value ?? ''} defaultValue="" variant="outlined" displayEmpty onChange={onChange}>
        {helpText && (
          <MenuItemWrapper disabled value="">
            {helpText}
          </MenuItemWrapper>
        )}

        {withClearOption ? <MenuItemWrapper value={clearOption}>{clearOption}</MenuItemWrapper> : null}
        {options.map((item) => {
          const {label, id} = item
          let value = label
          if (selectById) {
            value = id
          }
          return (
            <MenuItemWrapper value={value} key={id}>
              {label}
            </MenuItemWrapper>
          )
        })}
      </SelectWrapper>
      {disabled && <LockIcon className="lockIcon" />}
      {error && Boolean(errorMsg) && <FormHelperTextWrapper>{errorMsg}</FormHelperTextWrapper>}
      {!error && secondLabel && <FormHelperTextWrapper>{secondLabel}</FormHelperTextWrapper>}
    </StyledFormControl>
  )
}

export default StyledSelect
