import React from 'react'

import {styled} from '@mui/material/styles'
import Autocomplete from '@mui/material/Autocomplete'
import InputAdornment from '@mui/material/InputAdornment'
import Popper from '@mui/material/Popper'
import {Typography} from '@mui/material'

import loupe from '../../../images/loupe.svg'

export const StyledAutocomplete = styled(Autocomplete)(({theme}) => ({
  '& input': {
    border: 'none'
  },
  '& .MuiOutlinedInput-root': {
    background: theme.palette.background.paper,
    position: 'relative',
    marginTop: '26px',
    paddingRight: '13px !important',
    boxShadow: '0px 12px 32px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
    borderRadius: '6px'
  },
  '.MuiOutlinedInput-root': {
    zIndex: 1300,
    '&:before': {
      color: theme.palette.primary.main,
      content: "''",
      zIndex: 1300,
      position: 'absolute',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url(${loupe})`,
      width: '26px',
      height: '26px',
      backgroundSize: '26px',
      left: 7,
      top: 10,
      boxSizing: 'border-box'
    },
    '& input': {
      paddingLeft: '30px !important'
    }
  },
  '&.opened .MuiChip-root': {
    // display: 'none'
  },
  '&:not(.opened) .MuiAutocomplete-inputRoot .loupe-icon': {
    // display: 'none'
  },
  '& label': {
    display: 'none'
  },
  '& legend': {
    display: 'none'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    top: 0
  },
  '& .MuiAutocomplete-clearIndicator': {
    visibility: 'visible'
  }
}))

export const StyledInputAdornment = styled(InputAdornment)(() => ({
  cursor: 'pointer'
}))

export const GroupItem = styled(Typography)(({theme}) => ({
  fontWeight: '700',
  color: theme.palette.primary.main,
  padding: '10px 13px'
  // width: '100%'
}))

export const OptionItem = styled('li')(({theme}) => ({
  color: theme.palette.primary.main,
  padding: '6px 6px !important'
}))

export const StyledPopper = styled(Popper)(({theme}) => ({
  '&.groupedAutocomplete': {
    zIndex: '1300 !important',
    background: theme.palette.background.paper,
    width: '232px !important',
    // width: '290px !important',
    // top: '53px !important',
    // padding: '53px 6px 0px !important',
    boxSizing: 'border-box',
    borderRadius: '6px',
    '& *': {
      border: 0,
      boxShadow: 'none'
    },
    boxShadow: `0px 4px 8px ${theme.palette.grey[100]}`
  },
  [theme.breakpoints.down(1224)]: {
    '&.groupedAutocomplete': {
      top: '0 !important',
      paddingTop: '0 !important',
      width: '312px !important'
    }
  }
}))
