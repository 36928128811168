import React, {useState, useEffect} from 'react'
import {Typography, Dialog, Box, Divider} from '@mui/material'

import {getFormattedDate} from '../../../../../../utils/getFormattedDate'
import {StyledAllOperationsCell, StyledWrapper} from './RowTableAllOperationsStyles'
import {tableOperationsFields} from './constants'
import IconButton from '@mui/material/IconButton'
import {CrossIcon} from '../../../../../../icons'
import BillContainer from '../BillContainer'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import {getValuePlaceholder} from '../../../../../../utils/getValuePlaceholder'

export const fieldTypes = {
  string: [
    tableOperationsFields.projectName,
    tableOperationsFields.paymentDescription,
    tableOperationsFields.fullNameParent,
    tableOperationsFields.cardNumbers
  ],
  number: [tableOperationsFields.amountPaid, tableOperationsFields.date]
}

const RowTableAllOperations = ({row, history, projectFilter}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const [openModal, setOpenModal] = useState(false)
  const [isDebtOperation, setIsDebtOperation] = useState(false)
  //td-0...3 - классы с порядковыми значениями ячеек в строке таблицы

  const [cash, setCash] = useState('')

  useEffect(() => {
    // if (projectFilter) {
    switch (row.operationType) {
      case 'GiveOutSuccess':
        setCash(getValuePlaceholder(Math.abs(row.amountPaid)))
        setIsDebtOperation(false)
        break

      case 'Transfer':
        if (projectFilter) {
          if (row.amountPaid > 0) {
            setCash(`+${getValuePlaceholder(row.amountPaid)}`)
            setIsDebtOperation(false)
          } else {
            setCash(getValuePlaceholder(row.amountPaid))
            setIsDebtOperation(true)
          }
        } else {
          setCash(getValuePlaceholder(Math.abs(row.amountPaid)))
          setIsDebtOperation(false)
        }
        break

      case 'ExpertReportConfirmed':
        setCash(`-${getValuePlaceholder(row.amountPaid)}`)
        setIsDebtOperation(true)
        break

      default:
        setCash(`${row.amountPaid > 0 ? '+' : ''}${getValuePlaceholder(row.amountPaid)}`)
        setIsDebtOperation(false)
        break
    }
  }, [projectFilter, row.amountPaid, row.operationType])

  return (
    <>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="xs"
        sx={{margin: '20px'}}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '8px',
            left: 'auto',
            right: '12px'
          }}
          onClick={() => setOpenModal(false)}
        >
          <CrossIcon />
        </IconButton>
        <Box sx={{padding: '20px', display: 'flex', flexDirection: 'column', gap: '12px'}}>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
            <Typography variant="cell" sx={{fontSize: '12px', lineHeight: '16px', fontWeight: 500}}>
              Дата
            </Typography>
            <Typography variant="cell" sx={{fontSize: '15px', lineHeight: '24px', fontWeight: 600}}>
              {getFormattedDate(row.date)}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
            <Typography variant="cell" sx={{fontSize: '12px', lineHeight: '16px', fontWeight: 500}}>
              Тип
            </Typography>
            <Typography variant="cell" sx={{fontSize: '15px', lineHeight: '24px', fontWeight: 600}}>
              {row.parentName === 'Аноним'
                ? 'Донат'
                : row.operationType === 'Transfer' || row.operationType === 'GiveOutSuccess'
                ? 'Движение'
                : row.operationType === 'ExpertReportConfirmed'
                ? 'Расход'
                : 'Взнос'}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
            <Typography variant="cell" sx={{fontSize: '12px', lineHeight: '16px', fontWeight: 500}}>
              Отправитель
            </Typography>
            <Typography variant="cell" sx={{fontSize: '15px', lineHeight: '24px', fontWeight: 600}}>
              {row.parentName === 'Аноним'
                ? 'Неавторизованный пользователь'
                : row.operationType === 'Transfer' && row.amountPaid < 0
                ? row.projectName
                : row.operationType === 'Transfer' && row.amountPaid > 0
                ? row.relatedProjName
                : row.operationType === 'ExpertReportConfirmed'
                ? `${row.parentName} (ГРК)`
                : row.operationType === 'GiveOutSuccess'
                ? row.projectName
                : row.parentName}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
            <Typography variant="cell" sx={{fontSize: '12px', lineHeight: '16px', fontWeight: 500}}>
              Получатель
            </Typography>
            <Typography variant="cell" sx={{fontSize: '15px', lineHeight: '24px', fontWeight: 600}}>
              {row.operationType === 'Transfer' && row.amountPaid < 0
                ? row.relatedProjName
                : row.operationType === 'Transfer' && row.amountPaid > 0
                ? row.projectName
                : row.operationType === 'GiveOutSuccess'
                ? `${row.parentName} (ГРК)`
                : row.operationType === 'ExpertReportConfirmed'
                ? 'Поставщик'
                : row.projectName}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
            <Typography variant="cell" sx={{fontSize: '12px', lineHeight: '16px', fontWeight: 500}}>
              Назначение
            </Typography>
            {row.operationType === 'Transfer' ? (
              <Typography variant="cell" sx={{fontSize: '15px', lineHeight: '24px', fontWeight: 600}}>
                {row.amountPaid > 0
                  ? `Перевод с "${row.relatedProjName}" в "${row.projectName}"`
                  : `Перевод с "${row.projectName}" в "${row.relatedProjName}"`}
              </Typography>
            ) : row.operationType === 'GiveOutSuccess' ? (
              <Typography variant="cell" sx={{fontSize: '15px', lineHeight: '24px', fontWeight: 600}}>
                Вывод средств под отчет по проекту {row.projectName}
              </Typography>
            ) : row.operationType === 'ExpertReportConfirmed' ? (
              <Typography variant="cell" sx={{fontSize: '15px', lineHeight: '24px', fontWeight: 600}}>
                Покупка по проекту "{row.projectName}" {row.description && `на "${row.description}"`}
              </Typography>
            ) : (
              <Typography variant="cell" sx={{fontSize: '15px', lineHeight: '24px', fontWeight: 600}}>
                Взнос {row?.projectName !== 'Копилка' && 'по начислению'} за ребенка{' '}
                {row?.childrenName ? `${row?.childrenName?.lastName} ${row?.childrenName?.firstName}` : row.childName},
                по проекту "{row.projectName}"
                {row?.projectName === 'Копилка' && row.description && `, "${row.description}"`}. Плательщик{' '}
                {row.parentName} {row.authCode ? row.authCode : null} {row.cardNumbers ? row.cardNumbers : null}
              </Typography>
            )}
          </Box>
          <Divider />
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
            <Typography variant="cell" sx={{fontSize: '12px', lineHeight: '16px', fontWeight: 500}}>
              Сумма
            </Typography>
            <Typography variant="cell" sx={{fontSize: '15px', lineHeight: '24px', fontWeight: 600}}>
              {/*{row.operationType === 'ExpertReportConfirmed'*/}
              {/*  ? `-${row.amountPaid}`*/}
              {/*  : row.operationType === 'PaymentSuccess' || (row.operationType === 'Transfer' && row.amountPaid > 0)*/}
              {/*  ? `+${row.amountPaid}`*/}
              {/*  : projectFilter && row.operationType !== 'GiveOutSuccess'*/}
              {/*  ? `${row.amountPaid}`*/}
              {/*  : Math.abs(row.amountPaid)}{' '}*/}
              {cash} ₽
            </Typography>
          </Box>
          {row.fileUuids ? (
            <>
              <Divider />
              <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                <Typography variant="cell" sx={{fontSize: '12px', lineHeight: '16px', fontWeight: 500}}>
                  Первичный документ
                </Typography>
                <Typography variant="cell" sx={{fontSize: '15px', lineHeight: '24px', fontWeight: 600}}>
                  {row.fileUuids.map((item, index) => (
                    <a
                      href={`${process.env.REACT_APP_API_URL}/v1/doc/${item}`}
                      key={item}
                      download
                      style={{marginRight: '12px'}}
                    >
                      Документ №{index + 1}
                    </a>
                  ))}
                </Typography>
              </Box>
            </>
          ) : null}
        </Box>
      </Dialog>

      <StyledWrapper component="tr">
        <StyledAllOperationsCell
          component="td"
          className={`td-0 ${history ? 'history' : ''} ${mobile ? `mobile` : ''}`}
        >
          <Typography variant="cell">{getFormattedDate(row.date)}</Typography>
        </StyledAllOperationsCell>

        <StyledAllOperationsCell
          component="td"
          className={`td-1 ${history ? 'history' : ''} ${mobile ? `mobile` : ''}`}
        >
          <Typography variant="cell">
            {row.operationType === 'Transfer' && row.amountPaid < 0
              ? row.projectName
              : row.operationType === 'Transfer' && row.amountPaid > 0
              ? row.relatedProjName
              : row.operationType === 'ExpertReportConfirmed'
              ? `${row.parentName} (ГРК)`
              : row.operationType === 'GiveOutSuccess'
              ? row.projectName
              : row.parentName}
          </Typography>
        </StyledAllOperationsCell>

        <StyledAllOperationsCell
          component="td"
          className={`td-2 ${history ? 'history' : ''} ${mobile ? `mobile` : ''}`}
        >
          <Typography variant="cell">
            {row.operationType === 'Transfer' && row.amountPaid < 0
              ? row.relatedProjName
              : row.operationType === 'Transfer' && row.amountPaid > 0
              ? row.projectName
              : row.operationType === 'GiveOutSuccess'
              ? `${row.parentName} (ГРК)`
              : row.operationType === 'ExpertReportConfirmed'
              ? 'Поставщик'
              : row.projectName}
          </Typography>
        </StyledAllOperationsCell>

        {/* <StyledAllOperationsCell component="td" className={`td-3 ${history ? 'history' : ''}`}>
        {row.cardNumbers ? (
          <Typography variant="cell">оплата {row.cardNumbers ? ` c карты ${row.cardNumbers}` : null}</Typography>
        ) : (
          <Typography variant="cell">{row.paymentDescription ? row.paymentDescription : '-'}</Typography>
        )}
      </StyledAllOperationsCell> */}
        <StyledAllOperationsCell
          component="td"
          className={`td-3 ${history ? 'history' : ''} ${mobile ? `mobile` : ''}`}
        >
          {row.operationType === 'Transfer' ? (
            <Typography variant="cell">
              {row.amountPaid > 0
                ? `Перевод с "${row.relatedProjName}" в "${row.projectName}"`
                : `Перевод с "${row.projectName}" в "${row.relatedProjName}"`}
            </Typography>
          ) : row.operationType === 'GiveOutSuccess' ? (
            <Typography variant="cell">Вывод средств под отчет по проекту "{row.projectName}"</Typography>
          ) : row.operationType === 'ExpertReportConfirmed' ? (
            <Typography variant="cell">
              Покупка по проекту "{row.projectName}" {row.description && `на "${row.description}"`}
            </Typography>
          ) : (
            <Typography variant="cell">
              Взнос {row?.projectName !== 'Копилка' && 'по начислению'} за ребенка{' '}
              {row?.childrenName ? `${row?.childrenName?.lastName} ${row?.childrenName?.firstName}` : row.childName}, по
              проекту "{row.projectName}"{row?.projectName === 'Копилка' && row.description && `, "${row.description}"`}
              . Плательщик {row.parentName} {row.authCode ? row.authCode : null}{' '}
              {row.cardNumbers ? row.cardNumbers : null}
            </Typography>
          )}
          {/*<Typography variant="cell">{row.paymentDescription}</Typography>*/}
        </StyledAllOperationsCell>

        <StyledAllOperationsCell
          component="td"
          className={`td-4 ${history ? 'history' : ''} ${mobile ? `mobile` : ''}`}
          onClick={() => setOpenModal(true)}
          sx={{cursor: 'pointer'}}
        >
          <BillContainer
            billLink={row.fileUuids}
            amountPaid={row.amountPaid}
            type={row.operationType}
            changeColor
            projectFilter={projectFilter}
            cash={cash}
            isDebtOperation={isDebtOperation}
          />
        </StyledAllOperationsCell>
      </StyledWrapper>
    </>
  )
}

export default RowTableAllOperations
