export const getNumWithZero = (num) => (Number(num) > 9 ? `${num}` : `0${num}`)

export const getFormattedDate = (date_or_str) => {
  if (!date_or_str) {
    return ''
  }
  const date = new Date(date_or_str)
  const day = getNumWithZero(date.getDate())
  const month = getNumWithZero(date.getMonth() + 1)
  const year = date.getFullYear()
  return `${day}.${month}.${year}`
}

export const getFormattedDateWithHyphen = (date_or_str) => {
  if (!date_or_str) {
    return ''
  }
  const date = new Date(date_or_str)
  const day = getNumWithZero(date.getDate())
  const month = getNumWithZero(date.getMonth() + 1)
  const year = date.getFullYear()
  return `${year}-${month}-${day}`
}

export const convertDateToUTC = (date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
}

export const getMonth = (date) => {
  return date.toLocaleString('ru', {month: 'long'})
}
