import React, {useEffect, useMemo, useState, useCallback} from 'react'
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom'
import {Box, Typography, Button} from '@mui/material'
import {LOGO_IMAGE_DATA, pages} from '../../components/AppHeader/AppHeaderConstants'
import TextInput from '../../components/Inputs/TextInput/TextInput'
import {getProfileInfoEvent} from '../../store/inviteStore/inviteStoreSliceEvents'
import {useDispatch, useSelector} from 'react-redux'
import {committeeSettingsSelectors} from '../../store/committeeSettingsStore/committeeSettingsStore'
import {createDirectLinkDonate} from '../../services/payments'
import {
  getInitParentCommitteeData,
  getRkProfileEvent,
  getRkProfileEventSuccess
} from '../../store/committeeSettingsStore/committeeSettingsStoreSliceEvents'
import {createBillEvent} from '../../store/accrualsStore/accrualStoreSliceEvents'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import {getRkProfileRequest} from '../../services/committeeSettings'

const RkDonatePage = () => {
  const isLoading = false
  const dispatch = useDispatch()
  const [values, setValues] = useState({})
  const [projectErrors, setProjectErrors] = useState({})
  const {id} = useParams()
  // useEffect(async () => {
  //   const resp = await createDirectLinkDonate(id)
  //   if (resp.event === 'SUCCESS') window.location = resp.payload
  // }, [])

  const profileInfo = useSelector(committeeSettingsSelectors.profileInfo.selector)

  // useEffect(() => {
  //   getRkProfileRequest(id).then((r) => console.log(r))
  //   dispatch(getRkProfileEventSuccess.action(id))
  // }, [id])

  const getData = useCallback(async () => {
    const x = await getRkProfileRequest(id).then((r) => dispatch(getRkProfileEventSuccess.action(r.data.payload, id)))
  }, [id])

  useEffect(() => {
    getData()
  }, [id])

  const setValue = (value, field) => {
    setValues((prev) => ({...prev, [field]: value}))
    // onChangeGetOffError(field)
  }

  const setError = useCallback(
    (name, value) => {
      setProjectErrors((old) => ({...old, [name]: value}))
    },
    [projectErrors]
  )

  const onChange = (value) => {
    if (/^[0-9]*$/g.test(value)) {
      setValue(value, 'amount')
    }
  }

  const handlePublish = async () => {
    const amount = !!values?.amount
    !amount && setError('amount', 'Обязательное поле')
    const childName = !!values?.childName
    !childName && setError('childName', 'Обязательное поле')
    if (amount && childName) {
      const resp = await createDirectLinkDonate(id, {...values, rkUuid: id})
      if (resp.event === 'SUCCESS') window.location = resp.payload
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        background: '#FBFBFC',
        height: '100vh',
        width: '100%',
        // justifyContent: 'center',
        fontFamily: "font-family: 'Nunito', serif !important",
        '.input': {
          marginBottom: '0px !important'
        },
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Box
        sx={{
          padding: '48px',
          background: '#FFFFFF',
          boxShadow: '0px 12px 48px rgba(57, 33, 94, 0.06), 0px 2.4px 4px rgba(57, 33, 94, 0.04)',
          borderRadius: '24px',
          height: 'fit-content',
          marginTop: '40px',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          width: '78%'
        }}
      >
        <img className="logo" width="124px" height="40px" src={LOGO_IMAGE_DATA.SRC} alt={LOGO_IMAGE_DATA.ALT} />
        <Typography
          variant="h1"
          sx={{fontWeight: '700', fontSize: '40px', lineHeight: '48px', textTransform: 'inherit', color: '#39215E'}}
        >
          Взнос в копилку
        </Typography>
        <Box sx={{fontSize: '18px', lineHeight: '28px', fontWeight: 400, color: '#39215E'}}>
          <span>{`Деньги поступят на счет родительского комитета "${
            profileInfo?.name ? profileInfo?.name : profileInfo?.groupName
          }"`}</span>
          <br />
          <span>
            {profileInfo?.institutionAddress?.fullAddress}, {profileInfo?.institutionName}
          </span>
          <br />
          <span>{`${profileInfo?.institutionType === 'Kindergarten' ? 'Воспитатель:' : 'Классный руководитель:'} ${
            profileInfo?.educator
          }`}</span>
        </Box>
        <TextInput
          errorMsg={projectErrors?.amount}
          clearError={() => setError('amount', null)}
          id="amount"
          value={values.amount}
          // onChangeValue={(value) => setValue(value, 'amount')}
          onChangeValue={onChange}
          className="input"
          placeholder="Сумма"
        />
        <TextInput
          errorMsg={projectErrors?.childName}
          clearError={() => setError('childName', null)}
          id="childName"
          value={values.childName}
          onChangeValue={(value) => setValue(value, 'childName')}
          className="input"
          placeholder="Фамилия и имя ребёнка"
        />
        <TextInput
          // errorMsg={votingErrors.question}
          id="description"
          value={values.description || ''}
          onChangeValue={(value) => setValue(value, 'description')}
          className="input"
          withNotRequiredText
          placeholder="На что оплата  (не обязательно)"
          multiline={true}
          maxRows={7}
          minRows={7}
        />
        <Button
          variant="contained"
          type="button"
          sx={{width: 'fit-content'}}
          onClick={() => handlePublish()}
          endIcon={<ArrowRightAltIcon color="white" />}
        >
          К оплате
        </Button>
      </Box>
      <img src={'/images/svg/qiwi.svg'} alt={'qiwi'} width={'80%'} height={'120px'} />
    </Box>
  )
}

export default RkDonatePage
