import {Checkbox, Avatar, FormLabel, Box, IconButton} from '@mui/material'
import {styled} from '@mui/material/styles'
import {PencilIcon} from '../.../../../icons'

const StyledBox = styled('div')(({theme}) => ({
  // maxWidth: 416,
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '8px 12px',
  background: '#F9F7FB',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  borderRadius: 8,
  // cursor: 'pointer',
  boxSizing: 'border-box',
  '&:hover': {
    background: theme.palette.background.default
  },
  '.avatar': {
    width: 46,
    height: 46,
    marginLeft: '10px'
  },
  '.checkbox': {
    color: 'rgba(0, 0, 0, 0.12);'
  },
  '.checkboxChecked': {
    color: theme.palette.primary.main
  },
  '& svg': {
    fontSize: '24px'
  },
  '.label': {
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.primary.main,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  '@media(max-width: 600px)': {
    padding: '8px 12px',
    '& .avatar': {
      width: 36,
      height: 36
    },
    '& .label': {
      fontSize: '14px'
    }
  }
}))

const FormLabelWrapper = styled(FormLabel)(({theme}) => ({
  color: theme.palette.primary.main,
  fontSize: '18px',
  fontWeight: 500
}))

const KidsCheckbox = ({
  checked,
  setChecked,
  id,
  name,
  className,
  avatarUrl = '/images/avatar.png',
  birthday,
  onOpenModal
}) => {
  const onClick = () => {
    setChecked(!checked)
  }

  return (
    <StyledBox className={className}>
      <Checkbox
        onClick={onClick}
        checked={checked}
        classes={{
          root: 'checkbox',
          checked: 'checkboxChecked'
        }}
      />
      <Avatar classes={{root: 'avatar'}} alt={name} src={avatarUrl} />
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px', marginLeft: '16px'}}>
        <FormLabelWrapper classes={{root: 'label'}}>{name}</FormLabelWrapper>
        <span style={{fontSize: '12px', color: '#39215E', opacity: 0.6}}>{birthday}</span>
      </Box>
      <IconButton sx={{marginLeft: 'auto'}} onClick={() => onOpenModal(id)}>
        <PencilIcon className="button-icon" />
      </IconButton>
    </StyledBox>
  )
}

export default KidsCheckbox
