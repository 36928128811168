import {styled} from '@mui/material/styles'
import {Chip} from '@mui/material'

export const Wrapper = styled('div')(({theme}) => ({
  padding: 24,
  // margin: '38px 0 19px',
  // marginTop: 24,
  background: '#FFFFFF',
  boxShadow: '0px 12px 32px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  borderRadius: 12,
  // width: '100%',
  display: 'flex',
  flexDirection: 'column',
  // maxWidth: '640px',
  boxSizing: 'border-box',
  '.dead-line': {
    marginTop: 8
  },
  '.fundraising-alert': {
    marginTop: 10,
    marginBottom: 22
  },
  '.inner': {
    // maxWidth: 416
    with: '100%'
  },
  '.fundraising-input': {
    marginTop: 32,
    label: {
      color: '#000'
    }
  },
  '& .header': {
    marginBottom: 48
  },
  [theme.breakpoints.down(1224)]: {
    // margin: '38px 19px 19px'
  },
  [theme.breakpoints.down('md')]: {
    borderRadius: 12,
    // margin: '19px 0 0 0',
    padding: 24,
    '& .header': {
      marginBottom: 28
    }
  }
}))

export const StyledChip = styled(Chip)(({theme}) => ({
  marginLeft: '30px',
  borderRadius: '6px',
  height: 'auto',
  padding: '3px 0',
  background: 'rgba(57, 33, 94, 0.12)',
  color: theme.palette.primary.main,
  '&:hover': {
    background: '#E8DAFF'
  }
}))

export const TotalBlock = styled('div')(({theme}) => ({
  background: theme.palette.blue.main,
  color: theme.palette.blue.contrastText,
  padding: 13,
  borderRadius: '16px',
  fontWeight: 700,
  h4: {
    fontSize: 16
  }
  // marginTop: 24
}))

export const ChipsWrapper = styled('div')(() => ({
  marginBottom: 6,
  display: 'flex',
  gap: '3px',
  flexWrap: 'wrap'
}))

export const StyledLabel = styled('div')(({theme}) => ({
  ...theme.components.MuiInputLabel.styleOverrides.root,
  transform: 'translate(0, -1.5px) scale(0.75)',
  transformOrigin: 'left'
}))
