export const TABS = {
  ENTER: 'Войти',
  REG: 'Регистрация'
}

export const EVENT_STATUSES = {
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  CODE_CHECKED: 'CODE_CHECKED',
  CHANGING_PASSWORD_SEND_SMS: 'CHANGING_PASSWORD_SEND_SMS',
  REGISTRATION_SEND_SMS: 'REGISTRATION_SEND_SMS',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  RK_CREATED: 'RK_CREATED',
  RK_CREATE_FAIL_EXISTS: 'RK_CREATE_FAIL_EXISTS',
  UNAUTHORIZED: 'UNAUTHORIZED',
  USER_PROFILE_UPDATE: 'USER_PROFILE_UPDATE',
  RK_JOIN_CHILDREN: 'RK_JOIN_CHILDREN',
  CHILD_ADDED: 'CHILD_ADDED',
  RK_CHILD_CREATED: 'RK_CHILD_CREATED',
  RK_APPLICATION_MEMBERSHIP_CREATED: 'RK_APPLICATION_MEMBERSHIP_CREATED',
  RK_PROFILE_GET: 'RK_PROFILE_GET',
  RK_DELETED: 'RK_DELETED',
  RK_FAIL_NOT_FOUND: 'RK_FAIL_NOT_FOUND',
  RK_PROFILE_UPDATED: {STATUS: 'RK_PROFILE_UPDATED', MESSAGE_TO_USER: 'Параметры РОДКОМа успешно изменены'},
  RK_TRANSFER_CHIEF_MEMBERSHIP: 'RK_TRANSFER_CHIEF_MEMBERSHIP',
  RK_FAIL_NOT_FOUND_MEMBER: 'RK_FAIL_NOT_FOUND_MEMBER',
  AUTHORIZED: 'AUTHORIZED',
  RK_MEMBERS_LIST: 'RK_MEMBERS_LIST',
  RK_USER_LEFT: 'RK_USER_LEFT',
  RK_APPLICATION_MEMBERSHIP_APPROVED: 'RK_APPLICATION_MEMBERSHIP_APPROVED',
  RK_APPLICATION_MEMBERSHIP_REJECTED: 'RK_APPLICATION_MEMBERSHIP_REJECTED',
  RK_CHILD_DELETED: 'RK_CHILD_DELETED',
  RK_CHILD_UPDATED: 'RK_CHILD_UPDATED',
  USER_AVATAR_UPDATE: 'USER_AVATAR_UPDATE',
  PROJECT_FAIL_NOT_FOUND: 'PROJECT_FAIL_NOT_FOUND',
  PROJECT_ACTIVATED: 'PROJECT_ACTIVATED',
  PROJECT_CLOSED: 'PROJECT_CLOSED',
  PROJECT_DELETED: 'PROJECT_DELETED',
  PROJECT_ADDED: 'PROJECT_ADDED',
  RK_TRANSFER_CHIEF_MEMBERSHIP_APP: 'RK_TRANSFER_CHIEF_MEMBERSHIP_APP',
  RK_TRANSFER_CHIEF_MEMBERSHIP_NOT_LAUNCHED: 'RK_TRANSFER_CHIEF_MEMBERSHIP_NOT_LAUNCHED'
}

export const TIME = 59
