import httpClient from './network'
import {restApiMapper} from '../utils/restApiMapper'

export const getMySummary = (rkId) => {
  return restApiMapper.toClient(httpClient.get(`/accruals/${rkId}/summary`))
}

export const getMyAccruals = (rkId) => {
  return restApiMapper.toClient(httpClient.get(`/accruals/${rkId}/my`))
}

export const getPaidAccruals = (rkId) => {
  return restApiMapper.toClient(httpClient.get(`/accruals/${rkId}/paid`))
}

export const getPrAccruals = (rkId, prId) => {
  return restApiMapper.toClient(httpClient.get(`/accruals/${rkId}/all/${prId}`))
}

export const getRkAccruals = (rkId) => {
  return restApiMapper.toClient(httpClient.get(`/accruals/${rkId}/all`))
}
