import React, {useState} from 'react'
import {Avatar, Box, IconButton, Typography} from '@mui/material'
import {styled} from '@mui/material/styles'
import {ChildModal} from '../../../../components/ChildModal/ChildModal'
import {PencilIcon} from '../../../../icons'
import {avatarURL} from '../../../../utils/imageURL'

const ProfileSection = styled(Box)(({theme}) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  [theme.breakpoints.down(800)]: {
    flexDirection: 'column'
  },
  '& .child-card': {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
    padding: '6px 9px',
    minWidth: '214px',
    minHeight: '37px',
    background: '#ffffff',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '6px',
    '&:last-of-type': {
      // marginBottom: '19px'
    }
  },
  '& .child-avatar': {
    flexShrink: 0,
    width: '38px',
    height: '38px',
    marginRight: '13px'
  },
  '& .child-info': {
    marginRight: 'auto'
  },
  '& .child-name': {
    marginBottom: '3px',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'left'
  },
  '& .child-birth-date': {
    fontSize: '14px',
    lineHeight: '13px',
    color: '#777777',
    textAlign: 'left'
  },
  [theme.breakpoints.up('md')]: {
    '& .child-card': {
      width: '294px',
      minHeight: '45px',
      padding: '12px 18px'
    },
    '& .child-avatar': {
      width: '45px',
      height: '45px'
    },
    '& .child-name': {
      fontSize: '16px'
    }
  }
}))

export const MyChildren = ({children = [], setChildNewAvatarUrl, handleSaveChild, handleDeleteChild}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeChildData, setActiveChildData] = useState(null)

  const handleOpenModal = (childId) => {
    if (childId) {
      setIsModalOpen(true)
      const selectedChild = children.find(({id}) => id === childId)
      setActiveChildData(selectedChild)
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleAvatarSave = (avatarUrl, childId) => {
    setChildNewAvatarUrl(avatarUrl, childId)
  }

  const handleAvatarDelete = (childId) => {
    setChildNewAvatarUrl('', childId)
  }

  const getChildCard = (child) => {
    return (
      <Box className="child-card" key={child.id}>
        <Avatar src={avatarURL(child.avatarUrl)} className="child-avatar" />
        <Box className="child-info">
          <Typography className="child-name text">
            {child.lastName} {child.firstName}
          </Typography>
          <Typography className="child-birth-date text" sx={{fontSize: 14}}>
            {child.birthday}
          </Typography>
        </Box>
        <IconButton onClick={() => handleOpenModal(child.id)}>
          <PencilIcon className="button-icon" />
        </IconButton>
      </Box>
    )
  }

  return (
    <ProfileSection>
      {children.map((child) => getChildCard(child))}
      <ChildModal
        onSave={handleSaveChild}
        child={activeChildData}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onDelete={handleDeleteChild}
        handleAvatarSave={handleAvatarSave}
        handleAvatarDelete={handleAvatarDelete}
      />
    </ProfileSection>
  )
}
