import React, {useCallback, useMemo, useState, useEffect} from 'react'
import {Box} from '@mui/material'
import {useTheme} from '@mui/material/styles'

import RowTable from './RowTable'
import RowHead from './RowHead'
import {filterValues} from '../ReportsPage'
import RowTableAllOperations, {fieldTypes} from './RowTableAllOperations/RowTableAllOperations'

export const sortMethods = {
  ASC: 'ascend',
  DESC: 'descend'
}

const ReportTable = ({tableKind, data, headers, history, projectFilter}) => {
  const theme = useTheme()

  const dataPrepared = useMemo(() => {
    if (tableKind === filterValues.all) {
      return data.map((payment) => {
        return {
          ...payment
        }
      })
    }

    return null
  }, [data, tableKind])

  // useEffect(() => {
  //   console.log(dataPrepared)
  // }, [data])

  const [sortedData, setSortedData] = useState([])

  useEffect(() => {
    setSortedData(dataPrepared)
  }, [dataPrepared])

  const handleSort = useCallback(
    (sortOrderArray) => {
      const sortFunction = (a, b) => {
        let i = sortOrderArray.length
        let resultTotal = null
        while (i > 0) {
          const method = sortOrderArray[i - 1].sort
          const field = sortOrderArray[i - 1].field

          let resultInner

          switch (true) {
            case fieldTypes.string.includes(field):
              if (method === sortMethods.ASC) {
                resultInner = a[field]?.localeCompare(b[field])
              } else {
                resultInner = b[field]?.localeCompare(a[field])
              }
              break
            case fieldTypes.number.includes(field):
              if (method === sortMethods.ASC) {
                resultInner = b[field] - a[field]
              } else {
                resultInner = a[field] - b[field]
              }
              break
          }

          resultTotal = resultTotal === null ? resultInner : resultTotal || resultInner
          i--
        }

        return resultTotal
      }
      const newData = [...dataPrepared].sort(sortFunction)
      setSortedData(newData)
    },
    [dataPrepared]
  )

  // const renderTableRows = () => {
  //   if (!data.length) return null

  //   if (tableKind === filterValues.all) {
  //     return sortedData.map((row, index) => {
  //       return <RowTableAllOperations row={row} key={index.toString(36)} />
  //     })
  //   } else {
  //     return data.map((row, index) => {
  //       return <RowTable row={row} key={index.toString(36)} />
  //     })
  //   }
  // }

  const renderTableRows = useCallback(() => {
    if (!data.length) return null

    if (tableKind === filterValues.all && projectFilter) {
      return sortedData.map((row, index) => {
        return (
          <RowTableAllOperations row={row} key={index.toString(36)} history={history} projectFilter={projectFilter} />
        )
      })
    } else {
      // return data.map((row, index) => {
      //   return <RowTable row={row} key={index.toString(36)} />
      // })
      // const sortTransfers = sortedData.filter((data) => data.amountPaid > 0 && data.operationType === 'Transfer')
      const otherData = sortedData.filter((data) => {
        return !(data.amountPaid < 0 && data.operationType === 'Transfer')
      })
      // const newDataArray = [...sortTransfers, ...otherData].sort((a, b) => new Date(b.date) - new Date(a.date))

      return otherData.map((row, index) => {
        return (
          <RowTableAllOperations row={row} key={index.toString(36)} history={history} projectFilter={projectFilter} />
        )
      })
    }
  }, [sortedData, filterValues, data, projectFilter])

  return (
    <Box
      component="table"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.primary.main,
        border: 'none',
        shadow: 'none'
      }}
    >
      {data.length ? (
        <>
          <Box component="thead">
            <RowHead tableKind={tableKind} headers={headers} setSorting={handleSort} history={history} />
          </Box>
          <Box component="tbody" sx={{padding: '0', display: 'flex', flexDirection: 'column', width: '100%'}}>
            {renderTableRows()}
          </Box>
        </>
      ) : (
        <Box
          sx={{
            padding: '8px 12px',
            fontSize: '16px',
            fontWeight: 500,
            background: '#F8F8F8',
            borderRadius: '8px',
            textAlign: 'left'
          }}
        >
          Результаты не найдены. Измените параметры фильтрации.
        </Box>
      )}
    </Box>
  )
}

export default ReportTable
