import React, {useCallback, useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'

import {Table, TableBody, TableHead, TableRow, TableSortLabel} from '@mui/material'

import {HeaderCell, TableContainerWrapper} from './TableComponentStyle'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import Row from '../Row/Row'
import {PATHS} from '../../../../../../routes/paths'
import {
  approveMembershipEvent,
  excludeMemberEvent,
  rejectMembershipEvent
} from '../../../../../../store/committeeSettingsStore/committeeSettingsStoreSliceEvents'
import {deleteChildRecordEvent} from '../../../../../../store/creatingRkStore/creatingRkStoreSliceEvents'

const TableComponent = ({data, isChief, handleModal}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const openMyProfile = useCallback(() => {
    navigate(PATHS.PROFILE)
  }, [])

  const onRemoveFromCommittee = useCallback((userUuid) => {
    dispatch(excludeMemberEvent.action(userUuid))
  }, [])

  const handleApproveMembership = useCallback((userUuid) => {
    dispatch(approveMembershipEvent.action(userUuid))
  }, [])

  const handleRejectMembership = useCallback((userUuid) => {
    dispatch(rejectMembershipEvent.action(userUuid))
  }, [])

  const handleDeleteRecord = useCallback((ids) => {
    dispatch(deleteChildRecordEvent.action(ids))
  }, [])

  const handleEditRecord = useCallback(
    (userUuid) => {
      navigate(`${location.pathname}/member-record/${userUuid}`)
    },
    [navigate]
  )

  const rows = useMemo(() => {
    return data.map((row) => (
      <Row
        key={row.userUuid}
        row={row}
        openMyProfile={openMyProfile}
        handleRemoveFromCommittee={onRemoveFromCommittee}
        handleApproveMembership={handleApproveMembership}
        handleRejectMembership={handleRejectMembership}
        handleDeleteRecord={handleDeleteRecord}
        handleEditRecord={handleEditRecord}
        isCurrentUserChief={isChief}
        handleModal={handleModal}
      />
    ))
  }, [
    data,
    onRemoveFromCommittee,
    openMyProfile,
    handleApproveMembership,
    handleRejectMembership,
    handleDeleteRecord,
    handleEditRecord,
    isChief
  ])

  return (
    <TableContainerWrapper component={'div'}>
      <Table sx={{minWidth: mobile ? 0 : 902}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <HeaderCell>Участник</HeaderCell>
            <HeaderCell sx={{display: mobile && 'none'}}>дети</HeaderCell>
            <HeaderCell sx={{display: mobile && 'none'}}>Статус</HeaderCell>
            <HeaderCell sx={{display: (mobile || !isChief) && 'none'}}>Телефон</HeaderCell>
            <HeaderCell />
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainerWrapper>
  )
}

export default TableComponent
