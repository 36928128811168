import {styled} from '@mui/material/styles'
import {Typography} from '@mui/material'
import {ArrowForward} from '@mui/icons-material'

export const Wrapper = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '24px 0 24px 0',
  '.proj': {
    border: 0
  },
  button: {
    '&:hover': {
      border: 0
    }
  },
  [theme.breakpoints.up('md')]: {
    margin: '24px 0 24px 0'
  },
  [theme.breakpoints.between('md', 1224)]: {
    // padding: '0 19px'
  },
  [theme.breakpoints.up(560)]: {
    '& .mobile-add-button': {
      display: 'none'
    }
  },
  [theme.breakpoints.down(560)]: {
    '& .desktop-add-button': {
      display: 'none'
    },
    '& .mobile-add-button': {
      display: 'inline-flex',
      border: `1px solid rgba(57, 33, 94, 0.12)`,
      borderRadius: '6px',

      svg: {
        width: '14px',
        height: '14px'
      }
    }
  }
}))

export const ButtonsWrapper = styled('div')(() => ({
  width: 54,
  marginLeft: 19
}))

export const Text = styled(Typography)(({theme}) => ({
  color: theme.palette.primary.main,
  fontSize: '18px',
  lineHeight: '26px',
  fontWeight: 700,
  // marginBottom: 32,
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
    lineHeight: '32px'
  }
}))

export const Arrow = styled(ArrowForward)(({theme}) => ({
  marginLeft: '8px',
  [theme.breakpoints.up('md')]: {
    marginLeft: '8px'
  }
}))
