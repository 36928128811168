import {styled} from '@mui/material/styles'

export const Wrapper = styled('div')(({theme}) => ({
  // marginBottom: '77px'
}))

export const WrapperChild = styled('div')(() => ({
  width: '100%',
  height: '100%'
}))

export const WrapperButton = styled('div')(({theme}) => ({
  padding: '0 19px',
  [theme.breakpoints.up('md')]: {
    padding: '0 38px'
  }
}))

export const InfoBlock = styled('div')(({theme}) => ({
  display: 'flex',
  width: '100%',
  gap: '32px',
  '@media(max-width: 900px)': {
    flexDirection: 'column'
  }
}))

export const WrapperUserAgreement = styled('div')(({theme}) => ({
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  background: '#F5F5FF',
  height: 'min-content',
  // boxShadow: '0px 12px 12px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  border: '1px solid #EDEDFF',
  boxSizing: 'border-box',
  width: '515px',
  borderRadius: 12,
  marginTop: 32,
  button: {
    marginTop: '24px !important',
    fontSize: 16,
    width: '100% !important'
  },
  [theme.breakpoints.up('md')]: {
    padding: '24px'
  },
  '@media(max-width: 1100px)': {
    // maxWidth: '100%',
    width: '100%'
  }
}))

export const RkDescription = styled('div')(({theme}) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.25px',
  marginTop: '8px',
  color: theme.palette.primary.main,
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
    lineHeight: '24px'
  }
}))
