import {call, put, takeLatest, select, all} from 'redux-saga/effects'

import * as creatingRkStoreSliceEvents from '../../store/creatingRkStore/creatingRkStoreSliceEvents'
import * as authyorizationEvents from '../../store/initStore/initStoreSliceEvents'
import {withErrorLogging} from '../../utils/errorSaga'
import {
  createRkRequest,
  getMyRodcomsRequest,
  joiningToRk,
  getParentCommitteesRequest,
  joinChildrenToRkRequest,
  refChildReq,
  deleteChildRecordReq,
  updateChildRecordReq,
  forgettingRk
} from '../../services/creatingRk'
import {EVENT_STATUSES} from '../../pages/LoginPage/constants'
import {getTransformedErrorsFromBack} from '../../utils/getTransformedErrorsFromBack'
import {creatingRkSelectors} from '../../store/creatingRkStore/creatingRkStore'
import {committeeSettingsSelectors} from '../../store/committeeSettingsStore/committeeSettingsStore'
import * as committeeSettingsStoreSliceEvents from '../../store/committeeSettingsStore/committeeSettingsStoreSliceEvents'
import {sendCurrentUserData} from '../../store/profileStore/profileStoreSliceEvents'
import {joinChildrenToRk} from '../../store/creatingRkStore/creatingRkStoreSliceEvents'

export function* createRk(action) {
  try {
    const {data} = action.payload
    const {newRKData, userData, checkedKids} = data
    const response = yield withErrorLogging(call(createRkRequest, newRKData))

    if (response.data.event === EVENT_STATUSES.RK_CREATED) {
      yield put(authyorizationEvents.setRequestStatus.action(response.data.event))
      const uuid = response.data.payload
      yield put(creatingRkStoreSliceEvents.setCreatedRkUuid.action(uuid))

      yield put(sendCurrentUserData.action(userData))
      yield put(joinChildrenToRk.action(checkedKids))
    }
  } catch (e) {
    yield put(authyorizationEvents.setRequestStatus.action(e.response.data.event))
    const errors = getTransformedErrorsFromBack(e)
    if (errors) {
      yield put(creatingRkStoreSliceEvents.setErrors.action(errors))
    }
    // console.error(e)
  } finally {
    yield put(creatingRkStoreSliceEvents.setIsLoading.action(false))
  }
}

export function* joinChildrenToRkSaga(action) {
  try {
    const {checkedKids, uuid: uuidFromAction} = action.payload
    const uuid = yield select(creatingRkSelectors.uuid.selector)
    const currentUuid = uuidFromAction || uuid
    const response = yield withErrorLogging(call(joinChildrenToRkRequest, currentUuid, {children: checkedKids}))
    if (response.data.event === EVENT_STATUSES.RK_JOIN_CHILDREN) {
      const status = uuidFromAction ? EVENT_STATUSES.RK_JOIN_CHILDREN : `${EVENT_STATUSES.RK_JOIN_CHILDREN}${uuid}`
      yield put(authyorizationEvents.setRequestStatus.action(status))
    }
  } catch (e) {
    // console.error(e)
  }
  yield put(creatingRkStoreSliceEvents.setIsLoading.action(false))
}

export function* getMyRodkoms() {
  try {
    const response = yield withErrorLogging(call(getMyRodcomsRequest))
    yield put(creatingRkStoreSliceEvents.getMyRksSuccess.action(response.data.payload))
  } catch (e) {
    // console.error(e)
  }
  yield put(creatingRkStoreSliceEvents.setIsLoading.action(false))
}

export function* getParentCommittees(action) {
  try {
    const {data} = action.payload
    const response = yield withErrorLogging(call(getParentCommitteesRequest, data))
    yield put(creatingRkStoreSliceEvents.setParentCommittees.action(response.data.payload))
  } catch (e) {
    // console.error(e)
  }
  yield put(creatingRkStoreSliceEvents.setIsLoading.action(false))
}

export function* joinToRk(action) {
  try {
    const {data} = action.payload
    const response = yield withErrorLogging(call(joiningToRk, data.uuid, data.role, {children: data.checkedKids}))
    yield put(authyorizationEvents.setRequestStatus.action(response.data.event))
  } catch (e) {
    // console.error(e)
  }
  yield put(creatingRkStoreSliceEvents.setIsLoading.action(false))
}

export function* forgetRk(action) {
  try {
    const {data} = action.payload
    const response = yield withErrorLogging(call(forgettingRk, data.uuid))
    yield put(creatingRkStoreSliceEvents.getMyRks.action())
  } catch (e) {
    // console.error(e)
  }
  yield put(creatingRkStoreSliceEvents.setIsLoading.action(false))
}

export function* refChild(action) {
  try {
    const {data} = action.payload
    const uuid = yield select(committeeSettingsSelectors.uuid.selector)
    const reqs = data.fullNameChild.map((_, i) => {
      const reqData = {
        ...data,
        fullNameChild: data.fullNameChild[i]
      }
      if (data.phoneNumber) {
        reqData.phoneNumber = data.phoneNumber?.replace('+', '') || ''
      }
      return withErrorLogging(call(refChildReq, uuid, reqData))
    })
    const testRes = yield all(reqs)
    if (testRes?.[0]?.data?.event === EVENT_STATUSES.RK_CHILD_CREATED) {
      yield put(authyorizationEvents.setRequestStatus.action(EVENT_STATUSES.RK_CHILD_CREATED))
      yield put(committeeSettingsStoreSliceEvents.getRkProfileEvent.action(uuid))
    }
  } catch (e) {
    yield put(creatingRkStoreSliceEvents.setIsLoading.action(false))
    // console.error(e)
  }
}

export function* deleteChildRecord(action) {
  try {
    const {ids, data} = action.payload
    const uuid = yield select(committeeSettingsSelectors.uuid.selector)
    const reqs = ids.map((_, i) => withErrorLogging(call(deleteChildRecordReq, ids[i])))
    const testRes = yield all(reqs)
    if (testRes?.[0]?.data?.event === EVENT_STATUSES.RK_CHILD_DELETED) {
      if (data) {
        yield put(creatingRkStoreSliceEvents.refChildEvent.action(data))
      } else {
        yield put(committeeSettingsStoreSliceEvents.getRkProfileEvent.action(uuid))
      }
    }
  } catch (e) {
    yield put(creatingRkStoreSliceEvents.setIsLoading.action(false))
    // console.error(e)
  }
}

export function* updateChildRecord(action) {
  try {
    const {data} = action.payload
    const uuid = yield select(committeeSettingsSelectors.uuid.selector)
    const reqs = data.fullNameChild.map((_, i) => {
      const reqData = {
        ...data,
        uuid: data.ids[i],
        fullNameChild: data.fullNameChild[i]
      }
      if (data.phoneNumber) {
        reqData.phoneNumber = data.phoneNumber?.replace('+', '') || ''
      }
      return withErrorLogging(call(updateChildRecordReq, reqData))
    })
    const testRes = yield all(reqs)
    if (testRes?.[0]?.data?.event === EVENT_STATUSES.RK_CHILD_UPDATED) {
      yield put(authyorizationEvents.setRequestStatus.action(EVENT_STATUSES.RK_CHILD_UPDATED))
      yield put(committeeSettingsStoreSliceEvents.getRkProfileEvent.action(uuid))
    }
  } catch (e) {
    yield put(creatingRkStoreSliceEvents.setIsLoading.action(false))
    // console.error(e)
  }
}

export function* creatingRkSaga() {
  yield takeLatest(creatingRkStoreSliceEvents.searchParentCommittees.type, getParentCommittees)
  yield takeLatest(creatingRkStoreSliceEvents.createNewRk.type, createRk)
  yield takeLatest(creatingRkStoreSliceEvents.joinChildrenToRk.type, joinChildrenToRkSaga)
  yield takeLatest(creatingRkStoreSliceEvents.joiningRK.type, joinToRk)
  yield takeLatest(creatingRkStoreSliceEvents.refChildEvent.type, refChild)
  yield takeLatest(creatingRkStoreSliceEvents.updateChildRecordEvent.type, updateChildRecord)
  yield takeLatest(creatingRkStoreSliceEvents.deleteChildRecordEvent.type, deleteChildRecord)
  yield takeLatest(creatingRkStoreSliceEvents.getMyRks.type, getMyRodkoms)
  yield takeLatest(creatingRkStoreSliceEvents.forgetRK.type, forgetRk)
}
