import React, {useMemo, useState} from 'react'

import {Avatar, Box, Dialog, Typography} from '@mui/material'

import {Member, Text} from './members/MembersStyle'
import {RK_ROLE} from '../../../../JoiningRodcom/constants'
import {avatarURL, imageURL} from '../../../../../utils/imageURL'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import {CrossIcon} from '../../../../../icons'

const MemberItem = ({
  member,
  withoutSubText,
  withEmail,
  isChild,
  isMembers,
  normalWidth = true,
  isCurrentUserChief,
  isMembersPage
}) => {
  const isEducator = useMemo(() => member?.membershipRoles?.find((role) => role.name === RK_ROLE.EDUCATOR), [member])
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const [memberModal, setMemberModal] = useState(false)

  const subText = useMemo(() => {
    if (withoutSubText) {
      return ''
    }
    if (withEmail) {
      return member?.email || ''
    }
    return isEducator
      ? 'Педагог'
      : member?.children?.map((child) => child?.firstName ?? child?.fullName?.firstName)?.join(', ')
  }, [withEmail, isEducator, member, withoutSubText])

  const avatarStyles = useMemo(
    () => (normalWidth ? {width: '51px', height: '51px'} : {width: '32px', height: '32px'}),
    [normalWidth]
  )

  const textStyles = useMemo(
    () =>
      !normalWidth
        ? {whiteSpace: 'break-spaces', fontSize: '14px', fontWeight: 700}
        : {fontSize: '14px', fontWeight: 700, whiteSpace: 'break-spaces'},
    [normalWidth]
  )

  const memberStyles = useMemo(() => (!normalWidth ? {paddingLeft: '6px', minHeight: 'auto'} : {}), [normalWidth])

  const memberName = useMemo(() => {
    if (member?.fullName) {
      return `${member?.fullName?.surname} ${member?.fullName?.firstName}`
    } else {
      return <>&mdash;</>
    }
  }, [member?.fullName])

  const pattern = new RegExp(`(^\\d{${11 % 10}})(\\d{3})(\\d{3})(\\d{2})(\\d{2}$)`)

  const chipLabel = useMemo(() => {
    switch (member?.membershipRoles?.[0]?.name) {
      case 'Chief':
        return 'Глава'
      case 'Member':
        return 'Участник'
      case 'Educator':
        return 'Педагог'
      // case record && isCurrentUserChief:
      //   return 'Справочная запись'
      // case record && !isCurrentUserChief:
      //   return 'Пока не участвует'
      default:
        return ''
    }
  }, [member])

  return (
    <>
      <Dialog
        open={memberModal}
        onClose={() => setMemberModal(false)}
        fullScreen={mobile}
        fullWidth
        maxWidth="xs"
        sx={{margin: '12px'}}
      >
        <Box sx={{padding: '24px', display: 'flex', flexDirection: 'column', gap: '22px'}}>
          <IconButton
            sx={{
              position: 'absolute',
              top: '8px',
              left: 'auto',
              right: '12px'
            }}
            onClick={() => setMemberModal(false)}
          >
            <CrossIcon />
          </IconButton>
          <Box sx={{display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center'}}>
            <Avatar alt={memberName} src={imageURL(member?.photo)} sx={{width: '64px', height: '64px'}} />
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
              <Typography
                variant="caption"
                sx={{fontSize: '24px', fontWeight: 400, lineHeight: '36px', color: '#39215E'}}
              >
                {memberName}
              </Typography>
              <Typography
                variant="caption"
                sx={{fontSize: '16px', fontWeight: 400, lineHeight: '24px', color: '#39215E'}}
              >
                {chipLabel}
              </Typography>
            </Box>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
            {member?.verifiedPhone && isCurrentUserChief ? (
              <Typography
                variant="caption"
                sx={{fontSize: '16px', fontWeight: 400, lineHeight: '24px', color: '#39215E'}}
              >
                {member?.verifiedPhone.replace(pattern, '+7 ($2) $3-$4-$5')}
              </Typography>
            ) : null}
            {isCurrentUserChief ? (
              <Typography
                variant="caption"
                sx={{fontSize: '16px', fontWeight: 400, lineHeight: '24px', color: '#39215E'}}
              >
                {subText}
              </Typography>
            ) : null}
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '12px', alignItems: 'left'}}>
              {member?.children?.map((child) => (
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <Avatar
                    alt={`${child?.fullName?.firstName}`}
                    src={imageURL(child?.photo)}
                    sx={{width: '40px', height: '40px', marginRight: '12px'}}
                  />
                  <Typography
                    variant="caption"
                    sx={{fontSize: '16px', fontWeight: 400, lineHeight: '24px', color: '#39215E'}}
                  >
                    {child?.firstName
                      ? `${child?.surname} ${child?.firstName}`
                      : ` ${child?.fullName?.surname} ${child?.fullName?.firstName}`}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Dialog>

      <Member
        sx={(memberStyles, {cursor: mobile && 'pointer'})}
        className="progMember"
        onClick={() => mobile && setMemberModal(true)}
      >
        <Avatar sx={avatarStyles} alt={memberName} src={imageURL(member?.photo)} className="avatar" />
        <Box
          sx={{
            display: 'flex',
            overflow: 'hidden',
            flexDirection: isEducator && !isMembers ? 'column-reverse' : 'column'
            // gap: '3px'
          }}
        >
          <Text sx={textStyles} variant={isMembers ? 'h4' : 'h3'}>
            {`${memberName}`}
          </Text>
          <Text variant="caption" sx={{fontSize: '12px', fontWeight: 500, opacity: 0.6, overflow: 'hidden'}}>
            {chipLabel}
          </Text>
          {mobile || !isMembersPage ? (
            <Text variant="caption" sx={{fontSize: '12px', fontWeight: 500, opacity: 0.6}}>
              {member?.children
                ?.map((child) =>
                  child?.firstName
                    ? `${child?.surname} ${child?.firstName}`
                    : `${child?.fullName?.surname} ${child?.fullName?.firstName}`
                )
                ?.join(', ')}
            </Text>
          ) : null}

          {isCurrentUserChief && !mobile ? (
            <Text variant="caption" sx={{fontSize: '12px', fontWeight: 500, opacity: 0.6}}>
              {subText}
            </Text>
          ) : null}
        </Box>
      </Member>
    </>
  )
}

export default MemberItem
