import React from 'react'
import {Wrapper, Title} from './YourRodcomDataFormStyle'
import TextInput from '../Inputs/TextInput/TextInput'
import {PhoneInput} from '../Inputs/PhoneInput/PhoneInput'
import {checkOnlyLettersAndHyphen} from '../../utils/validations'

const YourRodcomDataForm = ({errors, fieldValues, onChangeValue, clearPhoneError}) => {
  return (
    <Wrapper sx={{background: '#ffffff', boxSizing: 'border-box'}}>
      <Title>Ваши данные</Title>
      <TextInput
        validator={checkOnlyLettersAndHyphen}
        errorMsg={errors.lastName}
        label="Фамилия"
        id="lastName"
        value={fieldValues.lastName}
        onChangeValue={(value) => onChangeValue(value, 'lastName')}
      />
      <TextInput
        validator={checkOnlyLettersAndHyphen}
        errorMsg={errors.firstName}
        label="Имя"
        id="firstName"
        value={fieldValues.firstName}
        onChangeValue={(value) => onChangeValue(value, 'firstName')}
      />
      <TextInput
        withNotRequiredText
        validator={checkOnlyLettersAndHyphen}
        errorMsg={errors.middleName}
        label="Отчество"
        id="middleName"
        value={fieldValues.middleName}
        onChangeValue={(value) => onChangeValue(value, 'middleName')}
      />
      <TextInput
        secondLabel={'Для получения важных уведомлений'}
        errorMsg={errors.email}
        label="Email"
        id="email"
        value={fieldValues.email}
        onChangeValue={(value) => onChangeValue(value, 'email')}
      />
      <PhoneInput
        className={'joining-phone'}
        secondLabel={'Используется для вывода средств, если вы — глава родкома'}
        phone={fieldValues.phone}
        phoneHandler={(value) => onChangeValue(value, 'phone')}
        isError={!!errors.phone}
        clearPhoneError={clearPhoneError}
        disabled
      />
    </Wrapper>
  )
}

export default YourRodcomDataForm
