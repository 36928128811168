import {groupBy, valuesIn, prop, filter, propEq, sum, assoc, pipe, map} from 'ramda'

const sumPaid = (a) => sum(a.map((e) => e.amountPaid))
const sumWrittenOff = (a) => sum(a.map((e) => e.writtenOff))

const getParentName = (member) => {
  if (!member) return 'Аноним'
  return member.label
  // return `${member.fullName.surname} ${member.fullName.firstName} ${member.fullName.patronymic}`
}
const getMemberName = (id, memberOptions) => {
  return getParentName(memberOptions.find((m) => m.id === id))
}

const getChildrenName = (id, list) => {
  return list?.find((m) => m.id === id)
}
const getProjectTitle = (project) => {
  return project?.title ?? ''
}

const getProjectBalance = (projectUuid, endBalance) => {
  return endBalance.find((e) => e.projectUuid === projectUuid)?.available ?? 0
}

const getProjectName = (id, projectsList) => getProjectTitle(projectsList?.find((m) => m.uuid === id))

const groupByName = groupBy(prop('Name'))

const mapInserters = (members, projects) => ({
  FullName: (a) => a.map((e) => ({...e, Name: getMemberName(e.userUuid, members)})),
  Project: (a) => a.map((e) => ({...e, Name: getProjectName(e.projectUuid, projects)}))
})

function inputIsNotReady(operations, projectsList, memberOptions) {
  return !operations || !operations.operations || !projectsList?.length || !memberOptions?.length
}

function getOperationDescription(operation, parentName, projectName, relatedProjName, description, childrenName) {
  return operation.operationType === 'Transfer' && operation.amountPaid > 0
    ? `Перевод с "${relatedProjName}" в "${projectName}"`
    : operation.operationType === 'Transfer' && operation.amountPaid < 0
    ? `Перевод с "${projectName}" в "${relatedProjName}"`
    : operation.operationType === 'GiveOutSuccess'
    ? `Вывод средств под отчет по проекту "${projectName}"`
    : operation.operationType === 'ExpertReportConfirmed'
    ? `Покупка по проекту "${projectName}" на "${description}"`
    : `${parentName === 'Аноним' ? 'Донат' : 'Взнос'} по начислению за ребенка 
      ${
        childrenName
          ? `${childrenName?.lastName} ${childrenName?.firstName} `
          : operation?.childName
          ? operation?.childName
          : ''
      }, по проекту
      "${projectName}". Плательщик ${parentName} ${operation.authCode ? operation.authCode : ''} 
      ${operation.cardNumbers ? operation.cardNumbers : ''}`
}
export const All = (operations, projectsList, memberOptions, children) => {
  if (inputIsNotReady(operations, projectsList, memberOptions)) return null

  const f = (e) => {
    const parentName = getMemberName(e.userUuid, memberOptions)
    const childrenName = getChildrenName(e.childUuid, children)
    const description = e.paymentDescription
    const projectName = getProjectName(e.projectUuid, projectsList)
    const x = operations.operations?.filter((oper) => oper.uuid === e.relatedUuid)
    const relatedProjName = getProjectName(e.relatedUuid, projectsList)
    return {
      ...e,
      parentName,
      projectName,
      description,
      childrenName,
      relatedProjName,
      paymentDescription: getOperationDescription(
        e,
        parentName,
        projectName,
        relatedProjName,
        description,
        childrenName
      )
    }
  }
  return map(f, operations.operations)
}

function fillSecondRowAndCounters(operation, m, groupIdx) {
  if (operation.operationType === 'ExpertReportConfirmed') {
    m.writtenOff += operation.amountPaid

    m.details[groupIdx].writtenOff += operation.amountPaid
    m.details[groupIdx].payments.push(operation)
  } else {
    m.amountPaid += operation.amountPaid
    m.details[groupIdx].amountPaid += operation.amountPaid
    m.details[groupIdx].payments.push(operation)
  }
}

export const Projects = (operations, projectsList, memberOptions) => {
  if (inputIsNotReady(operations, projectsList, memberOptions)) return null
  const insert = mapInserters(memberOptions, projectsList)
  const filterByProject = (v) => filter(propEq('projectUuid', v))

  const group = (o) => {
    return Object.entries(groupByName(o)).map((e) => ({
      Name: e[0],
      payments: e[1],
      amountPaid: sumPaid(e[1]),
      expenses: sumWrittenOff(e[1])
    }))
  }

  const x = projectsList.filter((proj) => operations.operations.some((oper) => proj.uuid.includes(oper.projectUuid)))

  return x.map((project) => {
    const available = getProjectBalance(project.uuid, operations.endBalances)
    const projectOperations = insert.FullName(filterByProject(project.uuid)(operations.operations))

    const m = {
      projectName: getProjectTitle(project),
      projectUuid: project.uuid,
      amountPaid: 0,
      writtenOff: 0,
      details: [],
      endMoney: available
      //details: group(userOperations)
    }

    for (const operation of projectOperations) {
      let groupIdx = m.details.findIndex((d) => d.Name === operation.Name)
      if (groupIdx < 0) {
        const project = memberOptions.find((m) => m.uuid === operation.userUuid)
        m.details.push({amountPaid: 0, writtenOff: 0, payments: [], Name: operation.Name})
        groupIdx = m.details.length - 1
      }

      fillSecondRowAndCounters(operation, m, groupIdx)
    }
    m.startMoney = m.endMoney - m.amountPaid + m.writtenOff
    return m
  })
}

export const Members = (operations, projectsList, memberOptions) => {
  if (inputIsNotReady(operations, projectsList, memberOptions)) return null

  const insert = mapInserters(memberOptions, projectsList)

  const group = (o) => {
    return Object.entries(groupByName(o)).map((e) => ({
      Name: e[0],
      payments: e[1],
      amountPaid: sumPaid(e[1].filter((x) => x.operationType !== 'ExpertReportConfirmed')),
      writtenOff: sumPaid(
        e[1]
          .filter((x) => x.operationType === 'ExpertReportConfirmed')
          .map((op) => ({
            ...op,
            writtenOff: op.amountPaid,
            amountPaid: 0
          }))
      )
    }))
  }

  const x = memberOptions.filter((proj) => operations.operations.some((oper) => proj.id.includes(oper.userUuid)))

  return x.map((member) => {
    const filterByUser = filter(propEq('userUuid', member.id))

    const userOperations = insert.Project(filterByUser(operations.operations))

    const m = {
      parentName: getParentName(member),
      amountPaid: 0,
      writtenOff: 0,
      details: []
      //details: group(userOperations)
    }

    for (const operation of userOperations) {
      let groupIdx = m.details.findIndex((d) => d.Name === operation.Name)
      if (groupIdx < 0) {
        const project = projectsList.find((m) => m.uuid === operation.projectUuid)
        m.details.push({amountPaid: 0, writtenOff: 0, payments: [], Name: operation.Name, endMoney: project?.available})
        groupIdx = m.details.length - 1
      }
      fillSecondRowAndCounters(operation, m, groupIdx)
    }

    m.endMoney = m.amountPaid - m.writtenOff
    return m
  })
}
