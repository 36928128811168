import httpClient from './network'
import {restApiMapper} from '../utils/restApiMapper'
import {childMapper, getChildrenMapper} from './mappers/childMapper'

export const addNewChild = (child) => {
  const dataToRequest = childMapper.toServer(child)
  return restApiMapper.toClient(httpClient.post('/family/child', dataToRequest), childMapper.toClient)
}

export const updateChild = (child) => {
  const dataToRequest = childMapper.toServer(child)
  return restApiMapper.toClient(httpClient.put('/family/child', dataToRequest), childMapper.toClient)
}

export const deleteChildReq = (uuid) => {
  return httpClient.delete(`/family/child/${uuid}`)
}

export const getChildren = () => {
  return restApiMapper.toClient(httpClient.get('/family/children'), getChildrenMapper.toClient)
}

export const changeChildAvatar = (uuid, avatar) => {
  const data = {
    name: `${uuid}.jpg`,
    contentType: 'image/jpeg',
    body: avatar.replace(/^data:image\/[a-z]+;base64,/, '')
  }
  return httpClient.post(`/family/child/${uuid}/avatar`, data)
}

export const deleteChildAvatar = (uuid) => {
  return httpClient.delete(`/family/child/${uuid}/avatar`)
}
