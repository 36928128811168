import React, {useState, useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import {Box} from '@mui/material'
import Typography from '@mui/material/Typography'

import SmsForm from '../../../LoginPage/components/SmsForm/SmsForm'
import {
  restoreAccess,
  setRequestStatus,
  checkAuthSmsCode,
  saveNewPassword
} from '../../../../store/initStore/initStoreSliceEvents'
import {useDispatch, useSelector} from 'react-redux'
import {PhoneInput} from '../../../../components/Inputs/PhoneInput/PhoneInput'
import {PasswordInput} from '../../../../components/Inputs/PasswordInput/PasswordInput'
import {checkIfPhoneValid, getPasswordError} from '../../../../utils/validations'
import {PATHS} from '../../../../routes/paths'
import {Wrapper} from './RestoringAccessFormStyle'
import {initAppSelectors} from '../../../../store/initStore/initStore'
import {EVENT_STATUSES, TIME} from '../../../LoginPage/constants'
import {StyledLoadingButton} from '../../../../components/StyledLoadingButton/StyledLoadingButton'
import ButtonBack from '../../../../components/ButtonBack/ButtonBack'

const FORM_VARIANT = {
  SMS: 'sms',
  PHONE: 'phone',
  PASS: 'pass'
}

const RestoringAccessForm = () => {
  const loading = useSelector(initAppSelectors.loading.selector)
  const requestStatus = useSelector(initAppSelectors.requestStatus.selector)
  const navigate = useNavigate()

  const [phone, setPhone] = useState('+7')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [verificationCode, setVerificationCode] = useState(['', '', '', ''])
  const [activeForm, setActiveForm] = useState(FORM_VARIANT.PHONE)
  const [isPhoneError, setIsPhoneError] = useState(false)
  const [passwordError, setPasswordError] = useState('')
  const [repeatPasswordError, setRepeatPasswordError] = useState('')
  const [seconds, setSeconds] = useState(TIME)

  const dispatch = useDispatch()

  useEffect(() => {
    if (requestStatus === EVENT_STATUSES.CHANGING_PASSWORD_SEND_SMS) {
      setActiveForm(FORM_VARIANT.SMS)
      dispatch(setRequestStatus.action(''))
    }
    if (requestStatus === EVENT_STATUSES.CHANGE_PASSWORD) {
      navigate(PATHS.LOGIN)
      dispatch(setRequestStatus.action(''))
    }
    if (requestStatus === EVENT_STATUSES.CODE_CHECKED) {
      setActiveForm(FORM_VARIANT.PASS)
      dispatch(setRequestStatus.action(''))
    }
  }, [requestStatus])

  const sendCode = () => {
    const changeLogin = phone.replace(/[^+\d]/g, '')
    dispatch(restoreAccess.action(changeLogin))
  }

  const submitPhone = () => {
    const changeLogin = phone.replace(/[^+\d]/g, '')
    const isPhoneValid = checkIfPhoneValid(changeLogin)
    if (!isPhoneValid) {
      setIsPhoneError(true)
      return
    }
    sendCode()
  }

  const submitPass = () => {
    const changeLogin = phone.replace(/[^+\d]/g, '')
    const passwordError = getPasswordError(password)
    if (passwordError) {
      setPasswordError(passwordError)
      return
    }
    if (password !== repeatPassword) {
      setRepeatPasswordError('Пароли не совпадают')
      return
    }
    dispatch(saveNewPassword.action(changeLogin, password, verificationCode))
  }

  const onSubmitForm = (event) => {
    event.preventDefault()
    if (activeForm === FORM_VARIANT.PHONE) {
      submitPhone()
    } else {
      submitPass()
    }
  }

  const onSmsNumberSubmit = (event) => {
    event.preventDefault()
    const changeLogin = phone.replace(/[^+\d]/g, '')
    //setActiveForm(FORM_VARIANT.PASS)

    dispatch(checkAuthSmsCode.action(changeLogin, verificationCode))
  }

  const onBackClick = () => {
    if (activeForm === FORM_VARIANT.SMS) {
      setActiveForm(FORM_VARIANT.PHONE)
    }
    if (activeForm === FORM_VARIANT.PASS) {
      setActiveForm(FORM_VARIANT.SMS)
    }
  }

  const formBtnText = activeForm === FORM_VARIANT.PHONE ? 'Отправить SMS-код' : 'Сохранить и войти'

  return (
    <Wrapper>
      <Typography sx={{marginBottom: '32px'}} className="header">
        Восстановление доступа
      </Typography>
      {activeForm !== FORM_VARIANT.SMS && (
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            alignItems: 'center'
          }}
          onSubmit={onSubmitForm}
        >
          {activeForm === FORM_VARIANT.PHONE && (
            <PhoneInput
              autoFocus
              phone={phone}
              phoneHandler={setPhone}
              isError={isPhoneError}
              clearPhoneError={setIsPhoneError}
              className="phoneField phone"
              withIcon
            />
          )}
          {activeForm === FORM_VARIANT.PASS && (
            <>
              <PasswordInput
                autoFocus
                id="access-pass"
                label="Новый пароль"
                password={password}
                passwordError={passwordError}
                passHandler={setPassword}
                clearPassError={setPasswordError}
                placeholder="Не менее 8 символов"
                autocomplete="new-password"
                className="pass"
              />
              <PasswordInput
                id="access-repeat-pass"
                label="Новый пароль ещё раз"
                withShowButton={false}
                password={repeatPassword}
                passwordError={repeatPasswordError}
                passHandler={setRepeatPassword}
                clearPassError={setRepeatPasswordError}
                className="repeatPassField"
                placeholder="Для надёжности"
                autocomplete="new-password"
              />
            </>
          )}
          <StyledLoadingButton
            loading={loading}
            loadingPosition="end"
            className="loadingButton smsBtn"
            variant="contained"
            type="submit"
            endIcon={<ArrowRightAltIcon className="arrowRight" />}
          >
            {formBtnText}
          </StyledLoadingButton>
          {activeForm === FORM_VARIANT.PASS && <ButtonBack onClick={onBackClick} text={'Назад'} className="back" />}
        </Box>
      )}
      {activeForm === FORM_VARIANT.SMS && (
        <SmsForm
          seconds={seconds}
          setSeconds={setSeconds}
          setCode={setVerificationCode}
          code={verificationCode}
          getRegistration={onSmsNumberSubmit}
          btnText="Продолжить"
          loading={loading}
          onSendAgain={sendCode}
        />
      )}
      {activeForm !== FORM_VARIANT.PASS && (
        <Link to={PATHS.LOGIN} className="link">
          Всё нормально, я помню пароль
        </Link>
      )}
    </Wrapper>
  )
}

export default RestoringAccessForm
