import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {styled} from '@mui/material/styles'
import {Navigate, Route, Routes, useParams, useNavigate, useLocation} from 'react-router-dom'
import useScript from 'utils/useScript'

import {PATHS} from './routes/paths'
import LoginPage from './pages/LoginPage/LoginPage'
import Profile from './pages/Profile/Profile'
import {getUserInfoRequest, setError, setSuccessMsg} from './store/initStore/initStoreSliceEvents'
import {initAppSelectors} from './store/initStore/initStore'
import RestoringAccessPage from './pages/RestoringAccessPage/RestoringAccessPage'
import {StyledSnackbar} from './components/StyledSnackbar/StyledSnackbar'
import {PageRoutes} from './components/PageRoutes/PageRoutes'
import EducationalInstitution from './pages/EducationalInstitutionPage/EducationalInstitution'
import AppHeader from './components/AppHeader/AppHeader'
import AppFooter from './components/AppFooter/AppFooter'
import SearchPage from './pages/SearchPage/SearchPage'
import RkDonatePage from './pages/Direct/RkDonatePage'
import {ConfirmProvider} from 'material-ui-confirm'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {notifyError, notifySuccess} from './utils/notify'

const paths = [
  {
    path: PATHS.PROFILE,
    Component: Profile
  }
]

export const PageWrapper = styled('div')(({theme}) => ({
  minHeight: 'calc(100vh - 80px - 160px)',
  background: '#FBFBFC',
  padding: '0 16px',
  margin: '0 auto',
  maxWidth: '1224px',
  width: '100%',
  flex: '1 0 auto',
  boxSizing: 'border-box',
  [theme.breakpoints.down('lg')]: {
    minHeight: 'calc(100vh - 48px - 344px)'
  }
}))

const App = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  // useScript('https://oplata.qiwi.com/popup/v1.js')

  const params = useParams()

  const isAuth = useSelector(initAppSelectors.isAuth.selector)
  const token = localStorage.getItem('token')

  const error = useSelector(initAppSelectors.error.selector)
  const successMsg = useSelector(initAppSelectors.successMsg.selector)

  const onCloseSnackBar = () => {
    dispatch(setError.action(''))
  }

  const onCloseMsgSnackBar = () => {
    dispatch(setSuccessMsg.action(''))
  }

  useEffect(() => {
    if (!isAuth && localStorage.getItem('token')) {
      dispatch(getUserInfoRequest.action())
    }
  }, [token])

  useEffect(() => {
    let prevPathname = location?.state?.from?.pathname + location?.state?.from?.search
    // let prevPathname = undefined
    if (!prevPathname) prevPathname = PATHS.MY_PARENT_COMMITTEES

    const isLoginPage = location?.pathname === PATHS.LOGIN
    const redirectToPrevPage = Boolean(token) && isLoginPage
    if (redirectToPrevPage) {
      navigate(prevPathname, {state: location?.state?.from?.state})
    }
  }, [isAuth, location])

  // const notifyError = () =>
  //   toast.error(`${error}`, {
  //     position: 'top-right',
  //     autoClose: 5000,
  //     hideProgressBar: true,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: 'light'
  //   })
  //
  // const notifySuccess = () =>
  //   toast.success(`${successMsg}`, {
  //     position: 'top-right',
  //     autoClose: 5000,
  //     hideProgressBar: true,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: 'light'
  //   })

  useEffect(() => {
    if (error) {
      notifyError(error)
    } else if (successMsg) {
      notifySuccess(successMsg)
    }
  }, [error, successMsg])

  return (
    <div>
      <ConfirmProvider>
        {!token ? (
          <Routes>
            <Route path={PATHS.LOGIN} element={<LoginPage />} />
            <Route
              path={PATHS.SEARCH_PAGE}
              element={
                <>
                  <AppHeader />

                  <PageWrapper>
                    <SearchPage isLogged={!!token} />
                  </PageWrapper>

                  <AppFooter />
                </>
              }
            />
            <Route path={PATHS.RESTORING_ACCESS} element={<RestoringAccessPage />} />
            <Route
              path={PATHS.EDUCATIONAL_INSTITUTION}
              element={
                <>
                  <AppHeader />
                  <PageWrapper>
                    <EducationalInstitution isLogged={!!token} />
                  </PageWrapper>
                  <AppFooter />
                </>
              }
            />
            <Route path="/rk/:id/donate" element={<RkDonatePage />} />
            {<Route path="*" element={<Navigate replace to={PATHS.LOGIN} state={{from: location}} />} />}
          </Routes>
        ) : (
          <PageRoutes params={params} routesDescription={paths} />
        )}
      </ConfirmProvider>

      {/* <StyledSnackbar msg={error} onClose={onCloseSnackBar} id="notify-error" /> */}
      <ToastContainer style={{minWidth: '300px'}} />
      {/* <StyledSnackbar isError={false} msg={successMsg} onClose={onCloseMsgSnackBar} isSuccess id="notify-success" /> */}
    </div>
  )
}

export default App
