import React from 'react'

export const MessageIcon = ({className, color = '#4F75FF'}) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 23.999V24.019M16 23.999V24.019M32 23.999V24.019M6 39.9992L8.6 32.1992C6.35289 28.8757 5.53999 24.94 6.31244 21.1238C7.08489 17.3076 9.39012 13.8705 12.7995 11.4517C16.2089 9.03293 20.4905 7.79698 24.8482 7.97366C29.2058 8.15035 33.343 9.72765 36.4906 12.4123C39.6381 15.097 41.5817 18.7063 41.9601 22.5692C42.3385 26.432 41.1259 30.2856 38.5477 33.4134C35.9696 36.5412 32.2013 38.7304 27.9436 39.5739C23.6859 40.4174 19.2284 39.8578 15.4 37.9992L6 39.9992Z"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
