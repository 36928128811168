import {styled} from '@mui/material/styles'
import {Typography} from '@mui/material'

export const FormWrapper = styled('div')(({theme}) => ({
  padding: 32,
  background: '#ffffff',
  borderRadius: 12,
  marginTop: 24,
  // width: '700px',
  // boxShadow: '0px 12px 32px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  border: '1px solid #EDEDFF',
  [theme.breakpoints.down(1000)]: {
    width: 'auto'
  }
}))

export const Text = styled(Typography)(({theme}) => ({
  color: theme.palette.primary.main,
  fontSize: '24px',
  lineHeight: '32px',
  paddingBottom: '20px',
  fontWeight: 700
  // paddingLeft: '19px',
  // [theme.breakpoints.up('md')]: {
  //   fontSize: '24px',
  //   lineHeight: '32px'
  // }
}))

export const DocumentWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '13px',
  paddingBottom: '13px',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  // paddingLeft: '19px',
  gap: '6px',
  position: 'relative',
  // [theme.breakpoints.down('md')]: {
  //   '&:not(:last-child)': {
  //     borderBottom: '1px solid',
  //     borderColor: 'rgba(0, 0, 0, 0.12)'
  //   }
  // },
  [theme.breakpoints.up('md')]: {
    padding: '13px'
  },
  '& .downloadIcon': {
    marginTop: '3px'
  },
  '& .downloadButton': {
    fontSize: 16,
    fontWeight: 500,
    color: '#39215E',
    padding: '4px 19px',
    background: 'rgba(57, 33, 94, 0.12)',
    border: '1px solid rgba(255, 255, 255, 1e-05)',
    '&:hover': {
      background: 'rgba(57, 33, 94, 0.24)',
      border: '1px solid rgba(255, 255, 255, 1e-05)'
    },
    '&:focused': {
      border: '1px solid rgba(255, 255, 255, 1e-05)'
    },
    '&:active': {
      boxShadow:
        ' 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
    }
  },
  '& .downloadWrapperMobile': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  [theme.breakpoints.down('md')]: {
    '& .downloadButton': {
      padding: '4px 12px',
      fontSize: 14
    }
  }
}))

export const DocumentText = styled(Typography)(({theme}) => ({
  color: theme.palette.primary.main,
  fontSize: '16px',
  lineHeight: '19px',
  [theme.breakpoints.down('md')]: {
    fontSize: 14
  }
}))

export const DateButtonBlock = styled(Typography)(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '30px',
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  [theme.breakpoints.down(625)]: {
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    gap: 12,
    p: {
      fontSize: 12
    },
    a: {
      fontSize: 12,
      width: '100%'
    },
    '.descr': {
      flexDirection: 'row !important',
      width: '100%',
      justifyContent: 'center'
    }
  },
  '.descr': {
    display: 'flex',
    flexDirection: 'row',
    gap: '30px'
  },
  [theme.breakpoints.down('md')]: {
    '.descr': {
      flexDirection: 'column',
      gap: '12px',
      alignItems: 'center'
    }
  }
}))
