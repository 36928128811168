import {createSelectorsTree} from '../selectorsFactory'
import {generateReducersObject} from '../sliceMapping'
import {allInitAppEvents} from './committeeSettingsStoreSliceEvents'

const initialState = {
  isLoading: false,
  tabsIsLoading: false,
  profileInfo: {},
  uuid: null,
  membersParentCommittee: [],
  membersOptions: [],
  roles: [],
  appMembership: [],
  membersIsLoading: false,
  recordsMembers: [],
  transferMemberApplication: {},
  address: {},
  operations: null
}

/** Reducers */
export const COMMITTEE_SETTINGS_STORE = 'committeeSettingsStore'
export const committeeSettingsStore = generateReducersObject({
  allSliceEvents: allInitAppEvents,
  initialState,
  reducerName: COMMITTEE_SETTINGS_STORE
})

/** Selectors */
export const committeeSettingsSelectors = createSelectorsTree(initialState, COMMITTEE_SETTINGS_STORE)
