import {styled} from '@mui/material/styles'
import {Accordion} from '@mui/material'
import {ChevronDownIcon} from '../../../../icons'

export const QuestionsWrapper = styled('div')(({theme}) => ({
  margin: '24px 0 0 0',
  display: 'flex',
  gap: '20px',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    margin: '24px 0 0 0'
  },

  [theme.breakpoints.up(1224)]: {
    margin: '24px 0 0 0'
  }

  // button: {
  //   fontSize: 16,
  //   fontWeight: 500,
  //   marginTop: 24,
  //   background: 'rgba(57, 33, 94, 0.12)',
  //   border: '1px solid rgba(255, 255, 255, 1e-05)',
  //   '&:hover': {
  //     background: 'rgba(57, 33, 94, 0.24)',
  //     border: '1px solid rgba(255, 255, 255, 1e-05)'
  //   },
  //   '&:focused': {
  //     border: '1px solid rgba(255, 255, 255, 1e-05)'
  //   },
  //   '&:active': {
  //     boxShadow:
  //       ' 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
  //   }
  // }
}))

export const Question = styled(Accordion)(({theme}) => ({
  color: theme.palette.primary.main,
  background: '#F5F5FF',
  borderTop: 'none',
  boxShadow: 'none',
  width: '100%',
  padding: '16px',
  boxSizing: 'border-box',
  borderRadius: '12px',
  '.title': {
    fontSize: 16,
    fontWeight: 700
  },
  '.description': {
    fontSize: 16
  },
  '&:before': {
    height: '0'
  },
  [theme.breakpoints.down('md')]: {
    '.title': {
      fontSize: 14,
      fontWeight: 700
    },
    '.description': {
      fontSize: 14
    }
  }
}))

export const WrapperChevronDownIcon = styled(ChevronDownIcon)(({theme}) => ({
  color: theme.palette.primary.main
}))
