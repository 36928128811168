import React, {useMemo} from 'react'

import {TableCell, TableRow, Box} from '@mui/material'

import StatusChip from '../StatusChip/StatusChip'
import {CHIP_TYPE} from '../../../constants/parentCommitteeConstants'
import MemberItem from '../../../Summary/components/MemberItem'
import {RK_ROLE} from '../../../../../JoiningRodcom/constants'
import {CellText, Hyphen, PhoneText, StyledTableCell} from '../../MembersPageStyle'
import ContextMenu from '../ContextMenu/ContextMenu'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'

const Row = ({
  row,
  openMyProfile,
  handleRemoveFromCommittee,
  handleApproveMembership,
  handleRejectMembership,
  handleDeleteRecord,
  handleEditRecord,
  isCurrentUserChief,
  handleModal
}) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const {fromApplication, record, isMember, isCurrentUser, userUuid, ids} = row

  const isEducator = useMemo(() => row?.membershipRoles?.some((role) => role.name === RK_ROLE.EDUCATOR), [row])
  const isChief = useMemo(() => row?.membershipRoles?.some((role) => role.name === RK_ROLE.CHIEF), [row])
  const isParent = useMemo(() => row?.membershipRoles?.some((role) => role.name === RK_ROLE.PARENT), [row])
  const isEmptyChildren = useMemo(
    () => (!record && !row?.children) || (!record && !row?.children?.length) || isEducator || (record && !row.kids),
    [record, row, isEducator]
  )

  const chipLabel = useMemo(() => {
    switch (true) {
      case isChief:
        return 'Глава'
      case isParent:
        return 'Участник'
      case isEducator:
        return 'Педагог'
      case fromApplication:
        return 'Хочет вступить'
      case record && isCurrentUserChief:
        return 'Справочная запись'
      case record && !isCurrentUserChief:
        return 'Пока не участвует'
      default:
        return ''
    }
  }, [isChief, isParent, isEducator, isCurrentUserChief])

  const children = useMemo(() => {
    if (isEmptyChildren) {
      return <Hyphen />
    }
    if (record) {
      return (
        <CellText sx={{padding: '13px', fontSize: 14, color: '#39215E', fontWeight: 700}} variant="h4">
          {row.kids}
        </CellText>
      )
    }
    return row.children.map((child) => {
      return <MemberItem key={child.uuid} member={child} isChild />
    })
  }, [row, isEmptyChildren, record])

  const chipType = useMemo(() => {
    switch (true) {
      case isMember:
        return ''
      case fromApplication:
        return CHIP_TYPE.APP
      default:
        return CHIP_TYPE.RECORD
    }
  }, [isMember, fromApplication])

  const parentName = record ? row.memberName : `${row.fullName?.surname || ''} ${row.fullName?.firstName || ''}`.trim()
  const memberCell = useMemo(() => {
    if (isMember) {
      return <MemberItem isMembers member={row} withEmail isCurrentUserChief={isCurrentUserChief} isMembersPage />
    }
    return (
      <>
        {parentName ? (
          <Box sx={{display: 'flex', flexDirection: 'column', padding: '13px'}}>
            <CellText sx={{padding: '0', fontSize: 14, color: '#39215E', fontWeight: 700}} variant="h4">
              {`${parentName} ${mobile ? `(${row.kids})` : ''}`}
            </CellText>
            {mobile ? (
              <CellText
                sx={{padding: '0', fontSize: 12, color: '#39215E', fontWeight: 500, opacity: 0.6, marginTop: '5px'}}
                variant="h4"
              >
                Справочная запись
              </CellText>
            ) : null}
          </Box>
        ) : (
          <Hyphen />
        )}
      </>
    )
  }, [isMember, row, parentName, record, mobile])

  const pattern = new RegExp(`(^\\d{${11 % 10}})(\\d{3})(\\d{3})(\\d{2})(\\d{2}$)`)

  return (
    <TableRow key={row.userUuid} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
      <StyledTableCell scope="row">{memberCell}</StyledTableCell>
      <StyledTableCell sx={{display: mobile && 'none'}}>{children}</StyledTableCell>
      <TableCell sx={{display: mobile && 'none'}}>
        <StatusChip label={chipLabel} className={chipType} />
      </TableCell>
      <TableCell sx={{padding: row.verifiedPhone ? '13px' : 0, display: (mobile || !isCurrentUserChief) && 'none'}}>
        {row.verifiedPhone ? (
          <PhoneText variant="h4" sx={{fontSize: 16, color: '#39215E', fontWeight: 500}}>
            {row.verifiedPhone.replace(pattern, '+7 ($2) $3-$4-$5')}
          </PhoneText>
        ) : (
          <Hyphen />
        )}
      </TableCell>
      {isCurrentUserChief && (
        <TableCell sx={{padding: 0}}>
          <ContextMenu
            openMyProfile={openMyProfile}
            fromApplication={fromApplication}
            record={record}
            isMember={isMember}
            isCurrentUser={isCurrentUser}
            handleRemoveFromCommittee={handleRemoveFromCommittee}
            handleApproveMembership={handleApproveMembership}
            handleRejectMembership={handleRejectMembership}
            handleDeleteRecord={handleDeleteRecord}
            handleEditRecord={handleEditRecord}
            handleModal={handleModal}
            userUuid={userUuid}
            userName={parentName}
            ids={ids}
          />
        </TableCell>
      )}
    </TableRow>
  )
}

export default Row
