import httpClient from './network'
import {restApiMapper} from '../utils/restApiMapper'

export const createBill = (items) => {
  return restApiMapper.toClient(httpClient.post(`/bills/cart-link`, {AccrualUuids: items}))
}

export const createProjectBill = (projectUuid) => {
  return restApiMapper.toClient(httpClient.post(`/bills/project-link/${projectUuid}`))
}

export const createDirectLinkDonate = (rkUuid, data) => {
  return restApiMapper.toClient(httpClient.post(`/bills/${rkUuid}/donate`, data))
}

export const checkBill = (uuid) => {
  return restApiMapper.toClient(httpClient.post(`/bills/${uuid}/check`))
}
