import {call, put, select, takeLatest, delay} from 'redux-saga/effects'
import {makeRkMembersEvent, setIsLoading} from '../../store/reportingStore/reportingStoreSliceEvents'
import {withErrorLogging} from '../../utils/errorSaga'
import {makeRkMembersReport, downloadRkMembersReport} from '../../services/reporting'

export function* getRkMembersRequest(action) {
  try {
    yield put(setIsLoading.action(true))
    const {uuid} = action.payload
    const {data} = yield withErrorLogging(call(makeRkMembersReport, uuid))
    const response = yield withErrorLogging(call(downloadRkMembersReport, data))

    const a = document.createElement('a')
    a.href = URL.createObjectURL(response.data)
    a.download = 'filename.pdf'
    // var a = document.createElement('a')
    // a.href = `https://localhost:8081/api/v1/reporting/report/${data}`
    //
    document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click()
  } catch (e) {
    // console.error(e)
  } finally {
    yield put(setIsLoading.action(false))
  }
}

export function* reportingSaga() {
  yield takeLatest(makeRkMembersEvent.type, getRkMembersRequest)
}
