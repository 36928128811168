import React, {useCallback, useEffect, useState, useMemo} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

import Button from '@mui/material/Button'
import {CircularProgress, Box} from '@mui/material'

import {FormWrapper, StyledButton} from '../../../Settings/SettingsPageStyle'
import ButtonBack from '../../../../../../components/ButtonBack/ButtonBack'
import TextInput from '../../../../../../components/Inputs/TextInput/TextInput'
import {PhoneInput} from '../../../../../../components/Inputs/PhoneInput/PhoneInput'
import {CheckIcon} from '../../../../../../icons'
import {
  checkEmail,
  checkIfPhoneValid,
  checkKidsNames,
  checkLettersAndOneSpace
} from '../../../../../../utils/validations'
import {setRequestStatus} from '../../../../../../store/initStore/initStoreSliceEvents'
import {
  deleteChildRecordEvent,
  refChildEvent,
  updateChildRecordEvent
} from '../../../../../../store/creatingRkStore/creatingRkStoreSliceEvents'
import {creatingRkSelectors} from '../../../../../../store/creatingRkStore/creatingRkStore'
import {EVENT_STATUSES} from '../../../../../LoginPage/constants'
import {initAppSelectors} from '../../../../../../store/initStore/initStore'
import {committeeSettingsSelectors} from '../../../../../../store/committeeSettingsStore/committeeSettingsStore'

const MemberRecordPage = ({setOpenModal, memberId}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const params = useParams()
  // const {memberId} = params

  const isLoading = useSelector(creatingRkSelectors.isLoading.selector)
  const requestStatus = useSelector(initAppSelectors.requestStatus.selector)
  const recordsMembers = useSelector(committeeSettingsSelectors.recordsMembers.selector)

  const [memberName, setMemberName] = useState('')
  const [kids, setKids] = useState('')
  const [emailParent, setEmailParent] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('+7')
  const [errors, setErrors] = useState({})

  const member = useMemo(() => {
    return recordsMembers?.find(({userUuid}) => userUuid === memberId) || null
  }, [memberId, recordsMembers])

  const setData = useCallback(() => {
    setErrors({})
    if (!member) {
      setMemberName('')
      setKids('')
      setEmailParent('')
      setPhoneNumber('')
    } else {
      const {switchedMemberName, switchedKids, verifiedPhone, email} = member
      setMemberName(switchedMemberName || '')
      setKids(switchedKids || '')
      setEmailParent(email || '')
      const phone = verifiedPhone.startsWith('+') ? verifiedPhone : `+${verifiedPhone}`
      setPhoneNumber(verifiedPhone ? phone : '+7')
    }
  }, [member])

  useEffect(setData, [member])

  const onBackClick = () => {
    navigate(location?.pathname.replace('/member-record', '').replace(`/${memberId}`, ''))
  }

  useEffect(() => {
    if (requestStatus === EVENT_STATUSES.RK_CHILD_CREATED || requestStatus === EVENT_STATUSES.RK_CHILD_UPDATED) {
      dispatch(setRequestStatus.action(''))
      onBackClick()
    }
  }, [requestStatus])

  const getFullNameParent = useCallback((name) => {
    const split = name.trim().split(' ')
    return {
      surname: split[0] || '',
      firstName: split[1] || ''
    }
  }, [])

  const clearError = useCallback((field) => {
    return setErrors((errs) => ({...errs, [field]: ''}))
  }, [])

  const checkName = useCallback((name) => {
    return (name.trim() && !name.trim().includes(' ')) || !name.trim()
  }, [])

  const checkFullNameChild = useCallback((name) => {
    if (!name.trim()) {
      return 'Поле должно быть заполнено'
    }
    let split = name.split(',')
    const isLastNotExisted = split.length && !split[split.length - 1].trim()
    if (isLastNotExisted) {
      split = split.slice(0, split.length - 1)
    }
    const isNotValid = split.some((str) => checkName(str))
    return isNotValid ? 'Введите фамилию и имя ребенка' : ''
  }, [])

  const getFullNameChild = useCallback((name) => {
    const split = name.split(',')
    return split.map((str) => getFullNameParent(str))
  }, [])

  const handleSaveClick = () => {
    const phone = phoneNumber.replace(/[^+\d]/g, '')
    const newErrors = {}
    if (emailParent) {
      newErrors.emailParent = checkEmail(emailParent, 'Введите валидный email', false)
    }
    if (phone !== '+7') {
      newErrors.phoneNumber = checkIfPhoneValid(phone) ? '' : 'Введите валидный номер телефона'
    }
    if (phone.length !== 12) {
      newErrors.phoneNumber = 'Введите валидный номер телефона'
    }
    if (!memberName.trim()) {
      newErrors.memberName = 'Поле должно быть заполнено'
    } else {
      if (checkName(memberName)) {
        newErrors.memberName = 'Введите фамилию и имя'
      }
    }
    newErrors.kids = checkFullNameChild(kids)
    setErrors(newErrors)
    const hasErrors = Object.values(newErrors).some((el) => el)
    if (hasErrors) {
      return
    }
    const data = {
      phoneNumber: phone === '+7' ? '' : phone,
      fullNameParent: getFullNameParent(memberName),
      fullNameChild: getFullNameChild(kids)
    }
    if (emailParent) {
      data.emailParent = emailParent
    }
    if (memberId) {
      data.ids = member.ids
      const isKidsChanged = kids.trim() !== member.switchedKids
      const isMemberNameChanged = memberName.trim() !== member.switchedMemberName
      const isVerifiedPhoneChanged = phone.replace('+', '') !== member.verifiedPhone
      const isEmailChanged = emailParent !== member.email
      const isMemberDataChanged = isMemberNameChanged || isVerifiedPhoneChanged || isEmailChanged
      if (!isKidsChanged && !isMemberDataChanged) {
        onBackClick()
      }
      if (!isKidsChanged && isMemberDataChanged) {
        dispatch(updateChildRecordEvent.action(data))
      }
      if (isKidsChanged) {
        dispatch(deleteChildRecordEvent.action(data.ids, data))
      }
    } else {
      dispatch(refChildEvent.action(data))
    }
    setOpenModal(false)
  }

  return (
    <>
      {isLoading ? (
        <CircularProgress sx={{margin: '0 auto', padding: 19, display: 'block'}} />
      ) : (
        <FormWrapper sx={{marginTop: 0}}>
          <TextInput
            clearError={() => clearError('memberName')}
            errorMsg={errors.memberName}
            label="Претендент"
            id="member"
            value={memberName}
            onChangeValue={setMemberName}
            validator={checkLettersAndOneSpace}
            placeholder="Фамилия Имя"
          />
          <TextInput
            clearError={() => clearError('kids')}
            errorMsg={errors.kids}
            label="Дети"
            id="kids"
            minRows={4}
            multiline
            value={kids}
            resize
            onChangeValue={setKids}
            validator={checkKidsNames}
            placeholder="Фамилия Имя каждого через запятую"
          />
          <TextInput
            clearError={() => clearError('emailParent')}
            errorMsg={errors.emailParent}
            label="Email"
            id="email"
            withNotRequiredText
            value={emailParent}
            onChangeValue={setEmailParent}
          />
          <PhoneInput
            clearPhoneError={() => clearError('phoneNumber')}
            isError={Boolean(errors.phoneNumber)}
            // todo withNotRequiredText добавить, когда бек исправит
            phone={phoneNumber}
            phoneHandler={setPhoneNumber}
          />
          <Box className="modal">
            <Button
              variant="contained"
              onClick={handleSaveClick}
              startIcon={<CheckIcon color="white" />}
              // sx={{marginTop: '38px'}}
            >
              Сохранить
            </Button>
            <StyledButton
              variant="outlined"
              onClick={() => setOpenModal(false)}
              sx={{height: '46px'}}
              className="close"
            >
              Отмена
            </StyledButton>
          </Box>
        </FormWrapper>
      )}
    </>
  )
}

export default MemberRecordPage
