import {convertDateToUTC} from './getFormattedDate'

export function num_word(value, words) {
  value = Math.abs(value) % 100
  const num = value % 10

  if (value > 10 && value < 20) return words[2]
  if (num > 1 && num < 5) return words[1]
  if (num === 1) return words[0]
  return words[2]
}

export function estimatedTime(fromDateString, ago = false, isDateInUTC = true, isAlterCase = false) {
  let dayWords, hoursWords, minutesWords

  if (isAlterCase) {
    dayWords = ['дня', 'дней', 'дней']
    hoursWords = ['часа', 'часов', 'часов']
    minutesWords = ['мин', 'мин', 'мин']
  } else {
    dayWords = ['день', 'дня', 'дней']
    hoursWords = ['час', 'часа', 'часов']
    minutesWords = ['мин', 'мин', 'мин']
  }

  const currentDate = convertDateToUTC(new Date())
  let fromDate = new Date(fromDateString)

  if (!isDateInUTC) {
    fromDate = convertDateToUTC(fromDate)
  }
  let difference = Number(fromDate) - Number(currentDate)
  if (ago) difference = -difference
  const timeAgoCount = {
    d: Math.floor(difference / (1000 * 60 * 60 * 24)),
    h: Math.floor((difference / (1000 * 60 * 60)) % 24),
    m: Math.floor((difference / 1000 / 60) % 60)
  }

  let agoCount
  let agoWords

  if (timeAgoCount.d < 1) {
    if (timeAgoCount.h < 1) {
      if (timeAgoCount.m < 0) {
        agoCount = 0
        agoWords = minutesWords
      } else {
        agoCount = timeAgoCount.m
        agoWords = minutesWords
      }
    } else {
      agoCount = timeAgoCount.h
      agoWords = hoursWords
    }
  } else {
    agoCount = timeAgoCount.d
    agoWords = dayWords
  }

  if (agoCount === 0) {
    return agoCount
  } else {
    return `${agoCount} ${num_word(agoCount, agoWords)}`
  }
}
