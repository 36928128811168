import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {Typography} from '@mui/material'

import {Wrapper} from './ProjectMembersBlockStyle'
import {Header} from '../../../../Settings/SettingsPageStyle'
import SelectableMemberItem from '../SelectableMemberItem/SelectableMemberItem'
import ParentCheckbox from '../ParentCheckbox/ParentCheckbox'
import {ButtonWrapper} from '../../../../Summary/components/projects/ProjectsStyle'
import {ChevronDownIcon} from '../../../../../../../icons'
import {Button} from '@mui/material'

const ProjectMembersBlock = ({
  className = '',
  members,
  editable,
  projectMembers = [],
  setProjectMembers,
  projectErrors,
  setError
}) => {
  const [isShowMore, setIsShowMore] = useState(false)
  const [childrenCount, setChildrenCount] = useState(0)

  const onCheckBoxClick = useCallback((userUuid) => {
    setProjectMembers((oldArr) => {
      if (oldArr.includes(userUuid)) {
        return oldArr.filter((id) => id !== userUuid)
      } else {
        return [...oldArr, userUuid]
      }
    })
  }, [])

  useEffect(() => {
    setChildrenCount(members.reduce((a, b) => a + (b['children'].length || 0), 0))
  }, [members])

  useEffect(() => {
    if (projectMembers.length) {
      setError('members', null)
    }
  }, [projectMembers])

  const membersItems = useMemo(() => {
    if (!editable && !members.length) {
      return (
        <Typography variant="h4" sx={{fontSize: '16px'}}>
          В проекте никого нет
        </Typography>
      )
    }
    return members.map((member, i) => {
      if (i > 2 && !editable && !isShowMore) {
        return null
      }
      return (
        <SelectableMemberItem
          editable={editable}
          withoutBorder={true}
          key={member.userUuid}
          member={member}
          checkedItems={projectMembers}
          onCheckBoxClick={onCheckBoxClick}
        />
      )
    })
  }, [members, projectMembers, onCheckBoxClick, editable, isShowMore])

  const handleChangeParent = useCallback(() => {
    setProjectMembers((oldArr) => {
      return oldArr.length === members?.length ? [] : members.map(({userUuid}) => userUuid)
    })
  }, [members])

  const onShowMoreClick = useCallback(() => {
    setIsShowMore(true)
  }, [])

  const showAdditionalButton = !editable && !isShowMore && members.length > 3

  return (
    <Wrapper className={className} sx={{borderColor: projectErrors?.members ? 'red !important' : null}} id="members">
      <Header className={editable ? 'editableTitle' : 'title'}>
        Кто в проекте ({membersItems?.length ? `у ${membersItems.length} участников ${childrenCount} детей` : null})
      </Header>
      {editable && (
        <ParentCheckbox
          checkedItems={projectMembers}
          members={members}
          childrenCount={childrenCount}
          handleChangeParent={handleChangeParent}
        />
      )}
      {membersItems}
      {showAdditionalButton && (
        <Button
          className="showMoreButton"
          variant="outlined"
          type="button"
          onClick={onShowMoreClick}
          endIcon={<ChevronDownIcon />}
        >
          Показать еще
        </Button>
      )}
      {projectErrors?.members ? (
        <Typography variant="h4" sx={{fontSize: '10px', color: '#E41D3D'}}>
          {projectErrors?.members}
        </Typography>
      ) : null}
    </Wrapper>
  )
}

export default ProjectMembersBlock
