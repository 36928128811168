import httpClient from './network'

export const makeRkMembersReport = (uuid) => {
  return httpClient.post(`reporting/rk-members/${uuid}`)
}

export const downloadRkMembersReport = (uuid) => {
  return httpClient.get(`reporting/report/${uuid}`, {
    responseType: 'blob'
  })
}
