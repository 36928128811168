import {styled} from '@mui/material/styles'

export const SchoolsWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  gap: '19px',
  flexDirection: 'column',
  margin: '38px 0 19px',
  [theme.breakpoints.up('md')]: {
    gap: '13px',
    margin: '42px 19px 438px 19px'
  },
  [theme.breakpoints.up(1224)]: {
    margin: '42px 0 38px 0'
  }
}))

export const MapWrapper = styled('div')(({theme}) => ({
  marginBottom: 24,
  height: '184px',
  [theme.breakpoints.up('md')]: {
    height: '240px',
    gap: '13px',
    marginBottom: 24
  },
  [theme.breakpoints.up(1224)]: {
    marginBottom: 24
  }
}))
