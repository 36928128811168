export const DOCUMENTS = [
  {
    name: 'Договор о совместной деятельности',
    format: 'DOCX',
    date: '02.02.2022',
    link: 'https://storage.yandexcloud.net/bucket576787/docs/%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80%20%D0%BE%20%D1%81%D0%BE%D0%B2%D0%BC%D0%B5%D1%81%D1%82%D0%BD%D0%BE%D0%B9%20%D0%B4%D0%B5%D1%8F%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8%20%D0%A0%D0%BE%D0%B4%D0%BA%D0%BE%D0%BC.pdf'
  }
  // {
  //   name: 'Договор о раздельной деятельности',
  //   format: 'DOCX',
  //   date: '01.01.2022',
  //   link: '/'
  // }
]
