import {styled} from '@mui/material/styles'
import {MenuItem, TableCell} from '@mui/material'
import Menu from '@mui/material/Menu'
import Typography from '@mui/material/Typography'

export const Wrapper = styled('div')(({theme}) => ({
  padding: 38,
  marginTop: 24,
  background: '#FFFFFF',
  borderRadius: 12,
  // boxShadow: '0px 12px 32px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  border: '1px solid #EDEDFF',
  // marginBottom: 150,
  '.row-btn': {
    boxShadow: 'none !important'
  },
  [theme.breakpoints.down(900)]: {
    marginBottom: 77
  },
  [theme.breakpoints.down(780)]: {
    padding: '21px 19px 19px'
  },
  '& .download-btn': {
    maxWidth: 'fit-content',
    // boxShadow: 'none',
    svg: {
      path: {
        stroke: '#39215E'
      }
    }
  },
  '& .add-record-btn': {
    maxWidth: 'fit-content',
    marginRight: '8px',
    marginTop: 0
    // height: '53px !important'
  },
  '& .members-amount': {
    marginRight: 'auto'
  },
  '& .title': {
    marginBottom: 3
  },
  '& .subTitle': {
    fontSize: 16
  },
  '& .downloadIcon': {
    marginTop: 3
  },
  '& .context-menu-btn': {
    padding: 0,
    minWidth: '29px',
    maxWidth: '29px',
    maxHeight: '29px',
    borderRadius: '6px',
    background: 'transparent',
    '&:active': {
      boxShadow: 'none'
    },
    margin: 13,
    '& > span': {
      margin: 0
    }
  },
  '& .header-btn': {
    marginLeft: 'auto',
    '& > span': {
      marginTop: 6
    }
  },
  [theme.breakpoints.down(900)]: {
    '& .add-record-btn': {
      display: 'none'
    },
    '& .download-btn': {
      display: 'none'
    },
    '& .header-btn': {
      display: 'block'
    }
  },
  [theme.breakpoints.up(900)]: {
    '& .header-btn': {
      display: 'none'
    }
  }
}))

export const MembersHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start'
}))

export const Hyphen = styled('div')(() => ({
  width: '16px !important',
  height: 1,

  background: '#39215E',
  margin: '26px 13px'
}))

export const StyledMenu = styled(Menu)(() => ({
  borderRadius: '6px',
  '& .cross-icon': {
    marginLeft: 3
  }
}))

export const StyledMenuItem = styled(MenuItem)(({theme}) => ({
  padding: '8px 13px',
  minWidth: '192px',
  color: theme.palette.primary.main
}))

export const CellText = styled(Typography)(({theme}) => ({
  padding: '13px',
  color: theme.palette.primary.main
}))

export const PhoneText = styled(Typography)(({theme}) => ({
  color: theme.palette.primary.main
}))

export const StyledTableCell = styled(TableCell)(() => ({
  minWidth: '240px',
  padding: 0,
  '& > div': {
    width: '100%'
  },
  '.progMember': {
    boxShadow: 'none',
    border: 'none',
    '.avatar': {
      width: 40,
      height: 40
    }
  },
  '@media(max-width: 600px)': {
    minWidth: '0px'
  }
}))
