import {styled} from '@mui/material/styles'

export const ItemWrapper = styled('div')(({theme}) => ({
  padding: '1px 0',
  display: 'flex',
  justifyContent: 'space-between',
  boxShadow: '0px -1px 0px rgba(0, 0, 0, 0.12)',
  alignItems: 'center',
  cursor: 'pointer',
  background: '#FFFFFF',
  [theme.breakpoints.down(900)]: {
    padding: '1px 0'
  },
  '&.withoutBorder': {
    boxShadow: 'none'
  },
  '&.notSelectable': {
    cursor: 'default'
  },
  '& .progMember': {
    boxShadow: 'none',
    // width: '100%',
    '& .MuiAvatar-root': {
      width: 40,
      height: 40
    }
  }
}))
