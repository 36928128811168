import React from 'react'
import {Wrapper, Title, ParentRole, EducationRole, Description, InputRadio, Label, Role} from './YourRoleStyle'
import {FormControl, FormControlLabel, RadioGroup} from '@mui/material'
import {RK_ROLE} from '../../constants/index'

const YourRole = ({role, onChange}) => {
  const handleChange = (val) => {
    onChange(val)
  }
  return (
    <Wrapper>
      <Title>Ваша роль в этом родкоме</Title>
      <FormControl>
        <RadioGroup
          //
          className="group"
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={role}
          onChange={(event) => {
            handleChange(event.target.value)
          }}
        >
          <ParentRole selected={role === RK_ROLE.PARENT}>
            <FormControlLabel
              value={RK_ROLE.PARENT}
              control={<InputRadio />}
              label={
                <Label>
                  <Role>Родитель</Role>
                  <Description>Участвуйте в проектах и принятии решений</Description>
                </Label>
              }
            />
          </ParentRole>
          <EducationRole selected={role === RK_ROLE.EDUCATOR}>
            <FormControlLabel
              value={RK_ROLE.EDUCATOR}
              control={<InputRadio />}
              label={
                <Label>
                  <Role>Педагог</Role>
                  <Description>Наблюдайте за проектами и голосованиями</Description>
                </Label>
              }
            />
          </EducationRole>
        </RadioGroup>
      </FormControl>
    </Wrapper>
  )
}

export default YourRole
