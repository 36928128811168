import initSlice from '../initSlice'

const {sliceEvent, allSliceEvents} = initSlice()

const PREFIX = 'COMMITTEE_SETTINGS_'

export const setIsLoading = sliceEvent({
  type: `${PREFIX}_SET_IS_LOADING`,
  action: (isLoading) => ({
    payload: {
      isLoading
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {isLoading} = action.payload
    return {
      ...state,
      isLoading
    }
  }
})

export const setTabsIsLoading = sliceEvent({
  type: `${PREFIX}_SET_TABS_IS_LOADING`,
  action: (tabsIsLoading) => ({
    payload: {
      tabsIsLoading
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {tabsIsLoading} = action.payload
    return {
      ...state,
      tabsIsLoading
    }
  }
})

export const deleteRkEvent = sliceEvent({
  type: `${PREFIX}_DELETE_RK_EVENT`,
  action: (uuid) => ({
    payload: {
      uuid
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const getInitParentCommitteeData = sliceEvent({
  type: `${PREFIX}_GET_INIT_PARENT_COMMITTEE_DATA`,
  action: (uuid) => ({
    payload: {
      uuid
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      tabsIsLoading: true
    }
  }
})

export const getParentCommitteeOperationsEvent = sliceEvent({
  type: `${PREFIX}_GET_PARENT_COMMITTEE_OPERATIONS`,
  action: (uuid, filter) => ({
    payload: {
      uuid,
      filter
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      // tabsIsLoading: true
      isLoading: true
    }
  }
})
export const getParentCommitteeOperationsEventSuccess = sliceEvent({
  type: `${PREFIX}_GET_PARENT_COMMITTEE_OPERATIONS_SUCCESS`,
  action: (operations) => ({
    payload: {
      operations
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {operations} = action.payload

    let report = {
      ...operations,
      operations: operations.operations.map((o) => {
        return {
          ...o,
          date: new Date(o.date) //,
          //parentName: state.membersParentCommittee.find((v) => v.uuid == o.userUuid)
        }
      })
    }
    return {
      ...state,
      tabsIsLoading: false,
      isLoading: false,
      operations: report
    }
  }
})

export const getRkProfileEvent = sliceEvent({
  type: `${PREFIX}_GET_RK_PROFILE_EVENT`,
  action: (uuid) => ({
    payload: {
      uuid
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const getRkProfileEventSuccess = sliceEvent({
  type: `${PREFIX}_GET_RK_PROFILE_EVENT_SUCCESS`,
  action: (profileInfo, uuid, recordsMembers) => ({
    payload: {
      profileInfo,
      uuid,
      recordsMembers
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {profileInfo, uuid, recordsMembers} = action.payload
    const phone = profileInfo?.withdrawalDetails?.phoneNumber
    const changedPhone = phone ? `+${phone}` : ''
    return {
      ...state,
      profileInfo: {...profileInfo, withdrawalDetails: {...profileInfo.withdrawalDetails, phoneNumber: changedPhone}},
      uuid: uuid ? uuid : state.uuid,
      recordsMembers: recordsMembers || state.recordsMembers,
      address: profileInfo.institutionAddress,
      isLoading: false
    }
  }
})

export const loadAddress = sliceEvent({
  type: `${PREFIX}_LOAD_ADDRESS`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    return {
      ...state,
      address: action.payload.address
    }
  }
})

export const updateRkProfileEvent = sliceEvent({
  type: `${PREFIX}_UPDATE_RK_PROFILE_EVENT`,
  action: (uuid, data) => ({
    payload: {
      uuid,
      data
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const getRkMembersEvent = sliceEvent({
  type: `${PREFIX}_GET_RK_MEMBERS_EVENT`,
  action: (uuid, fromMembersTab) => ({
    payload: {
      uuid,
      fromMembersTab
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      membersIsLoading: true
    }
  }
})

export const setMembersIsLoadingEvent = sliceEvent({
  type: `${PREFIX}_SET_MEMBERS_IS_LOADING_EVENT`,
  action: (isLoading) => ({
    payload: {
      isLoading
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {isLoading} = action.payload
    return {
      ...state,
      membersIsLoading: isLoading
    }
  }
})

export const checkIfUserIsMemberEvent = sliceEvent({
  type: `${PREFIX}_CHECK_IF_USER_IS_MEMBER_EVENT`,
  action: (uuid) => ({
    payload: {
      uuid
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true,
      tabsIsLoading: true
    }
  }
})

export const transferMembershipEvent = sliceEvent({
  type: `${PREFIX}_TRANSFER_MEMBERSHIP_EVENT`,
  action: (participant) => ({
    payload: {
      participant
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const transferMembershipApproveEvent = sliceEvent({
  type: `${PREFIX}_TRANSFER_MEMBERSHIP_APPROVE_EVENT`,
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const transferMembershipRejectEvent = sliceEvent({
  type: `${PREFIX}_TRANSFER_MEMBERSHIP_REJECT_EVENT`,
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const transferMembershipLastEvent = sliceEvent({
  type: `${PREFIX}_TRANSFER_MEMBERSHIP_LAST_EVENT`,
  action: (uuid) => ({
    payload: {
      uuid
    }
  })
})

export const transferMembershipMeEvent = sliceEvent({
  type: `${PREFIX}_TRANSFER_MEMBERSHIP_ME_EVENT`,
  action: (uuid) => ({
    payload: {
      uuid
    }
  })
})

export const transferMembershipMeEventSuccess = sliceEvent({
  type: `${PREFIX}_TRANSFER_MEMBERSHIP_ME_EVENT_SUCCESS`,
  action: (data) => ({
    payload: {
      data
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {data} = action.payload
    return {
      ...state,
      isLoading: false,
      transferMemberApplication: data
    }
  }
})

export const setMembersParentCommittee = sliceEvent({
  type: `${PREFIX}_SET_MEMBERS_PARENT_COMMITTEE`,
  action: (members, membersOptions, roles, appMembership) => ({
    payload: {
      members,
      membersOptions,
      roles,
      appMembership
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {members, membersOptions, roles, appMembership} = action.payload
    return {
      ...state,
      isLoading: false,
      membersParentCommittee: members,
      membersOptions,
      roles: roles || state.roles,
      appMembership: appMembership || state.appMembership
    }
  }
})

export const approveMembershipEvent = sliceEvent({
  type: `${PREFIX}_APPROVE_MEMBERSHIP_EVENT`,
  action: (appUuid) => ({
    payload: {
      appUuid
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      membersIsLoading: true
    }
  }
})

export const rejectMembershipEvent = sliceEvent({
  type: `${PREFIX}_REJECT_MEMBERSHIP_EVENT`,
  action: (appUuid) => ({
    payload: {
      appUuid
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      membersIsLoading: true
    }
  }
})

export const getAppMembershipEvent = sliceEvent({
  type: `${PREFIX}_GET_APP_MEMBERSHIP_EVENT`,
  action: (appUuid) => ({
    payload: {
      appUuid
    }
  })
})

export const excludeMemberEvent = sliceEvent({
  type: `${PREFIX}_EXCLUDE_MEMBER_EVENT`,
  action: (userUuid) => {
    return {
      payload: {
        userUuid
      }
    }
  },
  reducer: (state) => {
    return {
      ...state,
      membersIsLoading: true
    }
  }
})

export const transferMembershipRejectSuccessEvent = sliceEvent({
  type: `${PREFIX}_TRANSFER_MEMBERSHIP_REJECT_SUCCESS`,
  action: (userUuid) => {
    return {
      payload: {
        userUuid
      }
    }
  },
  reducer: (state) => {
    return {
      ...state,
      transferMemberApplication: {}
    }
  }
})

export const allInitAppEvents = allSliceEvents
