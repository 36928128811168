import React, {useState, useMemo, useCallback, useEffect} from 'react'
import {Avatar, CircularProgress} from '@mui/material'
import {styled} from '@mui/material/styles'
import Button from '@mui/material/Button'
import {CheckIcon, CrossIcon, UploadIcon} from '../../icons'
import ImageCrop from '../ImageCropModal/ImageCropModal'
import {CommonModal} from '../CommonModal/CommonModal'
import {avatarURL} from '../../utils/imageURL'
import {notifyError} from '../../utils/notify'

import heic2any from 'heic2any'
import {getFileType} from '../../utils/getFileType'

const maxSizeAvatar = 10485760

const Input = styled('input')({
  display: 'none'
})

const AvatarUploaderWrapper = styled('div')(({theme}) => ({
  '& .image-upload-button': {
    position: 'relative',
    width: '128px',
    height: '128px',
    padding: 0
  },
  '& .uploadIcon': {
    position: 'absolute',
    top: 'auto',
    left: 'auto',
    right: '0',
    bottom: '0',
    background: theme.palette.grey[100],
    borderRadius: '50%',
    width: '38px',
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .avatar': {
    width: '100%',
    height: '100%'
  },
  '& .avatar-user-buttons': {
    marginBottom: '8px'
  }
}))

const Label = styled('label')(({theme}) => ({
  display: 'block',
  position: 'relative',
  marginBottom: '19px',
  cursor: 'pointer',
  border: `1px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  padding: '10px 19px',
  borderRadius: '10px'
}))

const AvatarUploader = ({avatarUrl: avatarFromProps, onSave, onDelete}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [src, setSrc] = useState(avatarFromProps)
  const [avatarUrl, setAvatarUrl] = useState(avatarFromProps)
  const [currentUrl, setCurrentUrl] = useState(avatarFromProps)
  const [isLoading, setIsLoading] = useState(false)

  const setAvatarFromProps = useCallback(() => {
    setCurrentUrl(avatarFromProps)
    setSrc(avatarFromProps)
  }, [avatarFromProps])

  useEffect(setAvatarFromProps, [avatarFromProps])

  const handleCloseModal = () => {
    setIsOpen(false)
  }

  // @ts-ignore
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > maxSizeAvatar) {
        notifyError('Размер файла не должен превышать 10 Мб')
        return
      }
      if (!e.target.files[0].type && getFileType(e.target.files[0].name) === 'heic') {
        setIsLoading(true)
        heic2any({
          blob: e.target.files[0],
          toType: 'image/jpg'
        })
          .then((data) => {
            const file = new File([data], 'heic' + '.jpg', {type: 'image/jpeg', lastModified: new Date().getTime()})
            const url = URL.createObjectURL(file)
            setSrc(url)
            setIsLoading(false)
          })
          .catch(() => {
            notifyError('Не удалось загрузить изображение')
            setIsLoading(false)
          })
      } else {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          setSrc(reader.result)
        })
        reader.readAsDataURL(e.target.files[0])
        setIsOpen(true)
      }
    }
  }

  const handleClickDeleteButton = () => {
    setAvatarUrl('')
    setSrc('')
    setCurrentUrl('')
    onDelete()
    setIsOpen(false)
  }

  const updateImageUrl = (url) => {
    setAvatarUrl(url)
  }

  const handleOpenModal = () => {
    setIsOpen(true)
  }

  const handleSaveClick = () => {
    setIsOpen(false)
    if (avatarUrl) {
      onSave(avatarUrl)
    }
  }

  const buttonsList = useMemo(() => {
    return (
      <>
        <Button
          variant="outlined"
          type="button"
          onClick={handleClickDeleteButton}
          startIcon={<CrossIcon color="#CB0843" />}
          sx={{margin: '3px 0'}}
        >
          Удалить аватар
        </Button>
        <Button
          variant="contained"
          onClick={handleSaveClick}
          startIcon={<CheckIcon color="white" />}
          sx={{margin: '3px 0'}}
        >
          Сохранить
        </Button>
      </>
    )
  }, [handleClickDeleteButton, handleSaveClick])

  return (
    <AvatarUploaderWrapper>
      <Button
        className="image-upload-button"
        color="primary"
        aria-label="upload image"
        component="button"
        onClick={handleOpenModal}
      >
        <Avatar className="avatar" src={avatarURL(currentUrl)} />
        <UploadIcon className="uploadIcon" />
      </Button>

      <CommonModal isOpen={isOpen} onClose={handleCloseModal} buttonsList={buttonsList} isLoading={isLoading}>
        <Label htmlFor="icon-button-file">
          <Input accept=".gif, .jpg, .jpeg, .png, .heic" id="icon-button-file" type="file" onChange={onSelectFile} />
          Загрузить аватар
        </Label>
        <ImageCrop src={src} updateImageUrl={updateImageUrl} />
      </CommonModal>
    </AvatarUploaderWrapper>
  )
}

export default AvatarUploader
