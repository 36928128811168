import httpClient from './network'

export const getVoteRequest = (uuid) => {
  return httpClient.get(`/vote/${uuid}`)
}

export const updateVoteRequest = (uuid, data) => {
  return httpClient.put(`/vote/${uuid}`, data)
}

export const createVoteRequest = (data) => {
  return httpClient.post('/vote', data)
}

export const duplicateVoteRequest = (uuid) => {
  return httpClient.post(`/vote/duplicate/${uuid}`)
}

export const getVotesListRequest = (data) => {
  return httpClient.post('/vote/list', data)
}

export const toVoteRequest = (data) => {
  return httpClient.post('/vote/to-vote', data)
}

export const archiveVoteRequest = (uuid) => {
  return httpClient.put(`/vote/${uuid}/archive`)
}
