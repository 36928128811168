import React from 'react'

export const CrossIcon = ({onClick, className, color = '#39215E'}) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13 1L1 13M1 1L13 13" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
