import React, {useMemo} from 'react'

import {LockIcon} from '../../../icons'

import {FormHelperTextWrapper, InputLabelWrapper, StyledFormControl, StyledInputBase} from './TextInputStyle'

const TextInput = ({
  value = '',
  onChangeValue,
  id,
  label,
  className = '',
  withNotRequiredText = false,
  errorMsg,
  secondLabel,
  required,
  clearError,
  validator,
  disabled,
  resize,
  children,
  ...props
}) => {
  const onChange = (event) => {
    if (errorMsg && clearError) {
      clearError()
    }
    const {value} = event.target
    if (validator && !validator(value)) {
      return
    }
    onChangeValue(value)
  }

  const classes = useMemo(() => {
    let classNames = ''
    if (resize) {
      classNames = classNames + 'resize'
    }
    if (errorMsg) {
      classNames = classNames + ' error'
    }
    return classNames
  }, [errorMsg, resize])

  return (
    <StyledFormControl
      disabled={disabled}
      required={required}
      error={Boolean(errorMsg)}
      classes={{root: className}}
      variant="standard"
      fullWidth={true}
      sx={{maxWidth: '500px'}}
    >
      {label ? (
        <InputLabelWrapper htmlFor={id} shrink>
          {label} {withNotRequiredText && <span className="notRequiredText">(не обязательно)</span>}
        </InputLabelWrapper>
      ) : null}
      <StyledInputBase classes={{input: classes}} id={id} type="text" value={value} onChange={onChange} {...props} />
      {disabled && <LockIcon className="lockIcon" />}
      {errorMsg && <FormHelperTextWrapper>{errorMsg}</FormHelperTextWrapper>}
      {!errorMsg && secondLabel && <FormHelperTextWrapper>{secondLabel}</FormHelperTextWrapper>}
    </StyledFormControl>
  )
}

export default TextInput
