import {styled} from '@mui/material/styles'

export const ImagesWrapper = styled('div')(() => ({
  display: 'flex',
  gap: '13px',
  marginBottom: 24,
  flexWrap: 'wrap',
  '& img': {
    maxWidth: '100%'
  }
}))
