import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Box, Typography} from '@mui/material'
import {styled} from '@mui/material/styles'

import {filterValues} from '../ReportsPage'
import {ChevronDownIcon, ChevronDownIconAlt} from '../../../../../icons'
import {sortMethods} from './ReportTable'
import {StyledAllOperationsCell} from './RowTableAllOperations/RowTableAllOperationsStyles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'

const StyledWrapper = styled(Box)(({theme, index}) => ({
  padding: '12px',
  display: 'flex',
  width: '100%',
  gap: '13px',
  height: '48px',
  fontSize: 16,
  boxSizing: 'border-box',
  '& .tdc-0': {
    width: '3% !important'
  },
  '& .tdc-1': {
    width: '30% !important'
  },
  '& .tdc-2': {
    width: '19% !important'
  },
  '& .tdc-3': {
    width: '19% !important'
  },
  '& .tdc-4': {
    width: '19% !important'
  },
  '& .tdc-5': {
    width: '19% !important'
  }
}))

const StyledHeaderCell = styled(Box)(({theme, index}) => ({
  // flexGrow: !index ? '2' : '0',
  boxSizing: 'border-box',
  // width: '100%',
  textAlign: 'left',
  alignSelf: 'start',
  display: 'flex',
  alignItems: 'start'
}))

const RowHead = ({tableKind, headers, setSorting, history}) => {
  if (!headers.length) return null

  const headersWithSortStatusInit = useMemo(() => {
    return headers.map((header) => {
      return {
        title: header,
        sort: ''
      }
    })
  }, [tableKind])

  const [headersWithSortStatus, setHeadersWithSortStatus] = useState(headersWithSortStatusInit)
  const sortOrderArrayRef = useRef()
  const themes = useTheme()
  const mobile = useMediaQuery(themes.breakpoints.down('md'))

  const handleHeaderClick = useCallback(
    (fieldName) => {
      const prevSortOrderArrayRef = sortOrderArrayRef.current ? sortOrderArrayRef.current : []
      const prevSortField = prevSortOrderArrayRef.find((sortItem) => sortItem.field === fieldName)

      let newSortMethod
      if (!prevSortField) {
        newSortMethod = sortMethods.ASC
      } else {
        newSortMethod = prevSortField.sort !== sortMethods.ASC ? sortMethods.ASC : sortMethods.DESC
      }

      const newSortField = {field: fieldName, sort: newSortMethod}

      const newSortOrderArray = prevSortOrderArrayRef.filter((sortItem) => sortItem.field !== fieldName)
      newSortOrderArray.push(newSortField)

      sortOrderArrayRef.current = newSortOrderArray

      setHeadersWithSortStatus((prev) => {
        return prev.map((header) => {
          if (header.title.fieldName === fieldName) {
            return {...header, sort: newSortMethod}
          } else {
            return header
          }
        })
      })

      setSorting(newSortOrderArray)
    },
    [setSorting]
  )

  useEffect(() => {
    setHeadersWithSortStatus(headersWithSortStatusInit)
  }, [tableKind])

  const renderHeaderCells = () => {
    if (tableKind === filterValues.all) {
      return headersWithSortStatus.map((header, index) => (
        <StyledAllOperationsCell
          key={header.title.fieldName}
          index={index}
          component="td"
          onClick={() => handleHeaderClick(header.title.fieldName)}
          className={`td-${index} ${history ? `history` : ''} ${mobile ? `mobile` : ''}`}
          sx={{cursor: 'pointer'}}
        >
          <ChevronDownIconAlt
            headersWithSortStatus
            className={header.sort === sortMethods.DESC ? 'sortIconDesc' : null}
          />
          <Typography variant="table_header" sx={{marginLeft: '4px'}}>
            {header.title.headerName}
          </Typography>
        </StyledAllOperationsCell>
      ))
    } else {
      return headers.map((header, index) => (
        <StyledHeaderCell key={header} index={index} component="td" className={`tdc-${index}`}>
          <Typography variant="table_header">{header}</Typography>
        </StyledHeaderCell>
      ))
    }
  }

  return <StyledWrapper component="tr">{renderHeaderCells()}</StyledWrapper>
}

export default RowHead
