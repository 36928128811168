import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'

import {Button} from '@mui/material'

import {Class, ClassWrapper, InfoLabel, LeftBlock, StyledBox, StyledItem, Title} from './ParentCommitteesStyle'
import {CheckIcon} from '../../../../icons'
import {getFormattedDate} from '../../../../utils/getFormattedDate'
import {directoriesSelectors} from '../../../../store/directoriesStore/directoriesStore'

const RodcomItem = ({
  isSchool,
  chiefFullName,
  createdAt,
  educator,
  membersCount,
  groupName,
  groupNumber,
  name,
  city,
  institutionName,
  classLetterText,
  classesText,
  onLinkClick,
  classUuid,
  classLetterUuid,
  ...item
}) => {
  const classes = useSelector(directoriesSelectors.class.selector)
  const classesLetter = useSelector(directoriesSelectors.classLetter.selector)

  const createdText = useMemo(() => `Создан: ${getFormattedDate(new Date(createdAt))}`, [createdAt])

  const classNumber = classUuid && String(classes[classUuid])

  const titleText = useMemo(() => {
    const schoolClassName = `${classLetterText || ''}${classesText || ''} ${classNumber || ''}${groupName || ''}${
      name ? `, ${name}` : ''
    }`
    const otherClassName = `${
      groupName && groupNumber ? `${groupName}, Группа № ${groupNumber}` : groupName || `Группа № ${groupNumber}`
    }`

    return `${isSchool ? schoolClassName : otherClassName}, ${city || ''}, ${institutionName || ''}`
  }, [classLetterText, classesText, name, groupName, classNumber, groupNumber, isSchool])
  const classLetter = classesLetter[classLetterUuid]
  return (
    <StyledItem>
      {isSchool && classNumber && classLetter && (
        <ClassWrapper>
          <Class>{classNumber + classLetter}</Class>
        </ClassWrapper>
      )}
      <LeftBlock>
        <Title>{titleText}</Title>
        <StyledBox>
          {/*<InfoLabel variant="h6">{createdText}</InfoLabel>*/}
          <InfoLabel variant="h6">Глава: {chiefFullName}</InfoLabel>
          {educator && <InfoLabel variant="h6">{`${isSchool ? 'Классрук.:' : 'Воспитатель:'} ${educator}`}</InfoLabel>}
          <InfoLabel variant="h6">Участников: {membersCount}</InfoLabel>
        </StyledBox>
      </LeftBlock>
      <Button
        variant="contained"
        type="button"
        onClick={() => {
          onLinkClick(item.uuid)
        }}
        startIcon={<CheckIcon color="white" />}
      >
        Вступить
      </Button>
    </StyledItem>
  )
}

export default RodcomItem
