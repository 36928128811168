import React, {useEffect, useMemo} from 'react'
import {num_word} from '../../../../../../../../utils/EstimatedTime'
import {
  StyledOptionTitle,
  StyledSelectedCount,
  StyledVotedList,
  StyledVoteOptionWrapper,
  StyledVoterName
} from './VoteOptionStyle'
import classNames from 'classnames/bind'
import {Avatar, Typography} from '@mui/material'
import {useSelector} from 'react-redux'
import {profileSelectors} from '../../../../../../../../store/profileStore/profileStore'
import {avatarURL} from '../../../../../../../../utils/imageURL'
import {committeeSettingsSelectors} from '../../../../../../../../store/committeeSettingsStore/committeeSettingsStore'
import {RK_ROLE} from '../../../../../../../JoiningRodcom/constants'

const VoteOption = ({choice, voteData, isSelected, isVotable, ...props}) => {
  const myUuid = useSelector(profileSelectors.user.selector)?.uuid
  const roles = useSelector(committeeSettingsSelectors.roles.selector)

  const isEducator = useMemo(() => roles?.includes(RK_ROLE.EDUCATOR), [roles])

  const choiceSelectedCount = useMemo(() => {
    return voteData.voteAnswers.reduce((previousValue, currentValue) => {
      const personVoicesForThisChoice = currentValue.chosenOptionNumbers.filter((chosenOptionNumber) => {
        return chosenOptionNumber === choice.number
      })

      return previousValue + personVoicesForThisChoice.length
    }, 0)
  }, [voteData.voteAnswers])

  const usersSelected = voteData.voteAnswers.filter((answer) => {
    return answer.chosenOptionNumbers.includes(choice.number)
  })
  const fillWidth = `${(choiceSelectedCount / voteData.expectedAnswersTotal) * 100}%`

  const voicesString = `${choiceSelectedCount === 0 ? 'нет' : choiceSelectedCount} ${num_word(choiceSelectedCount, [
    'голос',
    'голоса',
    'голосов'
  ])}`

  const isAnsweredByMe = () => {
    if (!voteData.haveIAnswered) return false
    const whatIAnswered = voteData.voteAnswers.find((answer) => answer.userUuid === myUuid)

    if (whatIAnswered) {
      const whatIAnsweredArray = whatIAnswered.chosenOptionNumbers
      return whatIAnsweredArray.includes(choice.number)
    } else {
      return false
    }
  }

  const isUsersAnsweredShown = !isVotable && usersSelected.length && !voteData.isPrivate

  let emojiImg
  try {
    emojiImg = String.fromCodePoint('0x' + choice.emoji)
  } catch (e) {
    // console.error("Can't read emoji")
  }

  return (
    <StyledVoteOptionWrapper
      {...props}
      className={classNames({
        _selected: isSelected && !voteData.haveIAnswered,
        _answeredByMe: isAnsweredByMe(),
        _archived: !isVotable
      })}
    >
      <div className="optionMainContent">
        {!isVotable ? <div style={{width: fillWidth}} className="optionFill"></div> : null}
        <div className="optionData">
          {isVotable ? <span className="selectIcon"></span> : null}
          <div className="optionDataText">
            <StyledOptionTitle>{choice.text}</StyledOptionTitle>
            {!isVotable ? <StyledSelectedCount>{voicesString}</StyledSelectedCount> : null}
          </div>
          {choice.emoji ? <em-emoji id={choice.emoji?.id} set="apple" size="40px"></em-emoji> : null}
          {/* {emojiImg ? (
            <Typography className="optionEmoji" sx={{marginLeft: '24px'}}>
              {emojiImg ? emojiImg : null}
            </Typography>
          ) : null} */}
        </div>
      </div>
      {isUsersAnsweredShown ? (
        <StyledVotedList>
          {usersSelected.map((user) => {
            return (
              <div className="votedListItem" key={user.userUuid}>
                <Avatar
                  sx={{width: '29px', height: '29px'}}
                  alt={`${user?.userFullname?.surname} ${user?.userFullname?.firstName}`}
                  src={user?.userAvatarUrl ? avatarURL(user.userAvatarUrl) : '/'}
                />
                <StyledVoterName>{user?.userFullname?.surname + ' ' + user?.userFullname?.firstName}</StyledVoterName>
              </div>
            )
          })}
        </StyledVotedList>
      ) : null}
    </StyledVoteOptionWrapper>
  )
}

export default VoteOption
