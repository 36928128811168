import initSlice from '../initSlice'

const {sliceEvent, allSliceEvents} = initSlice()

const PREFIX = 'PROFILE_'

export const sendCurrentUserData = sliceEvent({
  type: `${PREFIX}_SEND_NEW_USER_DATA`,
  action: (userData) => ({
    payload: {
      user: userData
    }
  })
})

export const getUserInfoSuccess = sliceEvent({
  type: `${PREFIX}_USER_INFO_SUCCESS`,
  action: (userInfo) => ({
    payload: {
      userInfo
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {userInfo} = action.payload
    return {
      ...state,
      user: userInfo
    }
  }
})

export const setStepEvent = sliceEvent({
  type: `${PREFIX}_SET_STEP`,
  action: (step) => ({
    payload: {
      step
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    return {
      ...state,
      check_sum_step: action.payload.step
    }
  }
})

export const sendControlSumEvent = sliceEvent({
  type: `${PREFIX}_SEND_CSUM`,
  action: (rkId, bank, sbpPhone) => ({
    payload: {
      rkId,
      bank,
      sbpPhone
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const sendControlSumEventSuccess = sliceEvent({
  type: `${PREFIX}_SEND_CSUM_SUCCESS`,
  reducer: (state) => {
    return {
      ...state,
      isLoading: false,
      check_sum_step: 'check'
    }
  }
})

export const sendControlSumEventFail = sliceEvent({
  type: `${PREFIX}_SEND_CSUM_FAIL`,
  action: (event) => ({
    payload: {
      event
    }
  }),
  reducer: (state, action) => {
    const {event} = action.payload
    return {
      ...state,
      isLoading: false,
      check_sum_step: 'input'
    }
  }
})

export const checkControlSumEvent = sliceEvent({
  type: `${PREFIX}_CHECK_CSUM`,
  action: (rkId, bank, sbpPhone, amount) => ({
    payload: {
      bank,
      phone: sbpPhone,
      amount,
      rkId
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      isLoading: true
    }
  }
})

export const checkControlSumSuccessEvent = sliceEvent({
  type: `${PREFIX}_CHECK_CSUM_SUCCESS`,
  action: (bank, phone) => ({
    payload: {
      bank,
      phone
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {bank, phone} = action.payload
    return {
      ...state,
      check_sum_step: 'success',
      user: {
        ...state.user,
        beneficiaryBankName: bank,
        sbpPhone: phone
      },
      isLoading: false
    }
  }
})

export const checkControlSumFailEvent = sliceEvent({
  type: `${PREFIX}_CHECK_CSUM_FAIL`,
  action: (error) => ({
    payload: {
      error
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {error} = action.payload
    return {
      ...state,
      isLoading: false,
      check_sum_step: error === 'no_retry' ? 'no_retry' : 'retry'
    }
  }
})

export const setUserAvatar = sliceEvent({
  type: `${PREFIX}_USER_AVATAR`,
  action: (userAvatar) => ({
    payload: {
      userAvatar
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {userAvatar} = action.payload
    const {user} = state
    return {
      ...state,
      user: {
        // @ts-ignore
        ...user,
        avatarUrl: userAvatar
      }
    }
  }
})

export const getUserInfoRequest = sliceEvent({
  type: `${PREFIX}_USER_INFO_REQUEST`
})

export const deleteCurrentUser = sliceEvent({
  type: `${PREFIX}_DELETE_CURRENT_USER`
})

export const successDeleteCurrentUser = sliceEvent({
  type: `${PREFIX}_SUCCESS_DELETE_CURRENT_USER`,
  reducer: () => {
    return {
      user: null,
      errors: {},
      isLoading: false
    }
  }
})

export const deleteUserAvatar = sliceEvent({
  type: `${PREFIX}_DELETE_USER_AVATAR`,
  reducer: (state) => {
    return {
      ...state,
      user: {
        ...state.user,
        avatarUrl: ''
      }
    }
  }
})

export const setErrors = sliceEvent({
  type: `${PREFIX}_SET_ERRORS`,
  action: (errors) => ({
    payload: {
      errors
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {errors} = action.payload
    return {
      ...state,
      errors
    }
  }
})

export const allInitAppEvents = allSliceEvents
