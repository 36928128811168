export const SLIDER_SETTINGS = {
  arrows: true,
  centerPadding: '60px',
  slidesToShow: 4,
  swipeToSlide: true,
  infinite: false,
  dots: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        arrows: true,
        slidesToShow: 4,
        infinite: false,
        dots: false
      }
    },
    {
      breakpoint: 780,
      settings: {
        arrows: true,
        slidesToShow: 2,
        infinite: false,
        dots: false
      }
    },
    {
      breakpoint: 560,
      settings: {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: false
      }
    }
  ]
}

export const inviteText = 'Отправьте эту ссылку в родительский чат, чтобы упростить им задачу'
