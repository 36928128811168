import {styled} from '@mui/material/styles'

import {InfoIcon} from '../../../../../../../icons'
import {ArchiveIcon} from '../../../../../../../icons'
import {DeleteIcon} from '../../../../../../../icons'
export const FinalBlockWrapper = styled('div')(({theme}) => ({
  padding: '19px',
  display: 'flex',
  gap: '19px',
  width: '50%',
  flexDirection: 'column',
  backgroundColor: 'white',
  // maxWidth: '640px',
  boxShadow: '0px 12px 32px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  boxSizing: 'border-box',
  [theme.breakpoints.up('md')]: {
    borderRadius: '12px'
  },
  [theme.breakpoints.down(1224)]: {
    // margin: '0 19px 77px'
  },
  [theme.breakpoints.down('md')]: {
    // margin: '0 0 77px'
  },
  '.dateInput': {
    marginBottom: 0
  },
  [theme.breakpoints.down(1000)]: {
    width: '100%'
  },
  button: {
    fontSize: 16,
    color: '#39215E !important',
    background: 'rgba(57, 33, 94, 0.12)',
    border: '1px solid rgba(255, 255, 255, 1e-05)',
    '&:hover': {
      background: 'rgba(57, 33, 94, 0.24)',
      border: '1px solid rgba(255, 255, 255, 1e-05)'
    },
    '&:focused': {
      border: '1px solid rgba(255, 255, 255, 1e-05)'
    },
    '&:active': {
      boxShadow:
        ' 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
    },
    svg: {
      path: {
        stroke: '#39215E'
      }
    },
    '&:disabled': {
      opacity: 0.5
    }
  }
}))

export const InfoIconWarning = styled(InfoIcon)(({theme}) => ({
  '& path': {
    stroke: theme.palette.warningLight.contrastText
  }
}))

export const ArchiveIconWrapper = styled(ArchiveIcon)(({theme}) => ({
  '& path': {
    stroke: theme.palette.warningLight.contrastText
  }
}))

export const CloseIconWrapper = styled(DeleteIcon)(({theme}) => ({
  '& path': {
    stroke: theme.palette.warningLight.contrastText
  }
}))
