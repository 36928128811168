import React from 'react'
import {createTheme, ThemeProvider} from '@mui/material/styles'

import {generateThemeBase} from './base'

const Theme = (props) => {
  const {children} = props

  const generatedMuiTheme = createTheme(generateThemeBase)

  return <ThemeProvider theme={generatedMuiTheme}>{children}</ThemeProvider>
}

export default Theme
