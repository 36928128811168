import React from 'react'

export const LoupeIcon = ({className, color = '#39215E'}) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.875 22.75C19.2242 22.75 22.75 19.2242 22.75 14.875C22.75 10.5258 19.2242 7 14.875 7C10.5258 7 7 10.5258 7 14.875C7 19.2242 10.5258 22.75 14.875 22.75Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4414 20.4434L24.9977 24.9997"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
