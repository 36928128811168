import {styled} from '@mui/material/styles'

export const Wrapper = styled('div')({
  width: '100%',
  marginTop: '32px',
  '.loadingButton': {
    marginTop: '32px'
  },
  '.arrowRight': {
    marginLeft: '14px'
  },
  '.forgotPassword': {
    fontSize: '11px',
    color: 'rgba(119, 119, 119, 1)'
  },
  '.back': {
    color: 'rgba(0, 0, 0, 0.6)',
    padding: 0,
    marginTop: 23
  },
  '.passField': {
    marginBottom: 24
  },
  '.passFieldRepeat': {
    marginBottom: 0
  },
  '.phone': {
    marginBottom: '21px'
  },
  input: {
    fontSize: '16px',
    lineHeight: '24px'
  },
  button: {
    fontSize: '16px'
  },

  '@media(max-width: 500px)': {
    marginTop: '28px'
  }
})
