import {styled} from '@mui/material/styles'
import {FormControl} from '@mui/material'

export const InputBaseFormControl = styled(FormControl)(({theme}) => ({
  '& .MuiInputLabel-root': {
    position: 'static',
    fontSize: 19
  },
  '& .MuiFormControl-root': {
    marginBottom: 0
  },
  '& .MuiInputBase-root input': {
    border: 'none'
  },
  '& .MuiOutlinedInput-input': {
    color: theme.palette.primary.main
  },
  '.notRequiredText': {
    fontWeight: 400,
    color: theme.palette.primary.main
  }
}))
