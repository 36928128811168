import {
  ActiveProjectWrapper,
  BlockDescription,
  BlockTitle,
  Loader,
  SecondButtonWrapper,
  WrapperIconDatePayment,
  WrapperLoader
} from './ProjectsStyle'
import {Box, Typography, CircularProgress} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import React from 'react'
import {styled} from '@mui/material/styles'
import {Button} from '@mui/material'
import {getMonth} from 'utils/getFormattedDate'

const Text = styled(Typography)(({theme}) => ({
  color: theme.palette.primary.main,
  fontSize: 16
}))

const PayAllAtOnce = ({totalToPay, onClick, accruals, projects, accrualsLoader}) => (
  <ActiveProjectWrapper className="activePay payAll">
    <WrapperIconDatePayment className="payAllContent" sx={{height: 'fit-content'}}>
      {accrualsLoader ? (
        <CircularProgress sx={{margin: '0 auto', padding: 19, display: 'block'}} />
      ) : (
        <Box sx={{display: 'flex', flexDirection: 'column', alignSelf: 'start'}}>
          <Text
            variant="h1"
            sx={{fontWeight: 700, fontSize: 18, marginBottom: '12px', textTransform: 'inherit', lineHeight: '28px'}}
          >
            Оплатить всё разом
          </Text>
          <Text variant="h1" sx={{fontWeight: 700, fontSize: 48, marginBottom: '12px', lineHeight: '56px'}}>
            {accruals.reduce((a, b) => a + (b['amount'] || 0), 0)} ₽
          </Text>
          <Text
            variant="h1"
            sx={{
              fontWeight: 500,
              fontSize: 13,
              marginBottom: '12px',
              lineHeight: '24px',
              opacity: 0.8,
              textTransform: 'inherit'
            }}
          >
            Оплатите все актуальные взносы сразу
          </Text>
          {accruals.map((accrual, i) => (
            <Box sx={{display: 'flex', flexDirection: 'row'}} key={i}>
              <CheckIcon sx={{width: 20, height: 20, color: '#38AF00', marginRight: '16px'}} />
              <p style={{margin: '0 0 8px 0', color: '#39215E', fontSize: 14, fontWeight: 700}}>
                {accrual.projectName} (долг за {getMonth(accrual.deadline)})
              </p>
            </Box>
          ))}
          {/* <Text variant="h1" sx={{fontWeight: 'bold', lineHeight: '38px', fontSize: '24px'}}>
          {totalToPay} ₽
        </Text> */}
        </Box>
      )}
    </WrapperIconDatePayment>
    {/* <Text>Оплатите все актуальные взносы сразу</Text> */}
    {/* <em-emoji id="money_mouth_face" set="apple" size="120px"></em-emoji> */}
    {/* <WrapperLoader sx={{visibility: 'hidden'}}>
      <Loader />
    </WrapperLoader> */}
    <Button variant="contained" type="button" onClick={onClick} endIcon={<ArrowRightAltIcon />} sx={{width: '100%'}}>
      Подробнее и оплата
    </Button>
  </ActiveProjectWrapper>
)

const AllPaid = () => (
  <ActiveProjectWrapper>
    <BlockTitle>
      <span>Отлично!</span>
      <BlockDescription>Вы оплатили все взносы.</BlockDescription>
    </BlockTitle>
  </ActiveProjectWrapper>
)

export default ({totalToPay, accruals, onClick, projects, accrualsLoader}) => {
  return totalToPay > 0 ? (
    <PayAllAtOnce
      totalToPay={totalToPay}
      onClick={onClick}
      accruals={accruals}
      projects={projects}
      accrualsLoader={accrualsLoader}
    />
  ) : projects?.length > 1 ? (
    <AllPaid />
  ) : null
}
