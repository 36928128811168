import {styled} from '@mui/material/styles'
import {DialogContent} from '@mui/material'

export const ModalContent = styled(DialogContent)(() => ({
  padding: 24,
  '&.childrenLoading': {
    display: 'flex'
  },
  '& .image-upload-button': {
    width: 100,
    height: 100,
    marginBottom: 24,
    '& span': {
      lineHeight: '13px'
    },
    borderRadius: '8px'
  },
  '& .paper': {
    background: 'red'
  },
  '& .child-modal-date-input': {
    maxWidth: 480
  },
  '@media(max-width: 480px)': {
    padding: 16
  },
  '.MuiFormControl-root': {
    marginBottom: 16
  },
  '.child-modal-date-input': {
    marginBottom: 16,
    '.MuiFormControl-root': {
      marginBottom: 0
    }
  },
  '.childGender': {
    marginBottom: '16px !important'
  },
  '.saveBtn': {
    fontSize: 16,
    fontWeight: 500,
    background: 'rgba(57, 33, 94, 0.12) !important',
    border: '1px solid rgba(255, 255, 255, 1e-05) !important',
    color: '#39215E',
    svg: {
      width: 16,
      height: 16,
      path: {
        stroke: '#39215E'
      }
    },
    '&:hover': {
      background: 'rgba(57, 33, 94, 0.24) !important',
      border: '1px solid rgba(255, 255, 255, 1e-05) !important'
    },
    '&:focused': {
      border: '1px solid rgba(255, 255, 255, 1e-05) !important'
    },
    '&:active': {
      boxShadow:
        ' 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12) !important'
    }
  },
  '.delBtn': {
    fontSize: 16,
    background: 'rgba(255, 255, 255, 1e-05)',
    border: '1px solid rgba(57, 33, 94, 0.12)',
    color: '#F45D79',
    svg: {
      width: 10,
      height: 10
    },
    '&:hover': {
      background: 'rgba(57, 33, 94, 0.04)',
      border: '1px solid rgba(57, 33, 94, 0.12)'
    },
    '&:active': {
      boxShadow:
        ' 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12) !important'
    }
  }
}))
