import {styled} from '@mui/material/styles'
import InputAdornment from '@mui/material/InputAdornment'
import Calendar from 'react-calendar'
import {Typography} from '@mui/material'

export const StyledInputAdornment = styled(InputAdornment)(() => ({
  cursor: 'pointer'
}))

export const FiltersWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  [theme.breakpoints.down(1024)]: {
    flexDirection: 'column',
    background: theme.palette.background.paper
  },
  '.MuiChip-root': {
    background: '#E8DAFF'
  },
  '.period-input': {
    maxHeight: '108px',
    input: {
      fontSize: 14
    }
  },
  '.naznach-input': {
    maxHeight: '108px',
    input: {
      fontSize: 14
    }
  }
}))

export const FiltersButton = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  boxShadow: '0px 12px 32px rgba(57, 33, 94, 0.12), 0px 2.4px 8px rgba(57, 33, 94, 0.08)',
  padding: '14px',
  width: 'calc(100% - 32px)',
  // margin: '38px 19px 19px',
  cursor: 'pointer',
  justifyContent: 'center',
  borderRadius: '13px',
  border: `1px solid ${theme.palette.primary.main}`
}))

export const Text = styled(Typography)(({theme}) => ({
  color: theme.palette.primary.main,
  marginLeft: '13px'
}))

export const ButtonsWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  marginBottom: '5px',
  justifyContent: 'space-between',
  '& button': {
    // height: '100% !important'
  },
  [theme.breakpoints.up(1024)]: {
    '& .clearButton': {
      display: 'none'
    }
  },
  [theme.breakpoints.down(1024)]: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '333px',
    gap: '24px',
    '& button': {
      height: 'auto !important'
    },
    '& .clearButton': {
      width: 'fit-content'
    }
  }
}))

export const StyledCalendar = styled(Calendar)(({theme}) => ({
  '&.react-calendar': {
    width: '256px',
    padding: '12px 12px',
    fontFamily: theme.typography?.fontFamily,
    '& button, & span': {
      fontFamily: theme.typography?.fontFamily
    },
    '& .react-calendar__navigation': {
      button: {
        padding: 0
      }
    },
    '& .react-calendar__navigation__label__labelText, & .react-calendar__navigation__label__labelText--from': {
      // ...theme.typography.body1
    },
    '& .react-calendar__month-view__weekdays__weekday': {
      ...theme.typography.caption,
      color: theme.palette.grey[700],
      '& abbr': {
        textDecoration: 'none'
      }
    },
    '& .react-calendar__month-view__days__day--weekend': {
      color: 'inherit'
    },
    '& .react-calendar__tile': {
      borderRadius: '50%',
      flex: '0 0 13.6% !important',
      padding: '7px 5px'
    },
    '& .react-calendar__tile--active': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    },
    '& .react-calendar__month-view__days': {
      gap: '1.5px'
    }
  },
  boxShadow: `0px 4px 8px ${theme.palette.grey[600]}`,
  borderRadius: '3px',
  '& .react-calendar__tile--now': {
    background: 'transparent',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '50%'
  }
}))
