import {createSelectorsTree} from '../selectorsFactory'
import {generateReducersObject} from '../sliceMapping'
import {allInitAppEvents} from './initStoreSliceEvents'

const initialState = {
  isAuth: false,
  user: null,
  error: null,
  loading: false,
  loaded: false,
  requestStatus: null,
  successMsg: ''
}

/** Reducers */
export const INIT_STORE = 'initStore'
export const initStore = generateReducersObject({
  allSliceEvents: allInitAppEvents,
  initialState,
  reducerName: INIT_STORE
})

/** Selectors */
export const initAppSelectors = createSelectorsTree(initialState, INIT_STORE)
