import initSlice from '../initSlice'
import {groupBy, valuesIn, descend, compose, sortBy, prop, sortWith} from 'ramda'
const {sliceEvent, allSliceEvents} = initSlice()

const PREFIX = 'ACCRUALS_'

export const setIsLoading = sliceEvent({
  type: `${PREFIX}_SET_IS_LOADING`,
  action: (loading) => ({
    payload: {
      loading
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {loading} = action.payload
    return {
      ...state,
      loading
    }
  }
})

export const getSummaryEvent = sliceEvent({
  type: `${PREFIX}_GET_MY_SUMMARY`,
  action: (rkId) => ({
    payload: {
      rkId
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      loading: true
    }
  }
})

export const getSummarySuccessEvent = sliceEvent({
  type: `${PREFIX}_GET_MY_SUMMARY_SUCCESS`,
  action: (summary) => ({
    payload: {
      summary
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {summary} = action.payload
    return {
      ...state,
      summary,
      loading: false
    }
  }
})

export const getMyPaidEvent = sliceEvent({
  type: `${PREFIX}_GET_MY_PAID`,
  action: (rkId) => ({
    payload: {
      rkId
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      loading: true
    }
  }
})

export const getMyPaidSuccessEvent = sliceEvent({
  type: `${PREFIX}_GET_MY_PAID_SUCCESS`,
  action: (paid) => ({
    payload: {
      paid
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {paid} = action.payload
    return {
      ...state,
      paid,
      loading: false
    }
  }
})

export const getMyAccrualsEvent = sliceEvent({
  type: `${PREFIX}_GET_MY`,
  action: (rkId, unselect) => ({
    payload: {
      rkId,
      unselect
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      loading: true
    }
  }
})

export const getMyAccrualsSuccessEvent = sliceEvent({
  type: `${PREFIX}_GET_MY_SUCCESS`,
  action: (accruals, unselect) => ({
    payload: {
      accruals,
      unselect
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    // группировать по ProjectUUID, Deadline, чтобы несколько детей попадали в одну строку, но в дебаге
    // можно было бы увидеть список детей
    const {accruals, unselect} = action.payload

    const desc = (a) => -a
    const sort = sortBy(prop('deadline'))

    //const sortByDeadline = sortWith(descend(prop('deadline')))
    let shaped = accruals.map((accrual) => {
      return {
        key: accrual.accrualUuids[0],
        amount: accrual.amount,
        ids: accrual.accrualUuids,
        projectUuid: accrual.projectUuid,
        projectName: accrual.projectName,
        projectLogo: accrual.projectLogo,
        deadline: new Date(accrual.deadline),
        checked: !unselect
      }
    })
    const current = sort(shaped)
    return {
      ...state,
      current,
      loading: false
    }
  }
})

export const getRkAccrualsEvent = sliceEvent({
  type: `${PREFIX}_GET_RK`,
  action: (rkId) => ({
    payload: {
      rkId
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      loading: true
    }
  }
})

export const getRkAccrualsSuccessEvent = sliceEvent({
  type: `${PREFIX}_GET_RK_SUCCESS`,
  action: (all) => ({
    payload: {
      all
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {all} = action.payload
    return {
      ...state,
      all,
      loading: false
    }
  }
})

export const getPrAccrualsEvent = sliceEvent({
  type: `${PREFIX}_GET_PR`,
  action: (prId) => ({
    payload: {
      prId
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      loading: true
    }
  }
})

export const getPrAccrualsSuccessEvent = sliceEvent({
  type: `${PREFIX}_GET_PR_SUCCESS`,
  action: (all) => ({
    payload: {
      all
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {all} = action.payload
    return {
      ...state,
      all,
      loading: false
    }
  }
})

export const setErrors = sliceEvent({
  type: `${PREFIX}_SET_ERRORS`,
  action: (errors) => ({
    payload: {
      errors
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    const {errors} = action.payload
    return {
      ...state,
      errors
    }
  }
})

export const createProjectBillEvent = sliceEvent({
  type: `${PREFIX}_CREATE_PR_BILL`,
  action: (items) => ({
    payload: {
      items
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      loading: true
    }
  }
})

export const createBillEvent = sliceEvent({
  type: `${PREFIX}_CREATE_BILL`,
  action: (items) => ({
    payload: {
      items
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      loading: true,
      payInProgress: true
    }
  }
})

export const finishBillEvent = sliceEvent({
  type: `${PREFIX}_FINISH_BILL_SUCCESS`,
  action: (result) => ({
    payload: {
      result
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    // force clean caches
    //window.location.reload()
    return {
      ...state,
      loading: false,
      payInProgress: false
    }
  }
})

export const cleanBillEvent = sliceEvent({
  type: `${PREFIX}_CLEAN_BILL_SUCCESS`,
  reducer: (state, action = {type: '', payload: {}}) => {
    // force clean caches
    //window.location.reload()
    return {
      ...state,
      confirmationToken: null
    }
  }
})

export const createBillSuccessEvent = sliceEvent({
  type: `${PREFIX}_CREATE_BILL_SUCCESS`,
  action: (url) => ({
    payload: {
      url
    }
  }),
  reducer: (state, action = {type: '', payload: {}}) => {
    return {
      ...state,
      confirmationToken: action.payload.url,
      loading: false,
      payInProgress: false
    }
  }
})

export const checkBillEvent = sliceEvent({
  type: `${PREFIX}_CHECK_BILL`,
  action: (uuid, rkId, projectId) => ({
    payload: {
      uuid,
      rkId,
      projectId
    }
  }),
  reducer: (state) => {
    return {
      ...state,
      loading: false
    }
  }
})

export const checkBillSuccessEvent = sliceEvent({
  type: `${PREFIX}_CHECK_BILL_SUCCESS`,
  reducer: (state) => {
    return {
      ...state,
      loading: false
    }
  }
})

export const allInitAppEvents = allSliceEvents
