import React, {useState} from 'react'
import {Button, Box, FormControl, FormHelperText, InputBase, InputLabel} from '@mui/material'
import {CheckIcon} from '../../../../icons'

export const MyData = ({user, onSubmit}) => {
  const [firstName, setFirstName] = useState(user?.fullName.firstName)
  const [lastName, setLastName] = useState(user?.fullName.surname)
  const [middleName, setMiddleName] = useState(user?.fullName.patronymic)
  const [email, setEmail] = useState(user?.email)

  const handleDataSubmit = (event) => {
    event.preventDefault()
    const data = {
      uuid: user?.uuid,
      fullName: {
        firstName: firstName,
        surname: lastName,
        patronymic: middleName
      },
      email: email
    }
    onSubmit(data)
  }

  return (
    <Box className="profile-my-data" component="form" onSubmit={handleDataSubmit}>
      <FormControl variant="standard" fullWidth={false} required={true} sx={{maxWidth: '500px'}}>
        <InputLabel htmlFor="user-lastName" shrink className="text">
          Фамилия
        </InputLabel>
        <InputBase
          className="text"
          id="user-lastName"
          value={lastName}
          onChange={(evt) => /^[а-яА-ЯЁё-]*$/g.test(evt.target.value) && setLastName(evt.target.value)}
        />
      </FormControl>
      <FormControl variant="standard" fullWidth={false} required={true} sx={{maxWidth: '500px'}}>
        <InputLabel htmlFor="user-firstName" shrink className="text">
          Имя
        </InputLabel>
        <InputBase
          className="text"
          id="user-firstName"
          value={firstName}
          onChange={(evt) => /^[а-яА-ЯЁё-]*$/i.test(evt.target.value) && setFirstName(evt.target.value)}
        />
      </FormControl>
      <FormControl variant="standard" fullWidth={false} sx={{maxWidth: '500px'}}>
        <InputLabel htmlFor="user-middleName" shrink className="text">
          Отчество <span className="not-required-text">(не обязательно)</span>
        </InputLabel>
        <InputBase
          className="text"
          id="user-middleName"
          value={middleName}
          onChange={(evt) => /^[а-яА-ЯЁё-]*$/i.test(evt.target.value) && setMiddleName(evt.target.value)}
        />
      </FormControl>
      <FormControl variant="standard" fullWidth={false} required={true} sx={{maxWidth: '500px'}}>
        <InputLabel htmlFor="user-email" shrink className="text">
          Email
        </InputLabel>
        <InputBase
          className="text"
          id="user-email"
          value={email}
          type="email"
          aria-describedby="user-email-helper-text"
          onChange={(evt) => setEmail(evt.target.value)}
        />
        <FormHelperText id="component-helper-text" className="text">
          Для получения важных уведомлений
        </FormHelperText>
      </FormControl>
      <Button
        variant="contained"
        startIcon={<CheckIcon color="white" />}
        sx={{maxWidth: '500px'}}
        classes={{root: 'profile-button', startIcon: 'profile-button-icon'}}
        type="submit"
      >
        Сохранить
      </Button>
    </Box>
  )
}
