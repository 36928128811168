import React from 'react'

import {styled} from '@mui/material/styles'

import {SearchIcon} from '../../../icons'
import {PATHS} from '../../../routes/paths'

const Wrapper = styled('a')(({theme}) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  flexDirection: 'column',
  boxSizing: 'border-box',
  justifyContent: 'center',
  margin: '32px 0 0 0',
  gap: '11px',
  textDecoration: 'none',
  fontSize: 14,
  fontWeight: 700,
  minHeight: 46,
  borderRadius: 14,
  padding: '10px 19px',
  color: '#39215E',
  background: 'transparent',
  // boxShadow:
  //   '0px 12px 20px rgba(87, 51, 141, 0.0628148), 0px 4.62222px 6.37037px rgba(87, 51, 141, 0.0971852), 0px 0.977778px 1.62963px rgba(87, 51, 141, 0.16)',
  border: '2px solid #E6E6FF',
  // transition: '0.2s linear',
  '&:hover': {
    // background: '#D0D0F8',
    border: '2px solid #D0D0F8'
  },
  '&:focused': {
    // background: '#B3B3ED',
    border: '2px solid #B3B3ED'
  },
  '&:active': {
    // background: '#B3B3ED'
    border: '2px solid #B3B3ED'
  },
  svg: {
    path: {
      stroke: '#39215E !important'
    }
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    width: 'fit-content'
    // height: '96px'
  },
  [theme.breakpoints.down(900)]: {
    with: '100% !important'
    // height: '96px'
  }
}))

const Text = styled('span')(({theme}) => ({
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '19px',
  letterSpacing: '0.25px',
  textAlign: 'center',
  color: '#39215E',

  [theme.breakpoints.up('md')]: {
    fontSize: '16px'
  }
}))

const Icon = styled(SearchIcon)(({theme}) => ({
  color: theme.palette.primary.main,
  width: '24px'
  // height: '24px'
}))

const SearchRodcom = () => (
  <Wrapper href={PATHS.SEARCH_PAGE}>
    <Icon />
    <Text>Найти или создать родком</Text>
  </Wrapper>
)

export default SearchRodcom
