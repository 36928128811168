import React from 'react'
import {StyledInfoBox} from '../Layouts/TabContentLayout/TabContentLayout'
import Typography from '@mui/material/Typography'
import {MessageIcon} from '../../icons'
import {StyledLinkTitle} from '../LinkBlock/LinkBlock'
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined'
import {Box} from '@mui/material'
import {Button} from '@mui/material'

const ParentChatLinkBlock = ({title, link}) => {
  return (
    <StyledInfoBox>
      <Typography sx={{fontSize: 13, color: '#39215E', opacity: 0.8, fontWeight: 500}}> {title}</Typography>
      <Box sx={{display: 'flex', alignItems: 'center', marginTop: '12px'}}>
        <MessageIcon />
        <StyledLinkTitle href={link} target="_blank" sx={{textDecoration: 'underline', marginLeft: '12px'}}>
          {link}
        </StyledLinkTitle>
      </Box>
      <form action={link} target="_blank">
        <Button sx={{marginTop: '12px'}} variant="contained" color="info" type="submit">
          Открыть
        </Button>
      </form>
    </StyledInfoBox>
  )
}

export default ParentChatLinkBlock
