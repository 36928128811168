import React, {useState, useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {Dialog, Typography, Button, CircularProgress} from '@mui/material'

import {FundraisingInput} from '../../../../../../../components/Inputs/FundraisingInput/FundraisingInput'
import TextInput from '../../../../../../../components/Inputs/TextInput/TextInput'
import {CheckIcon} from '../../../../../../../icons'
import {getDebtSummaryEvent, requestMoneyEvent} from 'store/giveoutStore/giveoutStoreSliceEvents'
import {giveoutSelectors} from 'store/giveoutStore/giveoutStore'
import ConfirmForm from './ConfirmDialog'
import {StyledLoadingButton} from '../../../../../../../components/StyledLoadingButton/StyledLoadingButton'

const ModalContent = styled('div')(({theme}) => ({
  padding: '38px',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '19px',
  '& .MuiFormControl-root': {
    maxWidth: 'inherit'
  },
  '& .MuiAlert-standardSuccess': {
    background: theme.palette.success.secondary
  },
  '.description, .fundraising-output-input': {
    marginBottom: 0
  },
  '& label+.MuiInputBase-root': {
    marginTop: '17px'
  }
}))

const CanWithdrawBlock = styled('div')(({theme}) => ({
  padding: '13px',
  borderRadius: '6px',
  backgroundColor: theme.palette.success.secondary,
  display: 'flex',
  flexDirection: 'column'
}))

const ReportingOutputModal = ({isOpen, projectId, handleClose}) => {
  const theme = useTheme()
  const [amount, setAmount] = useState(0)
  const [amountError, setAmountError] = useState('')
  const [description, setDescription] = useState('')
  const dispatch = useDispatch()

  const expensesSummary = useSelector(giveoutSelectors.debtSummary.selector)
  const loading = useSelector(giveoutSelectors.isLoading.selector)
  const showConfirm = useSelector(giveoutSelectors.requireConfirm.selector)
  useEffect(() => {
    if (projectId) dispatch(getDebtSummaryEvent.action(projectId))
  }, [projectId])

  const requestGiveOut = useCallback(
    (e) => {
      if (!amount) {
        setAmountError('Выводимая сумма должна быть больше 0')
        return
      }
      if (amount > expensesSummary?.available) {
        setAmountError('Недостаточно средств')
        return
      }
      e.preventDefault()
      e.nativeEvent.stopPropagation()
      dispatch(requestMoneyEvent.action({uuid: projectId, amount, description}))
    },
    [expensesSummary, amount, description]
  )

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Dialog open={isOpen} onClose={handleClose} fullScreen={fullScreen} fullWidth maxWidth="xs">
      <ModalContent>
        <ConfirmForm
          loading={loading}
          event={requestMoneyEvent}
          data={{uuid: projectId, amount, description}}
          showConfirm={showConfirm}
        >
          <Typography variant="h4" sx={{lineHeight: '29px', fontSize: '19px'}}>
            Вывод под отчёт
          </Typography>
          <CanWithdrawBlock>
            <Typography variant="h4" sx={{fontWeight: '700'}}>
              Можно вывести: {expensesSummary?.available} ₽
            </Typography>
            <Typography variant="caption">с учётом комиссии сервиса</Typography>
          </CanWithdrawBlock>
          <FundraisingInput
            className="fundraising-output-input"
            id="fundraising-output-input"
            value={amount}
            error={amountError}
            handleChange={(newValue) => {
              setAmount(newValue)
            }}
            clearError={setAmountError}
            label="Сколько вывести"
            placeholder="Сумма, ₽"
          />
          <TextInput
            className="description"
            label="На что пойдут средства"
            id="description"
            minRows={4}
            multiline
            resize
            value={description}
            onChangeValue={(newValue) => {
              setDescription(newValue)
            }}
            placeholder="Кратко опишите назначение"
          />
          <StyledLoadingButton
            disabled={!(expensesSummary?.available > 0)}
            loading={loading}
            onClick={requestGiveOut}
            startIcon={<CheckIcon color="white" />}
            variant="contained"
            type="button"
          >
            Вывести
          </StyledLoadingButton>
          {fullScreen && (
            <Button variant="outlined" onClick={handleClose}>
              Отмена
            </Button>
          )}
        </ConfirmForm>
      </ModalContent>
    </Dialog>
  )
}
export default ReportingOutputModal
